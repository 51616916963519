import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { closeModel, getUser } from '../../../../utility/utility';
import { routes } from '../../../../utility/constants/constants';
import { reset } from 'redux-form';
import isEmpty from '../../../../utility/IsEmptyValidator';
import $ from "jquery";
import { validateAccountUsers } from '../../../../utility/validator/submit';
import AcountUsers from '../../../component/Accounts/AccountUsers/accountUsers';
import { animateScroll as scroll } from 'react-scroll'
import AccountsCalenderTable from '../../../component/Accounts/AccountUsers/AccountCalender';
import { criteria } from '../../../../utility/validator/validator';
import { startOfMonth, endOfMonth, convertDateFormatForSchedule, convertDateToDifferentTZ } from '../../../../utility/utility';
import storage from '../../../../utility/storage';
const cloneDeep = require('clone-deep');
var moment = require('moment');

class AccountUsersContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        let firstMonthDate = convertDateFormatForSchedule(startOfMonth(new Date()));
        let lastMonthDate = convertDateFormatForSchedule(endOfMonth(new Date()));
        storage.set('liveDay', new Date()); 
        if (this.props.currentAccount) { 
            this.props.getAccountSchedule(this.props.currentAccount.id,convertDateToDifferentTZ(firstMonthDate, 'from'), convertDateToDifferentTZ(lastMonthDate, 'to'))
            this.props.getScheduleTicketEvents(convertDateToDifferentTZ(firstMonthDate, 'from'), convertDateToDifferentTZ(lastMonthDate, 'to'), this.props.currentAccount.id)
            this.props.getInspectionFormForAccount(this.props.currentAccount.id)
        } else {
            this.props.getAccountSchedule(this.props.match.params.accountId, convertDateToDifferentTZ(firstMonthDate, 'from'), convertDateToDifferentTZ(lastMonthDate, 'to'))
            this.props.getScheduleTicketEvents(convertDateToDifferentTZ(firstMonthDate, 'from'), convertDateToDifferentTZ(lastMonthDate, 'to'), this.props.match.params.accountId)
        }
        if(this.props.match.params.event_id){ 
            const id_popup = this.props.match.params.event_id
            // this.props.getScheduleMailEvents(id_popup)
            // this.props.handleConfirmClickDeleteModel(id_popup)
        }
        // this.props.getScheduleEvents(startDate, endDate);
        scroll.scrollToTop();
    }
    componentDidUpdate() {
        if (this.props.accountsList && this.props.accountsList.length > 0) {
            this.props.accountsList.map((acc) => {
                if (acc.id === this.props.match.params.accountId) {
                    this.props.setCurrentAccount(acc);
                }
            })
        }
    }
    createNewSchedule = () => { 
        if (this.props.account_id_info) {
            this.props.history.push(
                { pathname: `/accounts/${this.props.account_id_info}/account_users/schedule-time/create-new-schedule`, })

        } else {
            this.props.history.push(
                { pathname: `/accounts/${this.props.match.params.accountId}/account_users/schedule-time/create-new-schedule`, })
        }
        // state: { 
        //     from: this.props.location.pathname
        // }

    }
    createNewTicketSchedule = () => { 
        if (this.props.account_id_info) {
            this.props.history.push(
                { pathname: `/accounts/${this.props.account_id_info}/account_users/create-new-ticket-schedule`, })

        } else {
            this.props.history.push(
                { pathname: `/accounts/${this.props.match.params.accountId}/account_users/create-new-ticket-schedule`, })
        }
    }
    getSchedule = (id) => {
        this.props.getSchedule(id)
    }
    getOneScheduleTicketEventInfo = (id) => {
        this.props.getOneScheduleTicketEventInfo(id)
    }
    deleteSchedule = (id) => { 
        let storedDate = storage.get('liveDay');
        let firstMonthDate = moment(storedDate).startOf('month').format('YYYY-MM-DD');
        let lastMonthDate = moment(storedDate).endOf('month').format('YYYY-MM-DD'); 
        this.props.deleteSchedule(id, this.props.match.params.accountId, convertDateToDifferentTZ(firstMonthDate, 'from'), convertDateToDifferentTZ(lastMonthDate, 'to'))
    }
    deleteEvent = (id) => {
        let storedDate = storage.get('liveDay');
        let firstMonthDate = moment(storedDate).startOf('month').format('YYYY-MM-DD');
        let lastMonthDate = moment(storedDate).endOf('month').format('YYYY-MM-DD'); 
        this.props.deleteEvent(id, this.props.match.params.accountId, convertDateToDifferentTZ(firstMonthDate, 'from'), convertDateToDifferentTZ(lastMonthDate, 'to'))
    }
    scheduleHistory = () => {
        this.props.history.push(`/accounts/${this.props.match.params.accountId}/account_users/inspection-schedule-history`)
    }
    scheduleTicketHistory = () => {
        this.props.history.push(`/accounts/${this.props.match.params.accountId}/account_users/ticket-schedule-history`)
    }
    deleteScheduleTicketEvent = (id) => {
        let storedDate = storage.get('liveDay');
        let firstMonthDate = moment(storedDate).startOf('month').format('YYYY-MM-DD');
        let lastMonthDate = moment(storedDate).endOf('month').format('YYYY-MM-DD'); 
        this.props.deleteCurrentScheduleTicketEvent(id, this.props.match.params.accountId, convertDateToDifferentTZ(firstMonthDate, 'from'), convertDateToDifferentTZ(lastMonthDate, 'to'))
    }
    deleteEntireScheduleTicket = (id) => {
        let storedDate = storage.get('liveDay');
        let firstMonthDate = moment(storedDate).startOf('month').format('YYYY-MM-DD');
        let lastMonthDate = moment(storedDate).endOf('month').format('YYYY-MM-DD'); 
        this.props.deleteEntireScheduleTicketEvents(id, this.props.match.params.accountId, convertDateToDifferentTZ(firstMonthDate, 'from'), convertDateToDifferentTZ(lastMonthDate, 'to'))
    }
    render() {

        return (
            <AccountsCalenderTable  {...this.props}
                getScheduleList={this.props.getScheduleList}
                getScheduleEventsList={this.props.getScheduleEventsList}
                scheduleId={this.props.scheduleId}
                getSchedule={this.getSchedule}
                createNewSchedule={this.createNewSchedule}
                account_id_info={this.props.account_id_info}
                account={this.props.account}
                scheduleWithAccountId={this.props.scheduleWithAccountId}
                handleClickDeleteModel={this.props.handleClickDeleteModel}
                handleDeleteClose={this.props.handleDeleteClose}
                isLoading={this.props.isLoading}
                deleteSchedule={this.deleteSchedule}
                deleteEvent={this.deleteEvent}
                deleteObject={this.props.deleteObject}
                openConfirmDeleteModel={this.props.openConfirmDeleteModel}
                handleConfirmClickDeleteModel={this.props.handleConfirmClickDeleteModel}
                handleScheduleClickDeleteModel={this.props.handleScheduleClickDeleteModel}
                openScheduleConfirmDeleteModel={this.props.openScheduleConfirmDeleteModel}
                getScheduleEmailEventsList={this.props.getScheduleEmailEventsList}
                scheduleHistory={this.scheduleHistory}
                scheduleTicketHistory={this.scheduleTicketHistory}
                createNewTicketSchedule={this.createNewTicketSchedule}
                getScheduleTicketEventsList={this.props.getScheduleTicketEventsList}
                getOneScheduleTicketEventInfo={this.getOneScheduleTicketEventInfo}
                scheduleTicketOneEvent={this.props.scheduleTicketOneEvent}
                deletingScheduleTicketId={this.props.deletingScheduleTicketId}
                openScheduleTicketDeleteModal={this.props.openScheduleTicketDeleteModal}
                deleteScheduleTicketEvent={this.deleteScheduleTicketEvent}
                deleteEntireScheduleTicket={this.deleteEntireScheduleTicket}
                deleteScheduleTicketEventAction={this.props.deleteScheduleTicketEventAction}
                deleteEntireScheduleTicketEventsAction={this.props.deleteEntireScheduleTicketEventsAction}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        getScheduleList: state.adminOrSuperAdminReducer.getScheduleList,
        getScheduleEventsList: state.adminOrSuperAdminReducer.getScheduleEventsList,
        scheduleId: state.adminOrSuperAdminReducer.scheduleId,
        scheduleWithAccountId: state.adminOrSuperAdminReducer.scheduleWithAccountId,
        account: state.adminOrSuperAdminReducer.account,
        account_id_info: state.adminOrSuperAdminReducer.account_id_info,
        isOpenModel: state.adminOrSuperAdminReducer.isOpenModel,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        currentAccount: state.adminOrSuperAdminReducer.currentAccount,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openConfirmDeleteModel: state.adminOrSuperAdminReducer.openConfirmDeleteModel,
        openScheduleConfirmDeleteModel: state.adminOrSuperAdminReducer.openScheduleConfirmDeleteModel,
        getScheduleEmailEventsList: state.adminOrSuperAdminReducer.getScheduleEmailEventsList,
        getScheduleTicketEventsList: state.adminOrSuperAdminReducer.getScheduleTicketEventsList,
        scheduleTicketOneEvent: state.adminOrSuperAdminReducer.scheduleTicketOneEvent,
        deletingScheduleTicketId: state.adminOrSuperAdminReducer.deletingScheduleTicketId,
        openScheduleTicketDeleteModal: state.adminOrSuperAdminReducer.openScheduleTicketDeleteModal,
        deleteScheduleTicketEventAction: state.adminOrSuperAdminReducer.deleteScheduleTicketEventAction,
        deleteEntireScheduleTicketEventsAction: state.adminOrSuperAdminReducer.deleteEntireScheduleTicketEventsAction,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllSchedule: (account_id, start_time, end_time) => dispatch(actions.getAllSchedule(account_id, start_time, end_time)),
        getScheduleEvents: (startDate, endDate) => dispatch(actions.getScheduleEvents(startDate, endDate)),
        getSchedule: (id) => dispatch(actions.getSchedule(id)),
        getAccountSchedule: (id, startDate, endDate) => dispatch(actions.getAccountSchedule(id, startDate, endDate)),
        closePopup: () => dispatch(actions.closePopup()),
        openPopup: () => dispatch(actions.openPopup()),
        handleClickDeleteModel: (criteria) => dispatch(actions.handleClickDeleteModel(criteria)),
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        setCurrentAccount: (account) => dispatch(actions.setCurrentAccount(account)),
        deleteSchedule: (id, account_id, startDate, endDate) => dispatch(actions.deleteSchedule(id, account_id, startDate, endDate)),
        deleteEvent: (id, account_id, startDate, endDate) => dispatch(actions.deleteEvent(id, account_id, startDate, endDate)),
        handleConfirmClickDeleteModel: (criteria) => dispatch(actions.handleConfirmClickDeleteModel(criteria)),
        handleScheduleClickDeleteModel: (criteria) => dispatch(actions.handleScheduleClickDeleteModel(criteria)),
        getInspectionFormForAccount: (id) => dispatch(actions.getInspectionFormForAccount(id)),
        getScheduleMailEvents: (id) => dispatch(actions.getScheduleMailEvents(id)),
        getScheduleTicketEvents: (startDate, endDate, id) => dispatch(actions.getScheduleTicketEvents(startDate, endDate, id)),
        getOneScheduleTicketEventInfo: (id) => dispatch(actions.getOneScheduleTicketEventInfo(id)),
        handleOpenScheduleTicketDeleteModal: (scheduleTicketId) => dispatch(actions.handleOpenScheduleTicketDeleteModal(scheduleTicketId)),
        deleteCurrentScheduleTicketEvent: (id, account_id, startDate, endDate) => dispatch(actions.deleteCurrentScheduleTicketEvent(id, account_id, startDate, endDate)),
        deleteEntireScheduleTicketEvents: (id, account_id, startDate, endDate) => dispatch(actions.deleteEntireScheduleTicketEvents(id, account_id, startDate, endDate)),
        handleDeleteScheduleTicketEvent: (scheduleTicketId) => dispatch(actions.handleDeleteScheduleTicketEvent(scheduleTicketId)),
        handleDeleteEntireScheduleTicketEvents: (scheduleTicketId) => dispatch(actions.handleDeleteEntireScheduleTicketEvents(scheduleTicketId)),
        previewSingleTicketClicked: (ticket) => dispatch(actions.previewSingleTicketClicked(ticket)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AccountUsersContainer);