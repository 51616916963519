import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../../styles/custom.css';
import { CustomNoDataComponent, makeFirstLetterCapital, convertDateToDifferentTZ, convertDateAndTimeTimezone } from '../../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import { routes } from '../../../../../utility/constants/constants';
import { performanceAuditEditId } from '../../../../../redux/actions/FormAuditModAction/AuditsAction/AuditsAction';
var moment = require('moment');

let PerformanceAuditTable = (props) => {

    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchPerformanceAudit && state.form.searchPerformanceAudit.values ? state.form.searchPerformanceAudit.values : {});
    const DateGenerate = useSelector(state => state.auditsReducer.dataGenerated);
    const values = useSelector(state => state.auditsReducer.performanceAuditList_searchValues);
    const No_of_pages = useSelector(state => state.auditsReducer.performanceAuditList_total_pages);
    const No_of_rows = useSelector(state => state.auditsReducer.performanceAuditList_no_of_rows);
    const isPerformanceAuditList = useSelector(state => state.auditsReducer.isPerformanceAuditList);
    const performanceAuditList_curPage = useSelector(state => state.auditsReducer.performanceAuditList_curPage);
    const SearchOrResetScheduledTaskListClicked = useSelector(state => state.auditsReducer.SearchOrResetScheduledTaskListClicked);
    const {timezone}=useSelector((state)=>state.authReducer.user)

    const accountTimezone = 'America/Los_Angeles';

    const editHandler = (id) => {
        dispatch(performanceAuditEditId(id))
        props.history.push(`performance-audit/${id}`)
    }

    const data = props.performanceAuditList && props.performanceAuditList.map((value) => {
        return {
            id: value && value.id ? value.id : '-',
            created_at: value.created_at ?  convertDateAndTimeTimezone(value.created_at,timezone): '-',
            submitted_by: value && value.submitted_by ? makeFirstLetterCapital(value.submitted_by?.first_name) + ' ' + makeFirstLetterCapital(value.submitted_by?.last_name) : '-',

            actions:
                <>
                   {value.status === "completed"  ? <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`performance-audit/view/${value.id}`)}  ><i class="fas fa-eye" aria-hidden="true" ></i></span> : null} 
                   {props.isCreateAccess && value.status === "completed" ? <span className="mr_20 account_edit_icn" onClick={() => editHandler(value.id)} ><i class="fa fa-pencil" aria-hidden="true" ></i></span> : null} 
                </>
        }
    })

    const columns = [
        {
            Header: <><span> ID </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'id',
            show: props.performanceAuditList.length !== 0,
            // width: 135,
             sortable: false,
        },
        {
            Header: <><span> Created At </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'created_at',
            show: props.performanceAuditList.length !== 0,
            // width: 235,
            // sortable: false,
        },
        {
            Header: <><span> Submitted By </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'submitted_by',
            show: props.performanceAuditList.length !== 0,
            // width: 235,
            // sortable: false,
        },
        {
            Header: '',
            accessor: 'actions',
            width: 135,
            sortable: false,
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew = React.useRef()
    if (performanceAuditList_curPage !== tablePageNew.current) {
        tablePageNew.current = performanceAuditList_curPage;
    }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.performanceAuditListPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetScheduledTaskListClicked) {
            dispatch(actions.resetSearchOrResetInventory())
        } else {
            if (!DateGenerate && !props.isPerformanceAuditList) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : performanceAuditList_curPage + 1;
                const inventoryItemsList_no_of_rows = state.pageSize;
                if (No_of_rows != inventoryItemsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }

                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                const from_date = SearchValue.fromDate ? moment(values.fromDate).format('DD-MM-YYYY') : null;
                const to_date = SearchValue.toDate ? moment(values.toDate).format('DD-MM-YYYY') : null;
                props.getAllPerformanceAudit({ page, limit, order, orderBy, from_date, to_date}, values)
            }
        }
    }

    return (
        SearchOrResetScheduledTaskListClicked && props.isPerformanceAuditList ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isPerformanceAuditList}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={performanceAuditList_curPage}
                page={performanceAuditList_curPage}
                onPageChange={(page) => { setTablePageFn(page) }}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPaginationBottom={false}
                showPagination={props.performanceAuditList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(isPerformanceAuditList, 'No Performance Audit List Found')}
                LoadingComponent={() =>
                    isPerformanceAuditList ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default PerformanceAuditTable;