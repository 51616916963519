import React, { useState, useEffect, useRef } from 'react';
import { Segmented } from 'antd';
import { Badge, Card, Space, Switch } from 'antd';
import * as faceapi from 'face-api.js';
import '../frontlineForm.css';

import errorHandler from '../../../../utility/errorHandler/errorHandler';
import SuccessAlert from '../../../../utility/successAlert/successAlert';

import FaceRegAnalyzerComponent from './FaceRegAnalyzerComponent';

const refreshPage = () => { window.location.reload(false); }

const FaceRecognitionComponent = (props) => {

    const [capturedScannedImageUrl, setCapturedScannedImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    let scannedImageFaceDetection = null;

    // LOAD FROM USEEFFECT
    useEffect(() => {
        if (capturedScannedImageUrl == null) {
            triggerWebCam(true);
        }
        videoRef && loadModels()

    }, [])

    // OPEN YOU FACE WEBCAM
    const triggerWebCam = (triggerToken) => {
        navigator.mediaDevices.getUserMedia({ video: triggerToken })
            .then((currentStream) => {
                videoRef.current.srcObject = currentStream
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const faceMyDetect = () => {

        if (capturedScannedImageUrl == null) {

            setTimeout(async () => {

                // detect the face from the taken scanned Image
                scannedImageFaceDetection = await faceapi.detectAllFaces(videoRef.current,
                    new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();

                /*** Do face comparison only when faces were detected*/
                if (scannedImageFaceDetection[0]) {
                    SuccessAlert('Face Detected')
                    setIsLoading(false);
                    captureImage();
                    // Using Euclidean distance to comapare face descriptions
                    // const distance = faceapi.euclideanDistance(flUserImageFaceDetection.descriptor, scannedImageFaceDetection[0].descriptor);
                    // console.log(distance);
                } else if (!scannedImageFaceDetection[0]) {
                    setIsLoading(false);
                    errorHandler("Couldn't Detect the Face in Scanned Image");
                    setTimeout(() => {
                        refreshPage();
                    }, 2000);
                    return;
                }

            }, 10000);

        }

    }

    // LOAD MODELS FROM FACE API
    const loadModels = () => {
        Promise.all([
            // THIS FOR FACE DETECT AND LOAD FROM YOU PUBLIC/MODELS DIRECTORY
            faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
            faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
            faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
            faceapi.nets.faceExpressionNet.loadFromUri("/models")

        ]).then(() => {
            if (capturedScannedImageUrl == null) {
                faceMyDetect();
                setIsLoading(true);
            }
        })
    }

    // For converting the captured scanned image ref into an image url and store it
    const captureImage = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // Draw the current frame of the video onto the canvas
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        // You can get the image as a data URL if you want
        const imageUrl = canvas.toDataURL();
        setCapturedScannedImageUrl(imageUrl);
        triggerWebCam(false);
        props.handleSubmit(imageUrl);
        console.log(imageUrl);
    };

    return (
        <div>

            <div className='faceReg-segment'>
                <Segmented size="large" block options={[{ label: 'FACIAL BIOMETRIC SCANNER', value: 'openScanner' }]} />
            </div>

            {capturedScannedImageUrl == null ? (
                <>
                    <div className="myapp faceRegAnal" style={{ margin: '2rem' }}>
                        <span className='text-v1 mb-4'> Ensure your face fits within the scanning guide outline </span>
                        <Badge.Ribbon text="SCANNER" color="#850037" size="default">
                            <Card title="Please avoid any movement − scanning process is in progress!" size="small" hoverable={true} bordered={true}>
                                <div className="outline-container">
                                    <div className="appvide">
                                        <video className="mirror-reflect-video" crossOrigin="anonymous" ref={videoRef} autoPlay></video>
                                    </div>
                                    <canvas ref={canvasRef} width="640" height="480" className="appcanvas" />
                                    <div className="face-outline">
                                        <svg id='yourSVGMask' viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                            <ellipse cx="100" cy="100" rx="90" ry="120" fill="none" stroke="white" strokeWidth="2" />
                                            <path d="M 40 40 Q 60 0 100 40 T 160 40" fill="none" stroke="white" strokeWidth="2" />
                                            <path d="M 40 160 Q 60 200 100 160 T 160 160" fill="none" stroke="white" strokeWidth="2" />
                                        </svg>
                                    </div>
                                </div>
                            </Card>
                        </Badge.Ribbon>
                        {isLoading ? <span className="faceScannerWaitScreenloader" style={{margin: '3rem'}}></span> : null}
                    </div>
                </>
            ) : (
                <FaceRegAnalyzerComponent {...props} capturedScannedImageUrl={capturedScannedImageUrl} />
            )}

        </div>
    );
};

export default FaceRecognitionComponent;