import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import { makeFirstLetterCapital, CustomNoDataComponent, convertInventoryDateTimezone } from '../../../../../utility/utility';
import { ClapSpinner } from 'react-spinners-kit';
import '../../../../../../node_modules/react-table-v6/react-table.css';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../../redux/actions/index';
import ReactTooltip from 'react-tooltip';

const PickupsTicketTable = (props) => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const SearchValue = useSelector(state => state.form.searchPickupsTicketForm.values);
  const DateGenerate = useSelector(state => state.InventoryReducer.dataGenerated);
  const SearchOrResetPickupsTicketListClicked = useSelector(state => state.InventoryReducer.SearchOrResetPickupsTicketListClicked);
  const No_of_pages = useSelector(state => state.InventoryReducer.pickupsTicket_total_pages);
  const No_of_rows = useSelector(state => state.InventoryReducer.pickupsTicket_no_of_rows);
  const pickupsTicketCurPage = useSelector(state => state.InventoryReducer.pickupsTicketCurPage);

  const data = props.pickupsTicketList && props.pickupsTicketList.map((ticket) => {
    debugger
    return {
        id: ticket.id,
        jobName: makeFirstLetterCapital(ticket.account.name),
        deliveryDate: ticket.delivery_at ? ticket.delivery_at : '–',
        createdBy: ticket.manager ? `${makeFirstLetterCapital(ticket.manager.first_name)} ${makeFirstLetterCapital(ticket.manager.last_name)}` : '' ,
        createdDateTime: ticket.created_at ? convertInventoryDateTimezone(ticket.created_at, ticket.manager.account_timezone).toUpperCase() : '' ,
        fulfillmentManager_location : ticket.location ? `${makeFirstLetterCapital(ticket.location.name)}` : '-',
        approver: ticket.approver ? `${makeFirstLetterCapital(ticket.approver.first_name)} ${makeFirstLetterCapital(ticket.approver.last_name)}` : '–',
        status: ticket.status == 'readyForPickup' ? 'Ready For Pickup' : makeFirstLetterCapital(ticket.status),
        emergency: ticket.is_emergency && ticket.is_emergency == true ? 'Yes' : 'No',
        actions:
        <div>
          {(
            <span className="mr_20 account_edit_icn" onClick={() => {props.history.push(
              ticket.status == 'readyForPickup' ? `/pickup-details/${ticket.id}` : 
              ticket.status == 'picked' ? `/inventoryScan/${ticket.id}/forPickedDelivery_qr` :
              ticket.status == 'delivered' && ticket.returnItems && ticket.returnItems.length > 0 ? `/scan-for-deliver/return` : 
              `/audit-logs/${ticket.id}`
            )}} data-tip={ticket.status == 'readyForPickup' ? 'Pickup' : ticket.status == 'picked' ? 'Deliver' : ticket.status == 'delivered' ? 'Warhouse Return' : 'View'}>
              <i class={ticket.status == 'readyForPickup' ? 'fas fa-cube' : ticket.status == 'picked' ? 'fas fa-truck' : ticket.status == 'delivered' ? 'fas fa-home' : 'fas fa-eye'} aria-hidden="true"></i>
              <ReactTooltip place="top" type="dark" effect="solid" />
            </span>
          )}
        </div>
    }
  })


  const columns = [
    {
        Header: <><span> ID </span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'id', // String-based value accessors!,
        show: props.pickupsTicketList.length !== 0,
        width: 100
    },
    {
        Header: <><span> Job Name </span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'jobName', // String-based value accessors!,
        show: props.pickupsTicketList.length !== 0,
    },
    {
        Header: <><span> Delivery Date </span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'deliveryDate', // String-based value accessors!,
        show: props.pickupsTicketList.length !== 0,
    },
    {
        Header: <><span> Created By </span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'createdBy',
        show: props.pickupsTicketList.length !== 0,
    },
    {
        Header: <><span> Created Date & Time </span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'createdDateTime',
        show: props.pickupsTicketList.length !== 0,
    },
    {
        Header: <><span> Fulfillment Manager Location </span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'fulfillmentManager_location',
        show: props.pickupsTicketList.length !== 0,
    },
    {
        Header: <><span> Approver </span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'approver',
        show: props.pickupsTicketList.length !== 0,
    },
    {
        Header: <><span> Status </span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'status',
        show: props.pickupsTicketList.length !== 0,
        width: 100
    },
    {
        Header: <><span> Emergency </span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'emergency',
        show: props.pickupsTicketList.length !== 0,
        width: 100
    },
    {
        Header: " ",
        accessor: "actions",
        show: props.pickupsTicketList.length !== 0,
        width: 50
    }
  ]

  const [tablePage, setTablePage] = useState(0)
  const tablePageNew =  React.useRef()
  if(pickupsTicketCurPage !== tablePageNew.current) {
    tablePageNew.current = pickupsTicketCurPage;
  }
  const setTablePageFn = (page) => {
      setTablePage(page)
      props.pickupsTicketListPageClicked(page)
      tablePageNew.current = page;
  }

  const fetchData = (state, instance) => {
    if (SearchOrResetPickupsTicketListClicked) {
      dispatch(actions.resetSearchOrResetInventory())
    }else {
      if(!DateGenerate) {
        state.page = 2;
        const limit = state.pageSize;
        const fl_users_no_of_rows = state.pageSize;
        let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : pickupsTicketCurPage + 1;
        if(No_of_rows != fl_users_no_of_rows) {
            page = 1;
            setTablePageFn(0)
        }
        const sorted = state.sorted;
        const fulfillment_manager_location = SearchValue.fulfillment_manager_location ? SearchValue.fulfillment_manager_location : '';
        const approver = SearchValue.approver_name ? SearchValue.approver_name : '';
        const status = SearchValue.status ? SearchValue.status : 'readyForPickup';
        const id = SearchValue.id ? SearchValue.id : '';
        const is_emergency = SearchValue.is_emergency ? SearchValue.is_emergency : '';
        props.getAllPickupsTicketList({ page, limit, sorted, fulfillment_manager_location, approver, status,id, is_emergency });
      }
    }
    // }
  }

  return (
    SearchOrResetPickupsTicketListClicked && props.isLoading ?
      <div className="loader_btn_block">
        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
      </div>
      :
      <> <ReactTable
        data={data}
        loading={props.isLoading}
        defaultPageSize={No_of_rows ? No_of_rows : pageSize}
        minRows={0}
        manual
        onFetchData={fetchData}
        pages={No_of_pages}
        gotoPage={pickupsTicketCurPage}
        page = { pickupsTicketCurPage }
        onPageChange={(page) => {setTablePageFn(page)}}
        onPageSizeChange={(e) => setPageSize(e)}
        showPaginationTop={true}
        showPagination={props.pickupsTicketList.length !== 0}
        columns={columns}
        NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Pickups Ticket Found')}
        getTheadThProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              borderTop: 'none',
              borderBottom: 'none',
              borderRight: 'none',
              textAlign: 'center',
              color: '#566975',
              fontSize: '14px',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              float: 'left',
            }
          }
        }
        }
        LoadingComponent={() =>
          props.isLoading ?
            <div style={{
              display: "block",
              position: "absolute",
              left: 0,
              height: '100%',
              right: 0,
              background: "rgba(255,255,255,0.8)",
              transition: "all .3s ease",
              top: 0,
              bottom: 0,
              textAlign: "center"
            }}
              className="loader_btn_block">
              <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div> : null}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            className: 'react-table-tr-element',
            style: {
              border: '1px solid #c7cdd1',
              transition: '.3s ease-in-out',
              width: '100%',
              marginTop: '5px',
              float: 'left'
            }
          }

        }}
        getTdProps={(state, rowInfo, column, instance) => {

          if (column.id == 'image') {
            return {
              style: {
                marginTop: '0px'
              }
            }
          } else {
            return {
              className: 'react-td-element',
            }
          }
        }
        }
        getProps={() => {
          return {
            style: {
              border: 'none'
            }
          }
        }}
      />
    </>
  )
}

export default PickupsTicketTable;