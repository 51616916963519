import React, { useEffect } from 'react'
import NewJobCardsComponent from '../../../../../component/FormAuditModule/BuildingProfileComponent/JobCards/NewJobCards/NewJobCardsComponent'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch, useSelector } from 'react-redux'
import { getAllJob, getUsersListWithoutClient } from '../../../../../../redux/actions/FormAuditModAction/TeamManagementAction/teamManagementAction'
import { addRequestFormDetails, getSingleRequestFormDetails, editRequestFormDetails } from '../../../../../../redux/actions/FormAuditModAction/TagModuleAction/TagModuleAction'
import moment from 'moment'
import NewRequestForm from '../../../../../component/FormAuditModule/TagModule/NewRequestForm/NewRequestForm'
import { routes } from '../../../../../../utility/constants/constants'
import SuccessAlert from '../../../../../../utility/successAlert/successAlert'

function NewRequestFormContainer() {
  const { isLoading, isUpdateRequestForm, requsetFormDetails } = useSelector((state) => state.TagModuleReducer)
  const { location: { pathname }, push } = useHistory()
  const { id } = useParams()
  const isEdit = Boolean(pathname.split("/")[2] !== "view" && id)
  const isView = Boolean(pathname.split("/")[2].includes("view"))
  const dispatch = useDispatch()

  const structureTask = (task) => {
    return {
      detail_time: task.detail_time.split(" ")[0],
      shift_length: task.shift_length.split(" ")[0],
      friday_project_time: task.friday_project_time.split(" ")[0],
      routine_time_3c: task.routine_time_3c.split(" ")[0],
      routine_time_4c: task.routine_time_4c.split(" ")[0],
    }
  }

  const MomentStringToHour = (str, format = "HH:mm:ss") => {

    let time = moment(str, format);

    return time
  }
  const structureSection = (section) => {
    const { lineItems, created_at, updated_at, ...remainItem } = section
    const data = {
      ...remainItem,
      lineItems: section.lineItems.map((item) => {
        const { created_at, updated_at, ...remainItem } = item
        return {
          ...remainItem
        }
      })
    }

    return data;
  }



  const convertDate = (date) => {
    if (date) {
      var parts = date.split('T');
      var datePart = parts[0];
      let dt = moment(datePart)._d
      return dt
    }
  }



  const defaultForm = {
    date: isView || isEdit ? requsetFormDetails?.date ? convertDate(requsetFormDetails?.date) : "" : "",
    completed: isView || isEdit ? typeof requsetFormDetails?.completed == "boolean" ? requsetFormDetails.completed ? "Yes" : "No" : "" : "",
    request: isView || isEdit ? requsetFormDetails?.request ? requsetFormDetails.request : "" : "",
    requester: isView || isEdit ? requsetFormDetails?.requester ? requsetFormDetails.requester : "" : "",
    given_to_id: isView || isEdit ? requsetFormDetails?.given_to_id ? requsetFormDetails.given_to_id : "" : "",
    notes: isView || isEdit ? requsetFormDetails?.notes ? requsetFormDetails.notes : "" : "",
    sections: isView || isEdit ? requsetFormDetails?.sections ? structureSection(requsetFormDetails?.sections) : {
      lineItems: [{
        name: "COMPLAINT",
        checked: false,
        url: "/images/thumbnails/complaint.png",
        sort: 1
      },
      {
        name: "CLEAN & DUST",
        checked: false,
        url: "/images/thumbnails/cleandust.png",
        sort: 2
      },
      {
        name: "SUPPLIES",
        checked: false,
        url: "/images/thumbnails/supplies.png",
        sort: 3,
      },
      {
        name: "TRASH",
        checked: false,
        url: "/images/thumbnails/trashb.png",
        sort: 4
      },
      {
        name: "SET-UP",
        checked: false,
        url: "/images/thumbnails/setup.png",
        sort: 5
      },
      {
        name: "PLUMBING",
        checked: false,
        url: "/images/thumbnails/plumbing.png",
        sort: 6
      },
      {
        name: "ELECTRIAL",
        checked: false,
        url: "/images/thumbnails/electrical.png",
        sort: 7
      },
      {
        name: "LOCK",
        checked: false,
        url: "/images/thumbnails/lock.png",
        sort: 8
      },

      ],
    } : {
      lineItems: [{
        name: "COMPLAINT",
        checked: false,
        url: "/images/thumbnails/complaint.png",
        sort: 1
      },
      {
        name: "CLEAN & DUST",
        checked: false,
        url: "/images/thumbnails/cleandust.png",
        sort: 2
      },
      {
        name: "SUPPLIES",
        checked: false,
        url: "/images/thumbnails/supplies.png",
        sort: 3,
      },
      {
        name: "TRASH",
        checked: false,
        url: "/images/thumbnails/trashb.png",
        sort: 4
      },
      {
        name: "SET-UP",
        checked: false,
        url: "/images/thumbnails/setup.png",
        sort: 5
      },
      {
        name: "PLUMBING",
        checked: false,
        url: "/images/thumbnails/plumbing.png",
        sort: 6
      },
      {
        name: "ELECTRIAL",
        checked: false,
        url: "/images/thumbnails/electrical.png",
        sort: 7
      },
      {
        name: "LOCK",
        checked: false,
        url: "/images/thumbnails/lock.png",
        sort: 8
      },

      ],
    }

  }

  const MomentFormatToHour = (date, format = "HH:mm:ss") => {

    return date.format(format)
  }







  const structureBody = (data) => {


    let body = null
    const { sections } = data
    let newSection = sections
    newSection.checked_count = sections.lineItems.filter((item) => item.checked == true).length
    newSection.total_count = sections.lineItems.length
    newSection.lineItems = sections.lineItems.map((item) => {
      const { url, ...restParams } = item
      return { ...restParams }
    })
    if (isEdit) {
      const { updated_at, created_at, sections, ...remainData } = data
      const newSections = {
        ...sections,
        checked_count: sections.lineItems.filter((item) => item.checked == true).length,
        total_count: sections.lineItems.length
      }

      body = { ...remainData, date: moment(remainData.date).format('YYYY-MM-DD'), user_id: requsetFormDetails.user_id, "active_status": requsetFormDetails.active_status, id: requsetFormDetails.id, completed: data?.completed === "Yes" ? true : false, sections: newSections }
    } else {

      body = { ...data, date: moment(data.date).format('YYYY-MM-DD'), sections: newSection, completed: data?.completed === "Yes" ? true : false }

    }

    return { data: [body] };
  }

  const onSubmit = (data) => {

    if (isEdit) {
      dispatch(editRequestFormDetails(structureBody(data)))

    } else {

      dispatch(addRequestFormDetails(structureBody(data)))
      //  SuccessAlert('Request Form Added Successfully')
      //  push(routes.REQUSET_FORM)

    }


  }

  useEffect(() => {
    dispatch(getUsersListWithoutClient({ roles: 'frontline' }))

  }, [])

  useEffect(() => {
    if (isEdit || (isView && id)) {

      dispatch(getSingleRequestFormDetails(id));
    }
  }, [id]);

  return (
    <NewRequestForm
      elements={defaultForm}
      formSubmittedCallback={onSubmit}
      isEdit={isEdit}
      isView={isView} 
      modalUpdate={true}
      />
  )
}

export default NewRequestFormContainer
