import React from 'react';

let MealBreakConcludingModal = (props) => {

    const { isLoading, mealBreakItem, handleFrequentTimeTracker_closeModal, handleFeedGapEnd } = props;

    return (
        <>

            <div className="modal fade request_quote_modal reject_quot associateUser" id="concludeMealBreak" tabindex="-1" role="dialog" aria-labelledby="rejectQuoteModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header"></div>
                        <div className="modal-body">
                            <div className="tab-content">
                                <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
                                    <form className="form_content">
                                        <div className="row" style={{ marginBottom: '-15px' }}>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="col-md-12 form_title text-center" style={{ fontSize: '25px' }}>
                                                        The meal break has concluded and work hours have now resumed
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={handleFrequentTimeTracker_closeModal} type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal"> Cancel </button>
                                            <button id='mealBreakConclude' key={mealBreakItem.id} onClick={() => { handleFeedGapEnd(mealBreakItem) }} data-test="submit_button" type="button" disabled={isLoading} className={isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} > {isLoading ? "" : "Yes"} </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
};

export default MealBreakConcludingModal;