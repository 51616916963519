import React from 'react';
import CHIServy from '../../component/CHIServys/CHIServys';
import * as actions from '../../../redux/actions/index';
import NewUser from '../../component/user/NewUser/newUser';
import { connect } from 'react-redux';
import { animateScroll as scroll} from 'react-scroll'
import { currentData, userRoles, routes } from '../../../utility/constants/constants';
import { GetFormatedDate, getNextMonthDate, getNextYearDate, getPreviousMonthDate, getPreviousYearDate, getUser, get_next_week_start, get_previous_week_start } from '../../../utility/utility';

class CHIServysContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // isWeek: true,
            // isMonth: false,
            // isYear: false,
        }
    }
    componentDidMount = () => {
        // if(!this.props.chiChartData) {
        const userDetails = getUser();
        const date = get_next_week_start(this.props.currentDate);
        this.props.getCHIDashboard(GetFormatedDate(this.props.currentDate), userDetails.mode, currentData.WEEK)
        this.props.getAllCHIServyResponse( 10, 1, GetFormatedDate(date),currentData.WEEK, '', '');
        this.props.updateCurrentDate(this.props.currentDate);
        this.props.updateWeekMonthYear(true, false, false);
        // }
        scroll.scrollToTop();
    }
    previewChiClicked = (chi) => {
        // this.props.history.push(routes.PREVIEW_CHI)
        this.props.previewChiClicked(chi)
    }
    nextWeekMonthYearCliked = () => {
        const userDetails = getUser();
        if (this.props.isWeek) {
            const date = get_next_week_start(this.props.currentDate);
            this.props.getCHIDashboard(GetFormatedDate(date), userDetails.mode, currentData.WEEK)
            this.props.getAllCHIServyResponse( 10, 1, GetFormatedDate(date),currentData.WEEK, '', '');
            this.props.updateCurrentDate(date);

        } else if (this.props.isMonth) {
            if (this.props.currentDate.getMonth() === 11) {
                const date = getNextYearDate(this.props.currentDate);
            this.props.getCHIDashboard(GetFormatedDate(date), userDetails.mode, currentData.MONTH)
            this.props.getAllCHIServyResponse( 10, 1, GetFormatedDate(date),currentData.MONTH, '', '');
                this.props.updateCurrentDate(getNextYearDate(this.props.currentDate));
            } else {
                const date = getNextMonthDate(this.props.currentDate);
            this.props.getCHIDashboard(GetFormatedDate(date), userDetails.mode, currentData.MONTH)
            this.props.getAllCHIServyResponse( 10, 1, GetFormatedDate(date),currentData.MONTH, '', '');
                this.props.updateCurrentDate(getNextMonthDate(this.props.currentDate));
            }
        } else if (this.props.isYear) {
            const date = getNextYearDate(this.props.currentDate);
            this.props.getCHIDashboard(GetFormatedDate(date), userDetails.mode, currentData.YEAR)
            this.props.getAllCHIServyResponse( 10, 1, GetFormatedDate(date),currentData.YEAR, '', '');
            this.props.updateCurrentDate(getNextYearDate(this.props.currentDate));
        }
    }

    previousWeekMonthYearCliked = () => {
        const userDetails = getUser();
        if (this.props.isWeek) {
            const date = get_previous_week_start(this.props.currentDate);
            this.props.getCHIDashboard(GetFormatedDate(date), userDetails.mode, currentData.WEEK)
            this.props.getAllCHIServyResponse( 10, 1, GetFormatedDate(date),currentData.WEEK, '', '');
            this.props.updateCurrentDate(date);
        } else if (this.props.isMonth) {
            if (this.props.currentDate.getMonth() === 0) {
                const date = getPreviousYearDate(this.props.currentDate);
            this.props.getCHIDashboard(GetFormatedDate(date), userDetails.mode, currentData.MONTH)
            this.props.getAllCHIServyResponse( 10, 1, GetFormatedDate(date),currentData.MONTH, '', '');
                this.props.updateCurrentDate(getPreviousYearDate(this.props.currentDate));
            } else {
                const date = getPreviousMonthDate(this.props.currentDate);
            this.props.getCHIDashboard(GetFormatedDate(date), userDetails.mode, currentData.MONTH)
            this.props.getAllCHIServyResponse( 10, 1, GetFormatedDate(date),currentData.MONTH, '', '');
                this.props.updateCurrentDate(getPreviousMonthDate(this.props.currentDate));
            }
        } else if (this.props.isYear) {
            const date = getPreviousYearDate(this.props.currentDate);
            this.props.getCHIDashboard(GetFormatedDate(date), userDetails.mode, currentData.YEAR)
            this.props.getAllCHIServyResponse( 10, 1, GetFormatedDate(date),currentData.YEAR, '', '');
            this.props.updateCurrentDate(getPreviousYearDate(this.props.currentDate));
        }
    }
    viewModeClicked = (viewMode) => {
        const userDetails = getUser();
        if (viewMode === currentData.WEEK) {
            this.props.updateWeekMonthYear(true, false, false);
            this.props.getCHIDashboard(GetFormatedDate(this.props.currentDate), userDetails.mode, currentData.WEEK)
            this.props.getAllCHIServyResponse( 10, 1, GetFormatedDate(this.props.currentDate),currentData.WEEK, '', '');
            console.log(GetFormatedDate(this.props.currentDate))

        } else if (viewMode === currentData.MONTH) {
            this.props.updateWeekMonthYear(false, true, false)
            this.props.getCHIDashboard(GetFormatedDate(this.props.currentDate), userDetails.mode, currentData.MONTH)
            this.props.getAllCHIServyResponse( 10, 1, GetFormatedDate(this.props.currentDate),currentData.MONTH, '', '');

            console.log(GetFormatedDate(this.props.currentDate))

        } else if (viewMode === currentData.YEAR) {
            this.props.updateWeekMonthYear(false, false, true)
            this.props.getCHIDashboard(GetFormatedDate(this.props.currentDate), userDetails.mode, currentData.YEAR)
            this.props.getAllCHIServyResponse( 10, 1, GetFormatedDate(this.props.currentDate),currentData.YEAR, '', '');
            console.log(GetFormatedDate(this.props.currentDate))

        }
    }

    render() {
        return (
            <CHIServy
                {...this.props}
                getAllCHIServyResponse={this.props.getAllCHIServyResponse}
                previewChiClicked={this.previewChiClicked}
                nextWeekMonthYearCliked={this.nextWeekMonthYearCliked}
                previousWeekMonthYearCliked={this.previousWeekMonthYearCliked}
                viewModeClicked={this.viewModeClicked}
                chi_surveyLoading={this.props.chi_surveyLoading}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        chiServyList: state.adminOrSuperAdminReducer.chiServyList ? state.adminOrSuperAdminReducer.chiServyList : [],
        chiChartData: state.adminOrSuperAdminReducer.chiChartData,
        currentDate: state.adminOrSuperAdminReducer.currentDate,
        isWeek: state.adminOrSuperAdminReducer.isWeek,
        isMonth: state.adminOrSuperAdminReducer.isMonth,
        isYear: state.adminOrSuperAdminReducer.isYear,
        chi_surveyLoading: state.adminOrSuperAdminReducer.chi_servyLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllCHIServyResponse: (chi_no_of_rows, page, date, option, sorted, filtered) => dispatch(actions.getAllCHIServyResponse(chi_no_of_rows, page, date, option, sorted, filtered)),
        getCHIDashboard: ( date, mode, option) => dispatch(actions.getCHIDashboard(date, mode, option)),
        updateCurrentDate: (date) => dispatch(actions.updateCurrentDate(date)),
        updateWeekMonthYear: (isWeek, isMonth, isYear) => dispatch(actions.updateWeekMonthYear(isWeek, isMonth, isYear)),
        previewChiClicked: (chi) => dispatch(actions.previewChiClicked(chi)),
        chiPageClicked: (page) => dispatch(actions.chiPageClicked(page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CHIServysContainer);