import React, { Component } from 'react';
import Accounts from '../../component/Accounts/accounts';
import * as actions from '../../../redux/actions/index';
import { routes } from '../../../utility/constants/constants';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { userRoles } from '../../../utility/constants/constants';
import storage from '../../../utility/storage';
import { getUser, convertDateToDifferentTZ } from '../../../utility/utility';
import { animateScroll as scroll} from 'react-scroll'
import moment from 'moment';

class AccountsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // activeJobs: localStorage.getItem('job_active') ? localStorage.getItem('job_active') === 'true' ? true : false : true,
        }
    }

    newAccountClicked = () => {
        this.props.history.push(routes.NEW_ACCOUNTS);
    }

    deleteAccount = (id) => {
        this.props.deleteAccount(id);
    }

    componentDidMount() {
        // if (!this.props.accountsList) {
        //     this.props.getAccounts();
        // }
        // this.setState({activeJobs: localStorage.getItem('job_active') === 'true' ? true : false});
        scroll.scrollToTop();
    }
    componentDidUpdate (prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
        if(prevProps.user.selected_mode !== user.selected_mode) {
            this.props.accountPageClicked(0)
        }
    }
    }
    editAccountClicked = (account) => {

        this.props.editAccountClicked(account);
    }

    searchAccountClicked = (values) => {
        this.props.accountPageClicked(0)
        if(values.name) {
            values.name = values.name.trim()
        }
        if(values.accountNumber) {
            values.accountNumber = values.accountNumber.trim()
        }
        values.active = this.props.activeJobs;
        this.props.searchAccount(values, this.props.user);
    }

    activeJobsClicked = (e) => {
        this.props.accountActiveChanged()
    }
    // this.props.SearchAreaName(values, this.props.user);

    resetClicked = () => {
        this.props.reset('searchAccountForm');
        this.props.accountPageClicked(0)
        if (this.props.user.role === userRoles.SUPER_ADMIN) {
            this.props.getAccounts({active: this.props.activeJobs}, this.props.accounts_rows);
        } else {
            this.props.getUserAccounts({active: this.props.activeJobs}, 10, 1, [], [], this.props.user.id);
        }
    }

    handleClickDeleteModel = (account) => {

        this.props.handleClickDeleteModel(account);
    }

    handleDeleteClose = () => {
        this.props.handleDeleteClose();
    }

    onAccountExportClicked = () => {
        const userDetails = getUser()
        this.props.getAccountExportData(userDetails.mode).then(response => {
            let ele = document.getElementById('accountExport')
                ele.click()
        })
    }

    onAccountsPageSizeChange = (pageNo) => {
        let rows = {
            account_no_of_rows: pageNo
        }
        // this.props.onAccountsPageSizeChange(rows);
    }

    previewAccountClicked = (account) => {

        this.props.previewAccountUsers(account);
        this.props.history.push(`/accounts/${account.user ? account.user.id : account.id}/account_users?job_users=true`);
    }
    schedulePreviewAccountClicked = (account) => {
        this.props.previewAccountUsers(account);
        this.props.history.push(`/accounts/${account.user ? account.user.id : account.id}/account_users/schedule-time`);
    }
    handleScheduleClickDeleteModel = (account) => {
        let today = moment(new Date()).subtract(0, "days").format("YYYY-MM-DD");
        let tomorrow = moment(new Date()).subtract(-1, "days").format("YYYY-MM-DD");
        let startDate = convertDateToDifferentTZ(today, 'from');
        let endDate = convertDateToDifferentTZ(tomorrow, 'to');
        this.props.handleScheduleClickDeleteModel(account);
        if(this.props.user.role === userRoles.ADMIN){
            this.props.getAccountSchedule(account.id, startDate, endDate)
            // this.props.getSchedule(account.id)
        }else if(this.props.user.role === userRoles.SUPER_ADMIN){
            this.props.getAccountSchedule(account.user.id, startDate, endDate)
            // this.props.getSchedule(account.user.id)
        }
        
    }
    render() {

        return (
            <Accounts
                {...this.props}
                resetClicked={this.resetClicked}
                onSubmit={this.searchAccountClicked}
                previewAccountClicked={this.previewAccountClicked}
                editAccountClicked={this.editAccountClicked}
                deleteAccount={this.deleteAccount}
                onAccountsPageSizeChange={this.onAccountsPageSizeChange}
                accountsList={this.props.accountsList}
                isLoading={this.props.isLoading}
                newAccountClicked={this.newAccountClicked}
                handleDeleteClose={this.handleDeleteClose}
                openDeleteModel={this.props.openDeleteModel}
                handleClickDeleteModel={this.handleClickDeleteModel}
                deleteObject={this.props.deleteObject}
                accounts_rows={this.props.accounts_rows}
                onAccountExportClicked={this.onAccountExportClicked}
                activeJobs={this.props.activeJobs}
                activeJobsClicked={this.activeJobsClicked}
                handleScheduleClickDeleteModel={this.handleScheduleClickDeleteModel}
                scheduleWithAccountId={this.props.scheduleWithAccountId}
                openScheduleConfirmDeleteModel={this.props.openScheduleConfirmDeleteModel}
                scheduleId={this.props.scheduleId}
                schedulePreviewAccountClicked={this.schedulePreviewAccountClicked}
            // handleClose={this.handleClose} 
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.adminOrSuperAdminReducer.account,
        accountsList: state.adminOrSuperAdminReducer.accountsList,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        accounts_rows: state.adminOrSuperAdminReducer.accounts_rows,
        user: state.authReducer.user,
        accountExportDataList: state.adminOrSuperAdminReducer.accountExportData,
        activeJobs: state.adminOrSuperAdminReducer.activeJobs,
        accountCurPage: state.adminOrSuperAdminReducer.accountCurPage,
        scheduleWithAccountId: state.adminOrSuperAdminReducer.scheduleWithAccountId,
        openScheduleConfirmDeleteModel: state.adminOrSuperAdminReducer.openScheduleConfirmDeleteModel,
        scheduleId: state.adminOrSuperAdminReducer.scheduleId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // handleClose: () => dispatch(actions.handleClose()),
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        onAccountsPageSizeChange: (rows) => dispatch(actions.onAccountsPageSizeChange(rows)),
        handleClickDeleteModel: (account) => dispatch(actions.handleClickDeleteModel(account)),
        searchAccount: (values, user) => dispatch(actions.searchAccount(values, user)),
        editAccountClicked: (account) => dispatch(actions.editAccountClicked(account)),
        deleteAccount: (id) => dispatch(actions.deleteAccount(id)),
        getAccounts: (values, account_no_of_rows, page, sorted, filtered) => dispatch(actions.getAccounts(values, account_no_of_rows, page, sorted, filtered)),
        getUserAccounts: (values, account_no_of_rows, page, sorted, filtered, userId) => dispatch(actions.getUserPaginationAccounts(values, account_no_of_rows, page, sorted, filtered, userId)),
        previewAccountUsers: (account) => dispatch(actions.previewAccountUsers(account)),
        reset: (searchAccountForm) => dispatch(reset(searchAccountForm)),  // requires form name
        getAccountExportData: (mode) => dispatch(actions.getAccountExportData(mode)),
        accountActiveChanged: () => dispatch(actions.accountActiveChanged()),
        accountPageClicked: (page) => dispatch(actions.accountPageClicked(page)),
        handleScheduleClickDeleteModel: (criteria) => dispatch(actions.handleScheduleClickDeleteModel(criteria)),
        getAccountSchedule: (id, startDate, endDate) => dispatch(actions.getAccountSchedule(id, startDate, endDate)),
        getSchedule: (id) => dispatch(actions.getSchedule(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsContainer);