import React, { Component } from 'react';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { decode, encode } from 'base64-arraybuffer';
import { convertDateToPickerFormat, getFirstAndLastOfMonth, getUser, ModalKpiGetFormatedDate, extractExtention} from '../../../../../utility/utility';
import * as commonService from "../../../../../utility/utility";
import { reset } from 'redux-form';
import moment from 'moment';
import NewProjectModule from '../../../../component/FormAuditModule/ProjectModule/NewProjectModule/newProjectModule';
import SuccessAlert from '../../../../../utility/successAlert/successAlert';
import { routes } from '../../../../../utility/constants/constants';
const cloneDeep = require('clone-deep');

class NewProjectModuleContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createScheduleTicketData: {},
            faultImages: 0,
            isImagesEmpty: false,
            imageName: '',
            idsToDelete: [],
            imageUrl: null,
            base64: [],
            // savedPortfolioURls: this.props.ticket.attachments_attributes ? this.props.ticket.attachments_attributes : [],
            AllFileTypes: [],
            fileType: '',
        }
        this.inputOpenFileRef = React.createRef();
        this.onPortfolioFileChange = this.onPortfolioFileChange.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
    }

    componentDidMount() {
        const user = getUser();
        this.props.getModeAccounts(user.mode);
        this.props.getCategories({hide: false});
            this.props.getManagersList({manager : true})
        if(this.props.match.params.accountId) {
            this.props.getAccountAreas({}, this.props.match.params.accountId);
            this.props.getAccountUsersInfo(this.props.match.params.accountId);
            this.props.getAccountUsersInfoPage(this.props.match.params.accountId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps && JSON.stringify(prevProps.accountsList) != JSON.stringify(this.props.accountsList)) {
            if (this.props.accountsList && this.props.accountsList.length > 0) {
                this.props.accountsList.map((acc) => {
                    if (acc.id === this.props.match.params.accountId) {
                        this.props.setCurrentAccount(acc);
                    }
                })
            }
        }
    }

    onPortfolioFileChange = (e) => {
        this.setState({
            faultImages: 0
        });
        console.log(this.state.base64);
        let files = e.target.files;
        let imageName = [], fileType = [], AllFileTypes = [], base64 = [];
        const that = this;
        // for (let i = 0; i < files.length; i++) {
        async function readFile(index) {
            if (index < files.length) {
                let reader = new FileReader();
                let file = files[index];
                imageName.push(file.name)
                fileType.push(file.type)
                AllFileTypes.push(file.type)

                if (file.type.match('image')) {

                    let fileDate = await commonService.compressImage(file, {});
                    let reader = new FileReader();
                    reader.readAsDataURL(fileDate);
                    reader.onload = function () {
                        // setImageData({ ...imageData, base64: reader.result, file: file });
                        base64.push(reader.result)
                        if (index === files.length - 1) {
                            //   
                            that.setState(prevState => ({
                                base64: [...prevState.base64, ...base64]
                            }))
                        }
                    };
                    reader.onerror = function (error) {
                        console.log("Error: ", error);
                    };
                    if (index === files.length - 1) {
                        //   
                        that.setState(prevState => ({
                            imageName: [...prevState.imageName, ...imageName],
                            fileType: [...prevState.fileType, ...fileType],
                            AllFileTypes: [...prevState.AllFileTypes, ...AllFileTypes],
                            // arrayBuffer: [...prevState.arrayBuffer, decode(reader.result)],
                            // base64: [...prevState.base64, ...base64]
                        }))
                    }
                    readFile(index + 1);

                }
                // reader.readAsArrayBuffer(file)
                // reader.readAsDataURL(file)
            }
        }

        readFile(0);
    }

    removeImage = (url, fileTypeIndex) => {
        let updatedUrls = [...this.state.base64];
        let updatedFileTypes = [...this.state.fileType]
        let updatedImageNames = [...this.state.imageName]

        let urlIndex = this.state.base64.findIndex(function (u) {
            return u === url
        })
        updatedUrls.splice(urlIndex, 1);
        updatedFileTypes.splice(fileTypeIndex, 1)
        updatedImageNames.splice(fileTypeIndex, 1)

        this.setState({
            base64: updatedUrls,
            fileType: updatedFileTypes,
            imageName: updatedImageNames
        })

    }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }

    getAccountAreasListTrigger = (id) => {
        if(id) {
            this.props.getAccountAreas({}, id);
        }
    }

    getAssignedUsersListTrigger = (id) => {
        if(id) {
            this.props.getAccountUsersInfo(id);
        }
    }

    occurrenceCheckScheduledDates = (checkValues) => {
        let schedule = cloneDeep(checkValues);
        if(schedule.start_date && schedule.end_date) {
            if (schedule.frequency === 'weekly') {
                const start_date = moment(schedule.start_date).format('YYYY-MM-DD');
                const end_date = moment(schedule.end_date).format('YYYY-MM-DD');
                schedule.start_date = start_date;
                schedule.end_date = end_date;
            } else {
                const start_date = moment(schedule.start_date).startOf('month').format('YYYY-MM-DD');
                const end_date = moment(schedule.end_date).endOf('month').format('YYYY-MM-DD');
                schedule.start_date = start_date;
                schedule.end_date = end_date;
            }
            if(schedule.frequency === 'monthly'){ 
                schedule.selected_month = '1';
            }
            this.props.checkSchedule({schedules: schedule});
        }
    }

    addNewScheduleTicket = (formValues) => {
        let scheduleTicketData = cloneDeep(formValues);
        let account_id;
        const user = getUser();
        if (this.props.currentAccount) {
            account_id = this.props.currentAccount.id;
        } else if(scheduleTicketData && scheduleTicketData.scheduleTicket && scheduleTicketData.scheduleTicket.account_id && scheduleTicketData.scheduleTicket.account_id.value) {
            account_id = scheduleTicketData.scheduleTicket.account_id.value;
        }
        if(scheduleTicketData && scheduleTicketData.scheduleTicket && scheduleTicketData.scheduleTicket.reminders) {
            scheduleTicketData.scheduleTicket.reminders.forEach((insert, index) => {
                let hoursValue = insert.hours_value.split(' ');
                if(hoursValue[1] === 'week') {
                    hoursValue[0] = 24 * 7
                }
                let convertedMinute = Number(hoursValue[0]) * 60
                insert.start_at = convertedMinute
                insert.option = insert.ba_value + ' ' + insert.hours_value
                insert.sort = index+1
                delete insert.ba_value;
                delete insert.hours_value;
            });
        }

        if(scheduleTicketData && scheduleTicketData.scheduleTicket && scheduleTicketData.scheduleTicket.manager_id){
            scheduleTicketData.scheduleTicket.manager_id = scheduleTicketData.scheduleTicket.manager_id.value
        } 

        scheduleTicketData.scheduleTicket.account_id = account_id;
        scheduleTicketData.scheduleTicket.created_by = user.id;
        scheduleTicketData.scheduleTicket.assigned_to = scheduleTicketData.scheduleTicket.assigned_to.value;
        // scheduleTicketData.scheduleTicket.area_id = '3116'
        scheduleTicketData.scheduleTicket.category = scheduleTicketData.scheduleTicket.category.value;
        scheduleTicketData.scheduleTicket.is_ticket_recur = JSON.parse(scheduleTicketData.scheduleTicket.is_ticket_recur);

        if(scheduleTicketData && scheduleTicketData.scheduleTicket && scheduleTicketData.scheduleTicket.is_ticket_recur === true) {
            if (scheduleTicketData && scheduleTicketData.scheduleTicket && scheduleTicketData.scheduleTicket.schedule && scheduleTicketData.scheduleTicket.schedule.frequency === 'weekly') {
                const start_date = moment(scheduleTicketData.scheduleTicket.schedule.start_date).format('YYYY-MM-DD');
                const end_date = moment(scheduleTicketData.scheduleTicket.schedule.end_date).format('YYYY-MM-DD');
                scheduleTicketData.scheduleTicket.schedule.start_date = start_date;
                scheduleTicketData.scheduleTicket.schedule.end_date = end_date;
            } else{
                const start_date = moment(scheduleTicketData.scheduleTicket.schedule.start_date).startOf('month').format('YYYY-MM-DD');
                const end_date = moment(scheduleTicketData.scheduleTicket.schedule.end_date).endOf('month').format('YYYY-MM-DD');
                scheduleTicketData.scheduleTicket.schedule.start_date = start_date;
                scheduleTicketData.scheduleTicket.schedule.end_date = end_date;
            }
            if(scheduleTicketData && scheduleTicketData.scheduleTicket && scheduleTicketData.scheduleTicket.schedule && scheduleTicketData.scheduleTicket.schedule.frequency === 'monthly'){ 
                scheduleTicketData.scheduleTicket.schedule.selected_month = '1';
            }
            this.setState({createScheduleTicketData: scheduleTicketData})
            this.props.handleClickScheduleOutlookConfirmModal();
        } else if(scheduleTicketData && scheduleTicketData.scheduleTicket && scheduleTicketData.scheduleTicket.is_ticket_recur === false) {
            const selectedDate = moment(scheduleTicketData.scheduleTicket.start_date).format('YYYY-MM-DD');
            scheduleTicketData.scheduleTicket.start_date = selectedDate;
            this.setState({createScheduleTicketData: scheduleTicketData})
            this.props.handleClickScheduleOutlookConfirmModal();
        }
    }

    newScheduleTicketOutlookTrigger = (dataType) => {
        const isDeepClean = true
        if(dataType === 'yes') {
            setTimeout(() => {
                if(document.getElementsByClassName('outlook-link').length) {
                    document.getElementsByClassName('outlook-link')[0].click()
                }
            }, 500);
        }
        if(this.state.base64.length > 0) {
            const arrayBuffer = [];
            this.state.base64.map((url, i) => {
                if (!url.photo_urls) {
                    let base_photo = null;
                    const image = url.split(',');
                    base_photo = image[1];

                    arrayBuffer.push(decode(base_photo))
                }

            })
            const extentions = extractExtention(this.state.imageName);
            this.props.getScheduleTicketPresignedUrl(this.state.createScheduleTicketData, extentions, arrayBuffer, this.state.idsToDelete, isDeepClean);
        } else {
            this.props.createNewScheduleTicket(this.state.createScheduleTicketData, isDeepClean);
        }
    }

    render() {
        return (
            <NewProjectModule
            {...this.props}
            onSubmit={this.addNewScheduleTicket}
            getAccountAreasListTrigger={this.getAccountAreasListTrigger}
            getAssignedUsersListTrigger={this.getAssignedUsersListTrigger}
            occurrenceCheckScheduledDates={this.occurrenceCheckScheduledDates}
            newScheduleTicketOutlookTrigger={this.newScheduleTicketOutlookTrigger}
            onPortfolioFileChange={this.onPortfolioFileChange}
            removeImage={this.removeImage}
            portfolioImages={this.state.base64}
            reference={this.inputOpenFileRef}
            choosePhotoClicked={this.choosePhotoClicked}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        accountsList: state.adminOrSuperAdminReducer.modeAccountsList,
        currentAccount: state.adminOrSuperAdminReducer.currentAccount,
        accountAreas: state.adminOrSuperAdminReducer.accountAreas,
        accountUsersList: state.adminOrSuperAdminReducer.accountUsersList ? state.adminOrSuperAdminReducer.accountUsersList : [],
        categoriesList: state.adminOrSuperAdminReducer.categoriesList,
        scheduleCheckValues: state.adminOrSuperAdminReducer.scheduleCheckValues,
        openScheduleOccurrenceModal: state.adminOrSuperAdminReducer.openScheduleOccurrenceModal,
        openScheduleOutlookConfirmModal: state.adminOrSuperAdminReducer.openScheduleOutlookConfirmModal,
        managersList: state.FormAuditModReducer.managersList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createNewScheduleTicket: (scheduleTicket, isDeepClean) => dispatch(actions.createNewScheduleTicket(scheduleTicket, isDeepClean)),
        getModeAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
        setCurrentAccount: (account) => dispatch(actions.setCurrentAccount(account)),
        getAccountAreas: (params, id) => dispatch(actions.getAccountAreas({params, id})),
        getAccountUsersInfo: (id) => dispatch(actions.getAccountUsersInfo(id)),
        getCategories: (dataList) => dispatch(actions.getCategories(dataList)),
        checkSchedule: (body) => dispatch(actions.checkSchedule(body)),
        handleClickScheduleOccurrenceModal: () => dispatch(actions.handleClickScheduleOccurrenceModal()),
        handleClickScheduleOutlookConfirmModal: () => dispatch(actions.handleClickScheduleOutlookConfirmModal()),
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        getAccountUsersInfoPage: (id) => dispatch(actions.getAccountUsersInfoPage(id)),
        getScheduleTicketPresignedUrl: (scheduleTicketData, extentions, arrayBuffer, idsToDelete, isDeepClean) => dispatch(actions.getScheduleTicketPresignedUrl(scheduleTicketData, extentions, arrayBuffer, idsToDelete, isDeepClean)),
        getManagersList: (params) => dispatch(actions.getManagersList(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewProjectModuleContainer);