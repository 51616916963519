import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
//import '../../../../../styles/custom.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeFirstLetterCapital, CustomNoDataComponent, getUser, convertInventoryDateTimezone } from '../../../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import '../../../../../../node_modules/react-table-v6/react-table.css';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../../redux/actions/index';
import ImageLoader from 'react-imageloader';

const EmergencyPickupTable = (props) => {
  const [pageSize, setPageSize] = useState(10);
  const [openModel, setOpen] = useState(false);

  const dispatch = useDispatch();
  const SearchValue = useSelector(state => state.form.searchEmergencyPickupListForm && state.form.searchEmergencyPickupListForm.values ? state.form.searchEmergencyPickupListForm.values : {});
  const values = useSelector(state => state.InventoryReducer.emergency_tickets_searchValues);
  const DateGenerate = useSelector(state => state.InventoryReducer.dataGenerated);
  const SearchOrResetEmergencyPickingTicketClicked = useSelector(state => state.InventoryReducer.SearchOrResetEmergencyPickingTicketClicked);
  const No_of_pages = useSelector(state => state.InventoryReducer.pickingTicket_pages);
  const No_of_rows = useSelector(state => state.InventoryReducer.pickingTicket_no_of_rows);
  const emergencyTicketCurPage = useSelector(state => state.InventoryReducer.emergencyTicketCurPage);
  // const fl_user_rows = useSelector(state => state.adminOrSuperAdminReducer.fl_user_rows);
  function preloader() {
    return <img style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} src="/images/gif/giphy.gif" />;
  }

  const data = props.pickupTicketList && props.pickupTicketList.map(ticket => {

    return {
      ticket_id: ticket ? ticket.id : '',
      name: ticket.account ? makeFirstLetterCapital(ticket.account.name) : "",
      created_by: ticket.manager ? `${makeFirstLetterCapital(ticket.manager.first_name)} ${makeFirstLetterCapital(ticket.manager.last_name)} ` : '-'  ,
      created_date: ticket &&  ticket.created_at ? convertInventoryDateTimezone(ticket.created_at, ticket.manager.account_timezone).toUpperCase() : '' ,
      approver: ticket.approver ? `${makeFirstLetterCapital(ticket.approver.first_name)} ${makeFirstLetterCapital(ticket.approver.last_name)} ` : ''  ,
      status: ticket ? ticket.status : '',
      emergency: ticket.is_emergency ? 'Yes' : 'No' ,
      fulfillmentManager_location : ticket.location ? `${makeFirstLetterCapital(ticket.location.name)}` : '-',
      approve : ticket && ticket.status === 'open' ?  <button  type="button" onClick={() => props.approveClicked(ticket.id)} className="btn cst_btn btn-success">Approve</button> : '',
      supervisor: ticket.supervisor ? `${makeFirstLetterCapital(ticket.supervisor.first_name)} ${makeFirstLetterCapital(ticket.supervisor .last_name)} ` : '-'  ,
      reject: ticket && ticket.status === 'open' ? <button  type="button" onClick={() => props.rejectClicked(ticket.id)} className="btn cst_btn btn-danger">Reject</button> : '',
      actions: <>
      <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/audit-logs/${ticket.id}`)}><i class="fas fa-eye" aria-hidden="true" ></i></span>
      </> 

    }
  })


  const columns = [
    {
      Header: <><span>ID</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'ticket_id', // String-based value accessors!,
      show: props.pickupTicketList.length !== 0,
      width: 100
    },
    {
      Header: <><span>Job Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'name', // String-based value accessors!,
      show: props.pickupTicketList.length !== 0,
    },
    {
      Header: <><span>Created By</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'created_by', // String-based value accessors!,
      show: props.pickupTicketList.length !== 0,
    },
    {
        Header: <><span>Created Date & Time</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'created_date', // String-based value accessors!,
        show: props.pickupTicketList.length !== 0,
      },
      {
        Header: <><span> FulFillment Manager Location </span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'fulfillmentManager_location', // String-based value accessors!,
        show: props.pickupTicketList.length !== 0,
      },
      {
        Header: <><span>Approver</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'approver', // String-based value accessors!,
        show: props.pickupTicketList.length !== 0,
        width: 100
      },
      {
        Header: <><span>Supervisor</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'supervisor', // String-based value accessors!,
        show: props.pickupTicketList.length !== 0,
      },
      {
        Header: <><span>Status</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'status', // String-based value accessors!,
        show: props.pickupTicketList.length !== 0,
        width: 100
      },
      {
        Header: <><span>Emergency</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'emergency', // String-based value accessors!,
        show: props.pickupTicketList.length !== 0,
        width: 100
      },
      {
        Header: " ",
        accessor: 'approve', // String-based value accessors!,
        show: props.pickupTicketList[0]?.status === 'open',
        width: 110
      },
      {
        Header: " ",
        accessor: 'reject', // String-based value accessors!,
        show: props.pickupTicketList[0]?.status === 'open',
        width: 100
      },
    {
      Header: " ",
      accessor: "actions",
      show: props.pickupTicketList.length !== 0,
      width: 100
    }
  ]

  const [tablePage, setTablePage] = useState(0)
  const tablePageNew =  React.useRef()
  if(emergencyTicketCurPage !== tablePageNew.current) {
    tablePageNew.current = emergencyTicketCurPage;
  }
  const setTablePageFn = (page) => {
      setTablePage(page)
      props.emergencyTicketPageClicked(page)
      tablePageNew.current = page;
  }

  const fetchData = (state, instance) => {
    if (SearchOrResetEmergencyPickingTicketClicked) {
      dispatch(actions.resetSearchOrResetInventory())
    } else {
      if (!DateGenerate) {
                state.page = 2;
                const limit = state.pageSize;
                const fl_users_no_of_rows = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : emergencyTicketCurPage + 1;
                if(No_of_rows != fl_users_no_of_rows) {
                  page = 1;
                  setTablePageFn(0)
              }
                const sorted = state.sorted;
        //const filtered = state.filtered;
        const manager = SearchValue.manager ? SearchValue.manager : '';
        const fulfillment_manager_location = SearchValue.fulfillment_manager_location ? SearchValue.fulfillment_manager_location : '';
        const approver = SearchValue.approver ? SearchValue.approver : '';
        const status = SearchValue.status ? SearchValue.status : '';
        const is_emergency = true
        const id = SearchValue.id ? SearchValue.id : '';
        props.getEmergencyPickupTickets({page,limit, sorted, manager,fulfillment_manager_location,approver,status, is_emergency, id}, values)
      }
    }
    // }
  }
  const handleConfirm = (confirm) => {
    setOpen(false);
    //props.deleteArea(currentArea.id, props.match.params.accountId)
  };
  return (
    SearchOrResetEmergencyPickingTicketClicked && props.isLoading ?
      <div className="loader_btn_block">
        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
      </div>
      :
      <> <ReactTable
        data={data}
        loading={props.isLoading}
        defaultPageSize={No_of_rows ? No_of_rows : pageSize}
        minRows={0}
        manual
        onFetchData={fetchData}
        pages={No_of_pages}
        gotoPage={emergencyTicketCurPage}
        page = { emergencyTicketCurPage }
        onPageChange={(page) => {setTablePageFn(page)}}
        onPageSizeChange={(e) => setPageSize(e)}
        showPaginationTop={true}
        showPaginationBottom={false}
        showPagination={props.pickupTicketList.length !== 0}
        columns={columns}
        NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Tickets Found')}
        getTheadThProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              borderTop: 'none',
              borderBottom: 'none',
              borderRight: 'none',
              textAlign: 'center',
              color: '#566975',
              fontSize: '14px',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              float: 'left',
            }
          }
        }
        }
        LoadingComponent={() =>
          props.isLoading ?
            <div style={{
              display: "block",
              position: "absolute",
              left: 0,
              height: '100%',
              right: 0,
              background: "rgba(255,255,255,0.8)",
              transition: "all .3s ease",
              top: 0,
              bottom: 0,
              textAlign: "center"
            }}
              className="loader_btn_block">
              <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div> : null}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            className: 'react-table-tr-element',
            style: {
              border: '1px solid #c7cdd1',
              transition: '.3s ease-in-out',
              width: '100%',
              marginTop: '5px',
              float: 'left'
            }
          }

        }}
        getTdProps={(state, rowInfo, column, instance) => {

          if (column.id == 'image') {
            return {
              style: {
                marginTop: '0px'
              }
            }
          } else {
            return {
              className: 'react-td-element',
            }
          }
        }
        }
        getProps={() => {
          return {
            style: {
              border: 'none'
            }
          }
        }}
      />
        <Dialog
          open={openModel}

          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
            <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
          </DialogTitle>

          <DialogActions>
            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => handleConfirm(true)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setOpen(false)} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
          </DialogActions>
        </Dialog>

      </>
  )
}

export default EmergencyPickupTable;