import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Aux from '../../../../hoc/Oux/Oux';
import Cleave from 'cleave.js/react';
import "cleave.js/dist/addons/cleave-phone.us";
import NumberFormat from 'react-number-format';
import { InputNumber } from 'antd';

const renderField = ({ id, input, errorType, label, style, readOnly, placeholder, maxLength, autoComplete, type,dataTest, className, iconClass, min, max,disabled, meta: { touched, asyncValidating, error, warning } }) => {
    
    if (type === 'tel') {
        return (
            <Aux>
                <Cleave {...input}
                    options={{ phone: true, phoneRegionCode: 'US' }}
                    className={className}
                    dataTest={dataTest}
                    placeholder={placeholder}
                    style={(touched && error) ? { borderColor: '#f04d53', borderLeft: '3px solid #f04d53' } : null} />
                {touched &&
                    ((error && <span style={{ color: '#DD2726', fontSize: '13px' }}>{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </Aux>
        )
    } else if (type === 'numberFormate') {
        return (
            <>
                <NumberFormat {...input}
                    format="(###) ###-####" className="form-control input-modifier" placeholder={placeholder} mask="_"
                    style={(touched && error) ? { borderColor: '#f04d53', borderLeft: '3px solid #f04d53' } : null}
                />
                {touched &&
                    ((error && <span style={{ color: '#DD2726', fontSize: '13px' }}>{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </>

        )
    } else if(type === "numberWithIncDec"){
        return (
            <>
                <InputNumber {...input}
                    type='number'
                    className={className}
                    placeholder={placeholder}
                    min={min}
                    max={max}
                    disabled={disabled}
                    size="large"
                    readOnly={readOnly}
                    style={(touched && error) ? { borderColor: '#f04d53', borderLeft: '3px solid #f04d53' } : null}
                />
                {touched &&
                        ((error && <span style={{ color: '#DD2726', fontSize: '13px' }}>{error}</span>) ||
                            (warning && <span>{warning}</span>))}
            </>
        )
    }
    return (
        <Aux>
            <input {...input}
                id={id}
                maxLength={maxLength}
                autoComplete={autoComplete}
                placeholder={placeholder}
                type={type}
                readOnly={readOnly}
                data-test={dataTest}
                className={className}
                autocomplete={"off"}
                label={label}
                disabled={disabled}
                style={(touched && error) ? { display: 'block', borderColor: '#f04d53', borderLeft: '3px solid #f04d53' } : readOnly ? { display: 'block', backgroundColor: '#c7cdd154' } : { display: 'block' }}
            />
            {errorType === 'emptyText' ? null : touched &&
                ((error && <span style={{ color: '#DD2726', fontSize: '13px' }} data-test="error_msg">{error}</span>) ||
                    (warning && <span>{warning}</span>))}
        </Aux>
    );
};

export const InputField = ({ id, errorType, dataTest, disabled, style, type, name, value, label, placeholder, readOnly, maxLength, autoComplete, className, iconClass, errors, mask, borderColor, min, max }) => {
    {
        return (
            <Field
                id={id}
                style={style}
                errorType={errorType}
                type={type}
                readOnly={readOnly}
                placeholder={placeholder}
                name={name}
                value={value}
                label={label}
                maxLength={maxLength}
                autoComplete={autoComplete}
                className={className}
                iconClass={iconClass}
                borderColor={borderColor}
                dataTest={dataTest}
                disabled={disabled}
                min={min}
                max={max}
                {...mask}
                component={renderField} />
        )
    }
};

InputField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    iconClass: PropTypes.string,
    maxLength: PropTypes.number,
    readOnly: PropTypes.bool,
    autoComplete: PropTypes.oneOf(['on', 'off'])
};

InputField.defaultProps = {
    type: 'text',
    label: null,
    placeholder: '',
    className: '',
    maxLength: null,
    autoComplete: null,
    borderColor: '#c3c3c3'
};