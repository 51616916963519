
import jwtDecode from 'jwt-decode';
import $ from "jquery";
import { Status, userRoles, TimeZones, TimezoneMonths, routes } from './constants/constants';
import React from 'react';
import { valueTypes } from 'popmotion';
import * as Scroll from 'react-scroll';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import storage from '../utility/storage';
import imageCompression from 'browser-image-compression';
import { tzDateConverterTool_V1, tzDateConverterTool_V5, tzDateConverterTool_V6, tzTimeConversionTool_V1 } from './TimezoneOperations/timezoneUtility';

var dateFormat = require('dateformat');
var moment = require('moment');
const queryString = require('query-string');
var momentTZ = require('moment-timezone');
const cloneDeep = require('clone-deep');
const { DateTime } = require('luxon');

export const get_next_week_start = (now) => {
    var next_week_start = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (8 - now.getDay()));
    return next_week_start;
}

export const get_previous_week_start = (now) => {
    var previous_week_start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - (6 + now.getDay()));
    return previous_week_start;
}

export const get_next_90days = (now) => {
    // var myFutureDate=new Date(date);
    // myFutureDate.setDate(myFutureDate.getDate() + 90);
    // return (myFutureDate);

    var next_week_start = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (91 - now.getDay()));
    return next_week_start;

    // if (date.getMonth() === 11) {
    //     return (
    //      new Date(date.getFullYear(), date.getMonth(), date.getDate() + (90 + date.getDate()))
    //     );
    // } else {
    //     return (
    //         new Date((date.getMonth() + 2) + `/` + 1 + `/` + date.getFullYear())
    //     );
    // }    
}
export const getNextMonth = (now) => {
    var next_month_start = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (30 - now.getDay()));
    return next_month_start;

}

export const previous90DaysValue = (now) => {
    var next_week_start = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (91 + now.getDay()));
    return next_week_start;

}




export const getNextMonthShow = (now) => {
    var next_month_start = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (28 - now.getDay()));
    return next_month_start;

}
export const get_previous_90days = (date, curr) => {
    var myFutureDate = new Date(date);
    myFutureDate.setDate(myFutureDate.getDate() - 90);
    return myFutureDate
}

export const get_next_90days_date = (date, curr) => {
    var myFutureDate = new Date(date);
    myFutureDate.setDate(myFutureDate.getDate() + 90);
    return myFutureDate
}

export const get_previous_quarter = (date, curr) => {
    var myFutureDate = new Date(date);
    myFutureDate.setDate(myFutureDate.getDate() - 90);
    // var quarter1 = myFutureDate.setDate(myFutureDate.getMonth())
    var quarter2 = moment(myFutureDate).get('month');
    var quarter = Math.floor((quarter2 + 3) / 3)
    if (quarter == 4) {
        return quarter;
    } else if (quarter == 3) {
        return quarter
    } else if (quarter == 2) {
        return quarter
    } else if (quarter == 1) {
        return quarter
    }
    return myFutureDate
}

export const get_next_quarter = (date, curr) => {
    var myFutureDate = new Date(date);
    myFutureDate.setDate(myFutureDate.getDate() + 90);
    // var quarter1 = myFutureDate.setDate(myFutureDate.getMonth())
    var quarter2 = moment(myFutureDate).get('month');
    var quarter = Math.floor((quarter2 + 3) / 3)
    if (quarter == 4) {
        return quarter;
    } else if (quarter == 3) {
        return quarter
    } else if (quarter == 2) {
        return quarter
    } else if (quarter == 1) {
        return quarter
    }
    return myFutureDate
}

export const getPast90DaysCall = (curr) => {
    // var date = new Date()
    var quarter = Math.floor((curr.getMonth() + 3) / 3);
    if (quarter == 4) {
        return quarter;
    } else if (quarter == 3) {
        return quarter
    } else if (quarter == 2) {
        return quarter
    } else if (quarter == 1) {
        return quarter
    }
}

export const getNextMonthDate = (date) => {
    if (date.getMonth() === 11) {

        return (
            new Date(1 + `/` + 1 + `/` + date.getFullYear())
        );
    } else {
        return (
            new Date((date.getMonth() + 2) + `/` + 1 + `/` + date.getFullYear())
        );
    }

}

export const getPreviousMonthDate = (date) => {
    if (date.getMonth() === 0) {

        return (
            new Date((date.getMonth() + 1) + `/` + 1 + `/` + date.getFullYear())
        );
    } else {
        // const ch = new Date(date.getMonth() + `,` + 1 + `,` + date.getFullYear());
        // const mz = new Date('1-1-2019');
        //  
        return (
            new Date(date.getMonth() + `/` + 1 + `/` + date.getFullYear())
        );
    }
}

export const getNextYearDate = (date) => {
    return (
        new Date(1 + `/` + 1 + `/` + String(parseInt(date.getFullYear()) + 1))
    )
}

export const getPreviousYearDate = (date) => {
    return (
        new Date(12 + `/` + 1 + `/` + String(parseInt(date.getFullYear()) - 1))
    )
}

export const GetFormatedDate = (date) => {
    const d = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
    //  
    return (
        (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear()
    );
};

export const KpiGetFormatedDate = (date) => {
    const dateZone = moment(date).format('MM-DD-YYYY');
    const dateConvert = dateZone + 'T00:00:00' + 'Z';
    return dateConvert;
};

export const OutlookGetFromFormatedDate = (date) => {
    const dateZone = moment(date).format('YYYY-MM-DD');
    const dateConvert = dateZone + 'T00:00:00-04:00';
    return dateConvert;
};

export const OutlookGetToFormatedDate = (date) => {
    const dateZone = moment(date).format('YYYY-MM-DD');
    const dateConvert = dateZone + 'T00:30:00-04:00';
    return dateConvert;
};
export const getUntilDate = (date) => {
    return moment.utc(date).format("YYYYMMDDTHHmmssZ").replace("+00:00", "");
};
export const ModalKpiGetFormatedDate = (date) => {
    const dateZone = moment(date).format('MM-DD-YYYY');
    return dateZone;
};

export const getCustomConvertedDate = (date) => {
    return date.split('T')[0];
};

export const getFirstAndLastOfMonth = (date) => {
    var month = new Date(date);
    var firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
    var lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    return '(' + moment(firstDay).format('MM-DD-YYYY') + ') to (' + moment(lastDay).format('MM-DD-YYYY') + ')';
};

// DECODE JWT-Token

export const isTokenValid = (token) => {
    const data = jwtDecode(token);
    let Valid = true;
    if (data.exp) {
        if (new Date() < new Date(parseInt(data.exp * 1000))) {
            Valid = true;
        } else {
            Valid = false
        }
    }
    return Valid;
}

export const isArrayEmpty = (array) => {
    if (array.length === 0) {
        return true;
    } else {
        return false;
    }
}

export const makeFirstLetterCapital = (string) => {
    if (string && isNaN(string)) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
        return string;
    }
}

export const ConvertToProperFormat=(string)=>{
    let output=""
    if (string && isNaN(string)) {
        const splitby_=string.split("_")
        if(splitby_ && splitby_.length){
            splitby_.forEach((str)=>{
                output+= str.charAt(0).toUpperCase() + str.slice(1) +" ";
            })
        }
        else{
            output=string.charAt(0).toUpperCase() + string.slice(1) +" ";
        }
        
        return output;
    }
    else{
        return string;
    }
}

export const extractContent = (s) => {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent.length || span.innerText.length;
};

export const trimString = (string, lenght, manual) => {

    // if (manual) {
    let content = extractContent(string);
    let Html = ReactHtmlParser(string);
    // console.log("this is html parser" + ReactHtmlParser(string).length);

    if (content > 70) {
        Html = string.substring(0, 70);
        return ReactHtmlParser(Html);
    } else {
        return Html;
    }
    // } else {
    //     const trimmedString = string.substring(0, lenght);
    //     return trimmedString;
    // }
}

export const closeModel = () => {
    $("[data-dismiss=modal]").trigger({ type: "click" });
}

export const toggleSidebar = () => {
    $('.app').toggleClass('sidenav-toggled');
    $('.body').toggleClass('toggle-sideBar');

}

export const convertDateToPickerFormat = (date) => {
    return moment(date, "YYYY-MM-DD")._d
}

export const toggleSettings = () => {
    // var treeviewMenu = $('.app-menu');
    //  
    // if (!$(this).parent().hasClass('is-expanded')) {
    //     treeviewMenu.find("[data-toggle='treeview']").parent().removeClass('is-expanded');
    // }
    // $(this).parent().toggleClass('is-expanded');

    // $("[data-toggle='treeview.'].is-expanded").parent().toggleClass('is-expanded');

    //Activate bootstrip tooltips
    // $("[data-toggle='tooltip']").tooltip();
}



export const toggleOpportunities = () => {

    if ($("#customRadio1").is(":checked")) {
        $(".desc_block").fadeIn(200);
    } else {
        $(".desc_block").fadeOut(200);
    }
}

export const toatlSaleSortValues = (value) => {
    if (value) {
        if (value.props.children.props.children.props.children[0].props.children) {
            let a = value.props.children.props.children.props.children[0].props.children.props.children[1];
            //  
            console.log(a);
            return parseInt(value.props.children.props.children.props.children[0].props.children.props.children[1].replace(/\,/g, ""));
        } else {
            let b = value.props.children.props.children.props.children[1].props.children;
            //  
            console.log(b);
            return parseInt(value.props.children.props.children.props.children[1].props.children.replace(/\,/g, ""));
        }
    } else {
        return 0
    }
}

export const descSortvalue = (value) => {

    if (value.props) {
        if (value.props.children[0].props) {
            return value.props.children[0].props.children;
        } else {
            return value.props.children[0];
        }
    } else {
        return value;
    }
}

export const statusSortValue = (value) => {
    if (value.props) {
        if (value.props.children[0].props) {
            return value.props.children[0].props.children;
        } else {
            return value.props.children;
        }
    } else {
        return value;
    }
}

export const setTotalSales = (quote, totalSales) => {
    // const totalSales = storage.get('UpdatedTotalSale');
    const index = totalSales.findIndex(ts => ts[0] === quote.id);
    if (index >= 0) {
        const q = { ...quote };
        q.total_sale = totalSales[index][1];
        return q;
    } else {
        return quote;
    }
}

//keeping date and time intact just changing the timezone and returning the UTC
export const convertDateToDifferentTZ = (date, type) => {
    // const account = JSON.parse(localStorage.getItem('user'));
    // const accountTimezone = account.account_timezone;
    // const accountTimezone = 'America/Los_Angeles';
    const dateZone = moment(date).format('YYYY-MM-DD');
    const month = moment(date).format('MMMM');
    if (type === 'from') {
        const from = dateZone + 'T00:00:00-' + TimezoneMonths[month];
        return from;
    } else {
        const to = dateZone + 'T23:59:59-' + TimezoneMonths[month];
        return to;
    }

}

export const showModeToggleButton = (path) => {
    const paths = [routes.DASHBORD, routes.INSPECTIONS, routes.USER, routes.TICKETS,
    routes.QUOTES, routes.ACCOUNTS, routes.FRONTLINE_LIST, routes.INSIGHTS,
    routes.REPORTS, routes.INSPECTION_FORMS, routes.RATINGS, routes.CATEGORIES,
    routes.SETTINGS_PREFERENCES, routes.NEW_POSITIONS_AND_REGIONS]
    let searchIndex = paths.findIndex(function (s) {
        return s === path
    });
    if (searchIndex > -1) {
        return true
    } else {
        return false
    }
}

export const getUser = () => {
    const user = storage.get('user');
    let mode;
    let zoneMark = null;

    if (user) {

        if (user.mode === 'all') {
            mode = user.selected_mode;
        } else {
            mode = user.mode;
        }

        if (user.timezone) {
            if (user.timezone == TimeZones.PST) {
                zoneMark = 'Pacific Standard Time (PST)'
            } else if (user.timezone == TimeZones.CST) {
                zoneMark = 'Central Standard Time (CST)'
            } else if (user.timezone == TimeZones.IST) {
                zoneMark = 'India Standard Time (IST)'
            }
        }

        return { mode: mode, id: user.id, timezone: user.timezone, zoneMark: zoneMark };
    }
}

export const convertUtc = (date) => {
    const updatedDate = date.replace(/-/gi, "/");
    return moment.tz(updatedDate, TimeZones.PST).format('lll');
}


export const convertAreaScheduleTime = (date, timezone) => {

    const formattedDate = date.replace(/-/gi, '/');
    let zone = ''
    if (timezone === 'America/Los_Angeles') {
        zone = 'PST'
    } else if (timezone === 'America/Chicago') {
        zone = 'CST'
    }


    return moment.tz(formattedDate, timezone).format('hh:mm A') + '(' + zone + ')';
}

export const convertDeepcleanScheduleTime = (date, timezone) => {

    const formattedDate = date.replace(/-/gi, '/');
    let zone = ''
    if (timezone === 'America/Los_Angeles') {
        zone = 'PST'
    } else if (timezone === 'America/Chicago') {
        zone = 'CST'
    }
    return moment(new Date(formattedDate)).format('hh:mm A') + '(' + zone + ')';
}
//console.log();

export const convertAreaScheduleDateTime = (date, timezone) => {
    const formattedDate = date.replace(/-/gi, '/');
    let zone = ''
    if (timezone === 'America/Los_Angeles') {
        zone = 'PST'
    } else if (timezone === 'America/Chicago') {
        zone = 'CST'
    }
    return moment.tz(formattedDate, timezone).format('MM-DD-YYYY hh:mm A') + '(' + zone + ')';
}

export const convertDateTimezone = (date, timezone) => {
    // const formattedDate = date.replace(/-/gi, '/');
    let zone = ''
    if (timezone === 'America/Los_Angeles') {
        zone = 'PST'
    } else if (timezone === 'America/Chicago') {
        zone = 'CST'
    }
    return moment.tz(date, timezone).format('MM-DD-YYYY') + ' (' + zone + ')';
}
export const convertDateTimezoneSchedule = (date, timezone) => {
    return new Date(moment.tz(date, timezone).format('MM-DD-YYYY'));
}
export const convertDateTimezonePopupEvents = (date, timezone) => {
    // const formattedDate = date.replace(/-/gi, '/');
    let zone = ''
    if (timezone === 'America/Los_Angeles') {
        zone = 'PST'
    } else if (timezone === 'America/Chicago') {
        zone = 'CST'
    }
    return moment.tz(date, timezone).format('MM-DD-YYYY') + ' (' + zone + ')';
}
export const convertLogbookDateTimezone = (date, timezone) => {
    return moment.utc(date).tz(timezone).format('MM-DD-YYYY - hh:mm a z');;
}

export const convertInventoryDateTimezone = (date, timezone) => {
    if (date === null) {
        return '-'
    } else {
        let zone = ''
        if (timezone === 'America/Los_Angeles') {
            zone = 'PST'
        } else if (timezone === 'America/Chicago') {
            zone = 'CST'
        }
        return moment.tz(date, timezone).format('MM/DD/YYYY, hh:mm A') + ' (' + zone + ')';
    }

}

export const scheduledTaskPreviewTime = (date, timezone) => {
    if (date === null) {
        return '-'
    } else {
        let zone = ''
        if (timezone === 'America/Los_Angeles') {
            zone = 'PST'
        } else if (timezone === 'America/Chicago') {
            zone = 'CST'
        }
        return moment(date).format('hh:mm A') + ' (' + zone + ')';
    }

}

export const scheduledTaskPreviewDate = (date) => {
    if (date === null) {
        return '-'
    } else {
        return moment.utc(date).format('MM-DD-YYYY');
    }

}

export const convertLogbookSearchDateTimezone = (date) => {
    const newDate = date
    return new Date(newDate).toISOString()

}
export const convertDate = (date, timezone) => {
    // const formattedDate = date.replace(/-/gi, '/');
    return moment.tz(date, timezone).format('MM-DD-YYYY');
}
export const convertDateTimeWithoutZone = (date, timezone) => {
    const dateTime = moment.tz(date, timezone).format('MM-DD-YYYY hh:mm A');
    const updatedDate = dateTime.replace(/-/gi, "/");
    const data = moment(updatedDate).toDate();
    return data;
}

export const convertDateAndTimeTimezone = (date, timezone) => {
    let zone = ''
    if (timezone === 'America/Los_Angeles') {
        zone = 'PST'
    } else if (timezone === 'America/Chicago') {
        zone = 'CST'
    }
    return moment.tz(date, timezone).format('MM-DD-YYYY hh:mm A') + '(' + zone + ')';
}
export const convertDateAndTimeTimezonelll = (date, timezone) => {
    const formattedDate = date.replace(/-/gi, '/');
    let zone = ''
    if (timezone === 'America/Los_Angeles') {
        zone = 'PST'
    } else if (timezone === 'America/Chicago') {
        zone = 'CST'
    }
    const dateTime = moment.tz(formattedDate, timezone).format('lll') + '(' + zone + ')';
    return dateTime;
}

export const capitalAndConvertHyphen = (data) => {
    return data.split("_").map(e => e.charAt(0).toUpperCase() + e.slice(1)).join(" ");;
}

export const getSecMintHoursDays = (date, timezone) => {
    let zone = ''
    if (timezone === 'America/Los_Angeles') {
        zone = 'PST'
    } else if (timezone === 'America/Chicago') {
        zone = 'CST'
    }
    const formattedDateTime = moment.tz(date, timezone).format('MM/DD/YYYY hh:mm A')
    return moment(formattedDateTime).fromNow() + '(' + zone + ')';
}

export const CustomNoDataComponent = (isLoading, text) => {
    if (isLoading) {
        return null;
    }
    return <div className="rt-noData">{text}</div>
}

export const getWeekStartAndEndDay = (curr) => {

    var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
    // var last = first + 6; // last day is the first day + 6
    var firstday = new Date(curr.setDate(first));
    // var lastday = new Date(curr.setDate(last));
    var lastday = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() + (7 - curr.getDay()));
    return { firstday, lastday }
}
export const getMonthStartAndEndDay = (curr) => {

    var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
    // var last = first + 6; // last day is the first day + 6
    var firstday = new Date(curr.setDate(first));
    // var lastday = new Date(curr.setDate(last));
    var lastday = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() + (30 - curr.getDay()));
    return { firstday, lastday }
}

export const weekStartDayAndWeekEndDay = (curr) => {
    // var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    // var last = first + 6; // last day is the first day + 6
    var firstday = new Date(curr.setDate(first));
    // var lastday = new Date(curr.setDate(last));
    var lastday = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() + (6 - curr.getDay()));
    return '(' + GetFormatedDate(firstday) + ') to (' + GetFormatedDate(lastday) + ')';
}

export const getPast90Days = (curr) => {
    var quarter = Math.floor((curr.getMonth() + 3) / 3);
    if (quarter == 4) {
        return '' + moment.months(9, "MMMM") + '(' + moment(curr).format('YYYY') + ')' + ' to ' + moment.months(11, "MMMM") + '(' + moment(curr).format('YYYY') + ')';
    } else if (quarter == 3) {
        return '' + moment.months(6, "MMMM") + '(' + moment(curr).format('YYYY') + ')' + ' to ' + moment.months(8, "MMMM") + '(' + moment(curr).format('YYYY') + ')';
    } else if (quarter == 2) {
        return '' + moment.months(3, "MMMM") + '(' + moment(curr).format('YYYY') + ')' + ' to ' + moment.months(5, "MMMM") + '(' + moment(curr).format('YYYY') + ')';
    } else if (quarter == 1) {
        return '' + moment.months(0, "MMMM") + '(' + moment(curr).format('YYYY') + ')' + ' to ' + moment.months(2, "MMMM") + '(' + moment(curr).format('YYYY') + ')';
    }
}

export const getPast90DaysStartDayAndEndDay = (curr) => {
    var first = curr.getDate()
    var firstday = new Date(curr.setDate(first));
    var lastday = new Date(moment(firstday).subtract(90, 'days'));
    return '(' + GetFormatedDate(lastday) + ') to (' + GetFormatedDate(firstday) + ')';
}

export const getPast90DaysStartDay = (curr) => {
    var first = curr.getDate()
    var firstday = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() + ((curr.getDay() - 90)));
    // var lastday = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() + ((curr.getDay() - 90)));
    return GetFormatedDate(firstday)
}
export const getPast90DaysEndDay = (curr) => {
    var lastday = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() + ((curr.getDay() - 90)));
    return GetFormatedDate(lastday)
}


export const getMonthAndYear = (curr) => {

    return moment(curr).format('MMMM-YYYY');
    // return (dateFormat(curr, 'mmmm-yyyy'))
}

export const getYearView = (curr) => {
    return moment(curr).format('YYYY');
    // return (dateFormat(curr, 'yyyy'))
}

export const isEmpty = (obj) => {

    let flag = true;
    obj.forEach(element => {
        if (element !== '' && element.lenght !== 0) {
            flag = false
            // return fla
        }
    });
    return flag;
}

export const extractExtention = (arr) => {
    let result = [];
    for (var i = 0; i < arr.length; i++) {
        result.push(arr[i].substr((arr[i].lastIndexOf('.'))))
    }
    return result;
}

export const convertAccountsDataToCsvType = (data) => {
    if (data) {
        if (data.length !== 0) {
            let AccoutsData = cloneDeep(data);
            let csvType = [];
            AccoutsData.forEach(a => {
                const owner = a.user ? (a.user.first_name === null ? '-' : a.user.first_name) + ' ' + (a.user.last_name === null ? '' : a.user.last_name) : (a.first_name === null ? '-' : a.first_name) + ' ' + (a.last_name === null ? '' : a.last_name);
                let headers =
                {
                    // 'ID': e.id,
                    // 'User Name': name.replace(/null/gi, ''),
                    // 'Last Name': a.user.last_name,
                    'Job Name': a.user ? a.user.name : a.name,
                    'Job Number': a.user ? a.user.account_number : a.account_number,
                    'Owner': owner,
                    'Active Status': a.activestatus,
                    'Mode': makeFirstLetterCapital(a.mode),
                    // 'Email': a.user ? a.user.email : a.email,
                    // 'Phone No': a.user ? a.user.phone_number : a.phone_number,
                    // 'Contact2 FirstName': a.user ? a.user.contact2_FN : a.contact2_FN,
                    // 'Contact2 LastName': a.user ? a.user.contact2_LN : a.contact2_LN,
                    // 'Contact2 Email': a.user ? a.user.contact2_email : a.contact2_email,
                    // 'Contact2 Phone': a.user ? a.user.contact2_phone : a.contact2_phone,
                    // 'Address': a.user ? a.user.address.formatted_address : a.address ? a.address.formatted_address : '',
                    // 'Branch': a.user && a.user.region ? a.user.region.name : a.region ? a.region.name : '',
                    // 'Created On': a.user ? moment(a.user.created_at).format('MM-DD-YYYY') : moment(a.created_at).format('MM-DD-YYYY'),
                    // dateFormat(a.user.created_at, "mm/dd/yyyy"),

                    // 'Last Updated': dateFormat(e.lastUpdated.props.children, "mm/dd/yyyy"),

                }
                csvType.push(headers)

            })
            return csvType;
        } else {
            return []
        }

    } else {
        return []
    }
}

export const convertAreaLogsDataToCsvType = (data) => {
    if (data) {
        if (data.length !== 0) {
            let AreaLogsData = cloneDeep(data);
            let csvAreaType = [];
            AreaLogsData.forEach(a => {
                let headers =
                {
                    'Area Name': a.Name && a.Name.length > 0 ? a.Name : '',
                    'Error Message': a.message && a.message.length > 0 ? a.message : '',

                }
                csvAreaType.push(headers)

            })
            return csvAreaType;
        } else {
            return []
        }

    } else {
        return []
    }
}

export const convertLogbookLogDataToCsvType = (data) => {
    if (data) {
        if (data.length !== 0) {
            let LogbookLogsData = cloneDeep(data);
            let csvLogbookType = [];
            LogbookLogsData.forEach(a => {
                let headers =
                {
                    'Logbook Name': a.Name && a.Name.length > 0 ? a.Name : '',
                    'Error Message': a.message && a.message.length > 0 ? a.message : '',

                }
                csvLogbookType.push(headers)

            })
            return csvLogbookType;
        } else {
            return []
        }

    } else {
        return []
    }
}

export const convertAccountsGenericUrlDataToCsvType = (data) => {
    if (data) {
        if (data.length !== 0) {
            let AccoutsData = cloneDeep(data);
            let csvType = [];
            AccoutsData.forEach(a => {

                let headers =
                {
                    // 'ID': e.id,
                    'Job Name': a.user ? a.user.name : a.name,
                    // 'Last Name': a.user.last_name,
                    'Generic URL': a.user ? a.user.generic_url : a.generic_url,
                    // dateFormat(a.user.created_at, "mm/dd/yyyy"),

                    // 'Last Updated': dateFormat(e.lastUpdated.props.children, "mm/dd/yyyy"),

                }
                csvType.push(headers)

            })
            return csvType;
        } else {
            return []
        }

    } else {
        return []
    }
}


// const setStatus = (status) => {

//     if (status === Status.APPROVED_BY_SUPERADMIN || status === Status.APPROVED_BY_ADMIN) {
//                 return <span className="btn cst_btn btn_request">{makeFirstLetterCapital(status.replace(/_/g, " "))}</span>;
//             } else if (status === Status.CONFIRM_BY_CLIENT) {
//                 return <span className="btn cst_btn btn_request">{makeFirstLetterCapital(status.replace(/_/g, " "))}</span>;
//             } else if (status === Status.DECLINED_BY_CLIENT) {
//                 return <span className={"btn cst_btn btn_approved"}>{'Waiting for Client Approval'}</span>;
//             } else if (status === Status.REJECTED_BY_ADMIN || status === Status.REJECTED_BY_SUPERADMIN) {
//                 return <span className={"btn cst_btn btn_approved"}>{makeFirstLetterCapital(status.replace(/_/g, " "))}</span>;
//             } else if (status === Status.REQUESTED) {
//                 return <span className="btn cst_btn btn_request">{makeFirstLetterCapital(status.replace(/_/g, " "))}</span>
//             }
// }

export const convertQuotesDataToCsvType = (data) => {
    if (data) {
        if (data.lenght !== 0) {

            let QuotesData = cloneDeep(data);
            let csvType = [];
            QuotesData.forEach(a => {
                // a.user.created_at = dateFormat(a.user.created_at, "mm/dd/yyyy");
                var desc = a.description;
                let headers =
                {
                    'ID': a.id,
                    'Job Name': a.account.name,
                    'Requestor': a.requestor ? a.requestor.first_name + ' ' + a.requestor.last_name : '',
                    'Amount': a.tag_opportunity ? parseFloat(a.total_sale.replace(/\,/g, "")).toFixed(2) : '',
                    'Branch': a.region ? a.region.name : '',
                    'Description': a.tag_opportunity ? desc.includes('<') ? $(desc).text() : desc : 'No Quote Identified',
                    'Status': a.tag_opportunity ? Status.DECLINED_BY_CLIENT === a.status ? "Waiting for Customers Approval" : makeFirstLetterCapital(a.status.replace(/_/g, " ")) : 'N/A',
                    'Date': moment(a.created_at).format('MM-DD-YYYY'),
                    // dateFormat(a.created_at, "mm/dd/yyyy")
                }
                csvType.push(headers)
            })
            return csvType;
        } else {
            return []
        }

    } else {
        return []
    }
}

export const convertInspectionInventaryData = (inspection, data_list, fields) => {

    if (data_list) {
        if (data_list.length > 0) {

            let csvType = [];

            data_list.forEach(q => {
                let headers =
                {
                    'Section': q.section,
                    'LineItem': q.line_item,
                    'Score': q.rating_name,
                    'Comment': q.comment,
                    'Customer Contact 1': q.customer_1,
                    'Customer Contact 2': q.customer_2,
                }
                csvType.push(headers)

            })

            return csvType;
        } else {
            return []
        }
    } else {
        return []
    }
}

export const convertTicketsDataToCsvType = (data) => {
    if (data) {
        if (data.lenght !== 0) {

            let QuotesData = cloneDeep(data);
            let csvType = [];
            QuotesData.forEach(a => {
                let headers =
                {
                    'Ticket ID': a.id,
                    'Job Name': a.account,
                    'Description': a.message,
                    'Requestor': a.created_by,
                    'Priority': a.priority,
                    'Status': a.status,
                    'Days Open': a.elapsedDays,
                    'Due Date': a.due_on,
                    'Date': moment(a.created_at).format('MM-DD-YYYY'),
                }
                csvType.push(headers)
            })
            return csvType;
        } else {
            return []
        }

    } else {
        return []
    }
}

export const convertInspectionsDataToCsvType = (data) => {
    if (data) {
        if (data.lenght !== 0) {

            let QuotesData = cloneDeep(data);
            let csvType = [];
            QuotesData.forEach(a => {
                // a.user.created_at = dateFormat(a.user.created_at, "mm/dd/yyyy");
                let headers =
                {
                    'ID': a.id,
                    'Job Name': a.accountName,
                    'Form': a.inspectionForm,
                    'Score': a.score,
                    'Inspector': a.inspector,
                    'Region': a.region,
                    'Duration': a.duration,
                    'Status': a.status,
                    'QQV': a.qqv,
                    'Date': moment(a.created_at).format('MM-DD-YYYY'),
                    // dateFormat(a.created_at, "mm/dd/yyyy")
                }
                csvType.push(headers)
            })
            return csvType;
        } else {
            return []
        }

    } else {
        return []
    }
}

export const convertUsersDataToCsvType = (data) => {
    if (data) {
        if (data.length !== 0) {
            let UsersData = cloneDeep(data);
            let csvType = [];
            UsersData.forEach(a => {
                // a.user.created_at = dateFormat(a.user.created_at, "mm/dd/yyyy");
                let headers =
                {
                    'ID': a.id,
                    'Name': a.first_name + ' ' + a.last_name,
                    // 'Last Name': a.last_name,
                    'Email': a.email,
                    'Role': a.users_role === userRoles.CLIENT ? 'Customer' : a.users_role,
                    'Active Status': a.activestatus,
                    'Mode': makeFirstLetterCapital(a.mode)
                    // 'Quote Notifications': a.user.quote_notifications,
                    // 'Ticket Notifications': a.user.ticket_notifications,
                    // 'Inspection Notifications': a.user.inspection_notifications,
                    // 'Branch': a.user.region ? a.user.region.name : '',
                    // 'Position': a.user.position ? a.user.position.name : '',
                    // 'Customers': a.user.companies ? a.user.companies.map(c => c.name) : '',
                    // 'Contact2 Phone': a.user.contact2_phone,
                    // 'Address': a.user.accounts ? a.user.accounts.map(a => a.name) : '',
                    // 'Created On': moment(a.user.created_at).format('MM-DD-YYYY'),
                    //  dateFormat(a.user.created_at, "mm/dd/yyyy"),

                    // 'Last Updated': dateFormat(e.lastUpdated.props.children, "mm/dd/yyyy"),

                }
                csvType.push(headers)

            })
            return csvType;
        } else {
            return []
        }

    } else {
        return []
    }
}


export const convertDashboardDataToCsvType = (data) => {
    if (data) {
        if (data.requests_approved_by_admin) {

            let DashboardData = cloneDeep(data);
            let csvType = [];

            let headers =
            {
                // 'ID': e.id,
                'Total # Quotes Sent': DashboardData.requests_approved_by_admin,
                'Total $ Quotes Sent': DashboardData.sales_approved_by_admin,
                'Total # Quotes Pending': DashboardData.requests_pending_by_clients,
                'Total # Quotes Approved': DashboardData.requests_approved_by_clients,
                'Total $ Quotes Approved': DashboardData.sales_approved_by_clients,
                'Total $ Quotes Pending': DashboardData.sales_pending_by_clients,
                // 'Inspection Notifications': DashboardData.top_ten_quotes_approved_by_admin.forEach(a => a.user_name + `(${a.quotes_count})`),


                // 'Last Updated': dateFormat(e.lastUpdated.props.children, "mm/dd/yyyy"),

            }
            csvType.push(headers)
            return csvType;
        } else {
            return []
        }

    } else {
        return []
    }

}

export const convertQuotesReportsToCsvType = (data) => {

    if (data) {

        if (data.length !== 0) {
            let QuotesReport = cloneDeep(data);
            let csvType = [];

            QuotesReport.forEach(q => {
                // a.user.created_at = dateFormat(a.user.created_at, "mm/dd/yyyy");
                let headers =
                {
                    'Job Name': q.account_name,
                    'Customers': q.company_name,
                    'Requestors who created the quote': q.user,
                    'Total Quotes Created\n(Irrespective of quote status)': `${q.total_quotes_created}`,
                    'Total Quotes Sale (in $)': q.total_sale,
                    'Total # of Quotes\n pending admin approval': q.quotes_in_requested_status,
                    'Total # of Quotes\n Approved by Admin': q.quotes_approved_by_admin,
                    'Total # of Quotes\n Rejected by Admin': q.quotes_rejected_by_admin,
                    'Total Quotes Confirmed by Customers': q.quotes_confirmed_by_client,
                    'Total Approved Sale\n (in $)': q.confirmed_sales,
                    'Total Quote waiting for\n customers approval(as per our logic these quotes are declined\n by customers after 7 days from admin approval)': q.quotes_declined_by_client,
                    'Total Quote waiting\n for approval in $': q.declined_sales,
                    'Requestor Branch': q.requestor_region,
                    // 'Account Region': '',
                    'Active Status': q.user_active_status,
                }
                csvType.push(headers)

            })

            return csvType;
        } else {
            return []
        }

    } else {
        return []
    }
}

export const convertQuotesData1ToCsvType = (data) => {
    if (data) {

        if (data.length !== 0) {
            let QuotesReport = cloneDeep(data);
            let csvType = [];

            QuotesReport.forEach(q => {
                // a.user.created_at = dateFormat(a.user.created_at, "mm/dd/yyyy");
                let headers =
                {
                    'Job Name': q.account_name,
                    'Requestors who created the quote': q.user,
                    'Total Quotes Created\n(Irrespective of quote status)': `${q.total_quotes_created}`,
                    'Total Quotes Sale (in $)': q.total_sale,
                }
                csvType.push(headers)

            })

            return csvType;
        } else {
            return []
        }

    } else {
        return []
    }
}

export const convertQuotesData2ToCsvType = (data) => {
    if (data) {

        if (data.length !== 0) {
            let QuotesReport = cloneDeep(data);
            let csvType = [];

            QuotesReport.forEach(q => {
                // a.user.created_at = dateFormat(a.user.created_at, "mm/dd/yyyy");
                let headers =
                {
                    'Job Name': q.account_name,
                    'Requestors who created the quote': q.user,
                    'Total # of Quotes\n Approved by Admin': q.quotes_approved_by_admin,
                    'Total # of Quotes\n Rejected by Admin': q.quotes_rejected_by_admin,
                }
                csvType.push(headers)

            })

            return csvType;
        } else {
            return []
        }

    } else {
        return []
    }
}

export const convertQuotesData3ToCsvType = (data) => {
    if (data) {

        if (data.length !== 0) {
            let QuotesReport = cloneDeep(data);
            let csvType = [];

            QuotesReport.forEach(q => {
                // a.user.created_at = dateFormat(a.user.created_at, "mm/dd/yyyy");
                let headers =
                {
                    'Job Name': q.account_name,
                    'Requestors who created the quote': q.user,
                    'Total Quotes Confirmed by Customers': q.quotes_confirmed_by_client,
                    'Total Quote waiting for\n customers approval(as per our logic these quotes are declined\n by customers after 7 days from admin approval)': q.quotes_declined_by_client,
                }
                csvType.push(headers)

            })

            return csvType;
        } else {
            return []
        }

    } else {
        return []
    }
}

export const convertQuotesData4ToCsvType = (data) => {
    if (data) {

        if (data.length !== 0) {
            let QuotesReport = cloneDeep(data);
            let csvType = [];

            QuotesReport.forEach(q => {
                // a.user.created_at = dateFormat(a.user.created_at, "mm/dd/yyyy");
                let headers =
                {
                    'Job Name': q.account_name,
                    'Requestors who created the quote': q.user,
                    'Total Approved Sale\n (in $)': q.confirmed_sales,
                    'Total Quote waiting\n for approval in $': q.declined_sales,
                }
                csvType.push(headers)

            })

            return csvType;
        } else {
            return []
        }

    } else {
        return []
    }
}
export const ConvertCHIServysToCSVType = (data) => {
    let headers = {};
    if (data) {

        if (data.length !== 0) {
            let chiServyList = cloneDeep(data);
            let csvType = [];

            chiServyList.forEach(q => {
                if (q && q.user) {
                    let surveyData = q;
                    let surveyUserData = q.user;
                    headers = {
                        'User': surveyUserData.first_name ? surveyUserData.first_name : '–' + ' ' + surveyUserData.last_name ? surveyUserData.last_name : '–',
                        'Feedback': surveyData.feedback ? surveyData.feedback : '–',
                        'Region': surveyUserData.region && surveyUserData.region.name ? surveyUserData.region.name : '–',
                        'Date': surveyData.created_at ? surveyData.created_at : '–',
                        'Rating': surveyData.rating ? surveyData.rating : '–',
                        'Status': surveyData.ticket && surveyData.ticket.length > 0 ?
                            surveyData.ticket[0] && surveyData.ticket[0].status === "open" ? surveyData.ticket[0].status :
                                surveyData.ticket[0].status : "Satisfied"
                    }
                    csvType.push(headers);
                }

            })

            return csvType;
        } else {
            return []
        }

    } else {
        return []
    }


}

export const ConvertTimesheetListToCSVType = (data) => {
    let headers = {};
    if (data) {
        if (data.length !== 0) {
            let timeSheetList = cloneDeep(data);
            let csvType = [];

            timeSheetList.forEach((item, index) => {
                if (item) {
                    let shiftData = item;
                    let mealBreaks = item?.breaks?.map((item, index) => {
                        let startMeal = item?.start !== null ? item.start : null;
                        let endMeal = item?.end !== null ? item.end : null;
                        let startDate = startMeal?.split('T')[0];
                        let startTime = startMeal?.split('T')[1]?.split('.')[0];
                        let endDate = endMeal?.split('T')[0];
                        let endTime = endMeal?.split('T')[1]?.split('.')[0];

                        let formattedStartMeal = startMeal == null ? '____' : tzTimeConversionTool_V1(startTime, startDate);
                        let formattedEndMeal = endMeal == null ? '____' : tzTimeConversionTool_V1(endTime, endDate);

                        if (item.start === null || item.end === null) {
                            return ['null', 'null'];
                        } else {
                            return [formattedStartMeal + ' − ' + formattedEndMeal];
                        }
                    });
                    headers = {
                        'SINO': index + 1,
                        'Shift Id': shiftData?.shift_id ? shiftData.shift_id : '−',
                        'Date': shiftData?.date ? tzDateConverterTool_V1(shiftData.date) : '−',
                        'Clocked In': shiftData?.check_int_at ? tzDateConverterTool_V6(shiftData.check_int_at) : '−',
                        'Meal Break': mealBreaks,
                        'Clocked Out': shiftData?.check_out_at ? tzDateConverterTool_V6(shiftData.check_out_at) : '−',
                        'Penalty Hours': shiftData?.total_penality_minutes ? calculateHoursFromMinutes_V1(shiftData.total_penality_minutes) : '−',
                        'Actual Hours': shiftData?.total_time ? calculateHoursFromMinutes_V1(shiftData.total_time) : '−',
                        'Total Worked Hours': shiftData?.working_minutes ? calculateHoursFromMinutes_V1(shiftData.working_minutes) : '−',
                    }
                    csvType.push(headers);
                }
            })

            return csvType;
        } else {
            return []
        }
    } else {
        return []
    }
}

export const IsArrayofObjectEmpty = (obj) => {

    for (var i = 0; i < obj.length; i++) {
        if (obj[i] !== "")
            return false;
    }
    return true;
}

export const convertDateToDifferentTZSansUTC = (date, timezone) => {
    var now = momentTZ(date);
    now.tz(timezone, true);
    // console.log(now.format());
    // console.log(now.toISOString());
    console.log(now.toDate());
    return now.toDate();
}

export const resetOrientation = (srcBase64, srcOrientation, props) => {
    var img = new Image();

    let base64 = null;
    img.onload = function () {
        var width = img.width,
            height = img.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d");

        // set proper canvas dimensions before transform & export
        if (4 < srcOrientation && srcOrientation < 9) {
            canvas.width = height;
            canvas.height = width;
        } else {
            canvas.width = width;
            canvas.height = height;
        }

        // transform context before drawing image
        switch (srcOrientation) {
            case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
            case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
            case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
            case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
            case 7: ctx.transform(0, -1, -1, 0, height, width); break;
            case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
            default: ctx.transform(1, 0, 0, 1, 0, 0);
        }

        // draw image
        ctx.drawImage(img, 0, 0);

        // export base64
        // callback(canvas.toDataURL());

        base64 = canvas.toDataURL();
        //  
        props.setState({
            base64: base64
        })
        console.log(base64);
        return base64;
    };

    img.src = srcBase64;
    //  
}

export const compressImage = async (file, options) => {
    return await imageCompression(file, options)
}

export const resetMultipleOrientation = (srcBase64, srcOrientation, props, base64, index, filesLength) => {
    var img = new Image();

    // let base64 = [];
    img.onload = function () {
        var width = img.width,
            height = img.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d");

        // set proper canvas dimensions before transform & export
        if (4 < srcOrientation && srcOrientation < 9) {
            canvas.width = height;
            canvas.height = width;
        } else {
            canvas.width = width;
            canvas.height = height;
        }

        // transform context before drawing image
        switch (srcOrientation) {
            case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
            case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
            case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
            case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
            case 7: ctx.transform(0, -1, -1, 0, height, width); break;
            case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
            default: ctx.transform(1, 0, 0, 1, 0, 0);
        }

        // draw image
        ctx.drawImage(img, 0, 0);

        // export base64
        // callback(canvas.toDataURL());

        base64.push(canvas.toDataURL());
        //  
        if (index === filesLength - 1) {
            //   
            props.setState(prevState => ({
                base64: [...prevState.base64, ...base64]
            }))
        }


        console.log(base64);
        return base64;
    };
    img.src = srcBase64;
    //  
}

export const resetMultipleInspectionOrientation = (srcBase64, srcOrientation, props, InspecionForm, index, filesLength, sectionIndex, lineIndex) => {
    var img = new Image();

    // let base64 = [];
    img.onload = function () {
        var width = img.width,
            height = img.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d");

        // set proper canvas dimensions before transform & export
        if (4 < srcOrientation && srcOrientation < 9) {
            canvas.width = height;
            canvas.height = width;
        } else {
            canvas.width = width;
            canvas.height = height;
        }

        // transform context before drawing image
        switch (srcOrientation) {
            case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
            case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
            case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
            case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
            case 7: ctx.transform(0, -1, -1, 0, height, width); break;
            case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
            default: ctx.transform(1, 0, 0, 1, 0, 0);
        }

        // draw image
        ctx.drawImage(img, 0, 0);

        // export base64
        // callback(canvas.toDataURL());

        InspecionForm.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].portfolioImages.base64.push(canvas.toDataURL());
        //  
        if (index === filesLength - 1) {
            //   

            props.setState(prevState => {
                prevState.inspectionForm.inspection_form.sections_attributes.map((section, secIndex) => {
                    if (secIndex === sectionIndex) {
                        section.line_items_attributes.map((line, liIndex) => {
                            if (lineIndex === liIndex) {
                                InspecionForm.inspection_form.sections_attributes[sectionIndex].
                                    line_items_attributes[lineIndex].portfolioImages.base64 =
                                    [...new Set([...InspecionForm.inspection_form.sections_attributes[sectionIndex].
                                        line_items_attributes[lineIndex].portfolioImages.base64,
                                    ...line.portfolioImages.base64])];
                            }
                        })
                    }
                })

                return { inspectionForm: InspecionForm }

            });

            if (InspecionForm.inspection_form.sections_attributes[sectionIndex]
                .line_items_attributes[lineIndex].portfolioImages.base64.length >= filesLength) {

                props.setState({
                    imageLoading: true
                })
                props.saveLineItemsPortfolioImages(sectionIndex, lineIndex, InspecionForm, filesLength);
            }
        }


        // console.log(base64);
        return InspecionForm.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].portfolioImages.base64;
    };

    img.src = srcBase64;
    //  
}

export const convertStringToHtml = (string) => {
    var Html = new DOMParser().parseFromString(string, "text/xml");

    return Html.firstChild.innerHTML;
}

export const isString = (x) => {
    return Object.prototype.toString.call(x) === '[object String]';
}

export const sortInspectionFormArrayOfObject = (array) => {

    array.sort(function (a, b) {
        if (a.inspection_form.name < b.inspection_form.name) { return -1; }
        if (a.inspection_form.name > b.inspection_form.name) { return 1; }
        return 0;
    })
}


export const sortArrayOfObject = (array) => {

    array.sort(function (a, b) {
        if (a.name.toUpperCase() < b.name.toUpperCase()) { return -1; }
        if (a.name.toUpperCase() > b.name.toUpperCase()) { return 1; }
        return 0;
    })
}

// export const getSecMintHoursDays = (date) => {
//     let date_now = new Date();
//     const pastDate = new Date(date)
//     let delta = Math.abs(date_now - pastDate) / 1000;
//     var days = Math.floor(delta / 86400);
//     delta -= days * 86400;
//     // calculate (and subtract) whole hours
//     var hours = Math.floor(delta / 3600) % 24;
//     delta -= hours * 3600;

//     // calculate (and subtract) whole minutes
//     var minutes = Math.floor(delta / 60) % 60;
//     delta -= minutes * 60;

//     // what's left is seconds
//     var seconds = delta % 60;
//     if (days > 1) {
//         return days + ' ' + 'days';
//     } else if (days === 1) {
//         return days + ' ' + 'day';
//     }
//     else if (hours > 1) {
//         return hours + ' ' + 'hours';
//     } else if (hours === 1) {
//         return hours + ' ' + 'hour';
//     } else if (minutes <= 0) {
//         return 'less than a minute';
//     }
//     else if (minutes === 1) {
//         return minutes + ' ' + 'minute';
//     }
//     else {
//         return minutes + ' ' + 'minutes';
//     }
//     //  else if (seconds > 0) {
//     //     return seconds + ' ' + 'seconds';
//     // }
// }

export const sortQuotesCount = (a, b) => {
    // const bandA = a.band.toUpperCase();
    // const bandB = b.band.toUpperCase();
    const bandA = a.value;
    const bandB = b.value;

    let comparison = 0;
    if (bandA < bandB) {
        comparison = 1;
    } else if (bandA > bandB) {
        comparison = -1;
    }
    return comparison;
}

export const getCountOfLineItemAttributes = (inspectionForm) => {
    let count = 0;
    inspectionForm.sections_attributes.forEach(sec => {
        count = count + sec.line_items_attributes.length;
    })
    return count;
}

export const removeDublicatesArrayObjects = (array) => {

    const jsonObject = array.map(JSON.stringify);

    console.log(jsonObject);

    const uniqueSet = new Set(jsonObject);
    const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    return uniqueArray;
}

export const dateForChart = (date) => {
    if (date.length > 8) {
        let month = date.slice(0, 3)
        let day = date.slice(4, 6)
        let year = date.slice(7, 11)

        switch (month) {
            case 'Jan':
                month = "1";
                break;
            case 'Feb':
                month = "2";
                break;
            case 'Mar':
                month = "3";
                break;
            case 'Apr':
                month = "4";
                break;
            case 'May':
                month = "5";
                break;
            case 'Jun':
                month = "6";
                break;
            case 'Jul':
                month = "7";
                break;
            case 'Aug':
                month = "8";
                break;
            case 'Sep':
                month = "9";
                break;
            case 'Oct':
                month = "10";
                break;
            case 'Nov':
                month = "11";
                break;
            case 'Dec':
                month = "12";
                break;
            default:
                month = "1"
        }

        day = parseInt(day)

        let newDate = `${year}, ${month}, ${day}`
        return newDate;
    } else {
        let month = date.slice(0, 3)
        let year = date.slice(4, 8)

        switch (month) {
            case 'Jan':
                month = "1";
                break;
            case 'Feb':
                month = "2";
                break;
            case 'Mar':
                month = "3";
                break;
            case 'Apr':
                month = "4";
                break;
            case 'May':
                month = "5";
                break;
            case 'Jun':
                month = "6";
                break;
            case 'Jul':
                month = "7";
                break;
            case 'Aug':
                month = "8";
                break;
            case 'Sep':
                month = "9";
                break;
            case 'Oct':
                month = "10";
                break;
            case 'Nov':
                month = "11";
                break;
            case 'Dec':
                month = "12";
                break;
            default:
                month = "1"
        }


        let newDate = `${year}, ${month}`
        return newDate;
    }
}

export const startOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

export const startOfWeek = (date) => {
    return new Date(date.setDate(date.getDate() - date.getDay()));
}

export const startOfAgenda = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export const endOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export const endOfWeek = (date) => {
    return new Date(date.setDate(date.getDate() - date.getDay() + 6));
}

export const endOfAgenda = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 30);
}

export const convertDateFormatForSchedule = (dateToReturn) => {
    let formatedDate = moment(dateToReturn).format('YYYY-MM-DD');
    return formatedDate;
}

export const convertDateFormatNextDate = (dateToReturn) => {
    return `${dateToReturn.getFullYear()}-${dateToReturn.getMonth() + 1}-${dateToReturn.getDate() + 1}`
}

// export const getWeekToMonthNumber = (dataType) => {
//     let perfectMonth = ''
//     const weekDateStorage = storage.get('weekDates');
//     const split = weekDateStorage.split(' – ');
//     const firstMonth = split[0];
//     let firstMonthSplit = firstMonth.split(' ');
//     let beforeMonth = firstMonthSplit[0];
//     // 
//     const lastMonth = split[1];
//     let lastMonthSplit = lastMonth.split(' ');
//     let afterMonth = lastMonthSplit[0];
//     if(dataType == 'month') {
//         perfectMonth = lastMonthSplit && lastMonthSplit.length >= 2 ? afterMonth : beforeMonth;
//     } else {
//         perfectMonth = firstMonthSplit[0];
//     }
//     let monthNumber = ''
//         let month = [ "January", "February", "March", "April", "May", "June",
//         "July", "August", "September", "October", "November", "December" ];
//         if(perfectMonth.includes(month[0])) {
//             monthNumber = moment().month(month[0]).format("MM");
//             return monthNumber;
//         } else if(perfectMonth.includes(month[1])) {
//             monthNumber = moment().month(month[1]).format("MM");
//             return monthNumber;
//         } else if(perfectMonth.includes(month[2])) {
//             monthNumber = moment().month(month[2]).format("MM");
//             return monthNumber;
//         } else if(perfectMonth.includes(month[3])) {
//             monthNumber = moment().month(month[3]).format("MM");
//             return monthNumber;
//         } else if(perfectMonth.includes(month[4])) {
//             monthNumber = moment().month(month[4]).format("MM");
//             return monthNumber;
//         } else if(perfectMonth.includes(month[5])) {
//             monthNumber = moment().month(month[5]).format("MM");
//             return monthNumber;
//         } else if(perfectMonth.includes(month[6])) {
//             monthNumber = moment().month(month[6]).format("MM");
//             return monthNumber;
//         } else if(perfectMonth.includes(month[7])) {
//             monthNumber = moment().month(month[7]).format("MM");
//             return monthNumber;
//         } else if(perfectMonth.includes(month[8])) {
//             monthNumber = moment().month(month[8]).format("MM");
//             return monthNumber;
//         } else if(perfectMonth.includes(month[9])) {
//             monthNumber = moment().month(month[9]).format("MM");
//             return monthNumber;
//         } else if(perfectMonth.includes(month[10])) {
//             monthNumber = moment().month(month[10]).format("MM");
//             return monthNumber;
//         } else if(perfectMonth.includes(month[11])) {
//             monthNumber = moment().month(month[11]).format("MM");
//             return monthNumber
//         }
//         return monthNumber;
// }

// export const getDayToMonthNumber = () => {
//     let monthNumber = ''
//     let month = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
//         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
//         // const dayDateStorage = storage.get('dayDates');
//         const dayText = document.getElementsByClassName('rbc-toolbar-label');
//         const splitMonth = dayText[0].textContent
//         const setMonth = splitMonth.split(' ');
//         let dayDateMonth = setMonth[1];
//         if(dayDateMonth.includes(month[0])) {
//             monthNumber = moment().month(month[0]).format("MM");
//             return monthNumber;
//         } else if(dayDateMonth.includes(month[1])) {
//             monthNumber = moment().month(month[1]).format("MM");
//             return monthNumber;
//         } else if(dayDateMonth.includes(month[2])) {
//             monthNumber = moment().month(month[2]).format("MM");
//             return monthNumber;
//         } else if(dayDateMonth.includes(month[3])) {
//             monthNumber = moment().month(month[3]).format("MM");
//             return monthNumber;
//         } else if(dayDateMonth.includes(month[4])) {
//             monthNumber = moment().month(month[4]).format("MM");
//             return monthNumber;
//         } else if(dayDateMonth.includes(month[5])) {
//             monthNumber = moment().month(month[5]).format("MM");
//             return monthNumber;
//         } else if(dayDateMonth.includes(month[6])) {
//             monthNumber = moment().month(month[6]).format("MM");
//             return monthNumber;
//         } else if(dayDateMonth.includes(month[7])) {
//             monthNumber = moment().month(month[7]).format("MM");
//             return monthNumber;
//         } else if(dayDateMonth.includes(month[8])) {
//             monthNumber = moment().month(month[8]).format("MM");
//             return monthNumber;
//         } else if(dayDateMonth.includes(month[9])) {
//             monthNumber = moment().month(month[9]).format("MM");
//             return monthNumber;
//         } else if(dayDateMonth.includes(month[10])) {
//             monthNumber = moment().month(month[10]).format("MM");
//             return monthNumber;
//         } else if(dayDateMonth.includes(month[11])) {
//             monthNumber = moment().month(month[11]).format("MM");
//             return monthNumber;
//         }
//         return monthNumber;
// }
export const convertFeedbackTime = (date) => {
    const dateZone = moment(date).format('MM-DD-YYYY hh:mm A');
    return dateZone;
};

export const convertToPst = (date) => {
    return moment.tz(date, TimeZones.PST).format('MM-DD-YYYY hh:mm A');
}

export const getQuarter = (val) => {
    if (val === '3') {
        return '1st'
    } else if (val === '6') {
        return '2nd'
    } else if (val === '9') {
        return '3rd'
    } else {
        return '4th'
    }
}

export const getSelectedWeek = (val) => {
    if (val === '1') {
        return '1st'
    } else if (val === '2') {
        return '2nd'
    } else if (val === '3') {
        return '3rd'
    } else if (val === '4') {
        return '4th'
    } else {
        return '5th'
    }
}

export const getRecurringDay = (day) => {
    if (day === 'sunday') {
        return 'SU'
    } else if (day === 'monday') {
        return 'MO'
    } else if (day === 'tuesday') {
        return 'TU'
    } else if (day === 'wednesday') {
        return 'WE'
    } else if (day === 'thursday') {
        return 'TH'
    } else if (day === 'friday') {
        return 'FR'
    } else {
        return 'SA'
    }
}

// FOR CHI SURVEY FEEDBACK - FOR 4 QUARTERS IN A YEAR
export const convertedQuarterlyDate = new Date().getMonth();


export const convertTicketLogsDataToCsvType = (data) => {
    if (data) {
        if (data.length !== 0) {
            let AreaLogsData = cloneDeep(data);
            let csvAreaType = [];
            AreaLogsData.forEach(a => {
                let headers =
                {
                    'Error Message': a.message && a.message.length > 0 ? a.message : '',

                }
                csvAreaType.push(headers)

            })
            return csvAreaType;
        } else {
            return []
        }

    } else {
        return []
    }
}

export const convertDateFormatV1 = (date) => {
    let d = new Date(date),
        month = date.toLocaleString('default', { month: 'short' }),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month + ' ' + day + ', ' + year];
}

export const convertDateFormatV2 = (date) => {
    let d = new Date(date),
        month = d.getMonth() + 1,
        day = '' + d.getDate(),
        year = d.getFullYear();

    return [month + ' ' + day + ', ' + year];
}

export const convertDateFormatV3 = (date) => {
    return moment(date, "MM-DD-YYYY")._d
}

export const convertStartEndTimeFormat = (time) => {
    let fetchedTimeValue = null;

    // Check correct time format and split into components
    time = time && time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }

    fetchedTimeValue = (time[0] + ':' + time[2] + ' ' + time[5]).toString(); // return adjusted time or original string

    return fetchedTimeValue;
}

export const convertStartDateEndDate_totalDayCount = (start_date, end_date) => {
    let fetchedTimeValue = null;

    const startDate = new Date(new Date(start_date).getFullYear() + '-' + new Date(start_date).getMonth() + '-' + new Date(start_date).getDate());
    const endDate = new Date(new Date(end_date).getFullYear() + '-' + new Date(end_date).getMonth() + '-' + new Date(end_date).getDate());
    const differenceInMilliseconds = endDate - startDate;
    const differenceInDays = differenceInMilliseconds / (24 * 60 * 60 * 1000);

    fetchedTimeValue = differenceInDays == 0 ? `${differenceInDays + 1} Day` : `${differenceInDays + 1} Days`;

    return fetchedTimeValue;
}

export const getMonthNo = (month) => {
    if (month == 'January') {
        return '01'
    } else if (month == 'February') {
        return '02'
    } else if (month == 'March') {
        return '03'
    } else if (month == 'April') {
        return '04'
    } else if (month == 'May') {
        return '05'
    } else if (month == 'June') {
        return '06'
    } else if (month == 'July') {
        return '07'
    } else if (month == 'August') {
        return '08'
    } else if (month == 'September') {
        return '09'
    } else if (month == 'October') {
        return '10'
    } else if (month == 'November') {
        return '11'
    } else if (month == 'December') {
        return '12'
    }
}

export const getInbetweenWeekDates = (start, end) => {

    let inBetweenDates = [];

    let currentDate = moment(start);

    while (currentDate.isSameOrBefore(end)) {
        inBetweenDates.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'days');
    }

    return inBetweenDates;

}

export const getInbetweenWeekDates_V1 = (start, end) => {

    let inBetweenDates = [];

    let currentDate = moment(start);

    while (currentDate.isSameOrBefore(end)) {
        inBetweenDates.push(currentDate.format('dddd'));
        currentDate.add(1, 'days');
    }

    return inBetweenDates;

}

export const getInbetweenWeekDates_V2 = (start, end) => {

    let inBetweenDates = [];

    let currentDate = moment(start);

    while (currentDate.isSameOrBefore(end)) {
        inBetweenDates.push(currentDate.format('YYYY-MM-DDTHH:mm:ss'));
        currentDate.add(1, 'days');
    }

    return inBetweenDates;

}

export const getDayBinaryNo = (day) => {
    if (day == 'sunday') {
        return '0'
    } else if (day == 'monday') {
        return '1'
    } else if (day == 'tuesday') {
        return '2'
    } else if (day == 'wednesday') {
        return '3'
    } else if (day == 'thursday') {
        return '4'
    } else if (day == 'friday') {
        return '5'
    } else if (day == 'saturday') {
        return '6'
    }
}

export const getNameFromDayBinaryNo = (day) => {
    if (day == 0) {
        return 'Sunday'
    } else if (day == 1) {
        return 'Monday'
    } else if (day == 2) {
        return 'Tuesday'
    } else if (day == 3) {
        return 'Wednesday'
    } else if (day == 4) {
        return 'Thursday'
    } else if (day == 5) {
        return 'Friday'
    } else if (day == 6) {
        return 'Saturday'
    }
}

export const getConvertTo24Hours = (timeStr) => {
    const [time, meridian] = timeStr.split(' ');
    const [hour, minute] = time.split(':').map(Number);

    let hour24 = hour;

    if (meridian === 'PM' && hour !== 12) {
        hour24 += 12;
    } else if (meridian === 'AM' && hour === 12) {
        hour24 = 0;
    }

    return `${String(hour24).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
};

export const getConvertTo12Hours = (timeStr) => {
    const [hour, minute] = timeStr.split(':').map(Number);

    let period = 'AM';
    let newHour = hour;

    if (hour === 0) {
        newHour = 12;
    } else if (hour === 12) {
        period = 'PM';
    } else if (hour > 12) {
        newHour = hour - 12;
        period = 'PM';
    }

    return `${newHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
}

export const convertDateFormatV4 = (date) => {
    let formatedDate = moment(date).format('YYYY-MM-DD');
    return formatedDate;
}

export const convertDateFormatV5 = (date) => {
    let d = new Date(date),
        month = d.toLocaleString('default', { month: 'long' }),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month + ' ' + day + ', ' + year];
}

export const convertDateFormatV6 = (date) => {
    let d = new Date(date),
        month = d.toLocaleString('default', { month: 'short' }),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month + ' ' + day + ', ' + year];
}

export const convertDateFormatV7 = (date) => {

    let formatedDate = moment(date).format('MM-DD-YYYY');
    return formatedDate;
}

export const converShiftScheduleTime = (time, timezone) => {
    let zone = ''
    if (timezone === 'America/Los_Angeles') {
        zone = 'PST'
    } else if (timezone === 'America/Chicago') {
        zone = 'CST'
    }
    return moment(time, 'HH:mm:ss').format('hh:mm A') + ' ' + zone;
}

export const converShifDetailstDate = (time) => {

    // Parse the input date string as an ISO 8601 date
    const parsedDate = moment(time);

    // Format the date in the desired output format
    const formattedDate = parsedDate.format('MMM DD, YYYY');

    return formattedDate
}

/* Using this function to convert the input-select type string value into Options Object for using in Table List Pages */
export const transformStringToSelectOptionObject = (string) => {
    return {
        value: string,
        label: string.charAt(0).toUpperCase() + string.slice(1)
    };
};

// Function to convert 12-hour format to 24-hour format
export const convertTo24HourFormat = (time) => {

    let parsedTime = null;
    let convertedTime = null;

    // Parse the input time using Luxon
    parsedTime = DateTime.fromFormat(time, "h:mm a");

    // Format the time in 24-hour format
    convertedTime = parsedTime.toFormat("HH:mm:ss");

    return convertedTime;

}

export const mealPenaltyConvertTo24HourFormat = (time) => {debugger

    let parsedTime = null;
    let convertedTime = null;

    const updatedTime = time.replace(/\s*\(.*?\)$/, '');
    // Parse the input time using Luxon
    parsedTime = DateTime.fromFormat(updatedTime, "h:mm a");

    // Format the time in 24-hour format
    convertedTime = parsedTime.toFormat("HH:mm:ss");

    return convertedTime;

}

// Function to convert 24-hour format to 12-hour format
export const convertTo12HourFormat = (time) => {

    let parsedTime = null;
    let convertedTime = null;

    // Parse the input time using Luxon
    parsedTime = DateTime.fromFormat(time, "h:mm:ss");

    // Format the time in 24-hour format
    convertedTime = parsedTime.toFormat("HH:mm a");

    return convertedTime;

}

export const identifyDayNameOfWeek = (date) => {

    const dayName = moment(date).format('dddd');

    return dayName;

}

export const getHoursAndMinutesFormat_V1 = (startTime, endTime) => {

    const startTimeMoment = moment(startTime, "hh:mm A");
    const endTimeMoment = moment(endTime, "hh:mm A");

    const duration = moment.duration(endTimeMoment.diff(startTimeMoment));

    const formattedDuration = `${duration.hours()} hours and ${duration.minutes()} minutes`;

    return formattedDuration;

}

export const getHoursAndMinutesFormat_V2 = (duration) => {

    let convertedDuration = moment.duration(duration, 'minutes');
    // let formattedDuration = moment.duration(convertedDuration).humanize();
    let formattedDuration = `${convertedDuration.hours()} hours and ${convertedDuration.minutes()} minutes`;

    return formattedDuration;

}

export const getTotalMinutes_V1 = (timeFormat) => {

    let totalMinutes = null;
    const match = timeFormat.match(/(\d+) hours and (\d+) minutes/);

    if (match) {
        const hours = parseInt(match[1], 10);
        const minutes = parseInt(match[2], 10);
        const duration = moment.duration({ hours, minutes });
        totalMinutes = duration.asMinutes();
    }

    return totalMinutes;

}
export const closeFormModel = () => {
    $("[data-bs-dismiss=modal]").trigger({ type: "click" });
}

export const openFormModel = () => {
    $("[data-bs-target=exampleModal]").trigger({ type: "click" });
}


export const IsFormAccessForView = (object, key, multiple) => {

    if (!object) return false

    if (multiple) {
        return object[`${key[0]}`][`${key[1]}`].create
    }
    return object[`${key[0]}`].create
}

export const calculateTotalSeconds = (hours, minutes, seconds) => {
    return hours * 3600 + minutes * 60 + seconds;
}

export const convertSecondsToTime = (seconds) => ({
    hours: Math.floor(seconds / 3600),
    minutes: Math.floor((seconds % 3600) / 60),
    seconds: seconds % 60,
});

export const calculateHoursFromMinutes_V1 = (minutes) => {
    
    // Calculate hours and remaining minutes
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    // Format the result
    const result = `${hours} hour${hours !== 1 ? 's' : ''}, ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;

    return result;
}

/* Sun Feb 11 2024 06:25:00 GMT-0800 (Pacific Standard Time) => 2024-02-11T06:25:00 */
export const dateFormatConversion_V1 = (date) => {debugger
    let formatedDate = moment(date).format('MM-DD-YYYYTHH:mm:ss');
    return formatedDate;
};

/* This function takes a number as input and returns its ordinal representation (1st, 2nd, 3rd, etc.) */
export const getOrdinalNumber = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}

/* This function will fetch the Time difference between clockedInTime and currentTime */
export const getNarrowOfClockInTimegap = (clkInTime, totalMealBreakDuration) => {
    
    let formatTimerUnits = unit => unit.toString().padStart(2, '0');

    if (totalMealBreakDuration) {

        let clockedInTime = tzDateConverterTool_V5(clkInTime);

        // Parse the time strings into Date objects
        let clockedInTimeFormat = new Date(clockedInTime);
        let currentTimeFormat = new Date();

        // Convert total duration to hours, minutes, seconds
        const totalHours = Math.floor(totalMealBreakDuration / (1000 * 60 * 60));
        const totalMinutes = Math.floor((totalMealBreakDuration % (1000 * 60 * 60)) / (1000 * 60));
        const totalSeconds = Math.floor((totalMealBreakDuration % (1000 * 60)) / 1000);

        let totalMealBreakDurationFormat = new Date(`${clockedInTime.split('T')[0]}T${formatTimerUnits(totalHours)}:${formatTimerUnits(totalMinutes)}:${formatTimerUnits(totalSeconds)}`);

        // Calculate the difference in milliseconds
        let firstTimeDifference = currentTimeFormat - clockedInTimeFormat; // Subtracting current time with Clocked in time
        let convertedFirstTimeDifference = new Date(`${clockedInTime.split('T')[0]}T${formatTimerUnits(convertMSIntoHours(firstTimeDifference))}:${formatTimerUnits(convertMSIntoMinutes(firstTimeDifference))}:${formatTimerUnits(convertMSIntoSeconds(firstTimeDifference))}`); //Converting the subtracted time into new date format
        let secondTimeDifference = convertedFirstTimeDifference - totalMealBreakDurationFormat; // Again subtracting the Meal break time from the calculated subtracted time of Current and clocked in time

        // Convert milliseconds to hours, minutes, and seconds
        let hours = Math.floor(secondTimeDifference / (1000 * 60 * 60));
        let minutes = Math.floor((secondTimeDifference % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((secondTimeDifference % (1000 * 60)) / 1000);

        // Create the object
        let timeObject = {
            "hours": hours,
            "minutes": minutes,
            "seconds": seconds,
        };

        return timeObject;

    } else {

        let clockedInTime = tzDateConverterTool_V5(clkInTime);

        // Parse the time strings into Date objects
        let clockedInTimeFormat = new Date(clockedInTime);
        let currentTimeFormat = new Date();

        // Calculate the difference in milliseconds
        let timeDifference = currentTimeFormat - clockedInTimeFormat;

        // Convert milliseconds to hours, minutes, and seconds
        let hours = Math.floor(timeDifference / (1000 * 60 * 60));
        let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        // Create the object
        let timeObject = {
            "hours": hours,
            "minutes": minutes,
            "seconds": seconds,
        };

        return timeObject;

    }

}

export const getFormattedDateOfGoat = (year, month, date) => {

    let getMonthNo = new Date(`1 ${month} 2000`).getMonth();

    let dateObject = new Date(year, getMonthNo, date);
    let fetchedDate = moment(dateObject).format('YYYY-MM-DD');

    return fetchedDate;

}

export const convertMSIntoHours = (miliSeconds) => {
    return Math.floor(miliSeconds / (1000 * 60 * 60));
}

export const convertMSIntoMinutes = (miliSeconds) => {
    return Math.floor((miliSeconds % (1000 * 60 * 60)) / (1000 * 60));
}

export const convertMSIntoSeconds = (miliSeconds) => {
    return Math.floor((miliSeconds % (1000 * 60)) / 1000);
}

export const calculateMinutesFromHours_V1 = (hours) => {
    // Calculate hours and remaining minutes
    return hours * 60;
}
export const calculateHoursNumberFromMinutes_V1 = (minutes) => {
    // Calculate hours and remaining minutes
    const hours = Math.floor(minutes / 60);
    return hours;
}

/* This function will fetch the Time difference between Meal Break Start Time and currentTime */
export const getNarrowOfMealbreakTimegap = (mealBreakTime) => {

    let feedGapTime = tzDateConverterTool_V5(mealBreakTime);

    // Parse the time strings into Date objects
    let mealBreakStartedTimeFormat = new Date(feedGapTime);
    let currentTimeFormat = new Date();

    // Calculate the difference in milliseconds
    let timeDifference = currentTimeFormat - mealBreakStartedTimeFormat;

    // Convert milliseconds to hours, minutes, and seconds
    let feedHour = Math.floor(timeDifference / (1000 * 60 * 60));
    let feedMinute = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    let feedSecond = Math.floor((timeDifference % (1000 * 60)) / 1000);

    // Create the object
    let timeObject = {
        "feedHour": feedHour,
        "feedMinute": feedMinute,
        "feedSecond": feedSecond,
    };

    return timeObject;

}

/* Function to convert PDF to array buffer binary code */
export const generateArrayBufferFromPDF = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const arrayBuffer = reader.result;
            resolve(arrayBuffer);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsArrayBuffer(file);
    });
};

export const generateFileSizeFormat = (sizeInBytes) => {
    
    if (sizeInBytes < 1024) {
        return sizeInBytes + ' bytes';
    } else if (sizeInBytes < 1024 * 1024) {
        return (sizeInBytes / 1024).toFixed(2) + ' KB';
    } else if (sizeInBytes < 1024 * 1024 * 1024) {
        return (sizeInBytes / (1024 * 1024)).toFixed(2) + ' MB';
    } else {
        return (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    }
}

export const generateFileSizeHandler = (sizeInBytes) => {
    
    if (sizeInBytes < 1024) {
        return sizeInBytes + ' bytes';
    } else if (sizeInBytes < 1024 * 1024) {
        return (sizeInBytes / 1024).toFixed(2) + ' KB';
    } else if (sizeInBytes < 1024 * 1024 * 1024) {
        return (sizeInBytes / (1024 * 1024)).toFixed(2) + ' MB'
    } else {
        return (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    }
}

export function isValidUrl(url) {
    const pattern = /^https:\/\/[^\/]+\/account\/[\w-]+\/logbook\/[\d-]+\/log\/\d+$/;
    return pattern.test(url);
}

export const checkSizeMatch=(size,target=19)=>{
  debugger;
  if(!size || size==undefined) return true;
  const [newsize,bytesStr]=size.split(" ")
  
  if(bytesStr.toUpperCase() == "KB" || bytesStr.toUpperCase() == "bytes") return false
  if(bytesStr.toUpperCase() == "GB" ) return true

  if(+newsize >target) return true
  return false
}

export const calculateHoursFromMinutes_V1R = (minutes) => {
    
    // Calculate hours and remaining minutes
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    // Format the result
    const result = `${hours} Hour${hours !== 1 ? 's' : ''}, ${remainingMinutes} Minute${remainingMinutes !== 1 ? 's' : ''}`;

    return result;
}
export const BRMDeleteCloseModal = () => {
    const closeButton = document.getElementById("cancel");
    if(closeButton)
        {
            closeButton.click()
        }
}