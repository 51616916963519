import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { Input } from '../../UI/InputElement/InputElement';
import isEmpty from '../../../../utility/IsEmptyValidator';
import SignatureCanvas from 'react-signature-canvas'
import { makeFirstLetterCapital } from '../../../../utility/utility';
import TimecardCorrectionFormSkeleton from './TimecardCorrectionFormSkeleton';
import cloneDeep from 'clone-deep';
import { tzDateConverterTool_V1, tzDateConverterTool_V3, tzTimeConversionTool_V1, tzTimeConversionTool_V2 } from '../../../../utility/TimezoneOperations/timezoneUtility';
import { sort_TimeCard_Based_Upon_status_and_Time_ReviewForm } from '../../../../utility/timeCardUtility';
var moment = require('moment');


let TimecardCorrectionReviewerForm = (props) => {

    const { handleSubmit, isLoading, isTriggerLoader, user, singleTimecardCorrectData, handleCancel } = props;
    let { managerSignShade, payrollSignShade } = useRef({});
    console.log("props", props.initialValues.timecard.correctedInfo);
    const getFrontlineUsersList = (frontlineUsersList) => {
        if (props.frontlineUsersList && props.frontlineUsersList.length > 0) {
            return frontlineUsersList && frontlineUsersList.map((data) => {
                return {
                    value: data.id,
                    label: data.first_name + ' ' + data.last_name,

                }
            })
        } else {
            return []
        }
    };

    const [frontlineUsersList, setFrontlineUsersList] = useState(getFrontlineUsersList(props.frontlineUsersList));

    useEffect(() => {
        setFrontlineUsersList(getFrontlineUsersList(props.frontlineUsersList))
    }, [props.frontlineUsersList]);

    const handleMultiSignatures = () => {

        if (singleTimecardCorrectData.manager_id == null) {
            if (props?.formStates?.timecard?.managerId !== null) {
                if (managerSignShade.isEmpty() === false) {
                    let managerSignUrl = managerSignShade.getTrimmedCanvas().toDataURL("image/png");
                    let baseFile = { managerSignUrl };
                    props.handleManagerSignature(baseFile);
                }
            }
        }

        if ((singleTimecardCorrectData?.payroll_id == null) && (singleTimecardCorrectData?.manager_id !== null)) {
            if (props?.formStates?.timecard?.payrollId !== null) {
                if (payrollSignShade.isEmpty() === false) {
                    let payrollSignUrl = payrollSignShade.getTrimmedCanvas().toDataURL("image/png");
                    let baseFile = { payrollSignUrl };
                    props.handlePayrollSignature(baseFile);
                }
            }
        }

    };

    if (isTriggerLoader) {
        return (
            <TimecardCorrectionFormSkeleton />
        )
    } else {
        return (
            <>
                <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                    <div className="inspection_content_sec appcontent_Inner">
                        <div className="container-fluids">
                            <div className="quote_form ticket_form" style={{ maxWidth: '1200px' }}>

                                <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <h2 className="md_title" style={{ color: '#4a4a4a', fontWeight: '500', fontSize: '35px' }}>
                                            {(singleTimecardCorrectData?.payroll_id !== null) && (singleTimecardCorrectData?.manager_id !== null) || (user?.role == 'frontline') ? 'Timecard Corrected Info' : 'Update Timecard Correction'}
                                        </h2>
                                    </div>
                                </div>

                                <form className="form_content" onSubmit={handleSubmit} >
                                    <div className="addQuote_form mb-3">
                                        <div className="cst_tab_content">

                                            <div className="addQuote_form mt-3 mb-3">

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form_title fw-bold"> Employee Name </label>
                                                            <Input name="timecard[empName]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="Type here" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form_title fw-bold"> Employee ID# </label>
                                                            <Input name="timecard[empMail]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="Type here" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="addQuote_form mt-3 mb-3">

                                                <label className="form_title font-weight-bold mb-4"> STEP 1 </label>
                                                {
                                                    props?.initialValues?.timecard?.correctedInfo?.length ? props?.initialValues?.timecard?.correctedInfo.map((_, index) => {
                                                        return (
                                                            <div key={index} className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Job </label>
                                                                        <Input name={`timecard[correctedInfo][${index}][accountId]`} type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="Type here" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Job Time </label>
                                                                        <Input name={`timecard[correctedInfo][${index}][shiftTime]`} type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : null
                                                }




                                                {/* <div className="row">
                                                    {singleTimecardCorrectData?.corrections?.filter((e) => e.entity_type == 'ShiftDetail')?.map((shiftDetail, index) => {
                                                        debugger
                                                        if (shiftDetail.start_time) {
                                                            debugger
                                                        }

                                                    })}
                                                </div> */}

                                                {singleTimecardCorrectData?.corrections?.map((time, index) => {
                                                    if (time.entity_type == 'Break') {
                                                        return (
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Meal In </label>
                                                                        <Input name={`timecard[breakIn][${index}][mealIn]`} type="text" readOnly={true} className="form-control input-modifier text-color-shiraz" id="" placeholder="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Meal Out </label>
                                                                        <Input name={`timecard[breakOut][${index}][mealOut]`} type="text" readOnly={true} className="form-control input-modifier text-color-shiraz" id="" placeholder="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })}

                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form_title fw-bold"> Clock In </label>
                                                            <Input name="timecard[clockIn]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form_title fw-bold"> Clock Out </label>
                                                            <Input name="timecard[clockOut]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form_title fw-bold"> Date of Error </label>
                                                            <div className="input-group cst_input_group col-lg-14 pl-0">
                                                                <div style={{ width: '100%' }}>
                                                                    <Input name="timecard[errorDate]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                </div>
                                                                <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                    <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form_title fw-bold"> Reason for Error </label>
                                                            <Input name="timecard[errorReason]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="addQuote_form mt-3 mb-3">

                                                <label className="form_title font-weight-bold mb-4"> STEP 2 </label>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="form_title fw-bold">
                                                                I {makeFirstLetterCapital(singleTimecardCorrectData?.employee?.first_name) + makeFirstLetterCapital(singleTimecardCorrectData?.employee?.last_name)} certify that I agree with the {`error(s)`} above, and I agree to have my payroll/record changed to reflect these corrections. I understand that failing to Following the Peg Time policy will result in discipline, up to and including termination.
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="addQuote_form mt-3 mb-3">

                                                <label className="form_title font-weight-bold mb-4"> STEP 3 </label>

                                                <div className="row">
                                                    <div className="col-md-12 monthly_tab">
                                                        <div className="form-group box_input">
                                                            <label className="form_title fw-bold">
                                                                *** For a clock Out error, the employee must complete the attestation question***
                                                            </label>
                                                            <label className="form_title fw-bold">
                                                                1. I certify I was able to take my meal and rest break(s) today in accordance with state law, I performed my work today without sustaining any work injury and that my work hours are complete and accurate.
                                                            </label>
                                                            {singleTimecardCorrectData.clockout_agreement == true ? 'Yes' : 'No'}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="addQuote_form mt-3 mb-3">

                                                <label className="form_title font-weight-bold mb-4"> STEP 4 </label>

                                                <div className='d-flex'>

                                                    <div className="flex-direction-column" style={{ width: '35%' }}>
                                                        {singleTimecardCorrectData?.employee_signature_urls?.original ? (
                                                            <>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Employee Name </label>
                                                                        <Input name="timecard[empId]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Date Signed </label>
                                                                        <div className="input-group cst_input_group col-lg-14 pl-0">
                                                                            <div style={{ width: '100%' }}>
                                                                                <Input name="timecard[empSignDate]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                            </div>
                                                                            <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                                <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 flex_25 ">
                                                                    <label className="form_title fw-bold"> Employee Signature </label>
                                                                    <div className="form-group">
                                                                        <img src={singleTimecardCorrectData?.employee_signature_urls?.medium} style={{ height: '10rem', width: '21rem', borderRadius: '5px', borderColor: '#C2C2C2', borderWidth: 'thin', borderStyle: 'solid', float: 'left' }} alt="dfdf" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : null}
                                                    </div>

                                                    <div className="flex-direction-column" style={{ width: '35%' }}>
                                                        {(user?.role !== 'frontline') ? singleTimecardCorrectData?.manager_signature_urls?.original ? (
                                                            <>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Supervisor/Manager Name </label>
                                                                        <Input name="timecard[managerId]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Date Signed </label>
                                                                        <div className="input-group cst_input_group col-lg-14 pl-0">
                                                                            <div style={{ width: '100%' }}>
                                                                                <Input name="timecard[managerSignDate]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                            </div>
                                                                            <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                                <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 flex_25">
                                                                    <label className="form_title fw-bold"> Supervisor/Manager Signature </label>
                                                                    <div className="form-group">
                                                                        <img src={singleTimecardCorrectData?.manager_signature_urls?.medium} style={{ height: '10rem', width: '21rem', borderRadius: '5px', borderColor: '#C2C2C2', borderWidth: 'thin', borderStyle: 'solid', float: 'left' }} alt="dfdf" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Supervisor/Manager Name </label>
                                                                        <Input name="timecard[managerId]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Date Signed <sup className="cst_sup_txt text_danger">*</sup> </label>
                                                                        <div className="input-group cst_input_group col-lg-14 pl-0">
                                                                            <div style={{ width: '100%' }}>
                                                                                <Input
                                                                                    name="timecard[managerSignDate]"
                                                                                    type="date"
                                                                                    datePickerType={"react-datepicker"}
                                                                                    minDate={new Date()}
                                                                                    dateFormat="MM-dd-yyyy"
                                                                                    className="form-control input-modifier"
                                                                                    placeholder="Select Date"
                                                                                />
                                                                            </div>
                                                                            <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                                <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 flex_25">
                                                                    <label className="form_title fw-bold"> Supervisor/Manager Signature <sup className="cst_sup_txt text_danger">*</sup> </label>
                                                                    <div className="form-group">
                                                                        <SignatureCanvas
                                                                            ref={(ref) => { managerSignShade = ref; }}
                                                                            penColor='black' backgroundColor='white'
                                                                            canvasProps={{ width: 600, height: 300, className: 'form-control textarea_modifier' }}
                                                                        />
                                                                        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}><button type="button" onClick={clearSign} className="btn mr-2 btn-outline-secondary">Clear</button></div> */}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : singleTimecardCorrectData?.manager_id?.length > 0 ? (
                                                            <>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Supervisor/Manager Name </label>
                                                                        <Input name="timecard[managerId]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Date Signed </label>
                                                                        <div className="input-group cst_input_group col-lg-14 pl-0">
                                                                            <div style={{ width: '100%' }}>
                                                                                <Input name="timecard[managerSignDate]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                            </div>
                                                                            <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                                <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 flex_25">
                                                                    <label className="form_title fw-bold"> Supervisor/Manager Signature </label>
                                                                    <div className="form-group">
                                                                        <img src={singleTimecardCorrectData?.manager_signature_urls?.medium} style={{ height: '10rem', width: '21rem', borderRadius: '5px', borderColor: '#C2C2C2', borderWidth: 'thin', borderStyle: 'solid', float: 'left' }} alt="dfdf" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Supervisor/Manager Reviewing Status </label>
                                                                        <Input type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="Pending" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>

                                                    <div className="flex-direction-column" style={{ width: '35%' }}>
                                                        {(user?.role !== 'frontline') ? singleTimecardCorrectData?.payroll_signature_urls?.original ? (
                                                            <>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Payroll Name </label>
                                                                        <Input name="timecard[payrollId]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Date Signed </label>
                                                                        <div className="input-group cst_input_group col-lg-14 pl-0">
                                                                            <div style={{ width: '100%' }}>
                                                                                <Input name="timecard[payrollSignDate]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                            </div>
                                                                            <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                                <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 flex_25">
                                                                    <label className="form_title fw-bold"> Payroll Signature </label>
                                                                    <div className="form-group">
                                                                        <img src={singleTimecardCorrectData?.payroll_signature_urls?.medium} style={{ height: '10rem', width: '21rem', borderRadius: '5px', borderColor: '#C2C2C2', borderWidth: 'thin', borderStyle: 'solid', float: 'left' }} alt="dfdf" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : singleTimecardCorrectData?.manager_id?.length > 0 ? (
                                                            <>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Payroll Name </label>
                                                                        <Input name="timecard[payrollId]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Date Signed <sup className="cst_sup_txt text_danger">*</sup> </label>
                                                                        <div className="input-group cst_input_group col-lg-14 pl-0">
                                                                            <div style={{ width: '100%' }}>
                                                                                <Input
                                                                                    name="timecard[payrollSignDate]"
                                                                                    type="date"
                                                                                    datePickerType={"react-datepicker"}
                                                                                    minDate={new Date()}
                                                                                    dateFormat="MM-dd-yyyy"
                                                                                    className="form-control input-modifier"
                                                                                    placeholder="Select Date"
                                                                                />
                                                                            </div>
                                                                            <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                                <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 flex_25">
                                                                    <label className="form_title fw-bold"> Payroll Signature <sup className="cst_sup_txt text_danger">*</sup> </label>
                                                                    <div className="form-group">
                                                                        <SignatureCanvas
                                                                            ref={(ref) => { payrollSignShade = ref; }}
                                                                            penColor='black' backgroundColor='white'
                                                                            canvasProps={{ width: 600, height: 300, className: 'form-control textarea_modifier' }}
                                                                        />
                                                                        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}><button type="button" onClick={clearSign} className="btn mr-2 btn-outline-secondary">Clear</button></div> */}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Payroll Fields </label>
                                                                        <Input type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="Upon completing Manager Approval status, Payroll approval fields become accessible" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : singleTimecardCorrectData?.payroll_id?.length > 0 ? (
                                                            <>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Payroll Name </label>
                                                                        <Input name="timecard[payrollId]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Date Signed </label>
                                                                        <div className="input-group cst_input_group col-lg-14 pl-0">
                                                                            <div style={{ width: '100%' }}>
                                                                                <Input name="timecard[payrollSignDate]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                            </div>
                                                                            <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                                <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <label className="form_title fw-bold"> Payroll Signature </label>
                                                                    <div className="form-group">
                                                                        <img src={singleTimecardCorrectData?.payroll_signature_urls?.medium} style={{ height: '10rem', width: '21rem', borderRadius: '5px', borderColor: '#C2C2C2', borderWidth: 'thin', borderStyle: 'solid', float: 'left' }} alt="dfdf" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="col-md-12 flex_25">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> Payroll Approval Status </label>
                                                                        <Input type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="Pending" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="modal-footer">
                                        {(user?.role !== 'frontline') ? (singleTimecardCorrectData?.payroll_id !== null) && (singleTimecardCorrectData?.manager_id !== null) ? null : (
                                            <>
                                                <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" onClick={handleCancel}> Back </button>
                                                <button data-test="submit_button" type="submit" disabled={isLoading} className={isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} onClick={handleMultiSignatures}>{isLoading ? "" : (singleTimecardCorrectData?.manager_id !== null) ? 'Approve' : 'Review'}</button>
                                            </>
                                        ) : (
                                            <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" onClick={handleCancel}> Back </button>
                                        )}
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }

};


function convertShiftDetails(shiftDetailJobs) {
    return shiftDetailJobs.map((shift) => {
        return {
            ...shift,
            accountId: shift.account.name,
            shiftTime: tzTimeConversionTool_V1(shift?.start_at, shift?.start_at?.split('T')[0]) + ' − ' + tzTimeConversionTool_V1(shift?.end_at, shift?.end_at?.split('T')[0])
        }
    })
}

function convertShiftDetailsTocorrectedInfo(shiftDetailJobs, correctedInfo, key) {
    
    let data = sort_TimeCard_Based_Upon_status_and_Time_ReviewForm(correctedInfo).filter((shift) => shift.entity_type == key).map((shift) => {
        let is_startTimeParams= shift?.start_time==null ?  false :true
        let is_endTimeParams=shift?.end_time ==null ? false : true
        return {
            ...shift,
            accountId: shift?.shiftDetailJob?.account.name,
            shiftTime: ( 
                is_startTimeParams && is_endTimeParams ? 
                tzTimeConversionTool_V1(shift?.start_time.split('T')[1].split('.')[0], shift?.start_time?.split('T')[0]) + ' − ' + tzTimeConversionTool_V1(shift?.end_time.split('T')[1].split('.')[0], shift?.end_time?.split('T')[0]) :
                 is_startTimeParams && !is_endTimeParams ? tzTimeConversionTool_V1(shift?.start_time.split('T')[1].split('.')[0], shift?.start_time?.split('T')[0]) + ' − ' + "Not Clocked out Yet" : 
                 !is_startTimeParams && !is_endTimeParams ? "Not Clocked In Yet" : ""

            )
        }
        // start_at: acc?.start_at ? tzTimeConversionTool_V2(acc?.start_at.split('T')[1].split('.')[0], acc.start_at.split('T')[0]) : "",
        // end_at: acc?.end_at ? tzTimeConversionTool_V2(acc?.end_at.split('T')[1].split('.')[0], acc.end_at.split('T')[0]) : "",
    })
    
    return data
}

const mapStateToProps = (state, props) => {

    let timecard = {};

    /* For fetching Job Name */
    const getJobObject = (user) => {
        let fetchedJobObject = null;
        fetchedJobObject = {
            value: user.id,
            label: makeFirstLetterCapital(user.name)
        }
        return fetchedJobObject;
    };

    if (isEmpty(state.TimeTrackerModReducer.singleTimecardCorrectData)) {

        timecard = {
        }

    } else {

        // let catchedFormValues = state.form.updateTimecardCorrectReviewForm.values.timecard;
        const value = cloneDeep(state.TimeTrackerModReducer.singleTimecardCorrectData);
        const shiftDetail = cloneDeep(state.TimeTrackerModReducer.singleShiftScheduleData);
        console.log(shiftDetail);
        let clockedInTimeSlot = null
        let clockedOutTimeSlot = null

        let breakInSlots = null
        let breakOutSlots = null

        if (value?.corrections?.map((e) => e.entity_type == 'ShiftDetail')) {

            let data = value.corrections?.filter((e) => e.entity_type == 'ShiftDetail').map((shiftDetail) => {
                if (shiftDetail?.start_time) {
                    clockedInTimeSlot= shiftDetail?.start_time ? tzTimeConversionTool_V2(shiftDetail?.start_time.split('T')[1].split('.')[0], shiftDetail?.start_time.split('T')[0]) : '';
                } else {
                    clockedInTimeSlot= value?.shiftDetail?.check_int_at !== null ? tzTimeConversionTool_V2(value.shiftDetail.check_int_at.split('T')[1].split('.')[0], value.shiftDetail.date.split('T')[0]) : '';
                }
                return clockedInTimeSlot
            });

            let copy= value.corrections?.filter((e) => e.entity_type == 'ShiftDetail').map((shiftDetail) => {
                if (shiftDetail?.end_time) {
                    clockedOutTimeSlot= shiftDetail?.end_time ? tzTimeConversionTool_V2(shiftDetail?.end_time.split('T')[1].split('.')[0], shiftDetail?.end_time.split('T')[0]) : '';
                } else {
                    clockedOutTimeSlot= value?.shiftDetail?.check_out_at !== null ? tzTimeConversionTool_V2(value.shiftDetail.check_out_at.split('T')[1].split('.')[0], value.shiftDetail.date.split('T')[0]) : '';
                }
                return clockedInTimeSlot
            });

        }
        else {
            clockedInTimeSlot = value?.shiftDetail?.check_int_at !== null ? tzTimeConversionTool_V2(value.shiftDetail.check_int_at.split('T')[1].split('.')[0], value.shiftDetail.date.split('T')[0]) : '';
            clockedOutTimeSlot = value?.shiftDetail?.check_out_at !== null ? tzTimeConversionTool_V2(value.shiftDetail.check_out_at.split('T')[1].split('.')[0], value.shiftDetail.date.split('T')[0]) : '';
        }

        if (value?.corrections?.map((e) => e.entity_type == 'Break')) {
            breakInSlots = value.corrections.map((mealBreak) => {
                if (mealBreak.entity_type == 'Break') {
                    return { id: mealBreak?.id, mealIn: mealBreak?.start_time ? tzTimeConversionTool_V2(mealBreak?.start_time.split('T')[1].split('.')[0], mealBreak?.start_time.split('T')[0]) : '' }
                }
            });
            breakOutSlots = value.corrections.map((mealBreak) => {
                if (mealBreak.entity_type == 'Break') {
                    return { id: mealBreak?.id, mealOut: mealBreak?.end_time ? tzTimeConversionTool_V2(mealBreak?.end_time.split('T')[1].split('.')[0], mealBreak?.end_time.split('T')[0]) : '' }
                }
            });
        } else {
            breakInSlots = value?.shiftDetail?.breaks?.map((mealBreak) => {
                return { id: mealBreak?.id, mealIn: mealBreak?.start_time ? tzTimeConversionTool_V2(mealBreak?.start_time.split('T')[1].split('.')[0], mealBreak?.start_time.split('T')[0]) : '' }
            });
            breakOutSlots = value?.shiftDetail?.breaks?.map((mealBreak) => {
                return { id: mealBreak?.id, mealOut: mealBreak?.end_time ? tzTimeConversionTool_V2(mealBreak?.end_time.split('T')[1].split('.')[0], mealBreak?.end_time.split('T')[0]) : '' }
            });
        }

        // let ClockedIn = value.shiftDetail && value.shiftDetail?.check_int_at ? tzTimeConversionTool_V2(value.shiftDetail?.check_int_at.split('T')[1].split('.')[0], value.shiftDetail?.check_int_at.split('T')[0]) : ""
        // let ClockedOut = value.shiftDetail && value.shiftDetail?.check_out_at ? tzTimeConversionTool_V2(value.shiftDetail?.check_out_at.split('T')[1].split('.')[0], value.shiftDetail?.check_out_at.split('T')[0]) : ""
        
        timecard = {
            empName: value?.employee?.id ? makeFirstLetterCapital(value.employee.first_name) + makeFirstLetterCapital(value.employee.last_name) : '',
            empMail: value?.employee?.employee_id ? value?.employee.employee_id : '',
            accountId: value?.shiftDetail?.accounts[0]?.id ? value.shiftDetail.accounts[0].name : '',
            shiftTime: shiftDetail?.shift?.id ? tzTimeConversionTool_V1(shiftDetail?.shift?.start_time, value?.shift?.start_date?.split('T')[0]) + ' − ' + tzTimeConversionTool_V1(shiftDetail?.shift?.end_time, shiftDetail?.shift?.end_date?.split('T')[0]) : '',
            allShiftDetails: shiftDetail?.shiftDetailJobs?.length ? convertShiftDetails(shiftDetail?.shiftDetailJobs) : [],
            correctedInfo: value?.shiftDetailJob?.length && value?.corrections?.length ? convertShiftDetailsTocorrectedInfo(value?.shiftDetailJob, value?.corrections, "ShiftDetailJob") : [],
            clockIn: clockedInTimeSlot,
            clockOut: clockedOutTimeSlot,
            breakIn: breakInSlots?.length > 0 ? breakInSlots : [],
            breakOut: breakOutSlots?.length > 0 ? breakOutSlots : [],
            errorDate: value?.date_error ? tzDateConverterTool_V1(value.date_error) : '',
            errorReason: value?.corrections[0]?.reason || value.reason ? (value.corrections[0].reason || value.reason) : "" ,
            empId: value?.employee?.id ? makeFirstLetterCapital(value.employee.first_name) + makeFirstLetterCapital(value.employee.last_name) : '',
            empSignDate: value?.employee_signed_date ? tzDateConverterTool_V1(value.employee_signed_date) : '',
            managerId: value?.manager?.id ? makeFirstLetterCapital(value.manager.first_name) + makeFirstLetterCapital(value.manager.last_name) : props?.user?.position?.name == 'Manager' ? props.user.first_name + ' ' + props.user.last_name : '',
            managerSignDate: value?.manager_singed_date ? tzDateConverterTool_V1(value.manager_singed_date) : value?.id ? tzDateConverterTool_V3(moment().format('YYYY-MM-DDTHH:mm:ss')) : null,
            payrollId: value?.payroll?.id ? makeFirstLetterCapital(value.payroll.first_name) + makeFirstLetterCapital(value.payroll.last_name) : props?.user?.payroll_access == true ? props.user.first_name + ' ' + props.user.last_name : '',
            payrollSignDate: value?.payroll_signed_date ? tzDateConverterTool_V1(value.payroll_signed_date) : value?.id ? tzDateConverterTool_V3(moment().format('YYYY-MM-DDTHH:mm:ss')) : null,
        }

    }

    return {
        initialValues: { timecard },
        formStates: getFormValues('updateTimecardCorrectReviewForm')(state)
    }

}

TimecardCorrectionReviewerForm = reduxForm({
    form: 'updateTimecardCorrectReviewForm',
    // validate,
    enableReinitialize: true
})(TimecardCorrectionReviewerForm);

TimecardCorrectionReviewerForm = connect(mapStateToProps)(TimecardCorrectionReviewerForm)

export default TimecardCorrectionReviewerForm;