import React, { useEffect, useState } from 'react';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input } from '../../../UI/InputElement/InputElement';
import { convertDateToPickerFormat, getOrdinalNumber, transformStringToSelectOptionObject } from '../../../../../utility/utility';
import { shiftStatusTypeOptions, userRoles } from '../../../../../utility/constants/constants';
import ShiftScheduleDetailTable from './ShiftScheduleDetailTable';
import { DialogContent } from '@material-ui/core';
import { tzTimeConversionTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
var feedGap = null;

let ShiftScheduleDetail = (props) => {

    const { handleSubmit, pristine, reset, submitting, error } = props;

    const [openMealBreakModal, setMealBreakModalOpen] = useState(false);
    const [currentMealBreak, setCurrentMealBreak] = useState(null);

     /* This functionality for meal breaks Ascending order */
     if (currentMealBreak) {
        feedGap = currentMealBreak?.sort((a) => {
            return a.id;
        })
    };

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content ticket_filter">
                    <form onSubmit={handleSubmit}>
                        <div className="row mt-2">

                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <Input name="shiftStatus" dataTest="select_leave_type" type="inputSelect" options={shiftStatusTypeOptions} placeholder="Search by Status" />
                            </div>

                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <div className="input-group cst_input_group col-lg-14 pl-0">
                                    <label>
                                        <div style={{ width: '100%' }}>
                                            <Input
                                                type="date"
                                                datePickerType={"react-datepicker"}
                                                dateFormat="MM-dd-yyyy"
                                                className="form-control input-modifier"
                                                placeholder="From Date"
                                                name="fromDate"
                                            />
                                        </div>
                                        <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "37px", top: 0 }}>
                                            <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <div className="input-group cst_input_group col-lg-14 pl-0">
                                    <label>
                                        <div style={{ width: '100%' }}>
                                            <Input
                                                type="date"
                                                datePickerType={"react-datepicker"}
                                                dateFormat="MM-dd-yyyy"
                                                className="form-control input-modifier"
                                                placeholder="To Date"
                                                name="toDate"
                                            />
                                        </div>
                                        <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "37px", top: 0 }}>
                                            <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="frontline_btn">
                                    <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                    <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"}</button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

                <section className="account_sec inspection_tab_content appcontent_Inner">
                    <div className="quotes_table_content accounts_table_contnet table-responsive">
                        <div className="table quotes_table user_react_table">

                            <ShiftScheduleDetailTable
                                {...props}
                                setMealBreakModalOpen={setMealBreakModalOpen}
                                setCurrentMealBreak={setCurrentMealBreak}
                            />
                        </div>
                    </div>
                </section>
            </main>

            <Dialog
                open={openMealBreakModal}
                aria-labelledby="form-dialog-title"
            >
                <div className="row p-2 m-0">
                    <div className="col-10">
                        <h4 className="md_title" style={{ color: '#4a4a4a', fontWeight: '500', fontSize: '35px' }}>Meal Break</h4>
                    </div>
                    <div className="col-2">
                        <span onClick={() => setMealBreakModalOpen(false)} className="float-right text-right mt-0 cursor-pointer"><i className="fa fa-times"></i></span>
                    </div>
                </div>
                <DialogContent>
                    <>
                        <div className="p-3">
                            <div className="mt-1 d-flex">
                                {
                                    feedGap?.map((item, index) => {
                                        let startMeal = item?.start !== null ? item.start : null;
                                        let endMeal = item?.end !== null ? item.end : null;
                                        let startDate = startMeal?.split('T')[0];
                                        let startTime = startMeal?.split('T')[1]?.split('.')[0];
                                        let endDate = endMeal?.split('T')[0];
                                        let endTime = endMeal?.split('T')[1]?.split('.')[0];

                                        let formattedStartMeal = startMeal == null ? '____' : tzTimeConversionTool_V1(startTime, startDate);
                                        let formattedEndMeal = endMeal == null ? '____' : tzTimeConversionTool_V1(endTime, endDate);
                                        return (
                                            <>
                                                <div className='d-flex flex-xl-column align-items-stretch'>
                                                    <label className="m-1" style={{ color: '#727272', fontWeight: 'bold' }}> {getOrdinalNumber(index + 1)} meal </label>
                                                    <label key={index} className="m-1 timeslot-btn">{formattedStartMeal + ' − ' + formattedEndMeal} </label>
                                                </div>
                                            </>
                                        );
                                    }
                                    )
                                }
                            </div>
                        </div>
                    </>
                </DialogContent>
                <div className=" p-2">
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setMealBreakModalOpen(false)} className={"float-right btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel">Close</button>
                </div>
            </Dialog>

            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }} data-test="modal_delete_title">Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button data-test="modal_delete" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteTicket(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button data-test="modal_cancel" style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => {

    let shiftStatus = state.TimeTrackerModReducer.shiftScheduleDetail_searchValues && state.TimeTrackerModReducer.shiftScheduleDetail_searchValues.shiftStatus ? transformStringToSelectOptionObject(state.TimeTrackerModReducer.shiftScheduleDetail_searchValues.shiftStatus) : '';
    let fromDate = state.TimeTrackerModReducer.shiftScheduleDetail_searchValues && state.TimeTrackerModReducer.shiftScheduleDetail_searchValues.fromDate ? convertDateToPickerFormat(state.TimeTrackerModReducer.shiftScheduleDetail_searchValues.fromDate) : "";
    let toDate = state.TimeTrackerModReducer.shiftScheduleDetail_searchValues && state.TimeTrackerModReducer.shiftScheduleDetail_searchValues.toDate ? convertDateToPickerFormat(state.TimeTrackerModReducer.shiftScheduleDetail_searchValues.toDate) : "";

    return { initialValues: { shiftStatus, fromDate, toDate }, formStates: getFormValues('searchShiftScheduleDetail')(state) }

}

ShiftScheduleDetail = reduxForm({
    form: 'searchShiftScheduleDetail',
    // validate,
    enableReinitialize: true
})(ShiftScheduleDetail);

const selector = formValueSelector('searchShiftScheduleDetail');

ShiftScheduleDetail = connect(state => {
    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
})(ShiftScheduleDetail);

ShiftScheduleDetail = connect(mapStateToProps)(ShiftScheduleDetail)

export default ShiftScheduleDetail;