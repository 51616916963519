import React, { Component } from 'react';
import InventoryAccessComponent from '../../../component/Inventory/InventoryAccessComponent/InventoryAccessComponent';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';

class InventoryAccessContainer extends Component {
    handleSubmit = (value) => {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <InventoryAccessComponent
                {...this.props}
                onSubmit={this.handleSubmit}
                isLoading={this.props.isLoading}
                closeProfileModel={this.props.closeProfileModel}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.InventoryReducer.isLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeProfileModel: () => dispatch(actions.closeProfileModel),
        reset: (inventoryAccessForm) => dispatch(reset(inventoryAccessForm)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryAccessContainer);