import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card, Select } from 'antd';
// import { inventoryAccessRoleOptions } from '../../../../utility/constants/constants';
import storage from '../../../../utility/storage';
import SuccessAlert from '../../../../utility/successAlert/successAlert';
import { makeFirstLetterCapital } from '../../../../utility/utility';
const { Meta } = Card;

const InventoryAccessComponent = (props) => {

    const user = storage.get('user');
    const storedInventoryAccess = storage.get('InventoryAccessRole');
    let userInventoryAccessRole = [];
    let inventoryAccessRoleOptions = [];

    if(user && user.inventory_access && user.inventory_access.length && user.inventory_access.length > 0) {
        userInventoryAccessRole = user.inventory_access.filter((role) => {
            return role !== "admin"
        });
    }

    if(userInventoryAccessRole && userInventoryAccessRole.length && userInventoryAccessRole.length > 0) {
        inventoryAccessRoleOptions = userInventoryAccessRole && userInventoryAccessRole.map((role) => {
            return {value: role, label: makeFirstLetterCapital(role)}
        })
    }

    const [isAccessLoading, setIsAccessLoading] = useState(false);
    const [inventoryAccessRole, setInventoryAccessRole] = useState(storedInventoryAccess && storedInventoryAccess.length > 0 ? storedInventoryAccess : userInventoryAccessRole[0]);

    const handleInventoryAccessRole = (role) => {
        setIsAccessLoading(true);
        setTimeout(() => setIsAccessLoading(false), 1000);
        setInventoryAccessRole(role);
        storage.set('InventoryAccessRole', role);
    };

    const submitInventoryAccessRole = () => {
        props.closeProfileModel();
        setTimeout(() => {
            props.triggerInitial();
            SuccessAlert('Inventory Access Granted');
        }, 200);
    }

    const { handleSubmit, pristine, reset, submitting, error } = props;

    return (
        <div id="password" className="tab-pane password_tab fade cst_tab_content tab-pane fade show active">
            <form className="form_content" onSubmit={handleSubmit} data-test="change_pwd_form">

                <div className="form-group">
                    <div className="row" style={{justifyContent: 'space-evenly', alignItems: 'center'}}>
                        <div className="inventory_antd_select pl-1">
                            {/* <label className="form_title">Access Roles</label> */}
                            <Select
                                // suffixIcon={null}
                                size='large'
                                className="mr-2"
                                style={{ minHeight: '36px', minWidth: '270px' }}
                                loading={isAccessLoading}
                                placement={"bottomRight"}
                                defaultValue={inventoryAccessRole && !(inventoryAccessRole == undefined) ? inventoryAccessRole : "Select any Role"}
                                options={inventoryAccessRoleOptions}
                                onChange={(role)=>{handleInventoryAccessRole(role);}}
                            />
                        </div>
                        <div className="inventory_antd_card pr-1">
                            <Card
                                style={{width: '270px', textAlign: 'center'}}
                                bordered={false}
                                loading={isAccessLoading}
                                // hoverable={true}
                                // size='default'
                                // cover={
                                //   <img
                                //     alt="example"
                                //     src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                                //   />
                                // }
                                actions={[
                                //   <SettingOutlined key="setting" />,
                                //   <EditOutlined key="edit" />,
                                //   <EllipsisOutlined key="ellipsis" />,
                                ]}
                            >
                                <Meta
                                    avatar={
                                        <Avatar 
                                            src={
                                                inventoryAccessRole == 'manager' ? "/images/icons/manager.png" :
                                                inventoryAccessRole == 'fulfillmentManager' ? "/images/icons/fulfillment_manager.png" :
                                                inventoryAccessRole == 'supervisor' ? "/images/icons/supervisor.png" :
                                                inventoryAccessRole == 'approver' ? "/images/icons/approver.png" :
                                                "/images/gif/selectData.gif"
                                            }
                                        />
                                    }
                                    title={
                                        inventoryAccessRole == 'manager' ? "Manager Role" :
                                        inventoryAccessRole == 'fulfillmentManager' ? "Fulfillment Manager" :
                                        inventoryAccessRole == 'supervisor' ? "Supervisor Role" :
                                        inventoryAccessRole == 'approver' ? "Approver Role" :
                                        "SELECT DATA"
                                    }
                                    // description="This is the description"
                                />
                            </Card>
                        </div>
                    </div>
                </div>

                <div className="clearfix"></div>
                <div className="modal-footer">
                    <button data-test="submit_button" type='submit' disabled={props.isLoading} onClick={()=>{submitInventoryAccessRole();}} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} data-dismiss="modal">{props.isLoading ? "" : "Change Access"}</button>
                    {/* <button data-test="cancel_button" onClick={()=>{props.triggerInitial(); props.closeProfileModel();}} type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Close</button> */}
                </div>

            </form>
        </div>
    )
}


export default reduxForm({
    form: 'inventoryAccessForm',
    // validate
})(InventoryAccessComponent);