import React, { useEffect, useState } from 'react';
import '../../../styles/custom.css';
import 'react-table-v6/react-table.css';
import { routes, userRoles } from '../../../utility/constants/constants';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
    convertDateTimezonePopupEvents, convertDateTimezoneSchedule, makeFirstLetterCapital, startOfMonth, startOfWeek, startOfAgenda,
    endOfMonth, endOfWeek, endOfAgenda, convertDateFormatForSchedule, convertDateToDifferentTZ
} from '../../../utility/utility';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ClapSpinner } from 'react-spinners-kit';
import { useSelector } from 'react-redux';
import storage from '../../../utility/storage';
import ReactTooltip from 'react-tooltip';
import { data } from 'jquery';

var moment = require('moment');
const localizer = momentLocalizer(moment)
window.isMoreClicked = false;

const ScheduleComponents = (props) => {
    const [agendaClicked, setAgendaClicked] = useState(false);
    const [dayClicked, setDayClicked] = useState(false);
    const account_info = useSelector(state => state.adminOrSuperAdminReducer.currentAccount);

    useEffect(() => {
        let ele = document.getElementsByClassName('rbc-btn-group');
        if (ele) {
            ele[0].childNodes[1].textContent = "Previous"
        }
        if (ele && ele[1]) {
            ele[1].childNodes[3].addEventListener('click', function handleClick() {
                setAgendaClicked(true);
                setDayClicked(false);
                setTimeout(() => {
                    let ele2 = document.getElementsByClassName('rbc-header');
                    if (ele2 && ele2[2]) {
                        ele2[2].textContent = 'Job | Event'
                    }
                    let agendaLabel = document.getElementsByClassName('rbc-toolbar-label');
                    if (agendaLabel) {
                        if (agendaLabel[0].textContent.includes('Next 30 Days')) {
                            agendaLabel[0].textContent = `${agendaLabel[0].textContent}`
                        } else {
                            agendaLabel[0].textContent = `Next 30 Days ${agendaLabel[0].textContent}`
                        }
                    }
                }, 200);
            });
        }
        if (ele && ele[1]) {
            ele[1].childNodes[0].addEventListener('click', function handleClick() {
                setAgendaClicked(false);
                setDayClicked(false);
            });
        }
        if (ele && ele[1]) {
            ele[1].childNodes[1].addEventListener('click', function handleClick() {
                setAgendaClicked(false);
                setDayClicked(false);
            });
        }
        if (ele && ele[1]) {
            ele[1].childNodes[2].addEventListener('click', function handleClick() {
                setAgendaClicked(false);
                setDayClicked(true);
            });
        }
    }, [props])

    const scheduleInspectionData = props.getScheduleEventsList.map(schedule => {
        const timeZone = "America/Nome"
        return {
            title:
                agendaClicked ? <div onClick={() => { props.handleConfirmClickDeleteModel(schedule.id); props.getSchedule(schedule.schedule_id) }}>{schedule && schedule.account && schedule.account.name} |<span style={{ color: '#850037', fontWeight: 'bold' }}>{makeFirstLetterCapital(schedule.schedule && schedule.schedule.title)}</span> </div> :
                    <div onClick={() => { props.handleConfirmClickDeleteModel(schedule.id); props.getSchedule(schedule.schedule_id) }}>{makeFirstLetterCapital(schedule.schedule && schedule.schedule.title)}</div>,
            start: convertDateTimezoneSchedule(schedule.start_time, account_info ? account_info.timezone : ''),
            end: convertDateTimezoneSchedule(schedule.end_time, account_info ? account_info.timezone : '')
        }
    });

    const getEndTime = (endDate) => {
        return convertDateToDifferentTZ(endDate, 'to');
    }

    const scheduleTicketData = props.getScheduleTicketEventsList.map(schedule => {
        const timeZone = "America/Nome"
        return {
            title:
                agendaClicked ? <div onClick={() => { props.handleOpenScheduleTicketDeleteModal(schedule.id); props.getOneScheduleTicketEventInfo(schedule.schedule_ticket_id) }}>{schedule && schedule.account && schedule.account.name} |<span style={{ color: '#850037', fontWeight: 'bold' }}>{makeFirstLetterCapital(schedule.schedule_ticket && schedule.schedule_ticket.title)}</span> </div> :
                    <div onClick={() => { props.handleOpenScheduleTicketDeleteModal(schedule.id); props.getOneScheduleTicketEventInfo(schedule.schedule_ticket_id) }}>{makeFirstLetterCapital(schedule.schedule_ticket && schedule.schedule_ticket.title)}</div>,
            start: convertDateTimezoneSchedule(schedule.start_time, account_info ? account_info.timezone : ''),
            end: convertDateTimezoneSchedule(getEndTime(schedule.start_time), account_info ? account_info.timezone : '')
        }
    });

    const scheduledTaskData = props.getScheduledTaskEventsList.map(schedule => {
        // debugger
        const timeZone = "America/Nome"
        return {
            title:
                agendaClicked ? <div onClick={() => { props.handleOpenScheduleTicketDeleteModal(schedule.id); props.getOneScheduleTicketEventInfo(schedule.id) }}>{schedule && schedule.account && schedule.account.name} |<span style={{ color: '#0db268', fontWeight: 'bold' }}>{makeFirstLetterCapital(schedule.schedule_ticket && schedule.schedule_ticket.title)}</span> </div> :
                    <div onClick={() => { props.handleOpenScheduleTicketDeleteModal(schedule.id); props.getOneScheduleTicketEventInfo(schedule.id) }}>{makeFirstLetterCapital(schedule && schedule.name)}</div>,
            start: convertDateTimezoneSchedule(schedule.start_date, account_info ? account_info.timezone : ''),
            end: convertDateTimezoneSchedule(getEndTime(schedule.start_date), account_info ? account_info.timezone : '')
        }
    });

    let eventStyleBunker = (event) => {
        let eventsFilteredData = [];
        let tasksFilteredData = [];
        if (props.getScheduleTicketEventsList && props.getScheduleTicketEventsList.length) {
            props.getScheduleTicketEventsList.map((data) => {
                if (event.title.props.children == makeFirstLetterCapital(data.schedule_ticket.title) && new Date(event.start).toDateString() == new Date(data.start_time).toDateString()) {
                    eventsFilteredData.push(1);   // 1 refers nothing, just increasing the length to check values present or not!
                }
            })
        }
        if (props.getScheduledTaskEventsList && props.getScheduledTaskEventsList.length) {
            props.getScheduledTaskEventsList.map((data) => {
                if (event.title.props.children == makeFirstLetterCapital(data.name) && new Date(event.start).toDateString() == new Date(data.start_date).toDateString()) {
                    tasksFilteredData.push(1);   // 1 refers nothing, just increasing the length to check values present or not!
                }
            })
        }
        // console.log(event);
        var style = {
            backgroundColor: eventsFilteredData.length > 0 ? '#fa9c24d1' : tasksFilteredData.length > 0 ? '#0db268' : agendaClicked ? '#FFFFFF' : dayClicked ? '#ececec' : '#850037',
            color: eventsFilteredData.length > 0 ? '#FFFFFF' : tasksFilteredData.length > 0 ? '#FFFFFF' : dayClicked ? '#850037' : '#FFFFFF',
            // borderRadius: '0px',
            // opacity: 0.8,
            // border: '0px',
            // display: 'block'
        };
        return {
            style: style
        };
    };

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content job_filter">
                    <form>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 text-right quotes_btn_wrap schedulesButtonWrap">
                                <div class="dropdown text-right">
                                    {
                                        props.user.role === userRoles.INSPECTOR ? null :
                                            <button type='button' className="btn btn-secondary cst_btn" style={{ marginRight: '10px' }}><i className="fa fa-plus icn_plus"></i> Create New </button>
                                    }
                                    <div class="dropdown-content createSchedulesButtonDrop">
                                        <a href="#">
                                            <button type='button' onClick={() => { props.createNewSchedule() }} className="btn btn-secondary" data-test="new_job_account"><i className="fa fa-plus icn_plus"></i> Inspection Schedule </button>
                                        </a>
                                        <a href="#" style={{ display: 'grid', textDecoration: 'none' }}>
                                            <button type='button' onClick={() => { props.createNewTicketSchedule() }} className="btn btn-secondary" data-test="new_job_account"><i className="fa fa-plus icn_plus"></i> Ticket Schedule </button>
                                        </a>
                                    </div>
                                </div>
                                <div class="dropdown text-right">
                                    <button type='button' className="btn btn-secondary cst_btn" style={{ marginRight: '10px' }}> Schedule History </button>
                                    <div class="dropdown-content scheduleHistoryButtonDrop">
                                        <a href="#">
                                            <button className="btn btn-secondary cst_btn" type='button' onClick={() => { props.scheduleHistory() }}> Inspection History </button>
                                        </a>
                                        <a href="#" style={{ display: 'grid', textDecoration: 'none' }}>
                                            <button className="btn btn-secondary cst_btn" type='button' onClick={() => { props.scheduleTicketHistory() }}> Tickets History </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <section className="account_sec inspection_tab_content appcontent_Inner schedule_calender" id="schedule_calender">
                    <div className="quotes_table_content accounts_table_contnet table-responsive overflow-auto">
                        <div className="table quotes_table user_react_table">
                            <div className="mt-4">
                                <Calendar
                                    localizer={localizer}
                                    events={scheduleInspectionData.concat(scheduleTicketData).concat(scheduledTaskData)}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{ height: 600 }}
                                    tooltipAccessor={() => null}
                                    eventPropGetter={eventStyleBunker}
                                    onView={(dataType) => {
                                        setTimeout(() => {
                                            let storedDate = storage.get('liveDay')
                                            const storedYear = storedDate.slice(0, 4)
                                            if (dataType == 'month') {
                                                const domMonthLabel = document.getElementsByClassName('rbc-toolbar-label');
                                                const monthDatesArray = domMonthLabel[0].textContent.split(' ');
                                                let firstMonthDate = moment().month(monthDatesArray[0]).year(monthDatesArray[1]).startOf('month').format('YYYY-MM-DD');
                                                let lastMonthDate = moment().month(monthDatesArray[0]).year(monthDatesArray[1]).endOf('month').format('YYYY-MM-DD');
                                                props.getScheduleEvents(convertDateToDifferentTZ(firstMonthDate, 'from'), convertDateToDifferentTZ(lastMonthDate, 'to'));
                                                props.getScheduleTicketEvents(convertDateToDifferentTZ(firstMonthDate, 'from'), convertDateToDifferentTZ(lastMonthDate, 'to'));
                                                props.getScheduledTaskEvents(convertDateToDifferentTZ(firstMonthDate, 'from'), convertDateToDifferentTZ(lastMonthDate, 'to'));

                                            } else if (dataType == 'week') {
                                                const domWeekLabel = document.getElementsByClassName('rbc-toolbar-label');
                                                const weekDatesArray = domWeekLabel[0].textContent.split(' – ');
                                                const leftMonthDateArray = weekDatesArray[0].split(' ');
                                                const rightDate = weekDatesArray[1] && weekDatesArray[1].length > 2 ? weekDatesArray[1].split(' ')[1] : weekDatesArray[1]
                                                let firstWeekDate = moment().month(leftMonthDateArray[0]).year(storedYear).date(leftMonthDateArray[1]).format('YYYY-MM-DD');
                                                let lastWeekDate = moment().month(leftMonthDateArray[0]).year(storedYear).date(rightDate).format('YYYY-MM-DD');
                                                props.getScheduleEvents(convertDateToDifferentTZ(firstWeekDate, 'from'), convertDateToDifferentTZ(lastWeekDate, 'to'));
                                                props.getScheduleTicketEvents(convertDateToDifferentTZ(firstWeekDate, 'from'), convertDateToDifferentTZ(lastWeekDate, 'to'));
                                                props.getScheduledTaskEvents(convertDateToDifferentTZ(firstWeekDate, 'from'), convertDateToDifferentTZ(lastWeekDate, 'to'));

                                            } else if (dataType == 'day') {
                                                const domDayLabel = document.getElementsByClassName('rbc-toolbar-label');
                                                const dayDatesArray = domDayLabel[0].textContent.split(' ');
                                                let firstDayDate = moment().month(dayDatesArray[1]).year(storedYear).date(dayDatesArray[2]).format('YYYY-MM-DD');
                                                let secondDayDate = moment().month(dayDatesArray[1]).year(storedYear).date(dayDatesArray[2]).format('YYYY-MM-DD');
                                                props.getScheduleEvents(convertDateToDifferentTZ(firstDayDate, 'from'), convertDateToDifferentTZ(secondDayDate, 'to'));
                                                props.getScheduleTicketEvents(convertDateToDifferentTZ(firstDayDate, 'from'), convertDateToDifferentTZ(secondDayDate, 'to'));
                                                props.getScheduledTaskEvents(convertDateToDifferentTZ(firstDayDate, 'from'), convertDateToDifferentTZ(secondDayDate, 'to'));

                                            } else if (dataType == 'agenda') {
                                                const domAgendaLabel = document.getElementsByClassName('rbc-toolbar-label');
                                                const agendaDatesArray = domAgendaLabel[0].textContent.split(' – ');
                                                let firstAgendaDate = convertDateFormatForSchedule(new Date(agendaDatesArray[0].split(' ').slice(3)));
                                                let lastAgendaDate = convertDateFormatForSchedule(new Date(agendaDatesArray[1]));
                                                props.getScheduleEvents(convertDateToDifferentTZ(firstAgendaDate, 'from'), convertDateToDifferentTZ(lastAgendaDate, 'to'));
                                                props.getScheduleTicketEvents(convertDateToDifferentTZ(firstAgendaDate, 'from'), convertDateToDifferentTZ(lastAgendaDate, 'to'));
                                                props.getScheduledTaskEvents(convertDateToDifferentTZ(firstAgendaDate, 'from'), convertDateToDifferentTZ(lastAgendaDate, 'to'));

                                            }
                                        }, 500);
                                    }}
                                    onNavigate={(date, dataType) => {
                                        if (dataType == 'month') {
                                            setTimeout(() => {
                                                if (window.isMoreClicked == true) {
                                                    window.isMoreClicked = false;
                                                    return false;
                                                }
                                                storage.set('liveDay', date);
                                                let firstMonthDate = convertDateFormatForSchedule(startOfMonth(date));
                                                let lastMonthDate = convertDateFormatForSchedule(endOfMonth(date));
                                                props.getScheduleEvents(convertDateToDifferentTZ(firstMonthDate, 'from'), convertDateToDifferentTZ(lastMonthDate, 'to'));
                                                props.getScheduleTicketEvents(convertDateToDifferentTZ(firstMonthDate, 'from'), convertDateToDifferentTZ(lastMonthDate, 'to'));
                                                props.getScheduledTaskEvents(convertDateToDifferentTZ(firstMonthDate, 'from'), convertDateToDifferentTZ(lastMonthDate, 'to'));
                                            }, 500);

                                        } else if (dataType == 'week') {
                                            setTimeout(() => {
                                                storage.set('liveDay', date);
                                                let firstWeekDate = convertDateFormatForSchedule(startOfWeek(date));
                                                let lastWeekDate = convertDateFormatForSchedule(endOfWeek(date));
                                                props.getScheduleEvents(convertDateToDifferentTZ(firstWeekDate, 'from'), convertDateToDifferentTZ(lastWeekDate, 'to'));
                                                props.getScheduleTicketEvents(convertDateToDifferentTZ(firstWeekDate, 'from'), convertDateToDifferentTZ(lastWeekDate, 'to'));
                                                props.getScheduledTaskEvents(convertDateToDifferentTZ(firstWeekDate, 'from'), convertDateToDifferentTZ(lastWeekDate, 'to'));
                                            }, 500);

                                        } else if (dataType == 'day') {
                                            setTimeout(() => {
                                                storage.set('liveDay', date);
                                                // let yesterday = moment(date).subtract(1, "days").format("YYYY-MM-DD");
                                                let today = moment(date).subtract(0, "days").format("YYYY-MM-DD");
                                                // let tomorrow = moment(date).subtract(-1, "days").format("YYYY-MM-DD");
                                                let firstDayDate = convertDateFormatForSchedule(new Date(today));
                                                let secondDayDate = convertDateFormatForSchedule(new Date(today));
                                                props.getScheduleEvents(convertDateToDifferentTZ(firstDayDate, 'from'), convertDateToDifferentTZ(secondDayDate, 'to'));
                                                props.getScheduleTicketEvents(convertDateToDifferentTZ(firstDayDate, 'from'), convertDateToDifferentTZ(secondDayDate, 'to'));
                                                props.getScheduledTaskEvents(convertDateToDifferentTZ(firstDayDate, 'from'), convertDateToDifferentTZ(secondDayDate, 'to'));
                                            }, 500);

                                        } else if (dataType == 'agenda') {
                                            let firstAgendaDate = convertDateFormatForSchedule(startOfAgenda(date));
                                            let lastAgendaDate = convertDateFormatForSchedule(endOfAgenda(date));
                                            props.getScheduleEvents(convertDateToDifferentTZ(firstAgendaDate, 'from'), convertDateToDifferentTZ(lastAgendaDate, 'to'));
                                            props.getScheduleTicketEvents(convertDateToDifferentTZ(firstAgendaDate, 'from'), convertDateToDifferentTZ(lastAgendaDate, 'to'));
                                            props.getScheduledTaskEvents(convertDateToDifferentTZ(firstAgendaDate, 'from'), convertDateToDifferentTZ(lastAgendaDate, 'to'));

                                        }
                                    }}
                                    onShowMore={(e, f) => {
                                        window.isMoreClicked = true;
                                    }}
                                // view='month'
                                // defaultView="month"

                                />
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    {props.isLoading ?
                        <div className="loader_btn_block" style={{ width: "300px", height: "300px" }}>
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> :
                        props.scheduleId.map((preview) => {
                            return (
                                <div className="preview_schedule">
                                    <article className="custom_article">
                                        <h3 className="h3_title text-center" >Are you sure?</h3>
                                    </article>
                                    <DialogActions>
                                        {
                                            props.user.role === userRoles.SUPER_ADMIN ?
                                                <>
                                                    {(preview.events.map((date) => {
                                                        if (date.id === props.deleteObject) {
                                                            return (
                                                                <button style={{ minWidth: '45%' }} onClick={() => props.deleteEvent(date.id)} className={"btn cst_btn btn_danger"} id="cancel" type="cancel"><i class="fa fa-trash" aria-hidden="true"></i>Delete</button>
                                                            )
                                                        }
                                                    }))}
                                                </> : null
                                        }
                                        <button style={{ minWidth: '45%' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn_danger"} id="cancel" type="cancel">Cancel</button>
                                    </DialogActions>
                                </div>
                            )
                        })
                    }
                </DialogTitle>
            </Dialog>
            <Dialog
                open={props.openScheduleConfirmDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    {props.isLoading ?
                        <div className="loader_btn_block" style={{ width: "300px", height: "300px" }}>
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> :
                        props.scheduleId.map((preview) => {
                            return (
                                <div className="preview_schedule">
                                    <article className="custom_article">
                                        <h3 className="h3_title text-center" >Are you sure?</h3>
                                    </article>
                                    <DialogActions>
                                        <button style={{ minWidth: '45%' }} onClick={() => props.deleteSchedule(preview.id)} className={"btn cst_btn btn_danger"} id="cancel" type="cancel"><i class="fa fa-trash" aria-hidden="true"></i> Delete</button>
                                        <button style={{ minWidth: '45%' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn_danger"} id="cancel" type="cancel">Cancel</button>
                                    </DialogActions>
                                </div>
                            )
                        })
                    }
                </DialogTitle>
            </Dialog>
            <Dialog
                open={props.openConfirmDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    {props.isLoading ?
                        <div className="loader_btn_block" style={{ width: "300px", height: "300px" }}>
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : <>
                            {
                                props.match.params.event_id ?
                                    props.getScheduleEmailEventsList.map((preview) => {
                                        return (
                                            <div className="preview_schedule">
                                                <article className="custom_article">
                                                    <h3 className="h3_title" >{makeFirstLetterCapital(preview.schedule.title)}</h3>
                                                    <div className="custom_title schedule_title">Events:</div>
                                                    <div className="custom_title">{makeFirstLetterCapital(preview.schedule.day)}, {convertDateTimezonePopupEvents(preview.start_time).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')}</div>
                                                </article>
                                                {/*<div className="form-group">
                                            <label className="custom_title">Job</label>
                                            <div className="db_data">{makeFirstLetterCapital(preview.account.name)}</div>
                                        </div>
                                        <div className="form-group">
                                            <label className="custom_title">Inspection Form</label>
                                            <div className="db_data">{makeFirstLetterCapital(preview.inspection_form.name)}</div>
                                        </div>*/}
                                                <DialogActions>
                                                    {/*{
                                            props.user.role === userRoles.SUPER_ADMIN ?
                                            <>
                                            {(preview.events.map((date) => {
                                                if (date.id === props.deleteObject) {
                                                    return (
                                                        <>
                                                            <button style={{ minWidth: '45%' }} onClick={() => props.handleClickDeleteModel(date.id)} className={"btn cst_btn btn_danger"} id="cancel" type="cancel"><i class="fa fa-trash" aria-hidden="true"></i> Delete This Event</button>
                                                        </>
                                                    )
                                                }
                                            }))}
                                            <button style={{ minWidth: '45%' }} onClick={() => props.handleScheduleClickDeleteModel(preview.id)} className={"btn cst_btn btn_danger"} id="cancel" type="cancel"><i class="fa fa-trash" aria-hidden="true"></i> Delete Entire Schedule </button>
                                            </>
                                            : null
                                        }*/}
                                                </DialogActions>
                                                <button style={{ minWidth: '100%' }} onClick={() => props.history.push(routes.ACCOUNTS)} className={"btn cst_btn btn_danger mb-4"} id="cancel" type="cancel">Cancel</button>
                                            </div>
                                        )
                                    }) :
                                    props.scheduleId.map((preview) => {
                                        return (
                                            <div className="preview_schedule">
                                                <article className="custom_article">
                                                    <div>
                                                        <div className='d-inline-block'>
                                                            <h3 className="h3_title" >{makeFirstLetterCapital(preview.title)}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="custom_title schedule_title">Events:</div>
                                                    <div className="custom_title"> {(preview.events.map((date) => {
                                                        if (date.id === props.deleteObject) {
                                                            return (<div>{preview.day}, {convertDateTimezonePopupEvents(date.start_time).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')}</div>)
                                                        }
                                                    }))} </div>
                                                    {/* <div className="custom_title">{preview.frequency} On {preview.day}</div>*/}
                                                </article>
                                                <div className="form-group">
                                                    <label className="custom_title">Job</label>
                                                    <div className="db_data">{makeFirstLetterCapital(preview.account.name)}</div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="custom_title">Inspection Form</label>
                                                    <div className="db_data">{makeFirstLetterCapital(preview.inspection_form.name)}</div>
                                                </div>
                                                <DialogActions>
                                                    {
                                                        props.user.role === userRoles.SUPER_ADMIN ?
                                                            <>
                                                                {(preview.events.map((date) => {
                                                                    let separatedInspectionDate = date.start_time.split(' ');
                                                                    if (new Date() < new Date(separatedInspectionDate[0])) {
                                                                        if (date.id === props.deleteObject) {
                                                                            return (
                                                                                <>
                                                                                    <button style={{ minWidth: '45%' }} onClick={() => props.handleClickDeleteModel(date.id)} className={"btn cst_btn btn_danger"} id="cancel" type="cancel"><i class="fa fa-trash" aria-hidden="true"></i> Delete This Event</button>
                                                                                    <button style={{ minWidth: '45%' }} onClick={() => props.handleScheduleClickDeleteModel(preview.id)} className={"btn cst_btn btn_danger"} id="cancel" type="cancel"><i class="fa fa-trash" aria-hidden="true"></i> Delete Entire Schedule </button>
                                                                                </>
                                                                            )
                                                                        }
                                                                    }
                                                                }))}
                                                            </>
                                                            : null
                                                    }
                                                </DialogActions>
                                                <button style={{ minWidth: '100%' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn_danger mb-4"} id="cancel" type="cancel">Close</button>
                                            </div>
                                        )
                                    })
                            }
                        </>

                    }
                </DialogTitle>
            </Dialog>

            <Dialog
                open={props.deleteScheduleTicketEventAction}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    {props.isLoading ?
                        <div className="loader_btn_block" style={{ width: "300px", height: "300px" }}>
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : (
                            <div className="preview_schedule">
                                <article className="custom_article"> <h3 className="h3_title text-center" >Are you sure?</h3> </article>
                                <DialogActions>
                                    {props.scheduleTicketOneEvent && props.scheduleTicketOneEvent.ticket_events.map((date) => {
                                        if (date.id === props.deletingScheduleTicketId) {
                                            return (
                                                <button style={{ minWidth: '45%' }} onClick={() => props.deleteScheduleTicketEvent(date.id)} className={"btn cst_btn btn_danger"} id="cancel" type="cancel"><i class="fa fa-trash" aria-hidden="true"></i> Delete </button>
                                            )
                                        }
                                    })}
                                    <button style={{ minWidth: '45%' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn_danger"} id="cancel" type="cancel">Cancel</button>
                                </DialogActions>
                            </div>
                        )
                    }
                </DialogTitle>
            </Dialog>
            <Dialog
                open={props.deleteEntireScheduleTicketEventsAction}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    {props.isLoading ?
                        <div className="loader_btn_block" style={{ width: "300px", height: "300px" }}>
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : (
                            <div className="preview_schedule">
                                <article className="custom_article"> <h3 className="h3_title text-center" >Are you sure?</h3> </article>
                                <DialogActions>
                                    <button style={{ minWidth: '45%' }} onClick={() => props.deleteEntireScheduleTicket(props.scheduleTicketOneEvent && props.scheduleTicketOneEvent.id)} className={"btn cst_btn btn_danger"} id="cancel" type="cancel"><i class="fa fa-trash" aria-hidden="true"></i> Delete </button>
                                    <button style={{ minWidth: '45%' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn_danger"} id="cancel" type="cancel">Cancel</button>
                                </DialogActions>
                            </div>
                        )
                    }
                </DialogTitle>
            </Dialog>

            <Dialog
                open={props.openScheduleTicketDeleteModal}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    {props.isLoading ?
                        <div className="loader_btn_block" style={{ width: "300px", height: "300px" }}>
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : <>
                            <div className="preview_schedule">
                                <article className="custom_article">
                                    <div>
                                        <div className='d-inline-block mr-4'> <h3 className="h3_title" > {makeFirstLetterCapital(props.scheduleTicketOneEvent && props.scheduleTicketOneEvent.title)} </h3> </div>
                                        <div className='d-inline-block float-right mt-3'>
                                            <span style={{ color: 'black' }} data-tip="View Ticket" onClick={() => { props.previewSingleTicketClicked(props.scheduleTicketOneEvent); props.handleDeleteClose() }} className="mr_20 account_edit_icn" data-test="edit_eye_icon"><i className="fas fa-eye cst_icon mr-2" aria-hidden="true"></i></span>
                                            <ReactTooltip place="top" type="dark" effect="solid" />
                                        </div>
                                    </div>
                                    <div className="custom_title schedule_title">Scheduled Date:</div>
                                    <div className="custom_title">
                                        {props.scheduleTicketOneEvent && props.scheduleTicketOneEvent.ticket_events.map((date) => {
                                            if (date.id === props.deletingScheduleTicketId) {
                                                return (<div>{props.scheduleTicketOneEvent && props.scheduleTicketOneEvent.day == null ? moment(new Date(date.start_time)).format('dddd') : makeFirstLetterCapital(props.scheduleTicketOneEvent.day)}, {convertDateTimezonePopupEvents(date.start_time).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')}</div>)
                                            }
                                        })}
                                    </div>
                                </article>
                                <div className="form-group">
                                    <label className="custom_title">Job</label>
                                    <div className="db_data">{makeFirstLetterCapital(props.scheduleTicketOneEvent && props.scheduleTicketOneEvent.account.name)}</div>
                                </div>
                                <DialogActions>
                                    {props.user.role === userRoles.SUPER_ADMIN ?
                                        <>
                                            {props.scheduleTicketOneEvent && props.scheduleTicketOneEvent.ticket_events.map((date) => {
                                                // debugger
                                                let separatedTicketDate = date.start_time.split(' ');
                                                if (new Date() < new Date(separatedTicketDate[0])) {
                                                    if (date.id === props.deletingScheduleTicketId) {
                                                        return (
                                                            <>
                                                                <button style={{ minWidth: '45%' }} onClick={() => props.handleDeleteScheduleTicketEvent(date.id)} className={"btn cst_btn btn_danger"} id="cancel" type="cancel"><i class="fa fa-trash" aria-hidden="true"></i> Delete This Event</button>
                                                                <button style={{ minWidth: '45%' }} onClick={() => props.handleDeleteEntireScheduleTicketEvents(props.scheduleTicketOneEvent && props.scheduleTicketOneEvent.id)} className={"btn cst_btn btn_danger"} id="cancel" type="cancel"><i class="fa fa-trash" aria-hidden="true"></i> Delete Entire Schedule </button>
                                                            </>
                                                        )
                                                    }
                                                }
                                            })}
                                        </>
                                        : null}
                                </DialogActions>
                                <button style={{ minWidth: '100%' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn_danger mb-4"} id="cancel" type="cancel">Close</button>
                            </div></>
                    }
                </DialogTitle>
            </Dialog>
        </>
    )
}

export default ScheduleComponents;