import React, { useEffect, useState } from 'react';
import { useStopwatch } from 'react-timer-hook';
import storage from '../../../../utility/storage';
import { tzDateConverterTool_V4, tzTimeConverterTool_V3, tzUTCDateTimeConverterTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
import { calculateTotalSeconds, convertSecondsToTime, getNarrowOfClockInTimegap, getNarrowOfMealbreakTimegap, getOrdinalNumber } from '../../../../utility/utility';
import MealBreakConcludingModal from './MealBreakConcludingModal';
import { ClapSpinner } from 'react-spinners-kit';
import { useHistory } from 'react-router-dom';
import { Input } from '../../UI/InputElement/InputElement';
import { List } from 'antd';
var momentTZ = require('moment-timezone');
var refreshPage = () => { window.location.reload(false); }

let ClockInClockOutScreenComponent = (props) => {

    const history = useHistory();
    let formatTimerUnits = unit => unit.toString().padStart(2, '0');
    let clockInOffset = new Date();
    let currentUser = storage.get('user');

    let isClockInEngaged = storage.get('isClockInEngaged') ? storage.get('isClockInEngaged') : false; // For handling the clock in start clicked or not
    let isMealBreakInitiated = storage.get('isMealBreakInitiated') ? storage.get('isMealBreakInitiated') : false; // For handling the Meal Break start clicked or not
    let envReloadEngaged = storage.get('envReloadOccurs') ? storage.get('envReloadOccurs') : false; // For handling the page reload
    let currentFeedGapId = storage.get('currentFeedGapId') ? storage.get('currentFeedGapId') : null; // For handling the meal break id

    let [isClockInStarted, setIsClockInStarted] = useState(isClockInEngaged);
    let [envReloadOccurs, setEnvReloadOccurs] = useState(envReloadEngaged);

    let [feedgaptime, setFeedGapTime] = useState({ feedHour: 0, feedMinute: 0, feedSecond: 0 });
    let [isFeedGapSprinted, setIsFeedGapSprinted] = useState(isMealBreakInitiated);
    let [concludeFeedGap, setConcludeFeedGap] = useState(null);
    const [accountId, setAccountId] = useState("");

    const { isClockInShiftAvailable, clockedInShiftData, isShiftMealBreakTriggered, triggerClockInTimer } = props;

    /* WHENEVER RELOAD OCCURS, LIFECYCLE SAVES THE CURRENT TIMER TO LOCALSTORAGE FOR CLOCK-IN */
    useEffect(() => {

        /* Clock In Timer Functionality */
        if (isClockInStarted == true && isFeedGapSprinted == false) {
            let navigationEntries = window.performance.getEntriesByType('navigation');
            if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {

                const storedTime = localStorage.getItem('clockInTimer');
                if (storedTime && isClockInStarted) {
                    const parsedTime = JSON.parse(storedTime);
                    if (parsedTime) {
                        const { seconds, minutes, hours } = parsedTime;
                        localStorage.setItem('chronometer', JSON.stringify({ seconds, minutes, hours }));
                    }
                }
                storage.set('envReloadOccurs', true);
                setEnvReloadOccurs(true);
            }
        }

    }, []);


    /* RETRIEVE THE CLOCK-IN TIMER FROM LOCALSTORAGE & INTEGRATE IT INTO LIVE STREAM */
    if (isClockInStarted == true) {
        let storedTime = null;

        if (isClockInStarted == true && isFeedGapSprinted == true) {
            storedTime = localStorage.getItem('chronometer');
        } else {
            storedTime = localStorage.getItem('clockInTimer');
        }

        if (storedTime && isClockInStarted) {
            const parsedTime = JSON.parse(storedTime);
            if (parsedTime) {
                const { seconds, minutes, hours } = parsedTime;
                clockInOffset.setSeconds(clockInOffset.getSeconds() + calculateTotalSeconds(hours, minutes, seconds));
            }
        }
    }


    /* CLOCK-IN TIMER fUNCTIONALITY COMPONENT TEMPLATE */
    const {
        seconds,
        minutes,
        hours,
        start,
        pause,
        reset,
    } = useStopwatch({ offsetTimestamp: clockInOffset });


    /* MEAL BREAK TIMER fUNCTIONALITY COMPONENT TEMPLATE */
    useEffect(() => {
        let interval;

        /* Whenever Reload occurs, this event or functionality saves the current Timer to local storage for Meal Break */
        if (storage.get('mealBreakTimer')) {
            const storedTime = JSON.parse(localStorage.getItem('mealBreakTimer')); // Retrieve stored time when the refresh happens
            if (storedTime) {
                setFeedGapTime(storedTime);
            }
        }

        /* Meal Break Timer Functionality */
        if (isFeedGapSprinted) {
            interval = setInterval(() => {
                setFeedGapTime(prevTime => {

                    const totalSeconds = prevTime.feedHour * 3600 + prevTime.feedMinute * 60 + prevTime.feedSecond + 1;
                    const feedHour = Math.floor(totalSeconds / 3600);
                    const feedMinute = Math.floor((totalSeconds % 3600) / 60);
                    const feedSecond = totalSeconds % 60;

                    localStorage.setItem('mealBreakTimer', JSON.stringify({ feedSecond, feedMinute, feedHour }));

                    return {
                        feedHour,
                        feedMinute,
                        feedSecond,
                    };
                });
            }, 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isFeedGapSprinted]);


    /* TRIGGER CLOCK-IN TIMER FOR LIVE STREAM TEMPLATE */
    useEffect(() => {
        if (isClockInStarted == true && isFeedGapSprinted == false) {
            start();
        }
    }, [start, isClockInStarted, envReloadOccurs]);


    /* STORE LIVESTREAM CLOCKINTIMER STATE IN LOCALSTORAGE UPON CHANGE */
    useEffect(() => {

        if (isClockInStarted == true && isFeedGapSprinted == false) {
            localStorage.setItem('clockInTimer', JSON.stringify({ seconds, minutes, hours }));
        }

    }, [seconds, minutes, hours, isClockInStarted]);

    /* IDENTIFY AND UPDATING THE TIME DIFFERENCE BETWEEN CLOCKEDIN-TIME AND CURRENT-TIME IN STOPWATCH */
    useEffect(() => {

        const handleTimeTravel = () => {
            if (props.clockInCheckedInTime) {
                pause();

                let calculatedTotSeconds = null;
                if (props.clockInCheckedInTime) {
                    if (props?.clockedInShiftData?.breaks?.length > 0) {
                        let totalMealBreakDuration = 0;
                        props.clockedInShiftData.breaks.forEach((item) => {
                            if (item.start && item.end) {
                                const startTime = new Date(item.start);
                                const endTime = new Date(item.end);
                                const duration = endTime - startTime;
                                totalMealBreakDuration += duration;
                            }
                        });
                        const { seconds, minutes, hours } = getNarrowOfClockInTimegap(props.clockInCheckedInTime, totalMealBreakDuration);
                        calculatedTotSeconds = calculateTotalSeconds(hours, minutes, seconds);
                    } else {
                        const { seconds, minutes, hours } = getNarrowOfClockInTimegap(props.clockInCheckedInTime);
                        calculatedTotSeconds = calculateTotalSeconds(hours, minutes, seconds);
                    }
                }
                const { hours, minutes, seconds } = convertSecondsToTime(calculatedTotSeconds);
                localStorage.setItem('clockInTimer', JSON.stringify({ seconds, minutes, hours }));
                localStorage.setItem('chronometer', JSON.stringify({ seconds, minutes, hours }));
                
                refreshPage();
            }
        };

        if (!(storage.get('isTimeTravel'))) {
            storage.set('isTimeTravel', true);
            if (isClockInStarted == true) {
                handleTimeTravel();
            }
        }

        return history.listen(location => {
            if (location.pathname !== '/clkIn-clkOut') {
                // Routing is happening from home screen to another screen
                storage.remove('isTimeTravel');
            }
        });

    }, [history]);

    /* IDENTIFY AND UPDATING THE MEAL BREAK BEGINED & END FLOW */
    useEffect(() => {

        if (props?.clockedInShiftData?.breaks?.length > 0) {

            const fetchedMealBreakStartedObject = props.clockedInShiftData.breaks.find((feedItem) => feedItem.start !== null && feedItem.end == null);

            if (fetchedMealBreakStartedObject) {

                let storedTime = localStorage.getItem('clockInTimer');
                const parsedTime = JSON.parse(storedTime);
                if (parsedTime) {
                    const { seconds, minutes, hours } = parsedTime;
                    localStorage.setItem('chronometer', JSON.stringify({ seconds, minutes, hours }));
                }

                pause(); // For pausing the ClockIn Timer
                storage.set('isMealBreakInitiated', true);
                storage.set('currentFeedGapId', fetchedMealBreakStartedObject.id);
                const { feedHour, feedMinute, feedSecond } = getNarrowOfMealbreakTimegap(fetchedMealBreakStartedObject.start);
                localStorage.setItem('mealBreakTimer', JSON.stringify({ feedSecond, feedMinute, feedHour }));
                setIsFeedGapSprinted(true);

            }
        }

    }, [props.clockedInShiftData]);

    useEffect(() =>{
        const initialClockIn = storage.get('initialClockIn')
        if (clockedInShiftData?.check_int_at && initialClockIn) {
            storage.set('initialClockIn', false)
            storage.set('isClockInEngaged', true);
            localStorage.setItem('clockInTimer', JSON.stringify({ seconds, minutes, hours }));
            localStorage.setItem('chronometer', JSON.stringify({ seconds, minutes, hours }));
            start();
            setIsClockInStarted(true);
        }
    },[triggerClockInTimer])

    const handleClockIn = () => {
         
            const seperateId = accountId.split(",")
            const values = {
                shift_detail_id : seperateId[1],
                account_id : seperateId[0]
            }
             props.handleShiftClockCheckIn(values, setAccountId);
    };

    const handleFeedGapStart = (intervalItem) => {
        if (isShiftMealBreakTriggered) {

            let storedTime = localStorage.getItem('clockInTimer');
            const parsedTime = JSON.parse(storedTime);
            if (parsedTime) {
                const { seconds, minutes, hours } = parsedTime;
                localStorage.setItem('chronometer', JSON.stringify({ seconds, minutes, hours }));
            }

            pause(); // For pausing the ClockIn Timer
            storage.set('isMealBreakInitiated', true);
            const { feedHour, feedMinute, feedSecond } = feedgaptime
            localStorage.setItem('mealBreakTimer', JSON.stringify({ feedSecond, feedMinute, feedHour }));
            setIsFeedGapSprinted(true);

            refreshPage();

        } else {
            props.handleShiftMealBreakCheckIn(intervalItem, intervalItem.id);
        }
    };


    const handleClockOut = () => {

        let setData = { check_out_at: null, addresses_attributes: {}, };
        let fetchedCurrDateTime = momentTZ(new Date()).format('MM-DD-YYYYTHH:mm:ss').split('T');
        let checkOutTime = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setData.addresses_attributes.latitude = latitude;
                    setData.addresses_attributes.longitude = longitude;
                    localStorage.setItem('clkOutTime', JSON.stringify(setData));
                },
                (error) => {
                    console.error('Error getting location:', error.message);
                }
            );
        }

        storage.set('inHabitancyRecord', 'alpha');
        storage.remove('isTimeTravel');
        // storage.remove('isClockInEngaged');
        // storage.remove('clockInTimer');
        // storage.remove('chronometer');
        // storage.remove('envReloadOccurs');
        props.getMissedMealPenaltyBreaks(clockedInShiftData.id, checkOutTime, clockedInShiftData);

    }

    const handleFeedGapEnd = (intervalItem) => {
        if (isShiftMealBreakTriggered) {

            // let calculatedTotSeconds = null;
            // let storedTimeOfClockedIn = localStorage.getItem('chronometer');
            // let storedTimeOfMealBreak = localStorage.getItem('mealBreakTimer');
            // const parsedClockedInTime = JSON.parse(storedTimeOfClockedIn);
            // const parsedMealBreakTime = JSON.parse(storedTimeOfMealBreak);
            // if (parsedClockedInTime && parsedMealBreakTime) {
            //     const { seconds, minutes, hours } = parsedClockedInTime;
            //     const { feedSecond, feedMinute, feedHour } = parsedMealBreakTime;
            //     calculatedTotSeconds = calculateTotalSeconds(hours, minutes, seconds) + calculateTotalSeconds(feedHour, feedMinute, feedSecond);
            // }
            // const { hours, minutes, seconds } = convertSecondsToTime(calculatedTotSeconds);
            // localStorage.setItem('clockInTimer', JSON.stringify({ seconds, minutes, hours }));
            // localStorage.setItem('chronometer', JSON.stringify({ seconds, minutes, hours }));

            setFeedGapTime({
                feedHour: 0,
                feedMinute: 0,
                feedSecond: 0,
            });
            storage.remove('isMealBreakInitiated');
            storage.remove('mealBreakTimer');
            storage.remove('currentFeedGapId');
            setIsFeedGapSprinted(false);

            start();

            setTimeout(() => {
                refreshPage();
            }, 300);

        } else {
            props.handleShiftMealBreakCheckIn(intervalItem, intervalItem.id);
        }
    };

    /* Trigger the Meal Break Conclude Modal */
    const handleFeedGapEndModalTrigger = (intervalItem) => {
        setConcludeFeedGap(intervalItem);
        props.handleFrequentTimeTracker_openModal();
    }

    const handleJobChange = (e) => {
       setAccountId(e.target.value)
    }
    const checkPendingJobs = (jobs) => {
        const filterJobs =  jobs?.shiftDetailJobs?.filter((item) => item.status === "pending")
        return filterJobs
     }

    const handleNextJob = () => {
        const seperateId = accountId.split(",")
            const values = {
                shift_detail_id : seperateId[1],
                account_id : seperateId[0]
            }
             props.handleShiftNextJob(values, setAccountId);
    }

    const findCurrentJob = (jobs) => {
        const currentjob = jobs?.shiftDetailJobs?.find((item) => item.status === "clockedIn")
        if(currentjob){
            return currentjob?.account?.name
        }else {
            return '-'
        }
    }

    const findCurrentJobId = (jobs) => {
        const currentjob = jobs?.shiftDetailJobs?.find((item) => item.status === "clockedIn")
        if(currentjob){
            return currentjob?.account?.id
        }
    }


    if (props.isMealPenaltyLoader) {
        return (
            <div className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluids">
                        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <>

                <div className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                    <div className="inspection_content_sec appcontent_Inner">
                        <div className="container-fluids">
                            <div className="quote_form ticket_form" style={{ maxWidth: '1000px' }}>

                                <div className="row">

                                    {!(isClockInStarted) && !(isFeedGapSprinted) && !clockedInShiftData?.check_int_at ? (
                                        <>
                                            <div className="col-sm-12 text-center"> <h4 className="" > Clock In/Out </h4></div>
                                            <div className="col-md-12 text-center mt-4">
                                                <span style={{cursor : "default"}}  type='button'className={"btn cst_btn btn_danger FLH_button w-50 clkIn_startTimer"} >{"Clock In"}</span>
                                            </div>
                                            <div className="col-md-4 text-center mt-5" style={{marginLeft : '345px'}} >
                                            <select name="status" type="select"  placeholder="Select Status" className="custom-select input-modifier add_user_select" onChange={(e) => handleJobChange(e)} >
                                             <option  value="" >Select Job</option>
                                               {clockedInShiftData?.shiftDetailJobs?.filter((item) => item.status === "pending").map((item, i) => (
                                                <option key={i} value={`${item.account_id},${item.shift_detail_id}`} >{item.account?.name}</option>
                                               ))} 
                                            </select>
                                            </div>
                                            <div className="col-md-12 text-center mt-4">
                                            <button type='button' disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait " : "btn cst_btn btn_danger "} onClick={handleClockIn}>{props.isLoading ? "" : "Submit"}</button>
                                            </div>
                                        </>
                                    ) : null}


                                    {/* Normal Clock In ClockOut Timer UI */}
                                    {clockedInShiftData?.check_int_at && clockedInShiftData?.status === "clockedIn" && !(isFeedGapSprinted)  ? (
                                        <>
                                            <div className="col-md-12 text-center mt-5">

                                                <h3 className="card-title text-color-iridium swapUserName mt-0 mb-0" style={{ fontSize: '40px' }}> Hi {currentUser && currentUser.first_name + ' ' + currentUser.last_name} ! </h3>
                                                {clockedInShiftData && (
                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted mt-3">{`You are Clocked into the system at ${tzTimeConverterTool_V3(clockedInShiftData.check_int_at)} on ${tzDateConverterTool_V4(clockedInShiftData.check_int_at)}`}</h5>
                                                )}

                                                {/* Clock In Timer */}
                                                <div className='d-flex justify-content-center mt-5'>
                                                    <div className='clkIn_timerset1'>
                                                        <div style={{ fontWeight: 'bolder' }}>
                                                            <span>{formatTimerUnits(hours)}</span>:<span>{formatTimerUnits(minutes)}</span>:<span>{formatTimerUnits(seconds)}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Current Shift and Tickets Button */}
                                                <div className="row mt-5">
                                                    <div className='width_100' style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <button data-test="submit_button" type="button" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger min_width_50"} onClick={() => { props.handleMyShiftClick(); }} > {props.isLoading ? "" : "Current Shifts"} </button>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <button data-test="submit_button" type="button" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger min_width_50"} onClick={() => { props.handleMyTicketsClick(findCurrentJobId(clockedInShiftData)); }} > {props.isLoading ? "" : "Current Tickets"} </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Meal Break Starting Icon and Buttons */}
                                                <div className='mt-5' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                    {clockedInShiftData && clockedInShiftData.breaks && clockedInShiftData.breaks.map((intervalItem, index) => (
                                                        intervalItem.start == null ? (
                                                            <div key={index} className="">
                                                                <button id='mealBreakTrigger' key={intervalItem.id} className='clkIn_mealbreak_triggerButton' onClick={() => handleFeedGapStart(intervalItem)}>
                                                                    <div style={{ backgroundColor: '#c9c9c9', borderRadius: '50%', width: '60px', height: '60px' }}>
                                                                        <div className='d-flex justify-content-center p-3'>
                                                                            <img src="/images/icons/icn_mealbreak_black_32.png" className="app-menu__icon" />
                                                                        </div>
                                                                    </div>
                                                                    <p className='clkIn_mealbreak_counterText mt-2'> {clockedInShiftData.breaks.length > 1 ? getOrdinalNumber(index + 1) : null} meal break </p>
                                                                    <p className='clkIn_mealbreak_timerText'> {`${tzTimeConverterTool_V3(intervalItem.expected_start)} − ${tzTimeConverterTool_V3(intervalItem.expected_end)}`} </p>
                                                                </button>
                                                            </div>
                                                        ) : null
                                                    ))}
                                                </div>

                                            </div>
                                        </>
                                    ) : null}


                                    {/* Meal Break Timer UI */}
                                    {isClockInStarted && isFeedGapSprinted && (
                                        <>
                                            <div className="col-md-12 text-center mt-2">

                                                <div className="col-sm-12 text-center mb-5"> <h3 className="btn cst_btn warning_button w-25" style={{ borderRadius: '2rem' }}> Meal Break </h3></div>

                                                {clockedInShiftData && clockedInShiftData.breaks && clockedInShiftData.breaks.map((intervalItem, index) => (
                                                    intervalItem.id == currentFeedGapId ? (
                                                        <div key={index}>
                                                            <h6 className="mt-0 font-weight-bold mb-2 text-muted"> Meal Break Time </h6>
                                                            <h5 className="ml-lg-0 font-weight-bold order-1 order-lg-2 text-dark mt-2" style={{ fontSize: '15px' }}>
                                                                {`${tzTimeConverterTool_V3(intervalItem.expected_start)} − ${tzTimeConverterTool_V3(intervalItem.expected_end)}`}
                                                            </h5>
                                                        </div>
                                                    ) : null
                                                ))}

                                                {/* Clock In Timer with Meal Break Timer */}
                                                <div className='d-flex justify-content-center mt-5'>
                                                    <div className='clkIn_timerset1'>
                                                        <div style={{ color: '#ffffffad', fontWeight: 'bold' }}>
                                                            <span>{formatTimerUnits(hours)}</span>:<span>{formatTimerUnits(minutes)}</span>:<span>{formatTimerUnits(seconds)}</span>
                                                        </div>
                                                        <div style={{ fontSize: '50px', fontWeight: 'bold' }}>
                                                            <span>{formatTimerUnits(feedgaptime.feedHour)}:{formatTimerUnits(feedgaptime.feedMinute)}:{formatTimerUnits(feedgaptime.feedSecond)}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Meal Break Ending Icon and Button */}
                                                <div className='mt-5' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                    {clockedInShiftData && clockedInShiftData.breaks && clockedInShiftData.breaks.map((intervalItem, index) => (
                                                        intervalItem.end == null && intervalItem.id == currentFeedGapId ? (

                                                            <div key={index} className="">
                                                                <button onClick={() => { handleFeedGapEndModalTrigger(intervalItem); }} data-test="meal-break" type="button" className='clkIn_mealbreak_triggerButton' data-toggle="modal" data-target="#concludeMealBreak" data-backdrop="static" data-keyboard="false">
                                                                    <div style={{ backgroundColor: '#222222eb', borderRadius: '50%', width: '60px', height: '60px' }}>
                                                                        <div className='d-flex justify-content-center p-3'>
                                                                            <img src="/images/icons/icn_mealbreak_white_32.png" className="app-menu__icon" />
                                                                        </div>
                                                                    </div>
                                                                    <p className='clkIn_mealbreak_timerText mt-2'> End meal break </p>
                                                                </button>
                                                            </div>

                                                        ) : null
                                                    ))}
                                                </div>

                                            </div>
                                        </>
                                    )}

                                    {/* ClockOut Button UI and Move on to next job */}
                                    {!isFeedGapSprinted && clockedInShiftData?.check_int_at ? (
                                        
                                        <div className='col-md-12 text-center mt-5'>
                                      <h5 className=' fw-bold'>You are currently clocked in to {findCurrentJob(clockedInShiftData)}</h5>
                                      <br />  
                                      <List
                                                itemLayout="horizontal"
                                                dataSource={clockedInShiftData?.shiftDetailJobs}
                                                bordered={true}
                                                
                                                renderItem={(item, index) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                    title={item.account.name}
                                                    description={`${item.start_at ? tzTimeConverterTool_V3(item.start_at) : '_'} − ${item.end_at ? tzTimeConverterTool_V3(item.end_at) : '_'}`}
                                                    />
                                                </List.Item>
                                                )}
                                            />
                                    </div>
                                    ) : null
                                    }
                                    {
                                        isFeedGapSprinted ? null : (
                                            <>
                                            {clockedInShiftData?.check_int_at ? (
                                               <>
                                               {clockedInShiftData?.shiftDetailJobs?.length > 1 && (
                                                <>
                                                <div className="col-md-12 text-center mt-5">
                                                     <button style={{cursor:'default'}}  type='button'className={"btn cst_btn btn_danger FLH_button w-40 moveOnToNextJob"} >{"Move on to next Job"}</button>
                                                 </div>
                                                 <div className="col-md-4 text-center mt-4" style={{marginLeft : '345px'}} >
                                                 <select name="status" type="select" value={accountId} isClockInStarted placeholder="Select Status" className="custom-select input-modifier add_user_select" onChange={(e) => handleJobChange(e)} >
                                                  <option  value="" >Select Job</option>
                                                    {clockedInShiftData?.shiftDetailJobs?.map((item, i) => (
                                                     <option key={i} value={`${item.account_id},${item.shift_detail_id}`} disabled={item.status !=="pending"} >{item.account?.name}</option>
                                                    ))} 
                                                 </select>
                                                 </div>
                                                 <div className="col-md-12 text-center mt-4">
                                                 <button  type='button' disabled={props.isNextJobLoading || !checkPendingJobs(clockedInShiftData).length || !accountId} className={props.isNextJobLoading ? "btn cst_btn btn_danger btn-wait " : "btn cst_btn btn_danger "} onClick={handleNextJob}>{props.isNextJobLoading ? "" : "Submit"}</button>
                                                 </div>
                                                </>
                                               )}
                                                 <div className="col-md-12 d-flex justify-content-center mt-5">
                                                     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                         <button style={{ borderStyle: 'none', borderColor: 'white', backgroundColor: 'white', borderRadius: '30px', cursor: 'pointer' }} onClick={() => { handleClockOut(); }}>
                                                             <div style={{ backgroundColor: '#850037', borderRadius: '50%', width: '60px', height: '60px' }}>
                                                                 <div className='d-flex justify-content-center p-3'>
                                                                     <img src="/images/icons/icn_stop_white_24.png" className="app-menu__icon" />
                                                                 </div>
                                                             </div>
                                                         </button>
                                                         <h5 className="ml-lg-0 order-1 order-lg-2 text-muted mt-3" style={{ fontSize: '15px' }}> Proceed to Clock Out </h5>
                                                     </div>
                                                 </div>
                                             
                                               </>
                                            ) : null}
                                            </>
                                      
                                        )
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {props.isTimeTrackerModalOpen ? (
                    <MealBreakConcludingModal
                        {...props}
                        mealBreakItem={concludeFeedGap}
                        handleFeedGapEnd={handleFeedGapEnd}
                    />
                ) : null}

            </>
        );
    }

};

export default ClockInClockOutScreenComponent;