import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import { CustomNoDataComponent, convertInventoryDateTimezone, makeFirstLetterCapital } from '../../../../../utility/utility';
import { ClapSpinner } from 'react-spinners-kit';
import '../../../../../../node_modules/react-table-v6/react-table.css';
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../../redux/actions/index';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';

const AssetsPreviewTable = (props) => {
  const [pageSize, setPageSize] = useState(10);
  const [openModel, setOpen] = useState(false);

  const dispatch = useDispatch();
  const SearchValue = useSelector(state => state.form.searchAssetsListForm && state.form.searchAssetsListForm.values ? state.form.searchAssetsListForm.values : {});
  const DateGenerate = useSelector(state => state.InventoryReducer.dataGenerated);
  const values = useSelector(state => state.InventoryReducer.assets_searchValues);
  const SearchOrResetAssetsClicked = useSelector(state => state.InventoryReducer.SearchOrResetAssetsClicked);
  const No_of_pages = useSelector(state => state.InventoryReducer.assetSingleLog_total_pages);
  const No_of_rows = useSelector(state => state.InventoryReducer.assetSingleLog_no_of_rows);
  const assestsCurPage = useSelector(state => state.InventoryReducer.assetSingleLogCurPage);


  const data = props.assets && props.assets.map((asset) => {
    return {
      asset_name: asset && asset.name ? `${makeFirstLetterCapital(asset.name)}` : '-',
      job_name: asset && asset.account && asset.account.name ? asset.account.name : '-',
      check_out_location: asset && asset.status_log ? asset.status_log.filter(item => item.status === 'checkedOut').map(value => {
        if (value) {
          return value.location && value.location.name ? makeFirstLetterCapital(value.location.name) : '-';
        } else {
          return '-'
        }
      }) : '-',
      check_in_location: asset && asset.status_log ? asset.status_log.filter(item => item.status === 'checkedIn').map(value => {
        if (value) {
          return value.location && value.location.name ? makeFirstLetterCapital(value.location.name) : '-';
        } else {
          return '-'
        }
      }) : '-',
      check_out_date: asset && asset.status_log ? asset.status_log.filter(item => item.status === 'checkedOut').map(value => {
        if (value) {
          return convertInventoryDateTimezone(value.created_at, value.user.account_timezone)
        } else {
          return '-'
        }
      }) : '-',
      check_in_date: asset && asset.status_log ? asset.status_log.filter(item => item.status === 'checkedIn').map(value => {
        if (value) {
          return convertInventoryDateTimezone(value.created_at, value.user.account_timezone)
        } else {
          return '-'
        }
      }) : '-',
      status: asset && asset.status === 'checkedOut' ? 'Checked Out' : asset.status === 'checkedIn' ? 'Checked In' : '-',
      check_out_by: asset && asset.status_log ? asset.status_log.filter(value => value.status === 'checkedOut').map(item => {
        if (item) {
          return `${makeFirstLetterCapital(item.user.first_name)} ${makeFirstLetterCapital(item.user.last_name)}`
        } else {
          return '-'
        }
      }) : '-',
      check_in_by: asset && asset.status_log ? asset.status_log.filter(value => value.status === 'checkedIn').map(item => {
        if (item) {
          return `${makeFirstLetterCapital(item.user.first_name)} ${makeFirstLetterCapital(item.user.last_name)}`
        } else {
          return '-'
        }
      }) : '-',
    }
  })


  const columns = [
    {
      Header: <><span>Asset Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'asset_name', // String-based value accessors!,
      show: props.assets.length !== 0,
    },
    {
      Header: <><span>Job Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'job_name', // String-based value accessors!,
      show: props.assets.length !== 0,
    },
    {
      Header: <><span>Checked Out By</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'check_out_by', // String-based value accessors!,
      show: props.assets.length !== 0,
    },
    {
      Header: <><span>Checked Out Date & Time</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'check_out_date', // String-based value accessors!,
      show: props.assets.length !== 0,
    },
    {
      Header: <><span>Checked Out Location</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'check_out_location', // String-based value accessors!,
      show: props.assets.length !== 0,
    },
    {
      Header: <><span>Status</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'status', // String-based value accessors!,
      show: props.assets.length !== 0,
    },
    {
      Header: <><span>Checked In By</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'check_in_by', // String-based value accessors!,
      show: props.assets.length !== 0,
    },
    {
      Header: <><span>Checked In Date & Time</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'check_in_date', // String-based value accessors!,
      show: props.assets.length !== 0,
    },
    {
      Header: <><span>Checked In Location</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'check_in_location', // String-based value accessors!,
      show: props.assets.length !== 0,
    },

  ]

  const [tablePage, setTablePage] = useState(0)
  const tablePageNew = React.useRef()
  if (assestsCurPage !== tablePageNew.current) {
    tablePageNew.current = assestsCurPage;
  }
  const setTablePageFn = (page) => {
    setTablePage(page)
    props.assetsPageClicked(page)
    tablePageNew.current = page;
  }

  const fetchData = (state, instance) => {
    if (SearchOrResetAssetsClicked) {
      dispatch(actions.resetSearchOrResetInventory())
    } else {
      if (!DateGenerate) {
        state.page = 2;
        const limit = state.pageSize;
        const fl_users_no_of_rows = state.pageSize;
        let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : assestsCurPage + 1;
        if (No_of_rows != fl_users_no_of_rows) {
          page = 1;
          setTablePageFn(0)
        }
        const sorted = state.sorted;
        const type = 'asset'

        const name = SearchValue.name ? SearchValue.name : '';
        props.getSingleLogAsset(props.match.params.assetId, {page,limit})
      }
    }
    // }
  }
  const handleConfirm = (confirm) => {
    setOpen(false);
    //props.deleteArea(currentArea.id, props.match.params.accountId)
  };
  return (
    SearchOrResetAssetsClicked && props.isLoading ?
      <div className="loader_btn_block">
        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
      </div>
      :
      <> <ReactTable
        data={data}
        loading={props.isLoading}
        defaultPageSize={No_of_rows ? No_of_rows : pageSize}
        minRows={0}
        manual
        onFetchData={fetchData}
        pages={No_of_pages}
        gotoPage={assestsCurPage}
        page={assestsCurPage}
        onPageChange={(page) => { setTablePageFn(page) }}
        onPageSizeChange={(e) => setPageSize(e)}
        showPaginationTop={true}
        showPaginationBottom={false}
        showPagination={props.assets.length !== 0}
        columns={columns}
        NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Assests Stock Details Found')}
        getTheadThProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              borderTop: 'none',
              borderBottom: 'none',
              borderRight: 'none',
              textAlign: 'center',
              color: '#566975',
              fontSize: '14px',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              float: 'left',
            }
          }
        }
        }
        LoadingComponent={() =>
          props.isLoading ?
            <div style={{
              display: "block",
              position: "absolute",
              left: 0,
              height: '100%',
              right: 0,
              background: "rgba(255,255,255,0.8)",
              transition: "all .3s ease",
              top: 0,
              bottom: 0,
              textAlign: "center"
            }}
              className="loader_btn_block">
              <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div> : null}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            className: 'react-table-tr-element',
            style: {
              border: '1px solid #c7cdd1',
              transition: '.3s ease-in-out',
              width: '100%',
              marginTop: '5px',
              float: 'left'
            }
          }

        }}
        getTdProps={(state, rowInfo, column, instance) => {

          if (column.id == 'image') {
            return {
              style: {
                marginTop: '0px'
              }
            }
          } else {
            return {
              className: 'react-td-element',
            }
          }
        }
        }
        getProps={() => {
          return {
            style: {
              border: 'none'
            }
          }
        }}
      />

      </>
  )
}

export default AssetsPreviewTable;