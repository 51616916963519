import React from 'react';
import { calculateHoursFromMinutes_V1, getOrdinalNumber, makeFirstLetterCapital } from '../../../../../utility/utility';
import PreviewShifScheduleSkeleton from './PreviewShifScheduleSkeleton';

import { tzDateConverterTool_V1, tzDateConverterTool_V7, tzTimeConversionTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
var feedGap = null;

let PreviewShifSchedule = (props) => {

    const { singleShiftScheduleData } = props
    console.log("singleShiftScheduleData", singleShiftScheduleData);
    if (props.isLoad) {
        return (
            <>
                <PreviewShifScheduleSkeleton />
            </>
        );
    } else {

        /* This functionality for meal breaks Ascending order */
        if (singleShiftScheduleData && singleShiftScheduleData.breaks) {
            feedGap = singleShiftScheduleData?.breaks?.sort((a, b) => {
                return new Date(a.expected_start) - new Date(b.expected_start);
            })
        };

        return (
            <div>

                <main className="app-content add_account_modal add_frontline_pop">
                    <div className="appcontent_Inner user_newQuote pt-0">

                        <div className="container py-5">

                            <div className="row text-center mb-4">
                                <div className="col-lg-7 mx-auto">
                                    <h1 className="mb-0" style={{ color: '#4a4a4a', fontWeight: '500', fontSize: '35px' }}> Shift Details </h1>
                                </div>
                            </div>
                            <div className="addQuote_form">

                                <div className="row">
                                    <div className="col-lg-8 mx-auto">
                                        <ul className="list-group shadow rounded" style={{ marginLeft: '10%', marginRight: '10%' }}>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Date </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.date ? tzDateConverterTool_V1(singleShiftScheduleData.date) : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Timings </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.shift && singleShiftScheduleData?.accounts ? `${tzTimeConversionTool_V1(singleShiftScheduleData.shift?.start_time, singleShiftScheduleData.date)} - ${tzTimeConversionTool_V1(singleShiftScheduleData.shift?.end_time, singleShiftScheduleData.date)}` : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                        <div className="media-body" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                            <div style={{ width: '50%', border: '1px solid #555555' }}>
                                                                <h5
                                                                    className="mt-0 font-weight-bold mb-3 text-dark"
                                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                                                                >
                                                                    Allocated Job
                                                                </h5>
                                                            </div>
                                                            <div style={{ width: '30%', backgroundColor: '#555555', border: '1px solid #555555' }}>
                                                                <h5
                                                                    className="mt-0 font-weight-bold mb-3 text-white"
                                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                                                                >
                                                                    Address
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className="media-body" style={{ display: 'flex', flexDirection: 'column' }}>
                                                            {singleShiftScheduleData?.accounts ? singleShiftScheduleData.accounts.map((job, index, array) => {

                                                                let trade = job?.address;

                                                                return (
                                                                    <>
                                                                        <div style={{ borderLeft: '5px solid #cfcfcf', backgroundColor: '#f0f0f0' }}>
                                                                            <h5 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> {job && job.name} </h5>
                                                                            <h5 className="ml-lg-3 font-italic text-muted mb-2" style={{ fontSize: '15px' }}>
                                                                                {
                                                                                    trade?.formatted_address == null ?
                                                                                        `${trade.city !== null ? trade.city + ', ' : null}
                                                                                                ${trade.state !== null ? trade.state + ', ' : null}
                                                                                                ${trade.country !== null ? trade.country + ', ' : null}
                                                                                                ${trade.zip !== null ? trade.zip : null}` :
                                                                                        trade.formatted_address
                                                                                }
                                                                            </h5>
                                                                        </div>
                                                                        {index === array.length - 1 ? null : <br />}
                                                                    </>
                                                                );

                                                            }) : (<h5 className="ml-lg-0 font-italic text-color-info mb-0" style={{ fontSize: '15px' }}> None of Jobs available for this Assignee </h5>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Status </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.status ? makeFirstLetterCapital(singleShiftScheduleData.status) : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Notes </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.shift?.notes ? singleShiftScheduleData.shift.notes : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Assignee </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.assignedTo?.id ? `${singleShiftScheduleData.assignedTo?.first_name} ${singleShiftScheduleData.assignedTo?.last_name}` : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Clocked In Time </h5>
                                                    </div>
                                                    <div className="media-body order-2 order-lg-1">
                                                        {singleShiftScheduleData?.check_int_at ? <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.check_int_at ? tzDateConverterTool_V7(singleShiftScheduleData.check_int_at) : '−'} </h5> : "−"}
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                        <div className="media-body" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break Time </h5>
                                                        </div>
                                                        <div className="media-body" style={{ display: 'flex', flexDirection: 'column' }}>
                                                            {feedGap?.length > 0 ? feedGap.map((feed, index, array) => {
                                                                return (
                                                                    <>
                                                                        {feed.start && feed.end ? (
                                                                            <>
                                                                                <div style={{ borderLeft: '5px solid #cfcfcf', backgroundColor: '#f0f0f0' }}>
                                                                                    <h5 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> {getOrdinalNumber(index + 1)} meal </h5>
                                                                                    <h5 className="ml-lg-3 font-italic text-muted mb-2" style={{ fontSize: '15px' }}>
                                                                                        {`${feed?.start ? tzDateConverterTool_V7(feed.start) : null} − ${feed?.end ? tzDateConverterTool_V7(feed.end) : null}`}
                                                                                    </h5>
                                                                                </div>
                                                                                {index === array.length - 1 ? null : <br />}
                                                                            </>
                                                                        ) : '−'}
                                                                    </>
                                                                );

                                                            }) : (<h5 className="ml-lg-0 font-italic text-color-info mb-0" style={{ fontSize: '15px' }}> No Meal Break </h5>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                        <div className="media-body order-2 order-lg-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Clocked Out Time </h5>
                                                        </div>
                                                        <div className="media-body order-2 order-lg-1" style={{ display: 'flex', flexDirection: 'column' }}>
                                                            {singleShiftScheduleData?.check_out_at ? <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.check_out_at ? tzDateConverterTool_V7(singleShiftScheduleData.check_out_at) : '−'} </h5> : "−"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            {/* each Job Worked Hours details */}

                                            {
                                                singleShiftScheduleData?.shiftDetailJobs?.map((item) => (
                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body  order-2 order-lg-1">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Worked Hours in {item?.account?.name} </h5>
                                                                </div>
                                                                <div className="media-body  order-2 order-lg-1">
                                                                    {item?.total_minutes ? <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {item?.total_minutes ? calculateHoursFromMinutes_V1(item.total_minutes) : '–'} </h5> : '–'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            }

                                            {/* end each Job Worked Hours details */}

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                        <div className="media-body order-2 order-lg-1">
                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Total Worked Hours </h5>
                                                        </div>
                                                        <div className="media-body order-2 order-lg-1">
                                                            {singleShiftScheduleData?.total_time ? <h5 className="ml-lg-5 order-1 order-lg-2 text-muted">
                                                                {singleShiftScheduleData?.total_time ?
                                                                    calculateHoursFromMinutes_V1(singleShiftScheduleData.total_time)
                                                                    : '−'}
                                                            </h5> : "−"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Worked Tickets </h5>
                                                    </div>
                                                    <div className="media-body order-2 order-lg-1">
                                                        {/* <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> − </h5> */}
                                                        −
                                                    </div>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </div>
        );
    }

};

export default PreviewShifSchedule;