import React,{useState, useEffect} from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Input } from '../../../../UI/InputElement/InputElement';
import { newFulfillmentManagerFormValidator as validate } from '../../../../../../utility/validator/validator';
import FulfillmentManagerFormSkeleton from './FulfillmentManagerFormSkeleton';
import { Table } from 'antd';
import isEmpty from '../../../../../../utility/IsEmptyValidator';
import cloneDeep from 'clone-deep';
import { makeFirstLetterCapital } from '../../../../../../utility/utility';
import { Descriptions } from 'antd';

let FulfillmentManagerForm = (props) => {

  const data = props.singlePickupTicket && props.singlePickupTicket.ticket && props.singlePickupTicket.ticket.items.map((ticket) => {
    return {
        key: ticket.id,
        item: makeFirstLetterCapital(ticket.name),
        quantity: ticket.quantity,
    }
  });

  const columns = [
    {
      title: 'Items',
      dataIndex: 'item',
      key: 'item',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
  ];

  const getInventoryBoxNamesList = (props) => {
    if (props.inventoryBoxNamesList && props.inventoryBoxNamesList.length > 0) {
        return props.inventoryBoxNamesList && props.inventoryBoxNamesList.map((box) => {
            return {
                value: box.id,
                label: box.name
            }
        })
    } else {
        return []
    }
  };

  const [boxNamesList, setBoxNamesList] = useState(getInventoryBoxNamesList(props));

  useEffect(() => {
    setBoxNamesList(getInventoryBoxNamesList(props))
  }, [props.inventoryBoxNamesList]);

  const noOptionsMessage = (a, b) => {
    return 'No options';
  }

  const { handleSubmit, pristine, reset, submitting, error } = props;
  
  if (props.isFulfillmentManagerFormOpen) {
    return (
        <FulfillmentManagerFormSkeleton />
    )
  } else {
      return (
        <main className="app-content add_account_modal add_frontline_pop">
          <div className="appcontent_Inner user_newQuote pt-0">
            <div className="inspection_content_sec pt-0">
              <div className="add_info_block quote_form">
                <h1 className="section_title text-center" style={{color:'#2a2a2a', fontSize:'33px', fontWeight:'600'}}> Box Association </h1>
                <div className="addQuote_form">
                  <form onSubmit={handleSubmit}>
                    <div className="cst_tab_content">

                      <div className="form-group">
                        <label className="form_title font-weight-bold"> Job Name </label>
                        <input dataTest="ticket_subject" type="text" className="form-control input-modifier" placeholder={props.singlePickupTicket.ticket && props.singlePickupTicket.ticket.account.name} name="fulfillmentManager[job_name]" disabled />
                      </div>

                      <div className='pickingTicketId'>
                        <Descriptions size='small' bordered>
                          <Descriptions.Item label="Picking Ticket Id"> {props.pickingTicketId} </Descriptions.Item>
                        </Descriptions>&nbsp;
                      </div>

                      <div className="form-group">
                        <Table columns={columns} dataSource={data} pagination={false} />
                      </div>

                      <div className="form-group">
                        <label className="form_title font-weight-bold"> Select Box </label>
                        <Input type="inputSelect" placeholder="Select Box" noOptionsMessage={noOptionsMessage}  options={boxNamesList} name="fulfillmentManager[ticket][box_name]" />
                      </div>

                    </div>
                    <div className="modal-footer d-block">
                      <button data-test="submit_button" type="submit" className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-75" : "btn_box cst_btn btn_danger w-75"} >{props.isLoading ? "" : "Ready For Pickup"}</button>
                      <button data-test="cancel_button" type="button" className="btn btn-outline-secondary cst_btn w-75 mt-2" onClick={() => props.history.push("/picking-tickets")}> Back </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
  };
};

const mapStateToProps = (state) => {

  let fulfillmentManager = {};
  if(isEmpty(state.InventoryReducer.singlePickupTicket)) {
    fulfillmentManager = {
      // active_status: 'active',
    }
  }else {
    const values = cloneDeep(state.InventoryReducer && state.InventoryReducer.singlePickupTicket);
    fulfillmentManager = {
      ticket: {
        status: "readyForPickup"
      },
      ticket_id: values && values.ticket && values.ticket.id ? values.ticket.id : null,
    }
  }

  return { initialValues: { fulfillmentManager } };

};


FulfillmentManagerForm = reduxForm({
  form: 'fulfillmentManagerForm',
  validate,
  enableReinitialize: true,
})(FulfillmentManagerForm);

FulfillmentManagerForm = connect(mapStateToProps)(FulfillmentManagerForm);

export default FulfillmentManagerForm;