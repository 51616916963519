import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../styles/custom.css';
import { TicketStatus, userRoles, TimeZones, routes } from '../../../../utility/constants/constants';
import { CustomNoDataComponent,
    trimString,
    makeFirstLetterCapital,
    convertDateTimezone,
    convertDateAndTimeTimezone, getUser, getWeekStartAndEndDay, convertTicketsDataToCsvType, convertDateToDifferentTZ } from '../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import storage from '../../../../utility/storage';
import cloneDeep from 'clone-deep';
var moment = require('moment');

let InventoryListTable = (props) => {

    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchInventoryItemsList && state.form.searchInventoryItemsList.values ? state.form.searchInventoryItemsList.values : {});
    const DateGenerate = useSelector(state => state.InventoryReducer.dataGenerated);
    const values = useSelector(state => state.InventoryReducer.inventoryItems_searchValues);
    const No_of_pages = useSelector(state => state.InventoryReducer.inventoryItemsList_total_pages);
    const No_of_rows = useSelector(state => state.InventoryReducer.inventoryItemsList_no_of_rows);
    const isInventoryItemList = useSelector(state => state.InventoryReducer.isInventoryItemList);
    const inventoryItemsListPage = useSelector(state => state.InventoryReducer.inventoryItemsListCurPage);
    const SearchOrResetInventoryItemsListClicked = useSelector(state => state.InventoryReducer.SearchOrResetInventoryItemsListClicked);

    const accountTimezone = 'America/Los_Angeles';

    const data = props.inventoryItemsList && props.inventoryItemsList.map(value => {debugger
        return {
            name: makeFirstLetterCapital(value.name),
            manufacture: makeFirstLetterCapital(value.manufacture),
            sku_detail: value.sku_detail,
            expiration_date: moment(value.expiration_date).format('MM/DD/YYYY') + " " +"(PST)",
            initial_quantity: value.item.initial_quantity,
            location: value?.location_id ? makeFirstLetterCapital(value?.itemLocation?.name) : '−',
            supplier: makeFirstLetterCapital(value.supplier),
            actions:
                <>
                <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/inventory/preview-inventory-item/${value.id}`)}><i class="fas fa-eye" aria-hidden="true" ></i></span>
                <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/inventory/item/${value.id}`)} data-test="edit_pencil_icon"><i class="fa fa-pencil" aria-hidden="true"></i></span>
                <span className="mr_20 account_edit_icn" onClick={() => props.handleInventoryOpenDeleteModal(value.id)}><i class="fa fa-trash" aria-hidden="true"></i></span>
                </>
        }
    })

    const columns = [
        {
            Header: <><span> Item </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'name',
            show: props.inventoryItemsList.length !== 0,
        },
        {
            Header: <><span> Manufacture </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'manufacture',
            show: props.inventoryItemsList.length !== 0,
        },
        {
            Header: <><span> SKU Details </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'sku_detail',
            show: props.inventoryItemsList.length !== 0,
        },
        {
            Header: <><span> Expiration Details </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'expiration_date',
            show: props.inventoryItemsList.length !== 0,
        },
        {
            Header: <><span> Quantity </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'initial_quantity',
            show: props.inventoryItemsList.length !== 0
        },
        {
            Header: <><span> Location </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'location',
            sortable: false, // Disable sorting for this column
            show: props.inventoryItemsList.length !== 0
        },
        {
            Header: <><span> Supplier </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'supplier',
            show: props.inventoryItemsList.length !== 0
        },
        {
            Header: '',
            accessor: 'actions',
            width: 135,
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()
    if(inventoryItemsListPage !== tablePageNew.current) {
        tablePageNew.current = inventoryItemsListPage;
      }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.inventoryItemsListPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetInventoryItemsListClicked) {
            dispatch(actions.resetSearchOrResetInventory())
        } else {
            if (!DateGenerate && !props.isInventoryItemList) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : inventoryItemsListPage + 1;
                const inventoryItemsList_no_of_rows = state.pageSize;
                if(No_of_rows != inventoryItemsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                const sorted = state.sorted;
                const name = SearchValue.name ? SearchValue.name : '';
                const manufacture = SearchValue.manufacture ? SearchValue.manufacture : '';
                const expiration_date = SearchValue.expiration_date ? convertDateToDifferentTZ(SearchValue.expiration_date, 'to') : '';
                const supplier = SearchValue.supplier ? SearchValue.supplier : '';
                props.getAllInventoryItemsList({page,limit,sorted,name,manufacture,expiration_date,supplier}, values)
            }
        }
    }

    return (
        SearchOrResetInventoryItemsListClicked && props.isInventoryItemList ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isInventoryItemList}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={inventoryItemsListPage}
                page = { inventoryItemsListPage }
                onPageChange={(page) => {setTablePageFn(page)}}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPagination={props.inventoryItemsList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(props.isInventoryItemList, 'No Inventory Item List Found')}
                LoadingComponent={() =>
                    isInventoryItemList ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default InventoryListTable;