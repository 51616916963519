import React, { Component } from 'react';
// import Ticket from '../../../component/Ticket/ticket';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { convertDateToDifferentTZ, getUser } from '../../../../utility/utility';
import { animateScroll as scroll} from 'react-scroll'
import storage from '../../../../utility/storage';
import BRMSchedule from '../../../component/companies/BRMScheduleComponent/brmSchedule';
var moment = require('moment');
const cloneDeep = require('clone-deep');
class BRMScheduleContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        scroll.scrollToTop();
        this.props.getCompaniesDropDownList(); 
    }

    componentDidUpdate(prevProps, prevState) {
        const user = storage.get('user');
        if(user) {
            if(prevProps.user.selected_mode !== user.selected_mode) {
                this.props.scheduledTaskListPageClicked(0)
            }
        }
    }

    resetClicked = () => {
        const page = 1
        const limit = this.props.brmScheduleList_no_of_rows
        this.props.reset('brmScheduleForm');
        this.props.brmSchedulePageClicked(0)
        this.props.getAllBrmSchedule({page,limit,type:"default"})

    }

    searchBrmList = (value) => {
        const values = cloneDeep(value)
        let id =  null;
        let name = null;
        let status = null;
        let matrix = null;
        let company_id = null;
        let from = null;
        let to = null;
        let type=null
        const page = 1
        const limit = this.props.brmScheduleList_no_of_rows
        if(values.id) {
            values.id = values.id.trim()
            id = values.id
        }
      if(values.meeting) {
          values.meeting = values.meeting.trim()
          name = values.meeting
      }
      if(values.status) {
        values.status = values.status.trim()
        status = values.status
      }
      if(values.matrix) {
        values.matrix = values.matrix.trim()
        matrix =  values.matrix 
        }  
        if(values.customer) {
            company_id =  values.customer?.value
            }  
      if(values.fromDate) {
        values.fromDate = convertDateToDifferentTZ(values.fromDate, 'from');
        from = values.fromDate.split('T')[0];
      }  
      if(values.toDate) {
        values.toDate = convertDateToDifferentTZ(values.toDate, 'to');
        to = values.toDate.split('T')[0];
      }  

      if(value.type){
        type=value.type.value
      }
     
        this.props.getAllBrmSchedule({page,limit,name, status, matrix, company_id,id,from,to,type}, values);
    }
         
    render() {
        return (
            <BRMSchedule
            {...this.props}
            isLoading={this.props.isLoading}
            brmScheduleList={this.props.brmScheduleList ? this.props.brmScheduleList : []}  
            resetClicked={this.resetClicked} 
            onSubmit={this.searchBrmList}      
            />
        )
    }
}




const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.BRM.isLoading,
        brmScheduleList: state.BRM.brmScheduleList,
        brmScheduleList_no_of_rows: state.BRM.brmScheduleList_no_of_rows,
        companiesList: state.adminOrSuperAdminReducer.companyDropdownList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: (brmScheduleForm) => dispatch(reset(brmScheduleForm)),  // requires form name
        getAllBrmSchedule: (page, values) => dispatch(actions.getAllBrmSchedule(page, values)),
        brmSchedulePageClicked: (params) => dispatch(actions.brmSchedulePageClicked(params)),
        getCompaniesDropDownList: () => dispatch(actions.getCompaniesDropDownList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BRMScheduleContainer);