export const URL_REGEXP = /^(http|https)?:\/\/[a-zA-Z0-9-\.]+\.[a-z]{2,4}/;
export const EMAIL_REGEXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
// export const PhNoPattern = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
export const URL = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
export const PhNoPattern = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;

export const routes = {
    HOME: '/',
    HOMEPAGE: '/home',
    SIGNIN: '/signin',
    SIGNUP: '/signup',
    FORGET_PASSWORD: '/forgetpassword',
    RESET_PASSWORD: '/resetpassword',

    DASHBORD: '/dashboard',
    VIEW_FILTERED_QUOTES: '/dashboard/viewfilterquotes',
    VIEW_FILTERED_INSPECTIONS: '/dashboard/viewfilterinspections',
    VIEW_FILTERED_TICKETS: '/dashboard/viewfiltertickets',

    USER: '/user',
    NEW_USER: '/user/newuser',
    EDIT_USER: '/user/:userId',
    PREVIEW_USER: '/user/previewUser/:userId',
    USER_PROFILE: '/user_profile',

    QUOTES: '/quotes',
    NEW_QUOTES: '/newquotes',
    EDIT_QUOTES: '/quote/:quoteId/editquotes',
    MANUALL_QUOTE: '/manual_quote',
    QUOTE_APPROVED: '/quotes/:quoteId/quoteapproved',

    LOGBOOK: '/logbook',

    COMPANIES: '/customers',
    NEW_COMPANY: '/customers/newcustomer',
    EDIT_COMPANY: '/customers/:companyId',
    COMPANY_USERS: '/customers/:companyId/customer_users',
    NEW_COMPANY_USER: '/customers/:companyId/new_customer_user',
    API_ACCESS: '/customer/api-access',

    KPI_DASHBOARD: '/customers/:companyId/kpidashboard',

    ACCOUNTS: '/accounts',
    NEW_ACCOUNTS: '/accounts/newaccounts',
    EDIT_ACCOUNT: '/accounts/:accountId',
    ACCOUNT_USERS: '/accounts/:accountId/account_users',
    NEW_ACCOUNT_USER: '/accounts/:accountId/new_account_user',

    ACCOUNT_AREAS: "/accounts/:accountId/account_users/areas",
    ADD_AREA: "/accounts/:accountId/account_users/areas/newarea",
    ADD_LOGBOOK_AREA: "/accounts/:accountId/account_users/areas/newlogbookarea",
    PREVIEW_LOGBOOK_AREA: '/accounts/:accountId/account_users/view_logbookarea/:logbookId',
    PREVIEW_LOGBOOK_LOG_AREA: '/logbook/:logbookId/view_log/:logId',
    EDIT_AREA: "/accounts/:accountId/account_users/areas/:areaId/edit",
    EDIT_LOGBOOK_AREA: "/accounts/:accountId/account_users/logbookarea/:areaId/edit",
    CHI_REVIEW: '/accounts/chi_review',
    LOGBOOK_FEEDBACK: '/accounts/logbook-feedback/:logbookId',
    UPDATE_LOGBOOK_FEEDBACK: '/accounts/logbook-feedback/:logbookId/update/:logId',
    LOGBOOK_THANKYOU: '/accounts/logbook-thankyou',

    INSPECTIONS: '/inspections',
    MY_INSPECTIONS: '/inspections/my-inspection',
    PENDING_INSPECTIONS: '/inspections/pending-inspection',
    PREVIEW_PENDING_INSPECTIONS: '/inspections/pending-inspection/previewInspection/:inspectionId',
    PERFORM_INSPECTION: '/inspections/performInspection/:inspectionId',
    EDIT_INSPECTION: '/inspections/editInspection/:inspectionId',
    PREVIEW_INSPECTION: '/inspections/previewInspection/:inspectionId',
    REPORTS: '/reports',
    KPI_PREVIEW_INSPECTION: '/inspections/previewInspection/:id',

    TICKETS: '/tickets',
    PRIORITY_ESCALATION_TICKETS: '/priority-escalation-tickets',
    MY_TICKETS: '/tickets/my-tickets',
    SCHEDULED_TICKETS: '/scheduled-tickets',
    PREVIEW_SCHEDULED_TICKETS: '/schedules/ticket/:id',
    NEW_TICKET: '/tickets/newTickets',
    EDIT_TICKET: '/tickets/:ticketId',
    VIEW_TICKET: '/tickets/view_ticket/:ticketId',
    PREVIEW_SINGLE_TICKET: '/schedules/tickets/preview-ticket/:id',
    VIEW_PRIORITY_ESCALATION_TICKET: '/tickets/priority/:ticketId',

    CHI_SERVYS: '/chi_survey',
    PREVIEW_CHI: '/chi_survey/preview/:chiId',

    CRITERIA: '/criteria',

    SETTINGS: '/settings/',

    SETTINGS_PREFERENCES: '/settings/preferences',
    USER_EMAIL_PREFERENCES: '/settings/preferences/:userId',
    RATINGS: '/settings/ratings',
    NEW_RATING: '/settings/new_rating',
    EDIT_RATING: '/settings/rating/:ratingId',
    INSPECTION_FORMS: '/settings/inspection_forms',
    EDIT_INSPECTION_FORM: '/settings/inspection_forms/:inspectionFormId',
    NEW_INSPECTION_FORMS: '/settings/new_inspection_forms',
    NEW_POSITIONS_AND_REGIONS: '/settings/new_positions_and_regions',
    CATEGORIES: '/settings/categories',
    TIMEZONES: '/settings/timezones',
    PEGASSURE: '/settings/pegassure',
    PEGASSURE_LOGBOOK: '/pegassure-logbook',

    SCAN_QR: "/scan_qr",
    SCAN_LOGBOOK_QR: "/scan_logbook_qr",
    SCAN_GLOBAL_QR: "/account/:accountId/areas/:areaId",
    SEARCH_HISTORY: "/account/:accountId/V2",
    SEARCH_HISTORY_NEW: "/account/:accountId",
    SEARCH_LOGBOOK_HISTORY: "/logs/:logId",
    SCAN_SEARCH_RESULT: "/account/:accountId/scan-result-history",
    SCAN_SEARCH_SCHEDULE: "/account/:accountId/schedule",

    UPCOMING_PAST_SCHEDULE: "/schedules",

    FRONTLINE_LIST: "/frontlines",
    ADD_FRONTLINE: "/frontlines/new",
    EDIT_FRONTLINE: "/frontlines/:frontlineId",

    INSIGHTS: "/settings/insights",

    THANKYOU: '/thankyou',

    // CHI_REVIEW: '/chi_review',

    KPI_ROUTE: '/kpi',
    NEW_VERION: '/new-version',
    PEGASSURE_FEEDBACK: '/pegassure-feedback/:areaId',
    PEGASSURE_COMMENTS: '/pegassure-comments/:type/:areaId',
    PEGASSURE_THANKYOU: '/pegassure-thankyou/:areaId/:feedbackType',
    FEEDBACK_TABLE: '/feedbacks-table',
    PREVIEW_FEEDBACK: '/feedbacks/previewFeedback/:feedbackId',

    SCHEDULE_TIME: '/accounts/:accountId/account_users/schedule-time',
    CREATE_NEW_SCHEDULE: '/accounts/:accountId/account_users/schedule-time/create-new-schedule',
    CREATE_NEW_SCHEDULE_FROM_SCHEDULE: '/accounts/account_users/schedule-time/create-new-schedule',

    SCHEDULE_TAB: '/schedules',
    SCHEDULE_TIME_PREVIEW: '/accounts/:accountId/account_users/schedule-time/:event_id',

    INSPECTION_SCHEDULE_HISTORY: '/accounts/:accountId/account_users/inspection-schedule-history',
    ALL_INSPECTION_SCHEDULE_HISTORY: '/accounts/account_users/inspection-schedule-history',
    HOME_OPTION: '/cam-option',
    LOGBOOK_QR_BUTTON: '/scan-logbook',

    CREATE_NEW_TICKET_SCHEDULE: '/accounts/:accountId/account_users/create-new-ticket-schedule',
    CREATE_NEW_TICKET_SCHEDULE_FROM_SCHEDULE: '/accounts/account_users/schedule-time/create-new-ticket-schedule',

    TICKET_SCHEDULE_HISTORY: '/accounts/:accountId/account_users/ticket-schedule-history',
    ALL_TICKET_SCHEDULE_HISTORY: '/accounts/account_users/ticket-schedule-history',

    BOX: '/box',
    ADD_BOX: '/box/add-box',
    EDIT_BOX: '/box/:boxId',
    AUDIT_LOGS: '/audit-logs/:ticketId',

    PICKING_TICKETS: '/picking-tickets',
    PICKING_TICKETS_WITH_FILTERED_ID: '/picking-tickets/:ticketId',

    INVENTORY_THANKYOU: '/inventory-thankyou',
    EMERGENCY_PICKING_TICKETS: '/emergency-picking-tickets',
    EMERGENCY_PICKING_TICKETS_WITH_FILTERED_ID: '/emergency-picking-tickets/:ticketId',

    ASSETS_LIST: '/assets',
    ASSETS_STOCK: '/assets/asset-stock/:assetId',
    NEW_ASSET: '/assets/new-asset',
    ADD_ASSET_STOCK: '/asset-stock/new-asset-stock',
    UPDATE_ASSET_STOCK: '/edit-asset-stock/:assetId',
    EDIT_ASSET: '/assets/:assetId',
    ASSETS_PREVIEW: '/assets/view-asset-stock/:assetId',

    ASSET_SCAN_CHECK_OUT_QR: '/assetScan/checkOut_qr',
    ASSET_SCAN_CHECK_IN_QR: '/assetScan/checkIn_qr',

    ASSET_CHECK_OUT_FORM: '/asset/checkOut/:assetId',
    ASSET_CHECK_IN_FORM: '/asset/checkIn/:assetId',

    INVENTORY_MAIN_LIST: '/inventory-items-list',
    NEW_INVENTORY_ITEM: '/inventory/new-item',
    EDIT_INVENTORY_ITEM: '/inventory/item/:inventoryItemId',
    PREVIEW_INVENTORY_ITEM: '/inventory/preview-inventory-item/:inventoryItemId',

    NEW_INVENTORY_PULL_REQUEST_TICKET: '/inventory/pullrequest/create-ticket',

    PICKUPS_TICKETS: '/pickups-tickets',

    FULFILLMENT_MANAGER: '/fulfillment-manager/:ticketId',

    INVENTORY_SCAN_READY_FOR_PICKUP_QR: "/inventoryScan/readyForPickup_qr",

    PICKUP_DETAILS: '/pickup-details/:ticketId',

    INVENTORY_SCAN_READY_FOR_DELIVERY_QR: "/inventoryScan/readyForDelivery_qr",

    INVENTORY_SCAN_FOR_PICKED_DELIVERY_QR: "/inventoryScan/:ticketId/forPickedDelivery_qr",

    SCAN_FOR_DELIVERY: "/scan-for-delivery/:boxId",

    LISTED_ITEMS_COMING_BACK: "/listedItems-coming-back",

    SCAN_FOR_DELIVERY_RETURN: "/scan-for-deliver/return",

    LOCATION: '/inventory/location',

    UNAVAILABLE_ITEMS: '/inventory/unavailable-items',

    CHI_QUARTERLY_SURVEY_CHECK_SCREEN: '/chi_survey/wait-screen',

    SKILLSET: '/skillsets',

    DEPARTMENT: '/departments',

    LEAVE_LIST: '/leaves',
    NEW_LEAVE: '/leaves/new-leave',
    EDIT_LEAVE: '/leaves/edit-leave/:leaveId',
    PREVIEW_LEAVE_REQUEST: '/leaves/preview/:leaveId',

    SCHEDULED_TASKS: '/scheduled-tasks',
    SUB_SCHEDULED_TASK: '/scheduled-tasks/sub-ticket/:id',
    PREVIEW_SUB_SCHEDULED_TASK: '/scheduled-tasks/ticket/:id/preview-scheduled-task/:ticketId',
    PREVIEW_SUB_SCHEDULED_TASK_TASK_ID: '/scheduled-tasks/ticket/preview-scheduled-task/:ticketId',
    NEW_SCHEDULED_TASK: '/scheduled-tasks/new-task',

    FRONTLINE_FACE_DETECTOR: '/frontline/face-detector',
    ADD_AVAILABILITY: '/add-availability/:userId',
    OVERRIDE_AVAILABILITY: '/override-availability/:userId',
    CALENDAR_AVAILABILITY: '/calendar/availability/:userId',
    FLUSER_CALENDAR_AVAILABILITY: '/calendar/availability',

    SHIFT_SCHEDULE: '/shift-schedule',
    SHIFT_SCHEDULE_DETAIL: '/shift-schedule/single-shift-schedule/:id',
    PREVIEW_SHIFT_SCHEDULE: '/shift-schedule/preview-shift-schedule/:id',
    CALENDAR_SHIFT: '/calendar/shift/:userId',
    FLUSER_CALENDAR_SHIFT: '/calendar/shift',
    FLUSER_NEW_SHIFT: '/frontlineUser/new-shift',
    FLUSER_EDIT_SHIFT: '/frontlineUser/edit-shift/:shiftId',
    FLUSER_EDIT_PERDAY_SHIFT_DETAIL: '/frontlineUser/edit-perDay-shiftDetail/:perDayShiftId',
    SHIFT: '/shift',
    SWAP_LIST: '/shift-swap/list',
    SHIFT_CREATE_SWAP: '/shift-schedule/swap/:shiftId',
    PREVIEW_SHIFT_SWAP_REQUEST_INFO: '/shift-swap/swap-request/details/:swapId',
    PREVIEW_SHIFT_SWAPPED_SPECIFICS: '/shift-swap/swapped/aspects/:swapId',

    OVERRIDE_AVAILABILITY_REQUEST_LIST: '/override-availability-list',

    AVAILABILITY_LIST: '/availability-list',
    SINGLE_AVAILABILITY_LIST: '/availability-list/single-availability-list/:id',

    CLKIN_CLKOUT_SCREEN: '/clkIn-clkOut',
    CLKIN_CLKOUT_FEEDBACK: '/clkIn-clkOut/:clkId/feedback/:shiftId',
    CLKIN_CLKOUT_SHIFT_RECAP: '/clkIn-clkOut/shiftCondense',
    FLUSER_MYTICKET: '/myTicket',
    TIMECARD_CORRECTION_MODULE_V1: '/scheduled-shift/:shiftId/timecard-correction/:shiftDetailId',
    TIMECARD_REVIEWED_LIST: '/timecard-review-list',
    FL_TIMECARD_REVIEWED_LIST: '/frontline/timecard-review-list',
    TIMECARD_CORRECTION_MODULE_V2: '/scheduled-shift/timecard-correction/:timeCardId',
    PREVIEW_TIMECARD_CORRECTION: '/preview/timecard-correction/:timeCardId',
    TIME_SHEET_LIST: '/time-sheet-list/:flUserId',
    FL_TIME_SHEET_LIST: '/frontline/time-sheet-list',
    TIMECARD_CORRECTION_MODULE_V3: '/frontline/timecard-correction',
    ATTENDANCE_DASHBOARD: '/attendance-dashboard/:flUserId',
    MEALPENALTY_GENERATOR: '/mealpenalty-generator/:shiftDetailId',

    /* PAYROLL FLOW ENDS HERE */

    TEAM_CHECKLIST_LOG: '/team-checklist-log',
    NEW_TEAM_CHECKLIST_LOG: '/team-checklist-log/new',
    EDIT_TEAM_CHECKLIST_LOG: '/team-checklist-log/:id',
    VIEW_TEAM_CHECKLIST_LOG: '/team-checklist-log/view/:id',


    TEAM_CHECKLIST: '/team-checklist',
    NEW_TEAM_CHECKLIST: '/team-checklist/new',
    EDIT_TEAM_CHECKLIST: '/team-checklist/:id',
    VIEW_TEAM_CHECKLIST: '/team-checklist/view/:id',


    TEAM_CHECKLIST_MANAGER_SUMMARY: '/team-checklist-manager-summary',
    TEAM_CHECKLIST_MANAGER_SUMMARY_NEW: '/team-checklist-manager-summary/new',
    TEAM_CHECKLIST_MANAGER_SUMMARY_EDIT: '/team-checklist-manager-summary/:id',
    TEAM_CHECKLIST_MANAGER_SUMMARY_VIEW: '/team-checklist-manager-summary/view/:id',

    TEAM_CHECKLIST_SUPERVISIOR_SUMMARY: '/team-checklist-supervisior-summary',
    TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_NEW: '/team-checklist-supervisior-summary/new',
    TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_VIEW: '/team-checklist-supervisior-summary/view/:id',
    TEAM_CHECKLIST_SUPERVISIOR_SUMMARY_EDIT: '/team-checklist-supervisior-summary/:id',

    TRAINING_LOG: '/training-log',
    TRAINING_LOG_NEW: '/training-log/new',
    TRAINING_LOG_EDIT: '/training-log/:logId',
    TRAINING_LOG_VIEW: '/training-log/view/:logId',

    RESTROOM_PORTERING: '/rest-room-portering',
    NEW_LOG_RESTROOM_PORTERING: '/rest-room-portering/new',
    EDIT_RESTROOM_PORTERING: '/rest-room-portering/:id',
    VIEW_RESTROOM_PORTERING: "/rest-room-portering/view/:id",

    NEW_BUILDING_PROFILE: '/building-profile/new',
    EDIT_BUILDING_PROFILE: '/building-profile/:id',
    VIEW_BUILDING_PROFILE: '/building-profile/view/:id',
    BUILDING_PROFILE: '/building-profile',

    JOB_CORE_MAP_CARD: '/job-core-map-card',
    NEW_JOB_CORE_MAP_CARD: '/job-core-map-card/new',
    EDIT_JOB_CORE_MAP_CARD: '/job-core-map-card/:id',
    VIEW_JOB_CORE_MAP_CARD: "/job-core-map-card/view/:id",

    //Tag Module
    REQUSET_FORM: '/requset-form',
    NEW_REQUSET_FORM: '/requset-form/new',
    EDIT_REQUSET_FORM: '/requset-form/:id',
    VIEW_REQUSET_FORM: "/requset-form/view/:id",

    //Inventory

    DAILY_KITTING: '/daily-kitting',
    NEW_DAILY_KITTING: '/daily-kitting/new',
    EDIT_DAILY_KITTING: '/daily-kitting/:id',
    VIEW_DAILY_KITTING: "/daily-kitting/view/:id",

    MONTHLY_USAGE_REPORT: '/monthly-usage-report',
    MONTHLY_USAGE_REPORT_TABLE: '/monthly-usage-report-table',
    NEW_MONTHLY_USAGE_REPORT: '/bulk-storage/new',
    EDIT_MONTHLY_USAGE_REPORT: '/bulk-storage/:id',
    VIEW_MONTHLY_USAGE_REPORT: "/bulk-storage/view/:id",

    INVENTORY_INVENTORY: '/monthly-usage-report',
    NEW_MONTHLY_USAGE_REPORT: '/bulk-storage/new',
    EDIT_MONTHLY_USAGE_REPORT: '/bulk-storage/:id',
    VIEW_MONTHLY_USAGE_REPORT: "/bulk-storage/view/:id",


    MONTHLY_COST_SUMMARY_ANALYSIS: '/monthly-cost-summary-analysis',
    MONTHLY_COST_SUMMARY_ANALYSIS_TABLE: '/monthly-cost-summary-analysis-table',

    //Audits
    NEW_SELF_AUDIT: '/self-audit/new',
    SELF_AUDIT: "/self-audit",
    EDIT_SELF_AUDIT: '/self-audit/:id',
    VIEW_SELF_AUDIT: '/self-audit/view/:id',

    PERFORMANCE_AUDIT: '/performance-audit',
    NEW_PERFORMANCE_AUDIT: '/performance-audit/new',
    EDIT_PERFORMANCE_AUDIT: '/performance-audit/:id',
    VIEW_PERFORMANCE_AUDIT: "/performance-audit/view/:id",

    //Project Module

    DEEP_CLEAN_CHART: '/deep-clean-chart',
    PROJECT_MODULE: '/project-module',
    VIEW_PROJECT_MODULE: '/project-module/ticket/:id',
    PREVIEW_SINGLE_PROJECT_MODULE: '/project-module/tickets/preview/:id',
    NEW_PROJECT_MODULE: '/project-module/new',

    // Pegassure Playbook Flow
    PEGASSURE_PLAYBOOK_LIST: '/pegassure-playbook',
    PREVIEW_PEGASSURE_PLAYBOOK_PDF: '/logbook-pdf/:playbookId',
    PREVIEW_PLAYBOOK_LOGAREA_GRID: '/playbook-logarea-grid/:playbookId',


    //AUTOMATION  FLOW
    PREVIEW_EVENTS:'/preview-event/:id',
    //BRM Schedule Table
    BRM_Schedule: '/brm-schedule',

    //Standard Matrix
    Standard_Matrix: '/standard-matrix'

}

export const TimeZones = {
    PST: 'America/Los_Angeles',
    CST: 'America/Chicago',
    // IST: 'Asia/Kolkata',
}

export const TimezoneMonths = {
    January: '08:00',
    February: '08:00',
    March: '08:00',
    April: '07:00',
    May: '07:00',
    June: '07:00',
    July: '07:00',
    August: '07:00',
    September: '07:00',
    October: '07:00',
    November: '08:00',
    December: '08:00'
}

export const Timezones = [
    {
        id: 'America/Los_Angeles',
        name: 'Pacific Standard Time(PST)'
    },
    {
        id: 'America/Chicago',
        name: 'Central Standard Time(CST)'
    },
    // {
    //     id: 'Asia/Kolkata',
    //     name: 'India Standard Time(IST)'
    // }
]

export const BarGraphSaleOptions = {
    tooltips: {
        intersect: false,
        callbacks: {
            label: function (tooltipItems, data) {
                return "$" + tooltipItems.yLabel.toString();
            }
        },
        userCallback: function (label, index, labels) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
                return label;
            }

        },
    },
    legend: {
        display: false
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                callback: function (value, index, values) {
                    return '$' + value;
                },
                fontColor: '#a1395e'
            }
        }],
        xAxes: [{
            ticks: {
                fontColor: '#151b26',
                fontStyle: 'bold'
            },
            barPercentage: 0.9,
            barThickness: 20,
            // maxBarThickness: 8,
            // minBarLength: 2,
            // gridLines: {
            //     offsetGridLines: true
            // },
            stacked: true,
            gridLines: {
                lineWidth: 0,
                color: "rgba(255,255,255,0)"
            }
        }]
    }
}

export const BarGraphCountOptions = {
    tooltips: {
        intersect: false,
    },
    legend: {
        display: false,
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                fontColor: '#a1395e',
                userCallback: function (label, index, labels) {
                    // when the floored value is the same as the value we have a whole number
                    if (Math.floor(label) === label) {
                        return label;
                    }

                },
            },

        }],
        xAxes: [{
            barPercentage: 0.9,
            barThickness: 20,
            ticks: {
                fontColor: '#151b26',
                fontStyle: 'bold',
            },
            // maxBarThickness: 8,
            // minBarLength: 2,
            // gridLines: {
            //     offsetGridLines: true
            // },
            stacked: true,
            gridLines: {
                lineWidth: 0,
                color: "rgba(255,255,255,0)"
            }
        }]
    }
}

export const LineGraphQuoteCountOptions = {
    tooltips: {
        intersect: false,
    },
    legend: {
        display: false
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                fontColor: '#a1395e',
                userCallback: function (label, index, labels) {
                    // when the floored value is the same as the value we have a whole number
                    if (Math.floor(label) === label) {
                        return label;
                    }

                },
                // stepSize: 1
            }
        }],
        xAxes: [{
            stacked: true,
            gridLines: {
                lineWidth: 0,
                color: "rgba(255,255,255,0)"
            },
            ticks: {
                fontColor: '#151b26',
                fontStyle: 'bold'
            },
        }]
    }
}

export const LineGraphSaleOptions = {
    tooltips: {
        // position: 'nearest',
        intersect: false,
        callbacks: {
            label: function (tooltipItems, data) {
                return "$" + tooltipItems.yLabel.toString();
            }
        },
    },
    legend: {
        display: false
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                callback: function (value, index, values) {
                    return '$' + value;
                },
                fontColor: '#a1395e'
            }
        }],
        xAxes: [{
            stacked: true,
            gridLines: {
                lineWidth: 0,
                color: "rgba(255,255,255,0)"
            },
            ticks: {
                fontColor: '#151b26',
                fontStyle: 'bold'
            },
        }]
    }
}

export const emptyData = {
    labels: [],
    backgroundColor: '#a1395e',
    datasets: [{ data: [] }]
}

export const currentData = {
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year',
    THREE_MONTHS: '90_days',
    CUSTOM: 'custom',
    DATEFILTER: 'datefilter'
}

export const Status = {
    APPROVED_BY_ADMIN: 'approved_by_admin',
    APPROVED_BY_SUPERADMIN: 'approved_by_superadmin',
    AUTO_APPROVED_QUOTE: 'auto_approved_quote',
    REJECTED_BY_ADMIN: 'rejected_by_admin',
    REJECTED_BY_SUPERADMIN: 'rejected_by_superadmin',
    CONFIRM_BY_CLIENT: 'confirmed_by_client',
    DECLINED_BY_CLIENT: 'declined_by_client',
    REQUESTED: 'requested',
    N_A: 'n_a',
    DRAFT: 'draft'
    // DECLINED: 'declined'
}


export const RatingTypes = {
    Percentage: 'percentage',
    List: 'list',
    Text: 'text',
    Other: 'other'
}

export const TicketsEscalationTime = [
    {
        id: '10 min',
        name: '10 min'
    }, {
        id: '30 min',
        name: '30 min'
    }, {
        id: '1 hour',
        name: '1 hour'
    }, {
        id: '3 hours',
        name: '3 hours'
    }, {
        id: '6 hours',
        name: '6 hours'
    }, {
        id: '12 hours',
        name: '12 hours'
    }, {
        id: '24 hours',
        name: '24 hours'
    }, {
        id: '48 hours',
        name: '48 hours'
    }, {
        id: '3 days',
        name: '3 days'
    }, {
        id: '5 days',
        name: '5 days'
    },
]

export const ClientSettingsDailyHours = [
    {
        id: "12 am",
        name: "12 am"
    }, {
        id: "1 am",
        name: "1 am"
    }, {
        id: "2 am",
        name: "2 am"
    }, {
        id: "3 am",
        name: "3 am"
    }, {
        id: "4 am",
        name: "4 am"
    }, {
        id: "5 am",
        name: "5 am"
    }, {
        id: "6 am",
        name: "6 am"
    }, {
        id: "7 am",
        name: "7 am"
    }, {
        id: "8 am",
        name: "8 am"
    }, {
        id: "9 am",
        name: "9 am"
    }, {
        id: "10 am",
        name: "10 am"
    }, {
        id: "11 am",
        name: "11 am"
    }, {
        id: "12 pm",
        name: "12 pm"
    }, {
        id: "1 pm",
        name: "1 pm"
    }, {
        id: "2 pm",
        name: "2 pm"
    }, {
        id: "3 pm",
        name: "3 pm"
    }, {
        id: "4 pm",
        name: "4 pm"
    }, {
        id: "5 pm",
        name: "5 pm"
    }, {
        id: "6 pm",
        name: "6 pm"
    }, {
        id: "7 pm",
        name: "7 pm"
    }, {
        id: "8 pm",
        name: "8 pm"
    }, {
        id: "9 pm",
        name: "9 pm"
    }, {
        id: "10 pm",
        name: "10 pm"
    }, {
        id: "11 pm",
        name: "11 pm"
    }
]
export const MinImageResolution = {
    height: 683,
    width: 1024
}

export const inspectorChoice =
    [{
        id: 'single',
        name: 'Only One Choice'
    },
    {
        id: 'multiple',
        name: 'Multiple Choices'
    }];

export const FilteredQuotes = {
    SEND_TO_CLIENTS: [Status.APPROVED_BY_ADMIN, Status.APPROVED_BY_SUPERADMIN, Status.CONFIRM_BY_CLIENT, Status.DECLINED_BY_CLIENT],
    DENIED_BY_ADMIN: [Status.REJECTED_BY_SUPERADMIN, Status.REJECTED_BY_ADMIN],
    APPROVED_BY_CLIENTS: [Status.CONFIRM_BY_CLIENT],
    REJECTED_BY_CLIENTS: [Status.DECLINED_BY_CLIENT],
    PENDING_FOR_APPROVAL: [Status.APPROVED_BY_ADMIN, Status.APPROVED_BY_SUPERADMIN]
}

export const TicketStatus = {
    open: 'open',
    pending: 'pending',
    accepted: 'accepted',
    resolved: 'resolved',
    canceled: 'canceled'
}

export const address_attributes = {
    city: '',
    state: '',
    zip: '',
    country: '',
    latitude: '',
    longitude: '',
    formatted_address: ''
}

export const DEFAULT_QUOTA = {
    quota_year: '',
    quota_amount: ''
}

export const StatusOptions = [
    {
        id: 'all',
        name: 'All'
    }, {
        id: 'requested',
        name: 'Pending for Approval'
    }, {
        id: 'approved_by_admin',
        name: 'Approved by Admin'
    }, {
        id: 'approved_by_superadmin',
        name: 'Approved by SuperAdmin'
    }, {
        id: 'auto_approved_quote',
        name: 'Auto Approved Quote'
    }, {
        id: 'rejected_by_admin',
        name: 'Rejected by Admin'
    }, {
        id: 'rejected_by_superadmin',
        name: 'Rejected by SuperAdmin'
    }, {
        id: 'confirmed_by_client',
        name: 'Confirmed by Customer'
    }, {
        id: 'declined_by_client',
        name: 'Waiting for Customer Approval'
    }, {
        id: 'draft',
        name: 'Draft'
    },
    {
        id: 'expired_quotes',
        name: 'Expired'
    },
]

export const QuoteStatusLog = {
    ALL: 'requested',
    PENDING_FOR_APPROVAL: 'requested',
    APPROVED_BY_ADMIN: 'approved_by_admin',
    APPROVED_BY_SUPERADMIN: 'approved_by_superadmin',
    AUTO_APPROVED_QUOTE: 'auto_approved_quote',
    REJECTED_BY_ADMIN: 'rejected_by_admin',
    REJECTED_BY_SUPERADMIN: 'rejected_by_superadmin',
    CONFIRM_BY_CLIENT: 'confirmed_by_client',
    DECLINED_BY_CLIENT: 'declined_by_client',
    DRAFT: 'draft',
}

export const RolesOptions = [
    {
        id: 'admin',
        name: 'Admin'
    },
    {
        id: 'client',
        name: 'Customer'
    },
    {
        id: 'inspector',
        name: 'Inspector'
    },
    , {
        id: 'requestor',
        name: 'Requestor'
    },


]

export const SearchRolesOptions = [
    {
        id: 'superadmin',
        name: 'Superadmin'
    },
    {
        id: 'admin',
        name: 'Admin'
    },
    {
        id: 'client',
        name: 'Customer'
    },
    {
        id: 'inspector',
        name: 'Inspector'
    },
    , {
        id: 'requestor',
        name: 'Requestor'
    },


]

export const UserModes = [
    {
        id: 'all',
        name: 'All'
    },
    {
        id: 'internal',
        name: 'Internal'
    },
    {
        id: 'external',
        name: 'External'
    }
]
export const UserModesWithoutAll = [

    {
        id: 'internal',
        name: 'Internal'
    },
    {
        id: 'external',
        name: 'External'
    }
]

export const positionOptions = [
    {
        id: 'DM',
        name: 'DM'
    }, {
        id: 'CSM',
        name: 'CSM'
    }, {
        id: 'VP',
        name: 'VP'
    }, {
        id: 'RM',
        name: 'RM'
    }, {
        id: 'Admin',
        name: 'Admin'
    }, {
        id: 'Supervisor',
        name: 'Supervisor'
    }
]

export const userRoles = {
    REQUESTOR: 'requestor',
    ADMIN: 'admin',
    SUPER_ADMIN: 'superadmin',
    INSPECTOR: 'inspector',
    CLIENT: 'client',
    FRONTLINE: 'frontline',
}

export const RatingInspectorChoice = [
    {
        id: 'Only one choice',
        name: 'Only one choice'
    },
    {
        id: 'Multiple choices',
        name: 'Multiple choices'
    }
]

export const priorityOptions = [
    {
        id: '1-low',
        name: '1-low'
    }, {
        id: '2',
        name: '2'
    }, {
        id: '3',
        name: '3'
    }, {
        id: '4',
        name: '4'
    }, {
        id: '5',
        name: '5'
    }, {
        id: '6',
        name: '6'
    }, {
        id: '7',
        name: '7'
    }, {
        id: '8',
        name: '8'
    }, {
        id: '9',
        name: '9'
    }, {
        id: '10-critical',
        name: '10-critical'
    }
]

export const categoryOptions = [
    {
        id: 'compliments',
        name: 'Compliments'
    }, {
        id: 'customer_complaint',
        name: 'Customer Complaint'
    }, {
        id: 'customer_happiness_index_support',
        name: 'Customer Happiness Index Support'
    }, {
        id: 'customer_request',
        name: 'Customer Request'
    }, {
        id: 'eh&s_deficiency',
        name: 'EH&S Deficiency'
    }, {
        id: 'safely_incentive_program',
        name: 'Safety Incentive Program'
    }, {
        id: 'service_issue(escalation)',
        name: 'Service Issue (escalation)'
    }
]

export const PortfolioProperties = {
    // portfolio properties
    faultImages: 0,
    isImagesEmpty: false,
    imageName: [],
    idsToDelete: [],
    imageUrl: null,
    savedPortfolioURls: {},
    base64: [],
    AllFileTypes: [],
    fileType: [],
}
export const InspectionFilterWithoutQQV = [
    {
        id: 'all',
        name: 'All inspections'
    }, {
        id: 'deficient',
        name: 'Deficient'
    }, {
        id: 'flagged',
        name: 'Flagged'
    }, {
        id: 'private',
        name: 'Private'
    }, {
        id: 'notDeficient',
        name: 'Not deficient'
    }, {
        id: 'notFlagged',
        name: 'Not flagged'
    }, {
        id: 'notPrivate',
        name: 'Not private'
    }, {
        id: 'draft',
        name: 'Draft'
    }, {
        id: 'completed',
        name: 'Completed'
    }
]
export const InspectionsFilter = [
    {
        id: 'all',
        name: 'All inspections'
    },
    // {
    //     id: 'qqv',
    //     name: 'QQV' 
    // }, {
    //     id: 'not_qqv',
    //     name: 'Not QQV'
    // }, 
    {
        id: 'deficient',
        name: 'Deficient'
    }, {
        id: 'flagged',
        name: 'Flagged'
    }, {
        id: 'private',
        name: 'Private'
    }, {
        id: 'notDeficient',
        name: 'Not deficient'
    }, {
        id: 'notFlagged',
        name: 'Not flagged'
    }, {
        id: 'notPrivate',
        name: 'Not private'
    }, {
        id: 'draft',
        name: 'Draft'
    }, {
        id: 'pending',
        name: 'Pending Inspections'
    }, {
        id: 'completed',
        name: 'Completed'
    }
]

export const InspectionsPendingFilter = [
    {
        id: 'all',
        name: 'All inspections'
    },
    // {
    //     id: 'qqv',
    //     name: 'QQV' 
    // }, {
    //     id: 'not_qqv',
    //     name: 'Not QQV'
    // }, 
    {
        id: 'deficient',
        name: 'Deficient'
    }, {
        id: 'flagged',
        name: 'Flagged'
    }, {
        id: 'private',
        name: 'Private'
    }, {
        id: 'notDeficient',
        name: 'Not deficient'
    }, {
        id: 'notFlagged',
        name: 'Not flagged'
    }, {
        id: 'notPrivate',
        name: 'Not private'
    }
]

export const InspectionsQQvFilter = [
    {
        id: 'qqv',
        name: 'QQV'
    }, {
        id: 'not_qqv',
        name: 'Not QQV'
    }
]
export const InspectionCustomerPresentFilter = [
    {
        id: 'qqv_customer',
        name: 'Yes'
    }, {
        id: 'nonqqv_customer',
        name: 'No'
    },
]

export const FLOOR_NO = [
    { "id": 1, name: "1st floor" },
    { "id": 2, name: "2nd floor " },
    { "id": 3, name: "3rd floor" },
    { "id": 4, name: "4th floor" },
    { "id": 5, name: "5th floor" },
    { "id": 6, name: "6th floor" },
    { "id": 7, name: "7th floor" },
    { "id": 8, name: "8th floor" },
    { "id": 9, name: "9th floor" },
    { "id": 10, name: "10th floor" },
    { "id": 11, name: "11th floor" },
    { "id": 12, name: "12th floor" },
    { "id": 13, name: "13th floor" },
    { "id": 14, name: "14th floor" },
    { "id": 15, name: "15th floor" },
    { id: 16, name: "16th floor" },
    { id: 17, name: "17th floor" },
    { id: 18, name: "18th floor" },
    { id: 19, name: "19th floor" },
    { id: 20, name: "20th floor" },
    { id: 21, name: "21th floor" },
    { id: 22, name: "22th floor" },
    { id: 23, name: "23th floor" },
    { id: 24, name: "24th floor" },
    { id: 25, name: "25th floor" },
    { id: 26, name: "26th floor" },
    { id: 27, name: "27th floor" },
    { id: 28, name: "28th floor" },
    { id: 29, name: "29th floor" },
    { id: 30, name: "30th floor" },
    { id: 31, name: "31th floor" },
    { id: 32, name: "32th floor" },
    { id: 33, name: "33th floor" },
    { id: 34, name: "34th floor" },
    { id: 35, name: "35th floor" },
    { id: 36, name: "36th floor" },
    { id: 37, name: "37th floor" },
    { id: 38, name: "38th floor" },
    { id: 39, name: "39th floor" },
    { id: 40, name: "40th floor" },
    { id: 41, name: "41th floor" },
    { id: 42, name: "42th floor" },
    { id: 43, name: "43th floor" },
    { id: 44, name: "44th floor" },
    { id: 45, name: "45th floor" },
    { id: 46, name: "46th floor" },
    { id: 47, name: "47th floor" },
    { id: 48, name: "48th floor" },
    { id: 49, name: "49th floor" }
];

export const CHIReviews = {
    chiReview1: 'chiReview1',
    chiReview2: 'chiReview2',
    chiReview3: 'chiReview3'
}
export const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//export const DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

export const PegAssureTabs = {
    AreaTypes: 'AreaTypes',
    Holidays: 'Holidays'
}

export const recurWeekSelect = [
    {
        id: '1',
        name: 'First'
    }, {
        id: '2',
        name: 'Second'
    }, {
        id: '3',
        name: 'Third'
    }, {
        id: '4',
        name: 'Fourth'
    }, {
        id: '5',
        name: 'Fifth'
    }
];

export const recurOnWeekSelect = [
    {
        id: '1',
        name: '1'
    }, {
        id: '2',
        name: '2'
    }, {
        id: '3',
        name: '3'
    }, {
        id: '4',
        name: '4'
    }, {
        id: '5',
        name: '5'
    }
];

export const monthSelect = [
    {
        id: '1',
        name: '1'
    },
];

export const QuarterlymonthSelect = [
    {
        id: '3',
        name: '3'
    }, {
        id: '6',
        name: '6'
    }, {
        id: '9',
        name: '9'
    }, {
        id: '12',
        name: '12'
    }
];

export const selectDay = [
    {
        id: 'sunday',
        name: 'Sunday'
    }, {
        id: 'monday',
        name: 'Monday'
    }, {
        id: 'tuesday',
        name: 'Tuesday'
    }, {
        id: 'wednesday',
        name: 'Wednesday'
    }, {
        id: 'thursday',
        name: 'Thursday'
    }, {
        id: 'friday',
        name: 'Friday'
    }, {
        id: 'saturday',
        name: 'Saturday'
    }
];

export const selectMonth = [
    {
        id: '0',
        name: 'January'
    }, {
        id: '1',
        name: 'February'
    }, {
        id: '2',
        name: 'March'
    }, {
        id: '3',
        name: 'April'
    }, {
        id: '4',
        name: 'May'
    }, {
        id: '5',
        name: 'June'
    }, {
        id: '6',
        name: 'July'
    }, {
        id: '7',
        name: 'August'
    }, {
        id: '8',
        name: 'September'
    }, {
        id: '9',
        name: 'October'
    }, {
        id: '10',
        name: 'November'
    }, {
        id: '11',
        name: 'December'
    },
];

export const selectSchedule = [
    {
        id: 'weekly',
        name: 'Weekly'
    }, {
        id: 'monthly',
        name: 'Monthly'
    }, {
        id: 'quarterly',
        name: 'Quarterly'
    },
];

export const fortyEightHoursOption = [
    {
        id: '1 hour',
        name: '1 Hour'
    }, {
        id: '2 hours',
        name: '2 Hours'
    }, {
        id: '3 hours',
        name: '3 Hours'
    }, {
        id: '4 hours',
        name: '4 Hours'
    }, {
        id: '5 hours',
        name: '5 Hours'
    }, {
        id: '6 hours',
        name: '6 Hours'
    }, {
        id: '7 hours',
        name: '7 Hours'
    }, {
        id: '8 hours',
        name: '8 Hours'
    }, {
        id: '9 hours',
        name: '9 Hours'
    }, {
        id: '10 hours',
        name: '10 Hours'
    }, {
        id: '11 hours',
        name: '11 Hours'
    }, {
        id: '12 hours',
        name: '12 Hours'
    }, {
        id: '13 hours',
        name: '13 Hours'
    }, {
        id: '14 hours',
        name: '14 Hours'
    }, {
        id: '15 hours',
        name: '15 Hours'
    }, {
        id: '16 hours',
        name: '16 Hours'
    }, {
        id: '17 hours',
        name: '17 Hours'
    }, {
        id: '18 hours',
        name: '18 Hours'
    }, {
        id: '19 hours',
        name: '19 Hours'
    }, {
        id: '20 hours',
        name: '20 Hours'
    }, {
        id: '21 hours',
        name: '21 Hours'
    }, {
        id: '22 hours',
        name: '22 Hours'
    }, {
        id: '23 hours',
        name: '23 Hours'
    }, {
        id: '24 hours',
        name: '24 Hours'
    }, {
        id: '25 hours',
        name: '25 Hours'
    }, {
        id: '26 hours',
        name: '26 Hours'
    }, {
        id: '27 hours',
        name: '27 Hours'
    }, {
        id: '28 hours',
        name: '28 Hours'
    }, {
        id: '29 hours',
        name: '29 Hours'
    }, {
        id: '30 hours',
        name: '30 Hours'
    }, {
        id: '31 hours',
        name: '31 Hours'
    }, {
        id: '32 hours',
        name: '32 Hours'
    }, {
        id: '33 hours',
        name: '33 Hours'
    }, {
        id: '34 hours',
        name: '34 Hours'
    }, {
        id: '35 hours',
        name: '35 Hours'
    }, {
        id: '36 hours',
        name: '36 Hours'
    }, {
        id: '37 hours',
        name: '37 Hours'
    }, {
        id: '38 hours',
        name: '38 Hours'
    }, {
        id: '39 hours',
        name: '39 Hours'
    }, {
        id: '40 hours',
        name: '40 Hours'
    }, {
        id: '41 hours',
        name: '41 Hours'
    }, {
        id: '42 hours',
        name: '42 Hours'
    }, {
        id: '43 hours',
        name: '43 Hours'
    }, {
        id: '44 hours',
        name: '44 Hours'
    }, {
        id: '45 hours',
        name: '45 Hours'
    }, {
        id: '46 hours',
        name: '46 Hours'
    }, {
        id: '47 hours',
        name: '47 Hours'
    }, {
        id: '48 hours',
        name: '48 Hours'
    }, {
        id: '1 week',
        name: '1 Week'
    },
];

export const beforeAfterOption = [
    {
        id: 'before',
        name: 'Before'
    }, {
        id: 'after',
        name: 'After'
    }
];

export const GOOGLE_MEASUREMENT_ID = 'G-ER7F51VZ0X'

export const qrCodeSize_option = [
    {
        value: 'small',
        label: 'Small',
        columnValue: 5
    },
    {
        value: 'medSmall',
        label: 'Med-Small',
        columnValue: 5,
    },
    {
        value: 'medium',
        label: 'Medium',
        columnValue: 2
    },
    {
        value: 'original',
        label: 'Large',
        columnValue: 1
    },
    // {
    //   value: 'disabled',
    //   label: 'Disabled',
    //   disabled: true,
    // },
];

export const inventoryAccessRoleOptions = [
    {
        value: 'manager',
        label: 'Manager',
    },
    {
        value: 'fulfillmentManager',
        label: 'FulfillmentManager',
    },
    {
        value: 'supervisor',
        label: 'Supervisor',
    },
    {
        value: 'approver',
        label: 'Approver',
    },
];

// FOR CHI SURVEY FEEDBACK - FOR 4 QUARTERS IN A YEAR
export const quarterCheckDates = {
    QUARTER1: '0',
    QUARTER2: '3',
    QUARTER3: '6',
    QUARTER4: '9',
}

// For Time Tracker Leave Update Form
export const leaveTypeOptions = [
    {
        value: 'Vacation Time Off',
        label: 'Vacation Time Off'
    },
    {
        value: 'Paid Sick Time Off',
        label: 'Paid Sick Time Off'
    },
    {
        value: 'Time Off Without Pay',
        label: 'Time Off Without Pay'
    },
    {
        value: 'Bereavement',
        label: 'Bereavement'
    },
    {
        value: 'Non Worked Hours',
        label: 'Non Worked Hours'
    },
    {
        value: 'Workers Compensation',
        label: 'Workers Compensation'
    },
    {
        value: 'Jury Duty',
        label: 'Jury Duty'
    },
    {
        value: '22CASPSL B1 (for EHS use)',
        label: '22CASPSL B1 (for EHS use)'
    },
    {
        value: '22CASPSL B2 (for EHS use)',
        label: '22CASPSL B2 (for EHS use)'
    },
    {
        value: 'Exclusion Pay (for EHS use)',
        label: 'Exclusion Pay (for EHS use)'
    },
];

// For Shift Status - View shift details screen
// Status must be one of [created, unassigned, assigned, inprogress, completed, incomplete, swapped, cancelled]
export const shiftStatusTypeOptions = [
    {
        value: 'created',
        label: 'Created'
    },
    {
        value: 'swapped',
        label: 'Swapped'
    },
    {
        value: 'cancelled',
        label: 'Cancelled'
    },
    {
        value: 'clockedIn',
        label: 'Clocked In'
    },
    {
        value: 'clockedOut',
        label: 'Clocked Out'
    },
    {
        value: 'completed',
        label: 'Completed'
    },
];

// For Tesla External Ticket - All Tickets Screen
export const teslaExternalStatusOption = [
    {
        value: 'external',
        label: 'External'
    }, {
        value: 'non-external',
        label: 'Non-external',
    },
];

// For Shift Swap Status - View shift Swap Request List screen
export const shiftSwapRequestStatusOptions = [
    {
        value: 'approved',
        label: `Approved`
    },
    {
        value: 'requested',
        label: 'Requested'
    },
    {
        value: 'rejected',
        label: 'Rejected'
    }
];

// For Jobs - Users - Active Status Search
export const userActiveStatusOption = [
    {
        value: 'active',
        label: 'Active'
    }, {
        value: 'inactive',
        label: 'Inactive'
    },
];

// For Override Availability Review Status - Override Availability Request List Screen
export const overrideAvailabilityRequestStatusOptions = [
    {
        value: 'approved',
        label: `Approved`
    },
    {
        value: 'requested',
        label: 'Requested'
    },
    {
        value: 'rejected',
        label: 'Rejected'
    }
];

export const timezoneOptions = [
    {
        value: 'America/Los_Angeles',
        label: 'Pacific Standard Time(PST)'
    },
    {
        value: 'America/Chicago',
        label: 'Central Standard Time(CST)'
    },
    // {
    //     value: 'Asia/Kolkata',
    //     label: 'India Standard Time(IST)'
    // }
]


export const TEAM_MANAGEMENT_ROUTES = [
    // routes.TEAM_CHECKLIST_LOG,
    // routes.TEAM_CHECKLIST_MANAGER_SUMMARY,
    // routes.TEAM_CHECKLIST_SUPERVISIOR,
    // routes.TEAM_CHECKLIST_TRAINING_LOG
]

export const EDIT = "Edit"

export const VIEW = "View"

export const accessories = [
    { title: "/images/thumbnails/accessories-img-1.png" },
    { title: " /images/thumbnails/accessories-img-2.png" },
    { title: "/images/thumbnails/accessories-img-3.png" },
    { title: "/images/thumbnails/accessories-img-4.png" },
]
export const controlItems = [
    { title: "/images/thumbnails/control_items_img1.png" },
    { title: "/images/thumbnails/control_items_img1.png" },
    { title: "/images/thumbnails/control_items_2.png" },
    { title: "/images/thumbnails/control_items_3.png" }
]

export const COLLECTIONS = {
    collection: "Collection",
    vaccum: "Vaccum",
    restroom: "Restroom",
    utility: "Utility",
    dailyKitting: "Daily kitting",
    accessoris: "Accessoris",
    beyondComplaince: "Beyond complaince",
    controlItems: "Control items",
    pantry: "Pantry",
}

export const sectionsList = {
    "sections": [
        {
            "name": "Collection",
            "sort": 1,
            "checked_count": 0,
            "total_count": 8,
            "lineItems": [
                {
                    "name": "Clean",
                    "is_checked": false,
                    "sort": 1
                },
                {
                    "name": "Liners on a Roll",
                    "is_checked": false,
                    "sort": 2
                },
                {
                    "name": "Nifty Nabber",
                    "is_checked": false,
                    "sort": 3
                },
                {
                    "name": "Empty Proper",
                    "is_checked": false,
                    "sort": 4
                },
                {
                    "name": "Cleaning Cloths",
                    "is_checked": false,
                    "sort": 5
                },
                {
                    "name": "ProDuster & Cover-dated",
                    "is_checked": false,
                    "sort": 6
                },
                {
                    "name": "102 Pacs",
                    "is_checked": false,
                    "sort": 7
                },
                {
                    "name": "(OS1)ian",
                    "is_checked": false,
                    "sort": 8
                }
            ]
        },
        {
            "name": "Vaccum",
            "sort": 2,
            "checked_count": 0,
            "total_count": 8,
            "lineItems": [
                {
                    "name": "Clean",
                    "is_checked": false,
                    "sort": 1
                },
                {
                    "name": "Cord",
                    "is_checked": false,
                    "sort": 2
                },
                {
                    "name": "Vac Station & Log",
                    "is_checked": false,
                    "sort": 3
                },
                {
                    "name": "Attachments",
                    "is_checked": false,
                    "sort": 4
                },
                {
                    "name": "Filters-dated",
                    "is_checked": false,
                    "sort": 5
                },
                {
                    "name": "Empty Proper",
                    "is_checked": false,
                    "sort": 6
                },
                {
                    "name": "Vaccum Fit",
                    "is_checked": false,
                    "sort": 7
                },
                {
                    "name": "(OS1)ian",
                    "is_checked": false,
                    "sort": 8
                }
            ]
        },
        {
            "name": "Restroom",
            "sort": 3,
            "checked_count": 0,
            "total_count": 9,
            "lineItems": [
                {
                    "name": "Clean",
                    "is_checked": false,
                    "sort": 1
                },
                {
                    "name": "Cleaning Cloths",
                    "is_checked": false,
                    "sort": 2
                },
                {
                    "name": "ProDuster & Cover-dated",
                    "is_checked": false,
                    "sort": 3
                },
                {
                    "name": "264N & 201N Pacs",
                    "is_checked": false,
                    "sort": 4
                },
                {
                    "name": "(OS1)ian",
                    "is_checked": false,
                    "sort": 5
                },
                {
                    "name": "Dispenser Keys",
                    "is_checked": false,
                    "sort": 6
                },
                {
                    "name": "Mop Bucket/Wringer",
                    "is_checked": false,
                    "sort": 7
                },
                {
                    "name": "Nifty Nabber",
                    "is_checked": false,
                    "sort": 8
                },
                {
                    "name": "Mob Proper",
                    "is_checked": false,
                    "sort": 9
                }
            ]
        },
        {
            "name": "Utility",
            "sort": 4,
            "checked_count": 0,
            "total_count": 8,
            "lineItems": [
                {
                    "name": "Clean",
                    "is_checked": false,
                    "sort": 1
                },
                {
                    "name": "Mop Bucket/Wringer",
                    "is_checked": false,
                    "sort": 2
                },
                {
                    "name": "Tilt Truck",
                    "is_checked": false,
                    "sort": 3
                },
                {
                    "name": "Mob Proper",
                    "is_checked": false,
                    "sort": 4
                },
                {
                    "name": "Cleaning Cloths",
                    "is_checked": false,
                    "sort": 5
                },
                {
                    "name": "ProDuster & Cover-dated",
                    "is_checked": false,
                    "sort": 6
                },
                {
                    "name": "1082 Pacs",
                    "is_checked": false,
                    "sort": 7
                },
                {
                    "name": "(OS1)ian",
                    "is_checked": false,
                    "sort": 8
                }
            ]
        },
        {
            "name": "Daily kitting",
            "sort": 5,
            "checked_count": 0,
            "total_count": 8,
            "lineItems": [
                {
                    "name": "Clean",
                    "is_checked": false,
                    "sort": 1
                },
                {
                    "name": "Proper Pac Count",
                    "is_checked": false,
                    "sort": 2
                },
                {
                    "name": "Request Forms",
                    "is_checked": false,
                    "sort": 3
                },
                {
                    "name": "Pen/Pencil",
                    "is_checked": false,
                    "sort": 4
                },
                {
                    "name": "Lables Correct",
                    "is_checked": false,
                    "sort": 5
                },
                {
                    "name": "Job Card & Core Map",
                    "is_checked": false,
                    "sort": 6
                },
                {
                    "name": "(OS1) Field Guide",
                    "is_checked": false,
                    "sort": 7
                },
                {
                    "name": "(OS1)ian",
                    "is_checked": false,
                    "sort": 8
                }
            ]
        },
        {
            "name": "Accessoris",
            "sort": 6,
            "checked_count": 0,
            "total_count": 4,
            "lineItems": [
                {
                    "name": "1",
                    "is_checked": false,
                    "sort": 1,
                },
                {
                    "name": "2",
                    "is_checked": false,
                    "sort": 2
                },
                {
                    "name": "3",
                    "is_checked": false,
                    "sort": 3
                },
                {
                    "name": "4",
                    "is_checked": false,
                    "sort": 4
                }
            ]
        },
        {
            "name": "Beyond complaince",
            "sort": 7,
            "checked_count": 0,
            "total_count": 5,
            "lineItems": [
                {
                    "name": "Introduction",
                    "is_checked": false,
                    "sort": 1
                },
                {
                    "name": "Inventory Log",
                    "is_checked": false,
                    "sort": 2
                },
                {
                    "name": "Current SDS",
                    "is_checked": false,
                    "sort": 3
                },
                {
                    "name": "Written Program",
                    "is_checked": false,
                    "sort": 4
                },
                {
                    "name": "Training Log",
                    "is_checked": false,
                    "sort": 5
                }
            ]
        },
        {
            "name": "Control items",
            "sort": 8,
            "checked_count": 0,
            "total_count": 4,
            "lineItems": [
                {
                    "name": "1",
                    "is_checked": false,
                    "sort": 1
                },
                {
                    "name": "2",
                    "is_checked": false,
                    "sort": 2
                },
                {
                    "name": "3",
                    "is_checked": false,
                    "sort": 3
                },
                {
                    "name": "4",
                    "is_checked": false,
                    "sort": 4
                }
            ]
        },
        {
            "name": "Pantry",
            "sort": 9,
            "checked_count": 0,
            "total_count": 4,
            "lineItems": [
                {
                    "name": "Clean",
                    "is_checked": false,
                    "sort": 4
                },
                {
                    "name": "Keys Secured",
                    "is_checked": false,
                    "sort": 3
                },
                {
                    "name": "Paper Products",
                    "is_checked": false,
                    "sort": 2
                },
                {
                    "name": "Organized Properly",
                    "is_checked": false,
                    "sort": 1
                }
            ]
        }
    ]
}

export const SHIFTS = {
    0: "First Shift",
    1: "Second Shift",
    2: "Third Shift"
}

export const BP_AREAS = [
    { value: "Restrooms", label: "Restrooms" },
    { value: "Urinals", label: "Urinals" },
    { value: "Toilets", label: "Toilets" },
    { value: "Sinks", label: "Sinks" },
    { value: "Fountains", label: "Fountains" },
    { value: "Showers", label: "Showers" },
    { value: "Carpet sq. ft.", label: "Carpet sq. ft." },
    { value: "Tile sq. ft.", label: "Tile sq. ft." },
    { value: "Common Area sq. ft.", label: "Common Area sq. ft." },
    { value: "Total rooms", label: "Total rooms" },
    { value: "Stairwells", label: "Stairwells" },
    { value: "Room rotation", label: "Room rotation" },
    { value: "Storage", label: "Storage" },
    { value: "Equipment", label: "Equipment" },
    { value: "Other-Terazzo", label: "Other-Terazzo" },
    { value: "Area sq. ft.", label: "Area sq. ft." },
];

export const teamChecklistAccess = ["checklist", "checklist_log", "checklist_manager", "checklist_supervisor_summary", "training_log"]

export const calTotalAreas = {
    lineItems: [
        { key: "Restrooms", value: "lineItems.no_of_restrooms" },
        { key: "Urinals", value: "lineItems.no_of_urinals" },
        { key: "Toilets", value: "lineItems.no_of_toilets" },
        { key: "Sinks", value: "lineItems.no_of_sinks" },
        { key: "Fountains", value: "lineItems.no_of_fountains" },
        { key: "Showers", value: "lineItems.no_of_showers" },
        { key: "Stairwells", value: "lineItems.no_of_stairwells" },
    ],
    surveyItems: [
        { key: "Carpet sq. ft.", value: "surveyItems.aprox_sqft_carpet_floor" },
        { key: "Tile sq. ft.", value: "surveyItems.aprox_sqft_tile_floor" },
        { key: "Other-Terazzo", value: "surveyItems.aprox_sqft_other_floor" },
    ]
}