import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { Input } from '../../../UI/InputElement/InputElement';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import { convertTo12HourFormat, getHoursAndMinutesFormat_V1, getHoursAndMinutesFormat_V2, makeFirstLetterCapital } from '../../../../../utility/utility';
import { newLeaveFormValidator as validate } from '../../../../../utility/validator/validator';
import NewLeaveFormSkeleton from './NewLeaveFormSkeleton';
import moment from "moment";
import cloneDeep from 'clone-deep';
import { Button, InputNumber, Space } from 'antd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DateTimeField } from '../../../UI/DateTimeField/DateTimeField';

import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { Descriptions } from 'antd';
import { leaveTypeOptions } from '../../../../../utility/constants/constants';
import { tzDateConverterTool_V3, tzInbetweenDaysFinder_V2, tzTimeConversionTool_V2 } from '../../../../../utility/TimezoneOperations/timezoneUtility';

let NewLeaveForm = (props) => {

    let formValues = {};
    formValues = props && props.formStates && props.formStates.leaveUpdate ? props.formStates.leaveUpdate : {};
    const [triggerTimerModal, setTriggerTimerModal] = useState(false);

    // For converting and setting up time duration
    if (props.formStates && props.formStates.leaveUpdate) {

        if (props.formStates.leaveUpdate.day_coverage == 'false') {

            if ((props.formStates.leaveUpdate.start_time) && (props.formStates.leaveUpdate.end_time)) {

                if ((props.formStates.leaveUpdate.start_time.length > 0) && (props.formStates.leaveUpdate.end_time.length > 0)) {
                    let start = props.formStates.leaveUpdate.start_time && props.formStates.leaveUpdate.start_time.includes('(') ? props.formStates.leaveUpdate.start_time.split(' ')[0] : props.formStates.leaveUpdate.start_time;
                    let end = props.formStates.leaveUpdate.end_time && props.formStates.leaveUpdate.end_time.includes('(') ? props.formStates.leaveUpdate.end_time.split(' ')[0] : props.formStates.leaveUpdate.end_time;
                    props.formStates.leaveUpdate.time_duration = getHoursAndMinutesFormat_V1(start, end);
                }

            }

        } else if (props.formStates.leaveUpdate.day_coverage == 'true') {

            if ((props.formStates.leaveUpdate.start_date) && (props.formStates.leaveUpdate.end_date)) {
                props.formStates.leaveUpdate.time_duration = tzInbetweenDaysFinder_V2(props.formStates.leaveUpdate.start_date, props.formStates.leaveUpdate.end_date);
            }

        }

    }

    useEffect(() => {
        if (formValues) {
            if (formValues.day_coverage) {
                if (formValues.day_coverage == 'false' && props.isEditLeaveRequestForm == true) {
                    setTriggerTimerModal(true);
                } else if (formValues.day_coverage == 'true' && props.isEditLeaveRequestForm == true) {
                    setTriggerTimerModal(false);
                }
            }
            // // for clearing the end date whenever the start date changed but still we doidn't get any correct solution for this. so holding this....
            // if (formValues.start_date && props.isEditLeaveRequestForm == true) {
            //     delete props.formStates.leaveUpdate.end_date;
            // }
        }

    }, [props.formStates && props.formStates.leaveUpdate])

    const fullDayChoosed = () => {
        if (props.formStates && props.formStates.leaveUpdate) {
            delete props.formStates.leaveUpdate.time_duration
            delete props.formStates.leaveUpdate.start_time
            delete props.formStates.leaveUpdate.end_time
        }
        setTriggerTimerModal(false);
    };
    const partialDayChoosed = () => {
        setTriggerTimerModal(true);
        delete props.formStates.leaveUpdate.time_duration
        delete props.formStates.leaveUpdate.end_date

    };

    const imageTypes = ['.jpg', '.jpeg', '.png', '.gif'];

    const getJobAccountSitesList = (jobAccountSitesList) => {
        if (props.jobAccountSitesList && props.jobAccountSitesList.length > 0) {
            return jobAccountSitesList && jobAccountSitesList.map((data) => {
                return {
                    value: data.id,
                    label: data.name
                }
            })
        } else {
            return []
        }
    };

    const getDepartmentList = (department_list) => {
        if (props.department_list && props.department_list.length > 0) {
            return department_list && department_list.map((data) => {
                return {
                    value: data.id,
                    label: data.name
                }
            })
        } else {
            return []
        }
    };

    const getLeaveUpdateManagersList = (leaveUpdate_managersList) => {
        if (props.leaveUpdate_managersList && props.leaveUpdate_managersList.length > 0) {
            return leaveUpdate_managersList && leaveUpdate_managersList.map((data) => {
                return {
                    value: data.id,
                    label: data.first_name + ' ' + data.last_name,
                    email: data.email
                }
            })
        } else {
            return []
        }
    };

    const [jobSitesList, setJobSitesList] = useState(getJobAccountSitesList(props.jobAccountSitesList));
    const [departmentList, setDepartmentList] = useState(getDepartmentList(props.department_list));
    const [managerList, setManagerList] = useState(getLeaveUpdateManagersList(props.leaveUpdate_managersList));

    useEffect(() => {
        setJobSitesList(getJobAccountSitesList(props.jobAccountSitesList))
    }, [props.jobAccountSitesList])

    useEffect(() => {
        setDepartmentList(getDepartmentList(props.department_list))
    }, [props.department_list])

    useEffect(() => {
        setManagerList(getLeaveUpdateManagersList(props.leaveUpdate_managersList))
    }, [props.leaveUpdate_managersList])

    const [imageAttachments, setImageAttachments] = useState([props.singleLeaveRequestData && props.singleLeaveRequestData.attachments && props.singleLeaveRequestData.attachments.map((url, index) => {debugger
        return url.photo_urls?.small;
    })]);

    const handlePhotoPathDelete = (index) => {
        const newAttachments = [...imageAttachments];
        newAttachments.splice(index, 1);
        setImageAttachments(newAttachments);
    };

    const noOptionsMessage = (a, b) => {
        return 'No options';
    }

    const { handleSubmit, pristine, reset, submitting, error, submitFailed } = props;

    if (props.isUpdateLeaveRequestForm) {
        return (
            <NewLeaveFormSkeleton />
        )
    } else {
        return (
            <>
                <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                    <div className="inspection_content_sec appcontent_Inner">
                        <div className="container-fluids">
                            <div className="quote_form ticket_form" style={{ maxWidth: '700px' }}>
                                <div className="row">
                                    <div className="col-sm-12 text-center"> <h2 className="md_title" >{props.isEditLeaveRequestForm ? "Update Leave Request" : "New Leave Request"}</h2></div>
                                </div>
                                <div className="addQuote_form">
                                    <form className="form_content" onSubmit={handleSubmit} >
                                        <div className="cst_tab_content">

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> Leave Type <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="leaveUpdate[type]" dataTest="select_leave_type" type="inputSelect" options={leaveTypeOptions} noOptionsMessage={noOptionsMessage} placeholder="Please select" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="checkbox_block radius_checkbox" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '5px' }}>
                                                        <Input name="leaveUpdate[day_coverage]" labelClass="custom-control-label" type="radio" onClick={fullDayChoosed} forLable={"fullDay"} value={"true"} label={"Full-Day"} className="custom-control-input" id="fullDay" />
                                                        <Input name="leaveUpdate[day_coverage]" labelClass="custom-control-label" type="radio" onClick={partialDayChoosed} forLable={"partialDay"} value={"false"} label={"Partial-Day"} className="custom-control-input" id="partialDay" />
                                                    </div>
                                                    <div className="form-group">
                                                        <Input
                                                            name="leaveUpdate[time_duration]"
                                                            dataTest=""
                                                            type="numberWithIncDec"
                                                            className="form-control input-modifier"
                                                            placeholder={`${props.formStates && props.formStates.leaveUpdate && props.formStates.leaveUpdate.time_duration ? props.formStates.leaveUpdate.time_duration : 'Time Duration'}`}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {(triggerTimerModal == true) && (
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form_title fw-bold"> Start Time <sup className="cst_sup_txt text_danger">*</sup></label>
                                                            <DateTimeField name="leaveUpdate[start_time]" timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="startTime" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form_title fw-bold"> End Time <sup className="cst_sup_txt text_danger">*</sup></label>
                                                            <DateTimeField name="leaveUpdate[end_time]" timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="endTime" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> Start Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <div className="input-group cst_input_group col-lg-14 pl-0">
                                                            <div style={{ width: '100%' }}>
                                                                <Input
                                                                    name="leaveUpdate[start_date]"
                                                                    type="date"
                                                                    datePickerType={"react-datepicker"}
                                                                    minDate={new Date()}
                                                                    dateFormat="MM-dd-yyyy"
                                                                    className="form-control input-modifier"
                                                                    placeholder="Select Date"
                                                                />
                                                            </div>
                                                            <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> End Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <div className="input-group cst_input_group col-lg-14 pl-0">
                                                            <div style={{ width: '100%' }}>
                                                                <Input
                                                                    name="leaveUpdate[end_date]"
                                                                    type="date"
                                                                    datePickerType={"react-datepicker"}
                                                                    minDate={props && props.formStates && props.formStates.leaveUpdate && props.formStates.leaveUpdate.start_date ? new Date(props && props.formStates && props.formStates.leaveUpdate && props.formStates.leaveUpdate.start_date) : new Date()}
                                                                    dateFormat="MM-dd-yyyy"
                                                                    className="form-control input-modifier"
                                                                    placeholder={triggerTimerModal == true ? moment(props.formStates && props.formStates.leaveUpdate && props.formStates.leaveUpdate.start_date).format('MM-D-YYYY') : "Select Date"}
                                                                    disabled={triggerTimerModal == true ? true : false}
                                                                />
                                                            </div>
                                                            <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <div className="group">
                                                        <label className="form_title fw-bold"> Additional Information </label>
                                                        <Input name="leaveUpdate[info]" type="textarea" className="form-control textarea_modifier" rows="5" placeholder="Add your comments" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <div className="group">
                                                        <label className="form_title fw-bold"> Attachments <img src="/images/icons/attachment_icon.png" alt="Icon" /> </label>
                                                        <div className="addQuote_form mb-2 antdImgUpload" style={{ border: 'dashed', borderColor: '#71002f73', borderWidth: '3px' }}>
                                                            <Upload
                                                                listType="picture-card"
                                                                fileList={props.storedFileList}
                                                                onPreview={props.handlePreview}
                                                                onChange={props.handleChange}
                                                                // maxCount={10}
                                                                multiple
                                                                showUploadList={{ showErrorList: false, removeIcon: () => null, }}
                                                                accept={imageTypes.join(',')}
                                                                toolTipVisible={false}
                                                                supportServerRender={false}
                                                            >
                                                                <div> <PlusOutlined /> <div style={{ marginTop: 8 }}> Upload </div> </div>
                                                            </Upload>
                                                        </div>
                                                        <Modal open={props.previewFileQuery.previewOpen} title={props.previewFileQuery.previewTitle} footer={null} onCancel={props.handleCancel}>
                                                            <img alt="example" style={{ width: '100%' }} src={props.previewFileQuery.previewImage} />
                                                        </Modal>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <div className="group">
                                                        <div className="leaveRequest_attaches">
                                                            {props.singleLeaveRequestData && props.singleLeaveRequestData.attachments && props.singleLeaveRequestData.attachments.map((url, index) => (
                                                                <div key={index} className="leaveRequest_attaches-item">
                                                                    {/* <span className="leaveRequest_attaches-delete-icon" onClick={() => handlePhotoPathDelete(index)}>X</span> */}
                                                                    <img key={index} src={url.photo_urls?.small} alt={`Attachment ${index + 1}`} />
                                                                </div>

                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> Job <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="leaveUpdate[account_id]" dataTest="select_leave_type" type="inputSelect" onChange={(e) => props.getLeaveUpdateManagersList({ leaveManagers: true, accountId: e.value })} options={jobSitesList} placeholder="Please select" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> Departments <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="leaveUpdate[department_id]" dataTest="select_leave_type" type="inputSelect" options={departmentList} noOptionsMessage={noOptionsMessage} placeholder="Please select" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> Manager Name <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input
                                                            name="leaveUpdate[manager_id]"
                                                            dataTest="select_leave_type"
                                                            type="inputSelect"
                                                            onChange={(e) => {
                                                                if (formValues.manager_email.length >= 0) {
                                                                    formValues.manager_email = e.email;
                                                                }
                                                            }}
                                                            options={managerList}
                                                            noOptionsMessage={noOptionsMessage}
                                                            placeholder="Please select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> Manager Email <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <input
                                                            name="leaveUpdate[manager_email]"
                                                            dataTest=""
                                                            type="text"
                                                            className="form-control input-modifier"
                                                            placeholder={`${formValues && formValues.manager_email == '' ? "Select manager" : formValues.manager_email}`}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="modal-footer">
                                            <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} onClick={() => { props.handleStaticTriggerClick(); }}>{props.isLoading ? "" : "Submit"}</button>
                                            <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" onClick={() => { props.onCancelClicked() }}>Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </>
        );
    }

};

const mapStateToProps = (state, props) => {

    let leaveUpdate = {};

    // For fetching Leave Type
    const getLeaveType = (user) => {
        let fetchedLeaveType = null;
        leaveTypeOptions.map((value) => {
            if (value.value == user) {
                fetchedLeaveType = {
                    value: value.value,
                    label: value.label
                }
            }
        })
        return fetchedLeaveType;
    }

    const convertStartEndTimeFormat = (time) => {
        let fetchedTimeValue = null;

        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }

        fetchedTimeValue = (time[0] + ':' + time[2] + ' ' + time[5]).toString(); // return adjusted time or original string

        return fetchedTimeValue;
    }

    // For fetching Manager Name
    const getManagerObject = (user) => {
        let fetchedManagerObject = null;
        fetchedManagerObject = {
            value: user.id,
            label: makeFirstLetterCapital(user.first_name) + ' ' + makeFirstLetterCapital(user.last_name)
        }
        return fetchedManagerObject;
    };

    // For fetching Job Name
    const getJobObject = (user) => {
        let fetchedJobObject = null;
        fetchedJobObject = {
            value: user.id,
            label: makeFirstLetterCapital(user.name)
        }
        return fetchedJobObject;
    };

    // For fetching Department Name
    const getDepartmentObject = (user) => {
        let fetchedDepartmentObject = null;
        fetchedDepartmentObject = {
            value: user.id,
            label: makeFirstLetterCapital(user.name)
        }
        return fetchedDepartmentObject;
    };

    if (isEmpty(state.TimeTrackerModReducer.singleLeaveRequestData)) {

        leaveUpdate = {
            start_time: '',
            end_time: '',
            time_duration: '',
            manager_email: '',
            day_coverage: 'true'
        }

    } else {

        let catchedFormValues = state.form.newLeaveForm.values.leaveUpdate;
        const value = cloneDeep(state.TimeTrackerModReducer.singleLeaveRequestData);

        if (props.setStaticTrigger) {

            if (catchedFormValues) {

                leaveUpdate = {
                    type: catchedFormValues.type.value == value.type ? getLeaveType(value.type) : catchedFormValues.type,
                    day_coverage: catchedFormValues.day_coverage == value.day_coverage ? value.day_coverage == 'full' ? 'true' : 'false' : catchedFormValues.day_coverage == 'true' ? 'true' : 'false',
                    time_duration: catchedFormValues.time_duration == value.time_duration ? getHoursAndMinutesFormat_V2(value.time_duration) : catchedFormValues.time_duration,
                    start_time: catchedFormValues.start_time == value.start_time ? value.start_time : catchedFormValues.start_time,
                    end_time: catchedFormValues.end_time == value.end_time ? value.end_time : catchedFormValues.end_time,
                    start_date: catchedFormValues.start_date == value.start_date ? value.start_date : catchedFormValues.start_date,
                    end_date: catchedFormValues.end_date == value.end_date ? value.end_date : catchedFormValues.end_date,
                    info: catchedFormValues.info == value.info ? value.info : catchedFormValues.info,
                    account_id: catchedFormValues.account_id.value && value.account.id ? getJobObject(value.account) : catchedFormValues.account_id,
                    department_id: catchedFormValues.department_id.value == value.department.id ? getDepartmentObject(value.department) : catchedFormValues.department_id,
                    manager_id: catchedFormValues.manager_id.value == value.manager.id ? getManagerObject(value.manager) : catchedFormValues.manager_id,
                    manager_email: catchedFormValues.manager_email == value.manager.email ? value.manager.email : catchedFormValues.manager_email,
                }

            }

        } else {

            leaveUpdate = {
                type: value.type ? getLeaveType(value.type) : null,
                day_coverage: value && value.day_coverage && value.day_coverage == 'full' ? 'true' : 'false',
                time_duration: value && value.time_duration ? getHoursAndMinutesFormat_V2(value.time_duration) : '',
                start_time: value && value.start_time ? tzTimeConversionTool_V2(value.start_time, value.start_date) : '',
                end_time: value && value.end_time ? tzTimeConversionTool_V2(value.end_time, value.start_date) : '',
                start_date: tzDateConverterTool_V3(value.start_date),
                end_date: value && value.day_coverage && value.day_coverage == 'full' ? tzDateConverterTool_V3(value.end_date) : '',
                info: value && value.info ? value.info : '',
                account_id: value && value.account ? getJobObject(value.account) : '',
                department_id: value && value.department ? getDepartmentObject(value.department) : '',
                manager_id: value && value.manager ? getManagerObject(value.manager) : '',
                manager_email: value && value.manager ? value.manager.email : '',
            }

        }

    }
    return {
        initialValues: { leaveUpdate },
        formStates: getFormValues('newLeaveForm')(state)
    }

}

NewLeaveForm = reduxForm({
    form: 'newLeaveForm',
    validate,
    enableReinitialize: true
})(NewLeaveForm);

NewLeaveForm = connect(mapStateToProps)(NewLeaveForm)

export default NewLeaveForm;