import cloneDeep from 'clone-deep';
import history from '../../../router/history';
import { routes, userRoles } from '../../../utility/constants/constants';
import { TimeTrackerModTypes } from './TimeTrackerModTypes';
import * as API from '../../../API/TimeTrackerModApi';
import storage from '../../../utility/storage';
import SuccessAlert from '../../../utility/successAlert/successAlert';
import errorHandler from '../../../utility/errorHandler/errorHandler';
import { closeModel, convertDateFormatV5, makeFirstLetterCapital } from '../../../utility/utility';
import supportErrorHandler from '../../../utility/errorHandler/supportErrorHandler';
import { signInClicked } from '../AuthActions/authAction';
import InfoAlert from '../../../utility/infoAlert/infoAlert';
import { tzUTCDateTimeConverterTool_V1 } from '../../../utility/TimezoneOperations/timezoneUtility';
var momentTZ = require('moment-timezone');
const refreshPage = () => { window.location.reload(false); }

export const resetTimeTrackerObjects = () => {
    return {
        type: TimeTrackerModTypes.RESET_TIME_TRACKER_OBJECTS,
    };
};

export const resetSearchOrResetTimeTracker = () => {
    return {
        type: TimeTrackerModTypes.RESET_SEARCH_OR_RESET,
    };
};

export const handleFrequentTimeTracker_openDeleteModal = (deleteTimeTrackerObject) => {
    return {
        type: TimeTrackerModTypes.HANDLE_FREQUENT_TIME_TRACKER_OPEN_DELETE_MODAL,
        payload: deleteTimeTrackerObject,
    };
};

export const handleFrequentTimeTracker_closeDeleteModal = () => {
    return {
        type: TimeTrackerModTypes.HANDLE_FREQUENT_TIME_TRACKER_CLOSE_DELETE_MODAL,
    };
};

export const handleFrequentTimeTracker_openModal = () => {
    return {
        type: TimeTrackerModTypes.HANDLE_FREQUENT_TIME_TRACKER_OPEN_MODAL,
    };
};

export const handleFrequentTimeTracker_closeModal = () => {
    return {
        type: TimeTrackerModTypes.HANDLE_FREQUENT_TIME_TRACKER_CLOSE_MODAL,
    };
};

export const handleCancelClickAction = () => {
    return {
        type: TimeTrackerModTypes.HANDLE_CANCEL_CLICK_ACTION,
    };
};

export const handleStaticTriggerClick = () => {
    return {
        type: TimeTrackerModTypes.HANDLE_STATIC_TRIGGER_CLICK,
    };
};

export const handleShiftPresencePanel_openModal = (dataItemObj) => {
    return {
        type: TimeTrackerModTypes.HANDLE_SHIFT_PRESENCE_PANEL_OPEN_MODAL,
        payload: dataItemObj,
    };
};

export const handleShiftPresencePanel_closeModal = () => {
    return {
        type: TimeTrackerModTypes.HANDLE_SHIFT_PRESENCE_PANEL_CLOSE_MODAL,
    };
};

// FOR FACE RECOGNITION
export const getScannedFaceImagePresignUrl = (scannedImageBody, arrayBuffer, extention) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_SCANNED_FACE_IMAGE_PRESIGNED_URL,
            payload: API.getScannedFaceImagePresignUrl(extention)
                .then((response) => {
                    let presigned_url = null
                    if (response.data.success) {
                        if (response.data.presigned_urls && response.data.presigned_urls[0]) {
                            presigned_url = response.data.presigned_urls[0].presigned_url;
                            scannedImageBody.data.photo_path = response.data.presigned_urls[0].attachment_path;
                        }
                    }
                    dispatch({
                        type: TimeTrackerModTypes.UPLOAD_IMAGE_TO_S3,
                        payload: API.uploadImageToS3(presigned_url, arrayBuffer)
                            .then((response) => {
                                dispatch(getRecognizedFaceAccountCreds(scannedImageBody));
                            })
                            .catch((error) => {
                                console.log(error);
                            }),
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};
// FOR FACE RECOGNITION
export const getRecognizedFaceAccountCreds = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_RECOGNIZED_FACE_ACCOUNT_CREDS,
            payload: API.getRecognizedFaceAccountCreds(params)
                .then((response) => {
                    debugger
                    let setData = { user: { email: '', password: '' } };
                    if (response.data.success == true) {
                        SuccessAlert('Face were matched');
                        if (response.data.user && response.data.user.user) {
                            setData.user.email = response.data.user.user.employee_id.trim();
                            setData.user.password = response.data.user.user.dob_password.trim();
                            dispatch(signInClicked(setData));
                            setTimeout(() => {
                                refreshPage();
                            }, 1000);
                            return response.data;
                        }
                    } else if (response.data.success == false) {
                        setTimeout(() => {
                            InfoAlert('Redirecting to Scanner page');
                            setTimeout(() => {
                                refreshPage();
                            }, 2500);
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setTimeout(() => {
                        InfoAlert('Redirecting to Scanner page');
                        setTimeout(() => {
                            refreshPage();
                        }, 2500);
                    }, 1000);
                    console.log(error);
                }),
        });
    };
}

export const getUsersListByTheirRoles = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_USERS_LIST_BY_THEIR_ROLES,
            payload: API.getUsersListByTheirRoles(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            usersListBySelectedRole: response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getJobAccountSitesList = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_JOB_ACCOUNT_SITES_LIST,
            payload: API.getJobAccountSitesList(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            jobAccountSitesList: response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

// Getting all users list except Client and Frontline users
export const getLeaveUpdateManagersList = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_LEAVEUPDATE_MANAGERS_LIST,
            payload: API.getLeaveUpdateManagersList(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            leaveUpdate_managersList: response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getSkillSetList = (data) => {debugger;
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_SKILLSET_LIST,
            payload: API.getSkillSetList(data)
                .then((response) => {
                    if (response.data.success) {
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        })
    }
}

export const createSkillSet = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.CREATE_SKILLSET,
            payload: API.createSkillSet(data)
                .then((response) => {
                    if (response.data.success) {
                        let createdSkillSetData = cloneDeep(response.data.data);
                        closeModel();
                        SuccessAlert(`${makeFirstLetterCapital(createdSkillSetData.name)} skillset created successfully`);
                        dispatch(getSkillSetList({ activeStatus: createdSkillSetData.active_status }));
                        return response.data;
                    } else if (!(response.data.success)) {
                        closeModel();
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        })
    }
}

export const editSkillSetClicked = (skillset) => {
    return {
        type: TimeTrackerModTypes.EDIT_SKILLSET_CLICKED,
        payload: skillset,
    };
};

export const editSkillSet = (data, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.EDIT_SKILLSET,
            payload: API.editSkillSet(data, id)
                .then((response) => {
                    if (response.data.success) {
                        let updatedSkillSetData = cloneDeep(response.data.data);
                        closeModel();
                        SuccessAlert(`${makeFirstLetterCapital(updatedSkillSetData.name)} skillset updated successfully`);
                        dispatch(getSkillSetList({ activeStatus: updatedSkillSetData.active_status }));
                        return response.data;
                    } else if (!(response.data.success)) {
                        closeModel();
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        })
    }
}

export const deleteSkillSet = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.DELETE_SKILLSET,
            payload: API.deleteSkillSet(data)
                .then((response) => {
                    if (response.data.success) {
                        let skillSet_list = cloneDeep(getState().TimeTrackerModReducer.skillSet_list);
                        dispatch(getSkillSetList({ activeStatus: skillSet_list[0].active_status }));
                        return { data };
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

export const getDepartmentList = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_DEPARTMENT_LIST,
            payload: API.getDepartmentList(data)
                .then((response) => {
                    if (response.data.success) {
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        })
    }
}

export const createDepartment = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.CREATE_DEPARTMENT,
            payload: API.createDepartment(data)
                .then((response) => {
                    if (response.data.success) {
                        let createdDepartmentData = cloneDeep(response.data.data);
                        closeModel();
                        SuccessAlert(`${makeFirstLetterCapital(createdDepartmentData.name)} department created successfully`);
                        dispatch(getDepartmentList({ activeStatus: createdDepartmentData.active_status }));
                        return response.data;
                    } else if (!(response.data.success)) {
                        closeModel();
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        })
    }
}

export const editDepartmentClicked = (department) => {
    return {
        type: TimeTrackerModTypes.EDIT_DEPARTMENT_CLICKED,
        payload: department,
    };
};

export const editDepartment = (data, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.EDIT_DEPARTMENT,
            payload: API.editDepartment(data, id)
                .then((response) => {
                    if (response.data.success) {
                        let updatedDepartmentData = cloneDeep(response.data.data);
                        closeModel();
                        SuccessAlert(`${makeFirstLetterCapital(updatedDepartmentData.name)} department updated successfully`);
                        dispatch(getDepartmentList({ activeStatus: updatedDepartmentData.active_status }));
                        return response.data;
                    } else if (!(response.data.success)) {
                        closeModel();
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        })
    }
}

export const deleteDepartment = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.DELETE_DEPARTMENT,
            payload: API.deleteDepartment(data)
                .then((response) => {
                    if (response.data.success) {
                        let department_list = cloneDeep(getState().TimeTrackerModReducer.department_list);
                        dispatch(getDepartmentList({ activeStatus: department_list[0].active_status }));
                        return { data };
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

export const getAllLeaveList = (params, values) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_ALL_LEAVE_LIST,
            payload: API.getAllLeaveList(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            leavesList: response.data.data,
                            leavesList_total_pages: response.data.totalPages,
                            leavesList_no_of_rows: response.data.limit,
                            total_leavesList_count: response.data.total,
                            leavesList_curPage: params.page - 1,
                            searchValues: values
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        })
    }
}

export const getSingleLeaveRequest = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_SINGLE_LEAVE_REQUEST,
            payload: API.getSingleLeaveRequest(id)
                .then((response) => {
                    if (response.data.success) {
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        })
    }
}

export const leaveListPageClicked = (data) => {
    return {
        type: TimeTrackerModTypes.LEAVE_LIST_PAGE_CLICKED,
        payload: data,
    };
};

export const deleteLeave = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.DELETE_LEAVE,
            payload: API.deleteLeave(data)
                .then((response) => {
                    if (response.data.success) {
                        let limit = cloneDeep(getState().TimeTrackerModReducer.leavesList_no_of_rows);
                        SuccessAlert('Leave deleted successfully');
                        dispatch(getAllLeaveList({ page: 1, limit }));
                        return { data };
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

export const getCreateLeaveAttachmentPresignUrl = (leaveUpdate, arrayBuffer, extentions) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_CREATE_LEAVE_ATTACHMENT_PRESIGN_URL,
            payload: API.getCreateLeaveAttachmentPresignUrl(leaveUpdate, extentions)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                    leaveUpdate.data.attachments = [];
                    response.data.presigned_urls.forEach((p, i) => {
                        leaveUpdate.data.attachments[i] = {}
                        leaveUpdate.data.attachments[i] = p.attachment_path
                    });
                    response.data.presigned_urls.map((p, i) => {
                        dispatch({
                            type: TimeTrackerModTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        dispatch(createFrontlinerLeaveRequest(leaveUpdate));
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const createFrontlinerLeaveRequest = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.CREATE_FRONTLINER_LEAVE_REQUEST,
            payload: API.createFrontlinerLeaveRequest(data)
                .then((response) => {
                    if (response.data.success) {
                        history.push(routes.LEAVE_LIST);
                        SuccessAlert('Leave Request Successfully Created');
                        return response;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getEditLeaveAttachmentPresignUrl = (leaveUpdate, id, arrayBuffer, extentions) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_EDIT_LEAVE_ATTACHMENT_PRESIGN_URL,
            payload: API.getEditLeaveAttachmentPresignUrl(leaveUpdate, extentions)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                   leaveUpdate.data.attachments = [];
                    response.data.presigned_urls.forEach((p, i) => {
                        // leaveUpdate.data.attachments[i] = {}
                        leaveUpdate.data.attachments[i] = p.attachment_path
                    });
                    response.data.presigned_urls.map((p, i) => {
                        dispatch({
                            type: TimeTrackerModTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        dispatch(editFrontlinerLeaveRequest(leaveUpdate, id));
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const editFrontlinerLeaveRequest = (data, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.EDIT_FRONTLINER_LEAVE_REQUEST,
            payload: API.editFrontlinerLeaveRequest(data, id)
                .then((response) => {
                    if (response.data.success) {
                        history.push(routes.LEAVE_LIST);
                        SuccessAlert('Leave Request Successfully Updated');
                        return response;
                    }else{
                        return response
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getCloseLeaveRequest = (request, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_CLOSE_LEAVE_REQUEST,
            payload: API.getCloseLeaveRequest(request, id)
                .then((response) => {
                    if (response.data.success) {
                        history.push(routes.LEAVE_LIST);
                        if (request.data.status == 'approved') {
                            SuccessAlert('Leave Request Approved Successfully');
                        } else {
                            SuccessAlert('Leave Request Rejected');
                        }
                        return response;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const previewSingleScheduledTaskClicked = (ticket) => {
    let windowLocationValue = '';
    if (window.location.href && window.location.href.includes('.com')) {
        windowLocationValue = window.location.href.split('//')[1].split('.com')[1]
    } else {
        windowLocationValue = window.location.href.substring(21)
    }
    history.push({ pathname: `/scheduled-tasks/sub-ticket/${ticket.id}`, state: { storedRoute: windowLocationValue && windowLocationValue.length > 0 ? windowLocationValue : null } });
    storage.set('scheduleTaskTitle', ticket.name)
    storage.set('scheduleTaskTickekId', ticket.id)
    return {
        type: TimeTrackerModTypes.PREVIEW_SINGLE_SCHEDULED_TASK,
        payload: ticket
    };
};

export const getScheduledTask = (params, values) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_ALL_SCHEDULED_TASK,
            payload: API.getScheduledTask(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            scheduledTaskList: response.data.data,
                            scheduledTaskList_total_pages: response.data.totalPages,
                            scheduledTaskList_no_of_rows: response.data.limit,
                            total_scheduledTaskList_count: response.data.total,
                            scheduledTaskList_curPage: params.page - 1,
                            searchValues: values
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const scheduledTaskListPageClicked = (data) => {
    return {
        type: TimeTrackerModTypes.SCHEDULED_TASK_LIST_PAGE_CLICKED,
        payload: data,
    };
};

export const getSubScheduledTask = (params, values, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_ALL_SUB_SCHEDULED_TASK,
            payload: API.getSubScheduledTask(params, id)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            subScheduledTaskList: response.data.data,
                            subScheduledTaskList_total_pages: response.data.totalPages,
                            subScheduledTaskList_no_of_rows: response.data.limit,
                            total_subScheduledTaskList_count: response.data.total,
                            subScheduledTaskList_curPage: params.page - 1,
                            searchValues: values
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const subScheduledTaskListPageClicked = (data) => {
    return {
        type: TimeTrackerModTypes.SUB_SCHEDULED_TASK_LIST_PAGE_CLICKED,
        payload: data,
    };
};

export const getSubScheduledTaskPreview = (id, ticketId) => (dispatch) =>
    dispatch({
        type: TimeTrackerModTypes.GET_PREVIEW_SUB_SCHEDULED_TASK,
        payload: API.getSubScheduledTaskPreview(id, ticketId)
            .then((response) => {
                storage.set('ticketId', response.data.data.id);
                return response.data.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getCompanyList = (body) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_COMPANY_LIST,
            payload: API.getCompanyList(body)
                .then((response) => {
                    if (response.data.success) {
                        return response.data.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const newScheduledTaskTicket = (body) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.NEW_SCHEDULED_TASK_TICKET,
            payload: API.newScheduledTaskTicket(body)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Scheduled Task created successfully')
                        history.push(routes.SCHEDULED_TASKS);
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getJobsList = (data) => (dispatch) =>
    dispatch({
        type: TimeTrackerModTypes.GET_JOB_LIST,
        payload: API.getJobsList(data)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });


export const getScheduledTaskEvents = (startDate, endDate, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_SCHEDULED_TASK_EVENTS,
            payload: API.getScheduledTaskEvents(startDate, endDate, id)
                .then((response) => {
                    if (response.data.success) {
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getcommentsAttachmentPresignUrl = (comments, extentions, arrayBuffer, updateCommentId, setEditCommentObject) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.COMMENT_PRESIGN_URL,
            payload: API.getcommentsAttachmentPresignUrl(comments, extentions)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                    comments.data.attachment = [];
                    response.data.presigned_urls.forEach((p, i) => {
                        comments.data.attachment[i] = {}
                        comments.data.attachment[i] = p.attachment_path
                    });
                    response.data.presigned_urls.map((p, i) => {
                        dispatch({
                            type: TimeTrackerModTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        if (updateCommentId) {
                                            if (comments.data.ticket_id) {
                                                delete comments.data.ticket_id
                                            }
                                            if (comments.data.user_id) {
                                                delete comments.data.user_id
                                            }
                                            dispatch(updateComment(comments, updateCommentId, setEditCommentObject));
                                        } else {
                                            if (comments.data.removeAttachmentIds) {
                                                delete comments.data.removeAttachmentIds
                                            }
                                            dispatch(addComment(comments));
                                        }

                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};


export const addComment = (data) => {
    return (dispatch, getState) => {
        let ticket = cloneDeep(getState().TimeTrackerModReducer.ticket);
        dispatch({
            type: TimeTrackerModTypes.ADD_COMMENT,
            payload: API.addComment(data)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Comment Added Successfully');
                        ticket.comments.push(response.data.data)
                        return {
                            updatedTicket: ticket
                        };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const updateComment = (data, id, setEditCommentObject) => {
    return (dispatch, getState) => {
        let ticket = cloneDeep(getState().TimeTrackerModReducer.ticket);
        dispatch({
            type: TimeTrackerModTypes.UPDATE_COMMENT,
            payload: API.updateComment(data, id)
                .then((response) => {
                    if (response.data.success) {
                        if (setEditCommentObject) {
                            setEditCommentObject(null)
                        }
                        SuccessAlert('Comment Updated Successfully');
                        const index = ticket.comments.findIndex((item) => item.id === id)
                        ticket.comments[index] = response.data.data
                        return {
                            updatedTicket: ticket
                        };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getAvailabilityCalendarScheduledList = (startDate, endDate, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_AVAILABILITY_CALENDAR_SCHEDULED_LIST,
            payload: API.getAvailabilityCalendarScheduledList(startDate, endDate, id)
                .then((response) => {
                    if (response.data.success) {
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const addAvailability = (data) => {
    return (dispatch, getState) => {
        const user = getState().authReducer.user;
        dispatch({
            type: TimeTrackerModTypes.ADD_AVAILABILITY,
            payload: API.addAvailability(data)
                .then((response) => {
                    if (response.data.success) {
                        let userId = response.data && response.data.data && response.data.data[0] && response.data.data[0].user_id ? response.data.data[0].user_id : null;
                        if (user.role === userRoles.FRONTLINE) {
                            history.push(routes.FLUSER_CALENDAR_AVAILABILITY);
                            SuccessAlert('Availability Successfully Added');
                        } else {
                            history.push(`/calendar/availability/${userId}`);
                            SuccessAlert('Availability Successfully Added');
                        }
                        return response;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const overrideAvailability = (data) => {
    return (dispatch, getState) => {
        const user = getState().authReducer.user;
        dispatch({
            type: TimeTrackerModTypes.OVERRIDE_AVAILABILITY,
            payload: API.overrideAvailability(data)
                .then((response) => {
                    if (response.data.success) {
                        let userId = response.data && response.data.data && response.data.data.created_to ? response.data.data.created_to : null;
                        if (user.role === userRoles.FRONTLINE) {
                            history.push(routes.FLUSER_CALENDAR_AVAILABILITY);
                            SuccessAlert('Successfully initiated the Override Availability Request');
                        } else {
                            history.push(`/calendar/availability/${userId}`);
                            SuccessAlert('Successfully Availability Overriden');
                        }
                        return response;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getShiftSchedule = (params, values) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_ALL_SHIFT_SCHEDULE,
            payload: API.getShiftSchedule(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            shiftSchedule: response.data.data,
                            shiftSchedule_total_pages: response.data.totalPages,
                            shiftSchedule_no_of_rows: response.data.limit,
                            total_shiftSchedule_count: response.data.total,
                            shiftSchedule_curPage: params.page - 1,
                            searchValues: values
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const shiftScheduleListPageClicked = (data) => {
    return {
        type: TimeTrackerModTypes.SHIFT_SCHEDULE_PAGE_CLICKED,
        payload: data,
    };
};

export const previewSingleShiftScheduleClicked = (ticket) => {
    let windowLocationValue = '';
    if (window.location.href && window.location.href.includes('.com')) {
        windowLocationValue = window.location.href.split('//')[1].split('.com')[1]
    } else {
        windowLocationValue = window.location.href.substring(21)
    }
    storage.set('shiftScheduleId', ticket.id)
    history.push({ pathname: `/shift-schedule/single-shift-schedule/${ticket.id}`, state: { storedRoute: windowLocationValue && windowLocationValue.length > 0 ? windowLocationValue : null } });
    return {
        type: TimeTrackerModTypes.PREVIEW_SINGLE_SHIFT_SCHEDULE,
        payload: ticket
    };
};

export const getShiftScheduleDetail = (params, values, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_ALL_SHIFT_SCHEDULE_DETAIL,
            payload: API.getShiftScheduleDetail(params, id)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            shiftSchedule: response.data.data,
                            shiftSchedule_total_pages: response.data.totalPages,
                            shiftSchedule_no_of_rows: response.data.limit,
                            total_shiftSchedule_count: response.data.total,
                            shiftSchedule_curPage: params.page - 1,
                            searchValues: values,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const shiftScheduleDetailListPageClicked = (data) => {
    return {
        type: TimeTrackerModTypes.SHIFT_SCHEDULE_DETAIL_PAGE_CLICKED,
        payload: data,
    };
};

export const getShiftCalendarScheduledList = (startDate, endDate, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_SHIFT_CALENDAR_SCHEDULED_LIST,
            payload: API.getShiftCalendarScheduledList(startDate, endDate, id)
                .then((response) => {
                    if (response.data.success) {
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getSingleShifSchedule = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_SINGLE_SHIFT_SCHEDULE,
            payload: API.getSingleShifSchedule(id)
                .then((response) => {

                    if (response.data.success) {
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        })
    }
}

export const getShiftDetailForSingleFLUser = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_SHIFT_DETAIL_FOR_SINGLE_FLUSER,
            payload: API.getShiftDetailForSingleFLUser(id)
                .then((response) => {
                    if (response.data.success) {
                        return response.data;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const checkFrontlinerShift = (data, formType, userId) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.CHECK_FRONTLINER_SHIFT,
            payload: API.checkFrontlinerShift(data)
                .then((response) => {
                    let restrictShiftCreate = cloneDeep(response.data);
                    if (response.data.success) {
                        if (restrictShiftCreate) {

                            if (restrictShiftCreate.overwritetten && restrictShiftCreate.overwritetten == true) {
                                document.getElementsByClassName('shiftOverriddenWarningModal')[0].click(); //for triggering warning modal
                            } else {
                                if (formType == 'create') {
                                    dispatch(createFrontlinerShift(data));
                                } else if (formType == 'update') {
                                    delete data.data.accountIds
                                    delete data.data.end_date
                                    delete data.data.end_time
                                    delete data.data.notes
                                    delete data.data.start_date
                                    delete data.data.start_time
                                    dispatch(editPerDayFLUserShiftDetail(data, userId));
                                }
                            }

                        }
                        return response;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const createFrontlinerShift = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.CREATE_FRONTLINER_SHIFT,
            payload: API.createFrontlinerShift(data)
                .then((response) => {
                    if (response.data.success) {
                        closeModel();
                        history.push(routes.SHIFT_SCHEDULE);
                        SuccessAlert('Frontliner Shift Successfully Created');
                        return response;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const editFrontlinerShift = (data, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.EDIT_FRONTLINER_SHIFT,
            payload: API.editFrontlinerShift(data, id)
                .then((response) => {
                    if (response.data.success) {
                        history.push(routes.SHIFT_SCHEDULE);
                        SuccessAlert('Frontliner Shift Successfully Updated');
                        return response;
                    } else if (response.data.success == false) {
                        setTimeout(() => {
                            refreshPage();
                        }, 500);
                    }
                })
                .catch((error) => {
                    window.location.reload();
                    console.log(error);
                }),
        });
    };
}

export const editPerDayFLUserShiftDetail = (data, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.EDIT_PERDAY_FLUSER_SHIFT_DETAIL,
            payload: API.editPerDayFLUserShiftDetail(data, id)
                .then((response) => {
                    if (response.data.success) {
                        closeModel();
                        history.goBack();
                        SuccessAlert('Frontline shift Successfully Updated');
                        return response;
                    } else if (response.data.success == false) {
                        setTimeout(() => {
                            refreshPage();
                        }, 500);
                    }
                })
                .catch((error) => {
                    window.location.reload();
                    console.log(error);
                }),
        });
    };
}

export const deleteMainShift = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.DELETE_SHIFT,
            payload: API.deleteMainShift(data)
                .then((response) => {
                    if (response.data.success) {
                        let limit = cloneDeep(getState().TimeTrackerModReducer.shiftScheduleList_no_of_rows);
                        SuccessAlert('Shift deleted successfully');
                        dispatch(getShiftSchedule({ page: 1, limit }));
                        return { data };
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

export const getShift = (params, values) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_ALL_SHIFT,
            payload: API.getShift(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            shift: response.data.data,
                            shift_total_pages: response.data.totalPages,
                            shift_no_of_rows: response.data.limit,
                            total_shift_count: response.data.total,
                            shift_curPage: params.page - 1,
                            searchValues: values
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const grantCancelShiftRequest = (cancelRequest, id, paramsId) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GRANT_CANCEL_SHIFT_REQUEST,
            payload: API.grantCancelShiftRequest(cancelRequest, id)
                .then((response) => {
                    if (response.data.success) {
                        closeModel();
                        dispatch(getShiftScheduleDetail({ page: 1, limit: 10, status: 'cancelled' }, {}, paramsId));
                        SuccessAlert('Requested Shift Canceled Successfully');
                        return response;
                    } else {
                        closeModel();
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getSwapList = (params, values) => {
    return (dispatch) => {
        dispatch({
            type: TimeTrackerModTypes.GET_ALL_SWAP_LIST,
            payload: API.getSwapList(params, values)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            swapList: response.data.data,
                            swapList_total_pages: response.data.totalPages,
                            swapList_no_of_rows: response.data.limit,
                            total_swapList_count: response.data.total,
                            swapList_curPage: params.page - 1,
                            searchValues: values
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getPerDayFLUserGeneralShiftInfo = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_PERDAY_FLUSER_GENERAL_SHIFT_INFO,
            payload: API.getPerDayFLUserGeneralShiftInfo(params)
                .then((response) => {
                    if (response.data.success) {
                        return response.data;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getPerDayFLUserElaboratedShiftInfo = (params, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_PERDAY_FLUSER_ELABORATED_SHIFT_INFO,
            payload: API.getPerDayFLUserElaboratedShiftInfo(params, id)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            singleDayElaboratedShiftInfo: response.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const createFLUserShiftSwap = (body) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.CREATE_FLUSER_SHIFT_SWAP,
            payload: API.createFLUserShiftSwap(body)
                .then((response) => {
                    if (response.data.success) {

                        let fromShiftId = cloneDeep(response.data.data.fromShiftDetails.shift.id);
                        let fromShiftUserName = cloneDeep(response.data.data.fromShiftDetails.shift.assignedTo.first_name + ' ' + response.data.data.fromShiftDetails.shift.assignedTo.last_name);
                        let toShiftUserName = cloneDeep(response.data.data.toShiftDetails.shift.assignedTo.first_name + ' ' + response.data.data.toShiftDetails.shift.assignedTo.last_name);

                        history.push(routes.SHIFT);
                        SuccessAlert(`Successfully transitioned the shift from ${fromShiftUserName} to ${toShiftUserName}`);

                        return response;

                    } else if (response.data.success == false) {
                        // history.goBack();
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getSpecificShiftSwapInfo = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_SPECIFIC_SHIFT_SWAP_INFO,
            payload: API.getSpecificShiftSwapInfo(id)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            specificShiftSwapInfo: response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const grantSwapRequestReview = (body, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GRANT_SWAP_REQUEST_REVIEW,
            payload: API.grantSwapRequestReview(body, id)
                .then((response) => {
                    if (response.data.success) {
                        closeModel();

                        if (body.data.status == 'approved') {
                            history.push(`/shift-swap/swapped/aspects/${id}`);
                            dispatch(getSpecificShiftSwapInfo(id));
                            SuccessAlert(`Requested Shift Swap ${makeFirstLetterCapital(body.data.status)} Successfully`);
                            return response;

                        } else {
                            history.push(routes.SWAP_LIST);
                            dispatch(getSwapList({ page: 1, limit: 10, status: body.data.status }, {}));
                            SuccessAlert(`Requested Shift Swap ${makeFirstLetterCapital(body.data.status)} Successfully`);
                            return response;
                        }

                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const swapListPageClicked = (data) => {
    return {
        type: TimeTrackerModTypes.SWAP_LIST_PAGE_CLICKED,
        payload: data,
    };
};

export const getOverrideAvailabilityRequestList = (params, values) => {
    return (dispatch) => {
        dispatch({
            type: TimeTrackerModTypes.GET_OVERRIDE_AVAILABILITY_REQUEST_LIST,
            payload: API.getOverrideAvailabilityRequestList(params, values)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            overrideAvailabilityRequestList: response.data.data,
                            total_overrideAvailabilityRequestList_count: response.data.total,
                            overrideAvailabilityRequestList_total_pages: response.data.totalPages,
                            overrideAvailabilityRequestList_no_of_rows: response.data.limit,
                            overrideAvailabilityRequestList_curPage: params.page - 1,
                            overrideAvailabilityRequestList_searchValues: values
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const overrideAvailabilityRequestListPageClicked = (data) => {
    return {
        type: TimeTrackerModTypes.OVERRIDE_AVAILABILITY_REQUEST_LIST_PAGE_CLICKED,
        payload: data,
    };
};

export const grantOverrideAvailabilityRequest = (reviewRequest, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GRANT_OVERRIDE_AVAILABILITY_REQUEST,
            payload: API.grantOverrideAvailabilityRequest(reviewRequest, id)
                .then((response) => {
                    if (response.data.success) {
                        closeModel();
                        if (reviewRequest.data.status == 'approved') {

                            dispatch(getOverrideAvailabilityRequestList({ page: 1, limit: 10, status: 'approved' }, {}));
                            SuccessAlert('Requested Override Availability Approved Successfully');

                        } else if (reviewRequest.data.status == 'rejected') {

                            dispatch(getOverrideAvailabilityRequestList({ page: 1, limit: 10, status: 'rejected' }, {}));
                            SuccessAlert('Requested Override Availability Rejected Successfully');

                        }
                        return response;
                    }
                })
                .catch((error) => {
                    closeModel();
                    dispatch(getOverrideAvailabilityRequestList({ page: 1, limit: 10, status: 'requested' }, {}));
                    console.log(error);
                }),
        });
    };
}

export const singleAvailabilityListClicked = (ticket) => {
    let windowLocationValue = '';
    if (window.location.href && window.location.href.includes('.com')) {
        windowLocationValue = window.location.href.split('//')[1].split('.com')[1]
    } else {
        windowLocationValue = window.location.href.substring(21)
    }
    storage.set('singleAvailabilityListId', ticket.id)
    history.push({ pathname: `/availability-list/single-availability-list/${ticket.id}`, state: { storedRoute: windowLocationValue && windowLocationValue.length > 0 ? windowLocationValue : null } });
    return {
        type: TimeTrackerModTypes.SINGLE_AVAILABILITY_LIST_CLICKED,
        payload: ticket
    };
};

export const getAllAvailability = (params, values) => {
    return (dispatch) => {
        dispatch({
            type: TimeTrackerModTypes.GET_ALL_AVAILABILITY_LIST,
            payload: API.getAllAvailability(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            availabilityList: response.data.data,
                            availabilityList_total_pages: response.data.totalPages,
                            availabilityList_no_of_rows: response.data.limit,
                            total_availabilityList_count: response.data.total,
                            availabilityList_curPage: params.page - 1,
                            searchValues: values
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getAllAvailabilityListPageClicked = (data) => {
    return {
        type: TimeTrackerModTypes.ALL_AVAILABILITY_LIST_CLICKED,
        payload: data,
    };
};

/* FL User Shift Clock In Shift Identify CHECK CALL */
export const flUserShiftClockChecker = (body) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.FLUSER_SHIFT_CLOCK_CHECKER,
            payload: API.flUserShiftClockChecker(body)
                .then((response) => {
                    if (response.data.success == true) {

                        if (window.location.href.includes('shiftCondense')) {
                            return {
                                isShiftAvailable: response.data.success,
                                shiftData: response.data,
                            }
                        } else if (response?.data?.status == 'closed') {
                            storage.remove('isClockInEngaged');
                            storage.remove('clockInTimer');
                            storage.remove('chronometer');
                            storage.remove('envReloadOccurs');
                            storage.remove('inHabitancyRecord');
                            storage.remove('clkOutTime');
                            storage.remove('clkFeedbackForm');
                            storage.remove('clkAttestationForm');
                            storage.remove('missedMealPenaltyForm');
                            storage.remove('initialClockIn');
                            storage.remove('isTimeTravel');
                            history.push(routes.HOMEPAGE)
                            return false;
                        } else if (response?.data?.check_out_at?.length > 0) {
                            storage.remove('isClockInEngaged');
                            storage.remove('clockInTimer');
                            storage.remove('chronometer');
                            storage.remove('envReloadOccurs');
                            storage.remove('inHabitancyRecord');
                            storage.remove('clkOutTime');
                            storage.remove('clkFeedbackForm');
                            storage.remove('clkAttestationForm');
                            storage.remove('missedMealPenaltyForm');
                            storage.remove('isTimeTravel');
                            storage.remove('initialClockIn');
                            history.push(routes.HOMEPAGE)
                            return false;
                        } else if (response?.data?.check_int_at?.length > 0) {
                            storage.set('isClockInEngaged', true);
                            setTimeout(() => {
                                history.push(routes.CLKIN_CLKOUT_SCREEN);
                                if (storage.get('isMealBreakInitiated')) {
                                    document.getElementsByClassName('app-sidebar__toggle')[0] && document.getElementsByClassName('app-sidebar__toggle')[0].click();
                                }
                            }, 450);
                            return {
                                isShiftAvailable: response.data.success,
                                shiftData: response.data,
                            }
                        } else {
                            return {
                                isShiftAvailable: response.data.success,
                                shiftData: response.data,
                            }
                        }

                    } else if (response.data.success == false) {

                        if (response?.data?.message == "You have not colcked-In Yet for today's shift") {
                            return {
                                isShiftExcuse: true,
                                shiftData: response.data,
                            }
                        } else {
                            return {
                                isShiftAvailable: response.data.success,
                                shiftData: response.data,
                            }
                        }

                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

/* FL User Shift Clock In - Check In POST Call */
export const flUserShiftClockCheckIn = (body, setAccountId) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.FLUSER_SHIFT_CLOCK_CHECKIN,
            payload: API.flUserShiftClockCheckIn(body)
                .then((response) => {
                    if (response.data.success == true) {
                        setAccountId('')
                        storage.set('initialClockIn', true)
                        SuccessAlert('FL User Successfully ClockedIn');

                        return {
                            isShiftAvailable: response.data.success,
                            shiftData: response.data,
                        }

                    } else if (response.data.success == false) {

                        return {
                            isShiftAvailable: response.data.success,
                            // shiftData: response.data,
                        }

                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

/* FL User Shift Meal Break Session PUT Call */
export const flUserMealBreakSession = (mealBreakItem, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.FLUSER_MEAL_BREAK_SESSION,
            payload: API.flUserMealBreakSession(mealBreakItem, id)
                .then((response) => {
                    if (response.data.success == true) {

                        if (mealBreakItem?.data?.start) {
                            setTimeout(() => {
                                document.getElementById('mealBreakTrigger').click();
                            }, 300);
                        }

                        storage.set('currentFeedGapId', id);
                        dispatch(flUserShiftClockChecker({ data: { current_time: tzUTCDateTimeConverterTool_V1(momentTZ().format('YYYY-MM-DDTHH:mm:ss').split('T')[0], momentTZ().format('YYYY-MM-DDTHH:mm:ss').split('T')[1], 'none') } }));

                        if (mealBreakItem?.data?.end) {
                            setTimeout(() => {
                                document.getElementById('mealBreakConclude').click();
                            }, 300);
                        }
                        setTimeout(() => {
                            SuccessAlert(`Meal Break ${response.data && response.data?.data && response.data?.data?.start !== null && response.data?.data?.end == null ? 'Started' : 'Ended'} Successfully`);
                        }, 100);

                        return {
                            isShiftMealBreakTriggered: response.data && response.data?.end == null ? true : false,
                            shiftMealBreakData: response.data,
                        }

                    } else if (response.data.success == false) {

                        return {
                            isShiftMealBreakTriggered: response.data.success,
                            shiftMealBreakData: response.data,
                        }

                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

/* FL User Scheduled Tasks Tickets retrieve GET Call */
export const getPerFLUserScheduledTaskTickets = (params) => {
    return (dispatch) => {
        dispatch({
            type: TimeTrackerModTypes.GET_PER_FLUSER_SCHEDULED_TASK_TICKETS,
            payload: API.getPerFLUserScheduledTaskTickets(params)
                .then((response) => {
                    if (response.data.success) {
                        return response;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

/* FL User Signature Attachment PATCH Call */
export const getFLUserSignAttachPresignUrl = (shiftId, extentions, arrayBuffer, formData) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_FLUSER_SIGN_ATTACH_PRESIGN_URL,
            payload: API.getFLUserSignAttachPresignUrl(shiftId, extentions)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;

                    response.data.presigned_urls.map((p, i) => {
                        formData.data.signature = p.attachment_path;
                        if (formData?.data?.mealPenalty) {
                            formData.data.mealPenalty['employee_signature'] = p.attachment_path;
                        }
                        dispatch({
                            type: TimeTrackerModTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        dispatch(flUserShiftClockCheckOut(formData));
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });

                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

/* FL User Shift Clock Out - Check Out POST Call */
export const flUserShiftClockCheckOut = (body) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.FLUSER_SHIFT_CLOCK_CHECKOUT,
            payload: API.flUserShiftClockCheckOut(body)
                .then((response) => {
                    if (response.data.success == true) {
                        history.replace(routes.CLKIN_CLKOUT_SHIFT_RECAP);
                        storage.remove('inHabitancyRecord');
                        storage.remove('clkOutTime');
                        storage.remove('clkFeedbackForm');
                        storage.remove('clkAttestationForm');
                        storage.remove('missedMealPenaltyForm');
                        storage.remove('initialClockIn');
                        SuccessAlert('FL User Successfully ClockedOut');
                        return response;
                    } else if (response.data.success == false) {
                        return response;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

/* FL User Scheduled Task My Tickets GET call */
export const getFlUserScheduledTaskTickets = (params, values) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_FLUSER_SCHEDULED_TASK_TICKETS,
            payload: API.getFlUserScheduledTaskTickets(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            stMyTicketsList: response.data.data,
                            stMyTicketsList_total_pages: response.data.totalPages,
                            stMyTicketsList_no_of_rows: response.data.limit,
                            stMyTicketsList_curPage: params.page - 1,
                            stMyTicketsList_total_tickets: response.data.total,
                            searchValues: values,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const flUserSTMyTicketsPageClicked = (data) => {
    return {
        type: TimeTrackerModTypes.FLUSER_ST_MY_TICKETS_PAGE_CLICKED,
        payload: data,
    };
};

/* Timecard Correction Signature Attachment PATCH Call */
export const acquireTimecardCorrectionSignPresignUrl = (shiftId, extentions, arrayBuffer, formData, check, timecardId) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.ACQUIRE_TIMECARD_CORRECTION_SIGN_PRESIGNURL,
            payload: API.acquireTimecardCorrectionSignPresignUrl(shiftId, extentions)
                .then((response) => {

                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;

                    response.data.presigned_urls.map((p, i) => {
                        if (check.review == 'empReview') {
                            formData.data.employee_signature = p.attachment_path;
                        } else if (check.review == 'managerReview') {
                            formData.data.manager_signature = p.attachment_path;
                        } else if (check.review == 'payrollReview') {
                            formData.data.payroll_signature = p.attachment_path;
                        }
                        dispatch({
                            type: TimeTrackerModTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        if (check.review == 'empReview') {
                                            dispatch(createTimecardCorrectionQuery(formData));
                                        } else {
                                            dispatch(updateTimecardCorrectionQuery(timecardId, formData));
                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });

                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

/* Time Card Correction POST Call */
export const createTimecardCorrectionQuery = (body) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.CREATE_TIMECARD_CORRECTION_QUERY,
            payload: API.createTimecardCorrectionQuery(body)
                .then((response) => {
                    
                    if (response.data.success == true) {
                        history.push(routes.FL_TIMECARD_REVIEWED_LIST);
                        SuccessAlert('Time card correction initiated successfully');
                        
                        return response;
                    } else if (response.data.success == false) {
                        return response;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

/* Time Card Correction Reviewed List GET call */
export const getTimecardReviewedList = (params, values) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_TIMECARD_REVIEWED_LIST,
            payload: API.getTimecardReviewedList(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            timecardReviewedList: response.data.data,
                            timecardReviewedList_total_pages: response.data.totalPages,
                            timecardReviewedList_no_of_rows: response.data.limit,
                            timecardReviewedList_curPage: params.page - 1,
                            timecardReviewedList_total_tickets: response.data.total,
                            searchValues: values,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const timecardReviewedListPageClicked = (data) => {
    return {
        type: TimeTrackerModTypes.TIMECARD_REVIEWED_LIST_PAGE_CLICKED,
        payload: data,
    };
};

/* Missed Meal Penalty Break Check API GET call */
export const getMissedMealPenaltyBreaks = (id, time, clockedInShiftData, isMealBreakModeGenerated) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_MISSED_MEAL_PENALTY_BREAKS,
            payload: API.getMissedMealPenaltyBreaks(id, time)
                .then((response) => {
                    if (response.data.success) {

                        if (isMealBreakModeGenerated == true) { // This condition is for MealPenalty Generator Form
                            return response.data;
                        } else {
                            if (response?.data?.data?.mealPenalty == true) {
                                history.push(`/mealpenalty-generator/${clockedInShiftData.id}`);
                                return response.data;
                            } else {
                                history.replace({ pathname: `/clkIn-clkOut/${clockedInShiftData.id}/feedback/${clockedInShiftData.shift_id}`, state: { clkShiftInfo: clockedInShiftData } });
                                return response;
                            }
                        }

                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

/* Time card correction single card API GET call */
export const getSingleTimecardListData = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_SINGLE_TIMECARD_LIST_DATA,
            payload: API.getSingleTimecardListData(id)
                .then((response) => {
                    if (response.data.success) {
                        return response;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

/* Time Card Correction PUT Call */
export const updateTimecardCorrectionQuery = (id, body) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.UPDATE_TIMECARD_CORRECTION_QUERY,
            payload: API.updateTimecardCorrectionQuery(id, body)
                .then((response) => {
                    if (response.data.success == true) {
                        history.push(routes.TIMECARD_REVIEWED_LIST);
                        SuccessAlert('Time card correction updated successfully');
                        return response;
                    } else if (response.data.success == false) {
                        return response;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

/* Time Sheet List GET call */
export const getTimeSheetListInfo = (params, values) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_TIME_SHEET_LIST_INFO,
            payload: API.getTimeSheetListInfo(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            timeSheetList: response.data.data,
                            timeSheetList_total_pages: response.data.totalPages,
                            timeSheetList_no_of_rows: response.data.limit,
                            timeSheetList_curPage: params.page - 1,
                            timeSheetList_total_tickets: response.data.total,
                            searchValues: values,
                            timeSheetList_timecard: response.data.timecard,
                            timesheet_default_currentPage: response.data.currentPage,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const timeSheetListInfoPageClicked = (data) => {
    return {
        type: TimeTrackerModTypes.TIME_SHEET_LIST_INFO_PAGE_CLICKED,
        payload: data,
    };
};

/* Download Payroll CSV GET call */
export const triggerExportPayrollCSV = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.TRIGGER_EXPORT_PAYROLL_CSV,
            payload: API.triggerExportPayrollCSV(params)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Payroll CSV exported successfully');
                        return response;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

/* Get shifts by manipulating params - GET call */
export const getShiftListByParams = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_SHIFT_LIST_BY_PARAMS,
            payload: API.getShiftListByParams(params)
                .then((response) => {
                    if (response.data.success) {
                        if (response.data.data.length == 0) {
                            errorHandler('No shift assigned to selected date');
                        }
                        return {
                            shiftList: response.data.data,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

/* Get shifts by Attendance Dashboard Calendar List - GET call */
export const getShiftAttendanceCalendarList = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.GET_SHIFT_ATTENDANCE_CALENDAR_LIST,
            payload: API.getShiftAttendanceCalendarList(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            presents: response.data.data.presents,
                            absents: response.data.data.absents,
                            complaints: response.data.data.complaints,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const resolveScheduledTicket = (data, scheduleId, ticketId) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.RESOLVE_SCHEDULE_TICKET,
            payload: API.resolveTicket(data, scheduleId, ticketId)
                .then((response) => {
                    if (response.data.success) {
                        history.goBack();
                        SuccessAlert('Ticket Resolved Successfully');
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getResolveScheduledAttachmentPresignUrl = (comments, extentions, arrayBuffer, scheduleId, ticketId) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.COMMENT_PRESIGN_URL,
            payload: API.getcommentsAttachmentPresignUrl(comments, extentions)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                    comments.data.comments_attributes[0].attachment = [];
                    response.data.presigned_urls.forEach((p, i) => {
                        comments.data.comments_attributes[0].attachment[i] = {}
                        comments.data.comments_attributes[0].attachment[i] = p.attachment_path
                    });
                    response.data.presigned_urls.map((p, i) => {
                        dispatch({
                            type: TimeTrackerModTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        dispatch(resolveScheduledTicket(comments, scheduleId, ticketId));
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteTimecardCorrectionCard = (data) => {
    return (dispatch, getState) => {
        const user = getState().authReducer.user;
        let page = 1
        let limit = cloneDeep(getState().TimeTrackerModReducer.timecardReviewedList_no_of_rows);
        dispatch({
            type: TimeTrackerModTypes.DELETE_TIMECARD_CORRECTION_CARD,
            payload: API.deleteTimecardCorrectionCard(data)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Timecard deleted successfully');
                        if (user.role == 'frontline') {
                            let employedId = user.id;
                            dispatch(getTimecardReviewedList({ page, limit, employedId }));
                        } else {
                            dispatch(getTimecardReviewedList({ page, limit }));
                        }
                        return { data };
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

/* FL User Move on to Next Job - PUT Call */
export const flUserShiftNextJob = (body, shiftId, setAccountId) => {
    return (dispatch, getState) => {
        dispatch({
            type: TimeTrackerModTypes.FLUSER_SHIFT_NEXT_JOB,
            payload: API.flUserShiftNextJob(body, shiftId)
                .then((response) => {
                    if (response.data.success == true) {
                        setAccountId('')
                        SuccessAlert('FL User Successfully Job Changed');

                        return {
                            isShiftAvailable: response.data.success,
                            shiftData: response.data,
                        }

                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const clearSingleShifSchedule = () => {
    return {
        type: TimeTrackerModTypes.CLEAR_SINGLE_SHIFT_DETAIL,
    };
}