import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Input } from '../../../UI/InputElement/InputElement';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import { getUser, makeFirstLetterCapital } from '../../../../../utility/utility';
import { newAssetValidator as validate } from '../../../../../utility/validator/validator';
import moment from "moment";
import cloneDeep from 'clone-deep';
import AssetStockFormSkeleton from './AssetStockFormSkeleton';

let NewAssetStockForm = (props) => {

    const assetsOptions = [
        {
            id: 'readyForUse',
            name: 'Ready For Use'
        },
        {
            id: 'needsService',
            name: 'Needs Service'
        },
        {
            id: 'underRepair',
            name: 'Under Repair'
        },
    ];

    const { handleSubmit, pristine, reset, submitting, error, submitFailed } = props;

    if (props.isUpdateAsset) {
        return (
            <AssetStockFormSkeleton />
        )
    } else {
        return (
            <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluids">
                        <div className="quote_form ticket_form" style={{ maxWidth: '700px' }}>
                            <div className="row">
                                <div className="col-sm-12 text-center"> <h2 className="md_title" >{props.isEditAsset ? "Update Asset Stock" : "Add Asset Stock"}</h2></div>
                            </div>
                            <div className="addQuote_form">
                                <form className="form_content" onSubmit={handleSubmit} >
                                    <div className="cst_tab_content">

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Asset Name <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Asset Name" name="item[name]" />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title"> Quantity <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input dataTest="" type="numberWithIncDec" className="form-control input-modifier" placeholder="No of Quantity" name="item[initial_quantity]"/>
                                                    </div>
                                                </div> */}
                                            {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title"> Manufacturer <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Manufacturer Name" name="item[manufacture]" />
                                                    </div>
                                                </div> */}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Serial Number <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input dataTest="" type="number" disabled={true} className="form-control input-modifier" placeholder="Enter SKU No" name="item[sku_detail]" />
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="row">
                                                
                                             
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title"> Suppliers <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input dataTest="" type="text" className="form-control input-modifier" placeholder="Enter Supplier Name" name="item[supplier]" />
                                                    </div>
                                                </div>
                                            </div> */}

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form_title"> Next Service Date <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <div className="input-group cst_input_group col-lg-14 pl-0">
                                                        <div style={{ width: '100%' }}>
                                                            <Input
                                                                type="date"
                                                                datePickerType={"react-datepicker"}
                                                                minDate={new Date()}
                                                                dateFormat="MM-dd-yyyy"
                                                                className="form-control input-modifier"
                                                                placeholder="Select Date"
                                                                name="item[service_date]"
                                                            />
                                                        </div>
                                                        <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                            <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="label_modifier">Asset Status</label>
                                                    <Input instanceId="job_select" type="select" options={assetsOptions} placeholder="Select Status" className="custom-select input-modifier add_user_select" name="item[status]" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-30"} >{props.isLoading ? "" : props.isEditAsset ? "Update Asset Stock" : "Add Asset Stock"}</button>
                                        <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" onClick={() => { props.onCancelClicked() }}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}

const mapStateToProps = (state) => {

    let item = {};
    if (isEmpty(state.InventoryReducer.assetStock)) {
        item = {
            // active_status: 'active',
        }
    } else {
        const values = cloneDeep(state.InventoryReducer && state.InventoryReducer.assetStock);
        item = {
            name: values && values.name ? makeFirstLetterCapital(values.name) : null,
            sku_detail: values && values.sku_detail ? values.sku_detail : null,
            service_date: values && values.service_date ? moment(values.service_date, "YYYY-MM-DDTHH:mm:ss")._d : null,
            status: values && values.status ? values.status : null,
        }
    }
    return { initialValues: { item } }

}

NewAssetStockForm = reduxForm({
    form: 'newAssetStockForm',
    validate,
    enableReinitialize: true
})(NewAssetStockForm);

NewAssetStockForm = connect(mapStateToProps)(NewAssetStockForm)

export default NewAssetStockForm;