import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { routes } from '../../../utility/constants/constants';
import HomePage from '../../component/HomePage/HomePage';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import LogBookQrReader from '../../../adminOrSuperAdminOrRequestor/container/ AccountsContainer/LogBookAreaContainer/LogBookQRContainer/LogBookQRContainer';
import LogBookQrButton from './LogBookQrButton';
import { tzUTCDateTimeConverterTool_V1 } from '../../../utility/TimezoneOperations/timezoneUtility';
import storage from '../../../utility/storage';
var momentTZ = require('moment-timezone');
class HomePageContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            camOption: null,
        }
    }

    openCamera = () => {
        this.props.history.push(routes.SCAN_QR);
    }

    openPriorEscalation = () => {
        this.props.history.push(routes.PRIORITY_ESCALATION_TICKETS);
    }

    componentDidMount() {
        this.props.getPriorEscalationTickets()
        let localCamOption = JSON.parse(localStorage.getItem('user'))
        if (localCamOption.scan_type === 'clean') {
            this.setState({ camOption: false })
        } else {
            this.setState({ camOption: true })
        }

        /* CHECKER API FOR CLOCK IN CLOCK OUT SHIFT CHECKER */
        let setData = { data: {} };
        let fetchedCurrDateTime = momentTZ(new Date()).format('MM-DD-YYYYTHH:mm:ss').split('T');
        setData.data.current_time = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');
        this.props.flUserShiftClockChecker(setData);
    }

    openTimerScreen = () => {
        this.props.history.push(routes.CLKIN_CLKOUT_SCREEN);
    }

    render() {

        return (
            <>
                {this.state.camOption ?
                    <LogBookQrButton {...this.props}
                    openTimerScreen={this.openTimerScreen}
                    /> :
                    <HomePage
                        {...this.props}
                        openCamera={this.openCamera}
                        priorEscalation={this.openPriorEscalation}
                        openTimerScreen={this.openTimerScreen}
                    />
                }
                <Dialog
                    open={this.props.openFrequentTimeTracker_deleteModal}
                    onClose={this.props.handleFrequentTimeTracker_closeDeleteModal}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                        <h3 style={{ color: '#ab385e' }} data-test="modal_delete_title"> Do you want to Clockin? </h3>
                    </DialogTitle>

                    <DialogActions>
                        <button data-test="modal_cancel" style={{ minHeight: '36px', minWidth: '80px' }} onClick={this.props.handleFrequentTimeTracker_closeDeleteModal} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel"> No </button>
                        <button data-test="modal_delete" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => { this.openTimerScreen() }} disabled={this.props.isLoading} className={this.props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{this.props.isLoading ? "" : "Yes"}</button>
                    </DialogActions>
                </Dialog>
            </>
        );

    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        acceptedTicketCount: state.adminOrSuperAdminReducer.acceptedTicketCount,
        openTicketCount: state.adminOrSuperAdminReducer.openTicketCount,
        isClockInShiftAvailable: state.TimeTrackerModReducer.isClockInShiftAvailable,
        isClockInShiftExcuse: state.TimeTrackerModReducer.isClockInShiftExcuse,
        clockInCheckedInTime: state.TimeTrackerModReducer.clockInCheckedInTime, // Checked In Time
        openFrequentTimeTracker_deleteModal: state.TimeTrackerModReducer.openFrequentTimeTracker_deleteModal, // For triggerring the popup of clockin question for Exempt Employee
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPriorEscalationTickets: () => dispatch(actions.getPriorEscalationTickets({}, 10, 1, [], [])),
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        flUserShiftClockChecker: (data) => dispatch(actions.flUserShiftClockChecker(data)),

        handleFrequentTimeTracker_openDeleteModal: (value) => dispatch(actions.handleFrequentTimeTracker_openDeleteModal(value)),
        handleFrequentTimeTracker_closeDeleteModal: () => dispatch(actions.handleFrequentTimeTracker_closeDeleteModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);