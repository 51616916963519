import React, { Component } from 'react';
import * as actions from '../../../../../redux/actions/index';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll'
import AddAvailability from '../../../../component/TimeTrackerModuleComponent/AvailabilityComponent/AddAvailability/addAvailability';
import { convertDateFormatV4, convertTo24HourFormat, getInbetweenWeekDates, getInbetweenWeekDates_V1, getUser, makeFirstLetterCapital } from '../../../../../utility/utility';
import errorHandler from '../../../../../utility/errorHandler/errorHandler';
import { tzUTCDateTimeConverterTool_V1, tzUTCTimeConverterTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';

const cloneDeep = require('clone-deep');

class AddAvailabilityContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  onCancelClicked = () => {
    this.props.history.goBack();
  }

  addNewUser = (values) => {
    debugger

    if (values.data.start_date == undefined) {
      errorHandler('Please Selecet Start Date');
      return false;
    }
    if (values.data.end_date == undefined) {
      errorHandler('Please Selecet End Date');
      return false;
    }

    if (values && values.data && values.data.availability && values.data.availability.length > 0) {
      let checkedAvailability = true;
      values.data.availability.map((timeFormat) => {
        if (timeFormat.start_time.includes('PM') && timeFormat.end_time.includes('AM')) {
          errorHandler('Select a time range that falls within a single day');
          checkedAvailability = false;
          return false;
        }
      })
      if (checkedAvailability == false) {
        return checkedAvailability
      }
    }

    let getUserId = this.props && this.props.location && this.props.location.state && this.props.location.state.userId ? this.props.location.state.userId : getUser();
    let value = cloneDeep(values);
    let end_date = ''
    let start_date = ''
    let availability = []
    let user_id = this.props.match.params?.userId
    let selectedAvailabilityDayRange = getInbetweenWeekDates_V1(values.data.start_date, values.data.end_date);

    start_date = tzUTCDateTimeConverterTool_V1(value.data?.start_date, '00:00:00', 'from');
    end_date = tzUTCDateTimeConverterTool_V1(value.data?.end_date, '00:00:00', 'to');

    function convert12hrTo24hr(time12hr) {
      // Create a Date object with the 12-hour time string
      const timeParts = time12hr.split(':');
      let hours = parseInt(timeParts[0]);
      const minutes = parseInt(timeParts[1].split(' ')[0]);
      const amPm = timeParts[1].split(' ')[1].toUpperCase();

      // Adjust the hours based on AM/PM
      if (amPm === 'PM' && hours !== 12) {
        hours += 12;
      } else if (amPm === 'AM' && hours === 12) {
        hours = 0;
      }

      // Format the hours and minutes in 24-hour format
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');

      // Return the time in 24-hour format
      return `${formattedHours}:${formattedMinutes}`;
    }
    if (!value.data?.availability) {
      errorHandler('Please select atleast One Day')
      return false
    }

    if (value.data?.availability) {

      const dayMap = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

      // Iterate through the formData array
      for (let index = 0; index < 7; index++) {
        const formDataItem = value.data.availability[index];
        if (formDataItem === undefined) {
          // If the index is null, add a "day" object with "false" and null start/end times
          availability.push({
            "day": dayMap[index],
            "available": false,
          });
        } else if (formDataItem?.day === false || formDataItem?.day === undefined) {
          availability.push({
            "day": dayMap[index],
            "available": false,
          });
        } else {
          // If the index is not null, add a "day" object with "true" and the corresponding day name
          availability.push({
            "day": dayMap[index],
            "available": true,
            "start_time": formDataItem.start_time ? tzUTCTimeConverterTool_V1(convertDateFormatV4(start_date), convertTo24HourFormat(formDataItem.start_time)) : '',
            "end_time": formDataItem.end_time ? tzUTCTimeConverterTool_V1(convertDateFormatV4(end_date), convertTo24HourFormat(formDataItem.end_time)) : '',
            "start_date_time": formDataItem.start_time ? tzUTCDateTimeConverterTool_V1(convertDateFormatV4(start_date), convertTo24HourFormat(formDataItem.start_time), 'none') : '',
            "end_date_time": formDataItem.end_time ? tzUTCDateTimeConverterTool_V1(convertDateFormatV4(end_date), convertTo24HourFormat(formDataItem.end_time), 'none') : '',
          });
        }
      };

    }

    const checkNullAvailability = availability.filter((item) => item.available === false)
    if (checkNullAvailability.length === 7) {
      errorHandler('Please select atleast One Day')
      return false
    }

    const startTimeValidation = availability.filter((item) => item.available === true && (item.start_time === null || item.start_time === undefined || item.start_time === ''))
    if (startTimeValidation.length) {
      errorHandler('Please select Start Time')
      return false
    }
    const endTimeValidation = availability.filter((item) => item.available === true && (item.end_time === null || item.end_time === undefined || item.end_time === ''))
    if (endTimeValidation.length) {
      errorHandler('Please select End Time')
      return false
    }

    // if (value && value.data && value.data.end_date) {
    //     const customDate = new Date(value.data.end_date);
    //     const year = customDate.getFullYear();
    //     const month = (customDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    //     const day = new Date().getDate().toString().padStart(2, '0');
    //     const formattedDate = `${year}-${month}-${day}`;
    //     end_date = formattedDate
    // } 

    const data = {
      start_date,
      end_date,
      availability,
      user_id
    }

    if (data && data.availability && data.availability.length > 0) {

      let unmatchedDays = data.availability.reduce((unmatched, data) => {
        if(data.start_time){
          if (data.start_time == data.end_time) {
            return false;
        }  
        }
        let day = makeFirstLetterCapital(data.day);
        if (!selectedAvailabilityDayRange.includes(day) && data.available == true) {
          unmatched.push(day);
        }
        return unmatched;
      }, []);
      
       if(!unmatchedDays){
        errorHandler("Start Time and End Time can't be identical");
        return false;
       }
      if (unmatchedDays.length > 0) {
        errorHandler(`Selected [ ${unmatchedDays.join(', ')} ] days are outside the scope of the selected date range.`);
        return false;
      }

    }

    this.props.addAvailability({ data })

  }

  componentDidMount() {
    scroll.scrollToTop();
  }

  componentWillUnmount() {
    this.props.resetObjects();
  }


  render() {

    return (
      <>
        <AddAvailability
          {...this.props}
          onSubmit={this.addNewUser}
          onCancelClicked={this.onCancelClicked}
          isLoading={this.props.isLoading}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.TimeTrackerModReducer.isLoading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetObjects: () => dispatch(actions.resetObjects()),
    addAvailability: (body) => dispatch(actions.addAvailability(body)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAvailabilityContainer);