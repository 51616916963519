import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../styles/custom.css';
import { CustomNoDataComponent, makeFirstLetterCapital } from '../../../../utility/utility';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../redux/actions/index';
import ReactTooltip from 'react-tooltip';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import ImageLoader from 'react-imageloader';
import { userRoles } from '../../../../utility/constants/constants';
import storage from '../../../../utility/storage';
import moment from 'moment';
import SuccessAlert from '../../../../utility/successAlert/successAlert';

const APIAccessTable = (props) => {
    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.adminOrSuperAdminReducer.companyAccessSearchValues);
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const SearchOrResetCompanyClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetCompanyClicked);
    const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.company_access_page);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.company_access_rows);
    const companyPage = useSelector(state => state.adminOrSuperAdminReducer.company_access_cur_page);
    const user = storage.get('user');

    const getAccontsName = (accounts) => {

        return accounts && accounts.length > 0 && accounts.map((a, i) => {
            if ((accounts.length - 1) === i) {
                return a.name;
            } else {
                return a.name + ', ';
            }
        })
    }

    function preloader() {
        return <img style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} src="/images/gif/giphy.gif" />;
    }

    useEffect(() => {
        if (SearchOrResetCompanyClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {
        const account_no_of_rows = No_of_rows;
        const page = companyPage + 1;
        const sorted = [];
        const filtered = [];
        SearchValue.active = props.activeCompanies
        props.getAPIAccess(
            SearchValue,
            account_no_of_rows, page,
            sorted,
            filtered);
        }
      }, [props.activeCompanies]);

const generateKey = (company) =>{
    navigator.clipboard.writeText('Bearer' + ' ' + company.token)
    SuccessAlert('Copied to Clipboard')
}

    const data = props.companiesList.map(company => {
        return {
            // id: company ? company.id : '',
            // number: company ? company.company_number : '',
            image:
                (company && company.photo_urls && company.photo_urls.small) ?
                    <ImageLoader
                        imgProps={{ style: { height: '35px', width: '35px', borderRadius: '50px', float: 'left' } }}
                        style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }}
                        src={company.photo_urls && company.photo_urls.small}
                        wrapper={React.createFactory('div')}
                        preloader={preloader}>
                        Image load failed!
                                    </ImageLoader>
                    :
                    (company.photo_urls && company.photo_urls.small) ?
                        <ImageLoader
                            imgProps={{ style: { height: '35px', width: '35px', borderRadius: '50px', float: 'left' } }}
                            style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }}
                            src={company.photo_urls && company.photo_urls.small}
                            wrapper={React.createFactory('div')}
                            preloader={preloader}>
                            Image load failed!
                                    </ImageLoader>
                        :
                        <img src="/customImages/user.png" style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} />
            ,
            company: company ? makeFirstLetterCapital(company.company.name) : '-',
            // //address: company ? company.address && company.address.formatted_address : '',
            // // accounts: company ? getAccontsName(company.accounts) : getAccontsName(company.accounts),
             accessLimit: company && company.access_limit && company.is_limited ? company.access_limit :  '-' ,
            accessType: company && makeFirstLetterCapital(company.access_type) ,
            status: company ? makeFirstLetterCapital(company.active_status) : makeFirstLetterCapital(company.active_status),
             tokenExpires : company && moment(company.token_expires).format('MM/DD/YYYY') ,
             generateKey : <button className={'btn cst_btn btn_danger'}onClick={() => generateKey(company && company)} >Copy Key</button>,
            actions: <>
                {user.role !== 'admin' ?<> <span data-test="users_edit" className="mr_20 account_edit_icn" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#addAPIAccess" onClick={() => {
                    props.getOneAPIAccess(company.id);
                    props.editCompanyaccessClicked()
                }}><i class="fa fa-pencil" aria-hidden="true"></i></span>
                </> : ''}
            </>
        }
    })

    const columns = [
        
        {
            Header: '',
            accessor: 'image', // String-based value accessors!,
            width: 40,
            filterable: false,
        },
        
        {
            Header: <><span>Customer</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'company',
            //width: 150,
            show: props.companiesList.length !== 0,
            Filter: ({ filter, onChange }) => (
                <input
                    onChange={event => onChange(event.target.value)}
                    value={filter ? filter.value : ''}
                    placeholder={'Search Account...'}
                    className={'form-control input-modifier'}
                />
            ), // String-based value accessors!
        },
        {
            Header: <><span>Expiration Date</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'tokenExpires', // String-based value accessors!,
            //width: 100,
            show: props.companiesList.length !== 0,
        }, 
        {
            Header: <><span>Access Type</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'accessType', // String-based value accessors!,
            //width: 100,
            show: props.companiesList.length !== 0,
        }, 
        {
            Header: <><span>Call Limit</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'accessLimit', // String-based value accessors!,
            //width: 100,
            show: props.companiesList.length !== 0,
        }, 
        {
            Header: <><span>Tokens</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'generateKey', // String-based value accessors!,
            //width: 100,
            show: props.companiesList.length !== 0,
        }, 
        {
            Header: '',
            accessor: 'actions',
            width: 135,
            filterable: false,
            // show: false
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()
    
    if(companyPage !== tablePageNew.current) {
        tablePageNew.current = companyPage;
    }

    const setTablePageFn = (page) => {
        setTablePage(page)
        props.companyAccessPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {

        if (SearchOrResetCompanyClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {
            if (!DateGenerate) {
                state.page = 2;
                const company_no_of_rows = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : companyPage + 1;
                if(No_of_rows != company_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }                
                const sorted = state.sorted;
                const filtered = state.filtered;
                SearchValue.active = props.activeCompanies
                if (props.user.role === userRoles.SUPER_ADMIN) {
                    props.getAPIAccess(
                        SearchValue,
                        company_no_of_rows, page,
                        sorted,
                        filtered);
                }
                //  else {
                //     props.getUserPaginationCompanies(
                //         SearchValue,
                //         company_no_of_rows, page,
                //         sorted,
                //         filtered,
                //         props.user.id);
                // }
            }
            // }
        }
    }

    return (
        SearchOrResetCompanyClicked && props.isCompanyPending ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isCompanyPending}
                defaultPageSize={No_of_rows}
                minRows={0}
                manual
                onFetchData={fetchData}
                pages={No_of_pages}
                gotoPage={companyPage}
                page = {
                    companyPage
                }
                showPaginationTop={true}
                // onPageSizeChange={props.onCompaniesPageSizeChange}
                onPageChange={(page) => {setTablePageFn(page)}}
                NoDataComponent={() => CustomNoDataComponent(props.isCompanyPending, 'No Companies Found')}
                showPagination={props.companiesList.length !== 0}
                // filterable={true}
                columns={columns}
                LoadingComponent={() =>
                    props.isCompanyPending ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                // style={{marginTop: '10px'}}
                getTheadThProps={(state, rowInfo, column, instance) => {

                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {
                    // if (column.Header == 'Active' || column.Header == 'Actions') {
                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element'
                        }
                    }
                }
                }

                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default APIAccessTable;