import React from 'react';
import PerformInspection from '../../../component/Inspections/PerformInsepction/performInspection';
import { connect } from 'react-redux';
import { reset, change, getFormValues, initialize } from 'redux-form';
import * as actions from '../../../../redux/actions/index';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { extractExtention, getUser } from '../../../../utility/utility';
import errorHandeler from '../../../../utility/errorHandler/errorHandler';
import { resetMultipleInspectionOrientation } from '../../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import imageCompression from 'browser-image-compression';
import { routes, PortfolioProperties, MinImageResolution, RatingTypes } from '../../../../utility/constants/constants';
import { decode, encode } from 'base64-arraybuffer';
import storage from '../../../../utility/storage';
import * as API from '../../../../API/api';
import * as commonService from "../../../../utility/utility";
import { Link, Element, Events, animateScroll as scroll } from 'react-scroll';
import * as Scroll from 'react-scroll';
const cloneDeep = require('clone-deep');
var scroller = Scroll.scroller;

let InspectionData = null;
let Ids = [];
class PerformInspectionContainer extends React.Component {

    constructor(props) {
        InspectionData = storage.get("performInspectionData", null);
        Ids = cloneDeep(storage.get("IdsToDelete", null));
        super(props);

        this.state = {
            inspectionForm: InspectionData ? InspectionData.inspection : {},
            CurrentInspectonData: InspectionData,
            saveAsDraft: false,
            submitTrue: false,
            imageLoading: false,
            idsToDelete: Ids && Ids.length > 0 ? Ids : [],
            isSignatureEmpty: false,
            isDataChanged: false,
            base64: [],
            NameTypeSection: {
                isCustomerName: false,
                isFilled: false,
                secIndex: null,
                lineIndex: null
            },
            cancelClicked: true,
            customerPresent: false,
            mode: getUser(),
            selectedCriteria: ''
            // attachments: PortfolioProperties
        }

        this.inputOpenRefrences = {}

        this.createReferences();
        this.inputOpenFileRef = React.createRef();
        // this.createRefrences = this.createRefrences.bind(this);
        this.saveLineItemsPortfolioImages = this.saveLineItemsPortfolioImages.bind(this);
        this.onPortfolioFileChange = this.onPortfolioFileChange.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
    }


    createReferences = () => {

        this.props.inspection.inspection_form && this.props.inspection.inspection_form.sections_attributes.map((section, sectionIndex) => {
            section.line_items_attributes.map((line, lineIndex) => {
                this.inputOpenRefrences[`inputOpenFileRef${sectionIndex}${lineIndex}`] = React.createRef();
            })
        })
    }


    updateInsepctionFormState = () => {
        let InspectionForm = cloneDeep(this.props.inspection);
        if(InspectionForm && InspectionForm.id) {
            InspectionForm['portfolioImages'] = PortfolioProperties;
        }
        InspectionForm && InspectionForm.inspection_form && InspectionForm.inspection_form.sections_attributes.map((section, sectionIndex) => {
            section['id'] = InspectionForm.inspection_form.sections_attributes[sectionIndex].id;
            section.line_items_attributes.map((line, lineIndex) => {
                line['rating_type_id'] = InspectionForm.inspection_form.sections_attributes[sectionIndex].line_items_attributes[lineIndex].rating_type.id;
                line['portfolioImages'] = PortfolioProperties;
                line['full_attachment_url'] = [];
            })
        })

        this.setState({
            inspectionForm: InspectionForm
        })
    }

    componentWillUpdate(nextProps, nextState) {

        if (!InspectionData && this.state.inspectionForm === nextState.inspectionForm && isEmpty(this.state.inspectionForm) !== isEmpty(this.props.inspection)) {

            this.updateInsepctionFormState();
        }

    }

    componentWillMount = () => {
        if (InspectionData) {

            this.props.updateInspectionFormImages(InspectionData.inspection)
        }
    }

    componentDidUpdate(PrevProps, PrevState) {

        if (this.props.inspection !== PrevProps.inspection) {

            let InspectionData = {};
            let inspection = cloneDeep(this.props.inspection);

            this.updateFormData(inspection, { base64: false }, { addedurls: false }, { photoPaths: true });
            if (this.state.CurrentInspectonData) {

                InspectionData = this.state.CurrentInspectonData.inspection
            } else {
                InspectionData = inspection
            }
            InspectionData && InspectionData.inspection_form && InspectionData.inspection_form.sections_attributes.forEach((section, secIndex) => {
                section.line_items_attributes.forEach((line, lineIndex) => {
                    if (!isEmpty(line) && line.rating_type.name === 'Name') {
                        if ((line.comment && line.comment.length !== 0 || line.customer_rep_available)) {
                            {
                                this.setState({
                                    NameTypeSection: {
                                        isCustomerName: true,
                                        isFilled: true,
                                        secIndex: secIndex,
                                        // lineIndex: lineIndex
                                    }
                                })
                            }
                        } else {
                            this.setState({
                                NameTypeSection: {
                                    isCustomerName: true,
                                    isFilled: false,
                                    secIndex: secIndex,
                                    // lineIndex: lineIndex
                                }
                            })
                        }
                    }
                })
            })

            this.setState({
                saveAsDraft: false,
                submitTrue: false,
                imageLoading: false,
                // inspectionForm: InspectionData
            })
            if (isEmpty(this.inputOpenRefrences)) {
                this.createReferences();
            }
            // this.updateInsepctionFormState();

        if(this.props.criteries && this.props.criteries.length > 0 && this.props.inspection && this.props.inspection.inspection_form) {
            this.props.criteries.map((cri) => {
                if(this.props.inspection.inspection_form.criteria_id === cri.id.toString()) {
                    this.setState({selectedCriteria: cri.name})
                }
            })
        }
        }

        if (this.props.formStates && PrevProps.formStates) {
            if ((this.props.formStates.inspection !== PrevProps.formStates.inspection) || (this.props.inspection !== PrevProps.inspection)) {
                if (this.props.formStates.inspection !== PrevProps.formStates.inspection) {
                    this.setState({
                        isDataChanged: true
                    })
                }
                this.props.formStates.inspection.inspection_form.sections_attributes.forEach((section, secIndex) => {
                    section.line_items_attributes.forEach((line, lineIndex) => {
                        if (!isEmpty(line) && line.rating_type.name === 'Name') {

                            if ((line.comment && line.comment.length !== 0 || line.customer_rep_available)) {
                                {
                                    this.setState({
                                        NameTypeSection: {
                                            isCustomerName: true,
                                            isFilled: true,
                                            secIndex: secIndex,
                                            // lineIndex: lineIndex
                                        }
                                    })
                                }
                            } else {
                                this.setState({
                                    NameTypeSection: {
                                        isCustomerName: true,
                                        isFilled: false,
                                        secIndex: secIndex,
                                        // lineIndex: lineIndex
                                    }
                                })
                            }
                        }
                    })
                })
            }
        }
    }

    componentDidMount() {
        storage.remove('performInspectionData');
        // if (!this.props.ratingList) {
            this.props.getRatings({hide: false});
            this.props.getCriteriaList({hide: false});
            this.props.getCustomerOrInspectorQqvQuestions({type: 'feedback'});
        // }
        const inspection_id = storage.get('inspectionId');
        if (inspection_id !== this.props.match.params.inspectionId) {
            storage.remove('InspectionData');
            InspectionData = null;
        }
        if (isEmpty(this.props.inspection) && this.props.match.params.inspectionId && !InspectionData) {

            this.props.getInspection(this.props.match.params.inspectionId).then(res => {
                res.value.inspection_form.sections_attributes.forEach(section => {
                    section.line_items_attributes.forEach(line => {
                        if (line.name.toLowerCase().includes('customer contact') && line.comment && line.comment !== "" && line.comment !== " " && line.customer_rep_available !== true) {
                            this.setState({customerPresent: true})
                        }
                    })
                })
            })
        }
        // if (InspectionData) {
        //      
        //     this.props.updateInspectionFormImages(InspectionData)
        // }
    }

    componentWillUnmount() {
        this.props.resetObjects();
    }

    submitQuiz = (quiz1, quiz2, quiz3, props) => {   
        console.log('quiz', quiz1, quiz2, quiz3);
        let feedback = {};
        feedback['inspection_id'] = props.inspection.id 
        feedback['feedback_status'] = true
        feedback['feedback_attributes'] =  [
            {quiz_id: "1", answer: quiz1},
            {quiz_id: "2", answer: quiz2},
            {quiz_id: "3", answer: quiz3},
        ]
        this.props.qqvFeedback(feedback);
        
    }

    choosePhotoClicked = (sectionIndex, lineIndex) => {

        this.inputOpenRefrences[`inputOpenFileRef${sectionIndex}${lineIndex}`].current.click();
    }

    notesChoosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }
    updateFormData = (inspection, base64, addedurls, photoPaths) => {

        let updatedFormState = {};
        updatedFormState['inspection'] = {}

        if (this.props.formStates) {
            updatedFormState = cloneDeep(storage.get("performInspectionData", null));
            let IdsToDelete = cloneDeep(storage.get("IdsToDelete", null));

            if (inspection && inspection.idsToDelete) {

                if (!IdsToDelete) {
                    IdsToDelete = [];
                }
                if (IdsToDelete.indexOf(inspection.idsToDelete) === -1) {
                    IdsToDelete.push(inspection.idsToDelete);
                    this.setState({
                        idsToDelete: IdsToDelete
                    })
                }
            }

            if (inspection && inspection.inspection_form) {
                updatedFormState.inspection.inspection_form.sections_attributes.map((section) => {
                    inspection.inspection_form.sections_attributes.forEach((sec) => {
                        if (sec.id === section.id) {
                            section.line_items_attributes.map(line => {
                                sec.line_items_attributes.forEach(l => {
                                    if (l.attachments_attributes && line.id === l.id && addedurls && addedurls.addedurls) {
                                        // l.attachments_attributes.length > 0 &&
                                        line['attachments_attributes'] = l.attachments_attributes;
                                    }
                                    if (l.attachment_paths && line.id === l.id && photoPaths && photoPaths.photoPaths) {
                                        // l.attachment_paths.length > 0 &&
                                        line['attachment_paths'] = l.attachment_paths;
                                    }
                                    if (l.full_attachment_url && line.id === l.id && photoPaths && photoPaths.photoPaths) {
                                        line['full_attachment_url'] = l.full_attachment_url;
                                    }
                                    if (l.portfolioImages && line.id === l.id && base64 && base64.base64) {
                                        // l.portfolioImages.base64.length > 0 &&
                                        line.portfolioImages = cloneDeep(l.portfolioImages);
                                        line.portfolioImages.base64 = [];
                                        // line.portfolioImages.fileType.push(...l.portfolioImages.fileType);
                                        // line.portfolioImages.AllFileTypes.push(...l.portfolioImages.AllFileTypes);
                                        // line.portfolioImages.idsToDelete.push(...l.portfolioImages.idsToDelete);
                                        // line.portfolioImages.imageName.push(...l.portfolioImages.imageName);
                                        // line.portfolioImages.base64.push(...l.portfolioImages.base64);
                                    }
                                })
                            })
                        }
                    })
                })
            }
            storage.set('IdsToDelete', IdsToDelete);
            storage.set('performInspectionData', updatedFormState);
            // this.props.updateFormData(updatedFormState);
        }
    }

    saveLineItemsPortfolioImages = (sectionIndex, lineIndex, InspectionForm, FilesLength) => {

        if(sectionIndex !== undefined && lineIndex !== undefined ) {
        const Inspection = cloneDeep(this.props.inspection);
        let inspectionForm = cloneDeep(InspectionForm);
        const arrayBuffer = [];

        // if (inspectionForm.inspection_form.sections_attributes[sectionIndex].
        //     line_items_attributes[lineIndex].portfolioImages.base64.length === FilesLength) {
        this.updateFormData(inspectionForm, { base64: true }, { addedurls: false }, { photoPaths: false });
        // }


        const NewFiles = inspectionForm.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].portfolioImages.base64.splice(
                InspectionForm.inspection_form.sections_attributes[sectionIndex].
                    line_items_attributes[lineIndex].portfolioImages.base64.length - FilesLength
            );

        NewFiles.map((url, i) => {
            if (!url.photo_urls) {
                let base_photo = null;
                const image = url.split(',');
                base_photo = image[1];

                arrayBuffer.push(decode(base_photo))
            }

        })
        const NewImages = inspectionForm.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].portfolioImages.imageName.splice(
                InspectionForm.inspection_form.sections_attributes[sectionIndex].
                    line_items_attributes[lineIndex].portfolioImages.imageName.length - FilesLength
            );
        const extentions = extractExtention(NewImages);

        const lineItemId = InspectionForm.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].id;

        this.props.saveLineItemsPortfolioImages(arrayBuffer, extentions, lineItemId, sectionIndex, lineIndex, Inspection);
        } else {
        const Inspection = cloneDeep(this.props.inspection);
        let inspectionForm = cloneDeep(InspectionForm);
        const arrayBuffer = [];

        // if (inspectionForm.inspection_form.sections_attributes[sectionIndex].
        //     line_items_attributes[lineIndex].portfolioImages.base64.length === FilesLength) {
        this.updateFormData(inspectionForm, { base64: true }, { addedurls: false }, { photoPaths: false });
        // }


        const NewFiles = inspectionForm.portfolioImages.base64.splice(
                InspectionForm.portfolioImages.base64.length - FilesLength
            );

        NewFiles.map((url, i) => {
            if (!url.photo_urls) {
                let base_photo = null;
                const image = url.split(',');
                base_photo = image[1];

                arrayBuffer.push(decode(base_photo))
            }

        })
        const NewImages = inspectionForm.portfolioImages.imageName.splice(
                InspectionForm.portfolioImages.imageName.length - FilesLength
            );
        const extentions = extractExtention(NewImages);

        let lineItemId;

        this.props.saveLineItemsPortfolioImages(arrayBuffer, extentions, lineItemId, sectionIndex, lineIndex, Inspection);
        }
    }

    onSignatureAdded = async (sectionIndex, lineIndex, base64) => {
        let InspecionForm = cloneDeep(this.state.inspectionForm);

        InspecionForm.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].portfolioImages.faultImages = 0;

        InspecionForm.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].portfolioImages.imageName.push('signature.png');

        InspecionForm.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].portfolioImages.fileType.push('.png');

        InspecionForm.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].portfolioImages.AllFileTypes.push('.png');

        InspecionForm.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].portfolioImages.base64.push(base64);

        this.setState(prevState => {
            prevState.inspectionForm.inspection_form.sections_attributes.map((section, secIndex) => {
                if (secIndex === sectionIndex) {
                    section.line_items_attributes.map((line, liIndex) => {
                        if (lineIndex === liIndex) {
                            InspecionForm.inspection_form.sections_attributes[sectionIndex].
                                line_items_attributes[lineIndex].portfolioImages.base64 =
                                [...new Set([...InspecionForm.inspection_form.sections_attributes[sectionIndex].
                                    line_items_attributes[lineIndex].portfolioImages.base64,
                                ...line.portfolioImages.base64])];
                        }
                    })
                }
            })
            return { inspectionForm: InspecionForm }

        });

        this.setState({
            isSignatureEmpty: false,
            imageLoading: true
        })
        this.saveLineItemsPortfolioImages(sectionIndex, lineIndex, InspecionForm, 1);
    }


    onPortfolioFileChange = (e, sectionIndex, lineIndex) => {
        if(sectionIndex !== undefined && lineIndex !== undefined ) {
            let InspecionForm = cloneDeep(this.state.inspectionForm);
    
            InspecionForm.inspection_form.sections_attributes[sectionIndex].
                line_items_attributes[lineIndex].portfolioImages.faultImages = 0;
    
    
            let files = e.target.files;
            let imageName = [], fileType = [], AllFileTypes = [], base64 = [];
            const that = this;
            // for (let i = 0; i < files.length; i++) {
            async function readFile(index) {
                if (index < files.length) {
                    let file = files[index];
    
                    imageName.push(file.name);
    
                    InspecionForm.inspection_form.sections_attributes[sectionIndex].
                        line_items_attributes[lineIndex].portfolioImages.fileType.push(file.type);
    
                    InspecionForm.inspection_form.sections_attributes[sectionIndex].
                        line_items_attributes[lineIndex].portfolioImages.AllFileTypes.push(file.type);
    
                    if (file.type.match('image')) { 
                        let fileDate = await commonService.compressImage(file, {});
                        let reader = new FileReader();
                        reader.readAsDataURL(fileDate);
                        reader.onload = function () {
                            InspecionForm.inspection_form.sections_attributes[sectionIndex].
                            line_items_attributes[lineIndex].portfolioImages.base64.push(reader.result);
                        if (index === files.length - 1) {
                            console.log('line attributes',InspecionForm.inspection_form.sections_attributes[sectionIndex].line_items_attributes)
                
                            that.setState(prevState => {
                                prevState.inspectionForm.inspection_form.sections_attributes.map((section, secIndex) => {
                                    if (secIndex === sectionIndex) {
                                        section.line_items_attributes.map((line, liIndex) => {
                                            if (lineIndex === liIndex) {
                                                InspecionForm.inspection_form.sections_attributes[sectionIndex].
                                                    line_items_attributes[lineIndex].portfolioImages.base64 =
                                                    [...new Set([...InspecionForm.inspection_form.sections_attributes[sectionIndex].
                                                        line_items_attributes[lineIndex].portfolioImages.base64,
                                                    ...line.portfolioImages.base64])];
                                            }
                                        })
                                    }
                                })
                
                                return { inspectionForm: InspecionForm }
                
                            });
                
                            if (InspecionForm.inspection_form.sections_attributes[sectionIndex]
                                .line_items_attributes[lineIndex].portfolioImages.base64.length >= files.length) {
                
                                that.setState({
                                    imageLoading: true
                                })
                                that.saveLineItemsPortfolioImages(sectionIndex, lineIndex, InspecionForm, files.length);
                            }
                        }
    
    
    
                            if (index === files.length - 1) {
    
                                that.setState(prevState => {
                                    prevState.inspectionForm.inspection_form.sections_attributes.map((section, secIndex) => {
                                        if (secIndex === sectionIndex) {
                                            section.line_items_attributes.map((line, liIndex) => {
                                                if (lineIndex === liIndex) {
                                                    InspecionForm.inspection_form.sections_attributes[sectionIndex].
                                                        line_items_attributes[lineIndex].portfolioImages.imageName =
                                                        [...new Set([...InspecionForm.inspection_form.sections_attributes[sectionIndex].
                                                            line_items_attributes[lineIndex].portfolioImages.imageName,
                                                        ...line.portfolioImages.imageName])];
    
                                                    InspecionForm.inspection_form.sections_attributes[sectionIndex].
                                                        line_items_attributes[lineIndex].portfolioImages.fileType =
                                                        [...new Set([...InspecionForm.inspection_form.sections_attributes[sectionIndex].
                                                            line_items_attributes[lineIndex].portfolioImages.fileType
                                                            , ...line.portfolioImages.fileType])];
    
                                                    InspecionForm.inspection_form.sections_attributes[sectionIndex].
                                                        line_items_attributes[lineIndex].portfolioImages.AllFileTypes =
                                                        [...new Set([...InspecionForm.inspection_form.sections_attributes[sectionIndex].
                                                            line_items_attributes[lineIndex].portfolioImages.AllFileTypes,
                                                        ...line.portfolioImages.AllFileTypes])];
                                                }
                                            })
                                        }
                                    })
    
                                    return { inspectionForm: InspecionForm }
    
                                });
                                console.log(this.state);
    
                            }
                            readFile(index + 1);
                        };
                        reader.onerror = function (error) {
                            console.log("Error: ", error);
                        }
                    }
                }
                InspecionForm.inspection_form.sections_attributes[sectionIndex].
                        line_items_attributes[lineIndex].portfolioImages.imageName = imageName;
            }
    
            readFile(0);
        } else {

            let InspecionForm = cloneDeep(this.state.inspectionForm);
    
            InspecionForm.faultImages = 0;
    
            let files = e.target.files;
            let imageName = [], fileType = [], AllFileTypes = [], base64 = [];
            const that = this;
            // for (let i = 0; i < files.length; i++) {
            async function readFile(index) {
                if (index < files.length) {
                    let file = files[index];
    
                    imageName.push(file.name);
    
                    InspecionForm.portfolioImages.fileType.push(file.type);
    
                    InspecionForm.portfolioImages.AllFileTypes.push(file.type);
    
                    if (file.type.match('image')) { 
                        let fileDate = await commonService.compressImage(file, {});
                        let reader = new FileReader();
                        reader.readAsDataURL(fileDate);
                        reader.onload = function () {
                            InspecionForm.portfolioImages.base64.push(reader.result);
                        if (index === files.length - 1) {
                            that.setState(prevState => {
                                                InspecionForm.portfolioImages.base64 =
                                                    [...new Set([...InspecionForm.portfolioImages.base64,
                                                    ...InspecionForm.portfolioImages.base64])];
                
                                return { inspectionForm: InspecionForm }
                
                            });
                
                            if (InspecionForm.portfolioImages.base64.length >= files.length) {
                
                                that.setState({
                                    imageLoading: true
                                })
                                that.saveLineItemsPortfolioImages(sectionIndex, lineIndex, InspecionForm, files.length);
                            }
                        }
    
    
    
                            // if (index === files.length - 1) {
    
                            //     that.setState(prevState => {
                            //         prevState.inspectionForm.inspection_form.sections_attributes.map((section, secIndex) => {
                            //             if (secIndex === sectionIndex) {
                            //                 section.line_items_attributes.map((line, liIndex) => {
                            //                     if (lineIndex === liIndex) {
                            //                         InspecionForm.inspection_form.sections_attributes[sectionIndex].
                            //                             line_items_attributes[lineIndex].portfolioImages.imageName =
                            //                             [...new Set([...InspecionForm.inspection_form.sections_attributes[sectionIndex].
                            //                                 line_items_attributes[lineIndex].portfolioImages.imageName,
                            //                             ...line.portfolioImages.imageName])];
    
                            //                         InspecionForm.inspection_form.sections_attributes[sectionIndex].
                            //                             line_items_attributes[lineIndex].portfolioImages.fileType =
                            //                             [...new Set([...InspecionForm.inspection_form.sections_attributes[sectionIndex].
                            //                                 line_items_attributes[lineIndex].portfolioImages.fileType
                            //                                 , ...line.portfolioImages.fileType])];
    
                            //                         InspecionForm.inspection_form.sections_attributes[sectionIndex].
                            //                             line_items_attributes[lineIndex].portfolioImages.AllFileTypes =
                            //                             [...new Set([...InspecionForm.inspection_form.sections_attributes[sectionIndex].
                            //                                 line_items_attributes[lineIndex].portfolioImages.AllFileTypes,
                            //                             ...line.portfolioImages.AllFileTypes])];
                            //                     }
                            //                 })
                            //             }
                            //         })
    
                            //         return { inspectionForm: InspecionForm }
    
                            //     });
                            //     console.log(this.state);
    
                            // }
                            readFile(index + 1);
                        };
                        reader.onerror = function (error) {
                            console.log("Error: ", error);
                        }
                    }
                }
                InspecionForm.portfolioImages.imageName = imageName;
            }
    
            readFile(0);
        }

    }


    removeImage = (url, fileTypeIndex, lineIndex, sectionIndex) => {
        let UpdatedInspectionForm = cloneDeep(this.state.inspectionForm);
        let InspcetionFormWithAttatchmentAttributes = cloneDeep(this.props.inspection)
        // let updatedUrls = [...this.state.inspectionForm.sections_attributes[sectionIndex].line_items_attributes[lineIndex].portfolioImages.base64];

        // let updatedFileTypes = [...this.state.fileType]
        // let updatedImageNames = [...this.state.imageName]

        let urlIndex = this.props.inspection.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].full_attachment_url.findIndex(function (u) {
                return u === url
            })

        InspcetionFormWithAttatchmentAttributes.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].full_attachment_url.splice(urlIndex, 1);

        UpdatedInspectionForm.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].portfolioImages.fileType.splice(fileTypeIndex, 1);

        UpdatedInspectionForm.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].portfolioImages.imageName.splice(fileTypeIndex, 1);

        InspcetionFormWithAttatchmentAttributes.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].attachment_paths.splice(urlIndex, 1);

        // updatedUrls.splice(urlIndex, 1);
        // updatedFileTypes.splice(fileTypeIndex, 1)
        // updatedImageNames.splice(fileTypeIndex, 1)


        UpdatedInspectionForm.inspection_form.sections_attributes.map(section => {
            InspcetionFormWithAttatchmentAttributes.inspection_form.sections_attributes.map(sec => {
                section.line_items_attributes.map(line => {
                    sec.line_items_attributes.map(l => {

                        line.full_attachment_url = l.full_attachment_url;
                        line['attachment_paths'] = l.attachment_paths;
                    })
                })
            })
        })

        this.setState({
            inspectionForm: UpdatedInspectionForm
        })

        this.updateFormData(UpdatedInspectionForm, { base64: true }, { addedurls: false }, { photoPaths: true });
        this.props.updateInspectionFormImages(InspcetionFormWithAttatchmentAttributes);

    }

    removeUrls = (url, i, lineIndex, sectionIndex) => {

        let UpdatedInspectionForm = cloneDeep(this.props.inspection);

        if (url.photo_urls) {
            this.setState(prevState => ({
                idsToDelete: [...prevState.idsToDelete, url.id]
            }))
        }

        let urlIndex = UpdatedInspectionForm.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].attachments_attributes.findIndex(function (u) {
                return u.id === url.id
            })

        UpdatedInspectionForm.inspection_form.sections_attributes[sectionIndex].
            line_items_attributes[lineIndex].attachments_attributes.splice(urlIndex, 1);

        UpdatedInspectionForm['idsToDelete'] = '';
        UpdatedInspectionForm.idsToDelete = url.id;
        this.updateFormData(UpdatedInspectionForm, { base64: false }, { addedurls: true }, { photoPaths: false });
        this.props.updateInspectionFormImages(UpdatedInspectionForm);
    }


    removeNotesImage = (url, fileTypeIndex,) => {
        let UpdatedInspectionForm = cloneDeep(this.state.inspectionForm);
        let InspcetionFormWithAttatchmentAttributes = cloneDeep(this.props.inspection)
        let urlIndex = this.props.inspection.full_attachment_url.findIndex(function (u) {
                return u === url
            })

        InspcetionFormWithAttatchmentAttributes.full_attachment_url.splice(urlIndex, 1);

        UpdatedInspectionForm.portfolioImages.fileType.splice(fileTypeIndex, 1);

        UpdatedInspectionForm.portfolioImages.imageName.splice(fileTypeIndex, 1);

        InspcetionFormWithAttatchmentAttributes.attachment_paths.splice(urlIndex, 1);

        UpdatedInspectionForm.full_attachment_url = InspcetionFormWithAttatchmentAttributes.full_attachment_url;
        UpdatedInspectionForm['attachment_paths'] = InspcetionFormWithAttatchmentAttributes.attachment_paths;
    

        this.setState({
            inspectionForm: UpdatedInspectionForm
        })

        this.updateFormData(UpdatedInspectionForm, { base64: true }, { addedurls: false }, { photoPaths: true });
        this.props.updateInspectionFormImages(InspcetionFormWithAttatchmentAttributes);

    }

    removeNotesUrls = (url, i) => {

        let UpdatedInspectionForm = cloneDeep(this.props.inspection);

        if (url.photo_urls) {
            this.setState(prevState => ({
                idsToDelete: [...prevState.idsToDelete, url.id]
            }))
        }

        let urlIndex = UpdatedInspectionForm.attachments.findIndex(function (u) {
                return u.id === url.id
            })

        UpdatedInspectionForm.attachments.splice(urlIndex, 1);

        UpdatedInspectionForm['idsToDelete'] = '';
        UpdatedInspectionForm.idsToDelete = url.id;
        this.updateFormData(UpdatedInspectionForm, { base64: false }, { addedurls: true }, { photoPaths: false });
        this.props.updateInspectionFormImages(UpdatedInspectionForm);
    }


    performInspection = (data, status, feedbackStatus, quiz) => {debugger

        let values = cloneDeep(data);
        if(quiz){
            let inspectorFeedback ={}
            inspectorFeedback['feedback_attributes'] = [
                {
                    answer: quiz.quiz6.trim(),
                    quiz_id: "6"
                },
                {
                    quiz_id: "7",
                    is_yes: quiz.quiz7a === 'yes' ? true : false,
                    answer: quiz.quiz7b.trim()
                },
                {
                    quiz_id: "8",
                    is_yes: quiz.quiz8a === 'yes' ? true : false,
                    answer: quiz.quiz8b.trim()
                }
            ]
            inspectorFeedback['inspection_id'] = this.props.inspection.id
            this.props.inspectorFeedback({inspectorFeedback})
        }
        values.inspection['feedback_active_status'] = feedbackStatus      //should be false if both customer not available
        let signatureImageEmpty = false;
        const that = this;
        if (navigator.geolocation) {
            // Call getCurrentPosition with success and failure callbacks

            navigator.geolocation.getCurrentPosition(function showLocation(position) {
                var latitude = position.coords.latitude;
                var longitude = position.coords.longitude;

                values.inspection['addresses_attributes'] = [{
                    id: that.props.inspection.address.end_location.id,
                    latitude: latitude,
                    longitude: longitude
                }]


                let Inspection = cloneDeep(that.props.inspection);
                if (values.inspection && Inspection) {
                    values.inspection['inspection_id'] = that.props.match.params.inspectionId;
                    values.inspection['end_time'] = new Date();
                    values.inspection['attachment_paths'] = Inspection.attachment_paths;
                    values.inspection.inspection_form['id'] = Inspection.inspection_form.id;
                    if (Inspection ) {
                        var customersListAvailable = false;
                        var customersListUnvailable = false;
                        // var customer = 'any'
                        that.props.formStates.inspection.inspection_form.sections_attributes.map(section => {
                            section.line_items_attributes.map(line => {
                                if(line.name.includes('Customer Contact')) {
                                    if(line.customer_rep_available === false) {
                                        customersListAvailable = true
                                    } else {
                                        customersListUnvailable = true
                                    }
                                }
                            })
                        })
                        if((customersListAvailable === true && customersListUnvailable === false) || (customersListAvailable === true && customersListUnvailable === true)) {
                            values.inspection['qqv_status'] = 'qqv_customer'
                        } 
                        if (customersListAvailable === false && customersListUnvailable === true) {
                            values.inspection['qqv_status'] = 'nonqqv_customer'
                        }
                    }
                    values.inspection.inspection_form.sections_attributes.map((section, sectionIndex) => {
                        section['id'] = Inspection.inspection_form.sections_attributes[sectionIndex].id;
                        section.line_items_attributes.map((line, lineIndex) => {

                            if (!line.attachment_paths || line.attachment_paths === 0) {
                                line['attachment_paths'] = Inspection.inspection_form.sections_attributes[sectionIndex].line_items_attributes[lineIndex].attachment_paths;
                            }

                            const RatingOptionAttributes = line.rating_type.rating_options_attributes.map(ro => {
                                return {
                                    id: ro.id,
                                    name: ro.name
                                }
                            });
                            const isSignatureTrue = line.rating_type.category === RatingTypes.Percentage && RatingOptionAttributes[0].name === 'Signature';
                            if (isSignatureTrue) {

                                if ((!line.attachment_paths || (line.attachment_paths && line.attachment_paths.length === 0)) && ((line.attachments_attributes.length === 0) || (line.attachments_attributes[0] && that.state.idsToDelete.includes(line.attachments_attributes[0].id)))) {
                                    signatureImageEmpty = true;
                                    that.setState({
                                        isSignatureEmpty: true
                                    })
                                } else {
                                    signatureImageEmpty = false;
                                    that.setState({
                                        isSignatureEmpty: false
                                    })
                                }
                            } else {
                                signatureImageEmpty = false;
                                that.setState({
                                    isSignatureEmpty: false
                                })
                            }
                            line['rating_type_id'] = parseInt(Inspection.inspection_form.sections_attributes[sectionIndex].line_items_attributes[lineIndex].rating_type.id);
                            delete line.rating_type;
                            // line['rating_option_id'] = props.inspectionForm.inspection_form.sections_attributes[sectionIndex].line_items_attributes[lineIndex].rating_type.id;
                        })
                    })
                }

                if (!signatureImageEmpty) {
                    if (status.active_status === 'pending' || status.active_status === 'completed') {

                    if(Inspection.feedback) {
                        values.inspection['active_status'] = 'completed';
                    } else {
                        values.inspection['active_status'] = status.active_status;
                    }
                        that.setState({
                            submitTrue: true
                        })
                    } else {
                        that.setState({
                            saveAsDraft: true
                        })
                    }

                    values.inspection['updated'] = true;
                    // values.inspection['platform'] = 'web'
                    values.isEdit = that.props.location && that.props.location.state && that.props.location.state.isEdit ? that.props.location.state.isEdit : false;
                    that.props.performInspection(values, that.state.idsToDelete);
                }
            },
                function errorHandler(err) {
                    if (err.code == 1) {
                        errorHandeler("Access is denied!");
                    } else if (err.code == 2) {
                        errorHandeler("Position is unavailable!");
                    }
                }
            );
        } else {
            errorHandeler("Please enable your location access.")
        }
    }

    customerContactInput = (e, sectionIndex, lineIndex, from) => {
        if(from === 'checkbox') {
            this.setState({customerPresent: false})
        } else {
            const CurrentInspectonData = storage.get("performInspectionData", null);
            console.log(this.props.inspection);
            if(this.props.inspection && this.props.inspection['inspection_form']['sections_attributes'][sectionIndex]['line_items_attributes'][lineIndex].name.toLowerCase().includes('customer contact')) {
                if (e.target.value.length > 0) {
                    this.setState({customerPresent: true})
                } else {
                    this.setState({customerPresent: false})
                }
            }
        }
    }

    onCancelClicked = () => {
        // storage.remove('IdsToDelete');
        // storage.remove('performInspectionData');
        // this.setState({
        //     cancelClicked: true
        // })
        // this.props.history.push(routes.INSPECTIONS);
    }

    makeCancelInspectionStateFalse = () => {
        this.setState({
            cancelClicked: false
        })
    }

    resetSearchForm = () => {
        scroll.scrollToTop();
        document.getElementById("searchForm").reset();
    }
    searchValuechanged = (sections) => {
        sections.inspection.inspection_form.sections_attributes.map((sec, i) => {
            if (sec.id === sections.sectionSelect) {
                scroller.scrollTo(`inspection[inspection_form][sections_attributes][${i}][line_items_attributes][0][comment]`, {
                    duration: 500,
                    delay: 1,
                    smooth: true,
                    offset: -250, // Scrolls to element + 50 pixels down the page
                })
                scroller.scrollTo(scrollTo => scrollTo + 1);
            }
        })
    }

    render() {

        if (!this.props.inspection?.inspection_form) {
            return (
                <div className="loader_btn_block" >
                    <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                </div>
            )
        }
        return (
            <PerformInspection
                match={this.props.match}
                onSubmit={this.searchValuechanged}
                isDataChanged={this.state.isDataChanged}
                history={this.props.history}
                isSignatureEmpty={this.state.isSignatureEmpty}
                cancelClicked={this.state.cancelClicked}
                saveAsDraft={this.state.saveAsDraft}
                submitTrue={this.state.submitTrue}
                NameTypeSection={this.state.NameTypeSection}
                performInspection={this.performInspection}
                isLoading={this.props.isLoading}
                inspection={this.props.inspection}
                portfolioImages={this.state.inspectionForm}
                imageLoading={this.state.imageLoading}
                removeImage={this.removeImage}
                removeUrls={this.removeUrls}
                removeNotesImage={this.removeNotesImage}
                removeNotesUrls={this.removeNotesUrls}
                inspectionForm={this.props.inspectionForm}
                onCancelClicked={this.onCancelClicked}
                choosePhotoClicked={this.choosePhotoClicked}
                notesChoosePhotoClicked={this.notesChoosePhotoClicked}
                onSignatureAdded={this.onSignatureAdded}
                onPortfolioFileChange={this.onPortfolioFileChange}
                references={this.inputOpenRefrences}
                refs={this.inputOpenFileRef}
                ratingList={this.props.ratingList ? this.props.ratingList : []}
                makeCancelInspectionStateFalse={this.makeCancelInspectionStateFalse}
                blockInspectionRoute={this.props.blockInspectionRoute}
                submitQuiz={this.submitQuiz}
                customerContactInput={this.customerContactInput}
                customerPresent={this.state.customerPresent}
                mode={this.state.mode}
                resetSearchForm={this.resetSearchForm}
                selectedCriteria={this.state.selectedCriteria}
                qqvQuestion={this.props.qqvQuestion}
                getCustomerOrInspectorQqvQuestions={this.props.getCustomerOrInspectorQqvQuestions}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        inspectionForm: state.adminOrSuperAdminReducer.inspectionForm,
        inspection: state.adminOrSuperAdminReducer.inspection,
        isLoading: state.adminOrSuperAdminReducer.prformInspectionLoading,
        ratingList: state.adminOrSuperAdminReducer.ratingList,
        blockInspectionRoute: state.adminOrSuperAdminReducer.blockInspectionRoute,
        criteries: state.adminOrSuperAdminReducer.criteries,
        formStates: getFormValues('performInspectionForm')(state),
        qqvQuestion: state.adminOrSuperAdminReducer.qqvQuestion,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveLineItemsPortfolioImages: (arrayBuffer, extentions, lineItemId, sectionIndex, lineIndex, Inspection) => dispatch(actions.saveLineItemsPortfolioImages(arrayBuffer, extentions, lineItemId, sectionIndex, lineIndex, Inspection)),
        updateInspectionFormImages: (UpdatedInspectionForm) => dispatch(actions.updateInspectionFormImages(UpdatedInspectionForm)),
        performInspection: (values, idsToDelete) => dispatch(actions.performInspection(values, idsToDelete)),
        getEditInspectionForm: (id) => dispatch(actions.getEditInspectionForm(id)),
        getInspection: (id) => dispatch(actions.getInspection(id)),
        getInspectionForm: () => dispatch(actions.getInspectionForms()),
        resetObjects: () => dispatch(actions.resetObjects()),
        resetInspectionSearchForm: (resetInspectionSearchForm) => dispatch(reset(resetInspectionSearchForm)),
        getRatings: (data) => dispatch(actions.getRatings(data)),
        updateFormData: (InspecionForm) => dispatch(initialize("performInspectionForm", InspecionForm)),
        qqvFeedback: (values) => dispatch(actions.qqvFeedback(values)),
        getCriteriaList: (data) => dispatch(actions.getCriteriaList(data)),
        getCustomerOrInspectorQqvQuestions: (params) => dispatch(actions.getCustomerOrInspectorQqvQuestions(params)),
        inspectorFeedback: (body) => dispatch(actions.inspectorFeedback(body)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformInspectionContainer);