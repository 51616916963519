import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../styles/custom.css';
import 'react-table-v6/react-table.css';
import { CustomNoDataComponent, getWeekStartAndEndDay, convertStringToHtml, extractContent, convertDateToDifferentTZ, getUser } from '../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { userRoles, Status, routes, TimeZones } from '../../../utility/constants/constants';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import ReactTooltip from 'react-tooltip';
import { makeFirstLetterCapital, trimString, toatlSaleSortValues, descSortvalue, statusSortValue, convertDateTimezone } from '../../../utility/utility';
const cloneDeep = require('clone-deep');
var dateFormat = require('dateformat');
var moment = require('moment');
var momentTZ = require('moment-timezone');

const QuotesTable = (props) => {
    const dispatch = useDispatch();
    var SearchValue = cloneDeep(useSelector(state => state.adminOrSuperAdminReducer.searchQuoteValues));
    const resendEmailLoading = useSelector(state => state.adminOrSuperAdminReducer.resendEmailLoading);
    const quoteObject = useSelector(state => state.adminOrSuperAdminReducer.quote);
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.quotes_page);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.quotes_rows);
    const searchOrResetQuoteClicked = useSelector(state => state.adminOrSuperAdminReducer.searchOrResetQuoteClicked);
    const quotePage = useSelector(state => state.adminOrSuperAdminReducer.quoteCurPage);
    const quote_rows = useSelector(state => state.adminOrSuperAdminReducer.quote_page_rows);

    function filterCaseInsensitive(filter, row) {

        const id = filter.pivotId || filter.id;
        return (
            id === 'status' ?
                String(row.status.props.children["0"].props.children) === Status.APPROVED ? ('on').indexOf(filter.value.toLowerCase()) > -1 :
                    ('off').indexOf(filter.value.toLowerCase()) > -1
                :
                // row[id] !== undefined ?
                //   row[id] == null ?
                //     String(row[id]).indexOf(filter.value.toLowerCase()) > -1 :
                //     id == 'lastUpdated' ?
                //       String(dateFormat(row[id].props.children, "mm/dd/yyyy").toString().toLowerCase()).indexOf(filter.value.toLowerCase()) > -1 :
                //       String(row[id].toString().toLowerCase()).indexOf(filter.value.toLowerCase()) > -1
                //   :
                true
        );
    }


    const status = (role, quote) => {
        const status = quote.status;
        if (role === userRoles.REQUESTOR || role === userRoles.INSPECTOR) {
            if (status === Status.APPROVED_BY_SUPERADMIN || status === Status.APPROVED_BY_ADMIN || status === Status.AUTO_APPROVED_QUOTE) {
                return <span className="btn cst_btn btn_request">{makeFirstLetterCapital(status.replace(/_/g, " "))}</span>;
            } else if (status === Status.CONFIRM_BY_CLIENT) {
                return <span className="btn cst_btn btn_request">{'Confirmed by Customer'}</span>;
            } else if (status === Status.DECLINED_BY_CLIENT) {
                return <span className={"btn cst_btn btn_approved"}>{'Expired'}</span>;
            } else if (status === Status.REJECTED_BY_ADMIN || status === Status.REJECTED_BY_SUPERADMIN) {
                return <span className={"btn cst_btn btn_approved"}>{makeFirstLetterCapital(status.replace(/_/g, " "))}</span>;
            } else if (status === Status.REQUESTED) {
                return <span className="btn cst_btn btn_request">{makeFirstLetterCapital(status.replace(/_/g, " "))}</span>
            } else if (status === Status.DRAFT) {
                return <span className="btn cst_btn btn_approved">{makeFirstLetterCapital(status.replace(/_/g, " "))}</span>
            }
            return status;
        } else if (role === userRoles.SUPER_ADMIN || role === userRoles.ADMIN) {
            if (status === Status.APPROVED_BY_SUPERADMIN || status === Status.APPROVED_BY_ADMIN || status === Status.AUTO_APPROVED_QUOTE) {
                return <span className={"btn cst_btn btn_request"}>{makeFirstLetterCapital(status.replace(/_/g, " "))}</span>;
            } else if (status === Status.CONFIRM_BY_CLIENT) {
                return <span className="btn cst_btn btn_request">{'Confirmed by Customer'}</span>;
            } else if (status === Status.DECLINED_BY_CLIENT) {
                return <span className={"btn cst_btn btn_approved"}>{'Expired'}</span>;
            } else if (status === Status.REJECTED_BY_ADMIN || status === Status.REJECTED_BY_SUPERADMIN) {
                return <span className={"btn cst_btn btn_approved"}>{makeFirstLetterCapital(status.replace(/_/g, " "))}</span>;
            } else if (status === Status.REQUESTED) {
                return (
                    <>
                        <a href="javascript:void(0)" onClick={() => props.approvedCliked(quote)} className="btn cst_btn btn_danger mr-2"><i className="fa fa-check" aria-hidden="true"></i>
                            Approve</a>
                        <button onClick={() => props.openRejectModel(quote)} type="button" className="btn cst_btn btn-outline-secondary" data-toggle="modal" data-target="#rejectQuoteModal" data-backdrop="static" data-keyboard="false"><i className="fa fa-times" aria-hidden="true"></i>
                            Reject</button>
                    </>
                )
            } else if (status === Status.DRAFT) {
                return <span className="btn cst_btn btn_approved">{makeFirstLetterCapital(status.replace(/_/g, " "))}</span>
            }
        }
    }

    const setTotalSaleValue = (id, totalSale) => {

        const index = props.totalSalesArray.findIndex(ts => ts[0] === id);

        if (index >= 0) {
            return props.totalSalesArray[index][1];
        } else {
            return totalSale ? totalSale : '0';
        }
    }
    const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()

    if(quotePage !== tablePageNew.current) {
        tablePageNew.current = quotePage;
    }

    const setTablePageFn = (page) => {
        setTablePage(page)
        props.quotePageClicked(page)
        tablePageNew.current = page;
    }
    const fetchData = (state, instance) => {

        if (searchOrResetQuoteClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {

            if (!DateGenerate && !props.isQuotesLoading) {
                state.page = 2;
                const quotes_no_of_rows = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : quotePage + 1;
                if(quote_rows != quotes_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                const sorted = state.sorted;
                const filtered = state.filtered;

                if (props.history.location.pathname === routes.VIEW_FILTERED_QUOTES) {
                    // if (!props.filteredQuotesList) {

                    props.updateWeekMonthYear(props.history.location.state.isWeek, props.history.location.state.isMonth, props.history.location.state.isYear);
                    props.updateCurrentDate(props.history.location.state.currentDate);
                    if (props.history.location.state.isWeek) {

                        let startAndEndDate = getWeekStartAndEndDay(props.history.location.state.currentDate);
                        startAndEndDate.firstday = moment(startAndEndDate.firstday).utc().toISOString();
                        startAndEndDate.lastday = moment(startAndEndDate.lastday).utc().toISOString();
                        const SearchValues = { ...startAndEndDate, ...props.history.location.state };
                        SearchValues.currentDate = moment(SearchValues.currentDate).utc().toISOString();
                        // props.viewWeeklyFilterQuotes(props.history.location.state.Status, startAndEndDate.firstday, startAndEndDate.lastday, props.history.location.state.currentDate);
                        props.viewWeeklyFilterQuotes(
                            SearchValues,
                            quotes_no_of_rows,
                            page,
                            sorted,
                            filtered
                        );
                    } else if (props.history.location.state.isMonth || props.history.location.state.isYear) {
                        let currentDate = moment(props.history.location.state.currentDate, 'MM/DD/YYYY').startOf('day');
                        const month = currentDate.format('M');
                        const year = currentDate.format('YYYY');
                        const SearchValues = { ...props.history.location.state };
                        SearchValues.currentDate = moment(currentDate._d).utc().toISOString();
                        // .toISOString();

                        // props.viewMonthlyAndYearlyFilterQuotes(props.history.location.state.Status, month, year, props.history.location.state.isMonth, props.history.location.state.isYear, props.history.location.state.currentDate);
                        props.viewMonthlyAndYearlyFilterQuotes(
                            SearchValues,
                            quotes_no_of_rows,
                            page,
                            sorted,
                            filtered
                        );
                    }
                    // }
                } else {
                    if(SearchValue === null || SearchValue === undefined) {
                        SearchValue = {}
                        SearchValue.value = '';
                    } else {
                    // SearchValue.value = SearchValue;
                    }
                    if (SearchValue && (SearchValue.fromDate || SearchValue.toDate)) {

                        SearchValue.fromDate = convertDateToDifferentTZ(SearchValue.fromDate, 'from');
                        SearchValue.toDate = convertDateToDifferentTZ(SearchValue.toDate, 'to');
                    }
                    dispatch(actions.getQuotes(
                        SearchValue,
                        quotes_no_of_rows,
                        page,
                        sorted,
                        filtered));
                    // }
                }
            }
        }
    }

    const data = props.quotesList.map(quote => {
        console.log(this);
        //  
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                console.log('do validate');
                props.submitTotalSaleClicked(null, quote.id);
            }
        }
        const getFormattedZone = (timezone) => {
            let zone = ''
            if (timezone === 'America/Los_Angeles') {
                 zone = 'PST'
            } else if ( timezone === 'America/Chicago') {
                 zone = 'CST'
            }
            return '(' + zone + ')';
          }
        
        let createdFormatted = '';
        if (quote.date_filter) {
            createdFormatted = moment(quote.created_at.replace(/-/gi, '/')).format('MM/DD/YYYY') + '(PST)';
        } else {
            createdFormatted = convertDateTimezone(quote.created_at, quote.account.timezone);
        }
        return {
            // image: <img src="../images/thumbnails/user_img1.jpg" alt="profile" />,
            id: quote.id,
            created_at: createdFormatted,
            account: makeFirstLetterCapital(quote.account.name),
            account_id: quote.account.id,
            elapsedDays: 
            (quote.status === Status.DECLINED_BY_CLIENT) ?
            <span>-</span> :
            quote.elapsed_days,
            totalSale:
                // quote.tag_opportunity ?
                //     <span className='number col_total_sales'>
                //         <div className="control-group priceing_edit">
                //             {props.totalSaleClickedQuoteId.includes(quote.id) ?
                //                 <>
                //                     <input type="number" style={{ display: 'block' }} onKeyDown={handleKeyDown} onChange={(e) => props.totalSalesChange(e, quote.id)} value={setTotalSaleValue(quote.id, quote.total_sale.replace(/\,/g, ""))} className="edit-input" size="5" maxlength="5" />
                //                     <span style={{ display: 'none' }}>{quote.total_sale}</span>
                //                     <div className="controls">
                //                         <a className="edit" href="JavaScript:void(0);" onClick={(e) => props.submitTotalSaleClicked(e, quote.id)} ><i className="fa fa-pencil" aria-hidden="true"></i></a>
                //                     </div>
                //                 </>
                //                 :
                //                 <>
                                quote.total_sale == "NaN.00" ? 
                                <span style={{ color: '#8a2b3c' }}>-</span> :
                                    <label for="name" className="control-label"><p style={{ color: '#8a2b3c' }}>${quote.total_sale}</p></label> 
                                    

                            //     </>
                            // }
                    //     </div>
                    // </span>
                    // :
                    // null,
                    ,
            requestor: quote.requestor ? quote.requestor.first_name  + ' ' + quote.requestor.last_name : '',

            description:
                quote.tag_opportunity ?
                    props.clickedQuoteId.includes(quote.id) ?
                        <>{quote.description ? ReactHtmlParser(quote.description) : ''}
                            <a style={{ color: '#89073c', textDecoration: 'none' }} onClick={(e) => props.lessDescClicked(e, quote.id)} href="javascript:void (0)" id="toggle" className="more_link"> ...Less</a></> :
                        <>
                            <span>{trimString(String(quote.description ? quote.description : ''), 70, quote.manual)}</span>
                            {
                                quote.manual ? extractContent(quote.description) > 70 ?
                                    <a style={{ color: '#89073c', textDecoration: 'none' }} onClick={(e) => props.moreDescClicked(e, quote.id)} href="javascript:void (0)" id="toggle" className="more_link"> ...More</a>
                                    : null
                                    :
                                    String(quote.description).length > 70 ?
                                        <a style={{ color: '#89073c', textDecoration: 'none' }} onClick={(e) => props.moreDescClicked(e, quote.id)} href="javascript:void (0)" id="toggle" className="more_link"> ...More</a>
                                        : null
                            }
                        </>
                    : 'No Quote Identified',
            // phonenumber: '810-191-8851',
            // address: '4909 Murphy Canyon Rd # 200, San Diego, CA 92123',

            // moment(convertDateToDifferentTZ(quote.created_at, TimeZones.PST)).format('MM-DD-YYYY'),
            // dateFormat(quote.created_at, "mm-dd-yyyy"),
            region: quote.region ? quote.region.name : null,
            status: quote.tag_opportunity ? status(props.user.role, quote) : <span className="btn cst_btn btn_NA">N/A</span>,
            resendEmail:
                (quote.status === Status.APPROVED_BY_ADMIN
                    || quote.status === Status.APPROVED_BY_SUPERADMIN) ?
                    <>
                        <span className="mr_20 account_edit_icn">
                            {(resendEmailLoading && quoteObject.id === quote.id) ? <ClapSpinner backColor="#89073c" size={20} frontColor="#89073c8a" /> :
                                <i data-tip="Resend Email" className="fas fa-envelope" aria-hidden="true" onClick={() => props.resendEmailCliked(quote)}></i>}
                        </span>
                        <ReactTooltip place="top" type="dark" effect="solid" />
                    </> : null,
            actions:
                <>
                <span className="mr_20 account_edit_icn" data-toggle="modal" data-target="#viewQuotesCC" onClick={() => props.viewQuoteStatusClicked(quote.id)} ><i className="fas fa-eye cst_icon mr-2" aria-hidden="true"></i></span>
                    {((props.user.role === userRoles.REQUESTOR || props.user.role === userRoles.INSPECTOR) && (quote.status === Status.DRAFT)) ||
                        (props.user.role === userRoles.ADMIN || props.user.role === userRoles.SUPER_ADMIN) && (quote.status === Status.DRAFT) && !props.fromDashboard ?
                        <span className="mr_20 account_edit_icn" onClick={() => props.editQuoteClicked(quote)}><i class="fa fa-pencil" aria-hidden="true"></i></span>
                        : null
                    }

                    {
                        props.user.role === userRoles.REQUESTOR || props.user.role === userRoles.INSPECTOR || props.fromDashboard ? null :
                            (quote.status === Status.REQUESTED || !quote.tag_opportunity) ?
                                <span className="mr_20 account_edit_icn" onClick={() => props.handleClickDeleteModel(quote)}><i className="fa fa-trash" aria-hidden="true"></i></span> : null
                    }
                </>
        }
    })


    const columns = [
        {
            Header: <><span>ID</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'id',
            width: 100,
            show: props.quotesList.length !== 0,
            // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        },{
            Header: <><span>Date Created</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'created_at',
            show: props.quotesList.length !== 0,
            sortMethod: (a, b) => {
                //  
                let firstVal = statusSortValue(a);
                let secondVal = statusSortValue(b);
                if (firstVal === secondVal) {
                    return 0;
                }
                return new Date(firstVal) > new Date(secondVal) ? 1 : -1;
            }
        },
        {
            Header: <><span>Job Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'account',
            width: 150,
            show: props.quotesList.length !== 0,
            // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        }, {
            Header: <> <span>Description</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'description',
            width: 350,
            show: props.quotesList.length !== 0,
            sortMethod: (a, b, c) => {
                let firstVal = descSortvalue(a);
                let secondVal = descSortvalue(b);
                if (firstVal === secondVal) {
                    return 0;
                }
                return firstVal > secondVal ? 1 : -1;
            }
            // show: false
        }, {
            // id: 'friendName', // Required because our accessor is not a string
            Header: <> <span>Amount</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'totalSale',
            width: 140,
            show: props.quotesList.length !== 0,
            // width: 200,
            sortMethod: (a, b, c) => {

                let firstVal = toatlSaleSortValues(a);
                let secondVal = toatlSaleSortValues(b);
                if (firstVal === secondVal) {
                    return 0;
                }
                if (props.quotesList.some(r => props.totalSaleClickedQuoteId.includes(r.id))) {

                    // if (!c) {
                    return firstVal > secondVal ? 1 : -1;
                    // } 
                    // else {
                    //     return firstVal < secondVal ? 1 : -1;
                    // }
                }
                else {
                    return firstVal > secondVal ? 1 : -1;
                }

            } // Custom value accessors!
        }, {
            Header: <><span>Owner</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'requestor',
            show: props.quotesList.length !== 0, // String-based value accessors!
            // width: 160,
            // getProps: (state, rowInfo, column) => {
            //     return {
            //         style: {
            //             paddingTop:  20,
            //         },
            //     }
            // }
        }, {
            Header: <><span>Region</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'region',
            show: props.quotesList.length !== 0,
            // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        }, {
            Header: <><span>Status</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'status',
            width: 250,
            show: props.quotesList.length !== 0,
            sortMethod: (a, b) => {
                //  
                let firstVal = statusSortValue(a);
                let secondVal = statusSortValue(b);
                if (firstVal === secondVal) {
                    return 0;
                }
                return firstVal > secondVal ? 1 : -1;
            }
            // show: false
        }, {
            Header: <><span>Days Open</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'elapsedDays',
            show: props.quotesList.length !== 0,
            width: 123,
        }, {
            Header: <><span>Send Email</span> </>,
            accessor: 'resendEmail',
            // width: 90,
            filterable: false,
            show: (props.user.role === userRoles.REQUESTOR || props.user.role === userRoles.INSPECTOR ? false : props.quotesList.length !== 0),
        }, {
            Header: '',
            accessor: 'actions',
            width: 90,
            filterable: false,
            show: (props.quotesList.length !== 0),
        }
    ]

    return (
        searchOrResetQuoteClicked && props.isQuotesLoading ?
            <div className="loader_btn_block" >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :

            <ReactTable
                data={data}
                columns={columns}
                loading={props.isQuotesLoading}
                defaultPageSize={No_of_rows}
                // onPageSizeChange={props.onQuotesPageSizeChange}
                manual
                onFetchData={fetchData}
                pages={No_of_pages}
                gotoPage={quotePage}
                page = {
                    quotePage
                }
                onPageChange={(page) => setTablePageFn(page)}
                showPagination={props.quotesList.length !== 0}
                showPaginationTop={true}
                NoDataComponent={() => CustomNoDataComponent(props.isQuotesLoading, 'No Quotes Found')}
                minRows={0}
                // filterable={true}
                LoadingComponent={() =>
                    props.isQuotesLoading ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                defaultFilterMethod={filterCaseInsensitive}
                // style={{marginTop: '10px'}}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '3px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {
                    // if (column.Header.props) {
                    if (column.id === 'totalSale') {

                        let i = props.totalSalesArray.findIndex(ts => ts[0] === rowInfo.original.id)
                        if (i > -1) {
                            return {
                                className: 'react-td-element-editSale',
                            }
                        } else {
                            return {
                                className: 'react-td-element',
                            }
                        }

                    }

                    // } 
                    if (column.Header == 'Status') {
                        return {
                            className: 'react-td-element-status'
                        }
                    } else {
                        return {
                            className: 'react-td-element'
                        }
                    }

                }}

                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default QuotesTable;