import React, { useState, useEffect } from 'react';
import { reduxForm } from 'redux-form';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { RolesOptions, priorityOptions, categoryOptions, userRoles } from '../../../../utility/constants/constants';
import { Input } from '../../UI/InputElement/InputElement';
import { newTicketValidator as validate } from '../../../../utility/validator/validator';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { connect } from 'react-redux';
import ImageLoader from 'react-imageloader';
import ModalImageGallery from '../../ModalImageGallery/modalImageGallery';
import GooglePlacesAutoComplete from '../../GooglePlacesAutoComplete/googlePlacesAutoComplete';
import UserSkeleton from '../../user/NewUser/userSkeleton';
import { showConfirmAlert } from '../../../../utility/successAlert/ConfirmAlert';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import storage from '../../../../utility/storage';
import * as Scroll from 'react-scroll';
import ImageSlider from '../../ImageSlider/ImageSlider';

var scroller = Scroll.scroller;
var moment = require('moment');
// import UserSkeleton from './userSkeleton';

const getAccountsDropDown = (props) => {

    if (props.user.role === userRoles.INSPECTOR || props.user.role === userRoles.CLIENT || props.user.role === userRoles.REQUESTOR) {

        return props.userAccountLists && props.userAccountLists.map(account => {
            return {
                value: account.id,
                label: account.name
            }
        })
    } else {

        return props.accountsList && props.accountsList.map(account => {
            return {
                value: account.id,
                label: account.name
            }
        })
    }
}

const getUsersDropDownList = (props) => {

    return props.accountUsersList && props.accountUsersList.map(user => {
        return {
            value: user.id,
            label: user.first_name + ' ' + user.last_name
        }
    })
}

let OpenNewTicketModal = (props) => {
    const base64Clicked = (i, urlLength) => {
        setPopupModal(false);
        setStartIndex(false)
        setStartIndex((urlLength + i));
    }

    const photoUrlClicked = (i) => {

        setIsImageGallery(true);
        setStartIndex(i);
    }

    const [accounts, setAccounts] = useState(getAccountsDropDown(props));
    const [accountUsers, setAccountUsers] = useState(getUsersDropDownList(props))

    useEffect(() => {
        setAccounts(getAccountsDropDown(props))
    }, [props.accountsList, props.userAccountLists])

    useEffect(() => {
        setAccountUsers(getUsersDropDownList(props))
    }, [props.accountUsersList])
    // const accounts = props.accountsList.map(account => {
    //     return {
    //         value: account.user.id,
    //         label: account.user.name
    //     }
    // });

    const noAccountOptionsMessage = (a, b) => {
        return 'Job not found';
    }
    // const Inpsectors = props.dropDownUsersList.filter(user => user.user.role === 'inspector');
    const CategoriesOptions = props.categoriesList && props.categoriesList.map(c => {
        return {
            id: c.category.id,
            name: c.category.name && c.category.name.length > 32 ? c.category.name.substr(0, 32 - 1) + "..." : c.category.name
        }
    })

    // const users = props.dropDownUsersList && props.dropDownUsersList.map(user => {
    //     return {
    //         value: user.id,
    //         label: user.first_name + ' ' + user.last_name
    //     }
    // });

    const noUserOptionsMessage = (a, b) => {
        return 'User not found';
    }

    function preloader() {
        return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
    }

    const [isImageGallery, setIsImageGallery] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [popupModal, setPopupModal] = useState(true);
    const { handleSubmit, pristine, reset, submitting, error } = props;
    
    // if (isImageGallery) {
    //     // return <ModalImageGallery
    //     //     isImageGallery={isImageGallery}
    //     //     setIsImageGallery={setIsImageGallery}
    //     //     startIndex={startIndex}
    //     //     base64={props.portfolioImages}
    //     //     imageUrls={props.savedPortfolioURls}
    //     // />
    // }
    // else if (!accounts || !CategoriesOptions) {
    //     return <UserSkeleton />
    // }
    // else {
        return (
            <div className="modal fade creat_ticket_modal show" id="creatTicket" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" data-backdrop="static" data-keyboard="false" aria-hidden="true">
                
                {
                    popupModal ?
                
                <>    
                <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                        <div className="quote_form ticket_form">
                            <div className="modal-header">
                                <h2 className="modal-title md_title">Submit a Ticket</h2><button type="button" onClick={props.closeModal} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="addQuote_form">
                                    {!accounts || !CategoriesOptions ?
                                        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                                        :
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label name="ticket[account_id]" className="form_title">Job <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input name="ticket[account_id]" readOnly noOptionsMessage={noAccountOptionsMessage} onChange={props.getAccountUsers} type="inputSelect" options={accounts} />
                                                {/* <input className="form-control input-modifier" placeholder="Pegasus" /> */}
                                            </div>
                                            <div className="form-group">
                                                <label className="form_title">Category <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input name="ticket[category_id]" type="select" options={CategoriesOptions} className="custom-select input-modifier add_user_select" />
                                            </div>
                                            <div className="checkbox_block radius_checkbox">
                                                <div className="disabled_checkbox custom-control custom-checkbox">
                                                    <Input name="ticket[private]" disabled={true} type="checkbox" className="custom-control-input" id="customCheck1" />
                                                    <label className="custom-control-label" for="customCheck1">Private (You cannot change private settings since it is Inherited from inspection)</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form_title">Subject <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input name="ticket[subject]" type="text" className="form-control input-modifier" placeholder="subject" />
                                            </div>

                                            <div className="form-group">
                                                <label className="form_title">Message <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input type="textarea" name="ticket[message]" className="form-control textarea_modifier" rows="6" placeholder="Detailed Message" id="comment" />
                                            </div>
                                            <div className="form-group">
                                                <label name="ticket[user_id]" className="form_title">Assigned to
                                        {
                                                        props.user.role === userRoles.CLIENT
                                                            ? null : <sup className="cst_sup_txt text_danger" style={{marginLeft: "3px"}}>*</sup>
                                                    }</label>
                                                <Input name="ticket[user_id]" readOnly={props.userDropDownLoader} noOptionsMessage={noUserOptionsMessage} type="inputSelect" options={accountUsers} />
                                            </div>
                                            {/* <div className="row"> */}
                                            {/* <div className="col-6 col-sm-6 col-md-6 gutter-right"> */}
                                            <div className="form-group">
                                                <label className="form_title">Due on <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input name="ticket[due_on]"
                                                minDate={new Date()}
                                                preventReadOnly={true}
                                                dateFormat="MM-dd-yyyy" id="from_date" placeholder="Due Date" datePickerType={"react-datepicker"} type="date" className="form-control from-date-analytics" />
                                                {/* <DatePicker
                                            selected={isNaN(props.dueDate) ? null : props.dueDate}
                                            onChange={props.setDueDate}
                                            showTimeSelect
                                            className="form-control input-modifier"
                                            minDate={new Date()}
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            timeCaption="time"
                                            dateFormat="MM-dd-yyyy h:mm aa"
                                        /> */}
                                            </div>
                                            {/* </div> */}
                                            {/* <div className="col-6 col-sm-6 col-md-6 gutter-left"> */}
                                            <div className="form-group">
                                                <label className="form_title">Priority <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input name="ticket[priority]" className="custom-select input-modifier" noOptionsMessage={noUserOptionsMessage} type="select" options={priorityOptions} />
                                            </div>
                                            {/* </div> */}
                                            {/* </div> */}
                                            <div className="file_attachment">
                                                <Input type="file" name="attachment_paths" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                                <a href="javascript:void(0)" onClick={props.choosePhotoClicked} className="file_title"><img src="/images/icons/attachment_icon.png" alt="Icon" /> Attachments</a>
                                                <p className="size_dec">Up to 10 atachments (10MB each).</p>

                                                <div class="upload_file">
                                                    <div class="row">
                                                        {  props.savedPortfolioURls && props.savedPortfolioURls.map((url, i) => {
                                                            return (
                                                                <div class="col-4 col-sm-4 cst_gutter" key={i}>
                                                                    <div class="file_view" onClick={() => photoUrlClicked(i)}>
                                                                        <ImageLoader
                                                                            style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                                                            src={url.photo_urls.small}
                                                                            wrapper={React.createFactory('div')}
                                                                            preloader={preloader}>
                                                                            Image load failed!
                                                            </ImageLoader>
                                                                    </div>
                                                                    <div class="cancel_icon">
                                                                        <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeUrls(url, i)} alt="Image" />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        {props.portfolioImages && props.portfolioImages.map((url, i) => (
                                                            <div class="col-4 col-sm-4 cst_gutter" key={i} >
                                                                <div class="file_view">
                                                                    <img src={url} alt="Image" onClick={() => base64Clicked(i, props.savedPortfolioURls.length)} />
                                                                    <div class="cancel_icon">
                                                                        <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group btn_block mb-0">
                                                <button type="submit" className={props.isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>{props.isLoading ? "" : "Submit"}</button>
                                                <button id="ticketModalButton" data-dismiss="modal" type="button" onClick={props.closeModal} className="btn cst_btn btn-outline-secondary  cancel_btnnew">Cancel</button>
                                            </div>
                                        </form>
                                    }
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    </> : 
                      <>
                      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                      <div className="modal-content">
                      <div className="modal-header">
                      <button type="button" onClick={()=>setPopupModal(true)} className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                      <ImageSlider startIndex={startIndex} showFullscreenButton={false} showThumbnails={true} autoPlay={false} base64={props.portfolioImages} imageUrls={props.savedPortfolioURls} />
                      </div>
                      </div>
                      </div>
                      </>
                
                }

                </div>
        )
    // }

}

const mapStateToProps = (state) => {
    let ticket = {};
    const account = storage.get("performInspectionData", null);
    
    const getAccount = (account) => {
        return {
            value: account.id,
            label: account.account_name
        }
    }
    if (!isEmpty(state.adminOrSuperAdminReducer.ticket)) {

        const getUser = (user) => {
            return {
                value: user.id,
                label: user.first_name + ' ' + user.last_name
            }
        }
        //ticket = state.adminOrSuperAdminReducer.ticket;
        ticket['account_id'] = getAccount(account.inspection.account_info);
        //ticket['category_id'] = ticket.category ? ticket.category.id : null;
        // ticket['user_id'] = getUser(ticket.user);
        // ticket['due_on'] = state.adminOrSuperAdminReducer.ticket.due_on ? moment(state.adminOrSuperAdminReducer.ticket.due_on).toDate() : '';
    }
    ticket['account_id'] = getAccount(account.inspection.account_info);
    ticket['private'] = state.adminOrSuperAdminReducer.inspection.private;
    
    return { initialValues: { ticket } }

}

OpenNewTicketModal = reduxForm({
    form: 'newTicketForm',
    validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(OpenNewTicketModal);

OpenNewTicketModal = connect(mapStateToProps)(OpenNewTicketModal)

export const scrollToFirstError = (errors) => {

    let scroolToError = null;
    if (errors) {
        if (errors.ticket.account_id) {
            scroolToError = `ticket[account_id]`;
        } else if (errors.ticket.category_id) {
            scroolToError = `ticket[category_id]`;
        } else if (errors.ticket.subject) {
            scroolToError = `ticket[subject]`;
        } else if (errors.ticket.message) {
            scroolToError = `ticket[message]`;
        } else if (errors.ticket.user_id) {
            scroolToError = `ticket[user_id]`;
        } else if (errors.ticket.due_on) {
            scroolToError = `ticket[due_on]`;
        } else if (errors.ticket.priority) {
            scroolToError = `ticket[priority]`;
        }
    }
    //  
    scroller.scrollTo(`${scroolToError}`, {
        duration: 500,
        delay: 1,
        isDynamic: true,
        smooth: true,
        // containerId: 'ContainerElementID',
        offset: -100,
    })

}

export default OpenNewTicketModal;