import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { routes } from '../../../../utility/constants/constants';
import { reset } from 'redux-form';
import { animateScroll as scroll} from 'react-scroll'
import { getUser } from '../../../../utility/utility';
import APIAccess from '../../../component/companies/APIAccess/APIAccess';
import cloneDeep from 'clone-deep';
import moment from 'moment';

class APIAccessContainer extends React.Component {

    newCompanieClicked = () => {
        this.props.history.push(routes.NEW_COMPANY)
    }

    componentDidMount() {
        // if (!this.props.companiesList) {
        // this.props.getCompanies();
        // }
        scroll.scrollToTop();
    const userDetails = getUser();
    this.props.getModeAccounts(userDetails.mode);
    this.props.getCompaniesDropDownList()
    }

    resetClicked = () => {
        this.props.companyAccessPageClicked(0)
        this.props.reset('searchCompanieForm');
        this.props.getAPIAccess({active: this.props.activeCompanies}, this.props.company_rows);
    }

    companyAccessFormValue = (values) => {
        
        if(this.props.isEdit){
            values.company_access.company_id = this.props.companyAccess.company_id
        }
        if(values){
            if(!(values.company_access)) {
                return false;
            } else {
                if(values.company_access.is_limited == 'false') {
                    if(values.company_access.access_type.length == 0 || values.company_access.token_expires.length == 0 || !(values.company_access.company_id)) {
                        return false;
                    }
                }
                if(values.company_access.is_limited == 'true') {
                    if(values.company_access.access_limit.length == 0 || values.company_access.access_type.length == 0 || values.company_access.token_expires.length == 0 || !(values.company_access.company_id)) {
                        return false;
                    }
                }
            }
        }

        const value = cloneDeep(values)
        
        if(value.company_access.company_id) {
            value.company_access.company_id = value.company_access.company_id.value
        }
        if(value.company_access.access_type) {
            value.company_access.access_type = value.company_access.access_type.value
        }
        if(value.company_access.is_limited === 'false') {
           delete value.company_access.access_limit
        }
        if(value.company_access.token_expires) {
            value.company_access.token_expires = moment(value.company_access.token_expires).format('YYYY-MM-DD')
        }

        if(!this.props.isEdit){
            this.props.addNewAPIAccess(value)
        }else{
            this.props.updateAPIAccess(value,this.props.editCompanyAccessId)
        }
        this.props.reset('performingCompanyAccessForm');
        
    }


    handleClickDeleteModel = (company) => {

        this.props.handleClickDeleteModel(company);
    }

    handleDeleteClose = () => {
        this.props.handleDeleteClose();
    }

    deleteCompany = (id) => {
        this.props.deleteAPIAccess(id);
    }

    editCompanyClicked = (company) => {
        this.props.editCompanyClicked(company);
    }

    cancelCompanyModel = () => {
        this.props.cancelCompanyAccessModelClicked();
    }

    editCompanyaccessClicked = () => {
        this.props.updateCompanyAccessClicked();
    }

    previewCompanyClicked = (company) => {
this.props.reset('searchCompanieForm');
        this.props.previewCompanyUsers(company);
        this.props.history.push(`/customers/${company.id}/customer_users`);
    }

    searchCompanies = (values) => {
        this.props.companyAccessPageClicked(0)
        values.active = this.props.activeCompanies;
        this.props.getAPIAccess(values,this.props.company_rows, 1,[]);
    }

    activeCompanyClicked = (e) => {
        this.props.companyAccessActiveChanged()
    }
    render() {

        return (
            <APIAccess
                {...this.props}
                resetClicked={this.resetClicked}
                onSubmit={this.searchCompanies}
                deleteCompany={this.deleteCompany}
                handleDeleteClose={this.handleDeleteClose}
                newCompanieClicked={this.newCompanieClicked}
                deleteCompanie={this.deleteCompanie}
                companies_rows={this.props.company_rows}
                isLoading={this.props.isLoading}
                previewCompanyClicked={this.previewCompanyClicked}
                onCompaniesPageSizeChange={this.onCompaniesPageSizeChange}
                companiesList={this.props.companiesList}
                editCompanyClicked={this.editCompanyClicked}
                deleteObject={this.props.deleteObject}
                openDeleteModel={this.props.openDeleteModel}
                handleClickDeleteModel={this.handleClickDeleteModel}
                activeCompanies={this.props.activeCompanies}
                activeCompanyClicked={this.activeCompanyClicked}
                companyAccessFormValue={this.companyAccessFormValue}
                cancelCompanyModel={this.cancelCompanyModel}
                editCompanyaccessClicked={this.editCompanyaccessClicked}
                isEdit={this.props.isEdit}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        company: state.adminOrSuperAdminReducer.company,
        companiesList: state.adminOrSuperAdminReducer.companyAccessList,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        isCompanyPending: state.adminOrSuperAdminReducer.isCompanyAccessPending,
        deleteObject: state.adminOrSuperAdminReducer.deleteObject,
        openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
        company_rows: state.adminOrSuperAdminReducer.company_access_rows,
        companyDropdownList: state.adminOrSuperAdminReducer.companyDropdownList,
        activeCompanies: state.adminOrSuperAdminReducer.activeCompanyAccess,
        isEdit: state.adminOrSuperAdminReducer.isEdit,
        editCompanyAccessId: state.adminOrSuperAdminReducer.editCompanyAccessId,
        companyAccess: state.adminOrSuperAdminReducer.companyAccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        getCompaniesDropDownList: () => dispatch(actions.getCompaniesDropDownList()),
        handleClickDeleteModel: (user) => dispatch(actions.handleClickDeleteModel(user)),
        // searchUserClicked: (quote) => dispatch(actions.searchUserClicked(quote)),
        // onCompanyPageSizeChange: (rows) => dispatch(actions.onCompanyPageSizeChange(rows)),
        updateAPIAccess: (values,id) => dispatch(actions.updateAPIAccess(values,id)),
        addNewAPIAccess: (values) => dispatch(actions.addNewAPIAccess(values)),
        editCompanyClicked: (user) => dispatch(actions.editCompanyClicked(user)),
        getAPIAccess: (values, company_no_of_rows, page, sorted, filtered) => dispatch(actions.getAPIAccess(values, company_no_of_rows, page, sorted, filtered)),
        reset: (searchCompanieForm) => dispatch(reset(searchCompanieForm)),  // requires form name
        previewCompanyUsers: (company) => dispatch(actions.previewCompanyUsers(company)),
        getModeAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
        companyAccessActiveChanged: () => dispatch(actions.companyAccessActiveChanged()),
        companyAccessPageClicked: (page) => dispatch(actions.companyAccessPageClicked(page)),
        getOneAPIAccess: (id) => dispatch(actions.getOneAPIAccess(id)),
        deleteAPIAccess: (id) => dispatch(actions.deleteAPIAccess(id)),
        cancelCompanyAccessModelClicked: () => dispatch(actions.cancelCompanyAccessModelClicked()),
        updateCompanyAccessClicked: () => dispatch(actions.updateCompanyAccessClicked()),
        // newUserClicked: () => dispatch(actions.newUserClicked())

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(APIAccessContainer);