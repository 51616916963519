import React from 'react'
import { Input } from '../../../UI/InputElement/reactFormHookInputField'

function MeetingAttendeesCompponent(props) {
    let current = new Date()
    return (
        <>
            <div class="tm_form_wrap">
                <div class="tm_form_innner">
                    <div class="form">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <div class="form-group">
                               
                                    <Input
                                        type="text"
                                        formControlClasses="form-control"
                                        id={ `attedees.${props.index}.first_name`}
                                        placeholder="First Name"
                                        control={props.control}
                                        controller={props.controller}
                                        name={ `attedees.${props.index}.first_name`}
                                        dateFormat="MMMM - YYYY"
                                        minDate={new Date().setDate(current.getDate() - 400)}
                                        preventReadOnly={false}
                                        label="First Name"
                                        formControlProps={props.register(`attedees.${props.index}.first_name`, {
                                            required: "This field is required",
                                            // validate:handleValidateMonth,

                                        })}
                                        validationErrorMessage={props.errors?.attedees?.[props.index]?.first_name?.message ?? ""}
                                        disabled={props?.isView}
                                    />

                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="form-group">
                                    <Input
                                        type="text"
                                        formControlClasses="form-control"
                                        id={ `attedees.${props.index}.last_name`}
                                        placeholder="Last Name"
                                        control={props.control}
                                        controller={props.controller}
                                        name={ `attedees.${props.index}.last_name`}
                                        dateFormat="MMMM - YYYY"
                                        minDate={new Date().setDate(current.getDate() - 400)}
                                        preventReadOnly={false}
                                        label="Last Name"
                                        formControlProps={props.register(`attedees.${props.index}.last_name`, {
                                            required: "This field is required",
                                            // validate:handleValidateMonth,

                                        })}
                                        validationErrorMessage={props.errors?.attedees?.[props.index]?.last_name?.message ?? ""}
                                        disabled={props?.isView}
                                    />

                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="form-group">

                                    <Input
                                        type="email"
                                        formControlClasses="form-control"
                                        id={ `attedees.${props.index}.email`}
                                        placeholder="Email "
                                        control={props.control}
                                        controller={props.controller}
                                        name={ `attedees.${props.index}.email`}
                                        dateFormat="MMMM - YYYY"
                                        minDate={new Date().setDate(current.getDate() - 400)}
                                        preventReadOnly={false}
                                        label="Email "
                                        formControlProps={props.register(`attedees.${props.index}.email`, {
                                            required: "This field is required",
                                            // validate:handleValidateMonth,
                                            pattern: {
                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                message: 'Enter a valid email address'
                                              }                                  

                                        })}
                                        validationErrorMessage={props.errors?.attedees?.[props.index]?.email?.message ?? ""}
                                        disabled={props?.isView}
                                    />


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    props?.index > 0 && !props.isView ?
                        <div class="col-md-12 col-sm-12" >
                            <div class="action_button pt-3">
                                <span onClick={props.removeFromTaskList(props.index)}>
                                    <img src='/images/icons/trash.svg' />
                                </span>
                            </div>
                        </div> : null
                }
            </div>
        </>
    )
}

export default MeetingAttendeesCompponent
