import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../../styles/custom.css';
import { TicketStatus, userRoles, TimeZones, routes } from '../../../../../utility/constants/constants';
import {
    CustomNoDataComponent,
    trimString,
    makeFirstLetterCapital,
    convertDateTimezone,
    convertDateAndTimeTimezone, getUser, getWeekStartAndEndDay, convertTicketsDataToCsvType, convertDateToDifferentTZ, calculateHoursFromMinutes_V1
} from '../../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import storage from '../../../../../utility/storage';
import cloneDeep from 'clone-deep';
import { SwapOutlined } from '@ant-design/icons';
import ShiftCancelRequestForm from '../ShiftCancelComponent/ShiftCancelRequestForm';
import { tzDateConverterTool_V1, tzDateConverterTool_V2, tzDateConverterTool_V7 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
var moment = require('moment');

let ShiftScheduleDetailTable = (props) => {

    const [passShiftId, setPassShiftId] = useState(null);

    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchShiftScheduleDetail && state.form.searchShiftScheduleDetail.values ? state.form.searchShiftScheduleDetail.values : {});
    const DateGenerate = useSelector(state => state.TimeTrackerModReducer.dataGenerated);
    const values = useSelector(state => state.TimeTrackerModReducer.shiftScheduleDetail_searchValues);
    const No_of_pages = useSelector(state => state.TimeTrackerModReducer.shiftScheduleDetailList_total_pages);
    const No_of_rows = useSelector(state => state.TimeTrackerModReducer.shiftScheduleDetailList_no_of_rows);
    const isShiftScheduleDetailList = useSelector(state => state.TimeTrackerModReducer.isShiftScheduleDetailList);
    const shiftScheduleDetailListCurPage = useSelector(state => state.TimeTrackerModReducer.shiftScheduleDetailListCurPage);
    const SearchOrResetShiftScheduleDetailListClicked = useSelector(state => state.TimeTrackerModReducer.SearchOrResetShiftScheduleDetailListClicked);

    const accountTimezone = 'America/Los_Angeles';

    const data = props.shiftScheduleDetailList && props.shiftScheduleDetailList.map((value) => {
        debugger
        return {
            accountName: value?.accounts?.length > 0 ? value.accounts.map((item, i) => makeFirstLetterCapital(`${item.name}${value.accounts.length === i + 1 ? '' : ', '}`)) : '−',
            days: value?.date?.length > 0 ? tzDateConverterTool_V2(value.date) : '−',
            date: value?.date?.length > 0 ? tzDateConverterTool_V1(value.date) : '−',
            assingedTo: value?.assignedTo?.id ? `${makeFirstLetterCapital(value.assignedTo.first_name)} ${makeFirstLetterCapital(value.assignedTo.last_name)}` : '−',
            colck_in: value?.check_int_at?.length > 0 ? tzDateConverterTool_V7(value.check_int_at) : '−',
            meal_break: value?.breaks?.length > 0 ? (
                <>
                    <ReactTooltip place="left" type="dark" effect="solid" />
                    <span data-tip="View Meal Breaks" className="mr_20 account_edit_icn" onClick={() => [props.setMealBreakModalOpen(true), props.setCurrentMealBreak(value.breaks)]}><i class="fas fa-clock" aria-hidden="true" ></i></span>
                </>
            ) : '−',
            colck_out: value?.check_out_at?.length > 0 ? tzDateConverterTool_V7(value.check_out_at) : '−',
            overtime: value?.overtime ? value.overtime : '−',
            total_time: value?.total_time ? calculateHoursFromMinutes_V1(value.total_time) : '−',
            status: value?.status ? makeFirstLetterCapital(value.status) : '−',
            actions:
                <>

                    <ReactTooltip place="left" type="dark" effect="solid" />
                    <span data-tip="View Shift Details" className="mr_20 account_edit_icn" onClick={() => props.history.push(`/shift-schedule/preview-shift-schedule/${value.id}`)}  ><i class="fas fa-eye" aria-hidden="true" ></i></span>
                    {value?.status == 'cancelled' || value?.status == 'closed' ? null : (
                        <>
                            <span data-tip="Edit Single shift day" className="mr_20 account_edit_icn" onClick={() => { props.handleEditPerDayShiftDetailClicked(value.id); }}><i class="fa fa-pencil" aria-hidden="true" ></i></span>
                            <button data-tip="Cancel Shift" onClick={() => { props.handleFrequentTimeTracker_openModal(); setPassShiftId(value.id); }} data-test="cancel_button" type="button" className="btn cst_btn btn-danger mr-3" data-toggle="modal" data-target="#shiftCancelRequest" data-backdrop="static" data-keyboard="false"> Cancel </button>
                        </>
                    )}

                </>
        }

    })

    const columns = [
        {
            Header: <><span> Job </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'accountName',
            show: props.shiftScheduleDetailList.length !== 0,
        },
        {
            Header: <><span> Days </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'days',
            show: props.shiftScheduleDetailList.length !== 0,
        },
        {
            Header: <><span> Date </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'date',
            show: props.shiftScheduleDetailList.length !== 0,
        },
        {
            Header: <><span> Frontline User </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'assingedTo',
            show: props.shiftScheduleDetailList.length !== 0,
        },
        {
            Header: <><span> Clock In at </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'colck_in',
            show: props.shiftScheduleDetailList.length !== 0,
        },
        {
            Header: <><span> Meal Break </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'meal_break',
            show: props.shiftScheduleDetailList.length !== 0,
        },
        {
            Header: <><span> Clock Out at </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'colck_out',
            show: props.shiftScheduleDetailList.length !== 0,
        },
        {
            Header: <><span> Overtime </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'overtime',
            show: props.shiftScheduleDetailList.length !== 0,
        },
        {
            Header: <><span> Total Hours </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'total_time',
            show: props.shiftScheduleDetailList.length !== 0,
        },
        {
            Header: <><span> Status </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'status',
            show: props.shiftScheduleDetailList.length !== 0,
        },
        {
            Header: <><span> Actions </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'actions',
            show: props.shiftScheduleDetailList.length !== 0,
            width: 200
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew = React.useRef()
    if (shiftScheduleDetailListCurPage !== tablePageNew.current) {
        tablePageNew.current = shiftScheduleDetailListCurPage;
    }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.shiftScheduleDetailListPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetShiftScheduleDetailListClicked) {
            dispatch(actions.resetSearchOrResetInventory())
        } else {
            if (!DateGenerate && !props.isShiftScheduleDetailList) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : shiftScheduleDetailListCurPage + 1;
                const inventoryItemsList_no_of_rows = state.pageSize;
                if (No_of_rows != inventoryItemsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }

                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                const status = SearchValue.shiftStatus ? typeof (SearchValue.shiftStatus) == 'object' ? SearchValue.shiftStatus.value : SearchValue.shiftStatus : null;
                const from = SearchValue.fromDate ? convertDateToDifferentTZ(SearchValue.fromDate, 'from') : null;
                const to = SearchValue.toDate ? convertDateToDifferentTZ(SearchValue.toDate, 'to') : null;
                props.getShiftScheduleDetail({ page, limit, order, status, from, to, orderBy }, values, props.match.params.id)
            }
        }
    }

    return (
        SearchOrResetShiftScheduleDetailListClicked && props.isShiftScheduleDetailList ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            : (
                <>
                    <ReactTable
                        data={data}
                        loading={props.isShiftScheduleDetailList}
                        minRows={0}
                        defaultPageSize={No_of_rows}
                        pages={No_of_pages}
                        gotoPage={shiftScheduleDetailListCurPage}
                        page={shiftScheduleDetailListCurPage}
                        onPageChange={(page) => { setTablePageFn(page) }}
                        // onPageSizeChange={props.onUserPageSizeChange}
                        manual
                        onFetchData={fetchData}
                        showPaginationTop={true}
                        showPaginationBottom={false}
                        showPagination={props.shiftScheduleDetailList.length !== 0}
                        columns={columns}
                        // style={{marginTop: '10px'}}
                        NoDataComponent={() => CustomNoDataComponent(props.isShiftScheduleDetailList, 'No Shift Schedule Details List Found')}
                        LoadingComponent={() =>
                            isShiftScheduleDetailList ?
                                <div style={{
                                    display: "block",
                                    position: "absolute",
                                    left: 0,
                                    height: '100%',
                                    right: 0,
                                    background: "rgba(255,255,255,0.8)",
                                    transition: "all .3s ease",
                                    top: 0,
                                    bottom: 0,
                                    textAlign: "center"
                                }}
                                    className="loader_btn_block">
                                    <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                                </div> : null}
                        getTheadThProps={(state, rowInfo, column, instance) => {
                            // console.log(column);
                            return {
                                style: {
                                    borderTop: 'none',
                                    borderBottom: 'none',
                                    borderRight: 'none',
                                    textAlign: 'center',
                                    color: '#566975',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    whiteSpace: 'nowrap',
                                    float: 'left',
                                }
                            }
                        }
                        }
                        getTrProps={(state, rowInfo, column, instance) => {
                            // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                            console.log(column);
                            return {
                                className: 'react-table-tr-element',
                                style: {
                                    border: '1px solid #c7cdd1',
                                    transition: '.3s ease-in-out',
                                    width: '100%',
                                    // borderRadius: '4px',
                                    // height: '80px',
                                    marginTop: '5px',
                                    float: 'left'
                                }
                            }

                        }}
                        getTdProps={(state, rowInfo, column, instance) => {

                            if (column.id == 'image') {
                                return {
                                    style: {
                                        marginTop: '0px'
                                    }
                                }
                            } else {
                                return {
                                    className: 'react-td-element',
                                }
                                //  {
                                //   style: {
                                //     borderTop: 'none',
                                //     verticalAlign: 'middle',
                                //     fontSize: '14px',
                                //     color: '#2b3034',
                                //     marginTop: '10px !important',
                                //     textAlign: 'center',
                                //   }
                                // }
                            }
                        }
                        }
                        // getPaginationProps={() => {
                        //     return {
                        //         style: {
                        //             marginTop: '50px',
                        //             border: 'none',
                        //             boxShadow: 'none'
                        //         }
                        //     }
                        // }
                        // }
                        getProps={() => {
                            return {
                                style: {
                                    border: 'none'
                                }
                            }
                        }}
                    />

                    {props.isTimeTrackerModalOpen ? (
                        <ShiftCancelRequestForm {...props} shiftId={passShiftId} />
                    ) : null}

                </>
            )
    )
}

export default ShiftScheduleDetailTable;