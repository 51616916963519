import React, { useEffect, useState } from 'react'
import NewTeamChecklistForm from '../../../../../component/FormAuditModule/TeamManagement/TeamChecklist/NewTeamChecklist/newTeamChecklist'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { addChecklist, editChecklist, getAllJob, getManagersList, getSingleChecklist } from '../../../../../../redux/actions/FormAuditModAction/TeamManagementAction/teamManagementAction'
import storage from '../../../../../../utility/storage'
import moment from 'moment'

function NewTeamChecklistFormContainer() {
    const { location: { pathname } } = useHistory()
    const {checklist}=useSelector((state)=>state.FormAuditModReducer)
    const dispatch = useDispatch()
    const { id } = useParams();
    const isEdit = Boolean(pathname.split("/")[2] !== "view" && id)
    const isView = Boolean(pathname.split("/")[2].includes("view"))
    const user = storage.get('user')

    const convertDate = (date) => {
        if (date) {
          var parts = date.split('T');
          var datePart = parts[0];
          let dt = moment(datePart)._d
          return dt
        }
      }
    
    const defaultForm = {
        account_id:checklist && checklist.account_id ? checklist.account_id : "",
        location: checklist && checklist.location ? checklist.location : "",
        date: checklist && checklist.date ? convertDate(checklist?.date)  : "",
        specialist: checklist && checklist.specialist ? checklist.specialist : "",
        employee_id: checklist && checklist.employee_id ? checklist.employee_id : "",
        conducted_by: checklist && checklist.conductedBy ? `${checklist.conductedBy.first_name} ${checklist.conductedBy.last_name}` : `${user.first_name} ${user.last_name}`,
        comments: checklist && checklist.comments ? checklist.comments : "",
        score: checklist && checklist.score ? checklist.score : "",
        score_of: checklist && checklist.score_of ? checklist.score_of : "",
        percentage: checklist && checklist.percentage ? checklist.percentage : "",
        goal: checklist && checklist.goal ? checklist.goal : "",
    }
    useEffect(() => {
        if (isEdit || isView && id) {
            dispatch(getSingleChecklist(id))
        }
        dispatch(getManagersList({ roles: "frontline" }))
        dispatch(getAllJob())
    }, [])

    const onSubmit = (value) => {debugger
        if(isEdit){
        let values = value
        values.date = moment(values.date).format('YYYY-MM-DD')
        values.id = checklist.id
        values.user_id = checklist.user_id
        values.active_status = checklist.active_status
        values.created_at = checklist.created_at
        values.updated_at = checklist.updated_at
        values.conducted_by = checklist.conducted_by
        const data = [values]
        dispatch(editChecklist({ data }))
        }else{
            let values = value
            values.date = moment(values.date).format('YYYY-MM-DD')
            value.conducted_by = user.id
            const data = [values]
            dispatch(addChecklist({ data }))
        }
    }

    return (
        <NewTeamChecklistForm
            formSubmittedCallback={onSubmit}
            elements={defaultForm}
            isEdit={isEdit}
            isView={isView}
            modalUpdate={true}
        />
    )
}

export default NewTeamChecklistFormContainer