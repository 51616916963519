import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { addChecklist, editChecklist, getAllJob, getSingleChecklist } from '../../../../../../redux/actions/FormAuditModAction/TeamManagementAction/teamManagementAction'
import storage from '../../../../../../utility/storage'
import BuildingProfileForm from '../../../../../component/FormAuditModule/BuildingProfileComponent/BuildingProfile/NewBuildingProfile/buildingProfileMainForm'
import { routes } from '../../../../../../utility/constants/constants'
import { addBuildingProfile, editBuildingProfile, getSingleBuildingProfile } from '../../../../../../redux/actions/FormAuditModAction/BuildingProfileAction/BuildingProfileAction'
import moment from 'moment'
import errorHandler from '../../../../../../utility/errorHandler/errorHandler'

function NewBuildingProfileFormContainer() {
    const { location: { pathname }, push } = useHistory()
    const { checklist } = useSelector((state) => state.FormAuditModReducer)
    const { buildingProfileDetails } = useSelector((state) => state.BuildingProfileReducer)

    const dispatch = useDispatch()
    const { id } = useParams();
    const isEdit = Boolean(pathname.split("/")[2] !== "view" && id)
    const isView = Boolean(pathname.split("/")[2].includes("view"))
    const user = storage.get('user')

    const ConvertToSectionArray = (data) => {
        return data.map((item) => {
            return {
                sections: {
                    area_field: item.area_name,
                    total: item.total,
                    comments: item.comments,
                    data: item.sectionLineItems.map((value, i) => {
                        return {
                            column_name: value.column_name,
                            column_value: value.column_value,
                            sort: i + 1
                        }
                    })
                }
            }
        })

    }

    const ConvertToColumnArray = (data) => {
        const col = data[0]?.sectionLineItems?.map((value) => {
            return {
                column_name: value.column_name
            }
        })

        return data = { data: col }
    }

    const MomentStringToHour = (str, format = "HH:mm:ss") => {

        let time = moment(str, format);

        return time
    }

    const ConvertLineItems = (data) => {
        const { id, building_profile_id, active_status, updated_at, created_at, ...remaingLineItems } = data
        return remaingLineItems
    }

    const ConvertSurveyItems = (data) => {
        const { id, building_profile_id, active_status, updated_at, created_at, cleaning_time_from, cleaning_time_to, transportation_problem, non_eng_speak_employees, good_labor_source_avail, employee_avail_extended_hrs, building_owner_manager_extended_hrs, ...remaingSurveyItems } = data
        return {
            cleaning_time_from: MomentStringToHour(cleaning_time_from),
            cleaning_time_to: MomentStringToHour(cleaning_time_to),
            transportation_problem : transportation_problem.toString(),
            non_eng_speak_employees : non_eng_speak_employees.toString(),
            good_labor_source_avail : good_labor_source_avail.toString(),
            employee_avail_extended_hrs : employee_avail_extended_hrs.toString(),
            building_owner_manager_extended_hrs : building_owner_manager_extended_hrs.toString(),
            ...remaingSurveyItems
        }
    }

    const defaultForm = {
        account_id: isEdit || isView ? buildingProfileDetails?.account_id ? buildingProfileDetails.account_id : "" : "",
        building_name: isEdit || isView ? buildingProfileDetails?.building_name ? buildingProfileDetails.building_name : "" : "",
        city: isEdit || isView ? buildingProfileDetails?.city ? buildingProfileDetails.city : "" : "",
        address: isEdit || isView ? buildingProfileDetails?.address ? buildingProfileDetails.address : "" : "",
        state: isEdit || isView ? buildingProfileDetails?.state ? buildingProfileDetails.state : "" : "",
        zip: isEdit || isView ? buildingProfileDetails?.zip ? buildingProfileDetails.zip : "" : "",
        surveyItems: isEdit || isView ? buildingProfileDetails?.surveyItems ? ConvertSurveyItems(buildingProfileDetails?.surveyItems) : {} : {},
        lineItems: isEdit || isView ? buildingProfileDetails?.lineItems ? ConvertLineItems(buildingProfileDetails.lineItems) : {} : {},
        sections: isEdit || isView ? buildingProfileDetails?.sections ? ConvertToSectionArray(buildingProfileDetails.sections) : [] : [],
        columnName: isEdit || isView ? buildingProfileDetails?.sections ? ConvertToColumnArray(buildingProfileDetails.sections) : { data: [{ column_name: "" }] } : { data: [{ column_name: "" }] }

    }

    useEffect(() => {
        if (isEdit || isView && id) {

            dispatch(getSingleBuildingProfile(id))
        }
        dispatch(getAllJob())
    }, [])


    const MomentFormatToHour = (date, format = "HH:mm:ss") => {

        return date.format(format)
    }
    const ConvertToSectionsLineItems = (data) => {
        if(data.length){
        const section = data.map((item) => {
            return {
                area_name: item.sections.area_field,
                total: item.sections.total,
                comments: item.sections.comments,
                sectionLineItems: item.sections.data.map((value, i) => {
                    return {
                        column_name: value.column_name,
                        column_value: value.column_value,
                        sort: i + 1
                    }
                })
            }
        })
        return section
    }
    }


    const ConvertToCreateAPIBody = (data) => {

        if (isEdit) {
            data.id = id
            data.user_id = user.id
        }

        const { surveyItems, lineItems, sections, columnName, area, ...remainingData } = data;

        // if(isEdit){
        //     delete remainingData?.account_id
        // }
        
        if(lineItems?.no_of_showers){
            delete lineItems?.no_of_showers
        }

        const columnLineItems = ConvertToSectionsLineItems(sections)
        surveyItems.cleaning_time_from = MomentFormatToHour(surveyItems.cleaning_time_from);//08:00:00
        surveyItems.cleaning_time_to = MomentFormatToHour(surveyItems.cleaning_time_to);//08:00:00
        surveyItems.aprox_percent_carpet_floor = surveyItems.aprox_percent_carpet_floor ? surveyItems.aprox_percent_carpet_floor : 0
        surveyItems.aprox_percent_tile_floor = surveyItems.aprox_percent_tile_floor ? surveyItems.aprox_percent_tile_floor : 0
        surveyItems.aprox_percent_other_floor = surveyItems.aprox_percent_other_floor ? surveyItems.aprox_percent_other_floor : 0
        surveyItems.aprox_sqft_carpet_floor = surveyItems.aprox_sqft_carpet_floor ? surveyItems.aprox_sqft_carpet_floor : 0
        surveyItems.aprox_sqft_tile_floor = surveyItems.aprox_sqft_tile_floor ? surveyItems.aprox_sqft_tile_floor : 0
        surveyItems.aprox_sqft_other_floor = surveyItems.aprox_sqft_other_floor ? surveyItems.aprox_sqft_other_floor : 0
        lineItems.no_of_fixtures_in_rr = lineItems.no_of_fixtures_in_rr ? lineItems.no_of_fixtures_in_rr : 0
        lineItems.no_of_sinks = lineItems.no_of_sinks ? lineItems.no_of_sinks : 0
        lineItems.no_of_fountains = lineItems.no_of_fountains ? lineItems.no_of_fountains : 0
        lineItems.no_of_stairwells = lineItems.no_of_stairwells ? lineItems.no_of_stairwells : 0
        lineItems.no_of_toilets = lineItems.no_of_toilets ? lineItems.no_of_toilets : 0
        lineItems.no_of_restrooms = lineItems.no_of_restrooms ? lineItems.no_of_restrooms : 0
        lineItems.no_of_urinals = lineItems.no_of_urinals ? lineItems.no_of_urinals : 0

        return { data: [{ surveyItems: { ...surveyItems }, lineItems: { ...lineItems }, sections: columnLineItems, ...remainingData }] }

    }




    const onSubmit = (value) => {
        if(!value.sections.length){
            errorHandler('Please select atleast one Area')
            return false
        }

        if (isEdit) {
            const body = ConvertToCreateAPIBody(value)
            dispatch(editBuildingProfile(body))
        }
        else {
            const body = ConvertToCreateAPIBody(value)
            dispatch(addBuildingProfile(body))
        }
    }

    return (
        <BuildingProfileForm
            formSubmittedCallback={onSubmit}
            elements={defaultForm}
            isEdit={isEdit}
            isView={isView}
            modalUpdate={true}
        />
    )
}

export default NewBuildingProfileFormContainer