import React, { useState, useEffect } from 'react';
import CreateShiftSwapComponentSkeleton from './CreateShiftSwapComponentSkeleton';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { Input } from '../../../UI/InputElement/InputElement';
import isEmpty from '../../../../../utility/IsEmptyValidator';
import { newShiftSwapFormValidator as validate } from '../../../../../utility/validator/validator';
import { convertDateFormatV1, convertStartEndTimeFormat, getConvertTo12Hours, makeFirstLetterCapital } from '../../../../../utility/utility';
import { userRoles } from '../../../../../utility/constants/constants';
import moment from "moment";
import cloneDeep from 'clone-deep';
import errorHandler from '../../../../../utility/errorHandler/errorHandler';
import { tzDateConverterTool_V1, tzDateConverterTool_V2, tzTimeConversionTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';

let NewShiftSwapComponent = (props) => {

    const getSwappingEmployeeList = (usersListBySelectedRole) => {
        if (props.usersListBySelectedRole && props.usersListBySelectedRole.length > 0) {
            return usersListBySelectedRole && usersListBySelectedRole.map((data) => {
                return {
                    value: data.id,
                    label: data.first_name + ' ' + data.last_name,
                }
            })
        } else {
            return []
        }
    };

    const [oddOutShiftData, setOddOutShiftData] = useState(null);
    const [oddShiftJobsInfo, setOddShiftJobsInfo] = useState([]);
    const [oddInShiftInfo, setOddInShiftInfo] = useState(null);
    const [oddShiftAssignedToInfo, setOddShiftAssignedToInfo] = useState(null);
    const [swapEmployeeList, setSwapEmployeeList] = useState(getSwappingEmployeeList(props.usersListBySelectedRole));
    const [shiftSwapTime, setShiftSwapTime] = useState();

    useEffect(() => {

        setOddOutShiftData(props.singleShiftScheduleData);
        setOddShiftJobsInfo(props.singleShiftScheduleData.accounts ? props.singleShiftScheduleData.accounts : []);
        setOddInShiftInfo(props.singleShiftScheduleData.shift ? props.singleShiftScheduleData.shift : null);
        setOddShiftAssignedToInfo(props.singleShiftScheduleData.assignedTo ? props.singleShiftScheduleData.assignedTo : null);

        return () => {
            // Add functionalities
        }

    }, [props.singleShiftScheduleData]);

    useEffect(() => {

        setSwapEmployeeList(getSwappingEmployeeList(props.usersListBySelectedRole));

    }, [props.usersListBySelectedRole]);

    useEffect(() => {

        let setData = { userId: '', shiftDate: '' };

        if (props.formStates && props.formStates.swap && props.formStates.swap.selectedFlEmp && props.formStates.swap.choosedSwapDate) {

            setData.userId = props.formStates.swap.selectedFlEmp && props.formStates.swap.selectedFlEmp.value;
            setData.shiftDate = moment(props.formStates.swap.choosedSwapDate).format('YYYY-MM-DD');

            props.getPerDayFLUserGeneralShiftInfo(setData);

        }

    }, [props.formStates && props.formStates.swap && props.formStates.swap.selectedFlEmp && props.formStates.swap.choosedSwapDate]);

    useEffect(() => {

        let setData = { page: 1, limit: 10, userId: '', date: '' };
        let shiftId = ''

        if (props.singleDayGeneralShiftInfo && props.singleDayGeneralShiftInfo[0] && props.singleDayGeneralShiftInfo[0].id) {

            setData.userId = props.singleDayGeneralShiftInfo[0].user_id;
            setData.date = moment(props.formStates && props.formStates.swap && props.formStates.swap.choosedSwapDate).format('YYYY-MM-DD');
            shiftId = props.singleDayGeneralShiftInfo[0].id;

            props.getPerDayFLUserElaboratedShiftInfo(setData, shiftId);

        }

    }, [props.singleDayGeneralShiftInfo && props.singleDayGeneralShiftInfo[0] && props.singleDayGeneralShiftInfo[0].id]);

    useEffect(() => {

        let setData = { startTime: '', endTime: '' };

        if (props.singleDayElaboratedShiftInfo && props.singleDayElaboratedShiftInfo.shift && props.singleDayElaboratedShiftInfo.shift.id) {

            setData.startTime = tzTimeConversionTool_V1(props.singleDayElaboratedShiftInfo.shift.start_time, props.formStates.swap.choosedSwapDate);
            setData.endTime = tzTimeConversionTool_V1(props.singleDayElaboratedShiftInfo.shift.end_time, props.formStates.swap.choosedSwapDate);

            setShiftSwapTime(setData);

        }

    }, [props.singleDayElaboratedShiftInfo && props.singleDayElaboratedShiftInfo.shift && props.singleDayElaboratedShiftInfo.shift.id]);

    const noOptionsMessage = (a, b) => {
        return 'No options';
    }

    const { handleSubmit, pristine, reset, submitting, error } = props;

    if (props.isLoad) {

        return (
            <>
                <CreateShiftSwapComponentSkeleton />
            </>
        );

    } else {

        return (
            <div>

                <main className="app-content add_account_modal add_frontline_pop">
                    <div className="appcontent_Inner user_newQuote pt-0">

                        <div className="container py-5">

                            <div className="row text-center mb-4">
                                <div className="col-lg-7 mx-auto">
                                    <h1 className="mb-0" style={{ color: '#4a4a4a', fontWeight: '500', fontSize: '37px' }}> Create Shift Swap Request </h1>
                                    {/* <p className="lead mb-0" style={{ color: 'black' }}>Create an elegant product list using Bootstrap 4 list group</p> */}
                                </div>
                            </div>
                            <div className="addQuote_form">
                                <form onSubmit={handleSubmit}>

                                    <div className="row">
                                        <div className="col-lg-9 mx-auto">
                                            <ul className="list-group shadow rounded" style={{ marginLeft: '10%', marginRight: '10%' }}>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Assignee </h5>
                                                            </div>
                                                            <div className="media-body">
                                                                <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {oddShiftAssignedToInfo && oddShiftAssignedToInfo.first_name && oddShiftAssignedToInfo.last_name ? makeFirstLetterCapital(oddShiftAssignedToInfo.first_name) + '  ' + makeFirstLetterCapital(oddShiftAssignedToInfo.last_name) : '–'} </h5>
                                                                <h5 className="ml-lg-0 font-italic text-muted mb-0" style={{ fontSize: '15px' }}> {oddShiftAssignedToInfo && oddShiftAssignedToInfo.email ? oddShiftAssignedToInfo.email : '–'} </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Status </h5>
                                                            </div>
                                                            <div className="media-body">
                                                                <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {oddOutShiftData && oddOutShiftData.status ? makeFirstLetterCapital(oddOutShiftData.status) : '–'} </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Date </h5>
                                                            </div>
                                                            <div className="media-body">
                                                                <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {oddOutShiftData && oddOutShiftData.date ? tzDateConverterTool_V1(oddOutShiftData.date) : '–'} </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Day </h5>
                                                            </div>
                                                            <div className="media-body">
                                                                <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {oddOutShiftData && oddOutShiftData.date ? tzDateConverterTool_V2(oddOutShiftData.date) : '–'} </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Timings </h5>
                                                            </div>
                                                            <div className="media-body">
                                                                <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {oddInShiftInfo && oddInShiftInfo.start_time && oddInShiftInfo.end_time ? tzTimeConversionTool_V1(oddInShiftInfo.start_time, oddOutShiftData.date) + ' − ' + tzTimeConversionTool_V1(oddInShiftInfo.end_time, oddOutShiftData.date) : '–'} </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Notes </h5>
                                                            </div>
                                                            <div className="media-body">
                                                                <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {oddInShiftInfo && oddInShiftInfo.notes ? oddInShiftInfo.notes : '−'} </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                <div style={{ width: '30%', border: '1px solid #555555' }}>
                                                                    <h5
                                                                        className="mt-0 font-weight-bold mb-3 text-dark"
                                                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                                                                    >
                                                                        {oddShiftJobsInfo && oddShiftJobsInfo.length > 1 ? 'Jobs' : 'Job'}
                                                                    </h5>
                                                                </div>
                                                                <div style={{ width: '30%', backgroundColor: '#555555', border: '1px solid #555555' }}>
                                                                    <h5
                                                                        className="mt-0 font-weight-bold mb-3 text-white"
                                                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                                                                    >
                                                                        Address
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div className="media-body" style={{ display: 'flex', flexDirection: 'column' }}>
                                                                {oddShiftJobsInfo && oddShiftJobsInfo.map((job, index, array) => {

                                                                    let trade = job && job.address;

                                                                    return (
                                                                        <>
                                                                            <div style={{ borderLeft: '5px solid #cfcfcf', backgroundColor: '#f0f0f0' }}>
                                                                                <h5 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> {job && job.name} </h5>
                                                                                <h5 className="ml-lg-3 font-italic text-muted mb-2" style={{ fontSize: '15px' }}>
                                                                                    {
                                                                                        trade && trade.formatted_address == null ?
                                                                                            `${trade.city !== null ? trade.city + ', ' : null}
                                                                                            ${trade.state !== null ? trade.state + ', ' : null}
                                                                                            ${trade.country !== null ? trade.country + ', ' : null}
                                                                                            ${trade.zip !== null ? trade.zip : null}` :
                                                                                            trade.formatted_address
                                                                                    }
                                                                                </h5>
                                                                            </div>
                                                                            {index === array.length - 1 ? null : <br />}
                                                                        </>
                                                                    );

                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Select Swapping Employee <sup className="cst_sup_txt text_danger">*</sup> </h5>
                                                            </div>
                                                            <div className="media-body">
                                                                <Input
                                                                    type="inputSelect"
                                                                    dataTest="select_leave_type"
                                                                    name="swap[selectedFlEmp]"
                                                                    // onChange={(e) => props.getLeaveUpdateManagersList({ leaveManagers: true, accountId: e.value })}
                                                                    // onChange={(emp) => {}}
                                                                    options={swapEmployeeList}
                                                                    noOptionsMessage={noOptionsMessage}
                                                                    placeholder="Select Swap Employee"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Select Swapping Shift Date <sup className="cst_sup_txt text_danger">*</sup> </h5>
                                                            </div>
                                                            <div className="media-body">
                                                                <Input
                                                                    name="swap[choosedSwapDate]"
                                                                    type="date"
                                                                    datePickerType={"react-datepicker"}
                                                                    minDate={new Date()}
                                                                    dateFormat="MMMM dd, yyyy"
                                                                    className="form-control input-modifier"
                                                                    placeholder="Select Swap Shift Date"
                                                                    excludeDates = {[new Date(), new Date(oddOutShiftData && oddOutShiftData.date)]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Swap Time </h5>
                                                            </div>
                                                            <div className="media-body">
                                                                <h5 className="ml-lg-0 order-1 order-lg-2 text-muted">

                                                                    {props.formStates && props.formStates.swap && props.formStates.swap.selectedFlEmp && props.formStates.swap.choosedSwapDate ?
                                                                        props.singleDayElaboratedShiftInfo.data && props.singleDayElaboratedShiftInfo.data.length > 0 && props.singleDayGeneralShiftInfo && props.singleDayGeneralShiftInfo[0] ? shiftSwapTime && shiftSwapTime.startTime ? shiftSwapTime.startTime + ' − ' + shiftSwapTime.endTime : '−' : (
                                                                            <h5 className="ml-lg-0 font-italic mb-0 text-color-warning" style={{ fontSize: '15px' }}> No shift is associated with the swap Employee and Shift date you've chosen. Try another combination. </h5>
                                                                        ) : (
                                                                            <h5 className="ml-lg-0 font-italic mb-0 text-color-info" style={{ fontSize: '15px' }}> Shift swapping Time requires both the Swapping Employee and Date to be chosen </h5>
                                                                        )
                                                                    }

                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Reason for Swapping <sup className="cst_sup_txt text_danger">*</sup> </h5>
                                                            </div>
                                                            <div className="media-body">
                                                                <div className="form-group">
                                                                    <Input type="textarea" name="swap[reason]" className="form-control textarea_modifier" rows="5" placeholder="Place the swapping reason here" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                    <div className="modal-footer">
                                        <button data-test="submit_button" type="submit" disabled={props.isLoading && props.isCreateSwap} className={props.isLoading && props.isCreateSwap ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} onClick={() => { }} >{props.isLoading && props.isCreateSwap ? "" : "Submit"}</button>
                                        <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" onClick={() => { props.onCancelClicked() }}>Cancel</button>
                                    </div>

                                </form>
                            </div>
                        </div>

                    </div >
                </main >

            </div >
        );

    }

};

const mapStateToProps = (state, props) => {

    let swap = {};

    swap = {
        shiftDetailIdFrom: '',
        shiftDetailIdTo: ''
    }

    return {
        initialValues: { swap },
        formStates: getFormValues('newShiftSwapForm')(state)
    }

}

NewShiftSwapComponent = reduxForm({
    form: 'newShiftSwapForm',
    validate,
    enableReinitialize: true
})(NewShiftSwapComponent);

NewShiftSwapComponent = connect(mapStateToProps)(NewShiftSwapComponent)

export default NewShiftSwapComponent;