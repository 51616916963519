import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../../styles/custom.css';
import { CustomNoDataComponent, makeFirstLetterCapital,convertDateToDifferentTZ } from '../../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
var moment = require('moment');

let TrainingLogTable = (props) => {

    const dispatch = useDispatch();
    // const SearchValue = useSelector(state => state.form.searchScheduledTask && state.form.searchScheduledTask.values ? state.form.searchScheduledTask.values : {});
    const DateGenerate = useSelector(state => state.FormAuditModReducer.dataGenerated);
    // const values = useSelector(state => state.FormAuditModReducer.scheduledTask_searchValues);
    const No_of_pages = useSelector(state => state.FormAuditModReducer.trainingLog_total_pages);
    const No_of_rows = useSelector(state => state.FormAuditModReducer.trainingLog_no_of_rows);
    const isTrainingLog = useSelector(state => state.FormAuditModReducer.isTrainingLog);
    const trainingLogCurPage = useSelector(state => state.FormAuditModReducer.trainingLogCurPage);
    const SearchOrResetScheduledTaskListClicked = useSelector(state => state.FormAuditModReducer.SearchOrResetScheduledTaskListClicked);

    const accountTimezone = 'America/Los_Angeles';

    const data = props.trainingLog_list && props.trainingLog_list.map((value) => {
        return {
            instructor: makeFirstLetterCapital(value && value.instructor ? value.instructor : '-'),
            date: value.date ?  moment.utc(value.date).format('MM/DD/YYYY') : '-',
            title: value && value.title ? value.title : '-',
            verification_code: value && value.verification_code ? value.verification_code : '',
            location :  value && value.location ? value.location : '',
            actions:
                <>
                <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`training-log/view/${value.id}`)} ><i class="fas fa-eye" aria-hidden="true" ></i></span>
                {
                    props?.isCreateAccess ?  <span className="mr_20 account_edit_icn"  onClick={() => props.history.push(`training-log/${value.id}`)} ><i class="fa fa-pencil" aria-hidden="true" ></i></span> : null
                }
               
                </>
        }
    })

    const columns = [
        {
            Header: <><span> Instructor </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'instructor',
            show: props.trainingLog_list.length !== 0,
            width: 135,
        },
        {
            Header: <><span> Location </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'location',
            show: props.trainingLog_list.length !== 0,
        },
        {
            Header: <><span> Title</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'title',
            show: props.trainingLog_list.length !== 0,
        },
        {
            Header: <><span> Date</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'date',
            show: props.trainingLog_list.length !== 0,
        },
        {
            Header: <><span> Verification Number </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'verification_code',
            show: props.trainingLog_list.length !== 0,
        },
        {
            Header: '',
            accessor: 'actions',
            width: 135,
            sortable:false
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()
    if(trainingLogCurPage !== tablePageNew.current) {
        tablePageNew.current = trainingLogCurPage;
      }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.TrainingLogPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetScheduledTaskListClicked) {
            dispatch(actions.resetSearchOrResetInventory())
        } else {
            if (!DateGenerate && !props.isTrainingLog) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : trainingLogCurPage + 1;
                const inventoryItemsList_no_of_rows = state.pageSize;
                if(No_of_rows != inventoryItemsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                
                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                // const accountId = SearchValue.id ? SearchValue.id : null;
                // const taskName = SearchValue.name ? SearchValue.name : null;
                // const companyName = SearchValue.company ? SearchValue.company : null;
                // const accountName = SearchValue.job ? SearchValue.job : null;
                // const from = SearchValue.fromDate ? convertDateToDifferentTZ(SearchValue.fromDate, 'from') : null;
                // const to = SearchValue.toDate ? convertDateToDifferentTZ(SearchValue.toDate, 'to') : null;
                props.getAllTrainingLog({page,limit, order, orderBy})
            }
        }
    }

    return (
        SearchOrResetScheduledTaskListClicked && props.isTrainingLog ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isTrainingLog}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={trainingLogCurPage}
                page = { trainingLogCurPage }
                onPageChange={(page) => {setTablePageFn(page)}}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPaginationBottom={false}
                showPagination={props.trainingLog_list.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(isTrainingLog, 'No Training Log List Found')}
                LoadingComponent={() =>
                    isTrainingLog ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default TrainingLogTable;