import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const renderField = ({ input, radioType, onClick, onChange, label, forLable, value, className, labelClass, spanClass, htmlFor, id, toggleBtn, errorMsg, dataTest, checked, meta: { pristine, touched, asyncValidating, error } }) => {
	//   
	//  
	return (
		<>
			{radioType === "ratingCheckbox" ?
				<>
					<input data-test={dataTest} onClick={onClick} onChange={onChange} className={className} value={value} type="radio" {...input} checked={(pristine && window.location.pathname.includes('edit')) ? checked === 'true' : input.checked === true} id={id} />
					{
						// errorMsg && error && touched &&
						// <div className="form-group__error">{error}</div>
						touched && error && <span style={{color: "rgb(221, 39, 38)", fontSize: "13px"}} data-test="error_msg">{error}</span>
					}
				</>
				:
				radioType === "settingPreferences" ?
					<>
						{/* <div className="preference-checkbox"> */}
						<input data-test={dataTest} onClick={onClick} onChange={onChange} onFocus={true} className={className} value={value} type="radio" {...input} checked={(pristine && window.location.pathname.includes('edit')) ? checked === 'true' : input.checked === true} id={id} />
						{/* <label htmlFor={htmlFor} for={forLable} className={labelClass}>{label}</label> */}
						{/* <span className="checkmark-setup radio-preference"></span>
							<div className="preference-label"><b>All emails</b> Receive one email for every inspection</div>
						</div> */}
						{/* <span className={spanClass}></span> */}
						{
							errorMsg && error && touched &&
							<div className="form-group__error">{error}</div>
						}
					</>
					:
					// toggleBtn &&
					<div className="custom-control custom-radio custom-control-inline">
						<input data-test={dataTest} onClick={onClick} onChange={onChange} className={className} value={value} type="radio" {...input} checked={input.checked} id={id} />
						<label htmlFor={htmlFor} for={forLable} className={labelClass}>{label}</label>
						{/* <span className={spanClass}></span> */}
						{
							// errorMsg && error && touched &&
							// <div className="form-group__error">{error}</div>
							touched && error && <span style={{color: "rgb(221, 39, 38)", fontSize: "13px"}} data-test="error_msg">{error}</span>

						}
					</div>
			}
			{/* {!toggleBtn &&
				<div>
					<label className={labelClass}>{label}
						<input data-test={dataTest}  type="radio" {...input} checked={(pristine && window.location.pathname.includes('edit')) ? checked === 'true' : input.checked === true} />
						<span className={spanClass}></span>
					</label>
					{
						errorMsg && error && touched &&
						<div className="form-group__error">{error}</div>
					}
				</div>

			} */}
		</>
	);
}
export const RadioBtn = ({ type, radioType, onChange, onClick, name, forLable, label, className, value, labelClass, spanClass, id, htmlFor, toggleBtn, handleChange, checked, errorMsg, dataTest }) => {
	//   
	return (
		<Field
			type={type}
			onClick={onClick}
			onChange={onChange}
			radioType={radioType}
			forLable={forLable}
			name={name}
			label={label}
			value={value}
			className={className}
			id={id}
			labelClass={labelClass}
			spanClass={spanClass}
			htmlFor={htmlFor}
			toggleBtn={toggleBtn}
			errorMsg={errorMsg}
			checked={checked}
			dataTest={dataTest}
			component={renderField}
		/>
	)
};

RadioBtn.propTypes = {
	type: PropTypes.string,
	forLable: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	className: PropTypes.string,
	labelClass: PropTypes.string,
	spanClass: PropTypes.string,
	checked: PropTypes.bool,
	id: PropTypes.string,
	toggleBtn: PropTypes.string,
	onClick: PropTypes.func,
	handleChange: PropTypes.func,
	errorMsg: PropTypes.string,
	htmlFor: PropTypes.string
};

RadioBtn.defaultProps = {
	type: 'radio',
	label: null,
	labelClass: '',
	spanClass: '',
	id: '',
	checked: '',
	toggleBtn: '',
	errorMsg: '',
	htmlFor: '',
};