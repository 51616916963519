import React, { useState, useEffect, useRef } from 'react';
import moment from "moment";

import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import isEmpty from '../../../utility/IsEmptyValidator';
import { Input } from '../UI/InputElement/InputElement';
import { SelectField } from '../UI/SelectField/SelectField';
import { DateTimeField } from '../UI/DateTimeField/DateTimeField';
import { newFrontlineValidator as validate } from '../../../utility/validator/validator';
import ReactTooltip from "react-tooltip";
import Img from "react-fix-image-orientation";
import { Timezones, timezoneOptions } from '../../../utility/constants/constants';
import { calculateHoursFromMinutes_V1, calculateHoursNumberFromMinutes_V1, calculateMinutesFromHours_V1, convertTo24HourFormat } from '../../../utility/utility';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent } from '@material-ui/core';
import { useDispatch } from 'react-redux';
let AddFrontlineForm = (props) => {

  const getSkillSetDropDown = (props) => {
    if (props.skillSet_list && props.skillSet_list.length > 0) {
      return props.skillSet_list && props.skillSet_list.map(skill => {
        return {
          value: skill.id,
          label: skill.name
        }
      })
    } else {
      return []
    }
  }

  const getAccountsDropDown = (props) => {
    if (props.accountsList && props.accountsList.length > 0) {
      return props.accountsList && props.accountsList.map(account => {
        return {
          value: account.id,
          label: account.name
        }
      })
    } else {
      return []
    }
  }

  const [skillSets, setSkillSet] = useState(getSkillSetDropDown(props));
  const [accounts, setAccounts] = useState(getAccountsDropDown(props));
  const [dailyLimits, setDailyLimits] = useState(calculateHoursNumberFromMinutes_V1(props?.formState?.frontline?.daily_limit_minutes))
  const [weeklyLimits, setWeeklyLimits] = useState(calculateHoursNumberFromMinutes_V1(props?.formState?.frontline?.weekly_limit_minutes))
  const [err, setErr] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setSkillSet(getSkillSetDropDown(props))
  }, [props.skillSet_list])

  useEffect(() => {
    setAccounts(getAccountsDropDown(props))
  }, [props.accountsList])

  useEffect(() => {

    if (props?.formState?.frontline?.daily_limit_hours) {

      dispatch(change('frontlineForm', `frontline[weekly_limit_hours]`, 5 * props?.formState?.frontline?.daily_limit_hours));
    } else if (props?.formState?.frontline?.daily_limit_hours == "") {
      dispatch(change('frontlineForm', `frontline[weekly_limit_hours]`, 5 * 8));
    }

  }, [props?.formState?.frontline?.daily_limit_hours])




  const noSkillSetOptionsMessage = (a, b) => {
    return 'Skillset not found';
  }

  const noAccountOptionsMessage = (a, b) => {
    return 'Job not found';
  };



  const handleChangeWeeklyLimit = (event) => {

    const hour = event.target.value
    if (hour == null || hour == "") {
      setWeeklyLimits(hour)
      //dispatch(change('frontlineForm', `frontline[daily_limit_minutes]`, null));
    }



    setWeeklyLimits(hour)
    //we need to change the payload value as well based upon this selected Hour 
    //  dispatch(change('frontlineForm', `frontline[daily_limit_minutes]`, minutes));
    //  dispatch(change('frontlineForm', `frontline[weekly_limit_minutes]`, weeklyMinutes));
  }

  const handleChangeDailyLimit = (event) => {
    const hour = event.target.value
    if (hour == null || hour == "") {
      setDailyLimits(hour)
      //dispatch(change('frontlineForm', `frontline[daily_limit_minutes]`, null));
    }
    const minutes = calculateMinutesFromHours_V1(hour)


    setDailyLimits(hour)
  }

  const { handleSubmit, pristine, reset, submitting, error } = props;


  return (
    <>
      <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
        <div className="inspection_content_sec appcontent_Inner">
          <div className="container-fluids">
            <div className="quote_form ticket_form" style={{ maxWidth: '900px' }}>
              <div className="row">
                <div className="col-sm-12 text-center"> <h2 className="md_title text-dark" >{props.match.params.frontlineId ? "Update Frontline Employee" : "New Frontline Employee"}</h2></div>
              </div>
              <div className="addQuote_form">
                <form onSubmit={handleSubmit}>
                  <div className="cst_tab_content">
                    <div className="profile_img_block">
                      <div className="circle_img user-pic">
                        <Input dataTest="user_image" hidden={true} type="file" name="photo_path" onSelect={props.onFileChange} fileRef={props.reference} accept="image/*" />
                        <div className="user-profile-pic">
                          <Img style={{ height: "90px", width: "90px", borderRadius: "90px" }} src={props.base64 ? props.base64 : props.s3Url ? props.s3Url : "/customImages/user.png"} />
                          <div className="icn_userEdit" onClick={props.choosePhotoClicked}> <a href="javascript:void (0)"> <i className="fa fa-camera" aria-hidden="true"> </i> </a> </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form_title fw-bold"> First Name <sup className="cst_sup_txt text_danger">*</sup></label>
                          <Input name="frontline[first_name]" dataTest="select_leave_type" type="text" className="form-control input-modifier" placeholder="Type here" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form_title fw-bold"> Last Name <sup className="cst_sup_txt text_danger">*</sup></label>
                          <Input name="frontline[last_name]" dataTest="select_leave_type" type="text" className="form-control input-modifier" placeholder="Type here" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form_title fw-bold"> Employee ID <sup className="cst_sup_txt text_danger">*</sup></label>
                          <Input name="frontline[employee_id]" dataTest="select_leave_type" type="text" className="form-control input-modifier" placeholder="Type here" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form_title fw-bold"> Password <sup className="cst_sup_txt text_danger">*</sup></label>
                          <Input name="frontline[dob_password]" dataTest="select_leave_type" type="number" className="form-control input-modifier" placeholder="Type here" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form_title fw-bold"> Skill Sets </label>
                          <Input name="frontline[skill_ids]" isMulti={true} dataTest="select_leave_type" noOptionsMessage={noSkillSetOptionsMessage} type="inputSelect" options={skillSets} placeholder="Please select" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form_title fw-bold"> Job </label>
                          <Input name="frontline[account_ids]" isMulti={true} dataTest="select_leave_type" noOptionsMessage={noAccountOptionsMessage} type="inputSelect" options={accounts} placeholder="Please select" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form_title fw-bold"> PIN Number </label>
                          <Input name="frontline[pin_number]" dataTest="select_leave_type" type="text" className="form-control input-modifier" placeholder="Please select" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form_title fw-bold"> Contact Number <sup className="cst_sup_txt text_danger">*</sup></label>
                          <Input name="frontline[phone_number]" dataTest="select_leave_type" type="tel" className="form-control input-modifier" placeholder="Type here" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form_title fw-bold"> Emergency Contact Number </label>
                          <Input name="frontline[emergency_contact_number]" dataTest="select_leave_type" type="tel" className="form-control input-modifier" placeholder="Type here" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form_title fw-bold"> Primary Email </label>
                          <Input name="frontline[primary_email]" dataTest="select_leave_type" type="text" className="form-control input-modifier" placeholder="Type here" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form_title fw-bold"> Secondary Email </label>
                          <Input name="frontline[secondary_email]" dataTest="select_leave_type" type="text" className="form-control input-modifier" placeholder="Type here" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form_title fw-bold"> Timezone <sup className="cst_sup_txt text_danger">*</sup></label>
                          <Input name="frontline[timezone]" dataTest="select_leave_type" noOptionsMessage={noAccountOptionsMessage} type="inputSelect" options={timezoneOptions} placeholder="Please select" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="checkbox_block radius_checkbox">
                            <div className="custom-control custom-checkbox">
                              <Input name="frontline[phone_clock_in]" type="checkbox" className="custom-control-input" id="customCheck1" checked />
                              <label className="custom-control-label" for="customCheck1"> Authorized to Clock In/Out via Phone </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <div className="checkbox_block radius_checkbox">
                            <div className="custom-control custom-checkbox">
                              <Input name="frontline[overtime]" type="checkbox" className="custom-control-input" id="customCheck2" checked handleChange={(e) => {
                                // const value = e.target.value

                                // if (value == "" || value == "false") {
                                //   // trigger modal Open
                                //   props.handleWeeklyLimitSet_openModal()
                                // }
                              }} />
                              <label className="custom-control-label" for="customCheck2"> Authorized for Overtime </label>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>

                    <div className="owner_details">
                      <lable className="form_title font-weight-bold mb-2">Forms Access</lable>
                      <div className="form-group checkbox_cst_grid owner_block_wrap" style={{ display: "flex", flexWrap: "nowrap" }}>

                        {/* Team Management Section */}
                        <div className="checkbox-group">
                          <p >Team Management Module</p>
                          <div style={{ marginTop: "1rem" }}>
                            <div class="custom_checkbox_wrap flex-grow-1">

                            </div>





                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`frontline[form_access][team_management][training_log]`}
                                className="custom-control-inputs"
                                id={`frontline[form_access][team_management][training_log]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`frontline[form_access][team_management][training_log]`}
                              >
                                Training Log
                              </label>
                            </div>





                          </div>
                        </div>


                        {/* LogBook Module */}


                        <div className="checkbox-group">
                          <p >Logbook Module</p>
                          <div style={{ marginTop: "1rem" }}>
                            <div class="custom_checkbox_wrap flex-grow-1">

                            </div>

                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`frontline[form_access][logbook][porter_log]`}
                                className="custom-control-inputs"
                                id={`frontline[form_access][logbook][porter_log]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`frontline[form_access][logbook][porter_log]`}
                              >
                                Porter Log
                              </label>
                            </div>


                          </div>
                        </div>





                        <div className="checkbox-group">
                          <p >Tag Module</p>
                          <div style={{ marginTop: "1rem" }}>
                            <div class="custom_checkbox_wrap flex-grow-1">

                            </div>
                              
                                <div className="custom-control custom-checkbox">
                                  <Input
                                    type="checkbox"
                                    name={`frontline[form_access][tag][request_form]`}
                                    className="custom-control-inputs"
                                    id={`frontline[form_access][tag][request_form]`}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`frontline[form_access][tag][request_form]`}
                                  >
                                    Request Form
                                  </label>
                                </div>
                            </div>
                          </div>

                        <div className="checkbox-group">
                        <p >Building Profile</p>
                        <div style={{ marginTop: "1rem" }}>
                          <div class="custom_checkbox_wrap flex-grow-1">

                          </div>

                          <div className="custom-control custom-checkbox">
                            <Input
                              type="checkbox"
                              name={`frontline[form_access][building_profiles][building_profile]`}
                              className="custom-control-inputs"
                              id={`frontline[form_access][building_profiles][building_profile]`}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`frontline[form_access][building_profiles][building_profile]`}
                            >
                              Building Profile
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <Input
                              type="checkbox"
                              name={`frontline[form_access][building_profiles][job_cards]`}
                              className="custom-control-inputs"
                              id={`frontline[form_access][building_profiles][job_cards]`}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`frontline[form_access][building_profiles][job_cards]`}
                            >
                              Job Cards
                            </label>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form_title fw-bold"> Membership Type </label>
                        <Input name="frontline[membership_type]" type="radio" onClick={() => { }} labelClass="custom-control-label" forLable={"union"} value={"union"} label={"Union"} className="custom-control-input" id="union" />
                        <Input name="frontline[membership_type]" type="radio" onClick={() => { }} labelClass="custom-control-label" forLable={"nonUnion"} value={"non-union"} label={"Non-Union"} className="custom-control-input" id="nonUnion" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form_title fw-bold"> Exemption Type </label>
                        <Input name="frontline[exemption_type]" type="radio" onClick={() => { }} labelClass="custom-control-label" forLable={"exempt"} value={"exempt"} label={"Exempt"} className="custom-control-input" id="exempt" />
                        <Input name="frontline[exemption_type]" type="radio" onClick={() => { }} labelClass="custom-control-label" forLable={"nonExempt"} value={"non-exempt"} label={"Non-Exempt"} className="custom-control-input" id="nonExempt" />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form_title fw-bold"> Scan Type <sup className="cst_sup_txt text_danger">*</sup></label>
                        <Input name="frontline[scan_type]" type="radio" onClick={() => { }} labelClass="custom-control-label" forLable={"cleaner"} value={"clean"} label={"Cleaner"} className="custom-control-input" id="cleaner" />
                        <Input name="frontline[scan_type]" type="radio" onClick={() => { }} labelClass="custom-control-label" forLable={"logbookReviewer"} value={"review"} label={"Logbook Reviewer"} className="custom-control-input" id="logbookReviewer" />

                      </div>
                    </div>

                    <div className="col-md-6" style={{ marginTop: "27px" }}>
                      <div className="form-group">
                        <div className="checkbox_block radius_checkbox">
                          <div className="custom-control custom-checkbox">
                            <Input name="frontline[overtime]" type="checkbox" className="custom-control-input" id="customCheck2" checked handleChange={(e) => {
                              const value = e.target.value

                              if (value !== "" || value == "true") {
                                // trigger modal Open
                                dispatch(change('frontlineForm', `frontline[daily_limit_hours]`, props?.formState?.frontline?.daily_limit_minutes ? calculateHoursNumberFromMinutes_V1(props?.formState?.frontline?.daily_limit_minutes) : 8));
                              }
                            }} />
                            <label className="custom-control-label" for="customCheck2"> Authorized for Overtime </label>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>

                  {/* <div className='row'>
                      <div className="col-md-6">
                        <label className="form_title fw-bold"> Limits <sup className="cst_sup_txt text_danger">*</sup></label>
                      </div>
                    </div> */}

                  <div className='row'>

                    <div className="col-md-4 ">
                      {/* <div className='row'> */}
                      <div className="form-group ">
                        <label className="form_title fw-bold"> Daily Limit <sup className="cst_sup_txt text_danger">*</sup></label>
                        <Input name="frontline[daily_limit_hours]" dataTest="select_leave_type" type="number" className="form-control input-modifier" placeholder="Type here" disabled={!props?.formState?.frontline.overtime} />

                      </div>

                    </div>

                    <div className='col-md-4'>
                      <div className="form-group ">
                        <label className="form_title fw-bold"> Weekly Limit <sup className="cst_sup_txt text_danger">*</sup></label>
                        <Input name="frontline[weekly_limit_hours]" dataTest="select_leave_type" type="number" className="form-control input-modifier" placeholder="Type here" disabled={true} />
                      </div>
                    </div>


                    {/* {
                        props?.formState?.frontline.overtime ?
                          <div className='col-md-4'>
                            <div className="form-group ">
                              <label className="form_title fw-bold"> Over Time Limit <sup className="cst_sup_txt text_danger">*</sup></label>

                              <Input name="frontline[overtime_limit_hours]" dataTest="select_leave_type" type="number" className="form-control input-modifier" placeholder="Type here" />
                            </div>
                          </div> : null
                      } */}
                    {/* </div> */}





                    {/* <Input name="frontline[daily_limit_hours]" type="text" onClick={() => { }} labelClass="custom-control-label" forLable={"daily_limit"} value={""} label={"Daily Limit"} className="custom-control-input" id="daily_limit" />
                              <Input name="frontline[weekly_limit_minutes]" type="text" onClick={() => { }} labelClass="custom-control-label" forLable={"weekly_limit"} value={""} label={"Weekly Limit"} className="custom-control-input" id="weekly_limit" />
                              <Input name="frontline[overtime_limit_minutes]" type="text" onClick={() => { }} labelClass="custom-control-label" forLable={"overtime_limit"} value={"overtime_limit"} label={"Overtime Limit"} className="custom-control-input" id="overtime_limit" /> */}

            <div className="modal-footer d-block">
                <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : "Submit"}</button>
                <button data-test="cancel_button" type="button" className="btn btn-outline-secondary cst_btn" onClick={props.handleCloseFrontlineForm}>Cancel</button>
                {props.frontlineUser !== null && props.match.params.frontlineId ? <div style={{ display: "grid", paddingTop: "10px" }}> <span> Created At : {moment(props.frontlineUser.created_at).format('MM-DD-YYYY')}</span> <span>Updated At : {moment(props.frontlineUser.updated_at).format('MM-DD-YYYY')}</span> <span>Last Edited By : TBD</span> </div> : ''}
              </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main >

    </>


  )
};

const mapStateToProps = (state) => {

  let frontline = {};

  const getSkillId = (skills) => {
    let skillSetList = state.TimeTrackerModReducer.skillSet_list;

    // Extract all the id values from the accounts parameter
    const accountIds = skills && skills.map(account => account.skill_id);

    // Filter the skillSetList using the accountIds
    const filteredSkillSet = skillSetList && skillSetList.filter(skill => accountIds && accountIds.includes(skill.id.toString()));

    return filteredSkillSet && filteredSkillSet.map((s) => ({
      value: s.id,
      label: s.name,
    }));
  };

  const getAccountId = (accounts) => {
    return accounts && accounts.map((a) => ({
      value: a.id,
      label: a.name,
    }));
  };

  const getTimeZone = (timezone) => {

    return Timezones && Timezones.map((e) => {
      if (e.id == timezone) {
        return { value: e.id, label: e.name }
      }
    })

  }

  if (isEmpty(state.adminOrSuperAdminReducer.frontlineUser)) {

    frontline = {
      role: "frontline",
      first_name: '',
      last_name: '',
      employee_id: '',
      dob_password: '',
      skill_ids: [],
      account_ids: [],
      // pin_number: null,
      phone_number: '',
      primary_email: null,
      timezone: null,
      scan_type: '',
      form_access: {
        team_management: {
          checklist: false,
          checklist_log: false,
          checklist_supervisor_summary: false,
          checklist_manager: false,
          training_log: false
        },
        logbook: {
          porter_log: false
        },
        inventory: {
          bulk_storage_supplies_usage: false,
          equipment_safety: false,
          daily_kitting_safety: false,
          monthly_cost_summary: false,
          monthly_usage_report: false
        },
        building_profiles: {
          building_profile: false,
          job_cards: false
        },
        projects: {
          deep_clean_chart: false
        },
        tag: {
          request_form: false
        }
      },
      daily_limit_minutes: calculateMinutesFromHours_V1(8),
      weekly_limit_minutes: calculateMinutesFromHours_V1(8 * 5),
      weekly_limit_hours: 40,
      daily_limit_hours: 8,
      overtime_limit_minutes: null,
      overtime_limit_hours: null
    }

  } else {

    frontline = {

      role: "frontline",
      first_name: state.adminOrSuperAdminReducer.frontlineUser ? state.adminOrSuperAdminReducer.frontlineUser.first_name : '',
      last_name: state.adminOrSuperAdminReducer.frontlineUser ? state.adminOrSuperAdminReducer.frontlineUser.last_name : '',
      employee_id: state.adminOrSuperAdminReducer.frontlineUser ? state.adminOrSuperAdminReducer.frontlineUser.employee_id : '',
      dob_password: state.adminOrSuperAdminReducer.frontlineUser ? state.adminOrSuperAdminReducer.frontlineUser.dob_password : '',

      skill_ids: state.adminOrSuperAdminReducer.frontlineUser ? getSkillId(state.adminOrSuperAdminReducer.frontlineUser.skills) : [],
      account_ids: state.adminOrSuperAdminReducer.frontlineUser ? getAccountId(state.adminOrSuperAdminReducer.frontlineUser.accounts) : [],

      pin_number: state.adminOrSuperAdminReducer.frontlineUser ? state.adminOrSuperAdminReducer.frontlineUser.pin_number : '',

      phone_number: state.adminOrSuperAdminReducer.frontlineUser ? state.adminOrSuperAdminReducer.frontlineUser.phone_number : '',
      emergency_contact_number: state.adminOrSuperAdminReducer.frontlineUser ? state.adminOrSuperAdminReducer.frontlineUser.emergency_contact_number : '',

      primary_email: state.adminOrSuperAdminReducer.frontlineUser ? state.adminOrSuperAdminReducer.frontlineUser.primary_email : '',
      secondary_email: state.adminOrSuperAdminReducer.frontlineUser ? state.adminOrSuperAdminReducer.frontlineUser.secondary_email : '',

      timezone: state.adminOrSuperAdminReducer.frontlineUser ? getTimeZone(state.adminOrSuperAdminReducer.frontlineUser.timezone) : '',

      phone_clock_in: state.adminOrSuperAdminReducer.frontlineUser ? state.adminOrSuperAdminReducer.frontlineUser.phone_clock_in : '',
      overtime: state.adminOrSuperAdminReducer.frontlineUser ? state.adminOrSuperAdminReducer.frontlineUser.overtime : '',
      membership_type: state.adminOrSuperAdminReducer.frontlineUser ? state.adminOrSuperAdminReducer.frontlineUser.membership_type : '',
      exemption_type: state.adminOrSuperAdminReducer.frontlineUser ? state.adminOrSuperAdminReducer.frontlineUser.exemption_type : '',
      scan_type: state.adminOrSuperAdminReducer.frontlineUser ? state.adminOrSuperAdminReducer.frontlineUser.scan_type : '',
      daily_limit_minutes: state.adminOrSuperAdminReducer.frontlineUser?.daily_limit_minutes ? state.adminOrSuperAdminReducer.frontlineUser.daily_limit_minutes : calculateMinutesFromHours_V1(8),
      weekly_limit_minutes: state.adminOrSuperAdminReducer.frontlineUser?.weekly_limit_minutes ? state.adminOrSuperAdminReducer.frontlineUser.weekly_limit_minutes : calculateMinutesFromHours_V1(8 * 5),
      weekly_limit_hours: state.adminOrSuperAdminReducer.frontlineUser?.weekly_limit_minutes ? calculateHoursNumberFromMinutes_V1(state.adminOrSuperAdminReducer.frontlineUser.weekly_limit_minutes) : 40,
      daily_limit_hours: state.adminOrSuperAdminReducer.frontlineUser?.daily_limit_minutes ? calculateHoursNumberFromMinutes_V1(state.adminOrSuperAdminReducer.frontlineUser.daily_limit_minutes) : 8,
      overtime_limit_minutes: state.adminOrSuperAdminReducer.frontlineUser?.overtime_limit_minutes ? state.adminOrSuperAdminReducer.frontlineUser.overtime_limit_minutes : null,
      overtime_limit_hours: state.adminOrSuperAdminReducer.frontlineUser?.overtime_limit_minutes ? calculateHoursNumberFromMinutes_V1(state.adminOrSuperAdminReducer.frontlineUser.overtime_limit_minutes) : null,
      form_access:state.adminOrSuperAdminReducer.frontlineUser? state.adminOrSuperAdminReducer.frontlineUser.form_access : ""
    }

  }
  return {
    initialValues: { frontline },
    formState: state.form?.frontlineForm?.values
  }

};


AddFrontlineForm = reduxForm({
  form: 'frontlineForm',
  validate,
  enableReinitialize: true,
})(AddFrontlineForm);

AddFrontlineForm = connect(mapStateToProps)(AddFrontlineForm);

export default AddFrontlineForm;
