import React, { useState } from 'react';
import moment from "moment";
import { Bar } from 'react-chartjs-2';
import { emptyData, BarGraphCountOptions, BarGraphSaleOptions, LineGraphQuoteCountOptions, routes } from '../../../utility/constants/constants';
import WeekMonthYearLists from './weekMonthYearLists';
import DashboardSkeleton from './dashboardSkeleton';
import { isEmpty } from '../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import { Line } from 'react-chartjs-2';
import { Status, FilteredQuotes } from '../../../utility/constants/constants';
import { IsArrayofObjectEmpty, getSecMintHoursDays } from '../../../utility/utility';
import EmptyGraph from './emptyGraph';
import posed from 'react-pose';
import ClientPastInspections from './clientPastInspections';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import UpcommingPegsure from './upcommingPegsure';
import { Link } from 'react-router-dom';
import '../../../styles/custom.css';


const graphStype = {
    marginTop: '0px',
    paddingTop: '0px'
}

const BarGraphStyle = {
    // label: '',
    backgroundColor: '#a1395e',
    hoverBackgroundColor: '#a1395eb3',
    borderWidth: 2,
    barThickness: 5,
    // barPercentage: 0.5
}

const dashboardData = (props, setURL, setOpenURL) => {
    const options = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }

    if (!props.dashboardData) {
        return (
            <DashboardSkeleton />
        )
    } else {

        const Average_Inspection_Score = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Average Inspection Score'
            },
            ...BarGraphCountOptions
        }
        const Average_Inspection_Lable = [];
        const Average_Inspection_Data = [];
        let Average_Inspection = null;

        if (props.dashboardData.average_inspection_score_chart_data) {
            for (let data of props.dashboardData.average_inspection_score_chart_data) {
                Average_Inspection_Lable.push(data[0]);
                Average_Inspection_Data.push(String(data[1]));
            }
        }

        Average_Inspection = {
            labels: Average_Inspection_Lable,
            datasets: [{
                ...BarGraphStyle, label: '', borderColor: '#a1395e',
                borderWidth: 2, backgroundColor: '#a1395e', data: Average_Inspection_Data, fill: false
            }]

        }

        const Inspections_Performed_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Inspections Performed'
            },
            ...BarGraphCountOptions
        }
        const Inspections_Performed_Lable = [];
        const Inspections_Performed_Data = [];
        let Inspections_Performed = null;
        if (props.dashboardData.inspection_count_chart_data) {
            for (let data of props.dashboardData.inspection_count_chart_data) {
                Inspections_Performed_Lable.push(data[0]);
                Inspections_Performed_Data.push(String(data[1]));
            }
        }

        Inspections_Performed = {
            labels: Inspections_Performed_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: Inspections_Performed_Data }]

        }

        const Tickets_Created_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Tickets Created'
            },
            ...BarGraphCountOptions
        }
        const Tickets_Created_Lable = [];
        const Tickets_Created_Data = [];
        let Tickets_Created = null;
        if (props.dashboardData.ticket_count_chart_data) {
            for (let data of props.dashboardData.ticket_count_chart_data) {
                Tickets_Created_Lable.push(data[0]);
                Tickets_Created_Data.push(String(data[1]));
            }
        }

        Tickets_Created = {
            labels: Tickets_Created_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: Tickets_Created_Data }]
        }

        const cleaning_history = props.dashboardData.customer_urls.filter(i => i.area.length > 0);
        const logbook_history = props.logbookHistoryDashboard && props.logbookHistoryDashboard.accounts.filter(i => i.logbooks.length > 0);

        return (
            <>
                <WeekMonthYearLists {...props} />
                {/* <!-- chart --> */}

                {props.isLoading ?
                    <div className="loader_btn_block">
                        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                    </div>
                    :

                    <div className="chart-content">
                        <div className="row">
                            <div class="col-lg-9 col-xl-10">
                                <div className="row">
                                    <div className="col-6 col-xl-4">
                                        <div className="tile performance_chart element" >
                                            <h4 className="chart_title1">Average Inspection Score</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.dashboardData.average_inspection_score}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_User_quotescreated.png" alt="Total Quotes Created" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-xl-4">
                                        <div className="tile performance_chart element">
                                            <h4 className="chart_title1">Total Inspections Created</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.dashboardData.inspection_count}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_User_quotessent.png" alt="Total Quotes Sent to Clients" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-xl-4">
                                        <div className="tile performance_chart element" >
                                            <h4 className="chart_title1">Total Tickets Created</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.dashboardData.ticket_count}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_User_quotescreated.png" alt="Total Quotes Created" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-6 col-xl-3">
                                        <div className="tile performance_chart element" >
                                            <h4 className="chart_title1">	Avg Resolution Time</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.dashboardData.quotes_created}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_User_quotescreated.png" alt="Total Quotes Created" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-12 chart_cont_box graph_responsive" onClick={() => props.viewFilterData(FilteredQuotes.PENDING_FOR_APPROVAL, 'inspections')}>
                                                {Average_Inspection.datasets[0].data[0] === '0' || Average_Inspection.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Top 10 Quotes, Sent by User(#)'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='' style={graphStype}>
                                                    <div className="chart-img" style={graphStype}><Bar height={15} width={50} data={Average_Inspection} options={Average_Inspection_Score} id="desktop_screen_graph"/></div>
                                                    <div className="chart-img" style={graphStype}><Bar height={45} width={50} data={Average_Inspection} options={Average_Inspection_Score} id="mobile_screen_graph"/></div>                                                  
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData(FilteredQuotes.PENDING_FOR_APPROVAL, 'inspections')}>
                                                {IsArrayofObjectEmpty(Inspections_Performed.datasets[0].data) || Inspections_Performed.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Inspections Performed'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='inspectionsPerformedId' style={graphStype}>
                                                        {/* <h6 className="chart_title2">Top 10 Quotes Approved by Admin</h6> */}
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={Inspections_Performed} options={Inspections_Performed_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData(FilteredQuotes.PENDING_FOR_APPROVAL, 'tickets')}>
                                                {IsArrayofObjectEmpty(Tickets_Created.datasets[0].data) || Tickets_Created.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Tickets Created'} />
                                                    :
                                                    <div className="tile chart-box-botL" id="ticketsCreatedId" style={graphStype}>
                                                        {/* <h6 className="chart_title2">Top 10 Sales Approved by Admin</h6> */}
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={Tickets_Created} options={Tickets_Created_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ClientPastInspections {...props} />
                            </div>
                            <div class="col-lg-3 col-xl-2 chart_cont_box">
                                <div class="ticket_inbox">
                                    <div class="card">
                                        <div class="card-header">
                                            Ticket Inbox
                                        </div>
                                        <div class="card-body">
                                            <div class="ticket_status">
                                            <div class="col">
                                                    <div class="col p-0">
                                                        <small>Open</small>
                                                        <h2 class="status_pending">{props.dashboardData.pending_ticket_count}</h2>
                                                    </div>
                                                    <div class="col p-0">
                                                        <small>Resolved</small>
                                                        <h2 class="status_open">{props.dashboardData.resolved_ticket_count}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ticket_link_list">
                                                <ul class="link_list">
                                                    {props.dashboardData.ticket_data && props.dashboardData.ticket_data.map((ticket) => {
                                                        return (
                                                            <li>
                                                                <a href="javascript:void(0);" onClick={() => props.previewTicketClicked(ticket)}>{ticket.subject}</a>
                                                                <small>{ticket.user && ticket.user.first_name + ' ' + ticket.user && ticket.user.last_name} </small>
                                                                <br />
                                                                <small>{getSecMintHoursDays(ticket.updated_at.replace(/-/gi, "/"), ticket.account.timezone)}</small>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="ticket_inbox mt-2">
                                    <div class="card">
                                        <div class="card-header mb-0">
                                            Upcoming Schedules
                                  </div>
                                        <div class="card-body">
                                            <div class="ticket_status">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="ticket_link_list">
                                                            <ul class="link_list">
                                                                {props.upcomingSchedules && props.upcomingSchedules.future_schedules.map((schedule, index) => <li key={index} className="label_modifier"><small>{schedule.area.name}</small> <small className="status_open">{moment(moment().format("MM-DD-YYYY") + " " + schedule.start_time, "MM-DD-YYYY HH:mm:ss").format("hh:mm A")}</small></li>)}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div> */}
                                {cleaning_history && cleaning_history.length > 0 && (props.user.selected_mode && props.user.selected_mode === 'external') ?
                                    <div class="ticket_inbox mt-2">
                                        <div class="card">
                                            <div class="card-header mb-0">
                                                Cleaning History
                                            </div>
                                            <div class="card-body">
                                                <div class="ticket_status">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="ticket_link_list">
                                                                <ul class="link_list" style={{ textAlign: 'left' }}>
                                                                {cleaning_history && cleaning_history.map((customer, i) => <li style={{ marginTop: 5 }} key={i} className="label_modifier"> <a href="javascript:void(0);"
                                                                onClick={() => [setOpenURL(true), setURL(customer.url)]}
                                                            >{customer.name}</a></li>)}
                                                                    {/* {props.upcomingSchedules && props.upcomingSchedules.future_schedules.map((schedule, index) => <li key={index} className="label_modifier"><small>{schedule.area.name}</small> <small className="status_open">{moment(moment().format("MM-DD-YYYY") + " " + schedule.start_time, "MM-DD-YYYY HH:mm:ss").format("hh:mm A")}</small></li>)} */}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div> : ''
                                }
                                {logbook_history && (props.user.selected_mode && props.user.selected_mode === 'external') ?
                                    <div class="ticket_inbox mt-2">
                                        <div class="card">
                                            <div class="card-header mb-0">
                                                Logbook History
                                            </div>
                                            <div class="card-body">
                                                <div class="ticket_status">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="ticket_link_list">
                                                                <ul class="link_list" style={{ textAlign: 'left' }}>
                                                                {logbook_history && logbook_history.length > 0 ? logbook_history.map((customer, i) => <li style={{ marginTop: 5 }} key={i} className="label_modifier"> <a href="javascript:void(0);"
                                                                onClick={() => [setOpenURL(true), setURL(customer.url)]}
                                                            >{customer.name}</a></li>) : <h6 style={{textAlign: 'center', color: '#6f002e'}}> No logbook history </h6>}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div> : ''
                                }    
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

const ClientDashbord = (props) => {

    const [openUrl, setOpenURL] = useState(false);
    const [genericUrl, setURL] = useState("");

    window.onresize = function () {
        const emptyChartObj = document.getElementsByClassName("empty-graph_heading");

        let cahrtjsHeight = null;

        if (props.dashboardData) {
            if (props.dashboardData.inspection_count > 0) {
                cahrtjsHeight = document.getElementById('inspectionsPerformedId') ? document.getElementById('inspectionsPerformedId').clientHeight : '';
            } else if (props.dashboardData.ticket_count > 0) {
                cahrtjsHeight = document.getElementById('ticketsCreatedId') ? document.getElementById('ticketsCreatedId').clientHeight : '';
            }
        } else {
            cahrtjsHeight = document.getElementById('inspectionsPerformedId') ? document.getElementById('inspectionsPerformedId').clientHeight : '';
        }
        // const cahrtjsHeight = document.getElementById('inspectionsPerformedId') ? document.getElementById('inspectionsPerformedId').clientHeight : '';
        if (emptyChartObj.length !== 0) {
            for (var i = 0; i < emptyChartObj.length; i++) {
                emptyChartObj[i].style.height = `${cahrtjsHeight}px`
            }
        }
    };

    return (
        <main className="app-content wraper_content chart_section user_dashboard">
            <section className="admin_dashboard appcontent_Inner">
                {dashboardData(props, setURL, setOpenURL)}
                {/* <!-- canvas sidebar -->
            <div className="canvas-body">
                <div id="my-navigation" className="offcanvas offcanvas--right offcanvas--initialized">
                    <div className="sidebar_content">
                        <a href="#" className="close_sidebar"><img src="../images/icons/back-arrow.png"></a>
                        <div className="form-group">
                            <input type="text" className="form-control canvas-search" placeholder="Search">
                        </div>
                        <div className="recent-search">
                            <p>RECENT TICKETS</p>
                        </div>
                        <div className="search-category">
                            <p>Unsatisfied</p>
                        </div>
                    </div>
                </div>
            </div> */}
                <Dialog
                    open={openUrl}

                    aria-labelledby="form-dialog-title"
                >
                    <div className="row p-0 m-0">
                        <div className="col-10"></div>
                        <div className="col-2">
                            <h4 onClick={() => [setURL(''), setOpenURL(false)]} className="float-right text-right mt-2 cursor-pointer"><i className="fa fa-times"></i></h4>
                        </div>
                    </div>
                    <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                        <h3 style={{ color: '#ab385e' }}></h3>
                    </DialogTitle>
                    <div className="qr-body-single"><div className="p-5"><a href={genericUrl} target="_blank" className="text_danger">{genericUrl}</a></div></div>
                    <DialogActions>
                        <button style={{ minHeight: '36px', minWidth: '80px', visibility: "hidden" }} className={"float-right btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel">Cancel</button>
                        <button style={{ minHeight: '36px', minWidth: '80px', visibility: "hidden" }} id="delete" type="delete">Okay</button>
                    </DialogActions>
                </Dialog>
            </section>
            <UpcommingPegsure {...props} account_timezone={props.upcomingSchedules ? props.upcomingSchedules.account_timezone : null} futureSchedules={props.upcomingSchedules ? props.upcomingSchedules.future_schedules : null} />
        </main>
    )
}

export default ClientDashbord;