import React, { useEffect, useState } from "react";
import CheckListSupervisorForm from '../../../../../component/FormAuditModule/TeamManagement/ChecklistSupervisior/newChecklistSupervisor/checkListSupervisorForm'
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {  getAllJob, getCustomerInfos,getManagersList, getEmployeeList,addTeamSupervisorSummary, getSingleSupervisorData, editTeamSupervisorSummary,getCleaningWorkersList } from "../../../../../../redux/actions/FormAuditModAction/TeamManagementAction/teamManagementAction";
import moment from "moment";





const ChecklistSupervisiorContainer=()=> {
    const {location:{pathname}}=useHistory()
    const {id} = useParams();
    const dispatch=useDispatch()
    const isEdit=Boolean(pathname.split("/")[2] !== "view" && id)
    const isView=Boolean(pathname.split("/")[2].includes("view"))
    const {supervisorDetails}=useSelector((state)=>state.FormAuditModReducer)
    const current= new Date()
    
    const convertDate = (date) => {
      if (date) {
        var parts = date.split('T');
        var datePart = parts[0];
        let dt = moment(datePart)._d
        return dt
      }
    }  

  const formatSectionData=(item)=>{
      return [{...item,worker_id:item.worker_id,comments:item.comments,scheduled_date:convertDate(item?.scheduled_date),date_of_completed:item?.date_of_completed ? convertDate(item?.date_of_completed): null,completed:item.completed ? "Yes" :"No"}]
  }

  const formatMonthYear = (monthNumber, year) => {
    // Create a Moment.js object with the given month and year
    const date = new Date(year, monthNumber - 1); // Month is zero-based in Moment.js
    
    // Format the date as desired
    //const formattedDate = date.format('MMMM - YYYY'); // Format to "December - 2024"
  
    return date;
  };
  
   
  const defaultForm={
    company_id:isEdit || isView? supervisorDetails?.company_id ? supervisorDetails.company_id :"" : "",
    account_id:isEdit || isView? supervisorDetails?.account_id ? supervisorDetails.account_id :"" :"",
    supervisor_id:isEdit || isView? supervisorDetails?.supervisor_id ? supervisorDetails.supervisor_id :"" :"",
    manager_id:isEdit || isView? supervisorDetails?.manager_id ? supervisorDetails.manager_id :"" : "",
    month: isEdit || isView? supervisorDetails?.month ? formatMonthYear(supervisorDetails.month,supervisorDetails.year) :"" :"",
    year:isEdit || isView? supervisorDetails?.year ? current.setFullYear(+supervisorDetails.year) :"" :"",
    items: isEdit || isView? supervisorDetails?.sections? formatSectionData(supervisorDetails.sections) : [
      {
        worker_id:"",
        scheduled_date:"",
        completed:"",
        date_of_completed:"",
        comments:""
      }
    ] : [
      {
        worker_id:"",
        scheduled_date:"",
        completed:"",
        date_of_completed:"",
        comments:""
      }
    ]
  }
  const structureBody=(data)=>{
    let body=null
    const {items,month,year,...remainData}=data
    
    const commonBody={
      month:data.month?.month ? (data.month.month.number).toString() : (new Date(data.month).getMonth() + 1) ,
      year:data.month?.year? data.month?.year.toString() : new Date(data.year).getFullYear().toString(),
      ...remainData
    }

     if(isEdit){
      
      const {worker,date_of_completed, ...remainsItem}=items[0]
       body=[{
        ...remainData,
        id:id,
        // user_id:supervisorDetails.user_id,
        active_status: "active",
        month:data.month?.month ? (data.month.month.number).toString() : new Date(data.month).getMonth() + 1,
        year:data.month?.year? data.month?.year.toString() : new Date(data.month).getFullYear().toString(),
        sections:items[0].completed==="No" ? {...remainsItem,scheduled_date:moment(items[0].scheduled_date).format('YYYY-MM-DD'),completed:items[0].completed==="Yes" ? true : false,id:items[0].id,team_checklist_supervisor_summary_id:id,active_status: "active"}  : 
        {...remainsItem,scheduled_date:moment(items[0].scheduled_date).format('YYYY-MM-DD'),date_of_completed:moment(items[0].date_of_completed).format('YYYY-MM-DD'),completed:items[0].completed==="Yes" ? true : false,id:items[0].id,team_checklist_supervisor_summary_id:id,active_status: "active"} ,
       }]
     }
     else{
      
      body=data.items.map((item)=> {
        const {date_of_completed, ...remainingItem } = item
        
        
        if(item.completed==="No"){
          return {...commonBody,...remainingItem,scheduled_date:moment(item.scheduled_date).format('YYYY-MM-DD'), completed:item.completed==="Yes" ? true : false}
        }else{
          return {...commonBody,...remainingItem,scheduled_date:moment(item.scheduled_date).format('YYYY-MM-DD'),date_of_completed:moment(item.date_of_completed).format('YYYY-MM-DD'),completed:item.completed==="Yes" ? true : false}
        }
      })

      //   body={
      //   ...remainData,
      //   month:data.month?.getMonth ? (data.month?.getMonth() +1).toString() : (new Date(data.month).getMonth() + 1) ,
      //   year:data.year?.year? data.year?.year.toString() : new Date(data.year).getFullYear().toString(),
      //   sections:items.map((item)=> {
      //     return{...item,scheduled_date:item.scheduled_date.toISOString(),date_of_completed:item.date_of_completed.toISOString(),completed:item.completed==="Yes" ? true : false}
      //   })

      //  }
        
     }
     
     return {
      data:body
    }
  }  
  
  const onSubmit=(data)=>{
  //  const {items,month,year,completed,...remainData}=data
   
  //  const body={
  //   data:{
  //   month:data.month?.getMonth ? data.month?.getMonth() +1 : new Date(data.month).getMonth() + 1 ,
  //   year:data.year?.year? data.year?.year.toString() : new Date(data.year).getFullYear().toString(),
  //   [`${isEdit ? "item" :"items"}`]: isEdit ? {...items[0],scheduled_date:items[0].scheduled_date.toISOString(),date_of_completed:items[0].date_of_completed.toISOString(),completed:true}  : data.items.map((item)=> {
  //     let IsoSceduled=item.scheduled_date.toISOString()
  //     let IsodateofCompleted=item.date_of_completed.toISOString()
  //     return{...item,scheduled_date:IsoSceduled,date_of_completed:IsodateofCompleted,completed:completed==="Yes" ? true : false}
  //   }),
  //   ...remainData
  //   }
   
  //  }
   
   if(isEdit){
    dispatch(editTeamSupervisorSummary(structureBody(data)))
    
   }else{

     dispatch(addTeamSupervisorSummary(structureBody(data)))
   }

    
  }

  useEffect(()=>{
    if(isEdit || isView && id){
      dispatch(getSingleSupervisorData(id))
    }
    dispatch(getCustomerInfos())
    dispatch(getAllJob())
    dispatch(getManagersList({manager : true}))
    dispatch(getCleaningWorkersList({roles:"frontline"}))
  },[id])


//   useEffect(()=>{
  
//  },[])

  return (
    <CheckListSupervisorForm
    formSubmittedCallback={onSubmit}
    elements={defaultForm}
    isEdit={isEdit}
    isView={isView}
    modalUpdate={true}
    />
  );
}

export default ChecklistSupervisiorContainer;