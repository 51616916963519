import React, { useEffect, useState } from 'react'
import withFormHoc from '../../../../../../hoc/withReactHookForm/withReactHookForm'
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Input } from '../../../../UI/InputElement/reactFormHookInputField';
import CollectionsComponent from './collectionsComponent';
import { COLLECTIONS, EDIT, VIEW, sectionsList } from '../../../../../../utility/constants/constants';
import { useSelector } from 'react-redux';
import storage from '../../../../../../utility/storage';
import AssetsFormSkeleton from '../../../../Inventory/Assets/NewAssetsForm/AssetsFormSkeleton';
function NewTeamChecklistForm(props) {
  const history = useHistory()
  const params = useParams()
  const current = new Date()
  const [score, setScore] = useState(0)
  const [total, setTotal] = useState(0)
  const [values, setValues] = useState(sectionsList.sections)
  const [isAllChecked, setIsAllChecked] = useState(false)
  const { isLoading, isChecklistLoading } = useSelector((state) => state.FormAuditModReducer)

  useEffect(() => {
    const collectionValues = [...values]
    const findScore = collectionValues.reduce((arr, curr) => arr + curr.checked_count, 0)
    const findTotal = collectionValues.reduce((arr, curr) => arr + curr.total_count, 0)
    setTotal(findTotal)
    setScore(findScore)
  }, [values])

  const Calc = (score, total) => {
    const percent = (score / total) * 100
    const finalPercentage = percent.toFixed(0)
    return finalPercentage
  }

  const { managersList, jobList } = useSelector((state) => state.FormAuditModReducer)

  const getManagersList = (manager) => {
    if (managersList && managersList.length > 0) {
      return manager && manager.map((data) => {
        return {
          value: data.id,
          label: data.first_name + ' ' + data.last_name,
        }
      })
    } else {
      return []
    }
  };
  const [managerList, setManagerList] = useState(getManagersList(managersList));

  useEffect(() => {
    setManagerList(getManagersList(managersList))
  }, [managersList])

  const getJobList = (manager) => {
    if (jobList && jobList.length > 0) {
      return manager && manager.map((data) => {
        return {
          value: data.id,
          label: data.name,
        }
      })
    } else {
      return []
    }
  };

  const [listOfJob, setJobList] = useState(getJobList(jobList))

  useEffect(() => {
    setJobList(getJobList(jobList))
  }, [jobList])

  const inputValuesHandler = (value) => {
    if (score === 0) {
      setIsAllChecked(true)
      return false
    }

    // const removeImage = [...values]
    // const findCollection = removeImage.filter((item) => [COLLECTIONS.accessoris, COLLECTIONS.controlItems].includes(item.name))
    // let newData = findCollection.map((obj) => ({
    //   ...obj,
    //   lineItems: obj.lineItems.map((item) => {
    //     const { image, ...rest } = item; // Destructure the image property
    //     return rest; // Return the modified object without the image property
    //   }),
    // }));
    // const updateCollection = removeImage.filter((item) =>
    //   item.name !== COLLECTIONS.accessoris && item.name !== COLLECTIONS.controlItems
    // );
    // const FinalCollection = [...updateCollection, ...newData]
    let data = value
    data.score = score.toString()
    data.score_of = total.toString()
    data.percentage = Calc(score, total)
    data.sections = values
    props.onSubmit(data)
  }

  if (isChecklistLoading) {
    return (
      <AssetsFormSkeleton />
    )
  }

  return (
    <main class="app-content wraper_content chart_section">
      <section class="teammanage_formmain">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="team_manage_head">
                <h1 class="tm_top_head">{props?.isEdit ? `${EDIT} Team Checklist` : props?.isView ? `${VIEW} Team Checklist` : " Team Checklist"}</h1>
              </div>
            </div>
            <form class="col-lg-12" onSubmit={props.handleSubmit((data) => inputValuesHandler(data))}>
              <div class="tm_form_wrapper">
                <div class="tm_form_wrap">
                  <div class="tm_form_innner">
                    <div class="form">
                      <div class="row">
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            <Input
                              type="inputSelect"
                              labelClasses="form-label"
                              control={props.control}
                              controller={props.controller}
                              selectLabel="Job"
                              placeholder={'Please Select'}
                              selectName={`account_id`}
                              id={`account_id`}
                              selectOptions={listOfJob}
                              required={props.register(`account_id`, {
                                required: "This field is required"
                              })}
                              validationErrorMessage={props.errors?.account_id?.message}
                              selectClasses="form-select"
                              disabled={props.isView}
                            />
                            {/* <div class="dropdown_caret">
                            <img
                              src="/images/icons/dropdown_caret.svg"
                              alt="dropdown_caret"
                            />
                          </div> */}
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            <Input
                              type="text"
                              labelClasses="form-label"
                              selectLabel="Location"
                              label={`Location`}
                              placeholder={'Location'}
                              id={`location`}
                              formControlProps={props.register(`location`, {
                                required: "This field is required"
                              })}
                              validationErrorMessage={props.errors?.location?.message}
                              formControlClasses="form-control"
                              disabled={props.isView}
                            />
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            <Input
                              type="date"
                              datePickerType="date"
                              formControlClasses="form-control"
                              id="date"
                              placeholder={"MM /DD/ YYYY"}
                              control={props.control}
                              controller={props.controller}
                              selectName={"date"}
                              dateFormat="MM/dd/yyyy"
                              minDate={new Date()}
                              preventReadOnly={false}
                              label="Date"
                              formControlProps={props.register('date', {
                                required: "This field is required", maxLength: 80
                              })}
                              validationErrorMessage={props.errors?.date?.message}
                              disabled={props.isView}
                            />

                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            <Input
                              type="inputSelect"
                              labelClasses="form-label"
                              control={props.control}
                              controller={props.controller}
                              selectLabel="Specialist"
                              selectName={`specialist`}
                              placeholder={'Please Select'}
                              id={`specialist`}
                              selectOptions={[{ value: 'Collection Specialist', label: 'Collections Specialist' }, { value: 'Vacuum Specialist', label: 'Vaccum Specialist' }, { value: 'Restroom Specialist', label: 'Restroom Specialist' }, { value: 'Utility Specialist', label: 'Utility Specialist' }]}
                              required={props.register(`specialist`, {
                                required: "This field is required"
                              })}
                              validationErrorMessage={props.errors?.specialist?.message}
                              selectClasses="form-select"
                              disabled={props.isView}
                            />
                            {/* <div class="dropdown_caret">
                            <img
                              src="/images/icons/dropdown_caret.svg"
                              alt="dropdown_caret"
                            />
                          </div> */}
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            <Input
                              type="inputSelect"
                              labelClasses="form-label"
                              control={props.control}
                              controller={props.controller}
                              placeholder={'Please Select'}
                              selectLabel="Employee"
                              selectName={`employee_id`}
                              id={`employee_id`}
                              selectOptions={managerList}
                              required={props.register(`employee_id`, {
                                required: "This field is required"
                              })}
                              validationErrorMessage={props.errors?.employee_id?.message}
                              selectClasses="form-select"
                              disabled={props.isView}
                            />
                            {/* <div class="dropdown_caret">
                            <img
                              src="/images/icons/dropdown_caret.svg"
                              alt="dropdown_caret"
                            />
                          </div> */}
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            <Input
                              type="text"
                              labelClasses="form-label"
                              selectLabel="Conducted By"
                              label={`Conducted By`}
                              id={`conducted_by`}
                              formControlProps={props.register(`conducted_by`)}
                              formControlClasses="form-control"
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div class="col-md-12 col-sm-12">
                          <div class="form-group">
                            <Input
                              type="textarea"
                              id="comments"
                              formControlClasses="form-control"
                              placeholder="Type here"
                              label="Comments"
                              formControlProps={props.register(`comments`,
                                {
                                  required: "This field is required",
                                  maxLength: 80,
                                }
                              )}
                              validationErrorMessage={
                                props.errors?.comments?.message
                              }
                              disabled={props.isView}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CollectionsComponent isAllChecked={isAllChecked} setValues={setValues} setIsAllChecked={setIsAllChecked} {...props} />
              <div class="">
                <div class="row">
                  <div class="col-lg-8">
                    <div class="team_management_card_wrap">
                      <div class="card_top_wrap">
                        <h4>Goals</h4>
                      </div>
                      <div class="card_middle_wrap">
                        <Input
                          type="textarea"
                          formControlClasses="form-control"
                          placeholder="Type here"
                          rows={6}
                          formControlProps={props.register(`goal`,
                            {
                              required: "This field is required",
                              maxLength: 80,
                            }
                          )}
                          validationErrorMessage={
                            props.errors?.goal?.message
                          }
                          disabled={props.isView}
                        />
                      </div>
                    </div>
                    <p style={{ color: '#DD2726', fontSize: '13px' }} >
                      {props.errors?.goal?.message}
                    </p>
                  </div>
                  <div class="col-lg-4">
                    <div class="scrore-card">
                      <div class="scrore-input-card">
                        <label>Score</label>
                        <p>{score}</p>
                        <label>Of</label>
                        <p>{total}</p>
                      </div>
                      <div class="scrore-input-card scrore-input-card-center">
                        <label>%</label>
                        <p>{Calc(score, total)}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-sm-12">
                    <div class="submitcancel_btn">
                      <button onClick={() => history.goBack()} type="button" class="btn cst_btn btn-outline-secondary  cancel_btnnew">
                        Cancel
                      </button>
                      {props.isView ? null : (<button type="submit" class={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>
                        {isLoading ? ""  : props.isEdit ?  "Update"  : "Submit"}
                      </button>)}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  )
}

export default withFormHoc(NewTeamChecklistForm)


