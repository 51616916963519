import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { Input } from '../../../../UI/InputElement/InputElement';
import isEmpty from '../../../../../../utility/IsEmptyValidator';
import { getConvertTo12Hours, convertDateFormatV6 } from '../../../../../../utility/utility';
import { newShiftFormValidator as validate } from '../../../../../../utility/validator/validator';
import ShiftFormSkeleton from '../NewShiftComponent/ShiftFormSkeleton';
import moment from "moment";
import cloneDeep from 'clone-deep';
import { tzDateConverterTool_V1, tzTimeConversionTool_V1 } from '../../../../../../utility/TimezoneOperations/timezoneUtility';
import ShiftOverriddenWarningModal from '../NewShiftComponent/ShiftOverriddenWarningComponent/ShiftOverriddenWarningModal';

let EditPerDayShiftDetailForm = (props) => {

    const { handleSubmit, singleShiftScheduleData } = props;
    const [selectedJobsList, setSelectedJobsList] = useState([]);

    useEffect(() => {

        if (props.isEditFLShiftForm && props.isEditFLShiftForm == true) {

            if (singleShiftScheduleData && singleShiftScheduleData.id) {

                setSelectedJobsList(singleShiftScheduleData && singleShiftScheduleData.accounts && singleShiftScheduleData.accounts.length > 0 ? singleShiftScheduleData.accounts : []);

            }

        }

    }, [(props.singleShiftScheduleData && props.singleShiftScheduleData.id) || (singleShiftScheduleData && singleShiftScheduleData.id)]);

    const getFrontlineUsersList = (frontlineUsersList) => {
        if (props.frontlineUsersList && props.frontlineUsersList.length > 0) {
            return frontlineUsersList && frontlineUsersList.map((data) => {
                return {
                    value: data.id,
                    label: data.first_name + ' ' + data.last_name,

                }
            })
        } else {
            return []
        }
    };

    const [frontlineUsersList, setFrontlineUsersList] = useState(getFrontlineUsersList(props.frontlineUsersList));

    useEffect(() => {
        setFrontlineUsersList(getFrontlineUsersList(props.frontlineUsersList))
    }, [props.frontlineUsersList]);

    const noOptionsMessage = (a, b) => {
        return 'No options';
    }

    if (props.isUpdateFLShiftForm) {
        return (
            <ShiftFormSkeleton />
        )
    } else {
        return (
            <>
                <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                    <div className="inspection_content_sec appcontent_Inner">
                        <div className="container-fluids">
                            <div className="quote_form ticket_form" style={{ maxWidth: '700px' }}>
                                <div className="row">
                                    <div className="col-sm-12 text-center"> <h2 className="md_title" > Update Shift </h2></div>
                                </div>
                                <div className="addQuote_form">
                                    <form className="form_content" onSubmit={handleSubmit} >
                                        <div className="cst_tab_content">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> Frontline User <sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="shiftOrigin[user_id]" dataTest="select_leave_type" type="inputSelect" options={frontlineUsersList} noOptionsMessage={noOptionsMessage} placeholder="Please select" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> {selectedJobsList && selectedJobsList.length > 1 ? 'Jobs' : 'Job'} </label>
                                                        <input type="text" value={selectedJobsList && selectedJobsList.map(job => job.name).join(', ')} className="form-control input-modifier" placeholder='' disabled />
                                                    </div>
                                                </div>
                                            </div>

                                            {selectedJobsList && selectedJobsList.map((job, index) => {
                                                if (job && job.address && job.address.zip.length > 0) {
                                                    return (
                                                        <>
                                                            <div className="row" key={index}>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> {job.name ? job.name : ''} </label>
                                                                        <input
                                                                            type="text"
                                                                            value={`${job.address.state + ', ' + job.address.country + ', ' + job.address.zip}`}
                                                                            className="form-control input_modifier"
                                                                            placeholder=''
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                } else {
                                                    return (
                                                        <>
                                                            <div className="row" key={index}>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label className="form_title fw-bold"> {job.name ? job.name : ''} </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control input_modifier"
                                                                            placeholder='No Address'
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                }
                                            })}

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> From Date </label>
                                                        <div className="input-group cst_input_group col-lg-14 pl-0">
                                                            <div style={{ width: '100%' }}>
                                                                <input
                                                                    value={singleShiftScheduleData && singleShiftScheduleData.date ? tzDateConverterTool_V1(singleShiftScheduleData.date) : ''}
                                                                    className="form-control input-modifier"
                                                                    placeholder=""
                                                                    disabled
                                                                />
                                                            </div>
                                                            <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> To Date </label>
                                                        <div className="input-group cst_input_group col-lg-14 pl-0">
                                                            <div style={{ width: '100%' }}>
                                                                <input
                                                                    value={singleShiftScheduleData && singleShiftScheduleData.date ? tzDateConverterTool_V1(singleShiftScheduleData.date) : ''}
                                                                    className="form-control input-modifier"
                                                                    placeholder=""
                                                                    disabled
                                                                />
                                                            </div>
                                                            <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> Start Time </label>
                                                        <input
                                                            type="text"
                                                            value={singleShiftScheduleData && singleShiftScheduleData.shift && singleShiftScheduleData.shift.start_time ? tzTimeConversionTool_V1(singleShiftScheduleData.shift.start_time, new Date()) : ''}
                                                            className="form-control input-modifier"
                                                            placeholder=''
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form_title fw-bold"> End Time </label>
                                                        <input
                                                            type="text"
                                                            value={singleShiftScheduleData && singleShiftScheduleData.shift && singleShiftScheduleData.shift.end_time ? tzTimeConversionTool_V1(singleShiftScheduleData.shift.end_time, new Date()) : ''}
                                                            className="form-control input-modifier"
                                                            placeholder=''
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <div className="group">
                                                        <label className="form_title fw-bold"> Notes </label>
                                                        <textarea
                                                            value={singleShiftScheduleData && singleShiftScheduleData.shift && singleShiftScheduleData.shift.notes ? singleShiftScheduleData.shift.notes : ''}
                                                            className="form-control textarea_modifier"
                                                            rows="4"
                                                            cols="50"
                                                            placeholder=""
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal-footer">
                                            <button data-test="submit_button" type="submit" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} >{props.isLoading ? "" : "Submit"}</button>
                                            <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" onClick={() => { props.onCancelClicked() }}>Cancel</button>
                                            <button
                                                type="button"
                                                className="d-none shiftOverriddenWarningModal"
                                                data-toggle="modal"
                                                data-target="#shiftOverriddenWarningModal"
                                                data-backdrop="static"
                                                data-keyboard="false"
                                                onClick={() => { props.handleFrequentTimeTracker_openModal(); }}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >

                {props.isTimeTrackerModalOpen ? (
                    <ShiftOverriddenWarningModal {...props} />
                ) : null}
            </>
        );
    }

};

const mapStateToProps = (state, props) => {

    let shiftOrigin = {};

    const getAssignedFLUserInfo = (fluser) => {
        return {
            value: fluser.id,
            label: fluser.first_name + ' ' + fluser.last_name,
        }
    };

    if (isEmpty(state.TimeTrackerModReducer.singleShiftScheduleData)) {
        shiftOrigin = {
            user_id: '',
        }
    } else {
        const value = cloneDeep(state.TimeTrackerModReducer.singleShiftScheduleData);
        const fetchedValue = cloneDeep(props.singleShiftScheduleData);

        shiftOrigin = {
            accountIds: fetchedValue && fetchedValue.accounts && fetchedValue.accounts.length > 0 ? fetchedValue.accounts : [],
            user_id: value && value.assignedTo && value.assignedTo.id ? getAssignedFLUserInfo(value.assignedTo) : '',
            start_date: fetchedValue && fetchedValue.shift.start_date && fetchedValue.shift.start_date.length > 0 ? fetchedValue.shift.start_date : '',
            end_date: fetchedValue && fetchedValue.shift.end_date && fetchedValue.shift.end_date.length > 0 ? fetchedValue.shift.end_date : '',
            start_time: fetchedValue && fetchedValue.shift.start_time && fetchedValue.shift.start_time.length > 0 ? getConvertTo12Hours(fetchedValue.shift.start_time) : '',
            end_time: fetchedValue && fetchedValue.shift.end_time && fetchedValue.shift.end_time.length > 0 ? getConvertTo12Hours(fetchedValue.shift.end_time) : '',
            notes: fetchedValue && fetchedValue.shift.notes && fetchedValue.shift.notes.length > 0 ? fetchedValue.shift.notes : '',
        }
    }

    return {
        initialValues: { shiftOrigin },
        formStates: getFormValues('editPerDayShiftDetailForm')(state)
    }

}

EditPerDayShiftDetailForm = reduxForm({
    form: 'editPerDayShiftDetailForm',
    validate,
    enableReinitialize: true
})(EditPerDayShiftDetailForm);

EditPerDayShiftDetailForm = connect(mapStateToProps)(EditPerDayShiftDetailForm)

export default EditPerDayShiftDetailForm;