import React, { useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../styles/custom.css';
import { makeFirstLetterCapital, CustomNoDataComponent, GetFormatedDate, convertDateAndTimeTimezone } from '../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import * as actions from '../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { currentData, userRoles } from '../../../utility/constants/constants';
import ImageLoader from 'react-imageloader';
import '../../../styles/custom.css';


const CHIServysTable = (props) => {

    const dispatch = useDispatch();
    // const SearchValue = useSelector(state => state.form.searchUserForm.values);
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const SearchOrResetChiClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetChiClicked);
    const chiTotalCount = useSelector(state => state.adminOrSuperAdminReducer.chi_total_count);
    const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.chi_total_no_of_pages);
    const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.chi_no_of_rows);
    const ChiPage = useSelector(state => state.adminOrSuperAdminReducer.chi_cur_page);
    function preloader() {
        return <img style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} src="/images/gif/giphy.gif" />;
    }

    const data = props.chiServyList && props.chiServyList.map(chi => {

        return {
            id: chi.id,
            image:
                chi.user.photo_urls.small ?
                    <ImageLoader
                        imgProps={{ style: { height: '35px', width: '35px', borderRadius: '50px', float: 'left' } }}
                        style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }}
                        src={chi.user.photo_urls.small}
                        wrapper={React.createFactory('div')}
                        preloader={preloader}>
                        Image load failed!
                            </ImageLoader>
                    :
                    <img src="/customImages/user.png" style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} />
            ,
            name: chi.user.first_name + ' ' + chi.user.last_name,
            // job: chi.account,
            feedback: chi.feedback,
            region: chi.user.region.name,
            date: convertDateAndTimeTimezone(chi.created_at, chi.user.timezone),
            rating: chi.rating,
            status:
            chi.ticket.length > 0 ?
             <>
            {chi.ticket.length > 0 && chi.ticket[0].status === "open" ?  
            <button className='btn chi_status chi_close_status'>{makeFirstLetterCapital(chi.ticket.length > 0 && chi.ticket[0].status)}</button>
            : <button className='btn chi_status chi_open_status '>{makeFirstLetterCapital(chi.ticket.length > 0 && chi.ticket[0].status)}</button>
            }
            </> :
            <button className='btn chi_status chi_close_status'>Open</button>
            ,
            actions:  <>
            <span data-tip="View Ticket" className="mr_20 account_edit_icn" onClick={() => props.previewChiClicked(chi)} data-test="edit_eye_icon"><i className="fas fa-eye cst_icon mr-2" aria-hidden="true"></i></span>
            <ReactTooltip place="top" type="dark" effect="solid" />
        </>
        }
    })


    const columns = [
        
        {
            Header: <><span></span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'image', // String-based value accessors!,
            width: 50
        },
        {
            Header: <><span>ID</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'id', // String-based value accessors!,
            show: props.chiServyList.length !== 0,
            // width: 50
        },
         {
            Header: <><span>User</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'name', // String-based value accessors!,
            show: props.chiServyList.length !== 0,
        },

        {
            Header: <><span>Feedback</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'feedback',
            show: props.chiServyList.length !== 0,
        },
         {
            // id: 'friendName', // Required because our accessor is not a string
            Header: <><span>Region</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'region',
            show: props.chiServyList.length !== 0, // Custom value accessors!
        },
        {
            // id: 'friendName', // Required because our accessor is not a string
            Header: <><span>Date</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'date',
            show: props.chiServyList.length !== 0, // Custom value accessors!
        }, {
            Header: <><span>Rating</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'rating',
            show: props.chiServyList.length !== 0,
            // width: 350
        }, {
            Header: <><span>Status</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'status',
            show: props.chiServyList.length !== 0,
            // width: 135
            // show: false,
        }, {
            Header: <><span></span></>,
            accessor: 'actions',
            width: 135,
            filterable: false,
            show: props.chiServyList.length !== 0,
            // width: 350
        }
    ]
    const [tablePage, setTablePage] = useState(0)
    const ChiPageNew =  React.useRef()
    
    if(ChiPage !== ChiPageNew.current) {
        ChiPageNew.current = ChiPage;
    }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.chiPageClicked(page)
        ChiPageNew.current = page;
    }
    const fetchData = (state, instance) => {

        if (SearchOrResetChiClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {

            if (!DateGenerate) {
                state.page = 2;
                const chi_no_of_rows = state.pageSize;
                let page = ChiPageNew.current >= 0 ? ChiPageNew.current + 1 : ChiPage + 1;
                if(No_of_rows !== chi_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                const sorted = state.sorted;
                const filtered = state.filtered;
                if (props.isWeek) {
                props.getAllCHIServyResponse(
                    // SearchValue,
                    chi_no_of_rows, page,
                    GetFormatedDate(props.currentDate),
                    currentData.WEEK,
                    sorted,
                    filtered,);
                }
                if (props.isMonth) {
                    props.getAllCHIServyResponse(
                        // SearchValue,
                        chi_no_of_rows, page,
                        GetFormatedDate(props.currentDate),
                        currentData.MONTH,
                        sorted,
                        filtered,);
                    }
                if (props.isYear) {
                    props.getAllCHIServyResponse(
                        // SearchValue,
                        chi_no_of_rows, page,
                        GetFormatedDate(props.currentDate),
                        currentData.YEAR,
                        sorted,
                        filtered,);
                    }
            }
        }
        // }
        // }
    }

    return (
        SearchOrResetChiClicked && props.chi_surveyLoading ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.chi_surveyLoading}
                defaultPageSize={No_of_rows}
                minRows={0}
                manual
                onFetchData={fetchData}
                gotoPage={ChiPage}
                page = {
                    ChiPage
                }
                pages={No_of_pages}
                // onPageSizeChange={props.onUserPageSizeChange}
                onPageChange={(page) => setTablePageFn(page)}
                showPaginationTop={true}
                showPaginationBottom={false}
                showPagination={props.chiServyList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                LoadingComponent={() =>
                    props.chi_surveyLoading ?
                        <div style={{
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                NoDataComponent={() => CustomNoDataComponent(props.chi_surveyLoading, 'No Tickets Found')}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column, 'status-table', rowInfo.original.status)
                    return {
                        className: rowInfo.original.status === 'open' ? 'react-table-tr-element-open-status  react-table-tr-element-ticket ' : rowInfo.original.status === 'Close' ? 'react-table-tr-element-pending-status  react-table-tr-element-ticket' : 'react-table-tr-element-close-status react-table-tr-element-ticket',
                        // className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default CHIServysTable;
