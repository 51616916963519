import SuccessAlert from '../../../utility/successAlert/successAlert';
import ScanningSuccessAlert from '../../../utility/ScanningAlert/scanningSuccessAlert';
import { routes } from '../../../utility/constants/constants';
import { InventoryActionType } from './InventoryActionType';
import * as API from '../../../API/InventoryApi';
import history from '../../../router/history';
import cloneDeep from 'clone-deep';
import { closeModel, makeFirstLetterCapital } from '../../../utility/utility';
import storage from '../../../utility/storage';
const refreshPage = () => {window.location.reload(false);}

export const inventoryTabClicked = () => {
    return {
        type: InventoryActionType.INVENTORY_TAB_CLICKED,
    };
};

export const getCommonJobsAccountList = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.GET_COMMON_JOBS_ACCOUNT_LIST,
            payload: API.getCommonJobsAccountList(data)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            commonJobsList: response.data.accounts,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const resetInventoryObjects = () => {
    return {
        type: InventoryActionType.RESET_INVENTORY_OBJECTS,
    };
};

export const resetSearchOrResetInventory = () => {
    return {
        type: InventoryActionType.RESET_SEARCH_OR_RESET,
    };
};

export const handleInventoryOpenDeleteModal = (deleteInventoryObject) => {
    return {
        type: InventoryActionType.HANDLE_INVENTORY_OPEN_DELETE_MODAL,
        payload: deleteInventoryObject,
    };
};

export const handleInventoryCloseDeleteModal = () => {
    return {
        type: InventoryActionType.HANDLE_INVENTORY_CLOSE_DELETE_MODAL,
    };
};

export const getAllInventoryItemsList = (params, values) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.GET_ALL_INVENTORY_ITEMS_LIST,
            payload: API.getAllInventoryItemsList(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            itemsList: response.data.products,
                            itemsList_total_pages: response.data.pages,
                            itemsList_no_of_rows: response.data.no_of_rows,
                            total_itemsList_count: response.data.count,
                            itemsList_curPage: params.page - 1,
                            searchValues : values
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const inventoryItemsListPageClicked = (data) => {
    return {
        type: InventoryActionType.INVENTORY_IETMS_LIST_PAGE_CLICKED,
        payload: data,
    };
};

export const createInventoryItem = (body) => { 
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.CREATE_INVENTORY_ITEM,
            payload: API.createInventoryItem(body)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Inventory Item created successfully')
                        history.push(routes.INVENTORY_MAIN_LIST);
                        return response;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const updateInventoryItem = (body) => { 
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.UPDATE_INVENTORY_ITEM,
            payload: API.updateInventoryItem(body)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Inventory Item updated successfully')
                        history.push(routes.INVENTORY_MAIN_LIST);
                        return response;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getSingleInventoryItem = (id) => { 
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.GET_SINGLE_INVENTORY_ITEM,
            payload: API.getSingleInventoryItem(id)
                .then((response) => {
                    if (response.data.success) {
                        return response;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteInventoryItem = (id) => { 
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.DELETE_INVENTORY_ITEM,
            payload: API.deleteInventoryItem(id)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Item deleted successfully');
                        dispatch(getAllInventoryItemsList({page: 1, limit: 10}));
                        return response;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

// export const getSingleInventoryLogItem = (id) => (dispatch) =>
// dispatch({
//     type: InventoryActionType.GET_SINGLE_INVENTORY_LOG,
//     payload: API.getSingleInventoryLogItem(id),
// });

export const getBoxList = (params) => (dispatch) =>
    dispatch({
        type: InventoryActionType.GET_BOX_LIST,
        payload: API.getBoxList(params)
            .then((response) => {
                if (response.data.success) {
                    return {
                        boxCurPage: params.page - 1,
                        boxList : response.data.boxes,
                        box_total_count : response.data.count,
                        box_no_of_rows : response.data.no_of_rows,
                        box_pages : response.data.pages,
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

    export const addBox = (body) => (dispatch) =>
    dispatch({
        type: InventoryActionType.ADD_BOX,
        payload: API.addBox(body)
            .then((response) => {
                if (response.data.success) {
                    SuccessAlert('Box created successfully')
                    history.push(routes.BOX)
                    return response;
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

    export const updateBox = (body, id) => (dispatch) =>
    dispatch({
        type: InventoryActionType.UPDATE_BOX,
        payload: API.updateBox(body, id)
            .then((response) => {
                if (response.data.success) {
                    SuccessAlert('Box Updated successfully')
                    history.push(routes.BOX)
                    return response;
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

    export const getSingleBox = (id) => (dispatch) =>
    dispatch({
        type: InventoryActionType.GET_SINGLE_BOX,
        payload: API.getSingleBox(id),
    });

    export const addNewBoxClicked = () => (dispatch) =>
    dispatch({
        type: InventoryActionType.NEW_BOX_CLICKED,
    });

    export const deleteBox = (id) => (dispatch) =>
    dispatch({
        type: InventoryActionType.DELETE_BOX,
        payload: API.deleteBox(id).then((res) => {
            if (res.data.success) {
                SuccessAlert('Box deleted successfully');
                return { id };
            }
        }),
    });
    
    export const boxPageClicked = (user) => {
        return {
            type: InventoryActionType.BOX_PAGE_CLICKED,
            payload: user
        }
    }

    export const getAllAssests = (params, values) => (dispatch) =>
    dispatch({
        type: InventoryActionType.GET_ALL_ASSESTS,
        payload: API.getAllAssests(params)
            .then((response) => {
                if (response.data.success) {
                    return {
                        assestsCurPage: params.page - 1,
                        assestsList : response.data.products,
                        assests_total_count : response.data.count,
                        assests_no_of_rows : response.data.no_of_rows,
                        assests_pages : response.data.pages,
                        searchValues : values
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

    export const createAsset = (body) => { 
        debugger;
        return (dispatch, getState) => {
            dispatch({
                type: InventoryActionType.CREATE_ASSET,
                payload: API.createAsset(body)
                    .then((response) => {
                        debugger;
                        if (response.data.success) {
                            SuccessAlert('Asset created successfully')
                            history.push(routes.ASSETS_LIST);
                            return response;
                        };
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        };
    };
    
    export const updateAsset = (body,id) => { 
        
        return (dispatch, getState) => {
            dispatch({
                type: InventoryActionType.UPDATE_ASSET,
                payload: API.updateAsset(body,id)
                    .then((response) => {
                        if (response.data.success) {
                            SuccessAlert('Asset updated successfully')
                            history.push(routes.ASSETS_LIST);
                            return response;
                        };
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        };
    };
    
    export const getSingleAsset = (id) => { 
        return (dispatch, getState) => {
            dispatch({
                type: InventoryActionType.GET_SINGLE_ASSET,
                payload: API.getSingleAsset(id)
                    .then((response) => {
                        if (response.data.success) {
                            return response;
                        };
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        };
    };

    export const getSingleLogAsset = (id, params) => {
        return (dispatch, getState) => {
            dispatch({
                type: InventoryActionType.GET_SINGLE_ASSET_LOG,
                payload: API.getSingleLogAsset(id, params)
                    .then((response) => {
                        if (response.data.success) {
                            return {
                                assetSingleLogCurPage: params.page - 1,
                                assetSingleLogList : response.data.assets,
                                assetSingleLog_total_count: response.data.count,
                                assetSingleLog_no_of_rows: response.data.no_of_rows,
                                assetSingleLog_total_pages: response.data.pages,
                            };
                        };
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        };
    };

    export const deleteAsset = (id) => (dispatch) =>
    dispatch({
        type: InventoryActionType.DELETE_ASSET,
        payload: API.deleteAsset(id).then((res) => {
            if (res.data.success) {
                SuccessAlert('Asset deleted successfully');
                return { id };
            }
        }),
    });
    
    export const getIsolatedOneAssetData = (id) => { 
        return (dispatch, getState) => {
            dispatch({
                type: InventoryActionType.GET_ISOLATED_ONE_ASSET_DATA,
                payload: API.getIsolatedOneAssetData(id)
                    .then((response) => {
                        if (response.data.success == true) {
                            return response;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        };
    };

    export const engageAssetCheckOut = (body) => { 
        return (dispatch, getState) => {
            dispatch({
                type: InventoryActionType.ENGAGE_ASSET_CHECK_OUT,
                payload: API.engageAssetCheckOut(body)
                    .then((response) => {
                        if (response.data.success) {
                            ScanningSuccessAlert(`Asset SubItem has been checked out`);
                            setTimeout(() => {
                                history.push(routes.ASSET_SCAN_CHECK_OUT_QR);
                            }, 500);
                            return response;
                        };
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        };
    };

    export const engageAssetCheckIn = (body, id) => { 
        return (dispatch, getState) => {
            dispatch({
                type: InventoryActionType.ENGAGE_ASSET_CHECK_IN,
                payload: API.engageAssetCheckIn(body, id)
                    .then((response) => {
                        if (response.data.success) {
                            ScanningSuccessAlert(`${makeFirstLetterCapital(body.checkInItemName)} has been checked in`);
                            setTimeout(() => {
                                history.push(routes.ASSET_SCAN_CHECK_IN_QR);
                            }, 500);
                            return response;
                        };
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        };
    };

    export const getAssetActiveStatusToggle = () => {
        return {
            type: InventoryActionType.GET_ASSET_ACTIVE_STATUS_TOGGLE
        }
    }

    export const getAllPickingTickets = (params,values) => (dispatch) => {
    dispatch({
        type: InventoryActionType.GET_ALL_PICKING_TICKET,
        payload: API.getAllPickingTickets(params)
            .then((response) => {
                if (response.data.success) {
                    return {
                        pickingTicket_total_count : response.data.count,
                        pickingTicket_no_of_rows : response.data.no_of_rows,
                        pickingTicket_pages : response.data.pages,
                        pickingTicketList : response.data.tickets,
                        pickingTicketCurPage: params.page - 1,
                        searchValues : params
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });
}

    export const getSinglePickingTicket = (id) => (dispatch) =>
    dispatch({
        type: InventoryActionType.GET_SINGLE_PICKING_TICKET,
        payload: API.getSinglePickingTicket(id),
    });


    export const getEmergencyPickupTickets = (params, values) => (dispatch) =>
    dispatch({
        type: InventoryActionType.GET_EMERGENCY_PICKUP_TICKET,
        payload: API.getAllPickingTickets(params)
            .then((response) => {
                if (response.data.success) {
                    return {
                        pickingTicketCurPage: params.page - 1,
                        pickingTicketList : response.data.tickets,
                        pickingTicket_total_count : response.data.count,
                        pickingTicket_no_of_rows : response.data.no_of_rows,
                        pickingTicket_pages : response.data.pages,
                        searchValues : values
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

    export const approvePickupTicket = (body, id) => (dispatch) =>
    dispatch({
        type: InventoryActionType.APPROVE_PICKUP_TICKET,
        payload: API.approvePickupTicket(body, id)
            .then((response) => {
                if (response.data.success) {
                    if(response.data.ticket.status === 'approved') {
                        SuccessAlert('Ticket Approved successfully')
                    }else{
                        SuccessAlert('Ticket Rejected successfully')
                    }
                    return {id};
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const createInventoryPullRequestTicket = (body) => { 
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.CREATE_INVENTORY_PULL_REQUEST_TICKET,
            payload: API.createInventoryPullRequestTicket(body)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Inventory Ticket created successfully')
                        history.push(routes.PICKING_TICKETS);
                        return response.data;
                    }else{
                        history.push(routes.UNAVAILABLE_ITEMS);
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getInventoryItemNames = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.GET_INVENTORY_ITEM_NAMES,
            payload: API.getInventoryItemNames(data)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            itemsList: response.data.products,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getJobUsersInfo = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.GET_JOB_USERS_INFO,
            payload: API.getJobUsersInfo(id)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            jobUsersList: response.data.users,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}
  
export const getSingleInventoryLogItem = (id) => (dispatch) =>
dispatch({
    type: InventoryActionType.GET_SINGLE_ASSET,
    payload: API.getSingleInventoryLogItem(id),
});

export const pickingTicketPageClicked = (page) => {
    return {
        type: InventoryActionType.PICKING_TICKET_PAGE_CLICKED,
        payload: page
    }
}

export const emergencyTicketPageClicked = (page) => {
    return {
        type: InventoryActionType.EMERGENCY_TICKET_PAGE_CLICKED,
        payload: page
    }
}

export const assetsPageClicked = (page) => {
    return {
        type: InventoryActionType.ASSETS_PAGE_CLICKED,
        payload: page
    }
}

export const getAllPickupsTicketList = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.GET_ALL_PICKUPS_TICKET_LIST,
            payload: API.getAllPickupsTicketList(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            ticket_list : response.data.tickets,
                            total_ticket_count : response.data.count,
                            total_ticket_pages : response.data.pages,
                            no_of_rows : response.data.no_of_rows,
                            current_ticket_Page: params.page - 1,
                            currentStatus: params.status ? params.status : null,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const searchAllPickupsTicketList = (values) => {
    return (dispatch, getState) => {
        const page = 1;
        const limit = getState().InventoryReducer.pickupsTicket_no_of_rows;
        const fulfillment_manager_location = values.fulfillment_manager_location ? values.fulfillment_manager_location : '';
        const approver = values.approver_name ? values.approver_name : '';
        const status = values.status ? values.status : '';
        const id = values.id ? values.id : '';
        const is_emergency = values.is_emergency ? values.is_emergency : '';

        dispatch({
            type: InventoryActionType.SEARCH_ALL_PICKUPS_TICKET_LIST,
            payload: API.getAllPickupsTicketList({ page, limit, fulfillment_manager_location, approver, status, id , is_emergency})
                .then((response) => {
                    if (response.data.success) {
                        return {
                            ticket_list : response.data.tickets,
                            total_ticket_pages : response.data.pages,
                            no_of_rows : response.data.no_of_rows,
                            current_ticket_Page: 0,
                        };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const pickupsTicketListPageClicked = (value) => {
    return {
        type: InventoryActionType.PICKUPS_TICKET_LIST_PAGE_CLICKED,
        payload: value
    }
}

export const getSinglePickupTicket = (id) => { 
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.GET_SINGLE_PICKUP_TICKET,
            payload: API.getSinglePickupTicket(id)
                .then((response) => {
                    if (response.data.success) {
                        return response;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getAllInventoryBoxNamesList = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.GET_ALL_INVENTORY_BOX_NAMES_LIST,
            payload: API.getAllInventoryBoxNamesList(data)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            boxesList: response.data.boxes,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const readyForPickupQRGenerator = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.READY_FOR_PICKUP_QR_GENERATOR,
            payload: API.readyForPickupQRGenerator(data)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Successfully Associated');
                        history.push(routes.PICKING_TICKETS);
                        setTimeout(() => {
                            dispatch(getAllPickingTickets({page: 1, limit: 10, status: 'readyForPickup'}));
                        }, 1000);
                        return response;
                    }else {
                        setTimeout(() => {
                            refreshPage();
                        }, 500);
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const readyForDeliveryQRGenerator = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.READY_FOR_DELIVERY_QR_GENERATOR,
            payload: API.readyForDeliveryQRGenerator(data)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Successfully Items Picked');
                        history.push(routes.PICKUPS_TICKETS);
                        dispatch(getAllPickupsTicketList({page: 1, limit: 10, status: 'picked'}));
                        return response;
                    }else {
                        setTimeout(() => {
                            refreshPage();
                        }, 500);
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getDeliveryItemScanPresignedUrl = (deliveryItem, arrayBuffer, extentions) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.GET_DELIVERY_ITEM_SCAN_PRESIGNED_URL,
            payload: API.getDeliveryItemScanPresignedUrl(deliveryItem, extentions)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                    deliveryItem.ticket.attachments = [];
                    response.data.presigned_urls.forEach((p, i) => {
                        deliveryItem.ticket.attachments[i] = {}
                        deliveryItem.ticket.attachments[i]['photo_path'] = p.attachment_path
                    });
                    response.data.presigned_urls.map((p, i) => {
                        dispatch({
                            type: InventoryActionType.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        dispatch(getInventoryItemDeliveredSuccess(deliveryItem));
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getInventoryItemDeliveredSuccess = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.GET_INVENTORY_ITEM_DELIVERED_SUCCESS,
            payload: API.getInventoryItemDeliveredSuccess(data)
                .then((response) => {
                    if (response.data.success) {
                        let actionalValue = cloneDeep(response.data);
                        history.push(routes.INVENTORY_THANKYOU)
                        SuccessAlert('Successfully Items Delivered');
                        setTimeout(() => {
                            history.push(routes.PICKUPS_TICKETS);
                            if(actionalValue && actionalValue.ticket && actionalValue.ticket.status) {
                                if(actionalValue.ticket.status == 'completed') {
                                    dispatch(getAllPickupsTicketList({page: 1, limit: 10, status: 'completed'}));
                                }
                            } else {
                                dispatch(getAllPickupsTicketList({page: 1, limit: 10, status: 'delivered'}));
                            }
                        }, 3000);
                        return response;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getDeliveryReturnWarehouse = (id, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.GET_DELIVERY_RETURN_WAREHOUSE,
            payload: API.getDeliveryReturnWarehouse(id, data)
                .then((response) => {
                    if (response.data.success) {
                        history.push(routes.INVENTORY_THANKYOU)
                        SuccessAlert('Warehouse delivery return has been done');
                        setTimeout(() => {
                            history.push(routes.PICKUPS_TICKETS);
                            setTimeout(() => {
                                dispatch(getAllPickupsTicketList({page: 1, limit: 10, status: 'returned'}));
                            }, 3500);
                        }, 3000);
                        return response;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getAllInventoryLocationList = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.GET_ALL_INVENTORY_LOCATION_LIST,
            payload: API.getAllInventoryLocationList(data)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            locationsList: response.data.locations,
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
}

export const getLocation = () => (dispatch) =>
    dispatch({
        type: InventoryActionType.GET_LOCATION,
        payload: API.getLocation() 
        .then((res) => {
            if (res.data.success) {
                return {
                    locations : res.data.locations, 
                    location_count : res.data.count
                };
            }
        })
        .catch((error) => {
            console.log(error);
        }),
    });

    export const getLocationAssociation = (params) => (dispatch) =>
    dispatch({
        type: InventoryActionType.GET_LOCATION_ASSOCIATION,
        payload: API.getLocationAssociation(params)
        .then((res) => {
            if (res.data.success) {
                return {
                    locations : res.data.locations, 
                    count : res.data.count
                };
            }
        })
        .catch((error) => {
            console.log(error);
        }),
    });

    export const addLocation = (body) => {
        return (dispatch, getState) => {
            dispatch({
                type: InventoryActionType.ADD_LOCATION,
                payload: API.addLocation(body)
                    .then((response) => {
                        let locations = cloneDeep(getState().InventoryReducer.locations);
                        let location_count = cloneDeep(getState().InventoryReducer.location_count);
                        let newLocation = {};
                        if (response.data.success) {
                            SuccessAlert('Location added successfully');
                            closeModel();
                            newLocation = response.data.location;
                            locations.unshift(newLocation);
                            locations.sort(function (a, b) {
                                if (a.name < b.name) {
                                    return -1;
                                }
                                if (a.name > b.name) {
                                    return 1;
                                }
                                return 0;
                            });
                            return { location_count:  parseInt(location_count) + 1, locations: locations };
                        } else {
                            closeModel();
                            let locations = cloneDeep(getState().InventoryReducer.locations);
                            let location_count = cloneDeep(getState().InventoryReducer.location_count);
                            return { locations: locations, location_count: location_count };
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        };
    };

    export const getLocationName = (params) => (dispatch) =>
    dispatch({
        type: InventoryActionType.GET_LOCATION_NAME,
        payload: API.getLocationName(params)
        .then((res) => {
            if (res.data.success) {
                return {
                    locations : res.data.locations,
                };
            }
        })
        .catch((error) => {
            console.log(error);
        }),
    });

    export const getFulfillmentManager = (params) => (dispatch) =>
    dispatch({
        type: InventoryActionType.GET_FULFILLMENT_MANAGER,
        payload: API.getFulfillmentManager(params)
        .then((res) => {
            if (res.data.success) {
                return {
                    users : res.data.users,
                };
            }
        })
        .catch((error) => {
            console.log(error);
        }),
    });

    export const addLocationAssociation = (body, id) => {
        return (dispatch, getState) => {
            dispatch({
                type: InventoryActionType.ADD_LOCATION_ASSOCIATION,
                payload: API.addLocationAssociation(body, id)
                    .then((response) => {
                        let locationAssociation = cloneDeep(getState().InventoryReducer.locationAssociation);
                        let location_association_count = cloneDeep(getState().InventoryReducer.location_association_count);
                        let newLocation = {};
                        if (response.data.success) {
                            SuccessAlert('Location Associated successfully');
                            closeModel();
                            newLocation = response.data.location;
                            locationAssociation.unshift(newLocation);
                            locationAssociation.sort(function (a, b) {
                                if (a.name < b.name) {
                                    return -1;
                                }
                                if (a.name > b.name) {
                                    return 1;
                                }
                                return 0;
                            });
                            return { location_association_count:  parseInt(location_association_count) + 1, locationAssociation: locationAssociation };
                        } else {
                            closeModel();
                            let locationAssociation = cloneDeep(getState().InventoryReducer.locationAssociation);
                            let location_association_count = cloneDeep(getState().InventoryReducer.location_association_count);
                            return { locationAssociation: locationAssociation, location_association_count: location_association_count };
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        };
    };

    export const editLocationClicked = (location) => {
        return {
            type: InventoryActionType.EDIT_LOCATION_CLICKED,
            payload: location,
        };
    };

    export const editLocationAssociationClicked = (location) => {
        return {
            type: InventoryActionType.EDIT_LOCATION_ASSOCIATION_CLICKED,
            payload: location,
        };
    };

    export const openPopupInventory = () => {
        return {
            type: InventoryActionType.OPEN_MODEL_INVENTORY,
        };
    };
    
    export const closePopupInventory = () => {
        return {
            type: InventoryActionType.CLOSE_MODEL_INVENTORY,
        };
    };

    export const updateLocation = (body, id) => (dispatch) =>
    dispatch({
        type: InventoryActionType.UPDATE_LOCATION,
        payload: API.updateLocation(body, id).then((res) => {
            if (res.data.success) {
                closeModel();
                SuccessAlert('Location updated successfully');
                return res;
            }
        }),
    });

    export const updateLocationAssociation = (body, id) => (dispatch) =>
    dispatch({
        type: InventoryActionType.UPDATE_LOCATION_ASSOCIATION,
        payload: API.addLocationAssociation(body, id).then((res) => {
            if (res.data.success) {
                closeModel();
                SuccessAlert('Location Association updated successfully');
                return res;
            }
        }),
    });

    export const deleteLocation = (id) => (dispatch) =>
    dispatch({
        type: InventoryActionType.DELETE_LOCATION,
        payload: API.deleteLocation(id).then((res) => {
            if (res.data.success) {
                SuccessAlert('Location deleted successfully');
                return { id };
            }
        }),
    });

    export const newLocationClicked = () => (dispatch) =>
    dispatch({
        type: InventoryActionType.NEW_LOCATION_CLICKED,
    });

    export const newLocationAssociationClicked = () => (dispatch) =>
    dispatch({
        type: InventoryActionType.NEW_LOCATION_ASSOCIATION_CLICKED,
    });

    export const assetStockClicked = (asset) => {
        history.push({pathname: `/assets/asset-stock/${asset.id}`});
        storage.set('assetTitle' , asset.name)
        storage.set('assetId' , asset.id)
        return {
            type: InventoryActionType.ASSET_STOCK_CLICKED,
            payload:asset
        };
    };

    export const getAllAssestStock = (params, values, id) => (dispatch) => {
    dispatch({
        type: InventoryActionType.GET_ALL_ASSEST_STOCK,
        payload: API.getAllAssestStock(params, id)
            .then((response) => {
                if (response.data.success) {
                    return {
                        assestsCurPage: params.page - 1,
                        assestsList : response.data.subAssets,
                        assests_total_count : response.data.count,
                        assests_no_of_rows : response.data.no_of_rows,
                        assests_pages : response.data.pages,
                        searchValues : values
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    })
}

export const updateAssetStock = (body, id) => { debugger
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.UPDATE_ASSET_STOCK,
            payload: API.updateAssetStock(body, id)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Asset Stock updated successfully')
                        history.goBack()
                        return response;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getSingleAssetStock = (id) => { 
    return (dispatch, getState) => {
        dispatch({
            type: InventoryActionType.GET_SINGLE_ASSET_STOCK,
            payload: API.getSingleAssetStock(id)
                .then((response) => {
                    if (response.data.success) {
                        return response;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteAssetStock = (id) => (dispatch) =>
dispatch({
    type: InventoryActionType.DELETE_ASSET_STOCK,
    payload: API.deleteAssetStock(id).then((res) => {
        if (res.data.success) {
            SuccessAlert('Asset deleted successfully');
            return { id };
        }
    }),
});

export const assetStockPageClicked = (page) => {
    return {
        type: InventoryActionType.ASSET_STOCK_PAGE_CLICKED,
        payload: page
    }
}

