import React, { useEffect, useState } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import { priorPreviewTicketValidator as validate } from '../../../../utility/validator/validator';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, change, getFormValues } from 'redux-form';
import ImageLoader from 'react-imageloader';
import ModalImageGallery from '../../ModalImageGallery/modalImageGallery';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { TicketStatus, userRoles, TimeZones } from '../../../../utility/constants/constants';
import { convertDate, convertAreaScheduleTime, convertDateTimezone, makeFirstLetterCapital } from '../../../../utility/utility'
import errorHandler from '../../../../utility/errorHandler/errorHandler';
import history from '../../../../router/history';
import '../../../../styles/custom.css';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';

var moment = require('moment');

function preloader() {
    return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
}

const loadCommentImages = (props, attachments_attributes, cindex, commentPhotoUrlClicked, isCommentImageGallery, setIsCommentImageGallery, startIndex, commentId, photoCommentId, editCommentObject) => {

    if (props.ticket.comments_attributes.length - 1 === cindex && props.isLoading && isEmpty(attachments_attributes)) {
        return props.portfolioImages.map((element, i) => (
            <div className="attachments__file_box" key={i}>
                <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />
            </div>
        ));

    } else {
        if (!isEmpty(attachments_attributes)) {

            if (isCommentImageGallery && photoCommentId === commentId) {
                return <ModalImageGallery
                    isImageGallery={isCommentImageGallery}
                    setIsImageGallery={setIsCommentImageGallery}
                    startIndex={startIndex}
                    // base64={props.portfolioImages}
                    imageUrls={attachments_attributes}
                />
            } else {
                return (
                    attachments_attributes.map((url, i) => (
                        <>
                            <div className="attachments__file_box">
                                <div key={i} onClick={() => commentPhotoUrlClicked(i, commentId)}>
                                    <ImageLoader

                                        style={{ width: '100%', height: '82px', cursor: 'pointer' }}
                                        src={url.photo_urls.small}
                                        wrapper={React.createFactory('div')}
                                        preloader={preloader}>
                                        Image load failed!
            </ImageLoader>
                                </div>
                                {editCommentObject ?
                                    <div class="cancel_icon">
                                        <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeUrls(url, cindex)} alt="Image" />
                                    </div> : null
                                }
                            </div >
                        </>
                    )
                    )
                )
            }
        }
    }

}

let getLastUpdatedDaysCount = (updatedAt) => {
    let CurrentDate = moment(new Date()).startOf('day');
    let UpdatedDate = moment(updatedAt).startOf('day');
    let DifferenceRange = CurrentDate.diff(UpdatedDate, 'days');
    if (DifferenceRange === 0) {
        return moment(updatedAt).format('hh:m A');
    } else if (DifferenceRange === 1) {
        return DifferenceRange + ' day ago'
    } else {
        return DifferenceRange + ' days ago'
    }
}

let PriorEscPreviewTicket = (props) => {
    const [editCommentObject, setEditCommentObject] = useState(null);
    const [isImageGallery, setIsImageGallery] = useState(false);
    const [isCommentImageGallery, setIsCommentImageGallery] = useState(false);
    const [commentId, setCommentId] = useState(null);
    const [startIndex, setStartIndex] = useState(0);
    const [currentTicket, setCurrentTicket] = useState(0);
    const [photoUrls, setPhotoUrls] = useState([]);
    const [base64, setBase64] = useState([]);
    const [showAttachment, setShowAttachment] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.prior_ticketIndex) {
            props.prior_ticketIndex.map((ins, i) => {
                if(props.match.params.ticketId === ins) {
                    setCurrentTicket(i + 1)
                }
            })
        }
    }, [props])

    useEffect(() => {
        if(props.formStates && props.formStates.ticket) {
            if((props.formStates.ticket.comments_attributes.length !== props.ticket.comments_attributes.length) && props.formStates.ticket.comments_attributes[props.formStates.ticket.comments_attributes.length -1].description !== undefined) {
                setShowAttachment(true);
            } else {
                setShowAttachment(false);
            }
        }
    }, [props.formStates])


    const editCommentClicked = (comment, index) => {
        setEditCommentObject(comment);
        dispatch(change('previewTicketForm', `ticket[comments_attributes][${index}][description]`, comment.description))
    }

    if (isEmpty(props.ticket)) {
        return (<></>)
    } else {

        const { handleSubmit, pristine, reset, submitting, error } = props;

        const photoUrlClicked = (urls, i, base64) => {
            setIsImageGallery(true);
            setStartIndex(i);
            setPhotoUrls(urls);
            setBase64(base64);
        }

        const commentPhotoUrlClicked = (i, commentId) => {
            setIsCommentImageGallery(true);
            setCommentId(commentId);
            setStartIndex(i);
        }

        if (isImageGallery) {
            return <ModalImageGallery
                isImageGallery={isImageGallery}
                setIsImageGallery={setIsImageGallery}
                startIndex={startIndex}
                base64={base64}
                imageUrls={photoUrls}
            />
        }
        else {

            const cancelEditClicked = () => {
                props.cancelEditClicked();
                setEditCommentObject(null);
            }

            if (props.user.role === 'client' && props.ticket.private) {
                errorHandler('Invalid Request')
                history.push(`/tickets`);
            }
            
            return (    
                (props.user.role === 'client' && props.ticket.private) ? 
                null : 
                <>
                <main className="app-content wraper_content ticket_user">
                    <div className="appcontent_Inner">
                        <div className="tickets_user_profile_body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 col-xl-9 order-2 order-xl-1">
                                        <div className="tickets_user_activity">
                                            <div className="tickets_top_header">
                                                <div className='d-flex viewIns_btn align-items-start justify-content-between'>
                                                <div className="preview_comment">
                                                   <h4>{props.ticket.subject}</h4>
                                                </div>
                                                {props.prior_ticketIndex && props.prior_ticketIndex.length > 0 ? <div className='pn-flex'>
                                                <button className="btn cst_btn custom_hover" disabled={currentTicket === 1} onClick={() => props.previousClicked()}>Previous</button>
                                                <h4 style={{margin: '5px'}}>{currentTicket}/{props.prior_ticketIndex ? props.prior_ticketIndex.length : 0}</h4>
                                                <button className="btn cst_btn custom_hover" disabled={currentTicket === props.prior_ticketIndex.length } onClick={() => props.nextClicked()}>Next</button>
                                                </div> : null}
                                                </div>
                                                <div className="header_icn_block">
                                                    {/* <a href="#" className="mr-3"><img src="/images/icons/icn_file_export.png" alt="Export" /></a>
                                                <a href="#"><img src="/images/icons/icn_edit_md.png" alt="Edit" /></a> */}
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="user_activity_conent">
                                                <div className="misc_section">
                                                    <h6 style={{lineBreak: 'anywhere'}}>{props.ticket.message}</h6>
                                                </div>
                                                   <h4>User Selected Deficiencies</h4>
                                                   <div className="d-flex flex-wrap mb-5">
                                    { props.ticket.pegassure_feedback?.deficiencies?.length > 0 ? props.ticket?.pegassure_feedback?.deficiencies.map((s, i)=> {
                                            return (
                                                <div key={i}>
                                                    <button type="button" className='btn user_selected_deficiencies_btn' >{makeFirstLetterCapital(s.title)}</button>
                                                </div>
                                            )
                                    }) : <div className="misc_section"> <h6 style={{lineBreak: 'anywhere'}}>No Deficiencies</h6></div>}
                                </div>
                                                <div className="clearfix"></div>
                                                <div className="attachment_sec">
                                                    <div className="attachment_title">
                                                        <img className="attachment_icn" src="/images/icons/icn_attch.png" alt="" />
                                                        <h5 className="tickets_user_title1x">Attachments<span className="attachment_size"></span></h5>
                                                    </div>
                                                    <div className="attachments_files">
                                                        {props.ticket.pegassure_feedback && props.ticket.pegassure_feedback.attachments && props.ticket.pegassure_feedback.attachments.map((t, i) => {

                                                            return (
                                                                <div className="attachments__file_box" onClick={() => photoUrlClicked(props.ticket.pegassure_feedback.attachments, i)}>
                                                                    <ImageLoader
                                                                        style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                                                        src={t.photo_urls.small}
                                                                        wrapper={React.createFactory('div')}
                                                                        preloader={preloader}>
                                                                        Image load failed!
                                                        </ImageLoader>
                                                                    {/* <img src={t.photo_urls.small} alt="Image" /> */}
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="tickets_users_comments">
                                                    {props.ticket.status === TicketStatus.resolved && props.ticket.comments_attributes.length > 0 &&
                                                        <h4 className="tickets_user_title2x">{props.ticket.comments_attributes.length === 1 ? `${props.ticket.comments_attributes.length} Comment`
                                                        : props.ticket.comments_attributes.length > 1 ? `${props.ticket.comments_attributes.length} Comments` :
                                                            `Comments`}</h4>
                                                    }
                                                    <div className={props.ticket.status === TicketStatus.resolved && props.ticket.comments_attributes.length > 0 ? "sent_comments border_bottom" : "sent_comments"}>

                                                        {props.ticket.comments_attributes && props.ticket.comments_attributes.map((c, cindex) => {

                                                            if (editCommentObject && editCommentObject.id === c.id) {

                                                                return (<form onSubmit={handleSubmit} className="tickets_view_form">
                                                                    <div className="user_comment_area">

                                                                        <div className="form-group">
                                                                            <Input dataTest="preview_comment_box" type="textarea" name={`ticket[comments_attributes][${cindex}][description]`} className="form-control" rows="2" id="comment" />
                                                                        </div>

                                                                        <div className="comment_file_attachment">

                                                                            <div className="upload_file_block text-righ comment_file_attachmentt" >
                                                                                <div id="file-upload-wrapper" onClick={props.choosePhotoClicked} className="file-upload-wrapper" data-text="Up to 10 attachments (10MB each).">
                                                                                    <Input type="file" dataTest="attached_file" name="attachment_paths" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                                                                    {/* <input name="file-upload-field" type="file" className="file-upload-field" id="file-upload-field" value="" /> */}
                                                                                </div>

                                                                                <div className="attachment_sec">
                                                                                    <div className="attachments_files">

                                                                                        {props.portfolioImages && props.portfolioImages.map((url, i) => (

                                                                                            <div className="attachments__file_box" key={i}>
                                                                                                <img className="attachment_icn" src={url} alt="" />
                                                                                                <div className="cancel_icon">
                                                                                                    <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                        }
                                                                                        {loadCommentImages(props, c.attachments_attributes, cindex, commentPhotoUrlClicked, isCommentImageGallery, setIsCommentImageGallery, startIndex, c.id, commentId, true)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="comment_buttons" style={{ marginBottom: '10px' }}>
                                                                        <button data-test="update_comment" onClick={handleSubmit(values =>
                                                                            props.addTicketComment({ ...values }, setEditCommentObject, editCommentObject.id))}
                                                                            disabled={props.isCommentLoading || props.isCommentAndResolveLoading || ((!props.portfolioImages || props.portfolioImages.length === 0) ? pristine : false)} className={props.isCommentLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isCommentLoading ? "" : "UPDATE COMMENT"}</button>
                                                                        <button data-test="submit_button" onClick={handleSubmit(values =>
                                                                            props.addTicketCommentAndResolve({ ...values }, setEditCommentObject, editCommentObject.id))}
                                                                            type="submit" disabled={props.isCommentAndResolveLoading || props.isCommentLoading || ((!props.portfolioImages || props.portfolioImages.length === 0) ? pristine : false)} className={props.isCommentAndResolveLoading ? "btn cst_btn btn_success btn-wait mr-2" : "btn cst_btn btn_success mr-2"} >{props.isCommentAndResolveLoading ? "" : "COMMENT & RESOLVE"}</button>
                                                                        <button data-test="cancel_btn" onClick={() => cancelEditClicked()}
                                                                            type="button" className={"btn cst_btn btn-outline-secondary"} >{"CANCEL"}</button>
                                                                    </div>
                                                                </form>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div className="media">
                                                                        <div className="user_profile_icn mr-3">
                                                                            <img src={c.user && c.user.photo_urls && c.user.photo_urls.small ? c.user.photo_urls.small : '/customImages/user.png'} alt="Generic placeholder image" />
                                                                        </div>
                                                                        <div className="media-body user_comment_details">
                                                                            <h5 className="mt-0 sm-title">{c.user ? c.user.first_name : ''} <span className="comment_date">{getLastUpdatedDaysCount(c.updated_at)}</span></h5>

                                                                            {props.user.role !== userRoles.CLIENT && props.ticket.status !== 'resolved' && c.user && c.user.id === props.user.id ?
                                                                                <a data-test="edit_description" href="javascript:void(0)" className="edit_comment" onClick={() => editCommentClicked(c, cindex)}>Edit</a>
                                                                                : null}
                                                                            <p data-test="description" style={{ wordWrap: "break-word" }}>{c.description}</p>
                                                                            <div className="attachment_sec">
                                                                                <div className="attachments_files">

                                                                                    {loadCommentImages(props, c.attachments_attributes, cindex, commentPhotoUrlClicked, isCommentImageGallery, setIsCommentImageGallery, startIndex, c.id, commentId)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                    {props.user.role === userRoles.CLIENT || props.ticket.status === TicketStatus.resolved || (editCommentObject && !isEmpty(editCommentObject)) ? null :
                                                        <div className="sent_comments">
                                                            <div className="media">

                                                                <form onSubmit={handleSubmit} className="tickets_view_form">
                                                                    <div className="tickets_user_bottomSec">
                                                                        {props.ticket.status === TicketStatus.resolved && 
                                                                        <div className="user_comment_area">

                                                                            <div className="form-group">
                                                                                <Input dataTest="preview_comment_box" type="textarea"
                                                                                    name={`ticket[comments_attributes][${props.ticket.comments_attributes.length}][description]`} className="form-control" rows="2" id="comment" />
                                                                            </div>

                                                                            <div className="comment_file_attachment">

                                                                                {showAttachment ?  <div className="upload_file_block text-righ comment_file_attachmentt" >

                                                                                    <div id="file-upload-wrapper" onClick={props.choosePhotoClicked} className="file-upload-wrapper" data-text="Up to 10 attachments (10MB each).">
                                                                                        <Input type="file" dataTest="attached_file" name="attachment_paths" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                                                                    </div>
                                                                                    <div className="attachment_sec">
                                                                                        <div className="attachments_files">

                                                                                            {props.portfolioImages && props.portfolioImages.map((url, i) => (

                                                                                                <div className="attachments__file_box" key={i}>
                                                                                                    <img className="attachment_icn" src={url} alt="" />
                                                                                                    <div className="cancel_icon">
                                                                                                        <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div> : ''}

                                                                            </div>
                                                                        </div>
                                                                        }
                                                                        <div className="people_notify_others">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12 col-lg-8">
                                                                                            {/* <div className="notify_others_div">
                                                                                    <p>Your comment will be emailed to:
                                                                                    <span><a href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title="Natalia
                                                                                        Natalia
                                                                                        Natalia
                                                                                        Natalia
                                                                                        Natalia">5 people
                                                                                    </a>
                                                                                    <sub>|</sub><a href="javascript:void(0)" className="notify_others">Notify others</a></span>
                                                                               </p>

                                                                                    </div> */}
                                                                                        </div>

                                                                                        {/* <div className="col-sm-12 col-lg-4">
                                                                                        <div className="use_hashtag">
                                                                                            <p><i className="fas fa-lightbulb"></i> ProTip! Use <a href="#">hashtags</a> to change status.</p>
                                                                                        </div>
                                                                                    </div> */}
                                                                                    </div>

                                                                                    <div className="comment_buttons">
                                                                                        {/* <button data-test="add_comment_btn" onClick={handleSubmit(values =>
                                                                                            props.addTicketComment({ ...values }))}
                                                                                            disabled={props.isCommentLoading || props.isCommentAndResolveLoading || pristine} className={props.isCommentLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isCommentLoading ? "" : "ADD COMMENT"}</button>
                                                                                        <button data-test="submit_button" onClick={handleSubmit(values =>
                                                                                            props.addTicketCommentAndResolve({ ...values }))}
                                                                                            type="submit" disabled={props.isCommentAndResolveLoading || props.isCommentLoading || pristine} className={props.isCommentAndResolveLoading ? "btn cst_btn btn_success btn-wait" : "btn cst_btn btn_success"} >{props.isCommentAndResolveLoading ? "" : "COMMENT & RESOLVE"}</button> */}
                                                                                        {props.ticket.status === TicketStatus.open ? (
                                                                                            <button data-test="add_comment_btn" onClick={handleSubmit(values =>{
                                                                                                props.addTicketComment({ ...values })
                                                                                            })}
                                                                                              className={props.isCommentLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isCommentLoading ? "" : "ACCEPT"}</button>
                                                                                        ) : (
                                                                                            <button data-test="add_comment_btn" onClick={handleSubmit(() => setIsOpen(true))} 
                                                                                            className={"btn cst_btn btn_success"} >RESOLVE</button>
                                                                                        )}    
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </form>

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-xl-3 order-1 order-xl-2 border_user_profile">
                                        <div className="user_profile_details">
                                            <div className="user_profile_details_sec">
                                                {/* <div className="user_profile_picture">
                                                    <img src={props.ticket.user.photo_urls.small ? props.ticket.user.photo_urls.small : "/customImages/user.png"} alt="" />
                                                </div> */}
                                                {/* <div className="user_profile_title">
                                                    <h4>{props.ticket.user.first_name + ' ' + props.ticket.user.last_name}</h4>
                                                    <h6>{props.ticket.account.name}</h6>
                                                </div> */}
                                                <div className="clearfix"></div>
                                                <div className="user_status mob_view">
                                                    {props.ticket.status === TicketStatus.resolved ?
                                                        <a href="javascript:void(0)" className="btn btn_resolved cst_btn">Resolved</a>
                                                        : props.ticket.status === TicketStatus.pending ?
                                                            <a href="javascript:void(0)" className="btn btn_open cst_btn">Pending</a>
                                                            : props.ticket.status === TicketStatus.accepted ? <a href="javascript:void(0)" className="btn btn_open cst_btn">Accepted</a>
                                                            : <a href="javascript:void(0)" className="btn btn_open cst_btn">Open</a>
                                                    }

                                                </div>
                                                <div className="user_status sm_d_none">
                                                    {props.ticket.status === TicketStatus.resolved ?
                                                        <a href="javascript:void(0)" className="btn btn_resolved cst_btn">Resolved</a>
                                                        : props.ticket.status === TicketStatus.pending ?
                                                            <a href="javascript:void(0)" className="btn btn_open cst_btn">Pending</a>
                                                            : props.ticket.status === TicketStatus.accepted ? <a href="javascript:void(0)" className="btn btn_open cst_btn">Accepted</a>
                                                            : <a href="javascript:void(0)" className="btn btn_open cst_btn">Open</a>
                                                    }
                                                    
                                                    {/*  */}

                                                </div>
                                                
                                                    {/* <div className="d-inline-block w-100 text-center m-auto pl-4">
                                                         <h6 className="d-inline-block duo_date_title">Due Date:
                                                           <span className="d-inline-block duo_date_text">{convertDateTimezone(props.ticket.due_on, props.ticket.account.timezone)}</span>
                                                        </h6> 
                                                    </div> */}
                                                 
                                                <div className="priority_id">
                                                    <div className="row">
                                                        <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-6">
                                                            <div className="priority_box">
                                                                <h6><b>Priority</b></h6>
                                                                <span>{props.ticket.priority}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-sm-8  col-md-4 col-lg-4 col-xl-6">
                                                            <div className="user_id_box">
                                                                <h6><b>ID</b></h6>
                                                                <span>{props.ticket.id}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="assign_location sm_d_none">
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                                <h6><b>Job</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="user_location_box">
                                                                <h6>{props.ticket.account.name ? props.ticket.account.name : ''}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                                <h6 ><b>Assigned to</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="user_location_box">
                                                                <h6 className="d-flex">{props.ticket.user.first_name} {props.ticket.user.last_name}</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="user_assigned">
                                                        <div className="date_custom_dropdown assign_modal" role="menu">
                                                            <div className="modal_header">
                                                                <h6><b>Assign responsibility</b></h6>
                                                                <a href="#" className="assign_modal_close"><img src="images/icons/icn_cancel.png" alt="" /></a>
                                                            </div>
                                                            <div className="modal_select">
                                                                <div className="form-group">
                                                                    <select id="first-disabled" className="selectpicker custom_select" data-hide-disabled="true" data-live-search="true" title="No One">
                                                                        <option>Aaron Train</option>
                                                                        <option>Aminta Cuellar</option>
                                                                        <option>Aaron McDonals</option>
                                                                        <option>Abraham c3uves</option>
                                                                        <option>Adam Filmore</option>
                                                                        <option>Andrea DeVincenzo</option>
                                                                    </select>
                                                                </div>
                                                                <div className="assign_modal_btn">
                                                                    <button className="assign_btn">Assign</button>
                                                                    <button className="close_btn">Close</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="assign_location mob_view">
                                                <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                                <h6>Job</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                            <h6>{props.ticket.account.name ? props.ticket.account.name : ''}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="user_location_box">
                                                            <h6 >Assigned to</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="user_location_box">
                                                            <h6 className="d-flex">{props.ticket.user.first_name} {props.ticket.user.last_name}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="created_lastActivity">
                                                    {/* <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                                <h6>Category</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.ticket.category.name}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                {/* <div className="created_name">
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                                <h6>Created By</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6 className="d-inline-block">
                                                                    <label className="created_date d-inline-block">{props.ticket.requestor_user?.first_name}</label>
                                                                    <label className="created_date d-inline-block">{props.ticket.requestor_user?.last_name}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>  */}

                                                    {
                                                        props.ticket.inspection_id ? 
                                                        <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                                <h6><b>Inspection Id</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.ticket.inspection_id}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    null
                                                    }
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                                <h6><b>Created On</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{convertDate(props.ticket.created_at.replace(/-/gi, '/'), props.ticket.account.timezone)}</label>
                                                                    <label className="created_time">{convertAreaScheduleTime(props.ticket.created_at.replace(/-/gi, '/'), props.ticket.account.timezone)}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                                <h6><b>Last Activity</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{convertDate(props.ticket.updated_at.replace(/-/gi, '/'), props.ticket.account.timezone)}</label>
                                                                    <label className="created_time">{convertAreaScheduleTime(props.ticket.updated_at.replace(/-/gi, '/'), props.ticket.account.timezone)}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {props.ticket && props.ticket.accepted_by ? (
                                                        <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                                <h6><b>Accepted by</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.ticket?.accepted_by.first_name} {props.ticket?.accepted_by.last_name}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ) : null}
                                                    {props.ticket && props.ticket.status === 'resolved' ? (
                                                        <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                                <h6><b>Resolved by</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.ticket?.resolved_user.first_name} {props.ticket?.resolved_user.last_name}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                <div className='resolveModal'>
                <Dialog open={isOpen} className='resolveModal' aria-labelledby="form-dialog-title">
                    <main className="rm_wrapper">
                        <div className='rm_header'>
                            <img className='rm_profile' src={props.user.photo_urls ? props.user.photo_urls.small ? props.user.photo_urls.small : '/customImages/user.png' : '/customImages/user.png'} alt="Generic placeholder image" />
                            <h4 className="tickets_user_title2x"><b>Ticket Resolve</b></h4>&nbsp;<sup className="cst_sup_txt text_danger" style={{top: '-0.5em'}}>*</sup>
                        </div>
                        <div className="rm_close" onClick={handleSubmit(() => setIsOpen(false))}> x </div>
                        <div className="rm_border_bottom" />
                                <div className="rm_bgm">
                                        <form onSubmit={handleSubmit}>
                                            <div className="rm_contain_box">
                                            <h4 className="tickets_user_title2x" style={{color:'#6d002d'}}><b>Comments</b>&nbsp;<sup className="cst_sup_txt text_danger" style={{top: '-0.5em'}}>*</sup></h4>
                                                <div className="form-group">
                                                    <Input dataTest="preview_comment_box" type="textarea" name={`ticket[comments_attributes][${props.ticket.comments_attributes.length}][description]`} className="form-control" rows="2" id="comment" />
                                                </div>

                                                <div className="rm_showAttachment">
                                                    {/* {showAttachment ?   */}
                                                        <div>
                                                        <div id="file-upload-wrapper" onClick={props.choosePhotoClicked} className="file-upload-wrapper" data-text="* Attachments">
                                                            <Input type="file" dataTest="attached_file" name="attachment_paths" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                                        </div>
                                                        <div className='rm_imgAllign'>
                                                        {props.portfolioImages && props.portfolioImages.map((url, i) => (
                                                            <div className="rm_IMGiconWrap" key={i}>
                                                            <img className="rm_attachmentIMG" src={url} alt="" onClick={() => photoUrlClicked([], i, props.portfolioImages)}/>
                                                                <div className="rm_cancel_icon"><img style={{width: '9px', height: '9px', marginTop: '-12px', marginRight: '2.1px'}} src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" /></div>
                                                            </div>
                                                        ))}
                                                        </div>
                                                    </div> 
                                                    {/* : ''} */}
                                                </div>
                                            </div>
                                        </form>
                                </div>
                                <div className="rm_resolveButton">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="comment_buttons">
                                                <button data-test="submit_button" onClick={handleSubmit(values =>{
                                                props.addTicketCommentAndResolve({ ...values })})}
                                                disabled={props.user.role === userRoles.FRONTLINE ? props.isCommentLoading || props.isCommentAndResolveLoading || props.portfolioImages.length == 0 : null}
                                                type="submit" className={props.isCommentAndResolveLoading ? "btn cst_btn btn_success btn-wait" : "btn cst_btn btn_success"} >{props.isCommentAndResolveLoading ? "" : "RESOLVE"}</button>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </main>
                </Dialog>
                </div>
            </>

            )
        }
    }
}

const mapStateToProps = (state) => {
    let ticket = {};
    if (!isEmpty(state.adminOrSuperAdminReducer.ticket)) {
        ticket = state.adminOrSuperAdminReducer.ticket;
        return { initialValues: { ticket },
        formStates: getFormValues('previewTicketForm')(state)
    }
}

}

PriorEscPreviewTicket = reduxForm({
    form: 'previewTicketForm',
    validate,
    enableReinitialize: true
})(PriorEscPreviewTicket);

PriorEscPreviewTicket = connect(mapStateToProps)(PriorEscPreviewTicket)

export default PriorEscPreviewTicket;