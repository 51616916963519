import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Input } from '../../UI/InputElement/InputElement';
import { pegassureReportValidator as validate } from '../../../../utility/validator/validator';
import history from '../../../../router/history';
import moment from "moment";
import storage from '../../../../utility/storage';

let ReportsCsv = (props) => {
  let allAccountIds = [];
  let accounts = [];

  if (props.analyticsFormValues.company_ids && props.analyticsFormValues.company_ids.length > 0) {
    props.analyticsFormValues.company_ids.map(c => {
      let index = props.companyDropdownList.findIndex(com => com.id === c.value);
      if (index >= 0) {
        props.companyDropdownList[index].accounts_associated_with_company.map(account => {
          allAccountIds.push(account.id);
          accounts.push({
            value: account.id,
            label: account.name
          })
        })
      }
    })
  } else {
    accounts = props.accountsList && props.accountsList.map(account => {
          allAccountIds.push(account.id)
          return {
            value: account.id,
            label: account.name
          }
        });
  }

  const groupedAccountOptions = [
    {
      label: 'All Jobs',
      options: [{
        value: allAccountIds,
        label: 'All Jobs'
      }]
    },
    {
      label: 'Job-wise',
      options: accounts
    }
  ]

  const noAccountOptionsMessage = (a, b) => {
    return 'Job not found';
  }



  const groupedAreaOptions = () =>  {
    let allAreaIds = [];
    const allAreaDataList = [];
    props.allAreaData && props.allAreaData.map(c => {
      if (props.analyticsFormValues.account_ids) {
        if ( props.analyticsFormValues.account_ids.value === c.account.id) {
          allAreaIds.push(c.id);
          allAreaDataList.push({
            value: c.id,
            label: c.name
          })
        }
      }
      else {
        allAreaIds.push(c.id);
        allAreaDataList.push({
          value: c.id,
          label: c.name
        })
      }
      return c
    })
    return [
      {
        label: 'All Areas',
        options: [{
          value: allAreaIds,
          label: 'All Areas'
        }]
      },
      {
        label: 'Area-wise',
        options: allAreaDataList
      }
    ]
  }

  const { handleSubmit, pristine, reset, submitting, error } = props;

  const noCompanyOptionsMessage = () => {
    return 'Company not found';
  }
  const onAccountChange = (e) => {
    props.change("area_ids","");
    props.change("account_ids", e);
    if(e.label.toLowerCase() !== "all jobs"){
    props.getAllAreas(e.value );
    }
  }
  return (
      <div className="select_filter_block">
        <h3 className="h3_title">Select Filters</h3>

        <form onSubmit={(e) => handleSubmit(e)}>
        <div className="tile">
          <ol className="activity-feed">


            <li className="feed-item">
              <div className="filter_select">
                <Input name="account_ids" type="inputGroupSelect" onChange={(e) => onAccountChange(e)} className="multi_Select" noOptionsMessage={noAccountOptionsMessage} options={groupedAccountOptions} placeholder="JOBS" />
              </div>
            </li>
          <li className="feed-item">
             <div className="filter_select">
             <Input name="area_ids" type="inputGroupSelect" className="multi_Select" noOptionsMessage={noCompanyOptionsMessage} options={groupedAreaOptions()} placeholder="AREAS" />

             </div>
             </li>
            {/*<li className="feed-item">
              <div className="form-group">
                <label className="form_title cst_mt">Include Tags</label>
                <Input type="radio" labelClass="custom-control-label" forLable={"all"} value="all" label={"All"} className="custom-control-input input-modifier" id="all" name="tag_opportunity" />
                <Input type="radio" labelClass="custom-control-label" forLable={"yes"} value="true" label={"yes"} className="custom-control-input input-modifier" id="yes" name="tag_opportunity" />
                <Input type="radio" labelClass="custom-control-label" forLable={"no"} value="false" label={"No"} className="custom-control-input input-modifier" id="no" name="tag_opportunity" />
              </div>
            </li>
            <li className="feed-item">
              <div className="form-group">
                <label className="form_title cst_mt">Is Active</label>
                <Input type="radio" labelClass="custom-control-label" forLable={"activeAll"} value="all" label={"All"} className="custom-control-input input-modifier" id="activeAll" name="status" />
                <Input type="radio" labelClass="custom-control-label" forLable={"activeYes"} value="true" label={"yes"} className="custom-control-input input-modifier" id="activeYes" name="status" />
                <Input type="radio" labelClass="custom-control-label" forLable={"activeNo"} value="false" label={"No"} className="custom-control-input input-modifier" id="activeNo" name="status" />
              </div>
            </li>*/}
            <li className="feed-item mt-2">
              <form className="form-inline">
                <label className="form_title ">Date Range</label>
                <div className="input-group cst_input_group">
                  <Input name="fromDate"
                         maxDate={props.analyticsFormValues.toDate ? props.analyticsFormValues.toDate : null}
                         dateFormat="MM-dd-yyyy" id="from_date" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control from-date-analytics" />
                  {/* <input type="text" className="form-control" placeholder="01.03.2020" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                  <div className="input-group-append cursor-pointer" onClick={e => document.querySelector(".from-date-analytics").click()}>
                    <span className="input-group-text" id="basic-addon2"><img src="/images/icons/calendar.png" alt="Calendar Icon" /></span>
                  </div>
                </div>
                <span className="des"><h4>-</h4></span>
                <div className="input-group cst_input_group">
                  <Input name="toDate"
                         minDate={props.analyticsFormValues.fromDate ? props.analyticsFormValues.fromDate : null}
                         dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control to-date-analytics" />
                  {/* <input type="text" className="form-control" placeholder="07.03.2020" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                  <div className="input-group-append cursor-pointer" onClick={e => document.querySelector(".to-date-analytics").click()}>
                    <span className="input-group-text" id="basic-addon2"><img src="/images/icons/calendar.png" alt="Calendar Icon" /></span>
                  </div>
                </div>
              </form>
            </li>
            <li className="feed-item">
              <div className="btn_block">
                <button className="btn btn_danger cst_btn" type="submit">Submit</button>
              </div>
            </li>
          </ol>
          {/*<div className="tile_ft">
            <button className="btn btn_danger cst_btn">Download CSV</button>
          </div>*/}
        </div>
        </form>
      </div>
  )
}

const mapStateToProps = (state, props) => {
  const accounts = state.adminOrSuperAdminReducer.modeAccountsList;
  const allAreaData = state.adminOrSuperAdminReducer.allAreaData;
  const allAreaIds = [];
  const allAreaDataList = [];
  if (accounts) {
    const getAccount = (account) => {
      return {
          value: account.id,
          label: account.name
      }
    }

    allAreaData.map( c => {
      allAreaIds.push(c.id);
        allAreaDataList.push({
          value: c.id,
          label: c.name
        })
    });
    if (props.hasAccount && props.hasDate && props.hasEndDate) {
      var fromDate = moment(props.hasDate.replace(/-/gi, "/"))._d;
      var toDate = moment(props.hasEndDate.replace(/-/gi, "/"))._d;
      const acc_index = accounts.findIndex(i => i.id === props.hasAccount.toString());
      const acc = accounts[acc_index];
      var account_ids = getAccount(acc);
      var area_ids = {value: allAreaIds,
                      label: 'All Areas'}
    }
  }
  return { initialValues: {fromDate: fromDate, toDate: toDate, account_ids: account_ids, area_ids: area_ids} }
}

ReportsCsv = reduxForm({
  form: 'analyticsForm',
  validate,
  enableReinitialize: true
})(ReportsCsv);

ReportsCsv = connect(mapStateToProps)(ReportsCsv)
export default ReportsCsv;