import React from 'react';
import { Input } from '../../../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssetsListTable from './assetsListTable';



let AssetsList = (props) => {

  const { handleSubmit, pristine, reset, submitting, error } = props;
  return (
    <>
      <main className="app-content wraper_content inspection_wraper">
        <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner frontlines_filter mb-2">
          <form onSubmit={handleSubmit} >
            <div className="row mt-2">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="id" type="search" placeholder="Search by Asset Id" dataTest="frontline_name_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="app-search">
                  <Input className="fil_search_input input-modifier" name="name" type="search" placeholder="Search by Asset Name" dataTest="frontline_name_filter" />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              {/* text-right */}
              <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-12 ">
                <div className="frontline_btn">
                  <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2"} id="submit" type="submit">{"Go"} </button>
                  <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"} </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className='mt-4'>
          <div class=" mr-4 text-left ">
            <button className="btn cst_btn btn_danger float-right" onClick={() => { props.addAssetClicked() }}> Add Asset </button>
          </div>
          {/* <div class=" ml-4 text-right ">
            <div className="theme_switch_btn float-left" style={{ display: "inline" }}>
              <span className="switch_title">In-active</span>
              <label>
                <input type="checkbox" checked={props.activeAssets} onChange={(e) => { props.assetActiveStatusToggle(e) }} />
                <span></span>
              </label>
              <span className="ext_title">Active</span>
            </div>
          </div> */}
        </div>
        <section className="account_sec user_screen inspection_tab_content">

          <div className="quotes_table_content table-responsive">

            <div className="table quotes_table account_table">
              <AssetsListTable
                {...props}
              />
            </div>

          </div>
        </section>
      </main>

      <Dialog
        open={props.openInventoryDeleteModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
          <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
        </DialogTitle>

        <DialogActions>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteAsset(props.deleteInventoryObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
          <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleInventoryCloseDeleteModal} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
        </DialogActions>
      </Dialog>

    </>
  )
}

const mapStateToProps = (state) => {
  let id = state.InventoryReducer.assets_searchValues && state.InventoryReducer.assets_searchValues.id ? state.InventoryReducer.assets_searchValues.id : "";
  let name = state.InventoryReducer.assets_searchValues && state.InventoryReducer.assets_searchValues.name ? state.InventoryReducer.assets_searchValues.name : "";
  return { initialValues: { id, name } }
}

AssetsList = reduxForm({
  form: 'searchAssetsListForm',
  //validate,
  enableReinitialize: true
})(AssetsList);

AssetsList = connect(mapStateToProps)(AssetsList)

export default AssetsList;