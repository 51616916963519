import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import { animateScroll as scroll } from 'react-scroll'
import PreviewShifSchedule from '../../../../component/TimeTrackerModuleComponent/ShiftScheduleComponent/PreviewShifScheduleComponent/PreviewShifSchedule';
import storage from '../../../../../utility/storage';

const cloneDeep = require('clone-deep');

class PreviewShiftScheduleContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewFileQuery: {
                previewOpen: false,
                previewTitle: '',
                previewImage: '',
            },
            isLoad: false,
        }

    }

    componentDidMount() {

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            this.props.getSingleShifSchedule(this.props.match.params.id);
            storage.set('shiftScheduleDetailId', this.props.match.params.id)
        }
        this.setState({isLoad: true});
        setTimeout(() => {
            this.setState({isLoad: false});
        }, 1000);
        scroll.scrollToTop();

    }

    render() {
        return (
            <PreviewShifSchedule
                {...this.props}
                isLoad={this.state.isLoad}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.TimeTrackerModReducer.isLoading,
        singleShiftScheduleData: state.TimeTrackerModReducer.singleShiftScheduleData,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSingleShifSchedule: (id) => dispatch(actions.getSingleShifSchedule(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewShiftScheduleContainer); 