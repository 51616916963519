import React from 'react';
import PreviewShiftSwappingRequestInfoSkeleton from '../../ShiftComponent/PreviewShiftSwapInfoComponent/PreviewShiftSwappingRequestInfo/PreviewShiftSwappingRequestInfoSkeleton';
import { tzDateConverterTool_V1, tzTimeConversionTool_V1, tzTimeConverterTool_V3 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
import { calculateHoursFromMinutes_V1 } from '../../../../../utility/utility';
import { Link } from 'react-router-dom';


let ClkInClkOutShiftRecapComponent = (props) => {
    let { isLoad, isLoading, isClockInShiftAvailable, clockedInShiftData, assignedTaskTicketInfo } = props;
    console.log("PRPSKNSJBND", props);
    if (isLoad) {

        return (
            <>
                <PreviewShiftSwappingRequestInfoSkeleton />
            </>
        );

    } else {

        if (!(isClockInShiftAvailable)) {
            return (
                <>

                    <main className="app-content add_account_modal add_frontline_pop" style={{ marginTop: '-11px' }}>
                        <div className="appcontent_Inner user_newQuote pt-0">
                            <div className="container py-5">
                                <div className="row text-center mb-4" style={{ marginTop: '15%' }}>
                                    <div className="col-lg-7 mx-auto">
                                        <h3 className="mb-0" style={{ color: '#4a4a4a', fontWeight: '500', fontSize: '25px' }}> No Shift Data Available </h3>
                                        {/* <p className="lead mb-0" style={{ color: 'black' }}>Create an elegant product list using Bootstrap 4 list group</p> */}
                                    </div>
                                </div>
                            </div>

                        </div >
                    </main >

                </>
            );

        } else {

            return (
                <>

                    <main className="app-content add_account_modal add_frontline_pop" style={{ marginTop: '3%' }}>
                        <div className="appcontent_Inner user_newQuote pt-0">

                            <div className="container py-5">

                                <div className="row text-center mb-4">
                                    <div className="col-lg-7 mx-auto">
                                        <h1 className="mb-0" style={{ color: '#4a4a4a', fontWeight: '500', fontSize: '37px' }}> Shift Recap </h1>
                                        {/* <p className="lead mb-0" style={{ color: 'black' }}>Create an elegant product list using Bootstrap 4 list group</p> */}
                                    </div>
                                </div>
                                <div className="addQuote_form">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-9 mx-auto">
                                                <ul className="list-group shadow rounded" style={{ marginLeft: '10%', marginRight: '10%' }}>

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Date </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {clockedInShiftData?.date ? tzDateConverterTool_V1(clockedInShiftData.date) : '–'} </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Clocked In At </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted">
                                                                        {clockedInShiftData?.check_int_at ? tzTimeConversionTool_V1(clockedInShiftData.check_int_at.split('T')[1].split('.')[0], clockedInShiftData.check_int_at.split('T')[0]) : '–'}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    {
                                                        clockedInShiftData?.breaks?.length > 0 ? <li className="list-group-item">
                                                            <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                    <div className="media-body">
                                                                        {
                                                                            clockedInShiftData?.breaks?.length > 0 && clockedInShiftData?.breaks?.map((_, index) => (<h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break {index + 1}</h5>))
                                                                        }
                                                                        {/* <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break </h5>
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break </h5>
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break </h5>
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break </h5> */}
                                                                    </div>
                                                                    <div className="media-body">
                                                                        {clockedInShiftData?.breaks?.map((breaks, index) => {
                                                                            return (
                                                                                <>
                                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted">
                                                                                        {breaks?.start !== null ? tzTimeConversionTool_V1(breaks.start.split('T')[1].split('.')[0], breaks.start.split('T')[0]) : '–'} – {breaks?.end !== null ? tzTimeConversionTool_V1(breaks.end.split('T')[1].split('.')[0], breaks.end.split('T')[0]) : '–'}
                                                                                    </h5>
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </li> : <li className="list-group-item">
                                                            <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                    <div className="media-body">
                                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break </h5>

                                                                        {/* <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break </h5>
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break </h5>
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break </h5>
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break </h5> */}
                                                                    </div>
                                                                    <div className="media-body">

                                                                        <>
                                                                            <h5 className="ml-lg-0 order-1 order-lg-2 text-muted">
                                                                                –
                                                                            </h5>
                                                                        </>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </li>
                                                    }


                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Overtime </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {clockedInShiftData?.overtime ? clockedInShiftData.overtime : '–'} </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    {
                                                        clockedInShiftData?.shiftDetailJobs?.map((item) => (
                                                            <li className="list-group-item">
                                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                        <div className="media-body">
                                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Worked Hours in {item?.account?.name} </h5>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {item?.total_minutes ? calculateHoursFromMinutes_V1(item.total_minutes) : '–'} </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Total Worked Hours </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted">{
                                                                       clockedInShiftData?.total_time ? calculateHoursFromMinutes_V1(clockedInShiftData?.total_time) : '–'}</h5>
                                                                    {/* <h5 className="ml-lg-0 order-1 order-lg-2 text-muted"> {clockedInShiftData?.working_minutes ? calculateHoursFromMinutes_V1(clockedInShiftData.working_minutes) : '–'} </h5> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    {
                                                        assignedTaskTicketInfo?.map((item) => (
                                                            <li className="list-group-item">
                                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                        <div className="media-body">
                                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark">  {item?.name} Worked Tickets </h5>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            {
                                                                                item?.tickets && item?.tickets.length ? item?.tickets.map((ticket, index) => (
                                                                                    <Link to={`/scheduled-tasks/ticket/preview-scheduled-task/${ticket?.id}`} key={index} className="ml-lg-0 order-1 order-lg-2  " style={{ color: "blue", marginBottom: "-6px", marginRight: "10px" }}>
                                                                                        {ticket?.id ? ticket.id : "-"}
                                                                                    </Link>)) : null
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }

                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Clocked Out At </h5>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted">
                                                                        {clockedInShiftData?.check_out_at ? tzTimeConversionTool_V1(clockedInShiftData.check_out_at.split('T')[1].split('.')[0], clockedInShiftData.check_out_at.split('T')[0]) : '–'}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div >
                    </main >

                </>
            );
        }

    }


};

export default ClkInClkOutShiftRecapComponent;