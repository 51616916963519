import React, { useEffect, useState } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { TicketSearchValidator as validate } from '../../../../utility/validator/validator';
import { convertDateToPickerFormat, transformStringToSelectOptionObject } from '../../../../utility/utility';
import BrmScheduleTable from './brmScheduleTable';
import { InputField } from '../../UI/InputField/InputField';


const getCompnayName = (manager) => {
    return manager && manager.map((data) => {
        return {
            value: data.id,
            label: data.name,
        };
    })
};

let BRMSchedule = (props) => {

    const { handleSubmit, pristine, reset, submitting, error, companiesList } = props;

    const TierOptions = [
        {
            id: '1',
            name: 'Tier A'
        }, {
            id: '2',
            name: 'Tier B'
        }, {
            id: '3',
            name: 'Tier C'
        }
    ];
    const statusOptions = [
        {
            id: 'pending',
            name: 'Pending'
        },
        {
            id: 'completed',
            name: 'Completed'
        }
    ];

    const [companyList, setCompanyList] = useState(getCompnayName(companiesList));
    const MatrixList=[{
        label:"Standard Matrix",
        value:"default"
    },{
        label:"Custom Matrix",
        value:"custom" 
    }]
    useEffect(() => {
        setCompanyList(getCompnayName(companiesList));
    }, [companiesList]);

    const noCategoriesOptionsMessage = (a, b) => {
        return 'Category not found';
    }

    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content ticket_filter">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="something" />
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <InputField dataTest="search_by_description" name="id" className="fil_search_input" type="text" placeholder="Search by ID" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input name="meeting" className="fil_search_input" type="search" placeholder="Search by Meeting Name" />
                                    <button className="app-search__button">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">

                                <Input dataTest="search_by_description" name="customer" type="inputSelect" noOptionsMessage={noCategoriesOptionsMessage} options={companyList} placeholder="Search by Customer" />

                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <Input name="matrix" dataTest="select_ticket_status" type="select" options={TierOptions} placeholder="Tier" className="custom-select input-modifier add_user_select" />
                            </div>
                        </div>
                        <div className='row' style={{ marginTop: '10px' }}>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <Input name="status" dataTest="select_ticket_status" type="select" options={statusOptions} placeholder="Status" className="custom-select input-modifier add_user_select" />
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" style={{ maxWidth: '17%' }}>
                                <Input name="fromDate" maxDate={props.selectedValues.toDate ? props.selectedValues.toDate : new Date()} dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="brmSchedule_DatePicker" />
                                <div class="input-group-append cst_group_append">
                                    <span class="input-group-text" id="basic-addon2" style={{ marginLeft: '-3rem' }}><i class="fa fa-calendar"></i></span>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6" style={{ maxWidth: '17%' }}>
                                <Input name="toDate" minDate={props.selectedValues.fromDate ? props.selectedValues.fromDate : null} maxDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="brmSchedule_DatePicker" />
                                <div class="input-group-append cst_group_append">
                                    <span class="input-group-text" id="basic-addon2" style={{ marginLeft: '-3rem' }}><i class="fa fa-calendar"></i></span>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <Input dataTest="search_by_description" name="type" type="inputSelect" noOptionsMessage={noCategoriesOptionsMessage} options={MatrixList} placeholder="Search by Matrix" />
                            </div>

                            <div className="col-xl-2 col-lg-6 col-sm-3 col-12">
                                <button data-test="filter_go_btn" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                <button data-test="filter_reset_btn" style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <section className="account_sec inspection_tab_content appcontent_Inner">
                    <div className="quotes_table_content accounts_table_contnet table-responsive">
                        <div className="table quotes_table user_react_table">
                            <BrmScheduleTable
                                {...props}
                            />
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
const mapStateToProps = (state) => {
    let meeting = state.BRM.brmScheduleList_searchValues && state.BRM.brmScheduleList_searchValues.meeting ? state.BRM.brmScheduleList_searchValues.meeting : "";;
    let matrix = state.BRM.brmScheduleList_searchValues && state.BRM.brmScheduleList_searchValues.matrix ? state.BRM.brmScheduleList_searchValues.matrix : "";
    let customer = state.BRM.brmScheduleList_searchValues && state.BRM.brmScheduleList_searchValues.customer ? state.BRM.brmScheduleList_searchValues.customer : "";
    let id = state.BRM.brmScheduleList_searchValues && state.BRM.brmScheduleList_searchValues.id ? state.BRM.brmScheduleList_searchValues.id : "";
    let status = state.BRM.brmScheduleList_searchValues && state.BRM.brmScheduleList_searchValues.status ? state.BRM.brmScheduleList_searchValues.status : "";
    let fromDate = state.BRM.brmScheduleList_searchValues && state.BRM.brmScheduleList_searchValues.fromDate ? convertDateToPickerFormat(state.BRM.brmScheduleList_searchValues.fromDate) : "";
    let toDate = state.BRM.brmScheduleList_searchValues && state.BRM.brmScheduleList_searchValues.toDate ? convertDateToPickerFormat(state.BRM.brmScheduleList_searchValues.toDate) : "";
    let matrixType = state.BRM.brmScheduleList_searchValues && state.BRM.brmScheduleList_searchValues.matrixType ? state.BRM.brmScheduleList_searchValues.matrixType  : {label:"Standard Matrix",value:"default"};
    return { initialValues: { meeting, status, matrix, customer, id, fromDate, toDate,type:matrixType }, formStates: getFormValues('brmScheduleForm')(state) }
}
BRMSchedule = reduxForm({
    form: 'brmScheduleForm',
    validate,
    enableReinitialize: true
})(BRMSchedule);
const selector = formValueSelector('brmScheduleForm') // <-- same as form name
BRMSchedule = connect(state => {
    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
})(BRMSchedule)
BRMSchedule = connect(mapStateToProps)(BRMSchedule)
export default BRMSchedule;