import React, { useState } from 'react';
import ReactTable from 'react-table-v6';
import ReactTooltip from 'react-tooltip';
import '../../../../../styles/custom.css';
import { CustomNoDataComponent, makeFirstLetterCapital, getConvertTo12Hours, convertDateFormatV7, getUser } from '../../../../../utility/utility';
import { userRoles } from '../../../../../utility/constants/constants';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import storage from '../../../../../utility/storage';
import OverrideAvailabilityReviewForm from './OverrideAvailabilityReviewComponent/OverrideAvailabilityReviewForm';
import { tzDateConverterTool_V1, tzTimeConversionTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';

var moment = require('moment');

let OverrideAvailabilityRequestLisTable = (props) => {

    const [passOverrideAvailabilityObject, setPassOverrideAvailabilityObject] = useState({});

    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchOverrideAvailabilityRequestListForm && state.form.searchOverrideAvailabilityRequestListForm.values ? state.form.searchOverrideAvailabilityRequestListForm.values : {});
    const DateGenerate = useSelector(state => state.TimeTrackerModReducer.dataGenerated);
    const values = useSelector(state => state.TimeTrackerModReducer.overrideAvailabilityRequestList_searchValues);
    const No_of_pages = useSelector(state => state.TimeTrackerModReducer.overrideAvailabilityRequestList_total_pages);
    const No_of_rows = useSelector(state => state.TimeTrackerModReducer.overrideAvailabilityRequestList_no_of_rows);
    const isOverrideAvailabilityRequestList = useSelector(state => state.TimeTrackerModReducer.isOverrideAvailabilityRequestList);
    const overrideAvailabilityRequestList_curPage = useSelector(state => state.TimeTrackerModReducer.overrideAvailabilityRequestList_curPage);
    const SearchOrResetOverrideAvailabilityRequestListClicked = useSelector(state => state.TimeTrackerModReducer.SearchOrResetOverrideAvailabilityRequestListClicked);

    const data = props.overrideAvailabilityRequestList && props.overrideAvailabilityRequestList.map((override) => {

        if (override && override.id) {

            let requestedBy = override.createdBy && override.createdBy.id ? makeFirstLetterCapital(override.createdBy.first_name) + ' ' + makeFirstLetterCapital(override.createdBy.last_name) : '−'
            let requestedTo = override.createdTo && override.createdTo.id ? makeFirstLetterCapital(override.createdTo.first_name) + ' ' + makeFirstLetterCapital(override.createdTo.last_name) : '−'
            let approver = override.approver && override.approver.id ? makeFirstLetterCapital(override.approver.first_name) + ' ' + makeFirstLetterCapital(override.approver.last_name) : '−'

            return {
                start_date: override.start_date ? tzDateConverterTool_V1(override.start_date) : '−',
                start_time: override.start_time ? tzTimeConversionTool_V1(override.start_time, override.start_date) : '−',
                end_time: override.end_time ? tzTimeConversionTool_V1(override.end_time, override.start_date) : '−',
                createdBy: requestedBy,
                createdTo: requestedTo,
                approvedBy: approver,
                status: override.status ? makeFirstLetterCapital(override.status) : '−',
                actions:
                    <>

                        <ReactTooltip place="left" type="dark" effect="solid" />
                        {!(props.user.role === userRoles.FRONTLINE) && override.status && override.status == 'requested' ? (
                            <>
                                <div>
                                    <button data-tip="Approve Override Availability" onClick={() => { props.handleFrequentTimeTracker_openModal(); setPassOverrideAvailabilityObject({ id: override.id, reviewStatus: 'approved' }); }} type="button" className="btn cst_btn success_button mr-3" data-toggle="modal" data-target="#overrideAvailabilityReviewRequest" data-backdrop="static" data-keyboard="false"> Approve </button>
                                    <button data-tip="Reject Override Availability" onClick={() => { props.handleFrequentTimeTracker_openModal(); setPassOverrideAvailabilityObject({ id: override.id, reviewStatus: 'rejected' }); }} type="button" className="btn cst_btn danger_button mr-3" data-toggle="modal" data-target="#overrideAvailabilityReviewRequest" data-backdrop="static" data-keyboard="false"> Reject </button>
                                </div>
                            </>
                        ) : null}

                    </>
            };

        }

    });

    const columns = [
        {
            Header: <><span> Overriding Date </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'start_date',
            show: props.overrideAvailabilityRequestList.length !== 0,
        },
        {
            Header: <><span> Start Time </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'start_time',
            show: props.overrideAvailabilityRequestList.length !== 0,
        },
        {
            Header: <><span> End Time </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'end_time',
            show: props.overrideAvailabilityRequestList.length !== 0,
        },
        {
            Header: <><span> Requested By </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'createdBy',
            show: props.overrideAvailabilityRequestList.length !== 0,
        },
        {
            Header: <><span> {props.user.role === userRoles.FRONTLINE ? 'Approved By' : 'Requested To'} </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: `${props.user.role === userRoles.FRONTLINE ? 'approvedBy' : 'createdTo'}`,
            show: props.overrideAvailabilityRequestList.length !== 0,
        },
        {
            Header: <><span> Override Status </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'status',
            show: props.overrideAvailabilityRequestList.length !== 0,
        },
        {
            Header: '',
            accessor: 'actions',
            width: 300,
        }
    ];

    const [tablePage, setTablePage] = useState(0);
    const tablePageNew = React.useRef();

    if (overrideAvailabilityRequestList_curPage !== tablePageNew.current) {
        tablePageNew.current = overrideAvailabilityRequestList_curPage;
    }

    const setTablePageFn = (page) => {
        setTablePage(page)
        props.overrideAvailabilityRequestListPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetOverrideAvailabilityRequestListClicked) {
            dispatch(actions.resetSearchOrResetTimeTracker());
        } else {
            if (!DateGenerate && !props.isOverrideAvailabilityRequestList) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : overrideAvailabilityRequestList_curPage + 1;
                const overrideAvailabilityRequestList_no_of_rows = state.pageSize;
                if (No_of_rows != overrideAvailabilityRequestList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }

                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                const status = SearchValue.overrideAvailabilityRequestStatus ? typeof (SearchValue.overrideAvailabilityRequestStatus) == 'object' ? SearchValue.overrideAvailabilityRequestStatus.value : SearchValue.overrideAvailabilityRequestStatus : null;
                
                if (props.user.role == userRoles.FRONTLINE) {
                    let currentUser = getUser();
                    props.getOverrideAvailabilityRequestList({ page, limit, order, orderBy, status, userId: currentUser.id }, values);
                } else {
                    props.getOverrideAvailabilityRequestList({ page, limit, order, orderBy, status,  }, values);
                }

            }
        }
    }

    return (
        SearchOrResetOverrideAvailabilityRequestListClicked && props.isOverrideAvailabilityRequestList ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            : (
                <>

                    <ReactTable
                        data={data}
                        loading={props.isOverrideAvailabilityRequestList}
                        minRows={0}
                        defaultPageSize={No_of_rows}
                        pages={No_of_pages}
                        gotoPage={overrideAvailabilityRequestList_curPage}
                        page={overrideAvailabilityRequestList_curPage}
                        onPageChange={(page) => { setTablePageFn(page) }}
                        // onPageSizeChange={props.onUserPageSizeChange}
                        manual
                        onFetchData={fetchData}
                        showPaginationTop={true}
                        showPaginationBottom={false}
                        showPagination={props.overrideAvailabilityRequestList.length !== 0}
                        columns={columns}
                        // style={{marginTop: '10px'}}
                        NoDataComponent={() => CustomNoDataComponent(props.isOverrideAvailabilityRequestList, 'No Override Availability Request List Found')}
                        LoadingComponent={() =>
                            isOverrideAvailabilityRequestList ?
                                <div style={{
                                    display: "block",
                                    position: "absolute",
                                    left: 0,
                                    height: '100%',
                                    right: 0,
                                    background: "rgba(255,255,255,0.8)",
                                    transition: "all .3s ease",
                                    top: 0,
                                    bottom: 0,
                                    textAlign: "center"
                                }}
                                    className="loader_btn_block">
                                    <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                                </div> : null}
                        getTheadThProps={(state, rowInfo, column, instance) => {
                            // console.log(column);
                            return {
                                style: {
                                    borderTop: 'none',
                                    borderBottom: 'none',
                                    borderRight: 'none',
                                    textAlign: 'center',
                                    color: '#566975',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    whiteSpace: 'nowrap',
                                    float: 'left',
                                }
                            }
                        }
                        }
                        getTrProps={(state, rowInfo, column, instance) => {
                            // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                            console.log(column);
                            return {
                                className: 'react-table-tr-element',
                                style: {
                                    border: '1px solid #c7cdd1',
                                    transition: '.3s ease-in-out',
                                    width: '100%',
                                    // borderRadius: '4px',
                                    // height: '80px',
                                    marginTop: '5px',
                                    float: 'left'
                                }
                            }

                        }}
                        getTdProps={(state, rowInfo, column, instance) => {

                            if (column.id == 'image') {
                                return {
                                    style: {
                                        marginTop: '0px'
                                    }
                                }
                            } else {
                                return {
                                    className: 'react-td-element',
                                }
                                //  {
                                //   style: {
                                //     borderTop: 'none',
                                //     verticalAlign: 'middle',
                                //     fontSize: '14px',
                                //     color: '#2b3034',
                                //     marginTop: '10px !important',
                                //     textAlign: 'center',
                                //   }
                                // }
                            }
                        }
                        }
                        // getPaginationProps={() => {
                        //     return {
                        //         style: {
                        //             marginTop: '50px',
                        //             border: 'none',
                        //             boxShadow: 'none'
                        //         }
                        //     }
                        // }
                        // }
                        getProps={() => {
                            return {
                                style: {
                                    border: 'none'
                                }
                            }
                        }}
                    />

                    {props.isTimeTrackerModalOpen ? (
                        <OverrideAvailabilityReviewForm {...props} overrideAvailability={passOverrideAvailabilityObject} />
                    ) : null}

                </>
            )
    );

};

export default OverrideAvailabilityRequestLisTable;