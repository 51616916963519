import React, { useEffect, useState } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { accountUsersSearchValidator as validate } from '../../../../utility/validator/validator';
import AssociateAccountModal from './associateAccountModal';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AccountUsersTable from './accountUsersTable';
import AccountAreasTable from '../AccountsArea/AccountAreaListComponents/AccountAreasTable';
import ImportAreaCsv from '../AccountsArea/AccountAreaListComponents/importCsv';
import PrintQrCodes from '../AccountsArea/PrintQrCodes/PrintQrCodes';
import { userActiveStatusOption, userRoles } from '../../../../utility/constants/constants';
import AssociateAccountHolidaysModal from './associateAccountHolidaysModal';
import AssociateAccountJobsModal from './associateAccountJobsModal';
import AccountHolidaysTable from '../AccountsArea/AccountAreaListComponents/AccountHolidaysTable';
import '../../../../styles/custom.css';
import AccountSchedulesTable from './accountUsersTable';
import AccountSchedulesEventsTable from './AccountSchedulesTable';
import storage from '../../../../utility/storage';
import LogBookAreaTable from '../LogBook Area/AccountAreaListComponents/LogBookAreaTable';
import PlayBookAreaTable from '../PlayBookArea/AccountAreaListComponents/playbook_area_table';
import ImportLogbookCSV from '../LogBook Area/AccountAreaListComponents/importCsv';
import { transformStringToSelectOptionObject } from '../../../../utility/utility';
import isEmpty from '../../../../utility/IsEmptyValidator';
import cloneDeep from 'clone-deep';


let AccountUsers = (props) => {
    console.log('props', props);
    const [openUrl, setOpenURL] = useState(false);
    const [genericUrl, setURL] = useState("");
    const [usersTab, setUsersTab] = useState(true);
    const [logBookTab, setLogBookTab] = useState(false);
    
    const [tabInfo, setTabInfo] = useState('');
    const [areaTab, setAreaTab] = useState(false);
    const [holidaysTab, setHolidaysTab] = useState(false);
    const [scheduleTab, setscheduleTab] = useState(false);
    const [selectedTab, setSelectedTab] = useState('user');
    const { handleSubmit, pristine, reset, submitting, error } = props;
    const account_id_info = useSelector(state => state.adminOrSuperAdminReducer.currentAccount);

    const checkUserTab = () => {
        return (props.history.location.search.includes("job_users"))
    };

    const checkAreaTab = () => {
        return (props.history.location.search.includes("job_area"))
    };

    const checkSchedulesTab = () => {
        return (props.history.location.search.includes("job_schedules"))
    };

    const checkHolidayTab = () => {
        return (props.history.location.search.includes("job_holiday"))
    };

    const checkLogBookTab = () => {
        return (props.history.location.search.includes("job_logbook"))
    };

    const checkPlayBookTab = () => {
        return (props.history.location.search.includes("job_playbook"))
    };

    useEffect(() =>{
        if (props.history.location.search.includes("job_users")){
            setUsersTab(true);
            setAreaTab(false);
            setHolidaysTab(false);
            setscheduleTab(false)
            setLogBookTab(false)
            
            setTabInfo('users')
        } else if(props.history.location.search.includes("job_area")) {
            setUsersTab(false);
            setAreaTab(true);
            setHolidaysTab(false);
            setscheduleTab(false)
            setLogBookTab(false)
            
            setTabInfo('area')
        } else if (props.history.location.search.includes("job_holiday")) {
            setUsersTab(false);
            setAreaTab(false);
            setHolidaysTab(true);
            setscheduleTab(false)
            setLogBookTab(false)
            
            setTabInfo('holiday')
        }else if (props.history.location.search.includes("job_schedules")) { 
            setUsersTab(false);
            setAreaTab(false);
            setHolidaysTab(false);
            
            setscheduleTab(true)
            setTabInfo('schedules')
        }
        else if (props.history.location.search.includes("job_logbook")) {
            setUsersTab(false);
            setAreaTab(false);
            setHolidaysTab(false);
            setLogBookTab(true)
            
            setTabInfo('logbook')
        }
        else if (props.history.location.search.includes("job_playbook")) {
            setUsersTab(false);
            setAreaTab(false);
            setHolidaysTab(false);
            setLogBookTab(false)
            
            setTabInfo('playbook')
        }
    }, [props.history.location.search]);

    const tabClicked = ({ user, area, holidays, schedules, logBook,playbook }) => {
        setUsersTab(user);
        setAreaTab(area);
        setHolidaysTab(holidays);
        setLogBookTab(logBook);
        
        if(user) {
            props.history.push(`/accounts/${props.match.params.accountId}/account_users?job_users=true`)
            setSelectedTab('user');
        } else if (area) {
            props.history.push(`/accounts/${props.match.params.accountId}/account_users?job_area=true`)
            setSelectedTab('area');
        } else if (holidays) {
            props.history.push(`/accounts/${props.match.params.accountId}/account_users?job_holiday=true`)
            setSelectedTab('holiday');
        } else if (schedules) { 
            props.history.push(`/accounts/${props.match.params.accountId}/account_users?job_schedules=true`)
            setSelectedTab('schedules');
        } else if (logBook) {
            props.history.push(`/accounts/${props.match.params.accountId}/account_users?job_logbook=true`)
            setSelectedTab('logbook');
        }
        else if (playbook) {
            props.history.push(`/accounts/${props.match.params.accountId}/account_users?job_playbook=true`)
            setSelectedTab('playbook');
        }
        console.log('tabssss', usersTab, areaTab, holidaysTab, logBookTab)
    }
    const goToScheduleClicked = () => { 
        props.history.push(`/accounts/${account_id_info.id}/account_users/schedule-time`);
    }
    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content appcontent_inner">
                    <form onSubmit={handleSubmit}>
                        <div className="row mt-1 mb-0">
                            {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input className="fil_search_input input-modifier" name="name" type="search" placeholder="Search By Companie" />
                                    <button className="app-search__button">
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div> */}
                            {tabInfo !== 'area' && tabInfo !== 'holiday' && tabInfo !== 'logbook' ? <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6 col-12 theme_col_px d-flex">
                                <div class="app-search mt-0">
                                    <Input className="fil_search_input input-modifier" name="email" type="search" placeholder="Email Search" />
                                    <button className="app-search__button">
                                        <img src="/images/icons/icn_search.png" alt="Logo" />
                                    </button>
                                </div>
                                <div class="col-xl-7">
                                    <Input name="active_status" type="inputSelect" options={userActiveStatusOption} placeholder="ActiveStatus Search" />
                                </div>
                            </div> : null}
                            {tabInfo === 'area' ? <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6 col-12 theme_col_px">
                                <div class="app-search">
                                    <Input dataTest="area_name_search" className="fil_search_input input-modifier" name="area_name" type="text" placeholder="Search By Area Name" />
                                    <button className="app-search__button">
                                        <img src="/images/icons/icn_search.png" alt="Logo" />
                                    </button>
                                </div>
                            </div>: null}
                            {tabInfo === 'holiday' ? <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6 col-12 theme_col_px">
                                <div class="app-search">
                                    <Input className="fil_search_input input-modifier" name="holiday_name" type="search" placeholder="Search By Holiday" />
                                    <button className="app-search__button">
                                        <img src="/images/icons/icn_search.png" alt="Logo" />
                                    </button>
                                </div>
                            </div>: null}
                            {tabInfo === 'logbook' ? <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6 col-12 theme_col_px">
                                <div class="app-search">
                                    <Input className="fil_search_input input-modifier" name="logbook_name" type="search" placeholder="Search By Logbook Area" />
                                    <button className="app-search__button">
                                        <img src="/images/icons/icn_search.png" alt="Logo" />
                                    </button>
                                </div>
                            </div>: null}
                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12">
                                <div class="rset_btn">
                                    <button data-test="submit_button" style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                    <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"} </button>
                                </div>
                            </div>
                            {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input className="fil_search_input input-modifier" name="email" type="search" placeholder="Search By Email" />
                                    <button className="app-search__button">
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div> */}
                            <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 text-right accounts_user_btn theme_col_px">
                                {areaTab ? (props.allQRCodes && props.accountAreas.length !== 0 && (props.user.selected_mode && props.user.selected_mode === 'external') ? <button type="button" data-test="job_clearing_btn" className="btn btn-secondary float-right cst_btn mb-1 " onClick={() => [setOpenURL(true), setURL(props.account.generic_url)]}>Cleaning History
                                </button> : '') : null}
                                {logBookTab ? (props.logbookAreas && props.logbookAreas.length !== 0 && (props.user.selected_mode && props.user.selected_mode === 'external') ? <button type="button" data-test="job_clearing_btn" className="btn btn-secondary float-right cst_btn mb-1 " onClick={() => {setOpenURL(true); setURL(props.logsAreaUrl)}}> Logbook History
                                </button> : '') : null}
                                {props.user.selected_mode && props.user.selected_mode === 'external' ? <PrintQrCodes {...props} /> : ''}
                               
                                <div class="dropdown">
                                    <button type="button" class="dropbtn btn btn-secondary cst_btn mr-1 mb-1 min-width-btn" data-test="job_associate_btn">Associate</button>
                                    <div class="dropdown-content">
                                        {props.user.selected_mode && props.user.selected_mode === 'external' ? <a href="#">
                                        <button type="button" className="  btn-secondary cst_btn mr-1 mb-1 min-width-btn" onClick={props.associateAccoutHolidaysClicked} data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#associateHolidays" data-test="job_associate_holiday_btn">Associate Holidays</button>
                                        </a> : ''}
                                        <a href="#">
                                        <button type="button" className="  btn-secondary cst_btn mr-1 mb-1 min-width-btn" onClick={props.associateAccountJobsClicked} data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#associateJobs" data-test="job_associate_inspect_btn">Associate Inspection Forms</button>
                                        </a>
                                        <a href="#">
                                        <button type="button" className="  btn-secondary cst_btn mr-1 mb-1 min-width-btn" onClick={props.associateAccoutUserClicked} data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#associateUser" data-test="job_associate_user_btn">Associate User</button>
                                        </a>
                                    </div>
                                </div>
                                <div class="dropdown">
                                    <button type="button" class="dropbtn btn btn-secondary cst_btn mr-1 mb-1 min-width-btn" data-test="job_inner_add">Add</button>
                                    <div class="dropdown-content">
                                        {props.user.selected_mode && props.user.selected_mode === 'external' ? 
                                        <a>
                                        <button type="button" className=" btn-secondary cst_btn mr-1 mb-1 min-width-btn" onClick={props.areaUserClicked} data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#associateUser" data-test="job_add_area_btn">Add Area</button>
                                        </a> : ''}
                                        {props.user.role === userRoles.ADMIN ? null :
                                        <a>
                                    <button type="button" className="btn-secondary cst_btn mb-1 mr-1 min-width-btn" data-toggle="modal" onClick={() => {
                                        var routeClick = setInterval(() => {
                                            props.history.push({
                                                state: {storedRoute: props.location.pathname + props.location.search}
                                            });
                                            clearInterval(routeClick);
                                        }, 100);
                                        props.newAccountUserClicked();
                                    }} data-target="#addAccountModal" data-test="job_add_newuser_btn">Add New User</button>
                                        </a>
                                        }
                                        <a>
                                    <button type="button" className="btn-secondary cst_btn mb-1 mr-1 min-width-btn" data-toggle="modal" onClick={props.areaLogBookUserClicked} data-target="#addAccountModal" data-test="job_add_newuser_btn">Add Logbook Area</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                                <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary"} id="reset" type="reset">{"Reset"}  </button>
                            </div>


                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 text-right quotes_btn_wrap">
                                <a onClick={props.newCompanieClicked} href="javascript:void (0)" className="btn btn-secondary cst_btn"><i className="fa fa-plus icn_plus"></i>New Company</a>
                            </div> */}
                        </div>
                    </form>
                </div>
                <div id="exTab3" className="">
                    <ul className="custom-btn-group nav nav-pills  float-right mt-2">
                        <li onClick={() => tabClicked({ user: true, area: false, holidays: false, schedules: false, logBook : false,playbook:false })}>
                            <a href="#account-user" id='user-account-users-tab-click' data-toggle="tab" className={`${checkUserTab() ? 'active show' : ''} btn btn-user`} data-test="job_user_tab">Users</a>
                        </li>
                        {props.user.selected_mode && props.user.selected_mode === 'external' ? 
                        <>
                        <li onClick={() => tabClicked({ user: false, area: true, holidays: false, schedules: false,playbook:false })}>
                            <a href="#account-area" id='user-account-areas-tab-click' data-toggle="tab" className={`${checkAreaTab() ? 'active show' : ''} btn btn-area`} data-test="job_areas_tab">Areas</a>
                        </li>
                        <li onClick={() => tabClicked({ user: false, area: false, holidays: false, logBook : true,playbook:false })}>
                            <a href="#account-area" id='user-account-logbook-tab-click' data-toggle="tab" className={`${checkLogBookTab() ? 'active show' : ''} btn btn-area`} data-test="job_areas_tab">Logbook</a>
                        </li>
                        
                        <li onClick={() => tabClicked({ user: false, area: false, holidays: true, schedules: false,playbook:false })} className="rounded-0">
                            <a href="javascript:void(0)" id='user-account-holiday-tab-click' data-toggle="tab" className={`${checkHolidayTab() ? 'active show rounded-0' : 'rounded-0'} btn btn-area`} data-test="job_holidays_tab">Holidays</a>
                        </li>
                       <li onClick={() => {
                        storage.set('accountUsersRedirect', props.history.location.pathname + "?job_users=true")
                        goToScheduleClicked(props.accountId)}}>
                            <a href="#account-schedule" id='user-account-schedule' data-toggle="tab" className={`${checkSchedulesTab() ? 'active show' : ''} btn btn-area`} data-test="job_areas_tab">Schedules</a>
                        </li>
                        </>
                        : ''
                        }
                    </ul>
                    {areaTab ?
                        <>
                        {props.user.selected_mode && props.user.selected_mode === 'external' ? <ImportAreaCsv {...props}/> : '' }
                        </>
                    : logBookTab ? 
                        <>
                        {props.user.selected_mode && props.user.selected_mode === 'external' ? <ImportLogbookCSV {...props}/> : '' }
                        </>
                    : null}
                    <div className="tab-content clearfix">
                        {props.openAccountUserModal ?
                            <AssociateAccountModal
                                formStates={props.formStates}
                                getCurrentAccount={props.getCurrentAccount}
                                accountErrorMessage={props.accountErrorMessage}
                                popupLoading={props.popupLoading}
                                onSubmit={props.addAccountUsers}
                                filterAccountUsers={props.filterAccountUsers}
                                accountUsersList={props.accountUsersList ? props.accountUsersList : []}
                                account={props.account}
                                closeAccountModalClicked={props.closeAccountModalClicked}
                            />
                            : null}
                        {props.openAccountHolidaysModal ?
                            <AssociateAccountHolidaysModal
                                onSubmit={props.submitAssociateAccountHolidays}
                                {...props}
                            />
                            : null}
                        {props.openAccountJobsModal ?
                            <AssociateAccountJobsModal
                                {...props}
                            />
                            : null}
                        {/* {props.openAssociateCompanyModal ?
                         : null} */}
                        {usersTab ?
                            <div className={`tab-pane active`} id="account-user">
                                <section className="account_sec user_screen inspection_tab_content">
                                    {/* <CSVLink filename="Users.csv" className="csv_btn btn_danger " data={convertUsersDataToCsvType(props.usersList)} >Export Csv</CSVLink><br /><br /><br /> */}
                                    <div className="quotes_table_content table-responsive">
                                        <div className="table quotes_table user_table_contnet user_react_table account_table">
                                            <AccountUsersTable
                                                {...props}
                                                accountId={props.accountId}
                                                deleteAccountUsers={props.deleteAccountUsers}
                                                accountUsers_rows={props.accountUsers_rows}
                                                isLoading={props.isLoading}
                                                onAccountUsersPageSizeChange={props.onAccountUsersPageSizeChange}
                                                accountUsersList={props.accountUsersList ? props.accountUsersList : []}
                                                editAccountUsersClicked={props.editAccountUsersClicked}
                                                openDeleteModel={props.openDeleteModel}
                                                handleClickDeleteModel={props.handleClickDeleteModel}
                                                makeAccountUserAsOwner={props.makeAccountUserAsOwner} />
                                        </div>

                                    </div>

                                </section>
                            </div>
                            : scheduleTab ?

                            // schedule tab start here
                            <div className={`tab-pane active`} id="account-schedule">
                                <section className="account_sec user_screen inspection_tab_content">
                                    {/* <CSVLink filename="Users.csv" className="csv_btn btn_danger " data={convertUsersDataToCsvType(props.usersList)} >Export Csv</CSVLink><br /><br /><br /> */}
                                    <div className="quotes_table_content table-responsive">
                                        <div className="table quotes_table user_table_contnet user_react_table account_table">
                                            <AccountSchedulesEventsTable
                                                {...props}
                                                accountId={props.accountId}
                                                deleteAccountUsers={props.deleteAccountUsers}
                                                accountUsers_rows={props.accountUsers_rows}
                                                isLoading={props.isLoading}
                                                onAccountUsersPageSizeChange={props.onAccountUsersPageSizeChange}
                                                accountUsersList={props.accountUsersList ? props.accountUsersList : []}
                                                editAccountUsersClicked={props.editAccountUsersClicked}
                                                openDeleteModel={props.openDeleteModel}
                                                handleClickDeleteModel={props.handleClickDeleteModel}
                                                makeAccountUserAsOwner={props.makeAccountUserAsOwner} 
                                                getAccountSchedule={props.getAccountSchedule}
                                                scheduleWithAccountId={props.scheduleWithAccountId}
                                                account_id_info={props.account_id_info}
                                                />
                                        </div>

                                    </div>

                                </section>
                            </div>
                            // schedule tab end here
                            : 
                            areaTab ?
                                <div className={'tab-pane active'} id="account-area">
                                    <section className="account_sec user_screen inspection_tab_content">
                                        {/* <CSVLink filename="Users.csv" className="csv_btn btn_danger " data={convertUsersDataToCsvType(props.usersList)} >Export Csv</CSVLink><br /><br /><br /> */}
                                        <div className="quotes_table_content table-responsive">
                                            <div className="table quotes_table user_table_contnet user_react_table account_table accAreaTable" id="area_table_head">
                                                <AccountAreasTable
                                                    {...props}
                                                    onSubmit={props.submitAssociateAccountHolidays}
                                                    setCheckValue={props.setCheckValue}
                                                    checkboxClicked={props.checkboxClicked}
                                                    allAreaCheckboxClicked={props.allAreaCheckboxClicked}
                                                    accountId={props.accountId}
                                                    setURL={setURL}
                                                    setOpenURL={setOpenURL}
                                                    isLoading={props.isLoading}
                                                    onAccountAreasPageSizeChange={props.onAccountAreasPageSizeChange}
                                                    AccountAreasList={props.accountAreas ? props.accountAreas : []}
                                                    editAccountAreasClicked={props.editAccountAreasClicked}
                                                    openDeleteModel={props.openDeleteModel}
                                                    handleClickDeleteModel={props.handleClickDeleteModel} />
                                            </div>

                                        </div>
                                        {/* {props.openAssociateCompanyModal ?
                                 : null} */}

                                    </section>
                                </div> :
                                holidaysTab ?
                                    <div className={'tab-pane active'} id="account-area">
                                        <section className="account_sec user_screen inspection_tab_content">
                                            {/* <CSVLink filename="Users.csv" className="csv_btn btn_danger " data={convertUsersDataToCsvType(props.usersList)} >Export Csv</CSVLink><br /><br /><br /> */}
                                            <div className="quotes_table_content table-responsive">
                                                <div className="table quotes_table user_table_contnet user_react_table account_table">
                                                    <AccountHolidaysTable
                                                        {...props}
                                                        setCheckValue={props.setCheckValue}
                                                        checkboxClicked={props.checkboxClicked}
                                                        accountId={props.accountId}
                                                        setURL={setURL}
                                                        setOpenURL={setOpenURL}
                                                        getAccountHolidays={props.getAccountHolidays}
                                                        // isLoading={props.isLoading}
                                                        // onAccountAreasPageSizeChange={props.onAccountAreasPageSizeChange}
                                                        AccountAreasList={props.accountAreas ? props.accountAreas : []}
                                                        editAccountAreasClicked={props.editAccountAreasClicked}
                                                        openDeleteModel={props.openDeleteModel}
                                                        handleClickDeleteModel={props.handleClickDeleteModel} />
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    : logBookTab ?
                                    <div className={'tab-pane active'} id="account-area">
                                        <section className="account_sec user_screen inspection_tab_content">
                                            {/* <CSVLink filename="Users.csv" className="csv_btn btn_danger " data={convertUsersDataToCsvType(props.usersList)} >Export Csv</CSVLink><br /><br /><br /> */}
                                            <div className="quotes_table_content table-responsive">
                                                <div className="table quotes_table user_table_contnet user_react_table account_table accAreaTable" id="area_table_head">
                                                    <LogBookAreaTable
                                                        {...props}
                                                        onSubmit={props.submitAssociateAccountHolidays}
                                                        setCheckValue={props.setCheckValue}
                                                        checkboxClicked={props.checkboxClicked}
                                                        allLogbookAreaCheckboxClicked={props.allLogbookAreaCheckboxClicked}
                                                        accountId={props.accountId}
                                                        setURL={setURL}
                                                        setOpenURL={setOpenURL}
                                                        isLoading={props.isLoading}
                                                        onAccountAreasPageSizeChange={props.onAccountAreasPageSizeChange}
                                                        AccountAreasList={props.accountAreas ? props.accountAreas : []}
                                                        editAccountAreasClicked={props.editAccountAreasClicked}
                                                        openDeleteModel={props.openDeleteModel}
                                                        handleClickDeleteModel={props.handleClickDeleteModel} />
                                                </div>
    
                                            </div>
                                            {/* {props.openAssociateCompanyModal ?
                                     : null} */}
    
                                        </section>
                                    </div>   : null
                        }

                    </div>
                </div>

            </main>

            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                 <DialogTitle className="closeIcon" id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <div className="d-flex" style={{float: "right"}}>
                        <button type="button" class="close" onClick={props.handleDeleteClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </DialogTitle>
                <DialogTitle className="sureTxt" id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                        <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button data-test="delete_account" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteAccountUser(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openUrl}

                aria-labelledby="form-dialog-title"
            >
                <div className="row p-0 m-0">
                    <div className="col-10"></div>
                    <div className="col-2">
                        <h4 onClick={() => [setURL(''), setOpenURL(false)]} className="float-right text-right mt-2 cursor-pointer"><i className="fa fa-times"></i></h4>
                    </div>
                </div>
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }}></h3>
                </DialogTitle>
                <div className="qr-body-single"><div className="p-5"><a href={genericUrl} target="_blank" className="text_danger" data-test="cleaning_history_url">{genericUrl}</a></div></div>
                <DialogActions>
                    <button style={{ minHeight: '36px', minWidth: '80px', visibility: "hidden" }} className={"float-right btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel">Cancel</button>
                    <button style={{ minHeight: '36px', minWidth: '80px', visibility: "hidden" }} id="delete" type="delete">Okay</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => {

    let name = '', email = '', active_status = '';

    if (isEmpty(state.adminOrSuperAdminReducer.accountUsersList)) {
    } else {
        const value = cloneDeep(state.adminOrSuperAdminReducer.accountUsersList);
        active_status = value && value[0] && value[0].active_status ? transformStringToSelectOptionObject(value[0].active_status) : transformStringToSelectOptionObject('active');
    }

    return { initialValues: {email, name, active_status} }
}

AccountUsers = reduxForm({
    form: 'searchAccountUsersForm',
    validate,
    enableReinitialize: true
})(AccountUsers);

AccountUsers = connect(mapStateToProps)(AccountUsers)

export default AccountUsers;