import React from 'react';
import ReactTable from 'react-table-v6';
import '../../../styles/custom.css';
import { makeFirstLetterCapital, CustomNoDataComponent, capitalAndConvertHyphen } from '../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import ReactTooltip from 'react-tooltip';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { TimeZones, userRoles } from '../../../utility/constants/constants';

var moment = require('moment');

const HolidaysTable = (props) => {

    const data = props.holidaysList ? props.holidaysList.map(holiday => {
        return {
            date: moment.tz(holiday.holiday_date, TimeZones.PST).format('MM-DD-YYYY'),
            holiday: makeFirstLetterCapital(holiday.holiday_reason ? holiday.holiday_reason.trim() : ''),
            recurring: holiday.is_recurring ? "True" : "False",
            actions: <>
                {/* <span className="mr_20 account_edit_icn" data-toggle="modal" data-target="#holidayModal" data-backdrop="static" data-keyboard="false" onClick={() => props.editHolidayClicked(holiday)}><i className="fa fa-pencil" aria-hidden="true"></i></span> */}
                {/* <span className="mr_20 account_edit_icn" onClick={() => props.handleClickDeleteModel(holiday)}><i class="fa fa-trash" aria-hidden="true"></i></span> */}
            </>
        }
    }) : []

    const columns = [
        {
            Header: <><span>Date</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'date', // String-based value accessors!,
            show: props.holidaysList && props.holidaysList.length !== 0,
        }, {
            Header: <><span>Holiday</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'holiday', // String-based value accessors!,
            show: props.holidaysList && props.holidaysList.length !== 0,
        }, {
            Header: <><span>Is Recurring</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'recurring', // String-based value accessors!,
            show: props.holidaysList && props.holidaysList.length !== 0,
        },
        // {
        //     Header: '',
        //     accessor: 'actions',
        //     width: 150,
        //     filterable: false,
        //     sort: false
        //     // show: false
        // }
    ]

    return (
        props.isHolidaysLoading ?
            <div className="loader_btn_block">
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isHolidaysLoading}
                defaultPageSize={10}
                minRows={0}
                onPageSizeChange={props.onUserPageSizeChange}
                showPaginationTop={true}
                showPagination={props.holidaysList && props.holidaysList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(props.isHolidaysLoading, 'No Holidays Found')}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default HolidaysTable;
