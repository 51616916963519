import React, { Component } from 'react';
import NewInspection from '../../../component/InspectionForm/NewInspection/NewInspection';
import * as actions from '../../../../redux/actions/index';
import { RolesOptions } from '../../../../utility/constants/constants'
import { routes, address_attributes } from '../../../../utility/constants/constants';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { decode, encode } from 'base64-arraybuffer';
import getDetailAddress from '../../../../utility/getDetailAddress';
import storage from '../../../../utility/storage';
import { getUser } from '../../../../utility/utility';
import InspectionDuplicateModal from '../../../component/InspectionForm/NewInspection/inspectionDuplicateModal';

const cloneDeep = require('clone-deep');

class NewInspectionFormContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            profileImageName: '',
            base64: null,
            extension: null,
            address_attributes: isEmpty(this.props.user) ? address_attributes : this.props.user.user.address,
            isAddressEmpty: false,
            isZipEmpty: false,
            delete_section_ids: [],
            delete_line_item_ids: [],
            isDataChanged: false,
            cancelClicked: true,
            deleted_account_ids: []
        }
        this.inputOpenFileRef = React.createRef();
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
        // this.onFileChange = this.onFileChange.bind(this);
        // this.handleInputChange = this.handleInputChange.bind(this);
        // this.handleAddressSelect = this.handleAddressSelect.bind(this);
    }

    makeCancelInspectionStateFalse = () => {
        // storage.remove('inspectionFormData');
        // this.props.history.push(routes.INSPECTION_FORMS);
        this.setState({
            delete_section_ids: [],
            delete_line_item_ids: [],
            cancelClicked: false
        })
    }

    // onFileChange = (e) => {

    //     let reader = new FileReader();
    //     let file = e.target.files[0];
    //     reader.onloadend = () => {
    //         this.setState({
    //             extension: file.name.split('.').pop().toLowerCase(),
    //             profileImageName: file.name,
    //             base64: reader.result,
    //         })
    //     }
    //     reader.readAsDataURL(file)
    //     // reader.readAsArrayBuffer(file)
    // }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }

    // handleAddressChange = address => {
    //     let addressAttributes = { ...this.state.address_attributes };
    //     addressAttributes.formatted_address = address;

    //     this.setState({ address_attributes: addressAttributes, isAddressEmpty: false });
    // };

    // async handleAddressSelect(address) {
    //     const addressFields = await getDetailAddress(address);
    //     this.setState({
    //         address_attributes: addressFields,
    //         isAddressEmpty: false
    //     })
    // };

    // handleInputChange = (e) => {
    //     console.log(this.state);
    //     if (e.target.name === 'zip') {
    //         let addressFields = cloneDeep(this.state.address_attributes);
    //         addressFields.zip = e.target.value;
    //         this.setState({
    //             address_attributes: addressFields,
    //             isZipEmpty: false
    //         })
    //     } else {
    //         this.setState({
    //             about_me: e.target.value,
    //         })
    //     }
    // }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user !== prevProps.user) {

            this.setState({
                // address_attributes: this.props.user.user.address,
                profileImageName: '',
                base64: null,
                extension: null,
                // delete_line_item_ids: [],
                // delete_section_ids: []
            })
        }
        if (prevProps.formValues.inspection_form && this.props.formValues.inspection_form !== prevProps.formValues.inspection_form) {
            if(this.props.match.params.inspectionFormId) {
                if(this.props.formValues.inspection_form && this.props.formValues.inspection_form.account_ids && this.props.formValues.inspection_form.account_ids != prevProps.formValues.inspection_form.account_ids) {
                    this.objectsAreSame(this.props.formValues.inspection_form.account_ids, prevProps.formValues.inspection_form.account_ids)
                }
            } else {
                this.setState({
                    isDataChanged: true
                })
            }
        }
    }
    objectsAreSame(x, y) {
                this.setState({
                    isDataChanged: false
                })
                if(x === null || y === null) {
                    this.setState({
                        isDataChanged: true
                    })
                    return
                }
                if(x === undefined || y === undefined) {
                    this.setState({
                        isDataChanged: true
                    })
                    return
                }
                if(x.length !== y.length) {
                    this.setState({
                        isDataChanged: true
                    })
                    return
                }
                for(var propertyName in x) {
                    if(x[propertyName] && y[propertyName]) {
                        if(x[propertyName].label == "Job-wide" && y[propertyName].label == "Job-wide") {
                            this.setState({
                                isDataChanged: false
                            })
                            break;
                        }
                        if(x[propertyName].value != y[propertyName].value) {
                            this.setState({
                                isDataChanged: true
                            })
                            break;
                        }
                    } else {
                        this.setState({
                            isDataChanged: true
                        })
                    }
                }
                
        }

    componentDidMount() {
        // if (!this.props.ratingList) {
            this.props.getRatings({hide: false});
        // }

        // if (!this.props.categoriesList) {
            this.props.getCategories({hide: false});
        // }
        // if(!this.props.criteries){
            this.props.getCriteriaList({hide: false});
        // }

        if (isEmpty(this.props.inspectionForm) && this.props.match.params.inspectionFormId) {
            this.props.getEditInspectionForm(this.props.match.params.inspectionFormId)
            .then(res => {
                this.getModeAccounts(res.value.inspection_form.mode)
            })
        } else {
            if(this.props.inspectionForm.inspection_form) {
                this.getModeAccounts(this.props.inspectionForm.inspection_form.mode)
            }
        }
        // const userData = storage.get('inspectionFormData');
        // console.log(this.props.inspectionForm)
        // if(userData && userData.inspection_form && userData.inspection_form.mode) {
        //     this.getModeAccounts(userData.inspection_form.mode)
        // }
    }

    componentWillUnmount() {
        this.props.resetObjects();
    }

    getModeAccounts = (mode) => {
        const selectedMode = cloneDeep(mode);
        this.props.getModeBasedAccounts(selectedMode);
    }

    addNewInspectionForm = (values) => {
    
        // if (values.inspection_form.account_ids.value) {
        let InspectionForm = cloneDeep(values);
        // delete InspectionForm.inspection_form.department
        // let InspectionForm = cloneDeep(values);
       
        let ins = {}
        ins['inspection_form'] = {}
        ins.inspection_form['account_ids'] = InspectionForm.inspection_form.account_ids ? InspectionForm.inspection_form.account_ids : [];
        ins.inspection_form['mode'] = InspectionForm.inspection_form.mode;
        ins.inspection_form['name'] = InspectionForm.inspection_form.name;
        ins.inspection_form['private'] = InspectionForm.inspection_form.private;
        ins.inspection_form['category_id'] = InspectionForm.inspection_form.category_id;
        ins.inspection_form['sections_attributes'] = InspectionForm.inspection_form.sections_attributes;
        ins.inspection_form['form_category'] = InspectionForm.inspection_form.form_category;
        // ins.inspection_form['department_id'] = InspectionForm.inspection_form.department_id;
        ins.inspection_form['criteria_id'] = InspectionForm.inspection_form.criteria_id;
        ins.inspection_form['inventory'] = InspectionForm.inspection_form.inventory;
        
        InspectionForm.inspection_form.sections_attributes.map((sec, j) => {
            sec['sort'] = j;
            sec.line_items_attributes.map((line, i) => {
                line['sort'] = i;
                if(!line.weight) {
                    InspectionForm.inspection_form.sections_attributes[j].line_items_attributes[i].weight = "0"
                }
            })
        })
        InspectionForm.inspection_form['category_id'] = parseInt(InspectionForm.inspection_form.category_id);
            if(this.state.isDataChanged) {
                if (this.props.match.params.inspectionFormId) {
                    if(this.props.inspectionForm.inspection_form.account_ids.length > 0 && this.props.inspectionForm.inspection_form.account_ids[0].label == "Job-wide" && this.props.formValues.inspection_form.account_ids[0].label !== "Job-wide") {
                        let i = 0;
                        let entry1;
                        if(this.props.accountsList) {
                            while (i < this.props.accountsList.length) {
                                entry1 = this.props.accountsList[i];
                                if (this.props.formValues.inspection_form.account_ids.some(function(entry2) { return entry1.name === entry2.label; })) {
                                    // Found, progress to next
                                    ++i;
                                } else {
                                    // Not found, store id and splice
                                    this.state.deleted_account_ids.push(this.props.accountsList[i].id)
                                    this.props.accountsList.splice(i, 1);
                                }
                            }
                        }
                    } else {
                        let i = 0;
                        let entry1;
                        if(this.props.inspectionForm.inspection_form.account_ids) {
                            while (i < this.props.inspectionForm.inspection_form.account_ids.length) {
                                entry1 = this.props.inspectionForm.inspection_form.account_ids[i];
                                if (this.props.formValues.inspection_form.account_ids.some(function(entry2) { return entry1.label === entry2.label; })) {
                                    // Found, progress to next
                                    ++i;
                                } else {
                                    // Not found, store id and splice
                                    this.state.deleted_account_ids.push(this.props.inspectionForm.inspection_form.account_ids[i].value)
                                    this.props.inspectionForm.inspection_form.account_ids.splice(i, 1);
                                }
                            }
                        }
                    }
                InspectionForm.inspection_form['remove_account_ids']= this.state.deleted_account_ids
                }
                
            if (values.inspection_form.account_ids) {
                if (InspectionForm.inspection_form.account_ids[0].label === 'Job-wide') {
                    InspectionForm.inspection_form.account_ids = [];
                    delete InspectionForm.inspection_form.accounts;
                    this.props.accountsList.map(a =>  {
                        InspectionForm.inspection_form.account_ids.push(a.id)
                    });
                }
                else {
                    delete InspectionForm.inspection_form.accounts;
                    InspectionForm.inspection_form.account_ids = values.inspection_form.account_ids.map(a => (
                        a.value
                    ));
                }
            }
        } else {
            delete InspectionForm.inspection_form.account_ids;
            delete InspectionForm.inspection_form.accounts;
        }
        InspectionForm.inspection_form.sections_attributes.map(section => {
            section.line_items_attributes.map(lineItem => {
                lineItem.rating_type_id = lineItem.rating_type_id.value
            })
        })
        // }
        if (this.props.match.params.inspectionFormId) {
            InspectionForm.inspection_form['delete_section_ids'] = this.state.delete_section_ids;
            InspectionForm.inspection_form['delete_line_item_ids'] = this.state.delete_line_item_ids;
            let ins = {}
            ins['inspection_form'] = {}
            ins.inspection_form['id'] = InspectionForm.inspection_form.id;
            if(InspectionForm.inspection_form.account_ids) {
                ins.inspection_form['account_ids'] = InspectionForm.inspection_form.account_ids;
            }
            ins.inspection_form['delete_section_ids'] = InspectionForm.inspection_form.delete_section_ids;
            ins.inspection_form['delete_line_item_ids'] = InspectionForm.inspection_form.delete_line_item_ids;
            ins.inspection_form['mode'] = InspectionForm.inspection_form.mode;
            ins.inspection_form['name'] = InspectionForm.inspection_form.name;
            ins.inspection_form['private'] = InspectionForm.inspection_form.private;
            ins.inspection_form['tag_follow_up'] = InspectionForm.inspection_form.tag_follow_up;
            if(JSON.stringify(this.props.inspectionForm.inspection_form.inventory) != JSON.stringify(this.props.formValues.inspection_form.inventory)) {
                ins.inspection_form['inventory'] = InspectionForm.inspection_form.inventory;
                }
            if(JSON.stringify(this.props.inspectionForm.inspection_form.category_id) != JSON.stringify(this.props.formValues.inspection_form.category_id)) {
            ins.inspection_form['category_id'] = InspectionForm.inspection_form.category_id;
            }
            if(JSON.stringify(this.props.inspectionForm.inspection_form.criteria_id) != JSON.stringify(this.props.formValues.inspection_form.criteria_id)) {
                ins.inspection_form['criteria_id'] = InspectionForm.inspection_form.criteria_id;
                }
            if(JSON.stringify(this.props.inspectionForm.inspection_form.sections_attributes) != JSON.stringify(this.props.formValues.inspection_form.sections_attributes)) {
                ins.inspection_form['sections_attributes'] = InspectionForm.inspection_form.sections_attributes;
            }
            ins.inspection_form['form_category'] = InspectionForm.inspection_form.form_category;
            // if(JSON.stringify(this.props.inspectionForm.inspection_form.department_id) != JSON.stringify(this.props.formValues.inspection_form.department_id)) {
            //     ins.inspection_form['department_id'] = InspectionForm.inspection_form.department_id;
            // }
            if(InspectionForm.inspection_form.remove_account_ids) {
                ins.inspection_form['remove_account_ids'] = InspectionForm.inspection_form.remove_account_ids;
            }    
            this.props.updateInspectionForm(ins);
        } else {
            // values.rating_type['category'] = "percentage";
            this.props.addNewInspectionForm(InspectionForm, { isDublicate: false });
        }

    }

    deleteInspectionFormSection = (id) => {
        this.setState(prevState => ({
            delete_section_ids: [...prevState.delete_section_ids, id]
        }))
    }

    deleteInspectionFormLineItem = (id) => {
        this.setState(prevState => ({
            delete_line_item_ids: [...prevState.delete_line_item_ids, id]
        }))
    }

    createDublicateInspectionForm = (val) => {
        let InspectionForm = cloneDeep(this.props.formValues);
        InspectionForm.inspection_form.sections_attributes.map((sec, i) => {
            sec['sort'] = i;
            sec.line_items_attributes.map((line, i) => {
                line['sort'] = i;
            })
        })
        InspectionForm.inspection_form['category_id'] = parseInt(InspectionForm.inspection_form.category_id);
        if (this.props.inspectionForm.inspection_form.account_ids.length > 0) {
            if (InspectionForm.inspection_form.account_ids[0].label === 'Job-wide') {
                InspectionForm.inspection_form.account_ids = this.props.accountsList.map(a => a.id);
            }
            else {
                InspectionForm.inspection_form.account_ids = this.props.inspectionForm.inspection_form.account_ids.map(a => (
                    a.value
                ));
            }
        }

        InspectionForm.inspection_form.sections_attributes.map(section => {
            section.line_items_attributes.map(lineItem => {
                lineItem.rating_type_id = lineItem.rating_type_id.value
            })
        })
        InspectionForm.inspection_form.name = val.inspection_form_name
        
        if(InspectionForm.inspection_form?.criteriaId){
            InspectionForm.inspection_form.criteria_id = InspectionForm.inspection_form.criteriaId;
        }
        delete InspectionForm.inspection_form.id;
        delete InspectionForm.inspection_form.updated_at;
        delete InspectionForm.inspection_form.created_at;
        delete InspectionForm.inspection_form.active_status;
        delete InspectionForm.inspection_form.success;
        delete InspectionForm.inspection_form.hidden_value;
        delete InspectionForm.inspection_form.hidden_status;
        delete InspectionForm.inspection_form.category;
        delete InspectionForm.inspection_form.accounts;
        delete InspectionForm.inspection_form.criteriaId;
        // delete InspectionForm.inspection_form.department;
        delete InspectionForm.inspection_form.criteria;
        delete InspectionForm.inspection_form.line_items_attributes_without_section;
        InspectionForm.inspection_form.sections_attributes.map(sec => {
            delete sec.id;
            delete sec.active_status
            delete sec.created_at
            delete sec.updated_at
            delete sec.n_a
            delete sec.hidden_value

            sec.line_items_attributes.map(line => {
                delete line.id;
                delete line.active_status
                delete line.created_at
                delete line.updated_at
                delete line.media_url
                delete line.rating_option_id
                delete line.rating_options_attributes
                delete line.score
                delete line.comment
                delete line.customer_rep_available
                delete line.n_a
                delete line.rating_option_name
                delete line.section_id
                delete line.inspection_form_id
                delete line.rating_option
                delete line.description
                delete line.attachments_attributes
                delete line.quote_created_for_inspection
                delete line.rating_type
            })
        })
        this.props.addNewInspectionForm(InspectionForm, { isDublicate: true });
    }

    render() {

        if (this.props.isInspectionFromLoading || this.props.isCategories || this.props.isRating) {

            return (
                <main className="app-content wraper_content edit_inspection settings">
                    <section className="appcontent_Inner">
                        <div className="row">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div>
                    </section>
                </main>
            )
        } else {
            return (
                <>
                <NewInspection
                    isDataChanged={this.state.isDataChanged}
                    history={this.props.history}
                    s3Url={isEmpty(this.props.user) ? '' : this.props.user.user.photo_urls.original}
                    accountsList={this.props.accountsList ? this.props.accountsList : []}
                    ratingList={this.props.ratingList ? this.props.ratingList : []}
                    onSubmit={this.addNewInspectionForm}
                    isEdit={this.props.match.params.inspectionFormId ? true : false}
                    reference={this.inputOpenFileRef}
                    choosePhotoClicked={this.choosePhotoClicked}
                    base64={this.state.base64}
                    categoriesList={this.props.categoriesList ? this.props.categoriesList : []}
                    onFileChange={this.onFileChange}
                    deleteInspectionFormSection={this.deleteInspectionFormSection}
                    deleteInspectionFormLineItem={this.deleteInspectionFormLineItem}
                    makeCancelInspectionStateFalse={this.makeCancelInspectionStateFalse}
                    reorderDragAndDrop={this.props.reorderDragAndDrop}
                    isLoading={this.props.isLoading}
                    isAddressEmpty={this.state.isAddressEmpty}
                    isZipEmpty={this.state.isZipEmpty}
                    handleAddressChange={this.handleAddressChange}
                    handleInputChange={this.handleInputChange}
                    handleAddressSelect={this.handleAddressSelect}
                    formValues={this.props.formValues}
                    inspectionForm={this.props.inspectionForm}
                    cancelClicked={this.state.cancelClicked}
                    createDublicateInspectionForm={this.createDublicateInspectionForm}
                    getModeAccounts={this.getModeAccounts}
                    onInspectionDuplicateModalClicked={this.props.onInspectionDuplicateModalClicked}
                    criteries={this.props.criteries ? this.props.criteries : []}
                />
                {this.props.inspectionDuplicateModal ? <InspectionDuplicateModal {...this.props} onSubmit={this.createDublicateInspectionForm} /> : ''}
                </>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.adminOrSuperAdminReducer.requestor,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        ratingList: state.adminOrSuperAdminReducer.ratingList,
        formValues: getFormValues("newInspectionForm")(state) || {},
        accountsList: state.adminOrSuperAdminReducer.modeAccountsList,
        categoriesList: state.adminOrSuperAdminReducer.categoriesList,
        inspectionForm: state.adminOrSuperAdminReducer.inspectionForm,
        isInspectionFromLoading: state.adminOrSuperAdminReducer.isInspectionFromLoading,
        isCategories: state.adminOrSuperAdminReducer.isCategories,
        isRating: state.adminOrSuperAdminReducer.isRating,
        isAccountLoading: state.adminOrSuperAdminReducer.isAccountLoading,
        inspectionDuplicateModal: state.adminOrSuperAdminReducer.inspectionDuplicateModal,
        criteries: state.adminOrSuperAdminReducer.criteries,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addNewInspectionForm: (newInspectionForm, isDublicate) => dispatch(actions.addNewInspectionForm(newInspectionForm, isDublicate)),
        deleteInspectionFormSection: (id) => dispatch(actions.deleteInspectionFormSection(id)),
        deleteInspectionFormLineItem: (id) => dispatch(actions.deleteInspectionFormLineItem(id)),
        updateInspectionForm: (inspectionForm) => dispatch(actions.updateInspectionForm(inspectionForm)),
        resetObjects: () => dispatch(actions.resetObjects()),
        getRatings: (data) => dispatch(actions.getRatings(data)),
        getCategories: (data) => dispatch(actions.getCategories(data)),
        // getDropDownAccounts: () => dispatch(actions.getDropDownAccounts()),
        getModeBasedAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
        getEditInspectionForm: (id) => dispatch(actions.getEditInspectionForm(id)),
        reorderDragAndDrop: (inspectionForm) => dispatch(actions.reorderDragAndDrop(inspectionForm)),
        onInspectionDuplicateModalClicked: () => dispatch(actions.onInspectionDuplicateModalClicked()),
        getCriteriaList: (data) => dispatch(actions.getCriteriaList(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewInspectionFormContainer);