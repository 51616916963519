import React, { Component } from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import { closeModel } from '../../../../utility/utility';
import { routes } from '../../../../utility/constants/constants';
import { reset } from 'redux-form';
import isEmpty from '../../../../utility/IsEmptyValidator';
import $ from "jquery";
import { validateAccountUsers } from '../../../../utility/validator/submit';
import AddAreaForm from '../../../component/Accounts/AccountsArea/AccountAreaForm/EditAreaForm';
import { TimeZones } from '../../../../utility/constants/constants';
import errorHandler from '../../../../utility/errorHandler/errorHandler';

const cloneDeep = require('clone-deep');

class EditArea extends Component {
  debugger

  constructor(props) {
    super(props);

    this.state = {
      currentAssosciatedUserEmail: '',
      areaErrorMessage: '',
      areaDayErrorMessage: "",
      timeSlots: ["12:00 AM"],
      remove_timeslot_ids: [],
      removed_deficiency_ids: [],
      // selectedHolidaysIds: this.props.accountArea ? this.props.accountArea.holidays.map(h => h.id) : []
    }
    this.setTimeSlot = this.setTimeSlot.bind(this);
    this.resetDayError = this.resetDayError.bind(this);
  }
  setTimeSlot = (timeSlots, id) => {
    this.setState({ timeSlots })
    if (id) this.setState({ remove_timeslot_ids: [...this.state.remove_timeslot_ids, id] })
  };

  cancelClicked = () => {
    this.props.clearArea();
  }
  componentDidMount() {
    this.props.getAccount(this.props.match.params.accountId);
    if (isEmpty(this.props.areaTypes)) {
      this.props.getAreaTypes();
    }
    if (isEmpty(this.props.floorList)) {
      this.props.getFloors();
    }
    if (isEmpty(this.props.deficiencies)) {
      this.props.getAllDeficiencies();
    }
    // if (!this.props.holidaysList) {
    //   this.props.getHolidaysList();
    // }
    this.props.getArea(this.props.match.params.accountId, this.props.match.params.areaId)
  }

  componentDidUpdate = (prevProps, prevState) => {
    // if (prevProps.accountArea !== this.props.accountArea) {
    //   this.setState({
    //     selectedHolidaysIds: this.props.accountArea ? this.props.accountArea.holidays.map(h => h.id) : []
    //   })
    // }
  }

  handleSubmit = (values, a, b) => {
    var isValid = true;
    let tempForm = cloneDeep(values);
    let times = [];
    let days = [];
    let duplicateTime = false;
    // if (tempForm['holiday_ids']) {
    //   tempForm['holiday_ids'] = tempForm['holiday_ids'].map((a, i) => {
    //     return a.value
    //   });
    // }

    // tempForm['holiday_ids'] = this.state.selectedHolidaysIds;
    if (!tempForm.area?.name?.trim()) {
      errorHandler('Area Name is required')
      return false
    }
    if (!tempForm.area?.floor_id) {
      errorHandler('Floor Number is required')
      return false
    }
    if (!tempForm.area?.area_type_id) {
      errorHandler('Area Type is required')
      return false
    }
    if (tempForm.area.days && tempForm.area.days.length) {
      tempForm.area.days.map((day, index) => {
        if (day) days.push(index)
      });
      if (days.length) tempForm.area.days = days;
      else {
        isValid = false;
        this.setState({ areaDayErrorMessage: "Please select At least one Day" });
      }
    } else {
      isValid = false;
      this.setState({ areaDayErrorMessage: "Please select At least one Day" });
    }
    if (this.state.remove_timeslot_ids.length) {
      this.state.remove_timeslot_ids.map(id => {
        let timeIndex = tempForm.area.timeslots_attributes.findIndex(e => e.id === id)
        if(timeIndex >= 0) tempForm.area.timeslots_attributes.splice(timeIndex, 1);
      return timeIndex
      });
      delete tempForm.area.timeslots;
      tempForm.area.remove_timeslot_ids = this.state.remove_timeslot_ids;
    }
    if (tempForm.area.timeslots_attributes && tempForm.area.timeslots_attributes.length) {
      tempForm.area.timeslots_attributes.map(e => {
        if (times.includes(e.start_time)) duplicateTime = true;
        else times.push(e.start_time)
      });
    } else {
      delete tempForm.area.timeslots_attributes
    }
    if (duplicateTime) {
      isValid = false;
      this.setState({ areaErrorMessage: "Duplicate time not allowed" })
      setTimeout(() => this.setState({ areaErrorMessage: "" }), 2000)
    }
    else {
      if (tempForm.area.timeslots_attributes) {
        tempForm.area.timeslots_attributes.map(e => {
          const date = moment().format("MM/DD/YYYY") + " " + e.start_time;
          const hours = this.props.account.user.timezone === TimeZones.PST ? 7 : 5;
          e.start_time_utc = moment(moment().format("MM-DD-YYYY") + " " + e.start_time, "MM-DD-YYYY HH:mm A").add(hours, "hours").format("hh:mm A")
          // e.start_time_utc = moment.utc(moment(date)).format("hh:mm A");
          // e.start_time_utc = moment(moment().format("MM-DD-YYYY") + " " + e.start_time, "MM-DD-YYYY HH:mm A").add(7, "hours").format("hh:mm A")
        });
      }
      if (isValid) {
        debugger
        let schedule_date = tempForm.area.schedule_at;
        tempForm.area.schedule_at_utc = moment.utc(tempForm.area.schedule_at).format("MM-DD-YYYY");
        tempForm.area.schedule_at = moment(schedule_date).format("MM-DD-YYYY");
        if(tempForm.area.deficiency_ids && tempForm.area.deficiency_ids.length > 0) {
          const defIds = [];
          tempForm.area.deficiency_ids && tempForm.area.deficiency_ids.length > 0 && tempForm.area.deficiency_ids.map((t) => {
            defIds.push(parseInt(t.value))
          })
          // defIds.push(parseInt(1)); //Commented this for getting additional Others area inside the deficiency in production
          tempForm.area.deficiency_ids = defIds;
        }
        var i = 0;
        var entry1;
        if(this.props.accountArea.deficiencies) {
            if(tempForm.area.deficiency_ids) {
                while (i < this.props.accountArea.deficiencies.length) {
                    entry1 = this.props.accountArea.deficiencies[i];
                    if (tempForm.area.deficiency_ids.some(function(entry2) { return entry1.id === entry2; })) {
                        ++i;
                    } else {
                        this.state.removed_deficiency_ids.push(this.props.accountArea.deficiencies[i].id)
                        this.props.accountArea.deficiencies.splice(i, 1);
                    }
                }
            }
        }
        if(tempForm.area.deficiency_ids) {
            tempForm.area['removed_deficiency_ids'] = this.state.removed_deficiency_ids;
        } else {
            tempForm.area['removed_deficiency_ids'] = this.props.accountArea.deficiencies.map((a, i) => {
                return a.id
            });
        }
        this.props.updateArea(tempForm, this.props.match.params.accountId, this.props.accountArea.id)
      }
    }
  };


  componentWillUnmount() {
    //this.props.resetObjects();
  }
  resetDayError() {
    this.setState({ areaDayErrorMessage: "" })
  }

  holidaySelected = (id) => {
    let selectedIds = cloneDeep(this.state.selectedHolidaysIds);

    let searchIndex = this.state.selectedHolidaysIds.findIndex(function (s) {
      return s === id
    });

    if (searchIndex === -1) {
      this.setState({
        selectedHolidaysIds: [...selectedIds, id]
      })
    } else {
      selectedIds.splice(searchIndex, 1);
      this.setState({
        selectedHolidaysIds: selectedIds
      })

    }
  }

  setCheckValue = (id) => {
    let searchIndex = this.state.selectedHolidaysIds.findIndex(function (s) {
      return s === id
    });
    if (searchIndex > -1) {
      return true
    } else {
      return false
    }
  }

  selectAllClicked = (e) => {
    if (e.target.checked) {
      let selectedIds = []
      this.props.holidaysList.map(h => {
        return selectedIds.push(h.id)
      })
      this.setState({
        selectedHolidaysIds: selectedIds,
        selectAll: true,
        selectNone: false,
      })
    } else {
      this.setState({
        selectedHolidaysIds: [],
        selectAll: false
      })
    }
  }

  selectNoneClicked = (e) => {
    if (e.target.checked) {
      this.setState({
        selectedHolidaysIds: [],
        selectNone: true,
        selectAll: false
      })
    } else {
      this.setState({
        selectNone: false
      })
    }
  }

  render() {

    return (
      <AddAreaForm
        {...this.props}
        {...this.state}
        setCheckValue={this.setCheckValue}
        holidaySelected={this.holidaySelected}
        onSubmit={this.handleSubmit}
        timeSlots={this.state.timeSlots}
        setTimeSlot={this.setTimeSlot}
        resetDayError={this.resetDayError}
        selectAllClicked={this.selectAllClicked}
        selectNoneClicked={this.selectNoneClicked}
        areaDayErrorMessage={this.state.areaDayErrorMessage}
        areaErrorMessage={this.state.areaErrorMessage}
        cancelClicked={this.cancelClicked}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    account: state.adminOrSuperAdminReducer.account,
    accountArea: state.adminOrSuperAdminReducer.accountArea,
    areaTypes: state.adminOrSuperAdminReducer.areaTypes,
    holidaysList: state.adminOrSuperAdminReducer.holidaysList,
    floorList: state.adminOrSuperAdminReducer.floorList,
    isHolidaysLoading: state.adminOrSuperAdminReducer.isHolidaysLoading,
    deficiencies: state.adminOrSuperAdminReducer.deficiencies,

  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getHolidaysList: () => dispatch(actions.getHolidaysList()),
    getAreaTypes: () => dispatch(actions.getAreaTypes()),
    getFloors: () => dispatch(actions.getFloors()),
    getAccount: (id) => dispatch(actions.getEditAccount(id)),
    getArea: (id, area_id) => dispatch(actions.getArea({ id, area_id })),
    updateArea: (form, id, area_id) => dispatch(actions.updateArea({ body: form, area_id, id })),
    clearArea: () => dispatch(actions.clearArea()),
    getAllDeficiencies: () => dispatch(actions.getAllDeficiencies()),

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(EditArea);