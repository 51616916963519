import React, { Component } from 'react';
import errorHandler from '../../../../utility/errorHandler/errorHandler';
import moment from "moment";
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/index';
import isEmpty from '../../../../utility/IsEmptyValidator';
import EditLogBookAreaForm from '../../../component/Accounts/LogBook Area/LogBookAreaForm/EditLogBookAreaForm';
import { TimeZones } from '../../../../utility/constants/constants';

const cloneDeep = require('clone-deep');

class EditLogBookAreaContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      areaErrorMessage: '',
      areaDayErrorMessage: "",
      timeSlots: ["12:00 AM"],
      remove_timeslot_ids: [],
    }
    this.setTimeSlot = this.setTimeSlot.bind(this);
    this.resetDayError = this.resetDayError.bind(this);
  }
  setTimeSlot = (timeSlots, id) => {
    this.setState({ timeSlots })
    if (id) this.setState({ remove_timeslot_ids: [...this.state.remove_timeslot_ids, id] })
  };

  cancelClicked = () => {
    this.props.clearLogbookArea();
  }
  componentDidMount() {
    this.props.getAccount(this.props.match.params.accountId);
    if (isEmpty(this.props.areaTypes)) {
      this.props.getAreaTypes();
    }
    if (isEmpty(this.props.floorList)) {
      this.props.getFloors();
    }
    this.props.getLogbook(this.props.match.params.accountId, this.props.match.params.areaId)
  }

  componentDidUpdate = (prevProps, prevState) => {
    // if (prevProps.accountArea !== this.props.accountArea) {
    //   this.setState({
    //     selectedHolidaysIds: this.props.accountArea ? this.props.accountArea.holidays.map(h => h.id) : []
    //   })
    // }
  }

  handleSubmit = (values, a, b) => {
    if (values) {

      if (values.logbook) {

        if (values.logbook.name == '') {
          errorHandler('Logbook area name required');
          return false;
        }
        if (values.logbook.floor_id == '') {
          errorHandler('Floor no required');
          return false;
        }
        if (values.logbook.area_type_id == '') {
          errorHandler('Area type required');
          return false;
        }

      }

    }

    var isValid = true;
    let tempForm = cloneDeep(values);
    let times = [];
    let days = [];
    let duplicateTime = false;
    
    if (tempForm.logbook.days && tempForm.logbook.days.length) {
      tempForm.logbook.days.map((day, index) => {
        if (day) days.push(index)
      });
      if (days.length) tempForm.logbook.days = days;
      else {
        isValid = false;
        this.setState({ areaDayErrorMessage: "Please select At least one Day" });
      }
    } else {
      isValid = false;
      this.setState({ areaDayErrorMessage: "Please select At least one Day" });
    }
    if (this.state.remove_timeslot_ids.length) {
      this.state.remove_timeslot_ids.map(id => {
        let timeIndex = tempForm.logbook.timeslots_attributes.findIndex(e => e.id === id)
        if (timeIndex >= 0) tempForm.logbook.timeslots_attributes.splice(timeIndex, 1);
        return timeIndex
      });
      delete tempForm.logbook.timeslots;
      tempForm.logbook.remove_timeslot_ids = this.state.remove_timeslot_ids;
    }
    if (tempForm.logbook.timeslots_attributes && tempForm.logbook.timeslots_attributes.length) {
      tempForm.logbook.timeslots_attributes.map(e => {
        if (times.includes(e.start_time)) duplicateTime = true;
        else times.push(e.start_time)
      });
    } else {
      delete tempForm.logbook.timeslots_attributes
    }
    if (duplicateTime) {
      isValid = false;
      this.setState({ areaErrorMessage: "Duplicate time not allowed" })
      setTimeout(() => this.setState({ areaErrorMessage: "" }), 2000)
    }
    else {
      if (tempForm.logbook.timeslots_attributes) {
        tempForm.logbook.timeslots_attributes.map(e => {
          const date = moment().format("MM/DD/YYYY") + " " + e.start_time;
          const hours = this.props.account.user.timezone === TimeZones.PST ? 7 : 5;
          e.start_time_utc = moment(moment().format("MM-DD-YYYY") + " " + e.start_time, "MM-DD-YYYY HH:mm A").add(hours, "hours").format("hh:mm A")
          // e.start_time_utc = moment.utc(moment(date)).format("hh:mm A");
        });
      }
      if (isValid) {
        
        let schedule_date = tempForm.logbook.schedule_at;
        // tempForm.logbook.schedule_at_utc = moment.utc(tempForm.logbook.schedule_at).format("MM-DD-YYYY");
        tempForm.logbook.schedule_at = moment(schedule_date).format("MM-DD-YYYY");
        if(!tempForm.logbook.critical){
           tempForm.logbook.critical = false
          if(tempForm.logbook.labelsId){
            tempForm.logbook.removelabelsId = [this.props.accountArea.labelsId.value];
          }
          delete tempForm.logbook.labelsId
        }
        if(tempForm.logbook.labelsId){
          if ((this.props.accountArea.labelsId.value == tempForm.logbook.labelsId.value) || (this.props.accountArea.labelsId == '')) {
            tempForm.logbook.labelsId = [tempForm.logbook.labelsId.value];
          } else {
            tempForm.logbook.removelabelsId = [this.props.accountArea.labelsId.value];
            tempForm.logbook.labelsId = [tempForm.logbook.labelsId.value];
          }
        }
        this.props.updateLogbook(this.props.match.params.accountId, this.props.accountArea.id, tempForm)
      }
    }
  };


  componentWillUnmount() {
    //this.props.resetObjects();
  }
  resetDayError() {
    this.setState({ areaDayErrorMessage: "" })
  }

  holidaySelected = (id) => {
    let selectedIds = cloneDeep(this.state.selectedHolidaysIds);

    let searchIndex = this.state.selectedHolidaysIds.findIndex(function (s) {
      return s === id
    });

    if (searchIndex === -1) {
      this.setState({
        selectedHolidaysIds: [...selectedIds, id]
      })
    } else {
      selectedIds.splice(searchIndex, 1);
      this.setState({
        selectedHolidaysIds: selectedIds
      })

    }
  }

  setCheckValue = (id) => {
    let searchIndex = this.state.selectedHolidaysIds.findIndex(function (s) {
      return s === id
    });
    if (searchIndex > -1) {
      return true
    } else {
      return false
    }
  }

  selectAllClicked = (e) => {
    if (e.target.checked) {
      let selectedIds = []
      this.props.holidaysList.map(h => {
        return selectedIds.push(h.id)
      })
      this.setState({
        selectedHolidaysIds: selectedIds,
        selectAll: true,
        selectNone: false,
      })
    } else {
      this.setState({
        selectedHolidaysIds: [],
        selectAll: false
      })
    }
  }

  selectNoneClicked = (e) => {
    if (e.target.checked) {
      this.setState({
        selectedHolidaysIds: [],
        selectNone: true,
        selectAll: false
      })
    } else {
      this.setState({
        selectNone: false
      })
    }
  }

  render() {

    return (
      <EditLogBookAreaForm
        {...this.props}
        {...this.state}
        setCheckValue={this.setCheckValue}
        holidaySelected={this.holidaySelected}
        onSubmit={this.handleSubmit}
        timeSlots={this.state.timeSlots}
        setTimeSlot={this.setTimeSlot}
        resetDayError={this.resetDayError}
        selectAllClicked={this.selectAllClicked}
        selectNoneClicked={this.selectNoneClicked}
        areaDayErrorMessage={this.state.areaDayErrorMessage}
        areaErrorMessage={this.state.areaErrorMessage}
        cancelClicked={this.cancelClicked}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    account: state.adminOrSuperAdminReducer.account,
    accountArea: state.adminOrSuperAdminReducer.accountArea,
    areaTypes: state.adminOrSuperAdminReducer.areaTypes,
    holidaysList: state.adminOrSuperAdminReducer.holidaysList,
    floorList: state.adminOrSuperAdminReducer.floorList,
    isHolidaysLoading: state.adminOrSuperAdminReducer.isHolidaysLoading,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLogbook: (id, area_id, form) => dispatch(actions.updateLogbook({ id, area_id, form })),
    getLogbook: (id, area_id) => dispatch(actions.getLogbook({ id, area_id })),
    clearLogbookArea: () => dispatch(actions.clearLogbookArea()),
    getAreaTypes: () => dispatch(actions.getAreaTypes()),
    getFloors: () => dispatch(actions.getFloors()),
    getAccount: (id) => dispatch(actions.getEditAccount(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLogBookAreaContainer);