import React, { useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../styles/custom.css';
import { CustomNoDataComponent, makeFirstLetterCapital, convertDateTimezone, convertDateTimezonePopupEvents, getWeekStartAndEndDay, GetFormatedDate } from '../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import 'react-table-v6/react-table.css';
import { TimeZones, currentData } from '../../../utility/constants/constants';
import { Link } from 'react-router-dom';
import * as actions from '../../../redux/actions/index';
import { useDispatch, useSelector } from 'react-redux';
import { ClapSpinner } from 'react-spinners-kit';

var moment = require('moment');

const InspectionSchedules = (props) => {

    
    const data = props.getScheduleDashboardData && props.getScheduleDashboardData.map(event => {
        return {
            title: event.schedule.title,
            account_name: event.account.name,
            date: convertDateTimezonePopupEvents(event.start_time).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''),
            preview_schedule:
                <>
                <Link target="_blank" to={`/accounts/${event.account_id}/account_users/schedule-time`}  className="fas fa-eye cst_icon mr-2" style={{ color: "#89073C", fontSize: "15px", fontWeight: "500" }}>
                    </Link>
                </>
        }
    })

    const columns = [
        {
            Header: <><span>Job</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'account_name', // String-based value accessors!,
            show: props.getScheduleDashboardData && props.getScheduleDashboardData.length !== 0,
        },{
            Header: <><span>Title</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'title', // String-based value accessors!,
            show: props.getScheduleDashboardData && props.getScheduleDashboardData.length !== 0,
        }, {
            Header: <><span>Date</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'date', // String-based value accessors!,
            show: props.getScheduleDashboardData && props.getScheduleDashboardData.length !== 0,
        }, {
            Header: <><span>Preview Schedule</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'preview_schedule', // String-based value accessors!,
            show: props.getScheduleDashboardData && props.getScheduleDashboardData.length !== 0,
        },
    ]
    const schedulePage = useSelector(state => state.adminOrSuperAdminReducer.scheduleCurPage);
    const SearchOrResetScheduleClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetScheduleClicked);
    const dispatch = useDispatch();
    const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
    const schedule_rows = useSelector(state => state.adminOrSuperAdminReducer.schedule_rows);
    const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.schedule_page);
    const isSchedulePending = useSelector(state => state.adminOrSuperAdminReducer.isSchedulePending);


    const [tablePage, setTablePage] = useState(0)
    const tablePageNew =  React.useRef()
    
    if(schedulePage !== tablePageNew.current) {
        tablePageNew.current = schedulePage;
    }

    const setTablePageFn = (page) => {
        setTablePage(page)
        // props.schedulePageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {

        if (SearchOrResetScheduleClicked) {
            dispatch(actions.resetSearchOrResetValue())
        } else {
            if (!DateGenerate && !isSchedulePending) {
                state.page = 2;
                const schedule_no_of_rows = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : schedulePage + 1;
                if(schedule_rows != schedule_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                const sorted = state.sorted;
                const filtered = state.filtered;
            //     var todayDate = new Date();
            //     // var currentDate = moment(todayDate);
            //     let startAndEndDate = getWeekStartAndEndDay(todayDate);
            //     startAndEndDate.firstday = moment(startAndEndDate.firstday).utc().toISOString();
            //    startAndEndDate.lastday = moment(startAndEndDate.lastday).utc().toISOString();
               if(props.isWeek && props.isWeek == true) {
                    props.getScheduleEventDashboard(currentData.WEEK, GetFormatedDate(props.currentDate), schedule_no_of_rows, page, sorted, filtered);
               } else if(props.isMonth && props.isMonth == true) {
                    props.getScheduleEventDashboard(currentData.MONTH, GetFormatedDate(props.currentDate), schedule_no_of_rows, page, sorted, filtered);
               } else if(props.isYear && props.isYear == true) {
                    props.getScheduleEventDashboard(currentData.YEAR, GetFormatedDate(props.currentDate), schedule_no_of_rows, page, sorted, filtered);
               }
            }
        }
    }

    return (
        <div className="chi_response" style={{ width: '100%' }}>
            <div className="tile pb-0">
                <div className="response_head">
                    <h5 className="h5_title">Inspection Schedules</h5>
                    {/* <div className="cst_select_block p-0">
                        <select className="custom-select">
                            <option selected>All</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div> */}
                </div>

                <div className="quotes_table_content table-responsive response_table">
                    <div className="table mb-0">
                    {
                        SearchOrResetScheduleClicked && props.isSchedulePending ? 
                        <div className="loader_btn_block">
                        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                       </div> :
                       <ReactTable
                       data={data}
                       onFetchData={fetchData}
                       loading={props.isLoading}
                       manual
                       defaultPageSize={schedule_rows ? schedule_rows : 10}
                       minRows={0}
                       showPaginationTop={true}
                       pages={No_of_pages}
                       gotoPage={schedulePage}
                       page = {
                           schedulePage
                       }
                       onPageChange={(page) => setTablePageFn(page)}
                       showPagination={props.getScheduleDashboardData && props.getScheduleDashboardData.length !== 0}
                       columns={columns}
                       // style={{marginTop: '10px'}}
                       NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Inspections Found')}
                       getTheadThProps={(state, rowInfo, column, instance) => {
                           // console.log(column);
                           return {
                               style: {
                                   borderTop: 'none',
                                   borderBottom: 'none',
                                   borderRight: 'none',
                                   textAlign: 'center',
                                   color: '#566975',
                                   fontSize: '14px',
                                   fontWeight: '600',
                                   whiteSpace: 'nowrap',
                                   float: 'left',
                               }
                           }
                       }
                       }
                       getTrProps={(state, rowInfo, column, instance) => {
                           // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                           console.log(column);
                           return {
                               className: 'react-table-tr-element',
                               style: {
                                   border: '1px solid #C7CDD1',
                                   transition: '.3s ease-in-out',
                                   width: '100%',
                                   marginTop: '5px',
                                   float: 'left'
                               }
                           }

                       }}
                       getTdProps={(state, rowInfo, column, instance) => {
                           return {
                               className: 'react-td-element',
                           }
                       }
                       }

                       getProps={() => {
                           return {
                               style: {
                                   border: 'none'
                               }
                           }
                       }}
                   />
                    }
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InspectionSchedules;