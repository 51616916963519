import React, { useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { emptyData, BarGraphCountOptions, BarGraphSaleOptions, LineGraphQuoteCountOptions, routes } from '../../../utility/constants/constants';
import { trimString, getSecMintHoursDays, IsArrayofObjectEmpty } from '../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import WeekMonthYearLists from './weekMonthYearLists';
import EmptyGraph from './emptyGraph';
import { Status, FilteredQuotes } from '../../../utility/constants/constants';
import DashboardSkeleton from './dashboardSkeleton';
import posed from 'react-pose';
import PastInspections from './pastInspections';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Link } from 'react-router-dom';
import '../../../styles/custom.css';
import InspectionSchedules from './InspectionSchedules';
import moment from 'moment';
import { data } from 'jquery';


const graphStype = {
    marginTop: '0px',
    paddingTop: '0px'
}

const BarGraphStyle = {
    // label: '',
    backgroundColor: '#a1395e',
    hoverBackgroundColor: '#a1395eb3',
    borderWidth: 2,
    barThickness: 5,
    // barPercentage: 0.5
}

const Box = posed.div({
    hoverable: true,
    pressable: true,
    init: {
        scale: 1,
        filter: ' blur(0.1px)'
        // boxShadow: '0px 0px 0px rgba(0,0,0,0)'
    },
    hover: {
        scale: 1.1,
        filter: ' blur(0px)',
        boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
    },
    press: {
        scale: 1,
        // boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
    }
});

const week_month_year_filter_helper= {
    isWeek:["weekly"],
    isMonth:["bi_monthly","monthly"],
    isYear:["semi_annual","quarterly"]
}

const dashboardData = (props, setURL, setOpenURL) => {

    const handleChangeName = (name) => {
        if(name === "president_visit_call")
        {
            return "President Visit/Call";
        }
        else if(name === "vice_president_visit")
        {
            return "Vice President Visit";  
        }
        else if(name === "business_review_report")
        {
            return "Business Review Report";
        }
        else if(name === "business_review_meeting")
        {
            return "Business Review Meeting";
        }
        else if( name === "quality_control_visits"){
            return "Quality Control Visits";
        }else{
            return null;
        }
    }

    function toTitleCase(name) {
        if (name.includes('_')) {
            return name
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        } else {
            return name.charAt(0).toUpperCase() + name.slice(1);
        }
    }

    function find_which_filter_is_selected(){
        const data={
            isWeek:props.isWeek,
            isMonth:props.isMonth,
            isYear:props.isYear
        }
        return Object.keys(data).find((key)=> data[key]==true)
    }

    const moment = require('moment-timezone');
    const current = new Date();
  
    const getCurrentWeekBounds = () => {
        const now = new Date();
        const firstDayOfWeek = new Date(now);
        const lastDayOfWeek = new Date(now);
    
        firstDayOfWeek.setDate(now.getDate() - now.getDay());
        firstDayOfWeek.setHours(0, 0, 0, 0);
    
        lastDayOfWeek.setDate(now.getDate() - now.getDay() + 6);
        lastDayOfWeek.setHours(23, 59, 59, 999);
    
        return { start: firstDayOfWeek, end: lastDayOfWeek };
    };
    
    const { start, end } = getCurrentWeekBounds();
    console.log('Start of the week:', start);
    console.log('End of the week:', end); 
    

    const filteredDashboard = {
        pending: [],
        upcoming: []
    };
    
    
    props.brmdashboard.sort((a,b)=>{
    const dateA = new Date(a?.end_date);
    const dateB = new Date(b?.end_date);
    if(!dateA || dateB){
        if(!dateA) return 1
        if(!dateB) return -1
    }
    if (dateA < start && dateB < start) {
      return 1 // Both are past dates, sort by date ascending
    } else if (dateA < start) {
      return 1; // A is a past date, move to the end
    } else if (dateB < start) {
      return -1; // B is a past date, move to the end
    } else {
      return 0; // Both are future dates, sort by date ascending
    }

    }).forEach(item => {;
        //if (new Date(item.end_date)  >= start ) {   
                const week_month_year=item?.matrix?.frequency ? item.matrix.frequency :""
                const data={
                    isWeek:props.isWeek,
                    isMonth:props.isMonth,
                    isYear:props.isYear
                }
                const isWeek_Month_Year=Object.keys(data).find((key)=> data[key]==true)
                
                if (item.status === "pending" && week_month_year_filter_helper[isWeek_Month_Year].includes(week_month_year) ) {
                    filteredDashboard.upcoming.push(item);
                }else if(item.status === "completed" && week_month_year_filter_helper[isWeek_Month_Year].includes(week_month_year)){
                    filteredDashboard.pending.push(item);
                }else{
                return [];
         //}
    }
    
    });

    filteredDashboard.upcoming.forEach(item => {
        console.log(moment(item.start_date).tz('America/Los_Angeles').format('YYYY-MM-DD'));
    });

    function formatTimeUTC(isoDateTime) {
        let time = moment(isoDateTime).tz('America/Los_Angeles');
        let formattedTime = time.format('h:mm A');
    
        return formattedTime;
    }    

    if (!props.inspectionDashboardData || !props.ticketDashboardData || !props.quoteDashboardData || !props.logbookDashboardData) {
        return (
            <DashboardSkeleton />
        )
    } else {

        const Average_Inspection_Score = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Average Inspection Score'
            },
            ...BarGraphCountOptions
        }
        const Average_Inspection_Lable = [];
        const Average_Inspection_Data = [];
        let Average_Inspection = null;

        if (props.inspectionDashboardData.average_inspection_score_chart_data) {
            for (let data of props.inspectionDashboardData.average_inspection_score_chart_data) {
                Average_Inspection_Lable.push(data[0]);
                Average_Inspection_Data.push(String(data[1]));
            }
        }

        Average_Inspection = {
            labels: Average_Inspection_Lable,
            datasets: [{
                ...BarGraphStyle, label: '', borderColor: '#a1395e',
                borderWidth: 2, backgroundColor: '#a1395e', data: Average_Inspection_Data, fill: false
            }]

        }

        const Inspections_Performed_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Inspections Performed'
            },
            ...BarGraphCountOptions
        }
        const Inspections_Performed_Lable = [];
        const Inspections_Performed_Data = [];
        let Inspections_Performed = null;
        if (props.inspectionDashboardData.inspection_count_chart_data) {
            for (let data of props.inspectionDashboardData.inspection_count_chart_data) {
                Inspections_Performed_Lable.push(data[0]);
                Inspections_Performed_Data.push(String(data[1]));
            }
        }

        Inspections_Performed = {
            labels: Inspections_Performed_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: Inspections_Performed_Data }]

        }

        const Tickets_Created_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Tickets Created'
            },
            ...BarGraphCountOptions
        }
        const Tickets_Created_Lable = [];
        const Tickets_Created_Data = [];
        let Tickets_Created = null;
        if (props.ticketDashboardData.ticket_count_chart_data) {
            for (let data of props.ticketDashboardData.ticket_count_chart_data) {
                Tickets_Created_Lable.push(data[0]);
                Tickets_Created_Data.push(String(data[1]));
            }
        }

        Tickets_Created = {
            labels: Tickets_Created_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: Tickets_Created_Data }]
        }

        // * PEGASSURE_LOGBOOKS_PENDING
        const PegAssure_Logbooks_Pending_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'PegAssure Logbooks Pending'
            },
            ...BarGraphCountOptions
        }
        const PegAssure_Logbooks_Pending_Lable = [];
        const PegAssure_Logbooks_Pending_Data = [];
        let PegAssure_Logbooks_Pending = null;
        if (props.logbookDashboardData.pending_chart) {
            for (let data of props.logbookDashboardData.pending_chart) {
                PegAssure_Logbooks_Pending_Lable.push(data[0]);
                PegAssure_Logbooks_Pending_Data.push(String(data[1]));
            }
        }
        PegAssure_Logbooks_Pending = {
            labels: PegAssure_Logbooks_Pending_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: PegAssure_Logbooks_Pending_Data }]
        }
        // * PEGASSURE_LOGBOOKS_REVIEWED
        const PegAssure_Logbooks_Reviewed_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'PegAssure Logbooks Reviewed'
            },
            ...BarGraphCountOptions
        }
        const PegAssure_Logbooks_Reviewed_Lable = [];
        const PegAssure_Logbooks_Reviewed_Data = [];
        let PegAssure_Logbooks_Reviewed = null;
        if (props.logbookDashboardData.reviewed_chart) {
            for (let data of props.logbookDashboardData.reviewed_chart) {
                PegAssure_Logbooks_Reviewed_Lable.push(data[0]);
                PegAssure_Logbooks_Reviewed_Data.push(String(data[1]));
            }
        }
        PegAssure_Logbooks_Reviewed = {
            labels: PegAssure_Logbooks_Reviewed_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: PegAssure_Logbooks_Reviewed_Data }]
        }

        const Top_ten_quotes_approved_by_admin_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Top 10 Quotes, Approved by Admin'
            },
            ...BarGraphCountOptions
        }
        const Top_ten_quotes_approved_by_admin_Lable = [];
        const Top_ten_quotes_approved_by_admin_Data = [];
        let Top_ten_quotes_approved_by_admin = null;
        if (props.quoteDashboardData.top_ten_quotes_approved_by_admin) {
            for (let data of props.quoteDashboardData.top_ten_quotes_approved_by_admin) {
                Top_ten_quotes_approved_by_admin_Lable.push(data.user_name);
                Top_ten_quotes_approved_by_admin_Data.push(String(data.quotes_count));
            }
        }

        Top_ten_quotes_approved_by_admin = {
            labels: Top_ten_quotes_approved_by_admin_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: Top_ten_quotes_approved_by_admin_Data }]

        }

        const Top_ten_sales_approved_by_admin_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Top 10 Sales, Approved by Admin'
            },
            ...BarGraphSaleOptions
        }
        const Top_ten_sales_approved_by_admin_Lable = [];
        const Top_ten_sales_approved_by_admin_Data = [];
        let Top_ten_sales_approved_by_admin = null;
        if (props.quoteDashboardData.top_ten_sales_approved_by_admin) {
            for (let data of props.quoteDashboardData.top_ten_sales_approved_by_admin) {
                Top_ten_sales_approved_by_admin_Lable.push(data.user_name);
                Top_ten_sales_approved_by_admin_Data.push(data.total_sale);
            }
        }

        Top_ten_sales_approved_by_admin = {
            labels: Top_ten_sales_approved_by_admin_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: Top_ten_sales_approved_by_admin_Data }]
        }

        const Top_ten_quotes_approved_by_client_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Top 10 Quotes, Approved by Customer'
            },
            ...BarGraphCountOptions
        }
        const Top_ten_quotes_approved_by_client_Lable = [];
        const Top_ten_quotes_approved_by_client_Data = [];
        let Top_ten_quotes_approved_by_client = null;
        if (props.quoteDashboardData.top_ten_quotes_approved_by_client) {
            for (let data of props.quoteDashboardData.top_ten_quotes_approved_by_client) {
                Top_ten_quotes_approved_by_client_Lable.push(data.user_name);
                Top_ten_quotes_approved_by_client_Data.push(String(data.quotes_count));
            }
        }

        Top_ten_quotes_approved_by_client = {
            labels: Top_ten_quotes_approved_by_client_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: Top_ten_quotes_approved_by_client_Data }]

        }

        const Top_ten_sales_approved_by_client_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Top 10 Sales, Approved by Customer'
            },
            ...BarGraphSaleOptions
        }
        const Top_ten_sales_approved_by_client_Lable = [];
        const Top_ten_sales_approved_by_client_Data = [];
        let Top_ten_sales_approved_by_client = null;
        if (props.quoteDashboardData.top_ten_sales_approved_by_client) {
            for (let data of props.quoteDashboardData.top_ten_sales_approved_by_client) {
                Top_ten_sales_approved_by_client_Lable.push(data.user_name);
                Top_ten_sales_approved_by_client_Data.push(data.total_sale);
            }
        }

        Top_ten_sales_approved_by_client = {
            labels: Top_ten_sales_approved_by_client_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: Top_ten_sales_approved_by_client_Data }]
        }

        const cleaning_history = props.ticketDashboardData.customer_urls.filter(i => i.area.length > 0);

        return (
            <>
                <WeekMonthYearLists {...props} />
                {/* <!-- chart --> */}

                {props.isLoading ?
                    <div className="loader_btn_block">
                        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                    </div>
                    :

                    <div className="chart-content">
                        <div className="row">
                            <div className="col-lg-9 col-xl-10">
                                <div className="row  dashboard_admin_brm">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-12 chart_cont_box graph_responsive" onClick={() => props.viewFilterData(FilteredQuotes.PENDING_FOR_APPROVAL, 'inspections')}>
                                                {Average_Inspection.datasets[0].data[0] === '0' || Average_Inspection.datasets[0].data.length === 0 ?
                                                    
                                                        <EmptyGraph title={'Top 10 Quotes, Sent by User(#)'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='' style={graphStype}>
                                                    <div className="chart-img" style={graphStype}><Bar height={15} width={50} data={Average_Inspection} options={Average_Inspection_Score} id="desktop_screen_graph"/></div>
                                                    <div className="chart-img" style={graphStype}><Bar height={45} width={50} data={Average_Inspection} options={Average_Inspection_Score} id="mobile_screen_graph"/></div>                                                    
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData(FilteredQuotes.PENDING_FOR_APPROVAL, 'inspections')}>
                                                {IsArrayofObjectEmpty(Inspections_Performed.datasets[0].data) || Inspections_Performed.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Inspections Performed'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='inspectionsPerformedId' style={graphStype}>
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={Inspections_Performed} options={Inspections_Performed_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData(FilteredQuotes.PENDING_FOR_APPROVAL, 'tickets')}>
                                                {IsArrayofObjectEmpty(Tickets_Created.datasets[0].data) || Tickets_Created.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Tickets Created'} />
                                                    :
                                                    <div className="tile chart-box-botL" id="ticketsCreatedId" style={graphStype}>
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={Tickets_Created} options={Tickets_Created_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData('pending', 'logbook')}>
                                                {IsArrayofObjectEmpty(PegAssure_Logbooks_Pending.datasets[0].data) || PegAssure_Logbooks_Pending.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'PegAssure Logbooks Pending'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='pegAssureLogbooksPendingId' style={graphStype}>
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={PegAssure_Logbooks_Pending} options={PegAssure_Logbooks_Pending_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData('reviewed', 'logbook')}>
                                                {IsArrayofObjectEmpty(PegAssure_Logbooks_Reviewed.datasets[0].data) || PegAssure_Logbooks_Reviewed.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'PegAssure Logbooks Reviewed'} />
                                                    :
                                                    <div className="tile chart-box-botL" id="pegAssureLogbooksReviewedId" style={graphStype}>
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={PegAssure_Logbooks_Reviewed} options={PegAssure_Logbooks_Reviewed_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-3">
                                        <div className="tile performance_chart element" onClick={() => props.viewFilterData('pending', 'quotes')} style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total Quotes Sent</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.quoteDashboardData.quotes_sent_to_clients}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_quotes_sent.png" alt="Total Quotes Sent" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                                {/* <h6>Sent to Clients</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-3">
                                        <div className="tile performance_chart element" onClick={() => props.viewFilterData(FilteredQuotes.APPROVED_BY_CLIENTS, 'quotes')} style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total Quotes Pending</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.quoteDashboardData.quotes_denied_by_admin}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_quotes_denied.png" alt="Total Quotes Denied" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                                {/* <h6>Pending client response</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-3" >
                                        <div className="tile performance_chart element" onClick={() => props.viewFilterData('approved', 'quotes')} style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total Quotes Approved</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.quoteDashboardData.quotes_approved_by_clients}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_quotes_approved.png" alt="Total Quotes Approved" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                                {/* <h6>Approved by Clients</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-3" >
                                        <div className="tile performance_chart element" onClick={() => props.viewFilterData('reject', 'quotes')} style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total Quotes Rejected</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.quoteDashboardData.quotes_rejected_by_clients}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_quotes_rejected.png" alt="Total Quotes Approved" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                                {/* <h6>Rejected by Clients</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-3" >
                                        <div className="tile performance_chart element" onClick={() => props.viewFilterData('pending', 'quotes')} style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total Opportunities Created</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>${trimString(String(props.quoteDashboardData.quotes_opportunities_created, 7))}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_opportunities_created.png" alt="Total Opportunities Created" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                                {/* <h6>Quotes sent to Clients</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-3" >
                                        <div className="tile performance_chart element" onClick={() => props.viewFilterData(FilteredQuotes.APPROVED_BY_CLIENTS, 'quotes')} style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total Opportunities Denied</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>${trimString(String(props.quoteDashboardData.quotes_total_sales_rejected_by_admin, 7))}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_opportunities_denied.png" alt="Total Opportunities Denied" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                                {/* <h6>Denied by Admin</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-3" >
                                        <div className="tile performance_chart element" onClick={() => props.viewFilterData('approved', 'quotes')} style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total Sales Approved</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>${trimString(String(props.quoteDashboardData.quotes_total_sales_approved_by_clients, 7))}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_total_sales_approved.png" alt="Total Sales Approved" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                                {/* <h6>Approved by Clients</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-3">
                                        <div className="tile performance_chart element" onClick={() => props.viewFilterData('reject', 'quotes')} style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total Sales Rejected </h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>${trimString(String(props.quoteDashboardData.quotes_total_sales_declined, 7))}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_total_sales_reject.png" alt="Total Sales Rejected" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                                {/* <h6>Rejected by Clients</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-6 col-sm-6 col-lg-6 col-xl-4" >
                                        <div className="tile performance_chart" onClick={() => props.viewFilterData('pending', 'logbook')}>
                                            <h4 className="chart_title1">Total PegAssure Logbooks</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.logbookDashboardData.total_logs}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_quotes_sent.png" alt="Total Quotes Sent" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData('approved_by_admin', 'quotes')}>
                                                {Top_ten_quotes_approved_by_admin.datasets[0].data[0] === '0' || Top_ten_quotes_approved_by_admin.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Top 10 Quotes, Approved by Admin'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='quotesApprovedByAdminId' style={graphStype}>
                                                        {/* <h6 className="chart_title2">Top 10 Quotes Approved by Admin</h6> */}
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={Top_ten_quotes_approved_by_admin} options={Top_ten_quotes_approved_by_admin_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData('approved_by_admin', 'quotes')}>
                                                {Top_ten_sales_approved_by_admin.datasets[0].data[0] === '0' || Top_ten_sales_approved_by_admin.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Top 10 Sales, Approved by Admin'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='salesApprovedByAdminId' style={graphStype}>
                                                        {/* <h6 className="chart_title2">Top 10 Sales Approved by Admin</h6> */}
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={Top_ten_sales_approved_by_admin} options={Top_ten_sales_approved_by_admin_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData(FilteredQuotes.APPROVED_BY_CLIENTS, 'quotes')}>
                                                {Top_ten_quotes_approved_by_client.datasets[0].data[0] === '0' || Top_ten_quotes_approved_by_client.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Top 10 Quotes, Approved by Customer'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='quotesApprovedByClientId' style={graphStype}>
                                                        {/* <h6 className="chart_title2">Top 10 Quotes Approved by Admin</h6> */}
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={Top_ten_quotes_approved_by_client} options={Top_ten_quotes_approved_by_client_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData(FilteredQuotes.APPROVED_BY_CLIENTS, 'quotes')}>
                                                {Top_ten_sales_approved_by_client.datasets[0].data[0] === '0' || Top_ten_sales_approved_by_client.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Top 10 Sales, Approved by Customer'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='salesApprovedByClientId' style={graphStype}>
                                                        {/* <h6 className="chart_title2">Top 10 Sales Approved by Admin</h6> */}
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={Top_ten_sales_approved_by_client} options={Top_ten_sales_approved_by_client_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <InspectionSchedules {...props}/>
                                <PastInspections {...props} />
                            </div>
                            <div class="col-lg-3 col-xl-2 chart_cont_box">
                            <div class="ticket_inbox ticket_inbox_over ticket_inbox_over_styling">
                                <div >
                                        {filteredDashboard?.pending.length || filteredDashboard?.upcoming?.length ? (
                                                <div class="card-body Dashboard_newInbox" style={Average_Inspection.datasets[0].data.length ? { height: '338px' } : { height: '567px'}}>
                                                    {filteredDashboard?.pending?.length > 0 ? <span className="bold-first-letter">Completed Events</span> : null}
                                                    {filteredDashboard?.pending?.map((item,index) => {
                                                        return(
                                                                <div className="card">
                                                                    <div className="date-container">
                                                                        <span className="label">Start Date:</span>
                                                                        <span className="date">
                                                                        {item?.start_date ? moment(new Date(item.start_date)).tz('America/Los_Angeles').format('MM-DD-YYYY') : "-"}
                                                                        </span>
                                                                    </div>
                                                                    <div className="date-container">
                                                                        <span className="label">End Date:</span>
                                                                        <span className="date">
                                                                            {item?.end_date ? moment(new Date(item.end_date)).tz('America/Los_Angeles').format('MM-DD-YYYY') : "-"}
                                                                        </span>
                                                                    </div>
                                                                    <Link to={`/preview-event/${item?.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                                                        <p key={index + 1}  style={{ textDecoration: 'underline', color:'#2b3034'}}>{item?.matrix?.frequency ? item?.matrix?.frequency : "-"} meet at {item?.time ? formatTimeUTC(item?.time) : "-"} PST with {item?.company?.name ? toTitleCase(item?.company?.name) : "-"}.</p> 
                                                                    </Link>
                                                                </div>
                                                        )
                                                    })}
                                                    {filteredDashboard?.upcoming?.length > 0 ? <span className="bold-first-letter">Current Events</span> : null}
                                                     
                                                    {filteredDashboard?.upcoming?.map((item, index) => {
                                                        return (
                                                            <div className="card-brm">
                                                                <div className="date-container">
                                                                    <span className="label">Start Date:</span>
                                                                    <span className="date">
                                                                    {item?.start_date ? moment(new Date(item.start_date)).tz('America/Los_Angeles').format('MM-DD-YYYY') : "-"}
                                                                    </span>
                                                                </div>
                                                                <div className="date-container">
                                                                    <span className="label">End Date:</span>
                                                                    <span className="date">
                                                                    {item?.end_date ? moment(new Date(item.end_date)).tz('America/Los_Angeles').format('MM-DD-YYYY') : "-"}
                                                                    </span>
                                                                </div>
                                                                <Link to={`/preview-event/${item?.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                                                     <p key={index+1}  style={{ textDecoration: 'underline', color:'#2b3034'}} >{item?.matrix?.name ? handleChangeName(item?.matrix?.name) : "-"} with {item?.company?.name ? toTitleCase(item?.company?.name) : "-"}.</p>
                                                                </Link>
                                                             </div>
                                                        )
                                                    })}
                                </div>
                                ) 
                                : (                                         
                                <div class="card-body Dashboard_newInbox" style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'row', marginLeft: '12px',height:Average_Inspection.datasets[0].data.length ? "338px" : "567px"}} >
                                    <div><span style={{ fontWeight: '600', fontSize: '20px', color: 'black'}}>Event meeting is not available for this {find_which_filter_is_selected()?.split("is")[1]}.</span></div>
                                </div>
                            )}
                                    </div>                                                                      
                                </div>
                                <div class="ticket_inbox" style={{position:'absolute'}}>
                                    <div class="card">
                                        <div class="card-header">
                                            Ticket Inbox
                                        </div>
                                        <div class="card-body">
                                            <div class="ticket_status">
                                            <div class="col">
                                                    <div class="col p-0">
                                                        <small>Open</small>
                                                        <h2 class="status_pending">{props.ticketDashboardData.pending_ticket_count}</h2>
                                                    </div>
                                                    <div class="col p-0">
                                                        <small>Resolved</small>
                                                        <h2 class="status_open">{props.ticketDashboardData.resolved_ticket_count}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ticket_link_list">
                                                <ul class="link_list">
                                                    {props.ticketDashboardData.ticket_data && props.ticketDashboardData.ticket_data.map((ticket) => {
                                                        return (
                                                            <li>
                                                                <a href="javascript:void(0);" onClick={() => props.previewTicketClicked(ticket)}>{ticket.subject}</a>
                                                                <small>{ticket.user && ticket.user.first_name + ' ' + ticket.user && ticket.user.last_name} </small>
                                                                <br />
                                                                <small>{getSecMintHoursDays(ticket.updated_at.replace(/-/gi, "/"), ticket.account.timezone)}</small>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {cleaning_history && cleaning_history.length > 0 && (props.user.selected_mode && props.user.selected_mode === 'external') ?
                                    <div class="ticket_inbox mt-2" style={{position:'absolute',top:'18rem'}}>
                                        <div class="card">
                                            <div class="card-header mb-0">
                                                Cleaning History
                                            </div>
                                            <div class="card-body">
                                                <div class="ticket_status">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="ticket_link_list">
                                                                <ul class="link_list" style={{ textAlign: 'left' }}>
                                                                    {cleaning_history && cleaning_history.map((customer, i) => <li style={{ marginTop: 5 }} key={i} className="label_modifier"> <a href="javascript:void(0);"
                                                                        onClick={() => [setOpenURL(true), setURL(customer.url)]}
                                                                    >{customer.name}</a></li>)}
                                                                    {/* {props.upcomingSchedules && props.upcomingSchedules.future_schedules.map((schedule, index) => <li key={index} className="label_modifier"><small>{schedule.area.name}</small> <small className="status_open">{moment(moment().format("MM-DD-YYYY") + " " + schedule.start_time, "MM-DD-YYYY HH:mm:ss").format("hh:mm A")}</small></li>)} */}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

const AdminDashbord = (props) => {

    const [openUrl, setOpenURL] = useState(false);
    const [genericUrl, setURL] = useState("");

    window.onresize = function () {
        const emptyChartObj = document.getElementsByClassName("empty-graph_heading");
        let cahrtjsHeight = document.getElementById('quotesApprovedByAdminId') ? document.getElementById('quotesApprovedByAdminId').clientHeight : '';
        //  
        // if (props.dashboardData) {

        //     if (props.dashboardData.quotes_created > 0) {
        //         cahrtjsHeight = document.getElementById('quotesApprovedByAdminId') ? document.getElementById('quotesApprovedByAdminId').clientHeight : '';
        //     } else if (props.dashboardData.quotes_sent_to_clients > 0) {
        //         cahrtjsHeight = document.getElementById('salesApprovedByAdminId') ? document.getElementById('salesApprovedByAdminId').clientHeight : '';
        //     } else if (props.dashboardData.quotes_approved_by_clients > 0) {
        //         cahrtjsHeight = document.getElementById('quotesApprovedByClientId') ? document.getElementById('quotesApprovedByClientId').clientHeight : '';
        //     } else if (props.dashboardData.quotes_not_tagged > 0) {
        //         cahrtjsHeight = document.getElementById('quotesApprovedByClientId') ? document.getElementById('quotesApprovedByClientId').clientHeight : '';
        //     }
        // } else {
        //     cahrtjsHeight = document.getElementById('quotesApprovedByAdminId') ? document.getElementById('quotesApprovedByAdminId').clientHeight : '';
        // }

        if (emptyChartObj.length !== 0) {
            for (var i = 0; i < emptyChartObj.length; i++) {
                emptyChartObj[i].style.height = `${cahrtjsHeight}px`
            }
        }
    };

    return (
        <main className="app-content wraper_content chart_section ">
            <section className="admin_dashboard appcontent_Inner">
                {dashboardData(props, setURL, setOpenURL)}
                {/* <!-- canvas sidebar -->
            <div className="canvas-body">
                <div id="my-navigation" className="offcanvas offcanvas--right offcanvas--initialized">
                    <div className="sidebar_content">
                        <a href="#" className="close_sidebar"><img src="../images/icons/back-arrow.png"></a>
                        <div className="form-group">
                            <input type="text" className="form-control canvas-search" placeholder="Search">
                        </div>
                        <div className="recent-search">
                            <p>RECENT TICKETS</p>
                        </div>
                        <div className="search-category">
                            <p>Unsatisfied</p>
                        </div>
                    </div>
                </div>
            </div> */}
                <Dialog
                    open={openUrl}

                    aria-labelledby="form-dialog-title"
                >
                    <div className="row p-0 m-0">
                        <div className="col-10"></div>
                        <div className="col-2">
                            <h4 onClick={() => [setURL(''), setOpenURL(false)]} className="float-right text-right mt-2 cursor-pointer"><i className="fa fa-times"></i></h4>
                        </div>
                    </div>
                    <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                        <h3 style={{ color: '#ab385e' }}></h3>
                    </DialogTitle>
                    <div className="qr-body-single"><div className="p-5"><a href={genericUrl} target="_blank" className="text_danger">{genericUrl}</a></div></div>
                    <DialogActions>
                        <button style={{ minHeight: '36px', minWidth: '80px', visibility: "hidden" }} className={"float-right btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel">Cancel</button>
                        <button style={{ minHeight: '36px', minWidth: '80px', visibility: "hidden" }} id="delete" type="delete">Okay</button>
                    </DialogActions>
                </Dialog>
            </section>

        </main>

    )
}

export default AdminDashbord;

