import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../styles/custom.css';
import { TicketStatus, userRoles, TimeZones, routes } from '../../../../utility/constants/constants';
import {
    CustomNoDataComponent,
    trimString,
    makeFirstLetterCapital,
    convertDateTimezone,
    convertDateAndTimeTimezone, getUser, getWeekStartAndEndDay, convertTicketsDataToCsvType, convertDateToDifferentTZ, convertDateFormatV1, convertStartEndTimeFormat, convertStartDateEndDate_totalDayCount
} from '../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import storage from '../../../../utility/storage';
import cloneDeep from 'clone-deep';
import { tzDateConverterTool_V1, tzTimeConversionTool_V1, tzInbetweenDaysFinder_V1, tzDateConverterTool_V4, tzDateConverterTool_V5 } from '../../../../utility/TimezoneOperations/timezoneUtility';

const LeaveListTable = (props) => {

    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchLeaveList && state.form.searchLeaveList.values ? state.form.searchLeaveList.values : {});
    const DateGenerate = useSelector(state => state.TimeTrackerModReducer.dataGenerated);
    const values = useSelector(state => state.TimeTrackerModReducer.leavesList_searchValues);
    const No_of_pages = useSelector(state => state.TimeTrackerModReducer.leavesList_total_pages);
    const No_of_rows = useSelector(state => state.TimeTrackerModReducer.leavesList_no_of_rows);
    const isLeavesList = useSelector(state => state.TimeTrackerModReducer.isLeavesList);
    const leavesListPage = useSelector(state => state.TimeTrackerModReducer.leavesList_curPage);
    const SearchOrResetLeavesListClicked = useSelector(state => state.InventoryReducer.SearchOrResetLeavesListClicked);

    const accountTimezone = 'America/Los_Angeles';

    const data = props.leavesList && props.leavesList.map((value) => {
        if (props.user.role === userRoles.FRONTLINE) {
            
            return {
                type: makeFirstLetterCapital(value.type),
                day_coverage: value.day_coverage == 'full' ? 'Full Day' : 'Partial Day',
                leaveDate: value.day_coverage == 'full' ? tzDateConverterTool_V4(value.start_date) + ' − ' + tzDateConverterTool_V4(value.end_date) : tzDateConverterTool_V1(value.start_date),
                duration: value.day_coverage == 'partial' ? tzTimeConversionTool_V1(value.start_time, value.start_date) + ' − ' + tzTimeConversionTool_V1(value.end_time, value.start_date) : value.day_coverage == 'full' ? tzInbetweenDaysFinder_V1(tzDateConverterTool_V5(value.start_date), tzDateConverterTool_V5(value.end_date)) : '−',
                reject_reason: value.info ? makeFirstLetterCapital(value.info) : '−',
                approvedBy: (value.manager && value.manager.id) && (value.status !== "requested" && value.status !== "reject" ) ? makeFirstLetterCapital(value.manager.first_name) + ' ' + makeFirstLetterCapital(value.manager.last_name) : '−',
                managerName: value.manager && value.manager.id ? makeFirstLetterCapital(value.manager.first_name) + ' ' + makeFirstLetterCapital(value.manager.last_name) : '−',
                status: value.status ? value.status == 'reject' ? 'Rejected' : makeFirstLetterCapital(value.status) : '−',
                actions:
                    <>
                        <span className="mr_20 account_edit_icn" onClick={() => props.previewLeaveRequestPageClicked(value.id)}><i class="fas fa-eye" aria-hidden="true" ></i></span>
                        {value.status && value.status == 'requested' ? <span className="mr_20 account_edit_icn" onClick={() => props.updateLeaveRequestFormClicked(value.id)} data-test="edit_pencil_icon"><i class="fa fa-pencil" aria-hidden="true"></i></span> : ''}
                        {/* <span className="mr_20 account_edit_icn" onClick={() => props.handleFrequentTimeTracker_openDeleteModal(value.id)}><i class="fa fa-trash" aria-hidden="true"></i></span> */}
                    </>
            }
        } else {
            return {
                type: makeFirstLetterCapital(value.type),
                day_coverage: value.day_coverage == 'full' ? 'Full Day' : 'Partial Day',
                leaveDate: value.day_coverage == 'full' ? tzDateConverterTool_V4(value.start_date) + ' − ' + tzDateConverterTool_V4(value.end_date) : tzDateConverterTool_V1(value.start_date),
                duration: value.day_coverage == 'partial' ? tzTimeConversionTool_V1(value.start_time, value.start_date) + ' − ' + tzTimeConversionTool_V1(value.end_time, value.start_date) : value.day_coverage == 'full' ? tzInbetweenDaysFinder_V1(tzDateConverterTool_V5(value.start_date), tzDateConverterTool_V5(value.end_date)) : '−',
                reject_reason: value.info ? makeFirstLetterCapital(value.info) : '−',
                requestedBy: value.user && value.user.id ? makeFirstLetterCapital(value.user.first_name) + ' ' + makeFirstLetterCapital(value.user.last_name) : '−',
                managerName: value.manager && value.manager.id ? makeFirstLetterCapital(value.manager.first_name) + ' ' + makeFirstLetterCapital(value.manager.last_name) : '−',
                status: value.status ? value.status == 'reject' ? 'Rejected' : makeFirstLetterCapital(value.status) : '−',
                actions:
                    <>
                        <span className="mr_20 account_edit_icn" onClick={() => props.previewLeaveRequestPageClicked(value.id)}><i class="fas fa-eye" aria-hidden="true" ></i></span>
                        {/* <span className="mr_20 account_edit_icn" onClick={() => props.handleFrequentTimeTracker_openDeleteModal(value.id)}><i class="fa fa-trash" aria-hidden="true"></i></span> */}
                    </>
            }
        }
    })

    const columns = [
        {
            Header: <><span> Leave Type </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'type',
            show: props.leavesList.length !== 0,
        },
        {
            Header: <><span> Full Day/Partial Day </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'day_coverage',
            show: props.leavesList.length !== 0,
        },
        {
            Header: <><span> Date of Leave </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'leaveDate',
            show: props.leavesList.length !== 0,
        },
        {
            Header: <><span> Duration </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'duration',
            show: props.leavesList.length !== 0,
        },
        {
            Header: <><span> Info </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'reject_reason',
            show: props.leavesList.length !== 0,
        },
        {
            Header: <><span> {props.user.role === userRoles.FRONTLINE ? 'Approved By' : 'Requested By'} </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: `${props.user.role === userRoles.FRONTLINE ? 'approvedBy' : 'requestedBy'}`,
            show: props.leavesList.length !== 0
        },
        {
            Header: <><span> Manager Name </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'managerName',
            show: props.leavesList.length !== 0
        },
        {
            Header: <><span> Status </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
            accessor: 'status',
            show: props.leavesList.length !== 0
        },
        {
            Header: '',
            accessor: 'actions',
            width: 135,
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew = React.useRef()
    if (leavesListPage !== tablePageNew.current) {
        tablePageNew.current = leavesListPage;
    }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.leaveListPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetLeavesListClicked) {
            dispatch(actions.resetSearchOrResetTimeTracker())
        } else {
            if (!DateGenerate && !props.isLeavesList) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : leavesListPage + 1;
                const leavesList_no_of_rows = state.pageSize;
                if (No_of_rows != leavesList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }
                //const sorted = state.sorted;
                const order = state.sorted[0]?.id === 'time_duration' ? 'start_date' : state.sorted[0]?.id ;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                const status = SearchValue.status ? SearchValue.status : '';
                const leaveTypes = SearchValue.leaveTypes ? SearchValue.leaveTypes : '';
                const fromDate = SearchValue.fromDate ? SearchValue.fromDate : '';
                const toDate = SearchValue.toDate ? SearchValue.toDate : '';
                let sendGridData = {
                    page: page,
                    limit: limit,
                    order : order,
                    orderBy : orderBy
                }
                if (status !== '') {
                    sendGridData.status = status;
                }
                if (leaveTypes !== '') {
                    sendGridData.type = leaveTypes;
                }
                if (fromDate !== '') {
                    sendGridData.from = fromDate;
                }
                if (toDate !== '') {
                    sendGridData.to = toDate;
                }
                props.getAllLeaveList(sendGridData, values)
            }
        }
    }

    return (
        SearchOrResetLeavesListClicked && props.isLeavesList ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isLeavesList}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={leavesListPage}
                page={leavesListPage}
                onPageChange={(page) => { setTablePageFn(page) }}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPagination={props.leavesList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(props.isLeavesList, 'No Leaves Found')}
                LoadingComponent={() =>
                    isLeavesList ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
};

export default LeaveListTable;