import { InventoryActionType } from "../../actions/InventoryAction/InventoryActionType";
import storage from "../../../utility/storage";
import actions from "redux-form/lib/actions";
const cloneDeep = require("clone-deep");

export const initialState = {
  toggleInventoryTab: false,

  SearchOrResetInventoryItemsListClicked: false,
  SearchOrResetPickupsTicketListClicked: false,
  openInventoryDeleteModal: false,
  deleteInventoryObject: {},

  isLoading: false,
  dataGenerated: false,
  commonJobsAccountList: null,

  inventoryItemsListCurPage: 0,
  isInventoryItemList: false,
  inventoryItemsList: [],
  inventoryItemsList_total_pages: 1,
  inventoryItemsList_no_of_rows: 10,
  inventoryItemsList_rows: null,
  total_inventoryItemsList_count: null,
  inventoryItems_searchValues: {},

  singleInventoryItem: {},
  isEditInventoryItem: false,
  isUpdateInventoryItem: false,

  box_pages: 1,
  box_total_count: "",
  box_no_of_rows: 10,
  boxList: [],
  boxCurPage: 0,

  assests_pages: 1,
  assests_total_count: "",
  assests_no_of_rows: 10,
  assestsList: [],
  assestsCurPage: 0,
  assets_searchValues: {},
  SearchOrResetAssetsClicked: false,
  assets: [],
  assestStockCurPage : 0,
  assestStockList : [],
  assestStock_total_count : "",
  assestStock_no_of_rows : 10,
  assestStock_pages : 1,
  assetStock_searchValues : {},
  assetStock : {},

  singleAssetData: {},
  isEditAsset: false,
  isUpdateAsset: false,
  isolatedOneAssetData: {},
  activeAssets: true,

  pickingTicket_pages: 1,
  pickingTicket_total_count: "",
  pickingTicket_no_of_rows: 10,
  pickingTicketList: [],
  pickingTicketCurPage: 0,
  emergencyTicketCurPage: 0,
  SearchOrResetPickingTicketClicked: false,
  SearchOrResetEmergencyPickingTicketClicked: false,

  box: {},
  isEdit: false,
  isUpdateBox: false,

  pickingTicketPreviewData: {},
  picking_tickets_searchValues: {},
  emergency_tickets_searchValues: {},
  
  inventoryItemNamesList: null,
  jobUsersList: null,
  inventory: {},
  inventoryLog: [],

  pickupsTicketList: [],
  pickupsTicket_total_count: "",
  pickupsTicket_total_pages: 1,
  pickupsTicket_no_of_rows: 10,
  pickupsTicketCurPage: 0,
  pickupsTicketCurStatus: null,

  singlePickupTicket: {},
  inventoryBoxNamesList: null,
  isFulfillmentManagerFormOpen: false,
  isPickupDetailsFormOpen: false,

  inventoryLocationList: [],

  locations: [],
  location_count: "",
  locationAssociation: [],
  location_association_count: "",
  locationName: [],
  fulfillmentManager: [],
  location: {},
  locationAssociate: {},
  isOpenModel: false,

  unavailableItems : [],

  assetSingleLogCurPage: 0,
  assetSingleLogList: [],
  assetSingleLog_total_count: "",
  assetSingleLog_no_of_rows: 10,
  assetSingleLog_total_pages: 1,
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};

export const InventoryReducer = (state = initialState, action) => {
  switch (action.type) {

    case InventoryActionType.INVENTORY_TAB_CLICKED:
      return updateObject(state, { toggleInventoryTab: !state.toggleInventoryTab });

    case InventoryActionType.UPLOAD_IMAGE_TO_S3_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.UPLOAD_IMAGE_TO_S3_FULFILLED:
      return updateObject(state, { isLoading: true });

    case InventoryActionType.GET_COMMON_JOBS_ACCOUNT_LIST_PENDING:
      return updateObject(state, { commonJobsAccountList: null });
    case InventoryActionType.GET_COMMON_JOBS_ACCOUNT_LIST_FULFILLED:
      return updateObject(state, {
        commonJobsAccountList: action.payload.commonJobsList,
      });

    case InventoryActionType.RESET_INVENTORY_OBJECTS:
      return updateObject(state, {
        singleInventoryItem: {},
        isEditInventoryItem: false,
        isEdit: false,
        pickingTicketPreviewData: {},
        box: {},
        singleAssetData: {},
        isEditAsset: false,
        inventory: {},
        singlePickupTicket: {},
        pickupsTicketCurStatus: null,
        pickupsTicketList: [],
        isolatedOneAssetData: {},
      });

    case InventoryActionType.RESET_SEARCH_OR_RESET:
      return updateObject(state, {
        SearchOrResetInventoryItemsListClicked: false,
        SearchOrResetPickingTicketClicked: false,
        SearchOrResetEmergencyPickingTicketClicked: false,
        SearchOrResetAssetsClicked: false,
        SearchOrResetPickupsTicketListClicked: false,
      });

    case InventoryActionType.HANDLE_INVENTORY_OPEN_DELETE_MODAL:
      return updateObject(state, {
        deleteInventoryObject: action.payload,
        openInventoryDeleteModal: true,
      });

    case InventoryActionType.HANDLE_INVENTORY_CLOSE_DELETE_MODAL:
      return updateObject(state, { openInventoryDeleteModal: false });

    case InventoryActionType.GET_ALL_INVENTORY_ITEMS_LIST_PENDING:
      return updateObject(state, {
        isLoading: true,
        isInventoryItemList: true,
        inventoryItemsList: [],
      });
    case InventoryActionType.GET_ALL_INVENTORY_ITEMS_LIST_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        dataGenerated: false,
        isInventoryItemList: false,
        inventoryItemsList: action.payload.itemsList,
        inventoryItemsList_total_pages: action.payload.itemsList_total_pages,
        inventoryItemsList_no_of_rows: action.payload.itemsList_no_of_rows,
        inventoryItemsListCurPage: action.payload.itemsList_curPage,
        total_inventoryItemsList_count: action.payload.total_itemsList_count,
        inventoryItemsList_rows: action.payload.itemList_rows,
        inventoryItems_searchValues: action.payload.searchValues,
      });

    case InventoryActionType.INVENTORY_IETMS_LIST_PAGE_CLICKED:
      return updateObject(state, { inventoryItemsListCurPage: action.payload });

    case InventoryActionType.CREATE_INVENTORY_ITEM_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.CREATE_INVENTORY_ITEM_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        isEditInventoryItem: false,
      });

    case InventoryActionType.UPDATE_INVENTORY_ITEM_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.UPDATE_INVENTORY_ITEM_FULFILLED:
      return updateObject(state, { isLoading: false });

    case InventoryActionType.GET_SINGLE_INVENTORY_ITEM_PENDING:
      return updateObject(state, {
        isLoading: true,
        isEditInventoryItem: false,
        isUpdateInventoryItem: true,
      });
    case InventoryActionType.GET_SINGLE_INVENTORY_ITEM_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        isEditInventoryItem: true,
        isUpdateInventoryItem: false,
        singleInventoryItem: action.payload.data,
      });

    case InventoryActionType.DELETE_INVENTORY_ITEM_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.DELETE_INVENTORY_ITEM_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        // inventoryItemsList: action.payload,
        openInventoryDeleteModal: false,
      });

    case InventoryActionType.GET_SINGLE_INVENTORY_LOG_PENDING:
      return updateObject(state, {
        isLoading: true,
      });
    case InventoryActionType.GET_SINGLE_INVENTORY_LOG_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        inventoryLog: action.payload.data.product.status,
      });

    case InventoryActionType.GET_BOX_LIST_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.GET_BOX_LIST_FULFILLED:
      return updateObject(state, {
        dataGenerated: false,
        isLoading: false,
        isBox: false,
        boxCurPage: action.payload.boxCurPage,
        boxList: action.payload.boxList,
        box_total_count: action.payload.box_total_count,
        box_no_of_rows: action.payload.box_no_of_rows,
        box_pages: action.payload.box_pages,
      });
    case InventoryActionType.ADD_BOX_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.ADD_BOX_FULFILLED:
      return updateObject(state, {
        isLoading: false,
      });

    case InventoryActionType.UPDATE_BOX_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.UPDATE_BOX_FULFILLED:
      return updateObject(state, {
        isLoading: false,
      });

    case InventoryActionType.GET_SINGLE_BOX_PENDING:
      return updateObject(state, {
        box: null,
        isEdit: false,
        isUpdateBox: true,
      });
    case InventoryActionType.GET_SINGLE_BOX_FULFILLED:
      return updateObject(state, {
        box: action.payload.data.box,
        isEdit: true,
        isUpdateBox: false,
      });

    case InventoryActionType.NEW_BOX_CLICKED:
      return updateObject(state, { box: null, isEdit: false });

    case InventoryActionType.DELETE_BOX_PENDING:
      return updateObject(state, { isLoading: true });

    case InventoryActionType.DELETE_BOX_FULFILLED:
      const boxList = [...state.boxList];
      boxList.splice(
        boxList.findIndex((e) => e.id === action.payload.id),
        1
      );
      return updateObject(state, {
        isLoading: false,
        openInventoryDeleteModal: false,
        boxList,
      });

    case InventoryActionType.BOX_PAGE_CLICKED:
      return updateObject(state, { boxCurPage: action.payload });

    case InventoryActionType.GET_ALL_ASSESTS_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.GET_ALL_ASSESTS_FULFILLED:
      return updateObject(state, {
        dataGenerated: false,
        isLoading: false,
        assestsCurPage: action.payload.assestsCurPage,
        assestsList: action.payload.assestsList,
        assests_total_count: action.payload.assests_total_count,
        assests_no_of_rows: action.payload.assests_no_of_rows,
        assests_pages: action.payload.assests_pages,
        assets_searchValues: action.payload.searchValues,
      });

    case InventoryActionType.CREATE_ASSET_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.CREATE_ASSET_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        isEditAsset: false,
      });

    case InventoryActionType.UPDATE_ASSET_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.UPDATE_ASSET_FULFILLED:
      return updateObject(state, { isLoading: false });

    case InventoryActionType.GET_SINGLE_ASSET_PENDING:
      return updateObject(state, {
        isLoading: true,
        isEditAsset: false,
        isUpdateAsset: true,
        inventory: null,
      });
    case InventoryActionType.GET_SINGLE_ASSET_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        isEditAsset: true,
        isUpdateAsset: false,
        singleAssetData: action.payload.data,
        inventory: action.payload.data.product,
        inventoryLog: action.payload.data.product.status,
      });

    case InventoryActionType.GET_SINGLE_ASSET_LOG_PENDING:
      return updateObject(state, {
        isLoading: true,
      });
    case InventoryActionType.GET_SINGLE_ASSET_LOG_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        assetSingleLogCurPage: action && action.payload && action.payload.assetSingleLogCurPage,
        assetSingleLogList: action && action.payload && action.payload.assetSingleLogList,
        assetSingleLog_total_count: action && action.payload && action.payload.assetSingleLog_total_count,
        assetSingleLog_no_of_rows: action && action.payload && action.payload.assetSingleLog_no_of_rows,
        assetSingleLog_total_pages: action && action.payload && action.payload.assetSingleLog_total_pages,
      });

    case InventoryActionType.DELETE_ASSET_PENDING:
      return updateObject(state, { isLoading: true });

    case InventoryActionType.DELETE_ASSET_FULFILLED:
      const assestsList = [...state.assestsList];
      assestsList.splice(
        assestsList.findIndex((e) => e.id === action.payload.id),
        1
      );
      return updateObject(state, {
        isLoading: false,
        openInventoryDeleteModal: false,
        assestsList,
      });

    case InventoryActionType.GET_ISOLATED_ONE_ASSET_DATA_PENDING:
      return updateObject(state, {
        isLoading: true,
        isolatedOneAssetData: {},
      });
    case InventoryActionType.GET_ISOLATED_ONE_ASSET_DATA_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        isolatedOneAssetData: action && action.payload && action.payload.data,
      });
    
    case InventoryActionType.ENGAGE_ASSET_CHECK_IN_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.ENGAGE_ASSET_CHECK_IN_FULFILLED:
      return updateObject(state, {
        isLoading: false,
      });

    case InventoryActionType.ENGAGE_ASSET_CHECK_OUT_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.ENGAGE_ASSET_CHECK_OUT_FULFILLED:
      return updateObject(state, {
        isLoading: false,
      });

    case InventoryActionType.GET_ASSET_ACTIVE_STATUS_TOGGLE:
      return updateObject(state, { activeAssets: !state.activeAssets })

    case InventoryActionType.GET_ALL_PICKING_TICKET_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.GET_ALL_PICKING_TICKET_FULFILLED:
      return updateObject(state, {
        dataGenerated: false,
        isLoading: false,
        pickingTicketCurPage: action.payload.pickingTicketCurPage,
        pickingTicketList: action.payload.pickingTicketList,
        pickingTicket_total_count: action.payload.pickingTicket_total_count,
        pickingTicket_no_of_rows: action.payload.pickingTicket_no_of_rows,
        pickingTicket_pages: action.payload.pickingTicket_pages,
        picking_tickets_searchValues: action.payload.searchValues,
      });

    case InventoryActionType.GET_SINGLE_PICKING_TICKET_PENDING:
      return updateObject(state, {
        isLoading: true,
        pickingTicketPreviewData: null,
      });
    case InventoryActionType.GET_SINGLE_PICKING_TICKET_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        pickingTicketPreviewData: action.payload.data.ticket,
      });

    case InventoryActionType.GET_EMERGENCY_PICKUP_TICKET_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.GET_EMERGENCY_PICKUP_TICKET_FULFILLED:
      return updateObject(state, {
        dataGenerated: false,
        isLoading: false,
        emergencyTicketCurPage: action.payload.pickingTicketCurPage,
        pickingTicketList: action.payload.pickingTicketList,
        pickingTicket_total_count: action.payload.pickingTicket_total_count,
        pickingTicket_no_of_rows: action.payload.pickingTicket_no_of_rows,
        pickingTicket_pages: action.payload.pickingTicket_pages,
        emergency_tickets_searchValues: action.payload.searchValues,
      });

    case InventoryActionType.APPROVE_PICKUP_TICKET_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.APPROVE_PICKUP_TICKET_FULFILLED:
      const pickingTicketList = [...state.pickingTicketList];
      pickingTicketList.splice(
        pickingTicketList.findIndex((e) => e.id === action.payload.id),
        1
      );
      return updateObject(state, {
        isLoading: false,
        pickingTicketList,
      });

    case InventoryActionType.CREATE_INVENTORY_PULL_REQUEST_TICKET_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.CREATE_INVENTORY_PULL_REQUEST_TICKET_REJECTED:
      return updateObject(state, { isLoading: false });
      case InventoryActionType.CREATE_INVENTORY_PULL_REQUEST_TICKET_FULFILLED:
      return updateObject(state, { isLoading: false, unavailableItems : action.payload && action.payload.data ? action.payload.data : [] });

    case InventoryActionType.GET_INVENTORY_ITEM_NAMES_PENDING:
      return updateObject(state, { inventoryItemNamesList: null });
    case InventoryActionType.GET_INVENTORY_ITEM_NAMES_FULFILLED:
      return updateObject(state, {
        inventoryItemNamesList: action.payload.itemsList,
      });

    case InventoryActionType.GET_JOB_USERS_INFO_PENDING:
      return updateObject(state, { jobUsersList: null });
    case InventoryActionType.GET_JOB_USERS_INFO_FULFILLED:
      return updateObject(state, { jobUsersList: action.payload.jobUsersList });

    case InventoryActionType.BOX_PAGE_CLICKED:
      return updateObject(state, { boxCurPage: action.payload });

    case InventoryActionType.PICKING_TICKET_PAGE_CLICKED:
      return updateObject(state, { pickingTicketCurPage: action.payload });

    case InventoryActionType.EMERGENCY_TICKET_PAGE_CLICKED:
      return updateObject(state, { emergencyTicketCurPage: action.payload });

    case InventoryActionType.ASSETS_PAGE_CLICKED:
      return updateObject(state, { assestsCurPage: action.payload });

    case InventoryActionType.GET_ALL_PICKUPS_TICKET_LIST_PENDING:
      return updateObject(state, {
        isLoading: true,
        pickupsTicketList: [],
      });
    case InventoryActionType.GET_ALL_PICKUPS_TICKET_LIST_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        dataGenerated: false,
        pickupsTicketList: action.payload.ticket_list,
        pickupsTicket_total_count: action.payload.total_ticket_count,
        pickupsTicket_total_pages: action.payload.total_ticket_pages,
        pickupsTicket_no_of_rows: action.payload.no_of_rows,
        pickupsTicketCurPage: action.payload.current_ticket_Page,
        pickupsTicketCurStatus:
          action.payload && action.payload.currentStatus
            ? action.payload.currentStatus
            : null,
      });

    case InventoryActionType.SEARCH_ALL_PICKUPS_TICKET_LIST_PENDING:
      return updateObject(state, {
        isLoading: true,
        // SearchOrResetPickupsTicketListClicked: true
      });
    case InventoryActionType.SEARCH_ALL_PICKUPS_TICKET_LIST_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        SearchOrResetPickupsTicketListClicked: false,
        pickupsTicketList: action.payload.ticket_list,
        pickupsTicket_total_pages: action.payload.total_ticket_pages,
        pickupsTicket_no_of_rows: action.payload.no_of_rows,
        pickupsTicketCurPage: action.payload.current_ticket_Page,
      });

    case InventoryActionType.PICKUPS_TICKET_LIST_PAGE_CLICKED:
      return updateObject(state, { pickupsTicketCurPage: action.payload });

    case InventoryActionType.GET_SINGLE_PICKUP_TICKET_PENDING:
      return updateObject(state, {
        isLoading: true,
        isFulfillmentManagerFormOpen: true,
        isPickupDetailsFormOpen: true,
      });
    case InventoryActionType.GET_SINGLE_PICKUP_TICKET_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        isFulfillmentManagerFormOpen: false,
        isPickupDetailsFormOpen: false,
        singlePickupTicket: action.payload.data,
      });

    case InventoryActionType.GET_ALL_INVENTORY_BOX_NAMES_LIST_PENDING:
      return updateObject(state, { inventoryBoxNamesList: null });
    case InventoryActionType.GET_ALL_INVENTORY_BOX_NAMES_LIST_FULFILLED:
      return updateObject(state, {
        inventoryBoxNamesList: action.payload.boxesList,
      });

    case InventoryActionType.READY_FOR_PICKUP_QR_GENERATOR_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.READY_FOR_PICKUP_QR_GENERATOR_FULFILLED:
      return updateObject(state, { isLoading: false });

    case InventoryActionType.READY_FOR_DELIVERY_QR_GENERATOR_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.READY_FOR_DELIVERY_QR_GENERATOR_FULFILLED:
      return updateObject(state, { isLoading: false });

    case InventoryActionType.GET_DELIVERY_ITEM_SCAN_PRESIGNED_URL_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.GET_DELIVERY_ITEM_SCAN_PRESIGNED_URL_FULFILLED:
      return updateObject(state, { isLoading: false });

    case InventoryActionType.GET_INVENTORY_ITEM_DELIVERED_SUCCESS_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.GET_INVENTORY_ITEM_DELIVERED_SUCCESS_FULFILLED:
      return updateObject(state, { isLoading: false });

    case InventoryActionType.GET_DELIVERY_RETURN_WAREHOUSE_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.GET_DELIVERY_RETURN_WAREHOUSE_FULFILLED:
      return updateObject(state, { isLoading: false });

    case InventoryActionType.GET_ALL_INVENTORY_LOCATION_LIST_PENDING:
      return updateObject(state, { inventoryLocationList: null });
    case InventoryActionType.GET_ALL_INVENTORY_LOCATION_LIST_FULFILLED:
      return updateObject(state, {
        inventoryLocationList: action.payload.locationsList,
      });

    case InventoryActionType.GET_LOCATION_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.GET_LOCATION_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        locations: action.payload.locations,
        location_count: action.payload.location_count,
      });

    case InventoryActionType.GET_LOCATION_ASSOCIATION_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.GET_LOCATION_ASSOCIATION_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        locationAssociation: action.payload.locations,
        location_association_count: action.payload.count,
      });

    case InventoryActionType.ADD_LOCATION_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.ADD_LOCATION_REJECTED:
      return updateObject(state, { isLoading: false });
    case InventoryActionType.ADD_LOCATION_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        locations: action.payload.locations,
        location_count: action.payload.location_count,
      });

    case InventoryActionType.GET_LOCATION_NAME_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.GET_LOCATION_NAME_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        locationName: action.payload.locations,
      });

    case InventoryActionType.GET_FULFILLMENT_MANAGER_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.GET_FULFILLMENT_MANAGER_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        fulfillmentManager: action.payload.users,
      });

    case InventoryActionType.ADD_LOCATION_ASSOCIATION_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.ADD_LOCATION_ASSOCIATION_REJECTED:
      return updateObject(state, { isLoading: false });
    case InventoryActionType.ADD_LOCATION_ASSOCIATION_FULFILLED:
      return updateObject(state, {
        isLoading: false,
        locationAssociation: action.payload.locationAssociation,
        location_association_count: action.payload.location_association_count,
      });

    case InventoryActionType.EDIT_LOCATION_CLICKED:
      return updateObject(state, {
        isOpenModel: true,
        isEdit: true,
        location: action.payload,
      });

    case InventoryActionType.EDIT_LOCATION_ASSOCIATION_CLICKED:
      return updateObject(state, {
        isOpenModel: true,
        isEdit: true,
        locationAssociate: action.payload,
      });

    case InventoryActionType.OPEN_MODEL_INVENTORY:
      return updateObject(state, {
        isOpenModel: true,
        isEdit: false,
      });
    case InventoryActionType.CLOSE_MODEL_INVENTORY:
      return updateObject(state, {
        isOpenModel: false,
      });

    case InventoryActionType.UPDATE_LOCATION_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.UPDATE_LOCATION_REJECTED:
      return updateObject(state, { isLoading: false });
    case InventoryActionType.UPDATE_LOCATION_FULFILLED:
      let updatedLocation = [...state.locations];
      updatedLocation[
        updatedLocation.findIndex(
          (e) => e.id === action.payload.data.location.id
        )
      ] = action.payload.data.location;
      return updateObject(state, {
        isLoading: false,
        location: {},
        isEdit: false,
        locations: updatedLocation,
      });

    case InventoryActionType.UPDATE_LOCATION_ASSOCIATION_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.UPDATE_LOCATION_ASSOCIATION_REJECTED:
      return updateObject(state, { isLoading: false });
    case InventoryActionType.UPDATE_LOCATION_ASSOCIATION_FULFILLED:
      let updatedLocationAssociation = [...state.locationAssociation];
      updatedLocationAssociation[
        updatedLocationAssociation.findIndex(
          (e) => e.id === action.payload.data.location.id
        )
      ] = action.payload.data.location;
      return updateObject(state, {
        isLoading: false,
        locationAssociate: {},
        isEdit: false,
        locationAssociation: updatedLocationAssociation,
      });

    case InventoryActionType.DELETE_LOCATION_PENDING:
      return updateObject(state, { isLoading: true });
      case InventoryActionType.DELETE_LOCATION_REJECTED:
      return updateObject(state, { isLoading: false });
    case InventoryActionType.DELETE_LOCATION_FULFILLED:
      const locations = [...state.locations];
      locations.splice(
        locations.findIndex((e) => e.id === action.payload.id),
        1
      );
      return updateObject(state, {
        isLoading: false,
        openInventoryDeleteModal: false,
        locations,
      });

      case InventoryActionType.NEW_LOCATION_CLICKED:
      return updateObject(state, { location: {}, isEdit: false });

      case InventoryActionType.NEW_LOCATION_ASSOCIATION_CLICKED:
      return updateObject(state, { locationAssociate: {}, isEdit: false });

      case InventoryActionType.GET_ALL_ASSEST_STOCK_PENDING:
        return updateObject(state, { isLoading: true });
      case InventoryActionType.GET_ALL_ASSEST_STOCK_FULFILLED:
        return updateObject(state, {
          dataGenerated: false,
          isLoading: false,
          assestStockCurPage: action.payload.assestsCurPage,
          assestStockList: action.payload.assestsList,
          assestStock_total_count: action.payload.assests_total_count,
          assestStock_no_of_rows: action.payload.assests_no_of_rows,
          assestStock_pages: action.payload.assests_pages,
          assetStock_searchValues: action.payload.searchValues,
        });

        case InventoryActionType.UPDATE_ASSET_STOCK_PENDING:
      return updateObject(state, { isLoading: true });
    case InventoryActionType.UPDATE_ASSET_STOCK_FULFILLED:
      return updateObject(state, { isLoading: false });

      case InventoryActionType.GET_SINGLE_ASSET_STOCK_PENDING:
        return updateObject(state, {
          isLoading: true,
          isEditAsset: false,
          assetStock: null,
          isUpdateAsset  : true,
        });
      case InventoryActionType.GET_SINGLE_ASSET_STOCK_FULFILLED:
        return updateObject(state, {
          isLoading: false,
          isEditAsset: true,
          assetStock: action.payload.data.subProduct,
          isUpdateAsset  : false,
        });

        case InventoryActionType.DELETE_ASSET_STOCK_PENDING:
          return updateObject(state, { isLoading: true });
    
        case InventoryActionType.DELETE_ASSET_STOCK_FULFILLED:
          const assestStockList = [...state.assestStockList];
          assestStockList.splice(
            assestStockList.findIndex((e) => e.id === action.payload.id),
            1
          );
          return updateObject(state, {
            isLoading: false,
            openInventoryDeleteModal: false,
            assestStockList,
          });

          case InventoryActionType.ASSET_STOCK_PAGE_CLICKED:
            return updateObject(state, { assestStockCurPage: action.payload });

            case InventoryActionType.ASSET_STOCK_CLICKED:
              return updateObject(state, { assestStockCurPage: 0 });

    default:
      return state;
  }
};
