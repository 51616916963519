import React, { useState, useEffect } from 'react';
import moment from "moment";
import { convertDateFormatV1, getHoursAndMinutesFormat_V2, makeFirstLetterCapital } from '../../../../../utility/utility';

import { userRoles } from '../../../../../utility/constants/constants';
import LeaveRequestRejectionForm from '../LeaveRequestRejectionForm/LeaveRequestRejectionForm';

import { Image } from 'antd';
import PreviewLeaveRequestComponentSkeleton from './PreviewLeaveRequestComponentSkeleton';
import { tzDateConverterTool_V1, tzDateConverterTool_V4, tzDateConverterTool_V5, tzInbetweenDaysFinder_V1, tzTimeConversionTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';

let PreviewLeaveRequestComponent = (props) => {
    debugger

    const [leaveRequestData, setLeaveRequestData] = useState({});

    useEffect(() => {
        setLeaveRequestData(props.singleLeaveRequestData);
        if (props.singleLeaveRequestData) {
            if (props.singleLeaveRequestData.id) {

            }
        }
    }, [props.singleLeaveRequestData])

    const convertStartEndTimeFormat = (time) => {
        let fetchedTimeValue = null;

        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }

        fetchedTimeValue = (time[0] + ':' + time[2] + ' ' + time[5]).toString(); // return adjusted time or original string

        return fetchedTimeValue;
    }

    const { handleSubmit, pristine, reset, submitting, error } = props;

    if (props.isLoad) {
        return (
            <>
                <PreviewLeaveRequestComponentSkeleton />
            </>
        );
    } else {
        return (
            <div>

                <main className="app-content add_account_modal add_frontline_pop">
                    <div className="appcontent_Inner user_newQuote pt-0">

                        <div className="container py-5">

                            <div className="row text-center mb-4">
                                <div className="col-lg-7 mx-auto">
                                    <h1 className="mb-0" style={{ color: '#4a4a4a', fontWeight: '500', fontSize: '45px' }}> Leave Request </h1>
                                    {/* <p className="lead mb-0" style={{ color: 'black' }}>Create an elegant product list using Bootstrap 4 list group</p> */}
                                </div>
                            </div>
                            <div className="addQuote_form">
                                <form onSubmit={handleSubmit}>

                                    <div className="row">
                                        <div className="col-lg-8 mx-auto">
                                            <ul className="list-group shadow rounded" style={{ marginLeft: '10%', marginRight: '10%' }}>

                                                {/* <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div className="media-body order-2 order-lg-1">
                                                            <h5 className="mt-0 font-weight-bold mb-2"> EXAMPLE </h5>
                                                            <p className="font-italic text-muted mb-0 small">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit fuga autem maiores necessitatibus.</p>
                                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                                <h6 className="font-weight-bold my-2">$220.00</h6>
                                                                <ul className="list-inline small">
                                                                    <li className="list-inline-item m-0"><i className="fa fa-star text-success"></i></li>
                                                                    <li className="list-inline-item m-0"><i className="fa fa-star text-success"></i></li>
                                                                    <li className="list-inline-item m-0"><i className="fa fa-star text-success"></i></li>
                                                                    <li className="list-inline-item m-0"><i className="fa fa-star text-success"></i></li>
                                                                    <li className="list-inline-item m-0"><i className="fa fa-star text-success"></i></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <img src="https://bootstrapious.com/i/snippets/sn-cards/shoes-4_vgfjy9.jpg" alt="Generic placeholder image" width="200" className="ml-lg-5 order-1 order-lg-2" />
                                                        <h3 className="ml-lg-5 order-1 order-lg-2">mhvhvjv</h3>
                                                    </div>
                                                </li> */}

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div className="media-body order-2 order-lg-1">
                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Leave Type </h5>
                                                        </div>
                                                        <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.type ? leaveRequestData.type : ''} </h5>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div className="media-body order-2 order-lg-1">
                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Leave Status </h5>
                                                        </div>
                                                        <h5 className={leaveRequestData && leaveRequestData.status == 'reject' ? "ml-lg-5 order-1 order-lg-2 text-danger" : leaveRequestData.status == 'approved' ? "ml-lg-5 order-1 order-lg-2 text-success" : "ml-lg-5 order-1 order-lg-2 text-warming"}>
                                                            {leaveRequestData ? leaveRequestData.status == 'reject' ? 'Rejected' : makeFirstLetterCapital(leaveRequestData.status) : ''}
                                                        </h5>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div className="media-body order-2 order-lg-1">
                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Request Created On </h5>
                                                        </div>
                                                        <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.created_at ? tzDateConverterTool_V1(leaveRequestData.created_at) : ''} </h5>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div className="media-body order-2 order-lg-1">
                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Date of Leave </h5>
                                                        </div>
                                                        {leaveRequestData && leaveRequestData.day_coverage == 'full' ? (
                                                            <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.start_date ? tzDateConverterTool_V4(leaveRequestData.start_date) + ' − ' + tzDateConverterTool_V4(leaveRequestData.end_date) : ''} </h5>
                                                        ) : (
                                                            <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.start_date ? tzDateConverterTool_V1(leaveRequestData.start_date) : ''} </h5>
                                                        )}
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div className="media-body order-2 order-lg-1">
                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Full Day / Partial Day </h5>
                                                        </div>
                                                        <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.day_coverage == 'full' ? 'Full Day' : 'Partial Day'} </h5>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body order-2 order-lg-1">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Duration </h5>
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                                                                <div>
                                                                    {leaveRequestData && leaveRequestData.day_coverage == 'full' ? (
                                                                        <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {tzInbetweenDaysFinder_V1(tzDateConverterTool_V5(leaveRequestData.start_date), tzDateConverterTool_V5(leaveRequestData.end_date))} </h5>
                                                                    ) : (
                                                                        <>
                                                                            <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.start_time && tzTimeConversionTool_V1(leaveRequestData.start_time, leaveRequestData.start_date) + ' − ' + tzTimeConversionTool_V1(leaveRequestData.end_time, leaveRequestData.start_date)} </h5>
                                                                            <h5 className="ml-lg-5 font-italic text-muted mb-0" style={{ fontSize: '15px' }}> {leaveRequestData && leaveRequestData.time_duration && getHoursAndMinutesFormat_V2(leaveRequestData.time_duration)} </h5>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                {leaveRequestData && leaveRequestData.info ? (
                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div className="media-body order-2 order-lg-1">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Job Name </h5>
                                                            </div>
                                                            <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.account ? makeFirstLetterCapital(leaveRequestData.account.name) : ''} </h5>
                                                        </div>
                                                    </li>
                                                ) : ''}

                                                {leaveRequestData && leaveRequestData.info ? (
                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div className="media-body order-2 order-lg-1">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Requested Reason </h5>
                                                            </div>
                                                            <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.info ? makeFirstLetterCapital(leaveRequestData.info) : ''} </h5>
                                                        </div>
                                                    </li>
                                                ) : ''}

                                                {leaveRequestData && leaveRequestData.reject_reason ? (
                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div className="media-body order-2 order-lg-1">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Rejected Reason </h5>
                                                            </div>
                                                            <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.reject_reason ? makeFirstLetterCapital(leaveRequestData.reject_reason) : ''} </h5>
                                                        </div>
                                                    </li>
                                                ) : ''}

                                                {leaveRequestData && leaveRequestData.status == 'reject' ? (
                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div className="media-body order-2 order-lg-1">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Rejected By </h5>
                                                            </div>
                                                            <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.manager ? makeFirstLetterCapital(leaveRequestData.manager.first_name) + ' ' + makeFirstLetterCapital(leaveRequestData.manager.last_name) : ''} </h5>
                                                        </div>
                                                    </li>
                                                ) : leaveRequestData && leaveRequestData.status == 'approved' ? (
                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div className="media-body order-2 order-lg-1">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Approved By </h5>
                                                            </div>
                                                            <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.manager ? makeFirstLetterCapital(leaveRequestData.manager.first_name) + ' ' + makeFirstLetterCapital(leaveRequestData.manager.last_name) : ''} </h5>
                                                        </div>
                                                    </li>
                                                ) : ""}

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body order-2 order-lg-1">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Requested By </h5>
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {leaveRequestData && leaveRequestData.user && leaveRequestData.user.photo_urls && leaveRequestData.user.photo_urls.small ? (
                                                                    <img src={leaveRequestData.user.photo_urls.small} alt="No Image" width="70" className="ml-lg-5 order-1 order-lg-2 rounded-circle" />
                                                                ) : (
                                                                    <img src='/images/icons/icn_user_gray_short.png' alt="No Image" width="50" className="ml-lg-5 order-1 order-lg-2 rounded-circle" />
                                                                )}
                                                                <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.user ? makeFirstLetterCapital(leaveRequestData.user.first_name) + '  ' + makeFirstLetterCapital(leaveRequestData.user.last_name) : ''} </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', width: '100%' }}>
                                                            <div className="media-body order-2 order-lg-1">
                                                                <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Manager Info </h5>
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                                                                {leaveRequestData && leaveRequestData.manager && leaveRequestData.manager.photo_urls && leaveRequestData.manager.photo_urls.small ? (
                                                                    <img src={leaveRequestData.manager.photo_urls.small} alt="No Image" width="70" className="ml-lg-5 order-1 order-lg-2 rounded-circle" />
                                                                ) : (
                                                                    <img src='/images/icons/icn_user_gray_short.png' alt="No Image" width="50" className="ml-lg-5 order-1 order-lg-2 rounded-circle" />
                                                                )}
                                                                <div>
                                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.manager ? makeFirstLetterCapital(leaveRequestData.manager.first_name) + ' ' + makeFirstLetterCapital(leaveRequestData.manager.last_name) : ''} </h5>
                                                                    <h5 className="ml-lg-5 font-italic text-muted mb-0" style={{ fontSize: '15px' }}> {leaveRequestData && leaveRequestData.manager ? leaveRequestData.manager.email : ''} </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                {leaveRequestData && leaveRequestData.attachments && leaveRequestData.attachments.length > 0 && (
                                                    <li className="list-group-item">
                                                        <div className="p-3">
                                                            <h5 className="mt-0 font-weight-bold mb-4 text-dark"> Attachments <img src="/images/icons/attachment_icon.png" alt="Icon" /> </h5>
                                                            <div className="addQuote_form mb-2 antdImgUpload" style={{ border: 'dashed', borderColor: '#71002f73', borderWidth: '3px' }}>
                                                                <div className='p-3' style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                    {leaveRequestData && leaveRequestData.attachments && leaveRequestData.attachments.map((data, index) => {
                                                                        debugger
                                                                        return (
                                                                            <div key={index} className='m-1' style={{ border: 'solid', borderRadius: '2px', borderColor: '#aeaeae', borderWidth: '2px' }}>
                                                                                <Image width={100} src={data.photo_urls.medium} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}

                                                {/* <li className="list-group-item">
                                                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                        <div className="media-body order-2 order-lg-1">
                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Manager Email </h5>
                                                        </div>
                                                        <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {leaveRequestData && leaveRequestData.manager ? leaveRequestData.manager.email : ''} </h5>
                                                    </div>
                                                </li> */}

                                            </ul>
                                        </div>
                                    </div>

                                    {!(props.user.role === userRoles.FRONTLINE) && leaveRequestData && leaveRequestData.status == 'requested' && (
                                        <div className="modal-footer">
                                            <button data-test="submit_button" type="button" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} onClick={() => { props.handleLeaveRequestSanction({ status: 'approved' }) }} >{props.isLoading ? "" : "Approve"}</button>
                                            <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" data-toggle="modal" data-target="#reasonForRejection" data-backdrop="static" data-keyboard="false" onClick={() => { props.handleFrequentTimeTracker_openModal(); }}> Reject </button>
                                        </div>
                                    )}

                                </form>
                            </div>
                        </div>

                    </div>
                </main>

                {props.isTimeTrackerModalOpen ? (
                    <LeaveRequestRejectionForm
                        {...props}
                        onSubmit={props.handleLeaveRequestSanction}
                        handleFrequentTimeTracker_closeModal={props.handleFrequentTimeTracker_closeModal}
                    />
                ) : null}

            </div>
        );
    }

};

export default PreviewLeaveRequestComponent;