
import  axios,{ API_VERSION, accessToken, formAuditBaseUrl } from '../../config';
import storage from '../../utility/storage';
import { getUser } from '../../utility/utility';
const pureAxios = require('axios');

const user = storage.get('user');

const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};


export const getSingleJobCardAPI = (id) => {
    return formAuditBaseUrl.get(API_VERSION + `buildingprofile/jobcardmaps/${id}` , headers);
};

export const addDailyKittingAPI = (body) => {
    return formAuditBaseUrl.post(API_VERSION + `inventory_module/dailykitlog/` ,body, headers);
};

export const editJobCardAPI = (body) => {
    return formAuditBaseUrl.put(API_VERSION + `buildingprofile/jobcardmaps` ,body, headers);
};

export const getAllJobCardAPI = (params) => {
    return formAuditBaseUrl.get(API_VERSION + `buildingprofile/jobcardmaps/`,{params} , headers);
};

export const getAllInventoryListAPI = (params) => {
    return axios.get(API_VERSION+"inventory/item/names" , headers);
};

export const getAllDailyKItting=(params)=>{
    return formAuditBaseUrl.get(API_VERSION + `inventory_module/dailykitlog/`,{params} , headers);
}

export const getSingleDailyKittingAPI = (id) => {
    return formAuditBaseUrl.get(API_VERSION + `inventory_module/dailykitlog/${id}` , headers);
};

export const editDailyKittingAPI = (body) => {
    return formAuditBaseUrl.put(API_VERSION + `inventory_module/dailykitlog/` ,body, headers);
};




export const getCostSummary = () => {
    return axios.get(API_VERSION + `inventory/monthly_summary/summary_details` ,
        {
            "cost": {
                "January": 1500,
                "February": 1500,
                "March": 1500,
                "April": 1500,
                "May": 1500,
                "June": 1500,
                "July": 1500,
                "August": 1500,
                "September": 1500,
                "October": 1500,
                "November": 1500,
                "December": 1500
            }
        }, headers);
};

export const getMonthlyReportsProductsAPI=(params)=>{
    
    //  params={
    //     //targetMonth:"2023-06",
    //     ...params,
    //     accountId:472

    // }
    
    return axios.get(API_VERSION + `inventory/monthly_summary` ,{params}, headers);
    
}

export const getMonthlyReportsCostAPI=(params)=>{
    
    return axios.get(API_VERSION + `inventory/monthly_summary/summary_details` ,{params}, headers);
    
}

export const addMonthlyUsageReportsAPI=(body)=>{
   return axios.put(API_VERSION+`inventory/monthly_summary/summary`,body,headers)
}

export const addMonthlyUsageReportsBulkAPI=(body)=>{
    return axios.put(API_VERSION+`inventory/monthly_summary/bulk_summary`,body,headers)
 }