import React, { useEffect, useState } from 'react';
import InspectionTable from './InspectionTable';
import PerformInspectionModal from './performInspectionModal';
import { Input } from '../UI/InputElement/InputElement';
import { reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { InspectionSearchValidator as validate } from '../../../utility/validator/validator';
import Dialog from '@material-ui/core/Dialog';
import { convertAccountsDataToCsvType, convertDateToPickerFormat, getUser } from '../../../utility/utility';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CSVLink, CSVDownload } from "react-csv";
import { userRoles, InspectionsFilter, InspectionFilterWithoutQQV, InspectionCustomerPresentFilter, InspectionsQQvFilter } from '../../../utility/constants/constants';
import GooglePlacesAutoComplete from '../../component/GooglePlacesAutoComplete/googlePlacesAutoComplete';
import $ from 'jquery';

const getAccountsDropDown = (accounts) => {

    return accounts && accounts.map(a => {
        return {
            value: a.id,
            label: a.name
        }
    })
}

const getUsersDropDown = (users) => {
    let inspectors = []
    users && users.map(user => {
        if (user.role !== 'client') {
            inspectors.push(user)
        }
    })
    return inspectors && inspectors.map(u => {
        return {
            value: u.id,
            label: u.first_name + ' ' + u.last_name
        }
    })
}

let Inspections = (props) => {


    let inspectionFilterOptions = InspectionsFilter.filter(inspection => {
        if (inspection.id === 'private' || inspection.id === 'notPrivate') {
            if (props.user.role !== userRoles.CLIENT) {
                return inspection;
            }
        }
        else {
            return inspection;
        }
    })

    const [accountList, setAccountList] = useState(getAccountsDropDown(props.accountsList));
    const [usersList, setUsersList] = useState(getUsersDropDown(props.dropDownUsersList));
    const [hideQQVsatus, setHideQQVsatus] = useState(false);



    useEffect(() => {
        setAccountList(getAccountsDropDown(props.accountsList))
    }, [props.accountsList])

    useEffect(() => {
        setUsersList(getUsersDropDown(props.dropDownUsersList))
    }, [props.dropDownUsersList])

    useEffect(() => {
        if (props.formStates) {
            if (props.formStates.qqv_customer_contact === "nonqqv_customer") {
                setHideQQVsatus(true);
            } else {
                setHideQQVsatus(false);
            }
        }
    }, [props.formStates])

    const noUserOptionsMessage = (a, b) => {
        return 'User not found';
    }

    const noAccountOptionsMessage = (a, b) => {
        return 'Job not found';
    }
    const userDetail = getUser()
    const { handleSubmit, pristine, reset, submitting, error } = props;
    return (
        <>
            <main className="app-content wraper_content inspection_wraper">
                <div className="tab_header search_filter_cont inspection_tab_content">
                    <form onSubmit={handleSubmit}>

                        {props.user.role === userRoles.CLIENT ?

                            <>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                        <label className="custom_label">
                                            <Input name="fromDate" maxDate={props.selectedValues.toDate ? props.selectedValues.toDate : new Date()} dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                            <div className="input-group-append cst_group_append">
                                                <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar"></i></span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                        <label className="custom_label">
                                            <Input name="toDate" minDate={props.selectedValues.fromDate ? props.selectedValues.fromDate : null} maxDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                            <div className="input-group-append cst_group_append">
                                                <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar"></i></span>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-sm-6 col-12">
                                        <div class="more_filter_block">
                                            <button style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                            <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"} </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :

                            <>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                        <div className="app-search">
                                            <Input name="name" className="fil_search_input" type="search" placeholder="Search By Inspection Form" />
                                            <button className="app-search__button">
                                                <i className="fa fa-search" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12">
                                        <div className="app-search">
                                            <Input name="status" type="select" options={inspectionFilterOptions} placeholder="Search By Filters" className="custom-select input-modifier add_user_select" />
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12">
                                        <div className="app-search">
                                            <Input name="id" className="fil_search_input" type="number" placeholder="Search By Id" />
                                            <button className="app-search__button">
                                                <i className="fa fa-search" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                    {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <Input name="account" noOptionsMessage={noAccountOptionsMessage} type="inputSelect" options={accountList} />
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="app-search">
                                    <div>
                                        <Input name="user" noOptionsMessage={noUserOptionsMessage} placeholder="Account" type="inputSelect" options={usersList} />
                                    </div>
                                </div>
                            </div> */}
                                    <div class="col-xl-4 col-lg-6 col-sm-6 col-12">
                                        {props.fromDashboard ? (
                                            <>
                                                <button style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                                <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"} </button>
                                            </>
                                        ) : (
                                            <div class="more_filter_block">
                                                <button type="button" className="btn btn-link more_filter_btn collapsed" data-toggle="collapse" data-target="#morefilterBtn" aria-expanded="true" aria-controls="morefilterBtn">
                                                    More Filters
                                                </button>
                                                <button style={{ minWidth: '90px' }} className={"btn cst_btn btn_danger mr-2  account_btn"} id="submit" type="submit">{"Go"} </button>
                                                <button style={{ minWidth: '90px' }} onClick={props.resetClicked} className={"btn cst_btn btn-outline-secondary account_btn"} id="reset" type="reset">{"Reset"} </button>
                                            </div>
                                        )}
                                    </div>
                                    {/* <div className="col-xl-3 col-lg-4 col-md-3 col-sm-3 col-6"> */}

                                    {/* </div> */}
                                    {props.user.role === userRoles.CLIENT || props.fromDashboard ? null :
                                        <div className="col-xl-1 col-lg-6 col-sm-6 col-12 text-right quotes_btn_wrap">
                                            <a onClick={props.newPerformInspectionModalClicked} href="javascript:void (0)" className="btn btn-secondary cst_btn" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#addAccountModal" data-test="add_new_inspection"><i className="fa fa-plus icn_plus"></i>New</a>
                                        </div>
                                    }
                                </div>
                                {/* <!-- more filter html --> */}
                                <div className="more_filter_content">
                                    <div id="morefilterBtn" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row">
                                                {props.fromDashboard ? null : <>
                                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                                        <label className="custom_label">
                                                            <Input name="fromDate" maxDate={props.selectedValues.toDate ? props.selectedValues.toDate : new Date()} dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                                            <div className="input-group-append cst_group_append">
                                                                <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar"></i></span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                                        <label className="custom_label">
                                                            <Input name="toDate" minDate={props.selectedValues.fromDate ? props.selectedValues.fromDate : null} maxDate={new Date()} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                                            <div className="input-group-append cst_group_append">
                                                                <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar"></i></span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="app-search">
                                                            <Input name="region" className="fil_search_input" type="search" placeholder="Region" />
                                                            <button className="app-search__button">
                                                                <i className="fa fa-search" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="app-search">
                                                            <Input name="qqv_customer_contact" type="select" options={InspectionCustomerPresentFilter} placeholder="Search Customer Present" className="custom-select input-modifier add_user_select" />
                                                        </div>
                                                    </div>

                                                </>}
                                            </div>
                                        </div>
                                        <div className="card-body select_ft_cbody p-0">
                                            <div className="row">
                                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                                    <Input name="account" noOptionsMessage={noAccountOptionsMessage} placeholder="Job" type="inputSelect" options={accountList} />
                                                </div>
                                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                                    <Input name="user" noOptionsMessage={noUserOptionsMessage} placeholder="Inspected By" type="inputSelect" options={usersList} />
                                                </div>
                                                <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12">
                                                    <div className="app-search">
                                                        <Input name="score" className="fil_search_input" type="search" placeholder="Score" />
                                                        <button className="app-search__button">
                                                            <i className="fa fa-search" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                {hideQQVsatus ? null :
                                                    <>
                                                        {
                                                            userDetail.mode === 'internal' ? null : <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="app-search">
                                                                    <Input name="qqv_status" type="select" options={InspectionsQQvFilter} placeholder="Search By QQV" className="custom-select input-modifier add_user_select" />
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }

                                            </div>
                                        </div>


                                    </div>
                                </div> </>}

                    </form>
                </div>


                <section className="account_sec inspection_tab_content appcontent_Inner">
                    {/* <div className="export_csv_block">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label>
                                    <Input name="fromDate" maxDate={props.selectedValues.toDate ? props.selectedValues.toDate : null} dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                    <div className="input-group-append cst_group_append">
                                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar"></i></span>
                                    </div>
                                </label>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label>
                                    <Input name="toDate" minDate={props.selectedValues.fromDate ? props.selectedValues.fromDate : null} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                    <div className="input-group-append cst_group_append">
                                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar"></i></span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div> */}
                    <div className="quotes_table_content accounts_table_contnet table-responsive">

                        <div className="table quotes_table user_react_table">
                            <InspectionTable
                                {...props}
                                deleteAccount={props.deleteAccount}
                                getInspectionsList={props.getInspectionsList}
                                getUserInspcetionsList={props.getUserInspcetionsList}
                                user={props.user}
                                onAccountsPageSizeChange={props.onAccountsPageSizeChange}
                                inspectionsList={props.inspectionsList}
                                isLoading={props.isLoading}
                                previewInspcetionClicked={props.previewInspcetionClicked}
                                editInspectionClicked={props.editInspectionClicked}
                                openDeleteModel={props.openDeleteModel}
                                handleClickDeleteModel={props.handleClickDeleteModel}
                                accounts_rows={props.accounts_rows}
                                onInspectionExportClicked={props.onInspectionExportClicked}
                                inspectionCSvClikedReport={props.inspectionCSvClikedReport}
                                data_list={props.data_list}
                                fields={props.fields}
                                latestInspectionGoClicked={props.latestInspectionGoClicked}
                                previewBlankTargetScreen={props.previewBlankTargetScreen}
                            />
                        </div>

                    </div>
                    {/* <div className="clearfix"></div>
                <div className="modal-footer">
                    <button type="button" className="btn btn_danger cst_btn">Save</button>
                    <button type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Cancel</button>
                </div> */}
                </section>
            </main>
            <Dialog
                open={props.openDeleteModel}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
                </DialogTitle>

                <DialogActions>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => props.deleteInspection(props.deleteObject.id)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
                    <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
                    {/* <Button onClick={props.handleClose} variant="contained" color="primary">
                        Cancel
                        </Button>


                    <Button onClick={() => props.deleteAccount(props.deleteObject.id)} disabled={props.isLoading} variant="contained" color="secondary">
                        {props.isLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button> */}
                </DialogActions>
            </Dialog>
            {/* {props.openPerformInspectionModal ? */}
            <PerformInspectionModal
                user={props.user}
                isFormEmpty={props.isFormEmpty}
                isAccountEmpty={props.isAccountEmpty}
                openPerformInspectionModal={props.openPerformInspectionModal}
                onAccountChange={props.onAccountChange}
                isLoading={props.isLoading}
                onSubmit={props.goToPerformInspection}
                userAccountLists={props.userAccountLists ? props.userAccountLists : []}
                accountsList={props.accountsList ? props.accountsList : []}
                inspectionFormsList={props.inspectionFormsList}
                cancelPerformInspectionModalClicked={props.cancelPerformInspectionModalClicked}
                clearLatestInspection={props.clearLatestInspection}
                latestInspectionModalOpen={props.latestInspectionModalOpen}
                latestInspectionList={props.latestInspectionList}
                previewInspcetionClicked={props.previewInspcetionClicked}
                latestInspectionGoClicked={props.latestInspectionGoClicked}
                previewLatestInspcetionClicked={props.previewLatestInspcetionClicked}
                previewBlankTargetScreen={props.previewBlankTargetScreen}
                goToLatestPreviewInspectionRouter={props.goToLatestPreviewInspectionRouter}
                history={props.history}
            />
            {/* : null} */}

        </>

    )
}


const mapStateToProps = (state) => {
    let id = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.id ? state.adminOrSuperAdminReducer.searchInspectionValues.value.id : "";
    let name = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.name ? state.adminOrSuperAdminReducer.searchInspectionValues.value.name : "";
    let status = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.status ? state.adminOrSuperAdminReducer.searchInspectionValues.value.status : "";
    let fromDate = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.fromDate ? convertDateToPickerFormat(state.adminOrSuperAdminReducer.searchInspectionValues.value.fromDate) : "";
    let toDate = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.toDate ? convertDateToPickerFormat(state.adminOrSuperAdminReducer.searchInspectionValues.value.toDate) : "";
    let region = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.region ? state.adminOrSuperAdminReducer.searchInspectionValues.value.region : "";
    let qqv_status = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.qqv_status ? state.adminOrSuperAdminReducer.searchInspectionValues.value.qqv_status : "";
    let score = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.score ? state.adminOrSuperAdminReducer.searchInspectionValues.value.score : "";
    let account = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.account ? state.adminOrSuperAdminReducer.searchInspectionValues.value.account : "";
    let user = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.user ? state.adminOrSuperAdminReducer.searchInspectionValues.value.user : "";
    let qqv_customer_contact = state.adminOrSuperAdminReducer.searchInspectionValues && state.adminOrSuperAdminReducer.searchInspectionValues.value && state.adminOrSuperAdminReducer.searchInspectionValues.value.qqv_customer_contact ? state.adminOrSuperAdminReducer.searchInspectionValues.value.qqv_customer_contact : "";

    return {
        initialValues: { id, name, status, fromDate, toDate, region, score, account, user, qqv_status, qqv_customer_contact },
        formStates: getFormValues('searchInspection')(state)
    }

}


Inspections = reduxForm({
    form: 'searchInspection',
    validate,
    enableReinitialize: true
})(Inspections);

const selector = formValueSelector('searchInspection') // <-- same as form name
Inspections = connect(state => {

    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
})(Inspections)

Inspections = connect(mapStateToProps)(Inspections)

export default Inspections;