import React, { useEffect, useState } from 'react'
import { Input } from '../../../../UI/InputElement/reactFormHookInputField'
import withFormHoc from '../../../../../../hoc/withReactHookForm/withReactHookForm'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import JobCardModalLightDuty from '../Modals/JobCardModal'
import JobCardModalVaccum from '../Modals/VaccumModal'
import JobCardModalRestroom from '../Modals/RestroomModal'
import JobCardModalBlank from '../Modals/BlankModal'
import JobCardModalUtility from '../Modals/UtilityModal'
import AssetsFormSkeleton from '../../../../Inventory/Assets/NewAssetsForm/AssetsFormSkeleton'
import SuccessAlert from '../../../../../../utility/successAlert/successAlert'
import errorHandler from '../../../../../../utility/errorHandler/errorHandler'
function NewJobCardsComponent(props) {
    const { jobList, managersList } = useSelector((state) => state.FormAuditModReducer)
    const { isUpdatejobCard, isLoading } = useSelector((state) => state.BuildingProfileReducer)
    const typeWatch = useWatch({
        name: "type",
        control: props.control
    })
    const defaultTypes = [
        {
            label: "Light Duty",
            value: "Light Duty"
        },
        {
            label: "Vacuum",
            value: "Vacuum"
        },
        {
            label: "Restroom",
            value: "Restroom"
        },
        {
            label: "Utility",
            value: "Utility"
        },
        {
            label: "Blanks",
            value: "Blanks"
        }
    ]

    //FOR STRUCTURING 
    const getJobList = (job) => {
        if (job && job.length > 0) {
            return job && job.map((data) => {
                return {
                    value: data.id,
                    label: data.name,
                }
            })
        } else {
            return []
        }
    };

    const ValidateHours = (value) => {
        if (+value >= 1 && +value <= 24) {
            return true
        }
        return "Hours must be between one to twenty four"
    }



    const [dayCount, setDayCount] = useState(1)



    const defaultForm = {
        type: "Light Duty",
        version_date: null,
        by: "",
        account_id: "",
        location: "",
        team: "",
        shift_hours: "",
        start_time: "",
        finish_time: "",
        tasks: {
            shift_length: "",
            detail_time: "",
            friday_project_time: "",
            routine_time_3c: "",
            routine_time_4c: ""
        },
        coreMaps: {
            core_map_1: "",
            core_map_2: "",
            core_map_3: "",
            core_map_4: "",
            notes: {
                notes_1: "",
                notes_2: "",
                notes_3: "",
                notes_4: ""
            }
        }
    }

    const data = {
        1: {
            core_1: "Iam Core 1",
            core_one_time: "",
            core_one_notes: "Iam Core 1 notes ",
            core_2: "Iam Core 2",
            core_two_time: "",
            core_two_notes: "Iam Core 2notes",
            core_3: "Iam Core 3",
            core_three_notes: "",
            core_three_time: "Iam Core 3 notes",
            core_4: "Iam Core 4",
            core_four_notes: "Iam Core 4 notes",
            core_four_time: "",
            deepclean: "Iam Core Deepclean",
            deepclean_notes: "Iam deepclen notes",
            deepclean_time: ""
        },
        2: {
            core_1: "Iam Core 1 Day2",
            core_one_time: "",
            core_one_notes: "Iam Core 1 notes day2",
            core_2: "Iam Core 2",
            core_two_time: "",
            core_two_notes: "Iam Core 2notes Day 2",
            core_3: "Iam Core 3",
            core_three_notes: "",
            core_three_time: "Iam Core 3 notes day 2",
            core_4: "Iam Core 4",
            core_four_notes: "Iam Core 4 notes day 2",
            core_four_time: "",
            deepclean: "Iam Core Deepclean",
            deepclean_notes: "Iam deepclen notes day 2",
            deepclean_time: ""
        },
        3: {
            core_1: "Iam Core 1 day 3",
            core_one_time: new Date(),
            core_one_notes: "Iam Core 1 notes day 3",
            core_2: "Iam Core 2",
            core_two_time: new Date(),
            core_two_notes: "Iam Core 2notes day 3",
            core_3: "Iam Core 3",
            core_three_notes: "Iam Core 3 notes day 3",
            core_three_time: new Date(),
            core_4: "Iam Core 4",
            core_four_notes: "Iam Core 4 notes day 3",
            core_four_time: new Date(),
            deepclean: "Iam Core Deepclean day 3",
            deepclean_notes: "Iam deepclen notes day 3 day 3",
            deepclean_time: new Date()
        },
        4: {
            core_1: "Iam Core 1 day 4",
            core_one_time: new Date(),
            core_one_notes: "Iam Core 1 notes day 4",
            core_2: "Iam Core 2",
            core_two_time: new Date(),
            core_two_notes: "Iam Core 2notes day 4",
            core_3: "Iam Core 4",
            core_three_notes: new Date(),
            core_three_time: "Iam Core 4 notes day 4",
            core_4: "Iam Core 4",
            core_four_notes: "Iam Core 4 notes day 4",
            core_four_time: new Date(),
            deepclean: "Iam Core Deepclean day 4",
            deepclean_notes: "Iam deepclen notes day 4 day 4",
            deepclean_time: new Date()
        },
        5: {
            core_1: "Iam Core 1 day 5",
            core_one_time: new Date(),
            core_one_notes: "Iam Core 1 notes day 5",
            core_2: "Iam Core 2",
            core_two_time: new Date(),
            core_two_notes: "Iam Core 2notes day 5",
            core_3: "Iam Core 3",
            core_three_notes: new Date(),
            core_three_time: "Iam Core 4 notes day 5",
            core_4: "Iam Core 4",
            core_four_notes: "Iam Core 4 notes day 5",
            core_four_time: new Date(),
            deepclean: "Iam Core Deepclean day 5",
            deepclean_notes: "Iam deepclen notes day 4 day 5",
            deepclean_time: new Date()
        }
    }

    const [modalData, setModalData] = useState(null)

    const handleDays = (count, e) => {

        setDayCount(count)
        // if(props.isView || props.isEdit){
        
        //const sectionData = props.getValues(`sections[${count - 1}]`)

        const sectionData=props.getValues(`sections`)?.find((item)=> item?.sort ===count )

        if (sectionData) {
            setModalData(sectionData)
        }
        else {
            setModalData(null)
        }


    }

    const handleType = (e) => {
        e.preventDefault();
        if (!typeWatch) {
            return errorHandler({ data: true, message: "Please select any type first" })
        }
    }

    const handleValidateTime = (finish_time_data) => {

        const startTime = props.getValues(`start_time`)

        if (!startTime) return true


        if (!finish_time_data) return true
        if (finish_time_data > startTime) {
            return true
        }
        else {

            //props.setFocus(`items.${props?.index}.scheduled_date`)
            return "Finish time data should be greater than start time"
        }

    }

    useEffect(() => {
        if (!props.isView && !props?.isEdit) {
            props.reset({ ...defaultForm, type: typeWatch, account_id: "" })
        }
    }, [typeWatch])

    const [listOfJob, setJobList] = useState(getJobList(jobList))

    useEffect(() => {
        setJobList(getJobList(jobList))
    }, [jobList])

    const closeModal = () => {

        const closeButton = document.getElementById("CloseModal")
        closeButton.click()
    }

    if (isUpdatejobCard) {

        return (
            <AssetsFormSkeleton />
        )
    }

    return (
        <main class="app-content wraper_content chart_section">
            <section class="teammanage_formmain">
                <form class="container" onSubmit={props.handleSubmit((data) => {
                    
                    if(data.sections== undefined || data.sections == null  ||data.sections?.length <5 || data.sections.length !==5){
                        errorHandler('Please setup all days ')
                    }else{

                        props.onSubmit(data)
                    }
                })} noValidate>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="team_manage_head">
                                <h1 class="tm_top_head">Job and Core Map Cards - {typeWatch}</h1>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="tm_form_wrapper">
                                <div class="tm_form_wrap">
                                    <div class="tm_form_innner">
                                        <div class="form">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12">
                                                    <div class="form-group">

                                                        <Input
                                                            type="inputSelect"
                                                            labelClasses="form-label"
                                                            control={props.control}
                                                            controller={props.controller}
                                                            selectLabel="Type"
                                                            selectName="type"
                                                            id="type"
                                                            selectOptions={defaultTypes}
                                                            //defaultValue={typeWatch}
                                                            placeholder="Please select"
                                                            selectClasses="form-select"
                                                            disabled={props?.isView || props?.isEdit}
                                                        />
                                                        {/* <div class="dropdown_caret">
                                                        <img
                                                            src="/images/icons/dropdown_caret.svg"
                                                            alt="dropdown_caret"
                                                        />
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <Input
                                                            type="date"
                                                            //datePickerType="month"
                                                            formControlClasses="form-control"
                                                            id={`version_date`}

                                                            placeholder="MM/DD/YYYY"
                                                            control={props.control}
                                                            controller={props.controller}
                                                            selectName={`version_date`}
                                                            dateFormat="MM/dd/yyyy"
                                                            // minDate={new Date().setDate(current.getDate() - 400)}
                                                            preventReadOnly={false}
                                                            label="Version Date"
                                                            formControlProps={props.register(`version_date`, {
                                                                required: "This field is required", maxLength: 80
                                                            })}
                                                            validationErrorMessage={props.errors?.version_date?.message ?? ""}
                                                            disabled={props?.isView}

                                                        />

                                                        {/* <div class="calendar_icon">
                                                        <img
                                                            src="/images/icons/calendar.svg"
                                                            alt="calendar"
                                                        />
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        {/* <label for="jobname" class="form-label">By</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="jobname"
                                                        aria-describedby=""
                                                        placeholder="By"
                                                    /> */}

                                                        <Input
                                                            type="text"

                                                            id={`by`}
                                                            name={`by`}
                                                            formControlClasses="form-control"
                                                            placeholder="By"
                                                            required
                                                            label="By"
                                                            formControlProps={props.register(
                                                                `by`,
                                                                {
                                                                    required: "This field is required",
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.by?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />

                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <Input
                                                            type="inputSelect"
                                                            labelClasses="form-label"
                                                            control={props.control}
                                                            controller={props.controller}
                                                            selectLabel="Facility"
                                                            selectName="account_id"
                                                            id="account_id"
                                                            selectOptions={listOfJob}
                                                            placeholder={"Please select"}
                                                            //defaultValue={typeWatch}
                                                            required={props.register('account_id', {
                                                                required: "This field is required"
                                                            })}
                                                            validationErrorMessage={props.errors['account_id']?.message}
                                                            selectClasses="form-select"
                                                            disabled={props?.isView}
                                                        />
                                                        {/* <label for="disabledSelect" class="form-label"
                                                    >Facility</label>
                                                    <select id="disabledSelect" class="form-select">
                                                        <option>Please Select</option>
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                    </select> */}
                                                        {/* <div class="dropdown_caret">
                                                        <img
                                                            src="/images/icons/dropdown_caret.svg"
                                                            alt="dropdown_caret"
                                                        />
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        {/* <label for="jobname" class="form-label"
                                                    >Location</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="jobname"
                                                        aria-describedby=""
                                                        placeholder="Location"
                                                    /> */}

                                                        <Input
                                                            type="text"

                                                            id={`location`}
                                                            name={`location`}
                                                            formControlClasses="form-control"
                                                            placeholder="Location"
                                                            required
                                                            label="Location"
                                                            formControlProps={props.register(
                                                                `location`,
                                                                {
                                                                    required: "This field is required",
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.location?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />

                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        {/* <label for="jobname" class="form-label"
                                                    >Shift hours</label>
                                                    <input
                                                        type="date"
                                                        class="form-control"
                                                        id="jobname"
                                                        aria-describedby=""
                                                        placeholder="Shift hours"
                                                    /> */}

                                                        <Input
                                                            type="number"
                                                            id={`shift_hours`}
                                                            name={`shift_hours`}
                                                            formControlClasses="form-control"
                                                            placeholder="Shift hours"
                                                            required
                                                            label="Shift hours"
                                                            formControlProps={props.register(
                                                                `shift_hours`,
                                                                {
                                                                    required: "This field is required",
                                                                    validate: ValidateHours
                                                                }
                                                            )}
                                                            isIcon={true}
                                                            icon={<div class="calendar_icon">
                                                                <img
                                                                    src="/images/icons/reminder.svg"
                                                                    alt="calendar"
                                                                />
                                                            </div>}
                                                            validationErrorMessage={
                                                                props.errors?.shift_hours?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />

                                                        {/* <div class="calendar_icon">
                                                        <img
                                                            src="/images/icons/reminder.svg"
                                                            alt="calendar"
                                                        />
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">



                                                        <Input
                                                            type="text"
                                                            id={`team`}
                                                            name={`team`}
                                                            formControlClasses="form-control"
                                                            placeholder="Team"
                                                            required
                                                            label="Team"
                                                            formControlProps={props.register(
                                                                `team`,
                                                                {
                                                                    required: "This field is required",
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.team?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />


                                                        {/* <div class="dropdown_caret">
                                                        <img
                                                            src="/images/icons/dropdown_caret.svg"
                                                            alt="dropdown_caret"
                                                        />
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        {/* <label for="jobname" class="form-label"
                                                    >Start Time</label>
                                                    <input
                                                        type="date"
                                                        class="form-control"
                                                        id="jobname"
                                                        aria-describedby=""
                                                        placeholder="Start Time"
                                                    /> */}

                                                        {/* <Input
                                                        type="date"
                                                        //datePickerType="month"
                                                        formControlClasses="form-control"
                                                        id={`start_time`}
                                                        placeholder="MM /DD/ YY"
                                                        control={props.control}
                                                        controller={props.controller}
                                                        selectName={`start_time`}
                                                        dateFormat="MM/dd/yyyy"
                                                        // minDate={new Date().setDate(current.getDate() - 400)}
                                                        preventReadOnly={false}
                                                        label="Start Time"
                                                        formControlProps={props.register(`start_time`, {
                                                            required: "This field is required", maxLength: 80
                                                        })}
                                                        validationErrorMessage={props.errors?.start_time?.message ?? ""}
                                                    // disabled={props?.isView}
                                                    /> */}

                                                        <Input
                                                            type="date"
                                                            datePickerType="time"
                                                            formControlClasses="form-control"
                                                            id={`start_time`}
                                                            placeholder="Start Time"
                                                            control={props.control}
                                                            controller={props.controller}
                                                            selectName={`start_time`}
                                                            dateFormat="MM/dd/yyyy"
                                                            timeConstraints={{ minutes: { step: 5 } }}
                                                            // timeFormat={"hh:mm: A"}

                                                            // minDate={new Date().setDate(current.getDate() - 400)}
                                                            // preventReadOnly={false}
                                                            label="Start Time"
                                                            formControlProps={props.register(`start_time`, {
                                                                required: "This field is required",
                                                                maxLength: 80,
                                                            })}
                                                            validationErrorMessage={
                                                                props.errors?.start_time?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />

                                                        {/* <div class="calendar_icon">
                                                        <img
                                                            src="/images/icons/reminder.svg"
                                                            alt="calendar"
                                                        />
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        {/* <label for="jobname" class="form-label"
                                                    >Finish Time</label>
                                                    <input
                                                        type="date"
                                                        class="form-control"
                                                        id="jobname"
                                                        aria-describedby=""
                                                        placeholder="Finish Time"
                                                    /> */}

                                                        {/* <Input
                                                        type="date"
                                                        //datePickerType="month"
                                                        formControlClasses="form-control"
                                                        id={`finish_time`}
                                                        placeholder="MM /DD/ YY"
                                                        control={props.control}
                                                        controller={props.controller}
                                                        selectName={`finish_time`}
                                                        dateFormat="MM/dd/yyyy"
                                                        // minDate={new Date().setDate(current.getDate() - 400)}
                                                        preventReadOnly={false}
                                                        label="Finish Time"
                                                        formControlProps={props.register(`finish_time`, {
                                                            required: "This field is required", maxLength: 80
                                                        })}
                                                        validationErrorMessage={props.errors?.finish_time?.message ?? ""}
                                                    // disabled={props?.isView}
                                                    /> */}


                                                        <Input
                                                            type="date"
                                                            datePickerType="time"
                                                            formControlClasses="form-control"
                                                            id={`finish_time`}
                                                            placeholder="Finish Time"
                                                            control={props.control}
                                                            controller={props.controller}
                                                            selectName={`finish_time`}
                                                            dateFormat="MM/dd/yyyy"
                                                            timeConstraints={{ minutes: { step: 5 } }}
                                                            // timeFormat={"hh:mm: A"}
                                                            // minDate={new Date().setDate(current.getDate() - 400)}
                                                            // preventReadOnly={false}
                                                            label="Finish Time"
                                                            formControlProps={props.register(`finish_time`, {
                                                                required: "This field is required",
                                                                validate: handleValidateTime
                                                            })}
                                                            validationErrorMessage={
                                                                props.errors?.finish_time?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />

                                                        {/* <div class="calendar_icon">
                                                        <img
                                                            src="/images/icons/reminder.svg"
                                                            alt="calendar"
                                                        />
                                                    </div> */}
                                                    </div>
                                                </div>

                                                {
                                                    typeWatch ?
                                                        <div class="col-md-12 col-sm-12">
                                                            <div class="setup_box_btn">
                                                                <div class="form-group">
                                                                    <button
                                                                        type="button"
                                                                        class="btn theme_def_btn1"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#jobcard"
                                                                        onClick={(e) => handleDays(1, e)}
                                                                    >
                                                                        Setup Day 1
                                                                    </button>
                                                                    <button type="button" class="btn theme_def_btn1"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#jobcard"
                                                                        onClick={(e) => handleDays(2)}
                                                                    >
                                                                        Setup Day 2
                                                                    </button>
                                                                    <button type="button" class="btn theme_def_btn1"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#jobcard"
                                                                        onClick={(e) => handleDays(3)}
                                                                    >
                                                                        Setup Day 3
                                                                    </button>
                                                                    <button type="button" class="btn theme_def_btn1"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#jobcard"
                                                                        onClick={(e) => handleDays(4)}
                                                                    >
                                                                        Setup Day 4
                                                                    </button>
                                                                    <button type="button" class="btn theme_def_btn1"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#jobcard"
                                                                        onClick={(e) => handleDays(5)}
                                                                    >
                                                                        Setup Day 5
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        <div class="col-md-12 col-sm-12">
                                                            <div class="setup_box_btn">
                                                                <div class="form-group">
                                                                    <button
                                                                        type="button"
                                                                        class="btn theme_def_btn1"
                                                                        onClick={handleType}
                                                                    >
                                                                        Setup Day 1
                                                                    </button>
                                                                    <button type="button" class="btn theme_def_btn1" onClick={handleType}

                                                                    >
                                                                        Setup Day 2
                                                                    </button>
                                                                    <button type="button" class="btn theme_def_btn1" onClick={handleType}

                                                                    >
                                                                        Setup Day 3
                                                                    </button>
                                                                    <button type="button" class="btn theme_def_btn1" onClick={handleType}


                                                                    >
                                                                        Setup Day 4
                                                                    </button>
                                                                    <button type="button" class="btn theme_def_btn1" onClick={handleType}

                                                                    >
                                                                        Setup Day 5
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tm_form_wrap">
                                    <div class="tm_form_innner">
                                        <div class="form">
                                            <div class="row">
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        {/* <label for="jobname" class="form-label"
                                                    >Core Map (I)</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="jobname"
                                                        aria-describedby=""
                                                        placeholder="Core Map (I)"
                                                    /> */}


                                                        <Input
                                                            type="text"
                                                            id={`coreMaps.core_map_1`}
                                                            name={`coreMaps.core_map_1`}
                                                            formControlClasses="form-control"
                                                            placeholder="Core Map (I)"
                                                            required
                                                            label="Core Map (I)"
                                                            formControlProps={props.register(
                                                                `coreMaps.core_map_1`,
                                                                {
                                                                    required: "This field is required",
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.coreMaps?.core_map_1?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />

                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        {/* <label for="jobname" class="form-label"
                                                    >Core Map (II)</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="jobname"
                                                        aria-describedby=""
                                                        placeholder="Core Map (II)"
                                                    /> */}
                                                        <Input
                                                            type="text"
                                                            id={`coreMaps.core_map_2`}
                                                            name={`coreMaps.core_map_2`}
                                                            formControlClasses="form-control"
                                                            placeholder="Core Map (II)"
                                                            required
                                                            label="Core Map (II)"
                                                            formControlProps={props.register(
                                                                `coreMaps.core_map_2`,
                                                                {
                                                                    required: "This field is required",
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.coreMaps?.core_map_2?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        {/* <label for="jobname" class="form-label"
                                                    >Core Map (III)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="jobname"
                                                        aria-describedby=""
                                                        placeholder="Core Map (III)"
                                                    /> */}

                                                        <Input
                                                            type="text"
                                                            id={`coreMaps.core_map_3`}
                                                            name={`coreMaps.core_map_3`}
                                                            formControlClasses="form-control"
                                                            placeholder="Core Map (III)"
                                                            required
                                                            label="Core Map (III)"
                                                            formControlProps={props.register(
                                                                `coreMaps.core_map_3`,
                                                                {
                                                                    required: "This field is required",
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.coreMaps?.core_map_3?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        {/* <label for="jobname" class="form-label"
                                                    >Core Map (IV)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="jobname"
                                                        aria-describedby=""
                                                        placeholder="Core Map (IV)"
                                                    /> */}

                                                        <Input
                                                            type="text"
                                                            id={`coreMaps.core_map_4`}
                                                            name={`coreMaps.core_map_4`}
                                                            formControlClasses="form-control"
                                                            placeholder="Core Map (IV)"
                                                            required
                                                            label="Core Map (IV)"
                                                            formControlProps={props.register(
                                                                `coreMaps.core_map_4`,
                                                                {
                                                                    required: "This field is required",
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.coreMaps?.core_map_4?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tm_form_wrap">
                                    <div class="tm_form_innner">
                                        <div class="form">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="jobname" class="form-label"
                                                        ><u>Notes</u></label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        {/* <label for="jobname" class="form-label"
                                                    >Notes (I)</label
                                                    >
                                                    <textarea
                                                        class="form-control"
                                                        placeholder="Type here"
                                                        id="floatingTextarea"
                                                    ></textarea> */}
                                                        <Input
                                                            type="textarea"
                                                            id={`coreMaps.notes.notes_1`}
                                                            name={`coreMaps.notes.notes_1`}
                                                            formControlClasses="form-control"
                                                            placeholder="Type here"
                                                            required
                                                            label="Notes (I)"
                                                            formControlProps={props.register(
                                                                `coreMaps.notes.notes_1`,
                                                                {
                                                                    required: "This field is required",
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.coreMaps?.notes?.notes_1?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />


                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        {/* <label for="jobname" class="form-label"
                                                    >Notes (II)</label
                                                    >
                                                    <textarea
                                                        class="form-control"
                                                        placeholder="Type here"
                                                        id="floatingTextarea"
                                                    ></textarea> */}

                                                        <Input
                                                            type="textarea"
                                                            id={`coreMaps.notes.notes_2`}
                                                            name={`coreMaps.notes.notes_2`}
                                                            formControlClasses="form-control"
                                                            placeholder="Type here"
                                                            required
                                                            label="Notes (II)"
                                                            formControlProps={props.register(
                                                                `coreMaps.notes.notes_2`,
                                                                {
                                                                    required: "This field is required",
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.coreMaps?.notes?.notes_2?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />

                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        {/* <label for="jobname" class="form-label"
                                                    >Notes (III)
                                                    </label>
                                                    <textarea
                                                        class="form-control"
                                                        placeholder="Type here"
                                                        id="floatingTextarea"
                                                    ></textarea> */}

                                                        <Input
                                                            type="textarea"
                                                            id={`coreMaps.notes.notes_3`}
                                                            name={`coreMaps.notes.notes_3`}
                                                            formControlClasses="form-control"
                                                            placeholder="Type here"
                                                            required
                                                            label="Notes (III)"
                                                            formControlProps={props.register(
                                                                `coreMaps.notes.notes_3`,
                                                                {
                                                                    required: "This field is required",
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.coreMaps?.notes?.notes_3?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        {/* <label for="jobname" class="form-label"
                                                    >Notes (IV)
                                                    </label>
                                                    <textarea
                                                        class="form-control"
                                                        placeholder="Type here"
                                                        id="floatingTextarea"
                                                    ></textarea> */}

                                                        <Input
                                                            type="textarea"
                                                            id={`coreMaps.notes.notes_4`}
                                                            name={`coreMaps.notes.notes_4`}
                                                            formControlClasses="form-control"
                                                            placeholder="Type here"
                                                            required
                                                            label="Notes (IV)"
                                                            formControlProps={props.register(
                                                                `coreMaps.notes.notes_4`,
                                                                {
                                                                    required: "This field is required",
                                                                }
                                                            )}
                                                            validationErrorMessage={
                                                                props.errors?.coreMaps?.notes.notes_4?.message ?? ""
                                                            }
                                                            disabled={props?.isView}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    typeWatch !== "Utility" ? <div class="tm_form_wrap">
                                        <div class="tm_form_innner">
                                            <div class="form">
                                                <div class="row">
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group">
                                                            {/* <label for="jobname" class="form-label"
                                                    >Shift length</label>
                                                    <input
                                                        type="date"
                                                        class="form-control"
                                                        id="jobname"
                                                        aria-describedby=""
                                                        placeholder="Minutes"
                                                    /> */}

                                                            {/* <Input
                                                        type="date"
                                                        datePickerType="time"
                                                        formControlClasses="form-control"
                                                        id={`tasks.shift_length`}
                                                        placeholder="Shift length"
                                                        control={props.control}
                                                        controller={props.controller}
                                                        selectName={`tasks.shift_length`}
                                                        dateFormat="MM/dd/yyyy"
                                                        timeConstraints={{ minutes: { step: 5 }}}
                                                        timeFormat={"hh:mm"}
                                                        // minDate={new Date().setDate(current.getDate() - 400)}
                                                        // preventReadOnly={false}
                                                        label="Shift length"
                                                        formControlProps={props.register(`tasks.shift_length`, {
                                                            required: "This field is required",
                                                            maxLength: 80,
                                                        })}
                                                        validationErrorMessage={
                                                            props.errors?.tasks?.shift_length?.message ?? ""
                                                        }
                                                        disabled={props?.isView}
                                                    /> */}


                                                            <Input
                                                                type="number"
                                                                id={`tasks.shift_length`}
                                                                name={`tasks.shift_length`}
                                                                formControlClasses="form-control"
                                                                placeholder="Minutes"
                                                                required
                                                                label="Shift length"
                                                                formControlProps={props.register(
                                                                    `tasks.shift_length`,
                                                                    {
                                                                        required: "This field is required",
                                                                    }
                                                                )}
                                                                validationErrorMessage={
                                                                    props.errors?.tasks?.shift_length?.message ?? ""
                                                                }
                                                                disabled={props?.isView}
                                                            />



                                                            {/* <div class="calendar_icon">
                                                        <img
                                                            src="/images/icons/reminder.svg"
                                                            alt="calendar"
                                                        />
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group">
                                                            {/* <label for="jobname" class="form-label"
                                                    >Mon-Thu Detail Time</label
                                                    >
                                                    <input
                                                        type="date"
                                                        class="form-control"
                                                        id="jobname"
                                                        aria-describedby=""
                                                        placeholder="Minutes"
                                                    /> */}

                                                            {/* <Input
                                                        type="date"
                                                        datePickerType="time"
                                                        formControlClasses="form-control"
                                                        id={`tasks.detail_time`}
                                                        placeholder="Mon-Thu Detail Time"
                                                        control={props.control}
                                                        controller={props.controller}
                                                        selectName={`tasks.detail_time`}
                                                        dateFormat="MM/dd/yyyy"
                                                        timeConstraints={{ minutes: { step: 5 }}}
                                                        timeFormat={"hh:mm"}
                                                        // minDate={new Date().setDate(current.getDate() - 400)}
                                                        // preventReadOnly={false}
                                                        label="Mon-Thu Detail Time"
                                                        formControlProps={props.register(`tasks.detail_time`, {
                                                            required: "This field is required",
                                                            maxLength: 80,
                                                        })}
                                                        validationErrorMessage={
                                                            props.errors?.tasks?.detail_time?.message ?? ""
                                                        }
                                                        disabled={props?.isView}
                                                    /> */}


                                                            <Input
                                                                type="number"
                                                                id={`tasks.detail_time`}
                                                                name={`tasks.detail_time`}
                                                                formControlClasses="form-control"
                                                                placeholder="Minutes"
                                                                required
                                                                label="Mon-Thu Detail Time"
                                                                formControlProps={props.register(
                                                                    `tasks.detail_time`,
                                                                    {
                                                                        required: "This field is required",
                                                                    }
                                                                )}
                                                                validationErrorMessage={
                                                                    props.errors?.tasks?.detail_time?.message ?? ""
                                                                }
                                                                disabled={props?.isView}
                                                            />

                                                            {/* <div class="calendar_icon">
                                                        <img
                                                            src="/images/icons/reminder.svg"
                                                            alt="calendar"
                                                        />
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group">
                                                            {/* <label for="jobname" class="form-label"
                                                    >Friday Project Time</label
                                                    >
                                                    <input
                                                        type="date"
                                                        class="form-control"
                                                        id="jobname"
                                                        aria-describedby=""
                                                        placeholder="Minutes"
                                                    /> */}

                                                            {/* <Input
                                                        type="date"
                                                        datePickerType="time"
                                                        formControlClasses="form-control"
                                                        id={`tasks.friday_project_time`}
                                                        placeholder="Friday Project Time"
                                                        control={props.control}
                                                        controller={props.controller}
                                                        selectName={`tasks.friday_project_time`}
                                                        dateFormat="MM/dd/yyyy"
                                                        timeConstraints={{ minutes: { step: 5 }}}
                                                        timeFormat={"hh:mm"}
                                                        // minDate={new Date().setDate(current.getDate() - 400)}
                                                        // preventReadOnly={false}
                                                        label="Friday Project Time"
                                                        formControlProps={props.register(`tasks.friday_project_time`, {
                                                            required: "This field is required",
                                                            maxLength: 80,
                                                        })}
                                                        validationErrorMessage={
                                                            props.errors?.tasks?.friday_project_time?.message ?? ""
                                                        }
                                                        disabled={props?.isView}
                                                    /> */}



                                                            <Input
                                                                type="number"
                                                                id={`tasks.friday_project_time`}
                                                                name={`tasks.friday_project_time`}
                                                                formControlClasses="form-control"
                                                                placeholder="Minutes"
                                                                required
                                                                label="Friday Project Time"
                                                                formControlProps={props.register(
                                                                    `tasks.friday_project_time`,
                                                                    {
                                                                        required: "This field is required",
                                                                    }
                                                                )}
                                                                validationErrorMessage={
                                                                    props.errors?.tasks?.friday_project_time?.message ?? ""
                                                                }
                                                                disabled={props?.isView}
                                                            />

                                                            {/* <div class="calendar_icon">
                                                        <img
                                                            src="/images/icons/reminder.svg"
                                                            alt="calendar"
                                                        />
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group">
                                                            {/* <label for="jobname" class="form-label"
                                                    >Routine Time (3 Cores)</label
                                                    >
                                                    <input
                                                        type="date"
                                                        class="form-control"
                                                        id="jobname"
                                                        aria-describedby=""
                                                        placeholder="Minutes"
                                                    /> */}

                                                            {/* <Input
                                                        type="date"
                                                        datePickerType="time"
                                                        formControlClasses="form-control"
                                                        id={`tasks.routine_time_3c`}
                                                        placeholder="Routine Time (3 Cores)"
                                                        control={props.control}
                                                        controller={props.controller}
                                                        selectName={`tasks.routine_time_3c`}
                                                        dateFormat="MM/dd/yyyy"
                                                        timeConstraints={{ minutes: { step: 5 }}}
                                                        timeFormat={"hh:mm"}
                                                        // minDate={new Date().setDate(current.getDate() - 400)}
                                                        // preventReadOnly={false}
                                                        label="Routine Time (3 Cores)"
                                                        formControlProps={props.register(`tasks.routine_time_3c`, {
                                                            required: "This field is required",
                                                            maxLength: 80,
                                                        })}
                                                        validationErrorMessage={
                                                            props.errors?.tasks?.routine_time_3c?.message ?? ""
                                                        }
                                                        disabled={props?.isView}
                                                    /> */}


                                                            <Input
                                                                type="number"
                                                                id={`tasks.routine_time_3c`}
                                                                name={`tasks.routine_time_3c`}
                                                                formControlClasses="form-control"
                                                                placeholder="Minutes Per Core"
                                                                required
                                                                label="Routine Time (3 Cores)"
                                                                formControlProps={props.register(
                                                                    `tasks.routine_time_3c`,
                                                                    {
                                                                        required: "This field is required",
                                                                    }
                                                                )}
                                                                validationErrorMessage={
                                                                    props.errors?.tasks?.routine_time_3c?.message ?? ""
                                                                }
                                                                disabled={props?.isView}
                                                            />

                                                            {/* <div class="calendar_icon">
                                                        <img
                                                            src="/images/icons/reminder.svg"
                                                            alt="calendar"
                                                        />
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group">
                                                            {/* <label for="jobname" class="form-label"
                                                    >Routine Time (4 Cores)</label
                                                    >
                                                    <input
                                                        type="date"
                                                        class="form-control"
                                                        id="jobname"
                                                        aria-describedby=""
                                                        placeholder="Minutes"
                                                    /> */}

                                                            {/* <Input
                                                        type="date"
                                                        datePickerType="time"
                                                        formControlClasses="form-control"
                                                        id={`tasks.routine_time_4c`}
                                                        placeholder="Routine Time (4 Cores)"
                                                        control={props.control}
                                                        controller={props.controller}
                                                        selectName={`tasks.routine_time_4c`}
                                                        dateFormat="MM/dd/yyyy"
                                                        timeConstraints={{ minutes: { step: 5 }}}
                                                        timeFormat={"hh:mm"}
                                                        // minDate={new Date().setDate(current.getDate() - 400)}
                                                        // preventReadOnly={false}
                                                        label="Routine Time (4 Cores)"
                                                        formControlProps={props.register(`tasks.routine_time_4c`, {
                                                            required: "This field is required",
                                                            maxLength: 80,
                                                        })}
                                                        validationErrorMessage={
                                                            props.errors?.tasks?.routine_time_4c?.message ?? ""
                                                        }
                                                        disabled={props?.isView}
                                                    /> */}



                                                            <Input
                                                                type="number"
                                                                id={`tasks.routine_time_4c`}
                                                                name={`tasks.routine_time_4c`}
                                                                formControlClasses="form-control"
                                                                placeholder="Minutes Per Core"
                                                                required
                                                                label="Routine Time (4 Cores)"
                                                                formControlProps={props.register(
                                                                    `tasks.routine_time_4c`,
                                                                    {
                                                                        required: "This field is required",
                                                                    }
                                                                )}
                                                                validationErrorMessage={
                                                                    props.errors?.tasks?.routine_time_4c?.message ?? ""
                                                                }
                                                                disabled={props?.isView}
                                                            />

                                                            {/* <div class="calendar_icon">
                                                        <img
                                                            src="/images/icons/reminder.svg"
                                                            alt="calendar"
                                                        />
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                                }


                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="submitcancel_btn">
                                            <button type="button" class="btn cst_btn btn-outline-secondary  cancel_btnnew" onClick={props?.resetForm}>
                                                Cancel
                                            </button>
                                            {
                                                !props?.isView ? <button type="submit" className={isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>
                                                    {isLoading ? "" : props.isEdit ? "Update" : "Submit"}
                                                </button> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
            <div class="custom_modal_jobcard">
                <div
                    class={`modal fade`}
                    id="jobcard"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    {
                        typeWatch === "Light Duty" && <JobCardModalLightDuty dayCount={dayCount} setValue={props.setValue} modalData={modalData} isView={props.isView} closeModal={closeModal} {...props} />
                    }
                    {
                        typeWatch === "Vacuum" && <JobCardModalVaccum dayCount={dayCount} setValue={props.setValue} modalData={modalData} isView={props.isView} closeModal={closeModal} {...props} />
                    }
                    {
                        typeWatch === "Restroom" && <JobCardModalRestroom dayCount={dayCount} setValue={props.setValue} modalData={modalData} isView={props.isView} closeModal={closeModal} {...props} />
                    }
                    {
                        typeWatch === "Blanks" && <JobCardModalBlank dayCount={dayCount} setValue={props.setValue} modalData={modalData} isView={props.isView} closeModal={closeModal} {...props} />
                    }
                    {
                        typeWatch === "Utility" && <JobCardModalUtility dayCount={dayCount} setValue={props.setValue} modalData={modalData} isView={props.isView} closeModal={closeModal} {...props} />
                    }





                </div>
            </div>
        </main>
    )
}

export default withFormHoc(NewJobCardsComponent)



