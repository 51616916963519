export const InventoryActionType = {

    INVENTORY_TAB_CLICKED: 'INVENTORY_TAB_CLICKED',

    UPLOAD_IMAGE_TO_S3: 'UPLOAD_IMAGE_TO_S3',
    UPLOAD_IMAGE_TO_S3_PENDING: 'UPLOAD_IMAGE_TO_S3_PENDING',
    UPLOAD_IMAGE_TO_S3_FULFILLED: 'UPLOAD_IMAGE_TO_S3_FULFILLED',

    GET_COMMON_JOBS_ACCOUNT_LIST: 'GET_COMMON_JOBS_ACCOUNT_LIST',
    GET_COMMON_JOBS_ACCOUNT_LIST_PENDING: 'GET_COMMON_JOBS_ACCOUNT_LIST_PENDING',
    GET_COMMON_JOBS_ACCOUNT_LIST_FULFILLED: 'GET_COMMON_JOBS_ACCOUNT_LIST_FULFILLED',

    RESET_INVENTORY_OBJECTS: 'RESET_INVENTORY_OBJECTS',

    RESET_SEARCH_OR_RESET: 'RESET_SEARCH_OR_RESET',

    HANDLE_INVENTORY_OPEN_DELETE_MODAL: 'HANDLE_INVENTORY_OPEN_DELETE_MODAL',
    
    HANDLE_INVENTORY_CLOSE_DELETE_MODAL: 'HANDLE_INVENTORY_CLOSE_DELETE_MODAL',

    GET_ALL_INVENTORY_ITEMS_LIST: 'GET_ALL_INVENTORY_ITEMS_LIST',
    GET_ALL_INVENTORY_ITEMS_LIST_PENDING: 'GET_ALL_INVENTORY_ITEMS_LIST_PENDING',
    GET_ALL_INVENTORY_ITEMS_LIST_FULFILLED: 'GET_ALL_INVENTORY_ITEMS_LIST_FULFILLED',

    INVENTORY_IETMS_LIST_PAGE_CLICKED: 'INVENTORY_IETMS_LIST_PAGE_CLICKED',

    CREATE_INVENTORY_ITEM: 'CREATE_INVENTORY_ITEM',
    CREATE_INVENTORY_ITEM_PENDING: 'CREATE_INVENTORY_ITEM_PENDING',
    CREATE_INVENTORY_ITEM_FULFILLED: 'CREATE_INVENTORY_ITEM_FULFILLED',

    UPDATE_INVENTORY_ITEM: 'UPDATE_INVENTORY_ITEM',
    UPDATE_INVENTORY_ITEM_PENDING: 'UPDATE_INVENTORY_ITEM_PENDING',
    UPDATE_INVENTORY_ITEM_FULFILLED: 'UPDATE_INVENTORY_ITEM_FULFILLED',

    GET_SINGLE_INVENTORY_ITEM: 'GET_SINGLE_INVENTORY_ITEM',
    GET_SINGLE_INVENTORY_ITEM_PENDING: 'GET_SINGLE_INVENTORY_ITEM_PENDING',
    GET_SINGLE_INVENTORY_ITEM_FULFILLED: 'GET_SINGLE_INVENTORY_ITEM_FULFILLED',

    DELETE_INVENTORY_ITEM: 'DELETE_INVENTORY_ITEM',
    DELETE_INVENTORY_ITEM_PENDING: 'DELETE_INVENTORY_ITEM_PENDING',
    DELETE_INVENTORY_ITEM_FULFILLED: 'DELETE_INVENTORY_ITEM_FULFILLED',

    GET_SINGLE_INVENTORY_LOG: 'GET_SINGLE_INVENTORY_LOG',
    GET_SINGLE_INVENTORY_LOG_PENDING: 'GET_SINGLE_INVENTORY_LOG_PENDING',
    GET_SINGLE_INVENTORY_LOG_FULFILLED: 'GET_SINGLE_INVENTORY_LOG_FULFILLED',

    GET_BOX_LIST: "GET_BOX_LIST",
    GET_BOX_LIST_PENDING: 'GET_BOX_LIST_PENDING',
    GET_BOX_LIST_FULFILLED: 'GET_BOX_LIST_FULFILLED',

    ADD_BOX: "ADD_BOX",
    ADD_BOX_PENDING: 'ADD_BOX_PENDING',
    ADD_BOX_FULFILLED: 'ADD_BOX_FULFILLED',

    UPDATE_BOX: "UPDATE_BOX",
    UPDATE_BOX_PENDING: 'UPDATE_BOX_PENDING',
    UPDATE_BOX_FULFILLED: 'UPDATE_BOX_FULFILLED',

    GET_SINGLE_BOX: "GET_SINGLE_BOX",
    GET_SINGLE_BOX_PENDING: 'GET_SINGLE_BOX_PENDING',
    GET_SINGLE_BOX_FULFILLED: 'GET_SINGLE_BOX_FULFILLED',

    NEW_BOX_CLICKED: "NEW_BOX_CLICKED",

    DELETE_BOX: "DELETE_BOX",
    DELETE_BOX_PENDING: 'DELETE_BOX_PENDING',
    DELETE_BOX_FULFILLED: 'DELETE_BOX_FULFILLED',

    CLOSE_BOX_DELETE_MODEL: "CLOSE_BOX_DELETE_MODEL",

    OPEN_BOX_DELETE_MODEL: "OPEN_BOX_DELETE_MODEL",

    BOX_PAGE_CLICKED : 'BOX_PAGE_CLICKED',

    GET_ALL_ASSESTS: "GET_ALL_ASSESTS",
    GET_ALL_ASSESTS_PENDING: 'GET_ALL_ASSESTS_PENDING',
    GET_ALL_ASSESTS_FULFILLED: 'GET_ALL_ASSESTS_FULFILLED',

    CREATE_ASSET: 'CREATE_ASSET',
    CREATE_ASSET_PENDING: 'CREATE_ASSET_PENDING',
    CREATE_ASSET_FULFILLED: 'CREATE_ASSET_FULFILLED',

    UPDATE_ASSET: 'UPDATE_ASSET',
    UPDATE_ASSET_PENDING: 'UPDATE_ASSET_PENDING',
    UPDATE_ASSET_FULFILLED: 'UPDATE_ASSET_FULFILLED',

    GET_SINGLE_ASSET: 'GET_SINGLE_ASSET',
    GET_SINGLE_ASSET_PENDING: 'GET_SINGLE_ASSET_PENDING',
    GET_SINGLE_ASSET_FULFILLED: 'GET_SINGLE_ASSET_FULFILLED',

    GET_SINGLE_ASSET_LOG: 'GET_SINGLE_ASSET_LOG',
    GET_SINGLE_ASSET_LOG_PENDING: 'GET_SINGLE_ASSET_LOG_PENDING',
    GET_SINGLE_ASSET_LOG_FULFILLED: 'GET_SINGLE_ASSET_LOG_FULFILLED',

    DELETE_ASSET: "DELETE_ASSET",
    DELETE_ASSET_PENDING: 'DELETE_ASSET_PENDING',
    DELETE_ASSET_FULFILLED: 'DELETE_ASSET_FULFILLED',

    GET_ISOLATED_ONE_ASSET_DATA: 'GET_ISOLATED_ONE_ASSET_DATA',
    GET_ISOLATED_ONE_ASSET_DATA_PENDING: 'GET_ISOLATED_ONE_ASSET_DATA_PENDING',
    GET_ISOLATED_ONE_ASSET_DATA_FULFILLED: 'GET_ISOLATED_ONE_ASSET_DATA_FULFILLED',

    ENGAGE_ASSET_CHECK_IN: 'ENGAGE_ASSET_CHECK_IN',
    ENGAGE_ASSET_CHECK_IN_PENDING: 'ENGAGE_ASSET_CHECK_IN_PENDING',
    ENGAGE_ASSET_CHECK_IN_FULFILLED: 'ENGAGE_ASSET_CHECK_IN_FULFILLED',

    ENGAGE_ASSET_CHECK_OUT: 'ENGAGE_ASSET_CHECK_OUT',
    ENGAGE_ASSET_CHECK_OUT_PENDING: 'ENGAGE_ASSET_CHECK_OUT_PENDING',
    ENGAGE_ASSET_CHECK_OUT_FULFILLED: 'ENGAGE_ASSET_CHECK_OUT_FULFILLED',

    GET_ASSET_ACTIVE_STATUS_TOGGLE: 'GET_ASSET_ACTIVE_STATUS_TOGGLE',

    GET_ALL_PICKING_TICKET: "GET_ALL_PICKING_TICKET",
    GET_ALL_PICKING_TICKET_PENDING: 'GET_ALL_PICKING_TICKET_PENDING',
    GET_ALL_PICKING_TICKET_FULFILLED: 'GET_ALL_PICKING_TICKET_FULFILLED',

    GET_SINGLE_PICKING_TICKET: "GET_SINGLE_PICKING_TICKET",
    GET_SINGLE_PICKING_TICKET_PENDING: 'GET_SINGLE_PICKING_TICKET_PENDING',
    GET_SINGLE_PICKING_TICKET_FULFILLED: 'GET_SINGLE_PICKING_TICKET_FULFILLED',

    GET_EMERGENCY_PICKUP_TICKET: "GET_EMERGENCY_PICKUP_TICKET",
    GET_EMERGENCY_PICKUP_TICKET_PENDING: 'GET_EMERGENCY_PICKUP_TICKET_PENDING',
    GET_EMERGENCY_PICKUP_TICKET_FULFILLED: 'GET_EMERGENCY_PICKUP_TICKET_FULFILLED',

    PICKING_TICKET_PAGE_CLICKED : 'PICKING_TICKET_PAGE_CLICKED',

    EMERGENCY_TICKET_PAGE_CLICKED : 'EMERGENCY_TICKET_PAGE_CLICKED',

    APPROVE_PICKUP_TICKET: "APPROVE_PICKUP_TICKET",
    APPROVE_PICKUP_TICKET_PENDING: 'APPROVE_PICKUP_TICKET_PENDING',
    APPROVE_PICKUP_TICKET_FULFILLED: 'APPROVE_PICKUP_TICKET_FULFILLED',

    CREATE_INVENTORY_PULL_REQUEST_TICKET: 'CREATE_INVENTORY_PULL_REQUEST_TICKET',
    CREATE_INVENTORY_PULL_REQUEST_TICKET_PENDING: 'CREATE_INVENTORY_PULL_REQUEST_TICKET_PENDING',
    CREATE_INVENTORY_PULL_REQUEST_TICKET_REJECTED: 'CREATE_INVENTORY_PULL_REQUEST_TICKET_REJECTED',
    CREATE_INVENTORY_PULL_REQUEST_TICKET_FULFILLED: 'CREATE_INVENTORY_PULL_REQUEST_TICKET_FULFILLED',

    GET_INVENTORY_ITEM_NAMES: 'GET_INVENTORY_ITEM_NAMES',
    GET_INVENTORY_ITEM_NAMES_PENDING: 'GET_INVENTORY_ITEM_NAMES_PENDING',
    GET_INVENTORY_ITEM_NAMES_FULFILLED: 'GET_INVENTORY_ITEM_NAMES_FULFILLED',

    GET_JOB_USERS_INFO: 'GET_JOB_USERS_INFO',
    GET_JOB_USERS_INFO_PENDING: 'GET_JOB_USERS_INFO_PENDING',
    GET_JOB_USERS_INFO_FULFILLED: 'GET_JOB_USERS_INFO_FULFILLED',

    ASSETS_PAGE_CLICKED : 'ASSETS_PAGE_CLICKED',

    GET_ALL_PICKUPS_TICKET_LIST: "GET_ALL_PICKUPS_TICKET_LIST",
    GET_ALL_PICKUPS_TICKET_LIST_PENDING: 'GET_ALL_PICKUPS_TICKET_LIST_PENDING',
    GET_ALL_PICKUPS_TICKET_LIST_FULFILLED: 'GET_ALL_PICKUPS_TICKET_LIST_FULFILLED',

    SEARCH_ALL_PICKUPS_TICKET_LIST: 'SEARCH_ALL_PICKUPS_TICKET_LIST',
    SEARCH_ALL_PICKUPS_TICKET_LIST_PENDING: 'SEARCH_ALL_PICKUPS_TICKET_LIST_PENDING',
    SEARCH_ALL_PICKUPS_TICKET_LIST_FULFILLED: 'SEARCH_ALL_PICKUPS_TICKET_LIST_FULFILLED',

    PICKUPS_TICKET_LIST_PAGE_CLICKED: 'PICKUPS_TICKET_LIST_PAGE_CLICKED',

    GET_SINGLE_PICKUP_TICKET: 'GET_SINGLE_PICKUP_TICKET',
    GET_SINGLE_PICKUP_TICKET_PENDING: 'GET_SINGLE_PICKUP_TICKET_PENDING',
    GET_SINGLE_PICKUP_TICKET_FULFILLED: 'GET_SINGLE_PICKUP_TICKET_FULFILLED',

    GET_ALL_INVENTORY_BOX_NAMES_LIST: 'GET_ALL_INVENTORY_BOX_NAMES_LIST',
    GET_ALL_INVENTORY_BOX_NAMES_LIST_PENDING: 'GET_ALL_INVENTORY_BOX_NAMES_LIST_PENDING',
    GET_ALL_INVENTORY_BOX_NAMES_LIST_FULFILLED: 'GET_ALL_INVENTORY_BOX_NAMES_LIST_FULFILLED',

    READY_FOR_PICKUP_QR_GENERATOR: 'READY_FOR_PICKUP_QR_GENERATOR',
    READY_FOR_PICKUP_QR_GENERATOR_PENDING: 'READY_FOR_PICKUP_QR_GENERATOR_PENDING',
    READY_FOR_PICKUP_QR_GENERATOR_FULFILLED: 'READY_FOR_PICKUP_QR_GENERATOR_FULFILLED',

    READY_FOR_DELIVERY_QR_GENERATOR: 'READY_FOR_DELIVERY_QR_GENERATOR',
    READY_FOR_DELIVERY_QR_GENERATOR_PENDING: 'READY_FOR_DELIVERY_QR_GENERATOR_PENDING',
    READY_FOR_DELIVERY_QR_GENERATOR_FULFILLED: 'READY_FOR_DELIVERY_QR_GENERATOR_FULFILLED',

    GET_DELIVERY_ITEM_SCAN_PRESIGNED_URL: 'GET_DELIVERY_ITEM_SCAN_PRESIGNED_URL',
    GET_DELIVERY_ITEM_SCAN_PRESIGNED_URL_PENDING: 'GET_DELIVERY_ITEM_SCAN_PRESIGNED_URL_PENDING',
    GET_DELIVERY_ITEM_SCAN_PRESIGNED_URL_FULFILLED: 'GET_DELIVERY_ITEM_SCAN_PRESIGNED_URL_FULFILLED',

    GET_INVENTORY_ITEM_DELIVERED_SUCCESS: 'GET_INVENTORY_ITEM_DELIVERED_SUCCESS',
    GET_INVENTORY_ITEM_DELIVERED_SUCCESS_PENDING: 'GET_INVENTORY_ITEM_DELIVERED_SUCCESS_PENDING',
    GET_INVENTORY_ITEM_DELIVERED_SUCCESS_FULFILLED: 'GET_INVENTORY_ITEM_DELIVERED_SUCCESS_FULFILLED',

    GET_DELIVERY_RETURN_WAREHOUSE: 'GET_DELIVERY_RETURN_WAREHOUSE',
    GET_DELIVERY_RETURN_WAREHOUSE_PENDING: 'GET_DELIVERY_RETURN_WAREHOUSE_PENDING',
    GET_DELIVERY_RETURN_WAREHOUSE_FULFILLED: 'GET_DELIVERY_RETURN_WAREHOUSE_FULFILLED',

    GET_ALL_INVENTORY_LOCATION_LIST: 'GET_ALL_INVENTORY_LOCATION_LIST',
    GET_ALL_INVENTORY_LOCATION_LIST_PENDING: 'GET_ALL_INVENTORY_LOCATION_LIST_PENDING',
    GET_ALL_INVENTORY_LOCATION_LIST_FULFILLED: 'GET_ALL_INVENTORY_LOCATION_LIST_FULFILLED',

    GET_LOCATION: 'GET_LOCATION',
    GET_LOCATION_PENDING: 'GET_LOCATION_PENDING',
    GET_LOCATION_FULFILLED: 'GET_LOCATION_FULFILLED',

    GET_LOCATION_ASSOCIATION : 'GET_LOCATION_ASSOCIATION',
    GET_LOCATION_ASSOCIATION_PENDING: 'GET_LOCATION_ASSOCIATION_PENDING',
    GET_LOCATION_ASSOCIATION_FULFILLED: 'GET_LOCATION_ASSOCIATION_FULFILLED',

    ADD_LOCATION: 'ADD_LOCATION',
    ADD_LOCATION_PENDING: 'ADD_LOCATION_PENDING',
    ADD_LOCATION_REJECTED: 'ADD_LOCATION_REJECTED',
    ADD_LOCATION_FULFILLED: 'ADD_LOCATION_FULFILLED',

    GET_LOCATION_NAME: 'GET_LOCATION_NAME',
    GET_LOCATION_NAME_PENDING: 'GET_LOCATION_NAME_PENDING',
    GET_LOCATION_NAME_FULFILLED: 'GET_LOCATION_NAME_FULFILLED',

    GET_FULFILLMENT_MANAGER: 'GET_FULFILLMENT_MANAGER',
    GET_FULFILLMENT_MANAGER_PENDING: 'GET_FULFILLMENT_MANAGER_PENDING',
    GET_FULFILLMENT_MANAGER_FULFILLED: 'GET_FULFILLMENT_MANAGER_FULFILLED',

    ADD_LOCATION_ASSOCIATION: 'ADD_LOCATION_ASSOCIATION',
    ADD_LOCATION_ASSOCIATION_PENDING: 'ADD_LOCATION_ASSOCIATION_PENDING',
    ADD_LOCATION_ASSOCIATION_REJECTED: 'ADD_LOCATION_ASSOCIATION_REJECTED',
    ADD_LOCATION_ASSOCIATION_FULFILLED: 'ADD_LOCATION_ASSOCIATION_FULFILLED',

    EDIT_LOCATION_CLICKED : 'EDIT_LOCATION_CLICKED',
    EDIT_LOCATION_ASSOCIATION_CLICKED : 'EDIT_LOCATION_ASSOCIATION_CLICKED',

    OPEN_MODEL_INVENTORY : 'OPEN_MODEL_INVENTORY',
    CLOSE_MODEL_INVENTORY : 'CLOSE_MODEL_INVENTORY',

    UPDATE_LOCATION: 'UPDATE_LOCATION',
    UPDATE_LOCATION_PENDING: 'UPDATE_LOCATION_PENDING',
    UPDATE_LOCATION_REJECTED: 'UPDATE_LOCATION_REJECTED',
    UPDATE_LOCATION_FULFILLED: 'UPDATE_LOCATION_FULFILLED',

    UPDATE_LOCATION_ASSOCIATION: 'UPDATE_LOCATION_ASSOCIATION',
    UPDATE_LOCATION_ASSOCIATION_PENDING: 'UPDATE_LOCATION_ASSOCIATION_PENDING',
    UPDATE_LOCATION_ASSOCIATION_REJECTED: 'UPDATE_LOCATION_ASSOCIATION_REJECTED',
    UPDATE_LOCATION_ASSOCIATION_FULFILLED: 'UPDATE_LOCATION_ASSOCIATION_FULFILLED',

    DELETE_LOCATION: 'DELETE_LOCATION',
    DELETE_LOCATION_PENDING: 'DELETE_LOCATION_PENDING',
    DELETE_LOCATION_REJECTED: 'DELETE_LOCATION_REJECTED',
    DELETE_LOCATION_FULFILLED: 'DELETE_LOCATION_FULFILLED',

    NEW_LOCATION_CLICKED : 'NEW_LOCATION_CLICKED',

    NEW_LOCATION_ASSOCIATION_CLICKED : 'NEW_LOCATION_ASSOCIATION_CLICKED',

    ASSET_STOCK_CLICKED : 'ASSET_STOCK_CLICKED',

    GET_ALL_ASSEST_STOCK: 'GET_ALL_ASSEST_STOCK',
    GET_ALL_ASSEST_STOCK_PENDING: 'GET_ALL_ASSEST_STOCK_PENDING',
    GET_ALL_ASSEST_STOCK_FULFILLED: 'GET_ALL_ASSEST_STOCK_FULFILLED',

    UPDATE_ASSET_STOCK: 'UPDATE_ASSET_STOCK',
    UPDATE_ASSET_STOCK_PENDING: 'UPDATE_ASSET_STOCK_PENDING',
    UPDATE_ASSET_STOCK_FULFILLED: 'UPDATE_ASSET_STOCK_FULFILLED',

    GET_SINGLE_ASSET_STOCK: 'GET_SINGLE_ASSET_STOCK',
    GET_SINGLE_ASSET_STOCK_PENDING: 'GET_SINGLE_ASSET_STOCK_PENDING',
    GET_SINGLE_ASSET_STOCK_FULFILLED: 'GET_SINGLE_ASSET_STOCK_FULFILLED',

    DELETE_ASSET_STOCK: 'DELETE_ASSET_STOCK',
    DELETE_ASSET_STOCK_PENDING: 'DELETE_ASSET_STOCK_PENDING',
    DELETE_ASSET_STOCK_FULFILLED: 'DELETE_ASSET_STOCK_FULFILLED',

    ASSET_STOCK_PAGE_CLICKED : 'ASSET_STOCK_PAGE_CLICKED'
};