import React, { useEffect, useState } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import GooglePlacesAutoComplete from '../../GooglePlacesAutoComplete/googlePlacesAutoComplete';
import { newScheduleValidator as validate } from '../../../../utility/validator/validator';
import { connect, useSelector } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { showConfirmAlert } from '../../../../utility/successAlert/ConfirmAlert';
import * as Scroll from 'react-scroll';
import moment from "moment";
import { Timezones, UserModesWithoutAll, recurOnWeekSelect, selectDay, recurWeekSelect, QuarterlymonthSelect } from '../../../../utility/constants/constants';
import { getUntilDate, getUser, makeFirstLetterCapital, OutlookGetFromFormatedDate, OutlookGetToFormatedDate, getQuarter, getSelectedWeek, getRecurringDay } from '../../../../utility/utility';
import { formValueSelector } from 'redux-form';
import ReactTooltip from 'react-tooltip';
import AddToCalendar from 'react-add-to-calendar-recurring';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
const cloneDeep = require('clone-deep');

var scroller = Scroll.scroller;

let NewAccountsSchedule = (props) => {
    const[promptLoader, setPromptLoader] = useState({yes: false, no: false});
    const account_info = useSelector(state => state.adminOrSuperAdminReducer.currentAccount);
    const [weeklyTab, setWeeklyTab] = useState(true);
    const [monthlyTab, setMonthlyTab] = useState(false);
    const [quarterlyTab, setquarterlyTab] = useState(false);
    const weeklyTabClicked = () => {
        if(props.formStates && props.formStates.schedules) {
            delete props.formStates.schedules.start_date
            delete props.formStates.schedules.end_date
        }
        setWeeklyTab(true)
        setMonthlyTab(false)
        setquarterlyTab(false)
        setoccurrence('')
    }
    const monthlyTabClicked = () => {
        if(props.formStates && props.formStates.schedules) {
            delete props.formStates.schedules.start_date
            delete props.formStates.schedules.end_date
        }
        setMonthlyTab(true)
        setWeeklyTab(false)
        setquarterlyTab(false)
        setoccurrence('')
    }
    const quarterlyTabClicked = () => {
        if(props.formStates && props.formStates.schedules) {
            delete props.formStates.schedules.start_date
            delete props.formStates.schedules.end_date
        }
        setquarterlyTab(true)
        setMonthlyTab(false)
        setWeeklyTab(false)
        setoccurrence('')
    }
    // const getAccountName = () => {
    //     
    //     if (props.accountsList && props.accountsList.length > 0) {
    //         props.accountsList && props.accountsList.map(account => {
    //             if(account.id === props.match.params.accountId){
    //                 setaccountName(account.name) 
    //             }
    //         })
    //     } 
    // }
    // const [accountName, setaccountName] = useState(getAccountName());
    const getInspectionFormsForJob = (props) => {

        if (props.accountsList && props.accountsList.length > 0) {
            return props.accountsList && props.accountsList.map(account => {
                return {
                    value: account.id,
                    label: account.name
                }
            })
        } else {
            return []
        }
    }
    const getInspectionFormDropDown = (props) => {

        if (props.inspectionFormsList && props.inspectionFormsList.length > 0) {
            return props.inspectionFormsList && props.inspectionFormsList.map(inspectionForm => {

                return {
                    value: inspectionForm.id,
                    label: inspectionForm.name
                }
            })
        } else {
            return []
        }
    }
    const [accounts, setAccounts] = useState(getInspectionFormsForJob(props));
    const [InspectionForm, setInspectionForm] = useState(getInspectionFormDropDown(props));
    useEffect(() => {
        setAccounts(getInspectionFormsForJob(props))
    }, [props.accountsList])
    useEffect(() => {
        setInspectionForm(getInspectionFormDropDown(props))
    }, [props.inspectionFormsList])
    const noOptionsMessage = (a, b) => {
        return 'Job not found';
    }
    
    const current = new Date();

    const { handleSubmit, pristine, reset, submitting, error, account, submitFailed } = props;

   useEffect(() => {
        if(props.formStates && props.formStates.schedules && props.formStates.schedules.frequency && props.formStates.schedules.select_week && props.formStates.schedules.day && props.formStates.schedules.start_date && props.formStates.schedules.end_date) {
            props.checkScheduleValues(props.formStates.schedules)
        } else if (props.formStates && props.formStates.schedules && props.formStates.schedules.frequency && props.formStates.schedules.selected_week && props.formStates.schedules.day && props.formStates.schedules.start_date && props.formStates.schedules.end_date) {
            props.checkScheduleValues(props.formStates.schedules)
        } else if (props.formStates && props.formStates.schedules && props.formStates.schedules.frequency && props.formStates.schedules.selected_week && props.formStates.schedules.day && props.formStates.schedules.selected_month && props.formStates.schedules.start_date && props.formStates.schedules.end_date) {
            props.checkScheduleValues(props.formStates.schedules)
        }
    }, [props.formStates])

    const [occurrence, setoccurrence] = useState('');
    const [occurrenceList, setOccurrenceList] = useState('');

    useEffect(() => {
        if(props.scheduleCheckValues) {
            
            if(props.formStates && props.formStates.schedules && props.formStates.schedules && props.formStates.schedules.frequency === 'weekly') {
                const occLength = props.scheduleCheckValues.occurrence.length;
                const day = props.formStates.schedules.day;
                const select_week = props.formStates.schedules.select_week;
                if(occLength && day && select_week) {
                setoccurrence(`Every ${getSelectedWeek(select_week) === '1st' ? ' week' : getSelectedWeek(select_week) + ' week' } on ${makeFirstLetterCapital(day)}, until ${moment(props.formStates.schedules.end_date).format('MM-DD-YYYY')}, ${occLength} occurrence(s)`)
                setOccurrenceList(props.scheduleCheckValues.occurrence)
                } else {
                    setoccurrence(`0 occurrence(s)`)
                }
            } else if (props.formStates && props.formStates.schedules.frequency === 'monthly') {
                
                const occLength = props.scheduleCheckValues.occurrence.length;
                const day = props.formStates.schedules.day;
                const selected_week = props.formStates.schedules.selected_week;
                if(occLength && day && selected_week) {
                    setoccurrence(`Every Month ${getSelectedWeek(selected_week)} week on ${makeFirstLetterCapital(day)}, until ${moment(props.formStates.schedules.end_date).format('MM-DD-YYYY')}, ${occLength} occurrence(s)`)
                    setOccurrenceList(props.scheduleCheckValues.occurrence)
                } else {
                        setoccurrence(`0 occurrence(s)`)
                    }
            } else {
                if(props.formStates) {
                const occLength = props.scheduleCheckValues.occurrence.length;
                const day = props.formStates.schedules.day;
                const selected_month = props.formStates.schedules.selected_month;
                const selected_week = props.formStates.schedules.selected_week;

                if(occLength && day && selected_month && selected_week) {
                    setoccurrence(`Every ${getQuarter(selected_month)} Quarter ${getSelectedWeek(selected_week)} week on ${makeFirstLetterCapital(day)}, until ${moment(props.formStates.schedules.end_date).format('MM-YYYY')}, ${occLength} occurrence(s)`)
                    setOccurrenceList(props.scheduleCheckValues.occurrence)
                } else {
                        setoccurrence(`0 occurrence(s)`)
                    }
                } else {
                        setoccurrence(`0 occurrence(s)`)
                    }
            }
        }
    }, [props.scheduleCheckValues])

    
    const getSelectedAccount = (accounts) => {
        return accounts && accounts.map((a) => {
          if(a.id === props.match.params.accountId) {
              return a.name
          }
        });
      };

    let event = {}
    if(props.formStates && props.formStates.schedules.frequency === 'weekly') {
        event = {
            title: props.formStates && props.formStates.schedules.title,
            description: `New schedule created for ${props.formStates && props.formStates.schedules.account_id && props.formStates.schedules.account_id.label}`,
            location: 'United States',
            startTime: OutlookGetFromFormatedDate(props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length > 0 && props.scheduleCheckValues.occurrence[0]),
            endTime: OutlookGetToFormatedDate(props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length > 0 && props.scheduleCheckValues.occurrence[0]),
            recurring: {
                repeat: props.formStates && props.formStates.schedules.frequency, /* or DaiLy, Monthly, Yearly */
                byDay: getRecurringDay(props.formStates && props.formStates.schedules.day), /* SU,MO,TU,WE,TH,FR,SA */
                interval: props.formStates && props.formStates.schedules.select_week, /* Default is 1 */
                weekStart: 'SU', /* Week start default is Sunday */
                until: getUntilDate(props.formStates && props.formStates.schedules.end_date),
                // count: props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length, /* Weekly for 10 occurrences */
            }
        }
    } else if(props.formStates && props.formStates.schedules.frequency === 'monthly') {
        event = {
            title: props.formStates && props.formStates.schedules.title,
            description: `New schedule created for ${props.formStates && props.formStates.schedules.account_id && props.formStates.schedules.account_id.label}`,
            location: 'United States',
            startTime: OutlookGetFromFormatedDate(props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length > 0 && props.scheduleCheckValues.occurrence[0]),
            endTime: OutlookGetToFormatedDate(props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length > 0 && props.scheduleCheckValues.occurrence[0]),
            recurring: {
                repeat: props.formStates && props.formStates.schedules.frequency, /* or DaiLy, Monthly, Yearly */
                byDay: `${props.formStates && props.formStates.schedules.selected_week}${getRecurringDay(props.formStates && props.formStates.schedules.day)}`, /* SU,MO,TU,WE,TH,FR,SA , 2MO, 2TU*/
                // interval: props.formStates && props.formStates.schedules.select_week, /* Default is 1 */
                weekStart: 'SU', /* Week start default is Sunday */
                until: getUntilDate(props.formStates && props.formStates.schedules.end_date),
                // count: props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length, /* Weekly for 10 occurrences */
            }
        }
    } else {
        event = {
            title: props.formStates && props.formStates.schedules.title,
            description: `New schedule created for ${props.formStates && props.formStates.schedules.account_id && props.formStates.schedules.account_id.label}`,
            location: 'United States',
            startTime: OutlookGetFromFormatedDate(props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length > 0 && props.scheduleCheckValues.occurrence[0]),
            endTime: OutlookGetToFormatedDate(props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length > 0 && props.scheduleCheckValues.occurrence[0]),
            ecurring: {
                repeat: props.formStates && props.formStates.schedules.frequency, /* or DaiLy, Monthly, Yearly */
                byDay: `${props.formStates && props.formStates.schedules.selected_week}${getRecurringDay(props.formStates && props.formStates.schedules.day)}`, /* SU,MO,TU,WE,TH,FR,SA , 2MO, 2TU*/
                // interval: props.formStates && props.formStates.schedules.select_week, /* Default is 1 */
                weekStart: 'SU', /* Week start default is Sunday */
                // count: props.scheduleCheckValues && props.scheduleCheckValues.occurrence.length, /* Weekly for 10 occurrences */
                until: getUntilDate(props.formStates && props.formStates.schedules.end_date),
            }
        }
    }
    let items = [
        { outlook: 'Outlook ics file' },
        // { outlookcom: 'Outlook.com' },
        // { apple: 'Apple Calendar' },
        // { yahoo: 'Yahoo' },
        // { google: 'Google' }
    ];

    return (
        <>
            <main className="app-content  wraper_content inspection_wraper user_newQuote" id="schedule_form">
                {/* <div class="tickets_user_profile_body"> */}
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluid">
                        <div className="quote_form ticket_form">
                            <h2 className="md_title" data-test="create_ticket_header">Create New Schedule</h2>
                            <div className="addQuote_form">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label className="form_title">Title <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input dataTest="ticket_subject" name="schedules[title]" type="text" className="form-control input-modifier" placeholder="Subject" />
                                    </div>
                                    {/* <div className="form-group">
                                        <label className="form_title">Job Name <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <input dataTest="ticket_subject" name="schedules[account_id]" type="text" className="form-control input-modifier" placeholder={account_info ? account_info.name : ''} disabled />
                                    </div> */}
                                    {props.match.params.accountId ? <div className="form-group">
                                        <label className="form_title">Job Name <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <input dataTest="ticket_subject" name="schedules[account_id]" type="text" className="form-control input-modifier" placeholder={account_info ? account_info.name : getSelectedAccount(props.accountsList)} disabled />
                                    </div> : 
                                    <div className="form-group">
                                        <label className="form_title">Job Name <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input name="schedules[account_id]" noOptionsMessage={noOptionsMessage} onChange={(e) => props.getInspectionForm(e.value)} type="inputSelect" options={accounts} />
                                    </div>}
                                    <div className="form-group">
                                        <label className="form_title">Inspection Form <sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input name="schedules[inspection_form_id]" noOptionsMessage={noOptionsMessage} type="inputSelect" options={InspectionForm} disabled={props.inspectionFormsList} />
                                    </div>
                                    {/* <div className="form-group">
                                        <label className="form_title">Schedule<sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input name="schedules[frequency]" type="select" options={selectSchedule} placeholder="Select Schedule" className="custom-select input-modifier add_user_select" />
                                     </div> */}
                                    <div className="form-group">
                                        <label className="form_title">Schedule<sup className="cst_sup_txt text_danger">*</sup></label>
                                        <Input type="radio" onClick={weeklyTabClicked} labelClass="custom-control-label" forLable={"customRadio1"} value={"weekly"} label={"Weekly"} className="custom-control-input" id="customRadio1" name="schedules[frequency]" />
                                        <Input type="radio" onClick={monthlyTabClicked} labelClass="custom-control-label" forLable={"customRadio2"} value={"monthly"} label={"Monthly"} className="custom-control-input" id="customRadio2" name="schedules[frequency]" />
                                        <Input type="radio" onClick={quarterlyTabClicked} labelClass="custom-control-label" forLable={"customRadio3"} value={"quarterly"} label={"Quarterly"} className="custom-control-input" id="customRadio3" name="schedules[frequency]" />
                                    </div>
                                    {
                                        weeklyTab &&
                                        <div className="weekly_tab">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-lg-6 pr-1">
                                                        <label className="form_title">Recur week(s) on<sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="schedules[select_week]" type="select" options={recurOnWeekSelect} placeholder="Week" className="custom-select input-modifier add_user_select" />
                                                    </div>
                                                    <div className="col-lg-6 pl-1">
                                                        <label className="form_title">Select Day<sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="schedules[day]" type="select" options={selectDay} placeholder="Day" className="custom-select input-modifier add_user_select" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group" id="calender">
                                                <label className="form_title">Range of recurrence</label>
                                                <div className="row">
                                                    <div className="col-lg-6 pr-1">
                                                        <label> Start
                                                            <Input name="schedules[start_date]" minDate={new Date().setDate(current.getDate())} dateFormat="MM-dd-yyyy" placeholder="From Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                                            <div class="input-group-append cst_group_append">
                                                                <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-6 pl-1">
                                                        <label> End by
                                                            <Input name="schedules[end_date]" minDate={new Date().setDate(current.getDate())} dateFormat="MM-dd-yyyy" placeholder="To Date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier" />
                                                            <div class="input-group-append cst_group_append">   
                                                                <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div style={{display: 'none'}}>
                                                <AddToCalendar event={event}
                                                listItems={items}/>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        monthlyTab &&
                                        <div className="monthly_tab">
                                            <div className="form-group box_input">
                                                <label className="form_title">Yearly Recurring<sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio5"} value={"true"} label={"Yes"} className="custom-control-input" id="customRadio5" name="schedules[recur]" />
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio6"} value={"false"} label={"No"} className="custom-control-input" id="customRadio6" name="schedules[recur]" />
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col pr-1">
                                                        <label className="form_title">Select Week<sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="schedules[selected_week]" type="select" options={recurWeekSelect} placeholder="Week" className="custom-select input-modifier add_user_select" />
                                                    </div>
                                                    <div className="col pl-1 pr-1">
                                                        <label className="form_title">Select Day<sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="schedules[day]" type="select" options={selectDay} placeholder="Day" className="custom-select input-modifier add_user_select" />
                                                    </div>
                                                    {/*<div className="col pl-1 pr-1">
                                                        <label className="form_title">Month(s)<sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="schedules[selected_month]" type="select" options={monthSelect} placeholder="Month" className="custom-select input-modifier add_user_select" />
                                                     </div>*/}
                                                </div>
                                            </div>
                                            <div className="form-group month_picker" id="calender">
                                                <label className="form_title">Range of recurrence</label>
                                                <div className="row">
                                                    <div className="col-lg-6 pr-1">
                                                        <label> Start
                                                            <Input name="schedules[start_date]" minDate={new Date().setDate(current.getDate() - 28)} dateFormat="MMMM-yyyy" placeholder="From Date" datePickerType={"react-month-datepicker"} type="date" className="form-control input-modifier" />
                                                            <div class="input-group-append cst_group_append">
                                                                <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-6 pl-1">
                                                        <label> End by
                                                            <Input name="schedules[end_date]" minDate={new Date().setDate(current.getDate() - 28)} dateFormat="MMMM-yyyy" placeholder="To Date" datePickerType={"react-month-datepicker"} type="date" className="form-control input-modifier" />
                                                            <div class="input-group-append cst_group_append">
                                                                <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div style={{display: 'none'}}>
                                                <AddToCalendar event={event}
                                                listItems={items}/>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        quarterlyTab &&
                                        <div className="monthly_tab">
                                            <div className="form-group box_input">
                                                <label className="form_title">Yearly Recurring<sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio7"} value={"true"} label={"Yes"} className="custom-control-input" id="customRadio7" name="schedules[recur]" />
                                                <Input type="radio" labelClass="custom-control-label" forLable={"customRadio8"} value={"false"} label={"No"} className="custom-control-input" id="customRadio8" name="schedules[recur]" />
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col pr-1">
                                                        <label className="form_title">Select Week<sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="schedules[selected_week]" type="select" options={recurWeekSelect} placeholder="Week" className="custom-select input-modifier add_user_select" />
                                                    </div>
                                                    <div className="col pl-1 pr-1">
                                                        <label className="form_title">Select Day<sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="schedules[day]" type="select" options={selectDay} placeholder="Day" className="custom-select input-modifier add_user_select" />
                                                    </div>
                                                    <div className="col pl-1 pr-1">
                                                        <label className="form_title">Month(s)<sup className="cst_sup_txt text_danger">*</sup></label>
                                                        <Input name="schedules[selected_month]" type="select" options={QuarterlymonthSelect} placeholder="Quarter" className="custom-select input-modifier add_user_select" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group month_picker" id="calender">
                                                <label className="form_title">Range of recurrence</label>
                                                <div className="row">
                                                    <div className="col-lg-6 pr-1">
                                                        <label> Start
                                                            <Input name="schedules[start_date]" minDate={new Date().setDate(current.getDate() - 28)} dateFormat="MM-yyyy" placeholder="From Date" datePickerType={"react-month-datepicker"} type="date" className="form-control input-modifier" />
                                                            <div class="input-group-append cst_group_append">
                                                                <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-6 pl-1">
                                                        <label> End by
                                                            <Input name="schedules[end_date]" minDate={new Date().setDate(current.getDate() - 28)} dateFormat="MM-yyyy" placeholder="To Date" datePickerType={"react-month-datepicker"} type="date" className="form-control input-modifier" />
                                                            <div class="input-group-append cst_group_append">
                                                                <span class="input-group-text" id="basic-addon2"><i class="fa fa-calendar"></i></span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {occurrence && props.formStates && props.formStates.schedules && props.formStates.schedules.frequency && (props.scheduleCheckValues && props.scheduleCheckValues.occurrence && props.scheduleCheckValues.occurrence.length > 0 ? 
                                    <button type="button" className={"btn btn-info btn-sm mb-0"} onClick={() => {props.handleClickScheduleOccurrenceModal()}}> Preview Occurrence </button> : 
                                    props.scheduleCheckValues && props.scheduleCheckValues.occurrence && props.scheduleCheckValues.occurrence.length == 0 ? 
                                    <label className="form_title" style={{backgroundColor: '#fff', color: '#850035'}}>{occurrence}</label> : null)}
                                    <div className="form-group btn_block mb-0" style={{ cursor: "pointer" }}>
                                        <button id='create-new-inspectionSchedule' type='submit' data-test="submit_button" className="btn cst_btn btn_danger" disabled={occurrence && (props.scheduleCheckValues && props.scheduleCheckValues.occurrence && props.scheduleCheckValues.occurrence.length == 0 ? true : false)}> Schedule Now </button>
                                        <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary  cancel_btnnew" onClick={() => props.history.goBack()}>Cancel</button>
                                    </div>
                                    <Dialog
                                        open={ props.openScheduleOutlookConfirmModal}
                                        onClose={props.handleClose}
                                        aria-labelledby="form-dialog-title"
                                    >
                                        <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                                            <div className="preview_schedule">
                                                <article className="custom_article">
                                                    <h3 className="text-center" style={{color: '#272727', fontSize: '20px', fontWeight: 'bold', fontFamily: 'Open Sans'}}> Do you want to save this event for Outlook calendar? </h3>
                                                </article>
                                                <DialogActions>
                                                    <button data-test="submit_button" type='button' onClick={() => {
                                                        setPromptLoader({...promptLoader, yes: true})
                                                        if(document.getElementsByClassName('react-add-to-calendar-recurring__button').length) {
                                                            document.getElementsByClassName('react-add-to-calendar-recurring__button')[0].click()
                                                        }
                                                        props.createScheduleFinalStep('yes')
                                                    }} className={props.isLoading && promptLoader && promptLoader.yes ? "btn cst_btn btn-danger btn-wait" : "btn cst_btn btn-outline-danger"}> {props.isLoading ? "" : "Yes"} </button>
                                                    <button data-test="submit_button" type='button' onClick={() => {
                                                        setPromptLoader({...promptLoader, no: true})
                                                        props.createScheduleFinalStep('no')
                                                    }} className={props.isLoading && promptLoader && promptLoader.no ? "btn cst_btn btn-secondary btn-wait" : "btn cst_btn btn-outline-dark"}> {props.isLoading ? "" : "No"} </button>
                                                </DialogActions>
                                            </div>
                                        </DialogTitle>
                                    </Dialog>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </main>
            <Dialog
                open={props.openScheduleOccurrenceModal}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                    <div className="preview_schedule">
                    {/* <div className="modal-header"> */}
                        <button type="button" className="close" data-dismiss="modal" onClick={props.handleDeleteClose}><img src="/images/icons/icn_close_gray.png" alt="Close Icon" /></button>
                    {/* </div> */}
                        <article className="custom_article">
                            {/* <h3 className="h3_title" >{makeFirstLetterCapital(preview.title)}</h3> */}
                            {/* <div className="custom_title schedule_title"> OCCURRENCE </div> */}
                            <div className="custom_title"> <label className="form_title" style={{backgroundColor: '#fff', color: '#850035'}}>{occurrence}</label> </div>
                        </article>
                            <div className="form-group">
                                <label className="custom_title"> OCCURENCE LIST </label>
                                {occurrenceList && occurrenceList.map((params) => {
                                    if(props.formStates && props.formStates.schedules && props.formStates.schedules.frequency === 'weekly') {
                                        return ( <div className="db_data"> <ul><li>{moment(new Date(params)).format('MM-DD-YYYY')}</li></ul> </div> )
                                    } else {
                                        const dateList = params.split(' ')[0]
                                        return ( <div className="db_data"> <ul><li>{moment(new Date(dateList)).format('MM-DD-YYYY')}</li></ul> </div> )
                                    }
                                })}
                            </div>
                        <DialogActions> <button style={{ minWidth: '100%' }} onClick={props.handleDeleteClose} className={"btn cst_btn btn_danger mb-4"} id="cancel" type="cancel"> Close </button> </DialogActions>
                    </div>
                </DialogTitle>
            </Dialog>
        </>

    )
}
const mapStateToProps = (state, props) => {
    let schedules = {};
    let SelectedAccounts = [];
    if (!isEmpty(state.adminOrSuperAdminReducer.schedules)) {
        const getAccount = (account) => {
            return {
                value: account.id,
                label: account.name
            }
        }
        const getInspections = (inspection) => {
            return {
                value: inspection.inspection_form.id,
                label: inspection.inspection_form.name
            }
        }

        schedules = cloneDeep(state.adminOrSuperAdminReducer.schedules);
        // schedules['account_id'] = getAccount(schedules.account);
        schedules['start_date'] = schedules.start_date ? moment(schedules.start_date).format('YYYY-MM-DD') : '';
        schedules['end_date'] = schedules.end_date ? moment(schedules.end_date).format('YYYY-MM-DD') : '';
        schedules['inspection_form_id'] = getInspections(schedules.inspection);
    } else {
        const getAccountId = (accounts) => {
          return accounts && accounts.map((a) => {
            if(a.id === props.match.params.accountId) {
                return {
                    value: a.id,
                    label: a.name
                }
            }
          });
        };
        SelectedAccounts = getAccountId(props.accountsList);
          schedules['account_id'] = '';
    }
    return { initialValues: { schedules }, formStates: getFormValues('newAccountScheduleForm')(state) }

}
NewAccountsSchedule = reduxForm({
    form: 'newAccountScheduleForm',
    validate,
    enableReinitialize: true,
})(NewAccountsSchedule);
const selector = formValueSelector('newAccountScheduleForm') // <-- same as form name
NewAccountsSchedule = connect(state => {
    const { fromDate, toDate } = selector(state, 'fromDate', 'toDate')
    const selectedValues = { fromDate, toDate }
    return {
        selectedValues
    }
})(NewAccountsSchedule)
NewAccountsSchedule = connect(mapStateToProps)(NewAccountsSchedule);

export default NewAccountsSchedule;
