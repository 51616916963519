import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
//import '../../../../../styles/custom.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeFirstLetterCapital, CustomNoDataComponent, getUser, convertInventoryDateTimezone } from '../../../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import '../../../../../../node_modules/react-table-v6/react-table.css';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../../redux/actions/index';
import ImageLoader from 'react-imageloader';
import ReactTooltip from 'react-tooltip';
import storage from '../../../../../utility/storage';
import errorHandler from '../../../../../utility/errorHandler/errorHandler';

const PickingTicketTable = (props) => {
  const inventoryAccessRole = storage.get('InventoryAccessRole');
  const [pageSize, setPageSize] = useState(10);
  const [openModel, setOpen] = useState(false);

  const dispatch = useDispatch();
  const SearchValue = useSelector(state => state.form.searchPickupListForm && state.form.searchPickupListForm.values ? state.form.searchPickupListForm.values : {});
  const values = useSelector(state => state.InventoryReducer.picking_tickets_searchValues);
  const DateGenerate = useSelector(state => state.InventoryReducer.dataGenerated);
  const SearchOrResetPickingTicketClicked = useSelector(state => state.InventoryReducer.SearchOrResetPickingTicketClicked);
  const No_of_pages = useSelector(state => state.InventoryReducer.pickingTicket_pages);
  const No_of_rows = useSelector(state => state.InventoryReducer.pickingTicket_no_of_rows);
  const pickupTicketCurPage = useSelector(state => state.InventoryReducer.pickingTicketCurPage);

  const data = props.pickingTicketList && props.pickingTicketList.filter((ticket) => {
    if(ticket.status == 'approved') {
      return ticket.is_emergency == true
    }else {
      return ticket;
    }
  }).map(ticket => {
    return {
      id: ticket.id,
      name: ticket.account ? makeFirstLetterCapital(ticket.account.name) : "-",
      creator: ticket.manager ? `${makeFirstLetterCapital(ticket.manager.first_name)} ${makeFirstLetterCapital(ticket.manager.last_name)} ` : '-'  ,
      created_date: ticket &&  ticket.created_at ? convertInventoryDateTimezone(ticket.created_at, ticket.manager.account_timezone).toUpperCase() : '-' ,
      fulfillmentManager_location : ticket.location ? `${makeFirstLetterCapital(ticket.location.name)}` : '-'  ,
      approver: ticket.approver ? `${makeFirstLetterCapital(ticket.approver.first_name)} ${makeFirstLetterCapital(ticket.approver.last_name)} ` : '-'  ,
      supervisor: ticket.supervisor ? `${makeFirstLetterCapital(ticket.supervisor.first_name)} ${makeFirstLetterCapital(ticket.supervisor .last_name)} ` : '-'  ,
      status: ticket.status == 'readyForPickup' ? 'Ready For Pickup' : makeFirstLetterCapital(ticket.status),
      emergency: ticket && (ticket.is_emergency == true) ? 'Yes' : 'No' ,
      actions:
      <>
        <>
          <span data-tip="View Ticket" className="mr_20 account_edit_icn" onClick={() => props.history.push(`/audit-logs/${ticket.id}`)}><i class="fas fa-eye" aria-hidden="true" ></i></span>
          <ReactTooltip place="top" type="dark" effect="solid" />
        </>
        {(inventoryAccessRole == 'fulfillmentManager') && ((ticket.status == 'open') || (ticket.status == 'approved')) ? (
        <>
          <span data-tip="Associate Box" className="mr_20 account_edit_icn" onClick={() => {
            return ticket.status == 'open' && ticket.is_emergency == true ? errorHandler('This ticket is not yet approved') : props.history.push(`/fulfillment-manager/${ticket.id}`);
            }}>
            <i class="fas fa-cube" aria-hidden="true" ></i>
          </span> <ReactTooltip place="top" type="dark" effect="solid" />
        </>) : null}
      </>
    }
  })


  const columns = [
    {
      Header: <><span>ID</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'id', // String-based value accessors!,
      show: props.pickingTicketList.length !== 0,
    },
    {
      Header: <><span>Job Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'name', // String-based value accessors!,
      show: props.pickingTicketList.length !== 0,
    },
    {
      Header: <><span>Created By</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'creator', // String-based value accessors!,
      show: props.pickingTicketList.length !== 0,
    },
    {
        Header: <><span>Created Date & Time</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'created_date', // String-based value accessors!,
        show: props.pickingTicketList.length !== 0,
      },
      {
        Header: <><span>FulFillment Manager Location</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'fulfillmentManager_location', // String-based value accessors!,
        show: props.pickingTicketList.length !== 0,
      },
      {
        Header: <><span>Approver</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'approver', // String-based value accessors!,
        show: props.pickingTicketList.length !== 0,
      },
      {
        Header: <><span>Supervisor</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'supervisor', // String-based value accessors!,
        show: props.pickingTicketList.length !== 0,
      },
      {
        Header: <><span>Status</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'status', // String-based value accessors!,
        show: props.pickingTicketList.length !== 0,
      },
      {
        Header: <><span>Emergency</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
        accessor: 'emergency', // String-based value accessors!,
        show: props.pickingTicketList.length !== 0,
        width: 100
      },
    {
      Header: " ",
      accessor: "actions",
      show: props.pickingTicketList.length !== 0,
      width: 100
    }
  ]

  const [tablePage, setTablePage] = useState(0)
  const tablePageNew =  React.useRef()
  if(pickupTicketCurPage !== tablePageNew.current) {
    tablePageNew.current = pickupTicketCurPage;
  }
  const setTablePageFn = (page) => {
      setTablePage(page)
      props.pickingTicketPageClicked(page)
      tablePageNew.current = page;
  }

  const fetchData = (state, instance) => {
    if (SearchOrResetPickingTicketClicked) {
      dispatch(actions.resetSearchOrResetValue())
    } else {
      if (!DateGenerate) {
                state.page = 2;
                const limit = state.pageSize;
                const fl_users_no_of_rows = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : pickupTicketCurPage + 1;
                if(No_of_rows != fl_users_no_of_rows) {
                  page = 1;
                  setTablePageFn(0)
              }
                const sorted = state.sorted;
        //const filtered = state.filtered;
        const fulfillment_manager = SearchValue.fulfillment_manager ? SearchValue.fulfillment_manager : '';
        const manager = SearchValue.manager ? SearchValue.manager : '';
        const approver = SearchValue.approver ? SearchValue.approver : '';
        const status = SearchValue.status ? SearchValue.status : props.inventoryAccessRole && props.inventoryAccessRole == 'fulfillmentManager' ? 'approved' : '';
        const id = SearchValue.id ? SearchValue.id : '';
        const is_emergency = SearchValue.is_emergency ? SearchValue.is_emergency : '';
        props.getAllPickingTickets({page,limit, sorted, fulfillment_manager, manager,approver,status, id, is_emergency}, values);
      }
    }
    // }
  }
  const handleConfirm = (confirm) => {
    setOpen(false);
    //props.deleteArea(currentArea.id, props.match.params.accountId)
  };
  return (
    SearchOrResetPickingTicketClicked && props.isLoading ?
      <div className="loader_btn_block">
        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
      </div>
      :
      <> <ReactTable
        data={data}
        loading={props.isLoading}
        defaultPageSize={No_of_rows ? No_of_rows : pageSize}
        minRows={0}
        manual
        onFetchData={fetchData}
        pages={No_of_pages}
        gotoPage={pickupTicketCurPage}
        page = { pickupTicketCurPage }
        onPageChange={(page) => {setTablePageFn(page)}}
        onPageSizeChange={(e) => setPageSize(e)}
        showPaginationTop={true}
        showPaginationBottom={false}
        showPagination={props.pickingTicketList.length !== 0}
        columns={columns}
        NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Tickets Found')}
        getTheadThProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              borderTop: 'none',
              borderBottom: 'none',
              borderRight: 'none',
              textAlign: 'center',
              color: '#566975',
              fontSize: '14px',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              float: 'left',
            }
          }
        }
        }
        LoadingComponent={() =>
          props.isLoading ?
            <div style={{
              display: "block",
              position: "absolute",
              left: 0,
              height: '100%',
              right: 0,
              background: "rgba(255,255,255,0.8)",
              transition: "all .3s ease",
              top: 0,
              bottom: 0,
              textAlign: "center"
            }}
              className="loader_btn_block">
              <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div> : null}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            className: 'react-table-tr-element',
            style: {
              border: '1px solid #c7cdd1',
              transition: '.3s ease-in-out',
              width: '100%',
              marginTop: '5px',
              float: 'left'
            }
          }

        }}
        getTdProps={(state, rowInfo, column, instance) => {

          if (column.id == 'image') {
            return {
              style: {
                marginTop: '0px'
              }
            }
          } else {
            return {
              className: 'react-td-element',
            }
          }
        }
        }
        getProps={() => {
          return {
            style: {
              border: 'none'
            }
          }
        }}
      />
        <Dialog
          open={openModel}

          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
            <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
          </DialogTitle>

          <DialogActions>
            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => handleConfirm(true)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setOpen(false)} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
          </DialogActions>
        </Dialog>

      </>
  )
}

export default PickingTicketTable;