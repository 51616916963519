import React, { useEffect } from "react";
import {  useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PreviewEventForm from "../../../component/companies/BRMEventContainers/PreviewEvents/previewEventcomponent";
import { completePreviewEvent, getOnePreviewEvent, removePreviewDetails } from "../../../../redux/actions/BrmActions/BrmAction";
import { tzDateConverterTool_V2PreviewEvent } from "../../../../utility/TimezoneOperations/timezoneUtility";


const PreviewEventContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch()
    const { feedbackData } = useSelector((state) => state.BRM)
    const { state } = useLocation()

    const convertDate = (date) => {
        if (date) {
            var parts = date.split('T');
            var datePart = parts[0];
            let dt = moment(datePart)._d
            return dt
        }
    }

    const defaultForm = {
        attedees: feedbackData && feedbackData.feedbackUser?.length ? feedbackData.feedbackUser : [
            {
                first_name: "",
                last_name: "",
                email: "",
            },
        ],
        scheduled_at: feedbackData && feedbackData.scheduled_at ? convertDate(feedbackData.scheduled_at) : "",
        time: feedbackData && feedbackData.time ? tzDateConverterTool_V2PreviewEvent(feedbackData.time):"",
        notes: feedbackData && feedbackData.notes ? feedbackData.notes : "",
    }

    

    const structureBody = (data) => {

        let scheduled_at = new Date(data.scheduled_at)
        const year = scheduled_at.getFullYear()
        const month = scheduled_at.getMonth() + 1
        const day = scheduled_at.getDate()
        scheduled_at = `${year}-${month}-${day}`
        let timeInstring = data.time.toISOString().split("T")[1].split(".")[0]
        let time = `${scheduled_at} ${timeInstring}`;
        return {
            data: {
                ...data,
                scheduled_at,
                time
            }
        }
    }


    useEffect(() => {
        if ((id && (state?.isFetch || state == undefined))) {
            dispatch(getOnePreviewEvent(id))
        }

        return () => dispatch(removePreviewDetails(null))
    }, [id, state])

    const onSubmit = (data) => {
        dispatch(completePreviewEvent(id, structureBody(data)))
    }


    

    return (
        <PreviewEventForm
            formSubmittedCallback={onSubmit}
            elements={defaultForm}
            isEdit={true}
            isView={(state?.isFetch || feedbackData?.status == "completed") || false}
            modalUpdate={true}
        />
    );
}

export default PreviewEventContainer;