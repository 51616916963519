import React, { Component } from 'react';
import NewAccount from '../../../component/Accounts/NewAccount/newAccount';
import getDetailAddress from '../../../../utility/getDetailAddress';
import { routes, address_attributes } from '../../../../utility/constants/constants';
import * as actions from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { decode, encode } from 'base64-arraybuffer';
import NewAccountsSchedule from '../../../component/Accounts/AccountNewSchedule/accountNewSchedule';
import { convertDateToPickerFormat, getFirstAndLastOfMonth, getUser, ModalKpiGetFormatedDate } from '../../../../utility/utility';
import moment from 'moment';
const cloneDeep = require('clone-deep');

class NewAccountContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createScheduleData: {}
        }
    }
    componentDidMount() {
        const user = getUser();
        this.props.getModeAccounts(user.mode);
        if(this.props.match.params.accountId) {
            this.props.getInspectionFormForAccount(this.props.match.params.accountId)
        }
    }
    componentDidUpdate() {
        if (this.props.accountsList && this.props.accountsList.length > 0) {
            this.props.accountsList.map((acc) => {
                if (acc.id === this.props.match.params.accountId) {
                    this.props.setCurrentAccount(acc);
                }
            })
        }
    }

    onCancelClicked = () => {
        this.props.history.push(routes.SCHEDULE_TIME);
    }

    addNewSchedule = (values) => {
        let account_id;
        if (this.props.currentAccount) {
            account_id = this.props.currentAccount.id;
        }
        if(values.schedules.account_id.value) {
            account_id = values.schedules.account_id.value;
        }
        let schedules = cloneDeep(values);
        schedules.schedules.title = schedules.schedules.title.trim();
        if (this.props.isEdit) { 
        } else { 
            if (schedules.schedules.frequency === 'weekly') {
                const start_date = moment(schedules.schedules.start_date).format('YYYY-MM-DD');
                const end_date = moment(schedules.schedules.end_date).format('YYYY-MM-DD');
                schedules.schedules.start_date = start_date;
                schedules.schedules.end_date = end_date;
            } else{
                const start_date = moment(schedules.schedules.start_date).startOf('month').format('YYYY-MM-DD');
                const end_date = moment(schedules.schedules.end_date).endOf('month').format('YYYY-MM-DD');
                schedules.schedules.start_date = start_date;
                schedules.schedules.end_date = end_date;
            }
            if(schedules.schedules.frequency === 'monthly'){ 
                schedules.schedules.selected_month = '1';
            } 
            schedules.schedules.account_id = account_id;
            schedules.schedules.inspection_form_id = schedules.schedules.inspection_form_id.value;
            // schedules.schedules.selected_month = schedules.schedules.selected_month ? schedules.schedules.selected_month : []
            this.setState({createScheduleData: schedules})
            this.props.handleClickScheduleOutlookConfirmModal();  
            // this.props.getAllSchedule(schedules.schedules && schedules.schedules.account_id, start_date, end_date);
            // this.props.getAccountSchedule(account_id)
        }
    }

    createScheduleFinalStep = (dataType) => {
        debugger
        if(dataType === 'yes') {
            setTimeout(() => {
                if(document.getElementsByClassName('outlook-link').length) {
                    document.getElementsByClassName('outlook-link')[0].click()
                }
            }, 500);
        }
        this.props.addNewSchedule(this.state.createScheduleData);
    }

    checkScheduleValues = (values) => {
        let account_id;
        if (this.props.currentAccount) {
            account_id = this.props.currentAccount.id;
        }
        let schedules = cloneDeep(values);
        if(schedules.start_date && schedules.end_date) {
            if (schedules.frequency === 'weekly') {
                const start_date = moment(schedules.start_date).format('YYYY-MM-DD');
                const end_date = moment(schedules.end_date).format('YYYY-MM-DD');
                schedules.start_date = start_date;
                schedules.end_date = end_date;
            } else{
                const start_date = moment(schedules.start_date).startOf('month').format('YYYY-MM-DD');
                const end_date = moment(schedules.end_date).endOf('month').format('YYYY-MM-DD');
                schedules.start_date = start_date;
                schedules.end_date = end_date;
            }
            if(schedules.frequency === 'monthly'){ 
                schedules.selected_month = '1';
            } 
            schedules.account_id = schedules.account_id && schedules.account_id.value;
            schedules.inspection_form_id = schedules.inspection_form_id && schedules.inspection_form_id.value;
            this.props.checkSchedule({schedules: schedules});
        }
    }

    getInspectionForm = (id) => {
        if(id) {
        this.props.getInspectionFormForAccount(id)
        }
    }


    render() {

        return (
            <NewAccountsSchedule
                {...this.props}
                onSubmit={this.addNewSchedule}
                isLoading={this.props.isLoading}
                inspectionFormsList={this.props.inspectionFormsList ? this.props.inspectionFormsList : []}
                accountsList={this.props.accountsList ? this.props.accountsList : []}
                onCancelClicked={this.onCancelClicked}
                account_id_info={this.props.account_id_info}
                account={this.props.account}
                account_name_info={this.props.account_name_info}
                checkScheduleValues={this.checkScheduleValues}
                getInspectionForm={this.getInspectionForm}
                openScheduleOccurrenceModal={this.props.openScheduleOccurrenceModal}
                handleClickScheduleOccurrenceModal={this.props.handleClickScheduleOccurrenceModal}
                handleDeleteClose={this.props.handleDeleteClose}
                openScheduleOutlookConfirmModal={this.props.openScheduleOutlookConfirmModal}
                handleClickScheduleOutlookConfirmModal={this.props.handleClickScheduleOutlookConfirmModal}
                createScheduleFinalStep={this.createScheduleFinalStep}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        accountsList: state.adminOrSuperAdminReducer.modeAccountsList,
        inspectionFormsList: state.adminOrSuperAdminReducer.inspectionFormsList,
        scheduleId: state.adminOrSuperAdminReducer.scheduleId,
        scheduleWithAccountId: state.adminOrSuperAdminReducer.scheduleWithAccountId,
        account: state.adminOrSuperAdminReducer.account,
        account_id_info: state.adminOrSuperAdminReducer.account_id_info,
        account_name_info: state.adminOrSuperAdminReducer.account_name_info,
        currentAccount: state.adminOrSuperAdminReducer.currentAccount,
        scheduleCheckValues: state.adminOrSuperAdminReducer.scheduleCheckValues,
        openScheduleOccurrenceModal: state.adminOrSuperAdminReducer.openScheduleOccurrenceModal,
        openScheduleOutlookConfirmModal: state.adminOrSuperAdminReducer.openScheduleOutlookConfirmModal,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addNewSchedule: (schedule) => dispatch(actions.addNewSchedule(schedule)),
        getModeAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
        getInspectionForms: () => dispatch(actions.getInspectionForms()),
        getAllSchedule: (account_id, start_time, end_time) => dispatch(actions.getAllSchedule(account_id, start_time, end_time)),
        getAccountSchedule: (id) => dispatch(actions.getAccountSchedule(id)),
        setCurrentAccount: (account) => dispatch(actions.setCurrentAccount(account)),
        getInspectionFormForAccount: (id) => dispatch(actions.getInspectionFormForAccount(id)),
        checkSchedule: (body) => dispatch(actions.checkSchedule(body)),
        handleClickScheduleOccurrenceModal: () => dispatch(actions.handleClickScheduleOccurrenceModal()),
        handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
        handleClickScheduleOutlookConfirmModal: () => dispatch(actions.handleClickScheduleOutlookConfirmModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAccountContainer);