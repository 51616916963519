import React, { useState } from 'react';
import { emptyData, LineGraphSaleOptions, LineGraphQuoteCountOptions, BarGraphCountOptions, routes } from '../../../utility/constants/constants';
import WeekMonthYearLists from './weekMonthYearLists';
import DashboardSkeleton from './dashboardSkeleton';
import { isEmpty, getSecMintHoursDays, IsArrayofObjectEmpty } from '../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import { Line, Bar } from 'react-chartjs-2';
import { Status, FilteredQuotes } from '../../../utility/constants/constants';
import EmptyGraph from './emptyGraph';
import posed from 'react-pose';
import PastInspections from './pastInspections';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Link } from 'react-router-dom';
import '../../../styles/custom.css';
import InspectionSchedules from './InspectionSchedules';


const graphStype = {
    marginTop: '0px',
    paddingTop: '0px'
}

const BarGraphStyle = {
    // label: '',
    backgroundColor: '#a1395e',
    hoverBackgroundColor: '#a1395eb3',
    borderWidth: 2,
    barThickness: 5,
    // barPercentage: 0.5
}

const Box = posed.div({
    hoverable: true,
    pressable: true,
    init: {
        scale: 1,
        filter: ' blur(0.1px)'
        // boxShadow: '0px 0px 0px rgba(0,0,0,0)'
    },
    hover: {
        scale: 1.1,
        filter: ' blur(0px)',
        boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
    },
    press: {
        scale: 1,
        // boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
    }
});

const lineGraphStyle = {
    fill: false,
    lineTension: 0.1,
    backgroundColor: '#9a2151',
    borderColor: '#9a2151',
    pointHoverRadius: 5,
    pointBorderColor: '#9a2151',
    pointBackgroundColor: '#ffffff',
    pointHoverBackgroundColor: '#ffffff',
    pointHoverBorderColor: '#9a2151',
    pointHoverBorderWidth: 2,
    pointRadius: 4,
}

const dashboardData = (props, setURL, setOpenURL) => {
    const options = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }

    if (!props.inspectionDashboardData || !props.ticketDashboardData || !props.quoteDashboardData || !props.logbookDashboardData) {
        return (
            <DashboardSkeleton />
        )
    } else {

        const Average_Inspection_Score = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Average Inspection Score'
            },
            ...BarGraphCountOptions
        }
        const Average_Inspection_Lable = [];
        const Average_Inspection_Data = [];
        let Average_Inspection = null;

        if (props.inspectionDashboardData.average_inspection_score_chart_data) {
            for (let data of props.inspectionDashboardData.average_inspection_score_chart_data) {
                Average_Inspection_Lable.push(data[0]);
                Average_Inspection_Data.push(String(data[1]));
            }
        }

        Average_Inspection = {
            labels: Average_Inspection_Lable,
            datasets: [{
                ...BarGraphStyle, label: '', borderColor: '#a1395e',
                borderWidth: 2, backgroundColor: '#a1395e', data: Average_Inspection_Data, fill: false
            }]

        }

        const Inspections_Performed_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Inspections Performed'
            },
            ...BarGraphCountOptions
        }
        const Inspections_Performed_Lable = [];
        const Inspections_Performed_Data = [];
        let Inspections_Performed = null;
        if (props.inspectionDashboardData.inspection_count_chart_data) {
            for (let data of props.inspectionDashboardData.inspection_count_chart_data) {
                Inspections_Performed_Lable.push(data[0]);
                Inspections_Performed_Data.push(String(data[1]));
            }
        }

        Inspections_Performed = {
            labels: Inspections_Performed_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: Inspections_Performed_Data }]

        }

        const Tickets_Created_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Tickets Created'
            },
            ...BarGraphCountOptions
        }
        const Tickets_Created_Lable = [];
        const Tickets_Created_Data = [];
        let Tickets_Created = null;
        if (props.ticketDashboardData.ticket_count_chart_data) {
            for (let data of props.ticketDashboardData.ticket_count_chart_data) {
                Tickets_Created_Lable.push(data[0]);
                Tickets_Created_Data.push(String(data[1]));
            }
        }

        Tickets_Created = {
            labels: Tickets_Created_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: Tickets_Created_Data }]
        }

        // * PEGASSURE_LOGBOOKS_PENDING
        const PegAssure_Logbooks_Pending_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'PegAssure Logbooks Pending'
            },
            ...BarGraphCountOptions
        }
        const PegAssure_Logbooks_Pending_Lable = [];
        const PegAssure_Logbooks_Pending_Data = [];
        let PegAssure_Logbooks_Pending = null;
        if (props.logbookDashboardData.pending_chart) {
            for (let data of props.logbookDashboardData.pending_chart) {
                PegAssure_Logbooks_Pending_Lable.push(data[0]);
                PegAssure_Logbooks_Pending_Data.push(String(data[1]));
            }
        }
        PegAssure_Logbooks_Pending = {
            labels: PegAssure_Logbooks_Pending_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: PegAssure_Logbooks_Pending_Data }]
        }
        // * PEGASSURE_LOGBOOKS_REVIEWED
        const PegAssure_Logbooks_Reviewed_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'PegAssure Logbooks Reviewed'
            },
            ...BarGraphCountOptions
        }
        const PegAssure_Logbooks_Reviewed_Lable = [];
        const PegAssure_Logbooks_Reviewed_Data = [];
        let PegAssure_Logbooks_Reviewed = null;
        if (props.logbookDashboardData.reviewed_chart) {
            for (let data of props.logbookDashboardData.reviewed_chart) {
                PegAssure_Logbooks_Reviewed_Lable.push(data[0]);
                PegAssure_Logbooks_Reviewed_Data.push(String(data[1]));
            }
        }
        PegAssure_Logbooks_Reviewed = {
            labels: PegAssure_Logbooks_Reviewed_Lable,
            datasets: [{ ...BarGraphStyle, label: '', backgroundColor: '#a1395e', data: PegAssure_Logbooks_Reviewed_Data }]
        }

        const Quotes_created_chart_data_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Total Quotes Created'
            },
            ...LineGraphQuoteCountOptions
        }
        const Quotes_created_chart_data_Lable = [];
        const Quotes_created_chart_data_Data = [];
        let Quotes_created_chart_data = null;
        if (props.quoteDashboardData.quotes_created_chart_data) {
            for (let data of props.quoteDashboardData.quotes_created_chart_data) {

                Quotes_created_chart_data_Lable.push(data[0]);
                Quotes_created_chart_data_Data.push(data[1]);

            }
        }
        Quotes_created_chart_data = {
            labels: Quotes_created_chart_data_Lable,
            datasets: [{ ...lineGraphStyle, label: '', data: Quotes_created_chart_data_Data }]
        }


        const Quotes_sent_to_clients_chart_data_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Total Quotes, Sent to Customers'
            },
            ...LineGraphQuoteCountOptions
        }
        const Quotes_sent_to_clients_chart_data_Lable = [];
        const Quotes_sent_to_clients_chart_data_Data = [];
        let Quotes_sent_to_clients_chart_data = null;
        if (props.quoteDashboardData.quotes_sent_to_clients_chart_data) {
            for (let data of props.quoteDashboardData.quotes_sent_to_clients_chart_data) {
                Quotes_sent_to_clients_chart_data_Lable.push(data[0]);
                Quotes_sent_to_clients_chart_data_Data.push(data[1]);

            }
        }

        Quotes_sent_to_clients_chart_data = {
            labels: Quotes_sent_to_clients_chart_data_Lable,
            datasets: [{ ...lineGraphStyle, label: '', data: Quotes_sent_to_clients_chart_data_Data }]

        }


        const Quotes_approved_by_clients_chart_data_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Total Quotes, Approved by Customers'
            },
            ...LineGraphQuoteCountOptions
        }
        const Quotes_approved_by_clients_chart_data_Lable = [];
        const Quotes_approved_by_clients_chart_data_Data = [];
        let Quotes_approved_by_clients_chart_data = null;
        if (props.quoteDashboardData.quotes_approved_by_clients_chart_data) {
            for (let data of props.quoteDashboardData.quotes_approved_by_clients_chart_data) {
                Quotes_approved_by_clients_chart_data_Lable.push(data[0]);
                Quotes_approved_by_clients_chart_data_Data.push(data[1]);
            }
        }

        Quotes_approved_by_clients_chart_data = {
            labels: Quotes_approved_by_clients_chart_data_Lable,
            datasets: [{ ...lineGraphStyle, label: '', data: Quotes_approved_by_clients_chart_data_Data }]
        }


        const Quotes_not_tagged_chart_data_options = {
            title: {
                display: true,
                fontSize: 20,
                padding: 30,
                fontColor: '#962d39',
                text: 'Total Visits, With No Quote Identified'
            },
            ...LineGraphQuoteCountOptions
        }
        const Quotes_not_tagged_chart_data_Lable = [];
        const Quotes_not_tagged_chart_data_Data = [];
        let Quotes_not_tagged_chart_data = null;
        if (props.quoteDashboardData.quotes_not_tagged_chart_data) {
            for (let data of props.quoteDashboardData.quotes_not_tagged_chart_data) {
                Quotes_not_tagged_chart_data_Lable.push(data[0]);
                Quotes_not_tagged_chart_data_Data.push(data[1]);
            }

        }

        Quotes_not_tagged_chart_data = {
            labels: Quotes_not_tagged_chart_data_Lable,
            datasets: [{ ...lineGraphStyle, label: '', data: Quotes_not_tagged_chart_data_Data }]
        }

        const cleaning_history = props.ticketDashboardData.customer_urls.filter(i => i.area.length > 0);

        return (
            <>
                <WeekMonthYearLists {...props} />
                {/* <!-- chart --> */}

                {props.isLoading ?
                    <div className="loader_btn_block">
                        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                    </div>
                    :

                    <div className="chart-content">
                        <div className="row">
                            <div class="col-lg-9 col-xl-10">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-12 chart_cont_box graph_responsive" onClick={() => props.viewFilterData(FilteredQuotes.PENDING_FOR_APPROVAL, 'inspections')}>
                                                {Average_Inspection.datasets[0].data[0] === '0' || Average_Inspection.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Top 10 Quotes, Sent by User(#)'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='' style={graphStype}>
                                                    <div className="chart-img" style={graphStype}><Bar height={15} width={50} data={Average_Inspection} options={Average_Inspection_Score} id="desktop_screen_graph"/></div>
                                                    <div className="chart-img" style={graphStype}><Bar height={45} width={50} data={Average_Inspection} options={Average_Inspection_Score} id="mobile_screen_graph"/></div>                                                  
                                                      </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData(FilteredQuotes.PENDING_FOR_APPROVAL, 'inspections')}>
                                                {IsArrayofObjectEmpty(Inspections_Performed.datasets[0].data) || Inspections_Performed.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Inspections Performed'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='inspectionsPerformedId' style={graphStype}>
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={Inspections_Performed} options={Inspections_Performed_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData(FilteredQuotes.PENDING_FOR_APPROVAL, 'tickets')}>
                                                {IsArrayofObjectEmpty(Tickets_Created.datasets[0].data) || Tickets_Created.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'Tickets Created'} />
                                                    :
                                                    <div className="tile chart-box-botL" id="ticketsCreatedId" style={graphStype}>
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={Tickets_Created} options={Tickets_Created_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData('pending', 'logbook')}>
                                                {IsArrayofObjectEmpty(PegAssure_Logbooks_Pending.datasets[0].data) || PegAssure_Logbooks_Pending.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'PegAssure Logbooks Pending'} />
                                                    :
                                                    <div className="tile chart-box-botL" id='pegAssureLogbooksPendingId' style={graphStype}>
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={PegAssure_Logbooks_Pending} options={PegAssure_Logbooks_Pending_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData('reviewed', 'logbook')}>
                                                {IsArrayofObjectEmpty(PegAssure_Logbooks_Reviewed.datasets[0].data) || PegAssure_Logbooks_Reviewed.datasets[0].data.length === 0 ?
                                                    <EmptyGraph title={'PegAssure Logbooks Reviewed'} />
                                                    :
                                                    <div className="tile chart-box-botL" id="pegAssureLogbooksReviewedId" style={graphStype}>
                                                        <div className="chart-img" style={graphStype}>
                                                            <Bar height={45} width={50} data={PegAssure_Logbooks_Reviewed} options={PegAssure_Logbooks_Reviewed_options} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-4" >
                                        <div className="tile performance_chart" onClick={() => props.viewFilterData('pending', 'logbook')}>
                                            <h4 className="chart_title1">Total PegAssure Logbooks</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.logbookDashboardData.total_logs}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_quotes_sent.png" alt="Total Quotes Sent" />
                                                </div>
                                            </div>
                                            <div className="card_f_txt">
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="row">
                                    <div className="col-6 col-xl-4">
                                        <div className="tile performance_chart element" onClick={() => props.viewFilterData('all', 'quotes')} style={{ cursor: 'pointer' }} >
                                            <h4 className="chart_title1">Total Quotes Created</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.quoteDashboardData.quotes_created}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_User_quotescreated.png" alt="Total Quotes Created" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-xl-4">
                                        <div className="tile performance_chart element" style={{ cursor: 'pointer' }} onClick={() => props.viewFilterData(FilteredQuotes.SEND_TO_CLIENTS, 'quotes')}>
                                            <h4 className="chart_title1">Total Quotes Sent to Clients</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.quoteDashboardData.quotes_sent_to_clients}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_User_quotessent.png" alt="Total Quotes Sent to Clients" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-xl-4">
                                        <div className="tile performance_chart element" style={{ cursor: 'pointer' }} onClick={() => props.viewFilterData(FilteredQuotes.APPROVED_BY_CLIENTS, 'quotes')}>
                                            <h4 className="chart_title1">Total Quotes Approved by Clients</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.quoteDashboardData.quotes_approved_by_clients}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_User_quotesapproved.png" alt="Total Quotes Approved by Clients" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     <div className="col-6 col-xl-3">
                                        <div className="tile performance_chart element" style={{ cursor: 'pointer' }}>
                                            <h4 className="chart_title1">Total Visits With No Quote Identified</h4>
                                            <div className="flex_box performance_box">
                                                <div className="tile_block">
                                                    <h2>{props.dashboardData.quotes_not_tagged}</h2>
                                                </div>
                                                <div className="circle">
                                                    <img src="../images/icons/icn_User_quotesNotTagged.png" alt="Total Quotes Not Tagged" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div> */}


                                {/* <div className="row">
                                     <div className="user_dashboardChart">
                                         <div className="row">
                                             <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData('all', 'quotes')}>
                                            {} {isEmpty(Quotes_created_chart_data.datasets[0].data) ? 
                                                     <EmptyGraph title={'Total Quotes Created'} />
                                                     :
                                                     <div className="tile chart-box-botL" id='quotesCreatedId' style={graphStype}>
                                                         <h6 className="chart_title2">Total Quotes Created</h6> 
                                                         <div className="chart-img" style={graphStype}>
                                                             <Line height={45} width={50} data={Quotes_created_chart_data} options={Quotes_created_chart_data_options} /></div>
                                                     </div>
                                                 }
                                             </div>
                                             <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData(FilteredQuotes.SEND_TO_CLIENTS, 'quotes')}>
                                                 {isEmpty(Quotes_sent_to_clients_chart_data.datasets[0].data) ?
                                                     <EmptyGraph title={'Total Quotes, Sent to Customers'} />
                                                     :
                                                     <div className="tile chart-box-botL" id="quotesSentToClientsId" style={graphStype}>
                                                          <h6 className="chart_title2">Total Quotes Sent to Clients</h6> 
                                                         <div className="chart-img" style={graphStype}>
                                                             <Line height={45} width={50} data={Quotes_sent_to_clients_chart_data} options={Quotes_sent_to_clients_chart_data_options} /></div>
                                                     </div>
                                                 }
                                             </div>
                                         </div>
                                     </div>
                                 </div>  */}
                                {/*<div className="row">
                                    <div className="user_dashboardChart">
                                        <div className="row">
                                            <div className="col-xl-3 chart_cont_box">
                                            </div>
                                            <div className="col-xl-6 chart_cont_box" onClick={() => props.viewFilterData(FilteredQuotes.APPROVED_BY_CLIENTS, 'quotes')}>
                                                {isEmpty(Quotes_approved_by_clients_chart_data.datasets[0].data) ?
                                                    <EmptyGraph title={'Total Quotes, Approved by Customers'} />
                                                    :
                                                    <div className="tile chart-box-botL" id="quotesApprovedByClientsId" style={graphStype}>
                                                         <h6 className="chart_title2">Total Quotes Approved by Clients</h6> 
                                                        <div className="chart-img" style={graphStype}>
                                                            <Line height={45} width={50} data={Quotes_approved_by_clients_chart_data} options={Quotes_approved_by_clients_chart_data_options} /></div>
                                                    </div>
                                                }
                                            </div>
                                             <div className="col-xl-6 chart_cont_box">
                                                {isEmpty(Quotes_not_tagged_chart_data.datasets[0].data) ?
                                                    <EmptyGraph title={'Total Visits, With No Quote Identified'} />
                                                    :
                                                    <div className="tile chart-box-botL" id="quotesNotTagged" style={graphStype}>
                                                        <div className="chart-img" style={graphStype}>
                                                            <Line height={45} width={50} data={Quotes_not_tagged_chart_data} options={Quotes_not_tagged_chart_data_options} /></div>
                                                    </div>
                                                }
                                            </div> 
                                        </div>
                                    </div>
                                </div> */}
                                {/* <InspectionSchedules {...props}/> */}
                                <div className="row">

                                    <PastInspections {...props} />
                                </div>
                            </div>
                            <div class="col-lg-3 col-xl-2 chart_cont_box">
                                <div class="ticket_inbox">
                                    <div class="card">
                                        <div class="card-header">
                                            Ticket Inbox
                                        </div>
                                        <div class="card-body">
                                            <div class="ticket_status">
                                            <div class="col">
                                                    <div class="col p-0">
                                                        <small>Open</small>
                                                        <h2 class="status_pending">{props.ticketDashboardData.pending_ticket_count}</h2>
                                                    </div>
                                                    <div class="col p-0">
                                                        <small>Resolved</small>
                                                        <h2 class="status_open">{props.ticketDashboardData.resolved_ticket_count}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ticket_link_list">
                                                <ul class="link_list">
                                                    {props.ticketDashboardData.ticket_data && props.ticketDashboardData.ticket_data.map((ticket) => {
                                                        return (
                                                            <li>
                                                                <a href="javascript:void(0);" onClick={() => props.previewTicketClicked(ticket)}>{ticket.subject}</a>
                                                                <small>{ticket.user.first_name + ' ' + ticket.user.last_name} </small>
                                                                <br />
                                                                <small>{getSecMintHoursDays(ticket.updated_at.replace(/-/gi, "/"), ticket.account.timezone)}</small>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {cleaning_history && cleaning_history.length > 0 && (props.user.selected_mode && props.user.selected_mode === 'external')?
                                    <div class="ticket_inbox mt-2">
                                        <div class="card">
                                            <div class="card-header mb-0">
                                                Cleaning History
                                            </div>
                                            <div class="card-body">
                                                <div class="ticket_status">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="ticket_link_list">
                                                                <ul class="link_list" style={{ textAlign: 'left' }}>
                                                                    {cleaning_history && cleaning_history.map((customer, i) => <li style={{ marginTop: 5 }} key={i} className="label_modifier"> <a href="javascript:void(0);"
                                                                        onClick={() => [setOpenURL(true), setURL(customer.url)]}
                                                                    >{customer.name}</a></li>)}
                                                                    {/* {props.upcomingSchedules && props.upcomingSchedules.future_schedules.map((schedule, index) => <li key={index} className="label_modifier"><small>{schedule.area.name}</small> <small className="status_open">{moment(moment().format("MM-DD-YYYY") + " " + schedule.start_time, "MM-DD-YYYY HH:mm:ss").format("hh:mm A")}</small></li>)} */}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

const RequestorDashbord = (props) => {

    const [openUrl, setOpenURL] = useState(false);
    const [genericUrl, setURL] = useState("");

    window.onresize = function () {
        const emptyChartObj = document.getElementsByClassName("empty-graph_heading");
        let cahrtjsHeight = null;

        if (props.quoteDashboardData) {
            if (props.quoteDashboardData.quotes_created > 0) {
                cahrtjsHeight = document.getElementById('quotesCreatedId') ? document.getElementById('quotesCreatedId').clientHeight : '';
            } else if (props.quoteDashboardData.quotes_sent_to_clients > 0) {
                cahrtjsHeight = document.getElementById('quotesSentToClientsId') ? document.getElementById('quotesSentToClientsId').clientHeight : '';
            } else if (props.quoteDashboardData.quotes_approved_by_clients > 0) {
                cahrtjsHeight = document.getElementById('quotesApprovedByClientsId') ? document.getElementById('quotesApprovedByClientsId').clientHeight : '';
            } else if (props.quoteDashboardData.quotes_not_tagged > 0) {
                cahrtjsHeight = document.getElementById('quotesNotTagged') ? document.getElementById('quotesNotTagged').clientHeight : '';
            }
        } else {
            cahrtjsHeight = document.getElementById('quotesCreatedId') ? document.getElementById('quotesCreatedId').clientHeight : '';
        }

        if (emptyChartObj.length !== 0) {
            for (var i = 0; i < emptyChartObj.length; i++) {
                emptyChartObj[i].style.height = `${cahrtjsHeight}px`
            }
        }
    };

    return (
        <main className="app-content wraper_content chart_section user_dashboard">
            <section className="admin_dashboard appcontent_Inner">
                {dashboardData(props, setURL, setOpenURL)}
                {/* <!-- canvas sidebar -->
            <div className="canvas-body">
                <div id="my-navigation" className="offcanvas offcanvas--right offcanvas--initialized">
                    <div className="sidebar_content">
                        <a href="#" className="close_sidebar"><img src="../images/icons/back-arrow.png"></a>
                        <div className="form-group">
                            <input type="text" className="form-control canvas-search" placeholder="Search">
                        </div>
                        <div className="recent-search">
                            <p>RECENT TICKETS</p>
                        </div>
                        <div className="search-category">
                            <p>Unsatisfied</p>
                        </div>
                    </div>
                </div>
            </div> */}
                <Dialog
                    open={openUrl}

                    aria-labelledby="form-dialog-title"
                >
                    <div className="row p-0 m-0">
                        <div className="col-10"></div>
                        <div className="col-2">
                            <h4 onClick={() => [setURL(''), setOpenURL(false)]} className="float-right text-right mt-2 cursor-pointer"><i className="fa fa-times"></i></h4>
                        </div>
                    </div>
                    <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
                        <h3 style={{ color: '#ab385e' }}></h3>
                    </DialogTitle>
                    <div className="qr-body-single"><div className="p-5"><a href={genericUrl} target="_blank" className="text_danger">{genericUrl}</a></div></div>
                    <DialogActions>
                        <button style={{ minHeight: '36px', minWidth: '80px', visibility: "hidden" }} className={"float-right btn mr-2 cst_btn btn_danger"} id="cancel" type="cancel">Cancel</button>
                        <button style={{ minHeight: '36px', minWidth: '80px', visibility: "hidden" }} id="delete" type="delete">Okay</button>
                    </DialogActions>
                </Dialog>
            </section>
        </main>
    )
}

export default RequestorDashbord;