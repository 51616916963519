import React from 'react';
import moment from "moment";
import { Input } from '../../component/UI/InputElement/InputElement';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import { convertAreaScheduleTime, convertAreaScheduleDateTime, convertDateAndTimeTimezonelll } from '../../../utility/utility';
let UpcommingPegsure = (props) => {
    const areaValue = useSelector(state => state.form.upcommingSearch && state.form.upcommingSearch.values ? state.form.upcommingSearch.values.area_name.value : null);
    const noOptionsMessage = (a, b) => {
        return 'Area not found';
    }
    let groupedAccountOptions = [];
    if (props.dashboardData) {
        if (props.dashboardData.customer_urls) {
            groupedAccountOptions = props.dashboardData.customer_urls.map(url => {
                return (
                    {
                        label: url.name,
                        options: url.area.map((a, i) =>
                            ({
                                value: url.token,
                                label: a.name
                            })
                        )
                    }
                )
            })
        }
    }
    // const getFormatedTime = (time) => {
    //     const date = moment().format("MM/DD/YYYY") + " " + time;
    //     const st_time = moment.utc(moment(date)).format("hh:mm A");
    //     return (moment().format("MM-DD-YYYY") + " " + st_time);
    // }
    const getFormattedZone = (timezone) => {
        let zone = ''
        if (timezone === 'America/Los_Angeles') {
          zone = 'PST'
        } else if ( timezone === 'America/Chicago') {
          zone = 'CST'
        }
        return '(' + zone + ')';
      }
    const nextSchedule = (last) => {
        let nextScheduleDate;
        let date = moment().format("MM-DD-YYYY");
        //let currentTime = moment(last.scanned_at,"MM-DD-YYYY HH:mm A").subtract(6.5, "hours");
        // currentTime = currentTime > moment() ? currentTime : moment();
         let currentTime = moment();
        let nextSchedule = null;
        last.area.timeslots.map(time => {
          const startTime = moment(`${date} ${time.start_time_zone}`,"MM-DD-YYYY HH:mm:ss")
          if ( startTime > currentTime && !nextSchedule) {
            // const dateTime = moment().format("MM/DD/YYYY") + " " + time.start_time;
            // const st_time = moment.utc(moment(dateTime)).format("hh:mm A");
            const st_time = moment(moment().format("MM-DD-YYYY") + " " + time.start_time_zone, "MM-DD-YYYY HH:mm:ss").format('MM-DD-YYYY HH:mm A');
            nextSchedule = st_time;
          }
          return time;
        });
        if (!nextSchedule) {
          let nextDate;
          let currentDay = moment().get("day");
          let nextDay = last.area.days.find(a => (a) > currentDay);
          if (!nextDay) nextDay = last.area.days[0];
          if (nextDay > currentDay){
            nextDate = moment().add(1, 'day').isoWeekday(nextDay);
          } else {
            nextDate = moment().add(1, 'week').isoWeekday(last.area.days[0]);
          }
          date = nextDate.format("MM-DD-YYYY");
          // const dateTime = moment().format("MM/DD/YYYY") + " " + last.area.timeslots[0].start_time;
          // const st_time = moment.utc(moment(dateTime)).format("hh:mm A");
          const st_time = moment(moment(date).format("MM-DD-YYYY") + " " + last.area.timeslots[0].start_time_zone, "MM-DD-YYYY HH:mm:ss").format('MM-DD-YYYY HH:mm A');
          nextScheduleDate = st_time;
          console.log("restart next schedule", nextScheduleDate)
        } else {
          nextScheduleDate = nextSchedule;
          console.log("next schedule", nextScheduleDate)
        }
        const nextDate = nextScheduleDate.replace(/-/gi, '/');
        const formattedDate = moment(nextDate).format('lll');
        return `Next Schedule ${formattedDate} ${getFormattedZone(last.timezone)}`
      };    
    return (
        <div className="history_warp qr_app_content">
            <div className="theme_sub_header">
                <form className="form-inline">
                    <label className="label_modifier" >Area Name</label>
                    <Input name="area_name" onChange={props.onAreaChange} type="inputGroupSelect" className="input-modifier areaNameBtn" noOptionsMessage={noOptionsMessage} options={groupedAccountOptions} placeholder="AREA-NAME" />
                </form>
            </div>
            <div className="history_block">
                <div className="add_content_block">
                    {props.isAreaLoading ? <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" /> :
                        <>
                            <h2 className="h3_title"><span className="title_block">Upcoming Schedules</span> <span className="mob_border_view"><span className="cst_border"></span><img src="../images/icons/group_arrow.png" alt="Arrow" className="group_arrow" /></span></h2>
                            {!areaValue ? <span className="isAreaLoading">Please select the area for upcomming schedules.</span> :
                                areaValue && props.upcomingSchedules === null ?
                                    <span className="isAreaLoading">No Upcoming Schedules .</span>
                                    :
                                    <ol className="activity-feed">
                                       <div className="mt-1 front-line-task-block">{nextSchedule(props.upcomingSchedules)}</div>
                                        {/* {props.upcomingSchedules && props.upcomingSchedules && props.upcomingSchedules.future_schedules.map(future => {
                                            return (
                                                <li className="feed-item timeline_item">
                                                    <span className="list_content"><span className="font-weight-semibold">{future.area.name}</span> scheduled on <span className="font-weight-semibold">
                                                    {convertAreaScheduleDateTime(getFormatedTime(future.start_time), props.account_timezone)}</span></span>
                                                </li>
                                            )
                                        })} */}
                                    </ol>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
UpcommingPegsure = reduxForm({
    form: 'upcommingSearch'
})(UpcommingPegsure);
UpcommingPegsure = connect(null)(UpcommingPegsure);
export default UpcommingPegsure;