import React from "react";
import moment from "moment";
import {DAYS} from "../../../utility/constants/constants";
import { convertAreaScheduleTime } from "../../../utility/utility";
const SchedulePage = ({areaSearchResult, searchHistory, history,...props}) => {
  const getFormatedTime = (time) => {
    const date = moment().format("MM/DD/YYYY") + " " + time.start_time_zone;
    const st_time = moment.utc(moment(date)).format("hh:mm A");
    return (moment().format("MM-DD-YYYY") + " " + st_time);
  }
  const getFormattedZone = (timezone) => {
    let zone = ''
    if (timezone === 'America/Los_Angeles') {
      zone = 'PST'
    } else if ( timezone === 'America/Chicago') {
      zone = 'CST'
    }
    return '(' + zone + ')';
  }
  return(
      <main className="app-content wraper_content mt-1 pt-0">
        <div className={`frontline-content history_block ${props.user ? "pt-5 mt-5" : ""}`}>
          <h5 className="text-left font-weight-bold pb-3"> Schedule<button className="btn float-right btn_danger" onClick={() => history.goBack()}>Back</button></h5>
          <div className="mt-1 front-line-task-block text-left">Area Name<br/><h5>{searchHistory && searchHistory.area.name}</h5></div>
        </div>
        <div className="appcontent_Inner mt-2">
          <div className="history_block previous-history-block p-0">
            Time of Service
            <div className="mt-1">
            {
              searchHistory && searchHistory.area.days.map((day,index) => <label className="text_danger m-1">{DAYS[day]}</label>)
            }
            </div>
            <div className="mt-1">
              {
                searchHistory && searchHistory.area.timeslots.map((time, index) =>
                        <label key={index} className="m-1 timeslot-btn">{moment(moment().format("MM-DD-YYYY") + " " + time.start_time_zone, "MM-DD-YYYY HH:mm:ss").format("hh:mm A")} {getFormattedZone(searchHistory.timezone)}</label>
                )
              }
            </div>
          </div>
        </div>
      </main>
  )
};

export default SchedulePage;